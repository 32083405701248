import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import SpeedDialTooltipOpen from "../../buttons/SpeedDialTooltipOpen";
import {
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useNavigate } from "react-router";
import { useEffect, useState, useCallback } from "react";
import useLanguage from "../../../context/LanguageProvider";
import useApi from "../../../hooks/useApi";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { GET_TASKS_URL, RERUN_URL } from "../../../api/axios";
import { truncateString } from "../../parameterization/utils";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";

type SalesForecastCardProps = {
  item: {
    analisis: string;
    vista_id: number | null;
    id: number;
    analisis_id: number;
    complete: boolean;
    periodos_faltantes: string[];
    cantidad_periodos: number;
  };
  handleEdit: () => void;
  handleCopy: () => void;
  handleInfo: () => void;
  handleDelete: () => void;
  refetchData: () => void;
};
const SalesForecastCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  refetchData,
}: SalesForecastCardProps) => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [reRunClicked, setReRunClicked] = useState(false);

  const handleHover = () => setIsHovered(!isHovered);

  const handleViewResultInfo = useCallback(() => {
    navigate(`/delta/sales-forecast-resultado`);
    localStorage.setItem("salesForecastView", String(item.vista_id));
    localStorage.setItem("salesForecastId", String(item.id));
    localStorage.setItem("analysisId", String(item.analisis_id));
  }, [item, navigate]);

  const onSuccessReRun = useCallback(() => {
    refetchData();
  }, [refetchData]);

  const {
    isLoading: isLoadingReRunSalesForecast,
    callApi: reRunSalesForecast,
  } = useApi("", "POST", undefined, undefined, onSuccessReRun);

  const { data: salesForecastTasks, refetch: refetchSalesForecastTasks } =
    useApiQuery(
      `${GET_TASKS_URL}/?tipo=sales_forecast`,
      true,
      t("taskManager.getSalesForecast.error")
    );

  useEffect(() => {
    if (isLoadingReRunSalesForecast) {
      refetchSalesForecastTasks();
    }
  }, [isLoadingReRunSalesForecast, refetchSalesForecastTasks]);

  useEffect(() => {
    if (salesForecastTasks) {
      const hasPendingTask = salesForecastTasks.some(
        (task) =>
          task.analisis === item.analisis &&
          task.estado.toLowerCase() === "pendiente"
      );
      setReRunClicked(hasPendingTask);
    }
  }, [salesForecastTasks, item.analisis]);

  return (
    <>
      <Stack sx={{ padding: "3px 5px 5px 5px" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "40px",
            wordBreak: "break-word",
            position: "relative",
          }}
        >
          <Tooltip title={item.analisis}>
            <Typography
              variant="h6"
              sx={{
                color: "var(--text-main)",
                fontWeight: "800",
                textTransform: "uppercase",
                fontSize: "15px",
                lineHeight: "1",
                width: "98%",
              }}
            >
              {item?.analisis}
            </Typography>
          </Tooltip>
          <Stack
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            {item.complete ? (
              <Tooltip
                title={t(
                  "deltaTexts.salesForecast.salesForecastCard.allPeriodsExecuted"
                )}
                placement="right"
              >
                <Stack
                  sx={{
                    gap: 1,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    sx={{
                      backgroundColor: "var(--cream)",
                    }}
                  >
                    <DoneOutlineIcon
                      sx={{
                        color: "var(--icon-success)",
                      }}
                    />
                  </IconButton>
                </Stack>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  reRunClicked
                    ? t("general.loading")
                    : t(
                        "deltaTexts.salesForecast.salesForecastCard.notAllPeriodsExecuted"
                      )
                }
                placement="right"
              >
                <Stack
                  sx={{
                    gap: 1,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {reRunClicked ? (
                    <IconButton
                      sx={{
                        backgroundColor: "var(--cream)",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <CircularProgress
                        sx={{
                          color: "var(--bg-secondary) !important",
                          width: "100% !important",
                          height: "100% !important",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <PlayCircleFilledWhiteIcon
                      sx={{
                        color: "var(--text-main)",
                        cursor: "pointer",
                        fontSize: 40,
                        transition: "transform 0.2s ease-in-out",
                        transform: isHovered ? "scale(1.05)" : "scale(1)",
                      }}
                      onMouseEnter={handleHover}
                      onMouseLeave={handleHover}
                      onClick={() => {
                        setReRunClicked(true);
                        reRunSalesForecast(
                          RERUN_URL("sales_forecast", item.id)
                        );
                      }}
                    />
                  )}
                </Stack>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        <Divider sx={{ margin: "6px 0" }} />
        <Stack>
          <Typography
            variant="subtitle2"
            sx={{
              textAlign: "left",
              textTransform: "uppercase",
              fontWeight: "700",
              letterSpacing: "-0.5px",
            }}
            color="var(--text-main)"
          >
            {t(
              "deltaTexts.salesForecast.salesForecastCard.enabledPeriodsNonExecuted"
            )}
            :{" "}
            {
              <span
                style={{
                  color: "var(--text-info)",
                  textAlign: "left",
                  display: "inline",
                  textTransform: "capitalize",
                  fontWeight: "300",
                }}
              >
                {item.periodos_faltantes && item.periodos_faltantes.length > 0
                  ? truncateString(
                      item.periodos_faltantes
                        .map((period) => {
                          return period;
                        })
                        .join(", "),
                      32
                    )
                  : t("deltaTexts.salesForecast.salesForecastCard.none")}
              </span>
            }
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              textAlign: "left",
              textTransform: "uppercase",
              fontWeight: "700",
              letterSpacing: "-0.5px",
            }}
            color="var(--text-main)"
          >
            {t(
              "deltaTexts.salesForecast.newSalesForecastModal.periodsToForecast"
            )}
            :{" "}
            {
              <span
                style={{
                  color: "var(--text-info)",
                  textAlign: "left",
                  display: "inline",
                  textTransform: "capitalize",
                  fontWeight: "300",
                }}
              >
                {item.cantidad_periodos}
              </span>
            }
          </Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            pt: 1,
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "30px",
              backgroundColor:
                item.vista_id !== null
                  ? "var(--bg-success)"
                  : "var(--button-disabled)",
              border:
                item.vista_id !== null
                  ? "1px solid var(--bg-success)"
                  : "1px solid var(--bg-disabled)",
              "&:hover": {
                backgroundColor: item.vista_id !== null ? "white" : "",
                color: item.vista_id !== null ? "var(--text-success)" : "",
              },
              "&:active": {
                animation:
                  item.vista_id !== null ? "MoveUpDown 0.3s linear" : "",
                animationFillMode: item.vista_id !== null ? "forwards" : "",
              },
            }}
            onClick={
              item.vista_id === null ? () => undefined : handleViewResultInfo
            }
          >
            {t("simulatorTexts.simulationResultById.results")}
          </Button>
          <SpeedDialTooltipOpen
            id={item.id}
            handleEdit={!reRunClicked ? handleEdit : undefined}
            handleCopy={handleCopy}
            handleInfo={handleInfo}
            handleDelete={handleDelete}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default SalesForecastCard;
