import { isLastRowTotalSum } from "../utils";
import { TextField } from "@mui/material";
import { useEffect } from "react";
import { waterfallFunctionOptions } from "../data";
import AddButton from "../../buttons/AddButton";
import DeleteForever from "../../icons/DeleteForever";
import Grid from "@mui/material/Grid";
import Select from "../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../context/LanguageProvider";
import {
  selectStyles,
} from "../../criterias/configCriteria/select.styles";
interface GroupByRowSelectsProps {
  columns?: any;
  columnBaseSelectRef?: any;
  columnSelectedOption?: any;
  columnOptionsChangeHandler?: any;
  functionSelectRef?: any;
  functionSelectedOption?: any;
  functionOptionsChangeHandler?: any;
  handleAddGroupByRow?: any;
  handleDeleteAllRows?: any;
  waterfallRows?: any;
  waterfallColumnNameRef?: any;
  waterfallColumnName?: any;
  setWaterfallColumnName?: any;
  message?: any;
}

const GroupByRowSelects = ({
  columns,
  columnBaseSelectRef,
  columnSelectedOption,
  columnOptionsChangeHandler,
  functionSelectRef,
  functionSelectedOption,
  functionOptionsChangeHandler,
  handleAddGroupByRow,
  handleDeleteAllRows,
  waterfallRows,
  waterfallColumnNameRef,
  waterfallColumnName,
  setWaterfallColumnName,
  message,
}: GroupByRowSelectsProps) => {
  const { t } = useLanguage();

  const isValidName = (value: string) => {
    return waterfallRows
      ? !waterfallRows.some(
        (item: any) => item?.nombre?.toLowerCase() === value?.toLowerCase()
      )
      : true;
  };

  useEffect(() => {
    if (columnSelectedOption) {
      setWaterfallColumnName(columnSelectedOption?.label);
    } else if (functionSelectedOption?.value === "suma_total" || functionSelectedOption?.value === "suma_intermedia") {
      setWaterfallColumnName(functionSelectedOption?.label);
    }
  }, [columnSelectedOption]);

  useEffect(() => {
    if (functionSelectedOption?.value === "suma_total" || functionSelectedOption?.value === "suma_intermedia") {
      setWaterfallColumnName(functionSelectedOption.label);
    }
  }, [functionSelectedOption]);

  return (
    <>
      <Grid item xs={0.5}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <DeleteForever
            tooltipPlacement="bottom"
            onClick={handleDeleteAllRows}
          />
        </Stack>
      </Grid>
      <Grid item xs={3.5}>
        <Select
          id="function"
          reference={functionSelectRef}
          styles={selectStyles(functionSelectedOption)}
          options={waterfallFunctionOptions(t)}
          className="cc_select"
          name="function"
          onChange={functionOptionsChangeHandler}
          closeMenuOnSelect
          placeholder={t(
            "dataModelText.measureManagement.configMeasure.groupByRowSelects.chooseFunction"
          )}
          isClearable
          defaultValue={functionSelectedOption}
          isDisabled={isLastRowTotalSum(waterfallRows)}
        />
      </Grid>
      <Grid item xs={3.5}>
        <Select
          reference={columnBaseSelectRef}
          styles={selectStyles(columnSelectedOption)}
          options={columns}
          onChange={columnOptionsChangeHandler}
          closeMenuOnSelect
          placeholder={t(
            "dataModelText.measureManagement.configMeasure.groupByRowSelects.chooseColumn"
          )}
          isClearable
          defaultValue={columnSelectedOption}
          isDisabled={isLastRowTotalSum(waterfallRows) ||
            functionSelectedOption?.value === "suma_total" ||
            functionSelectedOption?.value === "suma_intermedia"
          }
        />
      </Grid>
      <Grid item xs={3.5}>
        <TextField
          disabled={isLastRowTotalSum(waterfallRows)}
          placeholder={t("dataModelText.measureManagement.configMeasure.groupByRowSelects.columnName")}
          ref={waterfallColumnNameRef}
          size="small"
          multiline
          variant="outlined"
          helperText={
            !isValidName(waterfallColumnName) ? t("dataModelText.measureManagement.configMeasure.groupByRowSelects.nameAlreadyExists") : ""
          }
          error={
            waterfallColumnName === "" || !isValidName(waterfallColumnName)
          }
          fullWidth
          value={waterfallColumnName}
          onChange={(e) => setWaterfallColumnName(e.target.value)}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
            },
          }}
          required
        />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
        item
        xs={1}
      >
        <AddButton align={true} onClick={() => handleAddGroupByRow()} disabled={isLastRowTotalSum(waterfallRows)} />
      </Grid >
    </>
  );
};

export default GroupByRowSelects;
