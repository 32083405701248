import { ConfirmationModal } from "../../dialog";
import { EXPENSES_TARGET_PARAMS_URL } from "../../../api/axios";
import { row_sb_100_styles } from "../../../styles/app-styles";
import { sortByName } from "../utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddConfigButton from "../../buttons/AddConfigButton";
import BasicGrid from "../../basicGrid/BasicGrid";
import ComponentLayout from "../../layout/ComponentLayout";
import ConstructionIcon from "@mui/icons-material/Construction";
import ExpenseTargetCard from "./ExpenseTargetCard";
import NoDataBox from "../../layout/NoDataBox";
import SearchInput from "../../forms/SearchInput";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useInfoContext from "../../../hooks/useInfoContext";
import useLanguage from "../../../context/LanguageProvider";
import ViewInfoModalContainer from "../../viewInfoModal/ViewInfoModalContainer";
import ViewInfoModalContent from "./ViewInfoModalContent";

const ExpensesTargetManagement = () => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] =
    useState<typeof dataGetExpenseTargetParams>();
  const [openViewInfoModal, handleToggleViewInfoModal] = useDialog();
  const [
    openDeleteSchemaConfirmationModal,
    toggleDeleteSchemaConfirmationModal,
  ] = useDialog();
  const [selectedElement, setSelectedElement] = useState<any>();
  const [elementToDelete, setElementToDelete] = useState<string | number>();
  const [searchedList, setSearchedList] = useState<any>([]);
  const [modalFilterList, setModalFilterList] = useState<any>([]);
  const [totalList, setTotalList] = useState<any>([]);

  const {
    isLoading: isLoadingExpenseTargetParams,
    callApi: getExpenseTargetParams,
    data: dataGetExpenseTargetParams,
    error: errorGetExpenseTargetParams,
  } = useApi(
    EXPENSES_TARGET_PARAMS_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    getExpenseTargetParams();
  }, []);

  useEffect(() => {
    if (dataGetExpenseTargetParams) {
      const dataByTabValue = dataGetExpenseTargetParams;
      setFilteredData(dataByTabValue);
      if (searchValue === "" || !searchValue) {
        setSearchedList(dataByTabValue);
        setModalFilterList(dataByTabValue);
        setTotalList(dataByTabValue);
      } else {
        getTotalList(searchedList, modalFilterList);
      }
    }
  }, [dataGetExpenseTargetParams]);

  const handleAddElement = () => {
    localStorage.setItem("configMode", "CREATE");
    navigate("/configuracion-receptores");
  };

  const handleEdit = (e: any, id: number) => {
    localStorage.setItem("configMode", "EDIT");
    //filtrar el elemento por id de dataGetExpenseTargetParams
    const element = dataGetExpenseTargetParams.find(
      (element) => element.id === id
    );
    localStorage.setItem("expenseTarget", JSON.stringify(element));
    navigate("/configuracion-receptores");
  };

  const handleCopy = (e: any, id: number) => {
    localStorage.setItem("configMode", "COPY");
    const element = dataGetExpenseTargetParams.find(
      (element) => element.id === id
    );
    localStorage.setItem("expenseTarget", JSON.stringify(element));
    navigate("/configuracion-receptores");
  };

  const handleInfo = (e: any, id: number) => {
    const element = dataGetExpenseTargetParams.find(
      (element) => element.id === id
    );
    setSelectedElement(element);
    handleToggleViewInfoModal();
  };

  //Eliminar criterio

  const onSuccessDeleteElement = () => {
    getExpenseTargetParams();
  };

  const {
    isLoading: isLoadingDeleteExpenseTarget,
    callApi: deleteExpenseTargetById,
  } = useApi(
    `${EXPENSES_TARGET_PARAMS_URL}/${elementToDelete}`,
    "DELETE",
    undefined, //TODO cambiar el texto
    undefined,
    onSuccessDeleteElement
  );

  const handleDelete = () => {
    deleteExpenseTargetById();
    toggleDeleteSchemaConfirmationModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setElementToDelete(id);
    toggleDeleteSchemaConfirmationModal();
  };

  //Filtrar elementos

  const filterSearchData = (data: any, value: string) => {
    if (
      //TODO buscar la manera de hacer esto mejor para que sea "all" en inglés, etc
      value.toLowerCase() === "t" ||
      value.toLowerCase() === "to" ||
      value.toLowerCase() === "tod" ||
      value.toLowerCase() === "todo" ||
      value.toLowerCase() === "todos"
    ) {
      return data?.filter((element: any) => {
        return (
          element.nombre?.toLowerCase().includes(value?.toLowerCase()) ||
          element.criterio_bucket.some((bucket: any) =>
            bucket.label?.toLowerCase().includes(value?.toLowerCase())
          ) ||
          element.criterio_bucket.length === 0 ||
          ""
        );
      });
    } else {
      return data?.filter((element: any) => {
        return (
          element.nombre?.toLowerCase().includes(value?.toLowerCase()) ||
          element.criterio_bucket.some((bucket: any) =>
            bucket.label?.toLowerCase().includes(value?.toLowerCase())
          ) ||
          ""
        );
      });
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setSearchedList(filterSearchData(filteredData, value));
    } else {
      setSearchedList(filteredData);
    }
    getTotalList(filterSearchData(filteredData, value), modalFilterList);
  };

  const arraysIntersection = (array1, array2) => {
    return array1.filter((item1) => {
      return array2.some((item2) => item2.id === item1.id);
    });
  };

  const getTotalList = (searchedList, modalFilterList) => {
    if (searchedList.length > 0 && modalFilterList.length > 0) {
      setTotalList(arraysIntersection(searchedList, modalFilterList));
    } else {
      setTotalList([]);
    }
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingExpenseTargetParams || isLoadingDeleteExpenseTarget}
        message={t("general.loading")}
      />
      <ComponentLayout
        title={t("menuItemsOptions.criteriaManagement.expensesTarget.title")}
        icon={<ConstructionIcon />}
      >
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Stack sx={{ ...row_sb_100_styles, gap: 1 }}>
            <Stack width="100%">
              <SearchInput
                value={searchValue}
                handleChange={(e) => handleSearch(e.target.value)}
                placeholder={t("Búsqueda por nombre de receptor de gasto")}
                clearIcon={true}
                handleDelete={() => handleSearch("")}
                size="small"
              />
            </Stack>
          </Stack>
          {totalList?.length > 0 ? (
            <BasicGrid
              data={sortByName(totalList)}
              Card={ExpenseTargetCard}
              handleEdit={
                userRolData &&
                userRolData.administracion_de_criterios
                  .visualizacion_y_edicion &&
                handleEdit
              }
              handleCopy={
                userRolData &&
                userRolData.administracion_de_criterios
                  .visualizacion_y_edicion &&
                handleCopy
              }
              handleInfo={handleInfo}
              handleDelete={
                userRolData &&
                userRolData.administracion_de_criterios
                  .visualizacion_y_edicion &&
                handleClickOnDelete
              }
              showInfo={true}
              xs={4}
            />
          ) : (
            !isLoadingExpenseTargetParams && (
              <NoDataBox
                text={t("Aún no hay receptores de gastos disponibles.")}
              />
            )
          )}
          {errorGetExpenseTargetParams && (
            <NoDataBox
              text={t("schemaManagementTexts.schemaManagement.notGetSchema")}
              error={true}
            />
          )}
          {userRolData &&
            (userRolData.administracion_de_criterios.visualizacion_y_creacion ||
              userRolData.administracion_de_criterios
                .visualizacion_y_edicion) && (
              <AddConfigButton handleClick={handleAddElement} />
            )}
        </Stack>
      </ComponentLayout>
      <ViewInfoModalContainer
        item={selectedElement}
        open={openViewInfoModal}
        title={selectedElement?.nombre}
        handleClose={handleToggleViewInfoModal}
        content={<ViewInfoModalContent item={selectedElement} />}
        handleEdit={handleEdit}
        /* handleCopy={handleEdit} */
        isEditRol={
          userRolData &&
          userRolData.administracion_de_criterios.visualizacion_y_edicion
        }
        width="1000px"
        maxWidth="xl"
      />
      <ConfirmationModal
        open={openDeleteSchemaConfirmationModal}
        handleClose={toggleDeleteSchemaConfirmationModal}
        handleAccept={handleDelete}
        message={t("eliminar el receptor ")}
        title={t("dialog.confirmTitle")}
      />
    </>
  );
};

export default ExpensesTargetManagement;
