import { RELATIONSHIP_URL } from "../../../../api/axios";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import Container from "@mui/material/Container";
import CustomAccordion from "../../../accordion/CustomAccordion";
import RelationshipRow from "./RelationshipRow";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";

const RelationshipLine = ({ relationship }) => {
  const { t } = useLanguage();
  const { relationships, setRelationships, getRelationshipsParams } =
    useCompanyParamProvider();
  const {
    callApi: deleteRelationship,
  } = useApi(
    RELATIONSHIP_URL(relationship.id),
    "DELETE",
    t("companyParam.getParams.codes"),
    undefined,
    () => getRelationshipsParams(),
    undefined,
    true
  );

  const handleDeleteRelationship = () => {
    if (relationship.id) {
      deleteRelationship();
    } else {
      const newRelationships = relationships.filter(
        (r: any) => r.nombre !== relationship.nombre
      );
      setRelationships(newRelationships);
    }
  };

  return (
    <CustomAccordion
      title={
        relationship.nombre +
        " (" +
        relationship?.archivo_base?.archivo.label +
        " - " +
        relationship?.archivo_base?.columna.label +
        ")"
      }
      key={relationship.nombre}
      backgroundColor={"var(--cream)"}
      summaryColor={"var(--text-main)"}
      detailsColor={"var(--text-main)"}
      arrowColor={"var(--icon-main)"}
      onDelete={() => handleDeleteRelationship()}
      deleteTooltipText={t("companyParam.deleteRelationship")}
    >
      <Container sx={{ mt: 2, mb: 2 }}>
        {relationship.involucrados &&
          relationship.involucrados.map((r: any, index) => {
            return (
              <RelationshipRow
                key={index}
                row={r}
                rows={relationship.involucrados}
              />
            );
          })}
      </Container>
    </CustomAccordion>
  );
};

export default RelationshipLine;
