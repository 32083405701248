import { max_level_radiobutton_container } from "../../../../styles/app-styles";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { useEffect, useState } from "react";
import { useMarketBasketConfigProvider } from "../../../../context/MarketBasketConfigProvider";
import Box from "@mui/material/Box";
import RadioButtonRow from "../../../companyParameterization/StepperView/Step2/RadioButtonRow";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";

const AnalysisLevel = ({ defaultValue }) => {
  const { t } = useLanguage();
  const {
    marketBasketElement,
    setMarketBasketElement,
    nivelAnalisisSelectRef,
    nivelAnalisisSelectedOption,
    setNivelAnalisisSelectedOption,
    totalColumnOptions,
    nivelAnalisisOptionChangeHandler,
  } = useMarketBasketConfigProvider();
  const [selectedOption, setSelectedOption] = useState(
    defaultValue ? defaultValue : undefined
  );

  useEffect(() => {
    if (nivelAnalisisSelectedOption) {
      setMarketBasketElement((prev) => ({
        ...prev,
        nivel_de_analisis: nivelAnalisisSelectedOption,
      }));
    } else {
      setMarketBasketElement((prev) => ({
        ...prev,
        nivel_de_analisis: undefined,
      }));
    }
  }, [nivelAnalisisSelectedOption]);

  const onChangeInput = (e) => {
    setSelectedOption(e.target.id);
    setMarketBasketElement((prev) => ({
      ...prev,
      tipo_nivel_de_analisis: e.target.id,
      nivel_de_analisis: undefined,
    }));
    setNivelAnalisisSelectedOption(undefined);
  };

  return (
    <Stack sx={{ width: "100%", mt: "10px" }} gap={1}>
      <Stack sx={max_level_radiobutton_container}>
        <Box sx={{ flex: 0.4 }}>
          <RadioButtonRow
            id={"Cadena cliente"}
            selectedOption={selectedOption}
            onChangeInput={onChangeInput}
            groupName={"Nivel de análisis"}
            labelText={t('marketBasket.config.customerChain')}
          />
        </Box>
        <Box sx={{ flex: 0.6 }}>
          {selectedOption === "Cadena cliente" && (
            <Select
              reference={nivelAnalisisSelectRef}
              styles={selectStyles(nivelAnalisisSelectedOption)}
              options={totalColumnOptions}
              onChange={nivelAnalisisOptionChangeHandler}
              closeMenuOnSelect={true}
              placeholder={t('general.chooseColumn')}
              defaultValue={marketBasketElement?.nivel_de_analisis}
              isClearable
            />
          )}
        </Box>
      </Stack>
      <Stack sx={max_level_radiobutton_container}>
        <Box sx={{ flex: 0.4 }}>
          <RadioButtonRow
            id={"Punto de venta"}
            selectedOption={selectedOption}
            onChangeInput={onChangeInput}
            groupName={"Nivel de análisis"}
            labelText={t('marketBasket.config.pointOfSale')}
          />
        </Box>
        <Box sx={{ flex: 0.6 }}>
          {selectedOption === "Punto de venta" && (
            <Select
              reference={nivelAnalisisSelectRef}
              styles={selectStyles(nivelAnalisisSelectedOption)}
              options={totalColumnOptions}
              onChange={nivelAnalisisOptionChangeHandler}
              closeMenuOnSelect={true}
              placeholder={t('general.chooseColumn')}
              defaultValue={marketBasketElement?.nivel_de_analisis}
              isClearable
            />
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default AnalysisLevel;
