import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
import Select from "../../forms/Select";
import { Checkbox, Stack } from "@mui/material";
import { selectStyles } from "../../criterias/configCriteria/select.styles";
import { IdOption, OnChange } from "../../criterias/configCriteria/types";
import { SingleValue } from "react-select";
import useLanguage from "../../../context/LanguageProvider";
import { useEffect, useRef, useState } from "react";
import FormSelectLayout from "../../forms/FormSelectLayout";
import InputSlider from "../InputSlider";
import useSingleSelect from "../../../hooks/useSingleSelect";

interface MultipleAssignModalProps {
  open: boolean;
  handleClose: () => void;
  handleFinish: () => void;
  prepareColumnsToUseOptions: () => any;
  costDriver: SingleValue<IdOption> | undefined;
  setCostDriver: (param: SingleValue<IdOption> | undefined) => void;
  variability: number;
  setVariability: (variability: number) => void;
  isAssigningDriverCost: boolean;
  setIsAssigningDriverCost: (available: any) => void;
  isAssigningVariability: boolean;
  setIsAssigningVariability: (available: any) => void;
}

const MultipleAssignModal = ({
  open,
  handleClose,
  handleFinish,
  prepareColumnsToUseOptions,
  costDriver,
  setCostDriver,
  variability,
  setVariability,
  isAssigningDriverCost,
  setIsAssigningDriverCost,
  isAssigningVariability,
  setIsAssigningVariability,
}: MultipleAssignModalProps) => {
  const costDriverRef: any = useRef();
  const { t } = useLanguage();

  const onVariableSelectedOptionChange = (value) => {
    setCostDriver(value);
  };

  const [
    variableSelectedOption,
    setVariableSelectedOption,
    variableOptionChangeHandler,
  ] = useSingleSelect((value) => onVariableSelectedOptionChange(value));

  useEffect(() => {
    if (!isAssigningDriverCost) {
      setVariableSelectedOption(undefined);
      costDriverRef.current?.clearValue();
    }
  }, [isAssigningDriverCost]);

  const toggleDriverCostAssignation = () => {
    setIsAssigningDriverCost((prev) => !prev);
  };

  const toggleVariabilityAssignation = () => {
    setIsAssigningVariability((prev) => !prev);
  };

  const isFormValid = () => {
    if (isAssigningDriverCost) {
      if (variableSelectedOption) {
        return true;
      }
    } else if (isAssigningVariability) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isAssigningVariability) {
      setVariability(0);
    }
  }, [isAssigningVariability]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("deltaTexts.simulationRules.multipleAssignModal.multipleAssign")}
      maxWidth="xl"
      actions={
        <>
          <Button
            title={t("simulatorTexts.assignVariabilityModal.cancel")}
            color="grey"
            type="button"
            onClick={handleClose}
          />
          <Button
            title={t("simulatorTexts.assignVariabilityModal.accept")}
            color="blue"
            type="button"
            disabled={!isFormValid()}
            onClick={handleFinish}
          />
        </>
      }
    >
      <Stack
        sx={{
          width: "750px",
          flexDirection: "column",
          gap: 3,
          padding: "15px 25px",
        }}
      >
        {/* Driver Cost Section */}
        <FormSelectLayout
          title={t(
            "deltaTexts.simulationRules.multipleAssignModal.assignCostDriver"
          )}
        >
          <Stack
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-start",
              pt: 1,
            }}
          >
            <Checkbox
              checked={isAssigningDriverCost}
              tabIndex={10}
              disableRipple
              onClick={toggleDriverCostAssignation}
            />
            <Select
              reference={costDriverRef}
              isDisabled={!isAssigningDriverCost}
              styles={selectStyles(costDriver)}
              options={prepareColumnsToUseOptions()}
              className="cc_select"
              name="dependentVar"
              onChange={variableOptionChangeHandler}
              placeholder={t(
                "simulatorTexts.assignVariabilityModal.dependentVariable"
              )}
              defaultValue={costDriver}
              closeMenuOnSelect
              isClearable
            />
          </Stack>
        </FormSelectLayout>

        {/* Variability Section */}
        <FormSelectLayout
          title={t("simulatorTexts.assignVariabilityModal.assignVariability")}
        >
          <Stack
            sx={{
              width: "90%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-start",
              padding: "20px 25px",
            }}
          >
            <Checkbox
              checked={isAssigningVariability}
              tabIndex={10}
              disableRipple
              onClick={toggleVariabilityAssignation}
            />
            <InputSlider
              isDisabled={!isAssigningVariability}
              variableValue={variability}
              setVariableValue={setVariability}
              width="100%"
            />
          </Stack>
        </FormSelectLayout>
      </Stack>
    </Dialog>
  );
};

export default MultipleAssignModal;
