import Tooltip from "@mui/material/Tooltip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useLanguage from "../../context/LanguageProvider";
export interface DeleteForeverIconProps {
  tooltipPlacement:
    | "left"
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined;
  onClick: () => void;
  color?: string;
  width?: string;
}

const DeleteForever = ({ tooltipPlacement, onClick, color, width }: DeleteForeverIconProps) => {
  const { t } = useLanguage();
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={t("icons.deleteAll")}
      placement={tooltipPlacement}
    >
      <DeleteForeverIcon
        onClick={onClick}
        sx={{
          color: color || "var(--text-info)",
          padding: "0",
          margin: "0",
          fontSize: width ? width : "24px",
          "& :hover": { color: "#F90D4A", cursor: "pointer" },
          textAlign: "center",
        }}
      />
    </Tooltip>
  );
};

export default DeleteForever;
