import { SchemaRowType } from "../../types";
import Delete from "../../../../icons/Delete";
import Divider from "@mui/material/Divider";

type SchemaRowProps = {
  row: SchemaRowType;
  handleDeleteSchemaRow: (row: SchemaRowType) => void;
};

const SchemaRow = ({ row, handleDeleteSchemaRow }: SchemaRowProps) => {
  return (
    <>
      <Divider sx={{ width: "90%" }} />
      <div className="cc_external_row_container">
        <div className="pp_schema_1_item_row">{row.destino}</div>
        <div className="pp_schema_2_item_row">
          {row.criterio ? row.criterio.label : row.porcentaje + "%"}
        </div>
        <div className="pp_schema_3_item_row">
          <Delete
            tooltipPlacement="bottom"
            onClick={() => handleDeleteSchemaRow(row)}
          />
        </div>
      </div>
    </>
  );
};

export default SchemaRow;