import { AddButton } from "../../buttons";
import { InputLabel } from "../../forms";
import { OnChange } from "../../criterias/configCriteria/types";
import { SelectWithAllOptions } from "../../forms/SelectWithAllOptions";
import { shadow_box_styles } from "../../../styles/app-styles";
import { Stack, Grid, Box } from "@mui/material";
import AddedMeasures from "./AddedMeasures";
import DeleteForever from "../../icons/DeleteForever";
import FormSelectLayout from "../../forms/FormSelectLayout";
import Select from "../../forms/Select";
import useLanguage from "../../../context/LanguageProvider";
import {
  selectMultiStyles,
  selectStyles,
} from "../../criterias/configCriteria/select.styles";
interface MeasureAgregationFormProps {
  addedMeasures: any[];
  setAddedMeasures: React.Dispatch<React.SetStateAction<any[]>>;
  dataGetAnalysisById: any;
  selected: any[];
  measuresSelectRef: React.RefObject<any>;
  setSelected: any;
  analysisSelectedOption: any;
  aggregationOperatorRef: React.RefObject<any>;
  aggregationSelectedOption: any;
  aggregationSelectedOptionChangeHandler: OnChange;
  setAggregationSelectedOption: React.Dispatch<React.SetStateAction<any>>;
}

const MeasureAgregationForm = ({
  addedMeasures,
  setAddedMeasures,
  dataGetAnalysisById,
  selected,
  measuresSelectRef,
  setSelected,
  analysisSelectedOption,
  aggregationOperatorRef,
  aggregationSelectedOption,
  aggregationSelectedOptionChangeHandler,
  setAggregationSelectedOption,
}: MeasureAgregationFormProps) => {
  const { t } = useLanguage();

  const measuresOptions =
    dataGetAnalysisById &&
    dataGetAnalysisById?.medidas
      ?.map((medida) => {
        return {
          value: medida.id,
          label: medida.nombre,
        };
      })
      ?.filter((item) => {
        return !addedMeasures.some((addedMeasure) =>
          addedMeasure.measures.includes(item.label)
        );
      });

  /************************* FILTERS SELECT *************************/

  const handleAddMeasureAndAgregation = () => {
    const info = {
      id: addedMeasures?.length,
      measures: selected?.map((measure) => measure.label),
      aggregationOperator: aggregationSelectedOption,
    };
    setAddedMeasures([...addedMeasures, info]);
    measuresSelectRef.current?.clearValue();
    setSelected([]);
    aggregationOperatorRef.current.clearValue();
    setAggregationSelectedOption(undefined);
  };

  const aggregationOperations = [
    {
      label: t("dataModelText.downloadReport.aggregationOperatorsLabels.sum"),
      value: "SUMAR",
    },
    {
      label: t(
        "dataModelText.downloadReport.aggregationOperatorsLabels.average"
      ),
      value: "PROMEDIAR",
    },
    {
      label: "MAXIMO",
      value: "MAXIMO",
    },
    {
      label: "MINIMO",
      value: "MINIMO",
    },
    {
      label: t("dataModelText.downloadReport.aggregationOperatorsLabels.count"),
      value: "CONTAR",
    },
    {
      label: t(
        "dataModelText.downloadReport.aggregationOperatorsLabels.count_unique"
      ),
      value: "CONTAR_UNICO",
    },
    {
      label: t("dataModelText.downloadReport.aggregationOperatorsLabels.first"),
      value: "PRIMERO",
    },
    {
      label: t("dataModelText.downloadReport.aggregationOperatorsLabels.last"),
      value: "ULTIMO",
    },
    {
      label: t(
        "dataModelText.downloadReport.aggregationOperatorsLabels.unique_values"
      ),
      value: "VALORES_UNICOS",
    },
  ];

  return (
    <Grid
      container
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          ...shadow_box_styles(false),
          border: !analysisSelectedOption
            ? ""
            : addedMeasures && addedMeasures.length < 1
            ? "red 1px solid"
            : "",
        }}
      >
        <Grid container spacing={2} sx={{ position: "relative" }}>
          {addedMeasures && addedMeasures.length > 0 && (
            <Stack
              sx={{
                position: "absolute",
                left: -50,
                top: -10,
              }}
            >
              <FormSelectLayout>
                <InputLabel title="" transparent={true}>
                  <DeleteForever
                    tooltipPlacement="bottom"
                    onClick={() => setAddedMeasures([])}
                    width="30px"
                  />
                </InputLabel>
              </FormSelectLayout>
            </Stack>
          )}
          <Grid item xs={5.6} sx={{ width: "100%" }}>
            <FormSelectLayout
              title={t("dataModelText.downloadReport.measures")}
              required={true}
            >
              <SelectWithAllOptions
                options={measuresOptions}
                value={selected}
                onChange={setSelected}
                labelSelectAll={t(
                  "dataModelText.downloadReport.selectAllMeasures"
                )}
                placeholder={
                  measuresOptions && measuresOptions.length !== 0
                    ? t("dataModelText.downloadReport.chooseMeasures")
                    : t(
                        "dataModelText.downloadReport.noOptionsMeasuresAvailable"
                      )
                }
                isDisabled={
                  !analysisSelectedOption ||
                  (measuresOptions && measuresOptions.length === 0)
                }
                reference={measuresSelectRef}
              />
            </FormSelectLayout>
          </Grid>
          <Grid item xs={5.6} sx={{ width: "100%", flexDirection: "row" }}>
            <FormSelectLayout
              title={t("dataModelText.downloadReport.aggregationOperators")}
              required={true}
            >
              <Select
                reference={aggregationOperatorRef}
                styles={
                  addedMeasures.length > 0
                    ? selectMultiStyles(aggregationSelectedOption)
                    : selectStyles(aggregationSelectedOption)
                }
                options={aggregationOperations}
                onChange={aggregationSelectedOptionChangeHandler}
                closeMenuOnSelect={true}
                placeholder={t(
                  "dataModelText.downloadReport.chooseAggregationOperators"
                )}
                isClearable
                isDisabled={
                  selected === undefined || (selected && selected.length < 1)
                    ? true
                    : false
                }
              />
            </FormSelectLayout>
          </Grid>
          <Grid item xs={0.8} sx={{ width: "100%", flexDirection: "row" }}>
            <FormSelectLayout color="white" title={"."} required={false}>
              <AddButton
                onClick={handleAddMeasureAndAgregation}
                disabled={
                  !aggregationSelectedOption ||
                  (selected && selected.length < 1)
                    ? true
                    : false
                }
              />{" "}
            </FormSelectLayout>
          </Grid>
        </Grid>
        <Stack
          sx={{
            maxHeight: "150px",
            overflowY: "auto",
            width: "100%",
          }}
        >
          <AddedMeasures
            addedMeasures={addedMeasures}
            setAddedMeasures={setAddedMeasures}
          />
        </Stack>
      </Box>
    </Grid>
  );
};

export default MeasureAgregationForm;
