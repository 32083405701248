import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Delete from "../../../../icons/Delete";
import { Item } from "../../../../basicGrid/BasicGrid";
import Typography from "@mui/material/Typography";
interface GroupByRowProps {
  row: any;
  groupByRows: any[];
  setGroupByRows: any;
}

const RowGroupBy = ({ row, groupByRows, setGroupByRows }: GroupByRowProps) => {
  
  const handleDeleteFilter = () => {
    const newGroupByRows = groupByRows;

    setGroupByRows(
      newGroupByRows.filter((groupByRow: any) => {
        return !(
          groupByRow.column.value === row?.column?.value &&
          groupByRow.function.value === row?.function?.value
        );
      })
    );
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />
        </Stack>
      </Grid>
      <Grid item xs={5.5}>
        <Item>
          <Typography color={"primary"}>{row?.column?.label}</Typography>
        </Item>
      </Grid>
      <Grid item xs={5}>
        <Item>
          <Typography color={"primary"}>{row.function?.label}</Typography>
        </Item>
      </Grid>
      <Grid item xs={1}></Grid>
    </>
  );
};

export default RowGroupBy;
