import { Item } from "../../../basicGrid/BasicGrid";
import Delete from "../../../icons/Delete";
import Grid2 from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
interface RelationshipRowProps {
  row: any;
  rows: any;
  setRows?: any;
}

const RelationshipRow = ({ row, setRows }: RelationshipRowProps) => {

  const handleDeleteFilter = () => {
    setRows &&
      setRows((prev) => {
        return {
          ...prev,
          involucrados: prev.involucrados.filter((r: any) => {
            return !(
              r.archivo.value === row?.archivo.value &&
              r.columna.value === row?.columna.value
            );
          }),
        };
      });
  };

  return (
    <Grid2 container direction={"row"} md={12} mt={1} gap={2} sx={{ mt: 2 }}>
      <Grid2 xs={0.3}>
        <Stack
          width="100%"
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {setRows && (
            <Delete tooltipPlacement="bottom" onClick={handleDeleteFilter} />
          )}
        </Stack>
      </Grid2>
      <Grid2 xs={4.2}>
        <Item>
          <Typography color={"primary"}>{row?.archivo?.label}</Typography>
        </Item>
      </Grid2>
      <Grid2 xs={4.2}>
        <Item>
          <Typography color={"primary"}>{row?.columna?.label}</Typography>
        </Item>
      </Grid2>
      {row?.tipo_relacion?.label && (
        <Grid2 xs={2}>
          <Item>
            <Typography color={"primary"}>
              {row?.tipo_relacion?.label || " "}
            </Typography>
          </Item>
        </Grid2>
      )}
      <Grid2 xs={0.5}></Grid2>
    </Grid2>
  );
};

export default RelationshipRow;
