import Dialog from "../../../../../dialog/Dialog";
import useLanguage from "../../../../../../context/LanguageProvider";
import { Stack } from "@mui/material";
import CustomStepper from "../../../../../Stepper/CustomStepper";
import { useState, useEffect, useContext } from "react";
import CustomButton from "../../../../../buttons/Button";
import { Filter } from "../../../../../criterias/configCriteria/types";
import useSingleSelect from "../../../../../../hooks/useSingleSelect";
import { ConfirmationModal } from "../../../../../dialog";
import useApi from "../../../../../../hooks/useApi";
import { GET_COLUMN_VALUES, POST_GET_PARAMS, REQUIRE_FILTERS } from "../../../../../../api/axios";
import { useApiQuery } from "../../../../../../hooks/useApiQuery";
import { buscarPorId, buscarPorLabel, roundNumber } from "../../../../utils";
import SimpleBackdrop from "../../../../../backdrop/SimpleBackdrop";
import FormLayout from "../../../../../forms/FormLayout";
import SimulationProvider from "../../../../../../context/SimulationProvider";
import Step1NewRegisterRouteModal from "./Step1NewRegisterRouteModal";
import Step2NewRegisterRouteModal from "./Step2NewRegisterRouteModal";
import Step3NewRegisterRouteModal from "./Step3NewRegisterRouteModal";

interface NewRegisterRouteModalProps {
    open: boolean;
    handleClose: () => void,
    configToEdit: any,
    setSelectedConfig: any,
    dataGetSimulationsInformation: any,
    dataGetAnalysisById: any,
    setConfigToEdit: any
}

const NewRegisterRouteModal = ({
    open,
    handleClose,
    configToEdit,
    setSelectedConfig,
    dataGetSimulationsInformation,
    dataGetAnalysisById,
    setConfigToEdit
}: NewRegisterRouteModalProps) => {

    const { t } = useLanguage();
    const analysisId = localStorage.getItem("analysisId") ? localStorage.getItem("analysisId") : undefined
    const [noRoutesMessage, setNoRoutesMessage] = useState<string>("")
    const { simulationState, simulationDispatch } = useContext<React.ContextType<typeof SimulationProvider>>(SimulationProvider);
    const [paramsInfo, setParamsInfo] = useState<any[]>([])

    /***************************** ROUTE TO EDIT ***************************/

    const onSuccessPostGetParams = (data: any) => {
        if (!data) {
            setActiveStep(0)
            setNoRoutesMessage(t("simulatorTexts.registerRoutes.newRouteModal.noRoutesSelectedFiltered"))
        }
        else {
            setParamsInfo(data.map(data => data.clave))
            setNoRoutesMessage("");
        }
    }

    const { isLoading: isLoadingGetParams, data: dataGetParams, callApi: postGetParams } = useApi(
        "",
        "POST",
        t("simulator.getRules.error"),
        undefined,
        onSuccessPostGetParams,
        undefined,
        false
    );


    // acá necesitamos que si selectedRows.length !== 1 se elimine toda la data preseteada para poder agregar "Nueva configuración"
    useEffect(() => {
        if (!configToEdit) {
            setRequiredFilters([])
            setFilters([])
            setVolumeProjectionModSelectedOptions(undefined)
            setAverageVolumePerRouteProjectedValue("")
            setAverageVolumePerRouteAdjustment("")
            setVpoStateProjectedValue("")
            setVpoStateAdjustment("")
            setClientsPerRouteProjectedValue("")
            setClientsPerRouteAdjustment("")
        }
    }, [configToEdit])

    // en cambio, acá necesitamos que si selectedRows.length === 1 setee toda aquella data que proviene de la row, 
    // y tambien depende de dataGetParams.. entonces el useEffect anterior es necesario

    useEffect(() => {
        if (configToEdit) {
            // STEP 1
            const paramsValuesToEdit = configToEdit?.filtros?.adicionales?.map((aditional) => {
                return {
                    id: `${aditional.columna}undefined`,
                    column: {
                        value: aditional.columna,
                        label: aditional.columna,
                    },
                    values: [
                        {
                            value: aditional.valor,
                            label: aditional.valor
                        }
                    ],
                    filterType: ""
                }
            })
            const requiredValuesToEdit = configToEdit?.filtros?.requeridos?.map((required) => {
                return {
                    label: required.label,
                    columna: required.columna,
                    valor: [required.valor]
                }
            })
            setFilters(paramsValuesToEdit)
            setRequiredFilters(requiredValuesToEdit)

            // STEP 3           
            setNewRoutes(configToEdit.cantidad)
        }
    }, [configToEdit])

    useEffect(() => {
        // STEP 2
        if (configToEdit && dataGetParams) {
            const rowToEdit: any = configToEdit
            setVolumeProjectionModSelectedOptions({
                label: rowToEdit.modalidad_proyeccion === 1 ? "Volumen mensual promedio por Cliente" : "Total Ruta",
                value: rowToEdit.modalidad_proyeccion
            })
            rowToEdit?.parametros?.map((param) => {
                dataGetParams && dataGetParams?.map((dataParam) => {
                    if (dataParam.clave.nombre === "Volumen mensual promedio Total por Ruta") {
                        setAverageVolumePerRouteProjectedValue(Number(param.valor_ajustado))
                        setAverageVolumePerRouteAdjustment(((Number(param.valor_ajustado) / Number(dataParam.valor)) - 1) * 100)
                    } else if (dataParam.clave.nombre === "Volumen mensual promedio por Cliente") {
                        setVpoStateProjectedValue(Number(param.valor_ajustado))
                        setVpoStateAdjustment(((Number(param.valor_ajustado) / Number(dataParam.valor)) - 1) * 100)
                    } else if (dataParam.clave.NOMBRE === "Clientes por Ruta") {
                        setClientsPerRouteProjectedValue(Number(param.valor_ajustado))
                        setClientsPerRouteAdjustment(((Number(param.valor_ajustado) / Number(dataParam.valor)) - 1) * 100)
                    }
                })
            })
        }
    }, [configToEdit, dataGetParams])


    /********************************* STEP 1 *****************************/

    const [filters, setFilters] = useState<Filter[]>([])
    const [requiredFilters, setRequiredFilters] = useState<any[]>([])
    const [requiredFiltersSchema, setRequiredFiltersSchema] = useState<any>([])

    const {
        data: dataGetRequiredFilters,
        isLoading: isLoadingDataGetRequiredFilters,
    } = useApiQuery(
        REQUIRE_FILTERS("ALTA_RUTA"),
        true,
        undefined
    );

    /********************************* STEP 2 *****************************/

    const [averageVolumePerRouteAdjustment, setAverageVolumePerRouteAdjustment] = useState<number | string>("")
    const [vpoStateAdjustment, setVpoStateAdjustment] = useState<number | string>("")
    const [clientsPerRouteAdjustment, setClientsPerRouteAdjustment] = useState<number | string>("")
    const [averageVolumePerRouteProjectedValue, setAverageVolumePerRouteProjectedValue] = useState<number | string>("")
    const [vpoStateProjectedValue, setVpoStateProjectedValue] = useState<number | string>("")
    const [clientsPerRouteProjectedValue, setClientsPerRouteProjectedValue] = useState<number | string>("")

    const [averageVolumePerRouteActualValue, setAverageVolumePerRouteActualValue] = useState<number | undefined>()
    const [vpoActualValue, setVpoActualValue] = useState<number | undefined>();
    const [averagePriceActualValue, setAveragePriceActualValue] = useState<number | undefined>();
    const [clientsPerRouteActualValue, setClientsPerRouteActualValue] = useState<number | undefined>();

    useEffect(() => {
        if (dataGetParams) {
            dataGetParams?.map((row: {
                clave:
                { id: number, nombre: string },
                valor: number
            }) => {
                if (row.clave.nombre === "Volumen mensual promedio Total por Ruta") {
                    setAverageVolumePerRouteActualValue(row?.valor);
                } else if (row.clave.nombre === "Volumen mensual promedio por Cliente") {
                    setVpoActualValue(row?.valor);
                } else if (row.clave.nombre === "Precio Promedio") {
                    setAveragePriceActualValue(row?.valor);
                } else if (row.clave.nombre === "Clientes por Ruta") {
                    setClientsPerRouteActualValue(row?.valor);
                }
            })
        }
    }, [dataGetParams])

    const [
        volumenProjectionModeSelectedOptions,
        setVolumeProjectionModSelectedOptions,
        volumenProjectionModeSelectedChangeHandler,
    ] = useSingleSelect();

    const handleGetParams = () => {
        const newConfig: { filtros: { columna: string; valor: string }[] } = {
            filtros: [],
        };

        requiredFilters.forEach((item) => {
            newConfig.filtros.push({
                columna: item.columna,
                valor: item.valor[0],
            });
        });

        filters.forEach((item) => {
            newConfig.filtros.push({
                columna: item.column.label,
                valor: item.values[0].value,
            });
        });

        analysisId && postGetParams(POST_GET_PARAMS(Number(analysisId), "RUTAS"), newConfig)
    }

    /********************************* STEP 3 *****************************/

    const { isLoading: isLoadingGetActualRoutes, data: dataGetActualRoutes, callApi: postGetActualRoutes } = useApi(
        "",
        "POST",
        t("simulator.getRules.error"),
        undefined,
        undefined,
        undefined,
        false
    );

    const [actualRoutes, setActualRoutes] = useState(0)
    const [newRoutes, setNewRoutes] = useState(0)

    const [balanceRoutes, setBalanceRoutes] = useState(0)

    useEffect(() => {
        dataGetActualRoutes && setActualRoutes(dataGetActualRoutes.length)
        dataGetActualRoutes && setBalanceRoutes(newRoutes + dataGetActualRoutes.length)
    }, [dataGetActualRoutes])

    const handleGetActualRoutes = () => {
        const newConfig: { filtros: { columna: string; valor: string }[] } = {
            filtros: [],
        };

        requiredFilters.forEach((item) => {
            newConfig.filtros.push({
                columna: item.columna,
                valor: item.valor[0],
            });
        });

        filters.forEach((item) => {
            newConfig.filtros.push({
                columna: item.column.label,
                valor: item.values[0].value,
            });
        });

        const cod_ruta_id = dataGetSimulationsInformation && dataGetSimulationsInformation.find(info => info.nombre === "Identificador único de ruta").id
        const referenceColumn = cod_ruta_id && simulationState && simulationState?.principalColumns?.find(column => column.informacion === cod_ruta_id).columna
        analysisId && postGetActualRoutes(GET_COLUMN_VALUES(Number(analysisId), referenceColumn.label, "dataexport"), newConfig)
    }


    /********************************* MANEJO STEPPER *****************************/

    const [openConfirmationCloseModal, setOpenConfirmationCloseModal] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setActualRoutes(0)
    };

    const handleNext = () => {
        if (activeStep === 0) {
            handleGetParams();
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        } else if (activeStep === 1) {
            handleGetActualRoutes();
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const labels = {
        steps: [
            t("simulatorTexts.registerRoutes.newRouteModal.steps.paramSelection"),
            t("simulatorTexts.registerRoutes.newRouteModal.steps.settings"),
            t("simulatorTexts.registerRoutes.newRouteModal.steps.routeResults")
        ]
    }

    const handleConfirmationCloseModal = () => {
        if (
            requiredFilters && requiredFilters.length > 0 || filters && filters.length > 0
        ) {
            setOpenConfirmationCloseModal(true)
        } else {
            closeAndClean()
        }
    }

    const validateContinueStepper = () => {
        if (activeStep === 0 ? requiredFilters && dataGetRequiredFilters && requiredFilters.length !== dataGetRequiredFilters.length : !volumenProjectionModeSelectedOptions ? true : volumenProjectionModeSelectedOptions?.label === "Total Ruta" ? (averageVolumePerRouteAdjustment === "" || averageVolumePerRouteProjectedValue === "" || clientsPerRouteAdjustment === "" || clientsPerRouteProjectedValue === "") : volumenProjectionModeSelectedOptions?.label === "Volumen mensual promedio por Cliente" ? (vpoStateAdjustment === "" || clientsPerRouteAdjustment === "" || vpoStateProjectedValue === "" || clientsPerRouteProjectedValue === "") : averageVolumePerRouteAdjustment === "-" || vpoStateAdjustment === "-" || clientsPerRouteAdjustment === "-") {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        open === false && setActiveStep(0)
    }, [open])

    /********************************* MANEJO DE DATOS *****************************/

    const closeAndClean = () => {
        setFilters([])
        setClientsPerRouteAdjustment("")
        setAverageVolumePerRouteAdjustment("")
        setVpoStateAdjustment("")
        setNewRoutes(0)
        setVolumeProjectionModSelectedOptions(null)
        setOpenConfirmationCloseModal(false)
        setAverageVolumePerRouteActualValue(undefined)
        setVpoActualValue(undefined)
        setAveragePriceActualValue(undefined)
        setClientsPerRouteActualValue(undefined)
        setRequiredFilters([])
        setNoRoutesMessage("")
        setConfigToEdit()
        handleClose()
    }    

    const handleFinish = () => {
        const row = [
            {
                [t("simulatorTexts.registerRoutes.newRouteModal.rows.volumeProjectionMode")]: volumenProjectionModeSelectedOptions?.label,
                [t("simulatorTexts.registerRoutes.newRouteModal.rows.averageMonthlyVolumePerRouteActual")]: averageVolumePerRouteActualValue && roundNumber(averageVolumePerRouteActualValue, 2),
                [t("simulatorTexts.registerRoutes.newRouteModal.rows.averageMonthlyVolumePerRouteAdjustment")]: averageVolumePerRouteAdjustment && averageVolumePerRouteActualValue ? roundNumber((Number(averageVolumePerRouteAdjustment) / 100 + 1) * Number(averageVolumePerRouteActualValue), 2) : "-",
                [t("simulatorTexts.registerRoutes.newRouteModal.rows.volumePerOutletActual")]: vpoActualValue && roundNumber(vpoActualValue, 2),
                [t("simulatorTexts.registerRoutes.newRouteModal.rows.volumePerOuletAdjustment")]: vpoStateAdjustment && vpoActualValue ? roundNumber((Number(vpoStateAdjustment) / 100 + 1) * Number(vpoActualValue), 2) : "-",
                [t("simulatorTexts.registerRoutes.newRouteModal.rows.averagePrice")]: averagePriceActualValue && roundNumber(averagePriceActualValue, 2),
                [t("simulatorTexts.registerRoutes.newRouteModal.rows.customerPerRouteActual")]: clientsPerRouteActualValue && roundNumber(clientsPerRouteActualValue, 2),
                [t("simulatorTexts.registerRoutes.newRouteModal.rows.customerPerRouteAdjustment")]: clientsPerRouteAdjustment && clientsPerRouteActualValue ? roundNumber((Number(clientsPerRouteAdjustment) / 100 + 1) * clientsPerRouteActualValue, 2) : "-",
                [t("simulatorTexts.registerRoutes.newRouteModal.rows.actualRoutes")]: dataGetActualRoutes && dataGetActualRoutes?.length,
                [t("simulatorTexts.registerRoutes.newRouteModal.rows.registerRoutes")]: newRoutes,
                [t("simulatorTexts.registerRoutes.newRouteModal.rows.newRoutes")]: balanceRoutes,
            },
        ]
        const newRow = row.map((row, idx) => {
            if (idx === 0) {
                const paramsResult = {}
                requiredFilters.forEach(item => {
                    paramsResult[item.columna] = item.valor?.map((v) => v).join(', ');
                });
                filters.forEach((item) => {
                    const label = item.column.label;
                    const value = item.values?.map((v) => v.value).join(', ');
                    paramsResult[label] = value;
                });
                return { ...paramsResult, ...row }
            }
        })
        //checkeamos de que si existe el outputArray (una ruta para editar eliminamos aquella que está siendo editada y ponemos la misma)
        if (configToEdit) {
            const configEditRoutes = simulationState.configurations?.filter((config, idx) => {
                return config.id !== configToEdit.id;
            });
            simulationDispatch({ type: "SET_CONFIGURATIONS", payload: configEditRoutes });
        }

        const newConfig = {
            id: simulationState.configurations.length,
            tipo: "alta",
            cantidad: newRoutes,
            modalidad_proyeccion: volumenProjectionModeSelectedOptions?.value ? volumenProjectionModeSelectedOptions?.value : "",
            parametros: [
                {
                    clave: paramsInfo.find(elemento => elemento.nombre === "Volumen mensual promedio Total por Ruta") || null, // 
                    valor: averageVolumePerRouteActualValue,
                    valor_ajustado: averageVolumePerRouteProjectedValue !== "" ? averageVolumePerRouteProjectedValue : null
                },
                {
                    clave: paramsInfo.find(elemento => elemento.nombre === "Volumen mensual promedio por Cliente") || null,
                    valor: vpoActualValue,
                    valor_ajustado: vpoStateProjectedValue !== "" ? vpoStateProjectedValue : null,
                },
                {
                    clave: paramsInfo.find(elemento => elemento.nombre === "Precio Promedio") || null, // Precio promedio
                    valor: averagePriceActualValue,
                    valor_ajustado: null
                },
                {
                    clave: paramsInfo.find(elemento => elemento.nombre === "Clientes por Ruta") || null, // Clientes por ruta
                    valor: clientsPerRouteActualValue,
                    valor_ajustado: clientsPerRouteProjectedValue !== "" ? clientsPerRouteProjectedValue : null
                },
            ],
            filtros: {
                requeridos: requiredFilters?.map((param) => {
                    return {
                        label: param.label,
                        columna: param.columna,
                        valor: param.valor[0]
                    }
                }),
                adicionales: filters?.map((params) => {
                    return {
                        columna: params.column.label,
                        valor: params.values.map((values) => {
                            return values.value
                        })[0]
                    }
                }),
            }
        }
        simulationDispatch({
            type: "SET_CONFIGURATIONS", payload: [
                ...simulationState.configurations,
                newConfig
            ]
        });
        closeAndClean();
        setActualRoutes(0)
        setSelectedConfig([])
    }

    useEffect(() => {
        if (simulationState && dataGetRequiredFilters && dataGetAnalysisById && dataGetSimulationsInformation) {
            const resultados = dataGetSimulationsInformation.filter(item =>
                dataGetRequiredFilters.includes(item.nombre)
            );

            // Crear el formato deseado
            const resultadoFormateado = resultados.map(item => ({
                nombre: item.nombre,
                id: item.id,
            }));
            const principalColumns = simulationState.principalColumns.map(columna => {
                if (columna.is_medida && columna.columna) {
                    const medidaEncontrada = dataGetAnalysisById && buscarPorId(columna.columna, dataGetAnalysisById.medidas);
                    if (medidaEncontrada) {
                        return {
                            ...columna,
                            columna: { value: medidaEncontrada.id, label: medidaEncontrada.nombre },
                        };
                    }
                } else if (!columna.is_medida) {
                    const agrupationColumnEncontrada = dataGetAnalysisById && buscarPorLabel(columna.columna, dataGetAnalysisById.agrupacion.columnas_a_usar)
                    if (agrupationColumnEncontrada) {
                        return {
                            ...columna,
                            columna: { value: agrupationColumnEncontrada.value, label: agrupationColumnEncontrada.label },
                        };
                    }
                }
                return columna
            });
            resultadoFormateado.forEach((itemResultado) => {
                // Buscar el elemento correspondiente en principalColumns
                const elementoCorrespondiente = principalColumns.find((itemPrincipal) => itemPrincipal.informacion === itemResultado.id);

                // Si se encuentra, agregar la propiedad columna a resultadoFormateado
                if (elementoCorrespondiente) {
                    itemResultado.columna = elementoCorrespondiente.columna;
                }
            });
            setRequiredFiltersSchema(resultadoFormateado);
        }
    }, [simulationState, dataGetRequiredFilters, dataGetAnalysisById])

    return (
        <Dialog
            open={open}
            handleClose={handleConfirmationCloseModal}
            title={t("simulatorTexts.registerRoutes.newRouteModal.routeRegistration")}
            maxWidth={"lg"}
        >
            <SimpleBackdrop
                open={isLoadingGetParams || isLoadingGetActualRoutes || isLoadingDataGetRequiredFilters}
                message={t("simulatorTexts.registerRoutes.newRouteModal.loading")}
            />
            <CustomStepper activeStep={activeStep} data={labels} />
            <Stack
                direction="column"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                padding="0 30px 30px 30px"
                position="relative"
                sx={{
                    width: "100%",
                    maxHeight: "700px",
                    height: "100%",
                    pt: "20px",
                }}
            >
                <FormLayout
                    width={"100%"}
                    backgroundColor={
                        "white"
                    }
                >
                    {activeStep === 0 &&
                        <Step1NewRegisterRouteModal
                            filters={filters}
                            setFilters={setFilters}
                            requiredFilters={requiredFilters}
                            setRequiredFilters={setRequiredFilters}
                            dataGetAnalysisById={dataGetAnalysisById}
                            dataGetRequiredFilters={dataGetRequiredFilters && dataGetRequiredFilters}
                            noRoutesMessage={noRoutesMessage}
                            defaultCedis={simulationState && simulationState.configurations.length > 0 ? simulationState.configurations[0].filtros?.requeridos?.find(filter => filter.label === "Centro de Distribución")?.columna : undefined}
                            requiredFiltersSchema={requiredFiltersSchema}
                        />
                    }
                    {activeStep === 1 &&
                        <Step2NewRegisterRouteModal
                            averageVolumePerRouteAdjustment={averageVolumePerRouteAdjustment}
                            setAverageVolumePerRouteAdjustment={setAverageVolumePerRouteAdjustment}
                            vpoState={vpoStateAdjustment}
                            setVpoState={setVpoStateAdjustment}
                            clientsPerRoute={clientsPerRouteAdjustment}
                            setClientsPerRoute={setClientsPerRouteAdjustment}
                            volumenProjectionModeSelectedOptions={volumenProjectionModeSelectedOptions}
                            volumenProjectionModeSelectedChangeHandler={volumenProjectionModeSelectedChangeHandler}
                            averageVolumePerRouteProjectedValue={averageVolumePerRouteProjectedValue}
                            setAverageVolumePerRouteProjectedValue={setAverageVolumePerRouteProjectedValue}
                            vpoStateProjectedValue={vpoStateProjectedValue}
                            setVpoStateProjectedValue={setVpoStateProjectedValue}
                            clientsPerRouteProjectedValue={clientsPerRouteProjectedValue}
                            setClientsPerRouteProjectedValue={setClientsPerRouteProjectedValue}
                            dataGetParams={dataGetParams}
                            averageVolumePerRouteActualValue={averageVolumePerRouteActualValue}
                            vpoActualValue={vpoActualValue}
                            clientsPerRouteActualValue={clientsPerRouteActualValue}
                            rowToEdit={undefined}
                        />
                    }
                    {activeStep === 2 &&
                        <Step3NewRegisterRouteModal
                            newRoutes={newRoutes}
                            setNewRoutes={setNewRoutes}
                            balanceRoutes={balanceRoutes}
                            setBalanceRoutes={setBalanceRoutes}
                            actualRoutes={actualRoutes}
                        />
                    }
                </FormLayout>
            </Stack>
            <Stack sx={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
            }}>
                {activeStep !== 0 &&
                    <CustomButton
                        title={t("simulatorTexts.registerRoutes.newRouteModal.goBack")}
                        color="grey"
                        type="button"
                        onClick={handleBack}
                    />
                }
                {activeStep === 2 ?
                    <CustomButton
                        title={t("simulatorTexts.registerRoutes.newRouteModal.finish")}
                        color="blue-greeny"
                        type="button"
                        onClick={handleFinish}
                        disabled={newRoutes === 0}
                    /> :
                    <CustomButton
                        title={t("simulatorTexts.registerRoutes.newRouteModal.continue")}
                        color="blue"
                        type="button"
                        onClick={() => handleNext()}
                        disabled={validateContinueStepper()}
                    />
                }
            </Stack>
            <ConfirmationModal
                open={openConfirmationCloseModal}
                handleClose={() => setOpenConfirmationCloseModal(false)}
                handleAccept={closeAndClean}
                message={t("simulatorTexts.registerRoutes.newRouteModal.closeModalWithInfo")}
                title={t("simulatorTexts.registerRoutes.newRouteModal.routeRegistration")}
            />
        </Dialog>
    )
}

export default NewRegisterRouteModal