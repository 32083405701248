import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import {
  list_item_button_styles,
  list_item_icon_styles,
  list_item_selected_styles,
  list_item_text_styles,
} from "../../styles/app-styles";
import { isOptionSelected } from "../menu/utils";
import MenuItem from "../menu/MenuItem";
import useLanguage from "../../context/LanguageProvider";
import { useNavigate } from "react-router";

interface ChatBotMenuItemProps {
  open: boolean;
  handleDrawerClose: () => void;
}

const ChatBotMenuItem = ({ open, handleDrawerClose }: ChatBotMenuItemProps) => {
  const { t } = useLanguage();
  const navigate = useNavigate();

  const handleNavigate = () => {
    handleDrawerClose();
    navigate("/chatbot");
  };

  return (
    <Stack
      sx={{
        width: "100%",
        pb: "15px",
      }}
    >
      <ListItem
        onClick={handleNavigate}
        disablePadding
        sx={list_item_selected_styles(isOptionSelected("PHITO", t))}
      >
        <ListItemButton sx={list_item_button_styles(open)}>
          <ListItemIcon sx={list_item_icon_styles(open)}>
            <MenuItem
              MenuIcon={SmartToyIcon}
              title={"PHITO"}
              handleClickItemMenu={() => undefined}
            />
          </ListItemIcon>
          <ListItemText primary={"PHITO"} sx={list_item_text_styles(open)} />
        </ListItemButton>
      </ListItem>
    </Stack>
  );
};

export default ChatBotMenuItem;
