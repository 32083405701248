import { Grid, Stack, Typography } from "@mui/material";
import Dialog from "../../dialog/Dialog";
import FormSelectLayout from "../../forms/FormSelectLayout";
import { Button } from "../../buttons";
import Select from "../../forms/Select";
import useLanguage from "../../../context/LanguageProvider";
import useSingleSelect from "../../../hooks/useSingleSelect";
import { useEffect, useRef } from "react";
import { GET_ANALYSIS_URL, GET_COST_FORECAST_URL } from "../../../api/axios";
import useApi from "../../../hooks/useApi";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import InfoIcon from "@mui/icons-material/Info";
import { ConfirmationModal } from "../../dialog";
import useDialog from "../../../hooks/useDialog";
import { selectStyles } from "../../criterias/configCriteria/select.styles";

const quantityPeriodsToForecastOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
];
interface NewCostForecastModalProps {
  open: boolean;
  handleClose: () => void;
  dataGetAnalysis: any[];
  dataToShow: any;
  refetchCostsForecast: any;
  setDataToShow: (param: undefined) => void;
  dataGetCostForecast: any;
}

const NewCostForecastModal = ({
  open,
  handleClose,
  dataGetAnalysis,
  dataToShow,
  refetchCostsForecast,
  setDataToShow,
  dataGetCostForecast,
}: NewCostForecastModalProps) => {
  const { t } = useLanguage();
  const analysisRef: any = useRef(null);
  const periodToIniciateRef: any = useRef(null);
  const periodsToForecastRef: any = useRef(null);
  const configMode = localStorage.getItem("configMode") || undefined;
  const [
    openEditForecastConfirmationModal,
    handleToggleEditForecastConfirmationModal,
    setOpenEditForecastConfirmationModal,
  ] = useDialog();
  /********************** CARGA Y OBTENCIÓN DE DATOS *****************************/

  const [
    analysisSelectedOption,
    setAnalysisSelectedOptions,
    analysisOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    dataToShow && { value: dataToShow.analisis_id, label: dataToShow.analisis }
  );

  const [periodToIniciate, setPeriodToIniciate, periodToIniciateHandlerChange] =
    useSingleSelect(
      undefined,
      dataToShow && {
        value: dataToShow?.periodo_inicial,
        label: dataToShow?.periodo_inicial,
      }
    );

  const [
    periodsToForecast,
    setPeriodsToForecast,
    periodsToForecastHandlerChange,
  ] = useSingleSelect(
    undefined,
    dataToShow && {
      value: dataToShow?.cantidad_periodos,
      label: dataToShow?.cantidad_periodos,
    }
  );

  const onSuccessPostCostForecast = () => {
    closeAndClean();
    refetchCostsForecast();
  };

  const { isLoading: isLoadingPostCostForecast, callApi: postCostForecast } =
    useApi(
      "",
      "POST",
      t("delta.postCostForecast.codes"),
      undefined,
      onSuccessPostCostForecast,
      undefined,
      false
    );

  const { isLoading: isLoadingPutCostForecast, callApi: putCostForecast } =
    useApi(
      "",
      "PUT",
      t("delta.putCostForecast.codes"),
      undefined,
      onSuccessPostCostForecast,
      undefined,
      false
    );

  const {
    isLoading: isLoadingGetAnalysisById,
    data: dataGetAnalysisById,
    callApi: getAnalysisById,
  } = useApi(
    undefined,
    "GET",
    t("dataModel.getAnalysis.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if (analysisSelectedOption) {
      getAnalysisById(
        GET_ANALYSIS_URL(Number(analysisSelectedOption.value), true)
      );
    }
  }, [analysisSelectedOption]);

  useEffect(() => {
    dataToShow &&
      setAnalysisSelectedOptions({
        value: dataToShow.analisis_id,
        label: dataToShow.analisis,
      });
  }, [dataToShow]);

  useEffect(() => {
    dataToShow && dataToShow.periodo_inicial
      ? setPeriodToIniciate(dataToShow.periodo_inicial)
      : dataGetAnalysisById &&
        setPeriodToIniciate(dataGetAnalysisById.periodos_forecast[0]);
    dataToShow &&
      setPeriodsToForecast({
        value: dataToShow?.cantidad_periodos,
        label: dataToShow?.cantidad_periodos,
      });
  }, [dataToShow, dataGetAnalysisById]);

  /********************** MANEJO DE FUNCIONES *****************************/

  const closeAndClean = () => {
    localStorage.removeItem("configMode");
    handleClose();
    setOpenEditForecastConfirmationModal(false);
    analysisRef.current?.clearValue();
    setAnalysisSelectedOptions(undefined);
    periodToIniciateRef.current?.clearValue();
    setPeriodToIniciate(undefined);
    periodsToForecastRef.current?.clearValue();
    setPeriodsToForecast(undefined);
    setDataToShow(undefined);
  };

  const disabledAddButton = () => {
    if (!periodsToForecast || !analysisSelectedOption || !periodToIniciate) {
      return true;
    } else {
      return false;
    }
  };

  const handleFinish = () => {
    const newConfig = {
      analisis: analysisSelectedOption?.value,
      cantidad_periodos: periodsToForecast && periodsToForecast.value,
      periodo_inicial: periodToIniciate && periodToIniciate.value,
    };
    if (dataToShow) {
      putCostForecast(GET_COST_FORECAST_URL + dataToShow.id, newConfig);
    } else {
      postCostForecast(GET_COST_FORECAST_URL, newConfig);
    }
  };

  return (
    <Dialog
      open={open}
      handleClose={closeAndClean}
      title={`${dataToShow ? t("general.edit") : t("general.create")} ${t(
        "deltaTexts.costForecast.title"
      )}`}
      maxWidth="xl"
      actions={
        <>
          <Button
            title={t("simulatorTexts.assignVariabilityModal.cancel")}
            color="grey"
            type="button"
            onClick={closeAndClean}
          />
          <Button
            title={dataToShow ? t("general.edit") : t("general.create")}
            color="blue"
            type="button"
            disabled={disabledAddButton()}
            onClick={
              configMode === "EDIT"
                ? handleToggleEditForecastConfirmationModal
                : handleFinish
            }
          />
        </>
      }
    >
      <SimpleBackdrop
        open={
          isLoadingPostCostForecast ||
          isLoadingPutCostForecast ||
          isLoadingGetAnalysisById
        }
        message={t("general.loading")}
      />
      <Stack
        sx={{
          minWidth: "700px",
          minHeight: "350px",
          flexDirection: "column",
          gap: 2,
          p: "10px 30px 20px 30px",
        }}
      >
        <FormSelectLayout
          title={t("dataModelText.analysisExecution.analysis")}
          required
        >
          <Select
            reference={analysisRef}
            styles={selectStyles(analysisSelectedOption)}
            options={
              configMode === "EDIT"
                ? dataGetAnalysis &&
                  dataGetAnalysis.map((analysis) => {
                    return {
                      value: analysis.analisis_id,
                      label: analysis.analisis,
                    };
                  })
                : dataGetAnalysis &&
                  dataGetCostForecast &&
                  dataGetAnalysis
                    .map((analysis) => {
                      return {
                        value: analysis.analisis_id,
                        label: analysis.analisis,
                      };
                    })
                    .filter(
                      (objeto) =>
                        !dataGetCostForecast
                          .map((salesForecast) => salesForecast.analisis_id)
                          ?.includes(objeto.value)
                    )
            }
            form="cc_form"
            className="cc_select"
            name="análisis"
            onChange={analysisOptionsChangeHandler}
            placeholder={t(
              "dataModelText.groupManagement.step1.newBaseFile.chooseAnalysis"
            )}
            closeMenuOnSelect={true}
            defaultValue={
              dataToShow && {
                value: dataToShow.analisis_id,
                label: dataToShow.analisis,
              }
            }
            data-testid="select_column_component"
            isClearable
          />
        </FormSelectLayout>
        {!analysisSelectedOption && (
          <Stack
            sx={{
              width: "100%",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <InfoIcon color="primary" fontSize="medium" />
            <Typography
              style={{ color: "var(--text-main)", fontWeight: "600" }}
            >
              {t(
                "simulatorTexts.salesForecast.newSalesForecastModal.cannotRepitAnalysis"
              )}
            </Typography>
          </Stack>
        )}
        {analysisSelectedOption && dataGetAnalysisById && (
          <Grid
            container
            spacing={2}
            sx={{
              p: 2,
              pr: 3,
              pl: 3,
              height: "100%",
              width: "700px",
            }}
          >
            <Grid item xs={6}>
              <FormSelectLayout
                title={t(
                  "deltaTexts.salesForecast.newSalesForecastModal.initialPeriod"
                )}
                required
              >
                <Select
                  reference={periodToIniciateRef}
                  styles={selectStyles(periodToIniciate)}
                  options={
                    dataGetAnalysisById && dataGetAnalysisById.periodos_forecast
                  }
                  form="cc_form"
                  className="cc_select"
                  name="periods"
                  onChange={periodToIniciateHandlerChange}
                  placeholder={t(
                    "deltaTexts.salesForecast.newSalesForecastModal.initialPeriod"
                  )}
                  closeMenuOnSelect={true}
                  defaultValue={
                    dataToShow && dataToShow.periodo_inicial
                      ? dataToShow.periodo_inicial
                      : dataGetAnalysisById &&
                        dataGetAnalysisById.periodos_forecast[0]
                  }
                  data-testid="select_column_component"
                  isClearable
                />
              </FormSelectLayout>
            </Grid>
            <Grid item xs={6}>
              <FormSelectLayout
                title={t(
                  "deltaTexts.salesForecast.newSalesForecastModal.periodsToForecast"
                )}
                required
              >
                <Select
                  reference={periodsToForecastRef}
                  styles={selectStyles(periodsToForecast)}
                  options={quantityPeriodsToForecastOptions}
                  form="cc_form"
                  className="cc_select"
                  name="periods"
                  onChange={periodsToForecastHandlerChange}
                  placeholder={t(
                    "deltaTexts.salesForecast.newSalesForecastModal.choosePeriods"
                  )}
                  closeMenuOnSelect={true}
                  defaultValue={
                    dataToShow && {
                      value: dataToShow.cantidad_periodos,
                      label: dataToShow.cantidad_periodos,
                    }
                  }
                  data-testid="select_column_component"
                  isClearable
                />
              </FormSelectLayout>
            </Grid>
          </Grid>
        )}
      </Stack>
      <ConfirmationModal
        open={openEditForecastConfirmationModal}
        handleClose={handleToggleEditForecastConfirmationModal}
        handleAccept={handleFinish}
        message={t(
          "deltaTexts.costForecast.costForecastAdmin.editCostForecast"
        )}
        title={t("general.confirm")}
      >
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <InfoIcon color="primary" fontSize="medium" />
          <Typography style={{ color: "var(--text-main)", fontWeight: "600" }}>
            {t(
              "deltaTexts.salesForecast.salesForecastAdmin.deleteAssociatedSimulationsAndRules"
            )}
          </Typography>
        </Stack>
      </ConfirmationModal>
    </Dialog>
  );
};

export default NewCostForecastModal;
