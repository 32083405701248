import Button from "../../../buttons/Button";
import Dialog from "../../../dialog/Dialog";
import FormLayout from "../../../forms/FormLayout";
import KeyColumns from "../Shared/KeyColumns";
import MessageBlock from "../../../forms/MessageBlock";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import TypeModalContent from "../Step3/TypeModalContent";
import Typography from "@mui/material/Typography";
import UploadedFile from "../Shared/UploadedFile";
import useApi from "../../../../hooks/useApi";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import { COMPANY_PARAMETERS_FILE_URL } from "../../../../api/axios";
import { columnsSet, stepperData } from "../data";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect, useState } from "react";
import {
  form_layouts_container_styles,
  form_subtitle_styles,
  param_steps_container,
  step_title_styles,
} from "../../../../styles/app-styles";

const Step1 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    keyExpensesColumns,
    setKeyExpensesColumns,
    setIsStepComplete,
    setExpensesFileName,
    dataGetExpensesParams,
    isLoadingPostExpensesParams,
    getExpensesParams,
    refetchAllowedFileNames,
    allowedFileNames,
  } = useCompanyParamProvider();
  const [openAllowedFilesModal, toggleAllowedFilesModal] = useDialog();
  const [fileData, setFileData] = useState<any>();

  /********************************** Seteo inicial de variables *************************************/

  const onSuccessGetInitialFilesParams = (data: any) => {
    setFileData(data);
  };

  const { callApi: getInitialFilesParams } = useApi(
    COMPANY_PARAMETERS_FILE_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    onSuccessGetInitialFilesParams,
    undefined,
    false
  );

  useEffect(() => {
    setMessage({ type: "", text: "" });
    refetchAllowedFileNames();
    if (existentFile) {
      getInitialFilesParams(
        `${COMPANY_PARAMETERS_FILE_URL}/${existentFile.id}`
      );
    } else {
      setFileData({ nombre: "GASTOS" });
    }
    if (dataGetExpensesParams) {
      setExpensesFileName(
        dataGetExpensesParams?.nombre ? dataGetExpensesParams.nombre : "GASTOS"
      );
      setKeyExpensesColumns({
        monto_gasto: dataGetExpensesParams?.columnas_clave?.monto_gasto,
        bucket: dataGetExpensesParams?.columnas_clave?.bucket,
        agrupador: dataGetExpensesParams?.columnas_clave?.agrupador,
        casos_preprorrateo:
          dataGetExpensesParams?.columnas_clave?.casos_preprorrateo,
      });
    }
  }, [dataGetExpensesParams]);

  const handleToggleAllowedFilesModal = () => {
    refetchAllowedFileNames();
    getExpensesParams();
    toggleAllowedFilesModal();
  };

  const existentFile =
    allowedFileNames?.find(
      (file) => file?.nombre === dataGetExpensesParams?.nombre
    ) || undefined;

  /*********************************** Traer datos de tipado *******************************************/

  const onSuccessGetFilesParams = (data: any) => {
    setFileData(data);
    getExpensesParams();
    toggleAllowedFilesModal();
  };

  const { isLoading: isLoadingFilesParams, callApi: getFilesParams } = useApi(
    COMPANY_PARAMETERS_FILE_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    onSuccessGetFilesParams,
    undefined,
    false
  );

  const handleSetFileData = () => {
    if (existentFile) {
      getFilesParams(`${COMPANY_PARAMETERS_FILE_URL}/${existentFile.id}`);
    } else {
      setFileData({ nombre: "GASTOS" });
      toggleAllowedFilesModal();
    }
  };

  const refetchData = () => {
    getExpensesParams();
    refetchAllowedFileNames();
  };

  /************************************* Validación step completo **********************************/

  const isStep1Complete = () => {
    if (
      keyExpensesColumns.monto_gasto &&
      keyExpensesColumns.bucket &&
      keyExpensesColumns.agrupador
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isStep1Complete()) {
      setIsStepComplete((prev) => {
        return { ...prev, step1: true };
      });
    } else {
      setIsStepComplete((prev) => {
        return { ...prev, step1: false };
      });
    }
  }, [keyExpensesColumns]);

  const isFileColumnKey =
    dataGetExpensesParams &&
    dataGetExpensesParams.archivo &&
    dataGetExpensesParams.archivo.columnas &&
    dataGetExpensesParams.archivo.columnas.length;

  return (
    <Stack sx={param_steps_container}>
      <SimpleBackdrop
        open={isLoadingPostExpensesParams || isLoadingFilesParams}
        message={t("general.loading")}
      />
      <Typography sx={step_title_styles}>{stepperData(t).steps[1]}</Typography>
      <MessageBlock message={message} />
      <Stack sx={form_layouts_container_styles}>
        <FormLayout width="100%" padding="10px 25px 120px 25px">
          {dataGetExpensesParams?.nombre && (
            <Typography
              sx={{ ...form_subtitle_styles, margin: "15px 0 5px 0" }}
            >
              {dataGetExpensesParams?.nombre}
            </Typography>
          )}
          {dataGetExpensesParams?.descripcion && (
            <Typography
              sx={{
                ...form_subtitle_styles,
                fontSize: "15px",
                margin: 0,
              }}
            >
              {dataGetExpensesParams?.descripcion}
            </Typography>
          )}
          {isFileColumnKey && (
            <UploadedFile dataGetParams={dataGetExpensesParams} />
          )}
          <Stack width={"100%"} direction="row" justifyContent={"center"}>
            <Button
              title={t("Parametrizar archivo")}
              color="blue-greeny"
              type="button"
              onClick={handleSetFileData}
              width={200}
              disabled={false}
            />
          </Stack>
          {existentFile?.tipado && (
            <KeyColumns
              columnsSet={columnsSet}
              setKeyColumns={setKeyExpensesColumns}
              dataGetParams={dataGetExpensesParams}
              fileData={fileData}
            />
          )}
        </FormLayout>
      </Stack>
      <Dialog
        open={openAllowedFilesModal}
        handleClose={handleToggleAllowedFilesModal}
        title={t("dataManagementTexts.dataManagement.allowedFiles")}
        maxWidth="xl"
      >
        <Stack width={"1200px"}>
          <TypeModalContent
            fileData={fileData}
            setFileData={setFileData}
            handleClose={handleToggleAllowedFilesModal}
            dataFilesList={allowedFileNames}
            refetchData={refetchData}
            type="GASTOS"
          />
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default Step1;
