import { max_level_radiobutton_container } from "../../../../styles/app-styles";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect, useRef, useState } from "react";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import RadioButtonRow from "./RadioButtonRow";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import useSingleSelect from "../../../../hooks/useSingleSelect";

const MaxLevelDetail = ({ defaultValue, dataGetParams, fileData }) => {
  const { t } = useLanguage();
  const { setMaxLevelDetail } = useCompanyParamProvider();
  const columnSelectRef: any = useRef(null);
  const [selectedOption, setSelectedOption] = useState(
    defaultValue && defaultValue.length === 1
      ? "existentColumn"
      : defaultValue && defaultValue.length > 1
      ? "multipleColumns"
      : undefined
  );

  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionChangeHandler,
  ] = useSingleSelect(
    undefined,
    defaultValue && defaultValue.length === 1
      ? { value: defaultValue[0], label: defaultValue[0] }
      : undefined
  );

  const [
    columnSelectedOptions,
    setColumnSelectedOptions,
    columnOptionsChangeHandler,
  ] = useMultiSelect(
    defaultValue && defaultValue.length > 1
      ? defaultValue.map((value) => {
          return { value: value, label: value };
        })
      : undefined,
    undefined
  );

  useEffect(() => {
    if (columnSelectedOption) {
      setMaxLevelDetail(columnSelectedOption);
    } else if (columnSelectedOptions && columnSelectedOptions.length > 0) {
      setMaxLevelDetail(columnSelectedOptions);
    } else {
      setMaxLevelDetail(undefined);
    }
  }, [columnSelectedOption, columnSelectedOptions]);

  const onChangeInput = (e) => {
    setSelectedOption(e.target.id);
    setMaxLevelDetail(undefined);
    setColumnSelectedOption(undefined);
    setColumnSelectedOptions(undefined);
  };

  const columnsOptions = fileData && fileData.columnas
  ? fileData.columnas.map((item: any) => {
      return { value: item.id, label: item.nombre };
    })
  : []

  return (
    <FormSelectLayout title={t("Seleccionar el mayor nivel de detalle que se alcanzará en el modelo")} required={true}>
      <Stack sx={{ width: "100%", mt: "15px" }} gap={3}>
        <Stack
          sx={max_level_radiobutton_container}
        >
          <RadioButtonRow
            id={"existentColumn"}
            selectedOption={selectedOption}
            onChangeInput={onChangeInput}
            groupName={"maxLevelDetail"}
            labelText={t('companyParam.existentColumn')}
          />
          {selectedOption === "existentColumn" && (
            <Select
              reference={columnSelectRef}
              styles={selectStyles(columnSelectedOption)}
              options={columnsOptions}
              className="cc_select"
              onChange={columnOptionChangeHandler}
              closeMenuOnSelect
              placeholder={t("configCriteria.step1.selectColumn")}
              defaultValue={columnSelectedOption}
              isClearable
            />
          )}
        </Stack>
        <Stack
          sx={max_level_radiobutton_container}
        >
          <RadioButtonRow
            id={"multipleColumns"}
            selectedOption={selectedOption}
            onChangeInput={onChangeInput}
            groupName={"maxLevelDetail"}
            labelText={t('companyParam.multipleColumns')}
          />
          {selectedOption === "multipleColumns" && (
            <Select
              reference={columnSelectRef}
              styles={selectStyles(columnSelectedOptions)}
              options={columnsOptions}
              className="cc_select"
              onChange={columnOptionsChangeHandler}
              placeholder={t("configCriteria.step1.selectColumn")}
              defaultValue={columnSelectedOptions}
              isMulti
              isClearable
              closeMenuOnSelect={false}
            />
          )}
        </Stack>
      </Stack>
    </FormSelectLayout>
  );
};

export default MaxLevelDetail;
