import { Item } from "../../../basicGrid/BasicGrid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const DestinyCriteriaRowElement = ({ schemaRow }) => {
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      width="100%"
      gap={1}
    >
      <Item sx={{ width: "100%" }}>
        <Typography color={"primary"} sx={{ fontSize: "14px" }}>
          {schemaRow?.destino}
        </Typography>
      </Item>
      <Stack width={"100%"}>
        <Item sx={{ width: "100%" }}>
          <Typography color={"primary"} sx={{ fontSize: "14px" }}>
            {schemaRow?.criterio?.label || schemaRow?.porcentaje || "-"}
          </Typography>
        </Item>
      </Stack>
    </Stack>
  );
};

export default DestinyCriteriaRowElement;
