import { transformDataAdminData } from "../transformDataAdminData";
import { Typography } from "@mui/material";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useState } from "react";
import Button from "../../buttons/Button";
import CompanyForm from "./CompanyForm";
import Dialog from "../../dialog/Dialog";
import NoDataBox from "../../layout/NoDataBox";
import React from "react";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useApi from "../../../hooks/useApi";
import useAuth from "../../../hooks/useAuth";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import VirtualizatedTable from "../../virtualizatedTable/VirtualizedTable";
import {
  EDIT_ACTIVE_COMPANY_URL,
  GET_ALL_COMPANIES_ADMIN_URL,
} from "../../../api/axios";
import {
  active_inactive_typography_styles,
  mb_20_styles,
} from "../../../styles/app-styles";
interface CompaniesViewProps {
  setConfigOpen: React.Dispatch<React.SetStateAction<boolean>>;
  configOpen: boolean;
}

const CompaniesView = ({ setConfigOpen, configOpen }: CompaniesViewProps) => {
  const { t } = useLanguage();
  const { auth }: any = useAuth();
  const [configMode, setConfigMode] = useState<"CREATE" | "EDIT" | "COPY" | "">(
    ""
  );
  const [company, setCompany] = useState<any>();
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, handleToggleModal] = useDialog();

  const {
    data: dataCompanies,
    isSuccess: isSuccesCompanies,
    isLoading: isLoadingAllCompanies,
    refetch: refetchCompanies,
  } = useApiQuery(GET_ALL_COMPANIES_ADMIN_URL, true);  

  const handleAdd = () => {
    setCompany(undefined);
    setConfigMode("CREATE");
    setConfigOpen(true);
  };

  const handleFinish = () => {
    setConfigOpen(false);
    refetchCompanies();
    setConfigMode("");
    window.location.reload();
  };

  const handleEdit = () => {
    setConfigMode("EDIT");
    setCompany(
      dataCompanies?.find((company: any) => company.id === selectedRows[0])
    );
    setConfigOpen(true);
  };

  const handleCopy = () => {
    setConfigMode("COPY");
    setCompany(
      dataCompanies?.find((company: any) => company.id === selectedRows[0])
    );
    setConfigOpen(true);
  };

  const isData = isSuccesCompanies && !isLoadingAllCompanies;

  const optional_columns = [
    "nombre",
    "nombre_visual",
    "habilitada",
    "compania",
    "usuarios_habilitados"
  ];

  const transform_column_names = (column: string) => {
    switch (column) {
      case "nombre":
        return t("administratorPanel.users.name");
      case "nombre_visual":
        return t("Nombre visual");
      case "habilitada":
        return t("Habilitada");
      case "compania":
        return t("Compañía");
      case "usuarios_habilitados":
        return t("Usuarios habilitados");
      default:
        return column;
    }
  };

  const onSuccessPutState = () => {
    refetchCompanies();
  };

  const { isLoading: isLoadingPutActive, callApi: putActive } = useApi(
    "",
    "PUT",
    t("administratorPanel.editUser.codes"),
    undefined,
    onSuccessPutState,
    undefined,
    false
  );

  const handleChangeSwitch = (params) => {
    const row = params.row;
    if (auth.empresa !== row.id) {
      putActive(EDIT_ACTIVE_COMPANY_URL(row.id), {
        habilitada: !row.habilitada,
      });
    } else {
      handleToggleModal();
    }
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingAllCompanies}
        message={t("administratorPanel.companies.loadingCompanies")}
      />
      <SimpleBackdrop
        open={isLoadingPutActive}
        message={t("general.loading")}
      />
      {!configOpen ? (
        <Stack spacing={2} sx={{ width: "100%", marginTop: "0px !important" }}>
          <Stack
            direction="row"
            width={"100%"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={active_inactive_typography_styles}>
              {t("administratorPanel.companies.activeCompanies")}
            </Typography>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Button
                title={t("Copiar")}
                color="blue"
                type="button"
                onClick={handleCopy}
                disabled={!selectedRows || selectedRows.length !== 1}
                margin={false}
              />
              <Button
                title={t("Editar")}
                color="blue"
                type="button"
                onClick={handleEdit}
                disabled={!selectedRows || selectedRows.length !== 1}
                margin={false}
              />
              <Button
                title={t("Crear")}
                color="blue-greeny"
                type="button"
                onClick={handleAdd}
                disabled={false}
                margin={false}
              />
            </Stack>
          </Stack>
          <Stack sx={mb_20_styles}>
            {isData &&
            dataCompanies.filter((company: any) => company.habilitada).length ===
              0 ? (
              <NoDataBox
                text={t("administratorPanel.companies.noActiveCompanies")}
              />
            ) : (
              isData && (
                <Stack sx={{ width: "100%" }}>
                  {dataCompanies && dataCompanies.length > 0 && (
                    <VirtualizatedTable
                      columns={
                        transformDataAdminData(
                          t,
                          dataCompanies.filter(
                            (company: any) => company.habilitada
                          ),
                          optional_columns,
                          transform_column_names,
                          handleChangeSwitch
                        ).columns
                      }
                      rows={
                        transformDataAdminData(
                          t,
                          dataCompanies.filter( (company: any) => company.habilitada),
                          optional_columns,
                          transform_column_names,
                          handleChangeSwitch
                        ).rows
                      }
                      rowsPerPageOptions={[5, 10, 20]}
                      rowHeight={35}
                      tableHeight={"400px"}
                      hideFooter={true}
                      toolbar={true}
                      columnsButton={true}
                      filterButton={true}
                      densitySelector={true}
                      exportButton={false}
                      saveViewButton={false}
                      setSelectedRows={setSelectedRows}
                      selectedRows={selectedRows}
                    />
                  )}
                </Stack>
              )
            )}
          </Stack>
          <Typography sx={active_inactive_typography_styles}>
            {t("administratorPanel.companies.inactiveCompanies")}
          </Typography>
          <Stack sx={mb_20_styles}>
            {isData &&
            dataCompanies.filter((company: any) => !company.habilitada)
              .length === 0 ? (
              <NoDataBox
                text={t("administratorPanel.companies.noInactiveCompanies")}
              />
            ) : (
              isData && (
                <Stack sx={{ width: "100%" }}>
                  {dataCompanies && dataCompanies.length > 0 && (
                    <VirtualizatedTable
                      columns={
                        transformDataAdminData(
                          t,
                          dataCompanies.filter(
                            (company: any) => !company.habilitada
                          ),
                          optional_columns,
                          transform_column_names,
                          handleChangeSwitch
                        ).columns
                      }
                      rows={
                        transformDataAdminData(
                          t,
                          dataCompanies.filter( (company: any) => !company.habilitada),
                          optional_columns,
                          transform_column_names,
                          handleChangeSwitch
                        ).rows
                      }
                      rowsPerPageOptions={[5, 10, 20]}
                      rowHeight={35}
                      tableHeight={"400px"}
                      hideFooter={true}
                      toolbar={true}
                      columnsButton={true}
                      filterButton={true}
                      densitySelector={true}
                      exportButton={false}
                      saveViewButton={false}
                      setSelectedRows={setSelectedRows}
                      checkboxSelection={false}
                    />
                  )}
                </Stack>
              )
            )}
          </Stack>
        </Stack>
      ) : (
        <CompanyForm
          configMode={configMode}
          company={company}
          handleFinish={handleFinish}
        />
      )}
      <Dialog
        open={open}
        handleClose={handleToggleModal}
        title={t("Deshabilitar empresa")}
      >
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          maxWidth="470px"
        >
          <Typography
            sx={{
              color: "var(--text-main)",
              fontSize: "18px",
              marginTop: "30px",
              maxWidth: "440px",
              textAlign: "center",
            }}
          >
            {t("No es posible deshabilitar tu propia empresa. Sólo otro usuario administrador puede realizar esta acción.")}
          </Typography>
          <Stack direction="row" sx={{ marginTop: "25px" }}>
            <Button
              title={t("general.accept")}
              type="button"
              onClick={handleToggleModal}
              color="blue-greeny"
            />
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default CompaniesView;
