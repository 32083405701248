import { ExternalRowProps } from "./types";
import Delete from "../../icons/Delete";
import Divider from "@mui/material/Divider";

const ExternalRow = ({
  id,
  externalColumn,
  columnFile,
  handleDeleteFilter,
  filters,
  setFilters,
}: ExternalRowProps) => { 

  return (
    <>
      <Divider sx={{ width: "90%" }} />
      <div className="cc_external_row_container">
        <div className="cc_external_column_1">
          <Delete
            tooltipPlacement="bottom"
            onClick={() => handleDeleteFilter(id, filters, setFilters)}
          />
        </div>
        <div className="cc_external_column_2">{externalColumn.label}</div>
        <div className="cc_external_column_3">{columnFile.label}</div>
        <div className="cc_external_column_4"></div>
      </div>
    </>
  );
};

export default ExternalRow;
