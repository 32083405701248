import { getBackgroundColor, isValidPercentage } from "../utils";
import { grid_item_element_styles, white_shadow_box_styles } from "../../../../styles/app-styles";
import { useEffect, useState } from "react";
import Delete from "../../../icons/Delete";
import Edit from "../../../icons/Edit";
import IterationExplicitNoMatchElement from "./IterationExplicitNoMatchElement";
import IterationExplicitZeroElement from "./IterationExplicitZeroElement";
import IterationImplicitNoMatchElement from "./IterationImplicitNoMatchElement";
import IterationImplicitZeroElement from "./IterationImplicitZeroElement";
import MessageBlock from "../../../forms/MessageBlock";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";
import WhiteShadowBox from "../../../layout/WhiteShadowBox";

interface IterationElementProps {
  iteration: any;
  deleteIteration?: any;
  setAreIterationErrors?: any;
  setIterationToEdit?: any;
  tipo_preprorrateo: any;
}

const IterationElement = ({
  iteration,
  deleteIteration,
  setAreIterationErrors,
  setIterationToEdit,
  tipo_preprorrateo,
}: IterationElementProps) => {
  const { t } = useLanguage();
  const [errorState, setErrorState] = useState({ isValid: true, message: "" });
  const isExplicit = tipo_preprorrateo === "EXPLICITA";

  const isValidNewCriteria = () => {
    if (iteration?.sin_match?.caso === "CRITERIO_NUEVO") {
      if (
        iteration.sin_match?.criterios_destinos?.some(
          (criterio_destino) => !criterio_destino.criterio
        )
      ) {
        return {
          isValid: false,
          message: "Debe seleccionar un criterio para el destino",
        };
      }
    }
    if (iteration?.criterio_cero?.caso === "CRITERIO_NUEVO") {
      if (
        iteration.criterio_cero?.criterios_destinos?.some(
          (criterio_destino) => !criterio_destino.criterio
        )
      ) {
        return {
          isValid: false,
          message: "Debe seleccionar un criterio para el destino",
        };
      }
    }
    return { isValid: true, message: "" };
  };

  const isValidModifyCriteria = () => {
    if (iteration?.sin_match?.caso === "ELIMINAR") {
      if (iteration?.tipo_preprorrateo === "EXPLICITA") {
        if (
          !iteration?.sin_match?.criterios_destinos?.every((criterio_destino) =>
            criterio_destino?.validaciones_conservar?.every((v) => v?.criterio)
          )
        ) {
          return {
            isValid: false,
            message: "Revisar validaciones a incluir",
          };
        }
      } else if (iteration?.tipo_preprorrateo === "IMPLICITA") {
        if (
          !iteration.sin_match?.criterio_receptor?.validaciones_conservar?.every(
            (v) => v?.criterio
          )
        ) {
          return {
            isValid: false,
            message: "Revisar validaciones a incluir",
          };
        }
      }
    }

    if (iteration?.criterio_cero?.caso === "ELIMINAR") {
      if (iteration?.tipo_preprorrateo === "EXPLICITA") {
        if (
          !iteration.criterio_cero?.criterios_destinos?.every(
            (criterio_destino) =>
              criterio_destino?.validaciones_conservar?.every(
                (v) => v?.criterio
              )
          )
        ) {
          return {
            isValid: false,
            message: "Revisar validaciones a incluir",
          };
        }
      } else if (iteration?.tipo_preprorrateo === "IMPLICITA") {
        if (
          !iteration.criterio_cero?.criterio_receptor?.validaciones_conservar?.every(
            (v) => v?.criterio
          )
        ) {
          return {
            isValid: false,
            message: "Revisar validaciones a incluir",
          };
        }
      }
    }
    return { isValid: true, message: "" };
  };

  const isValidPercentages = () => {
    return (
      isValidPercentage(t, iteration?.sin_match?.porcentaje).isValid &&
      isValidPercentage(t, iteration?.criterio_cero?.porcentaje).isValid
    );
  };

  // Efecto para manejar errores
  useEffect(() => {
    const areValidNewCriteria = isValidNewCriteria().isValid;
    const areValidModifyCriteria = isValidModifyCriteria().isValid;
    const arePercentagesValid = isValidPercentages();

    let message = "";
    if (!areValidNewCriteria) {
      message = isValidNewCriteria().message;
    } else if (!areValidModifyCriteria) {
      message = isValidModifyCriteria().message;
    } else if (
      !isValidPercentage(t, iteration?.sin_match?.porcentaje).isValid
    ) {
      message = isValidPercentage(t, iteration?.sin_match?.porcentaje).message;
    } else if (
      !isValidPercentage(t, iteration?.criterio_cero?.porcentaje).isValid
    ) {
      message = isValidPercentage(
        t,
        iteration?.criterio_cero?.porcentaje
      ).message;
    }

    const theAreErrors =
      !arePercentagesValid || !areValidNewCriteria || !areValidModifyCriteria;
    setErrorState({ isValid: theAreErrors, message: message });
    setAreIterationErrors && setAreIterationErrors(theAreErrors);
  }, [iteration]);

  return (
    <WhiteShadowBox
      sx={{
        ...white_shadow_box_styles,
        margin: 0,
        border: errorState.isValid ? "4px var(--bg-error) solid" : "none",
      }}
    >
      <MessageBlock message={{ type: "error", text: errorState.message }} />
      <Stack
        sx={{
          width: "100%",
          justifyContent: "flex-start",
          height: "100%",
          minHeight: "150px",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            ...grid_item_element_styles(
              getBackgroundColor,
              "iteration",
              () => "100%"
            ),
            color: "white",
            minHeight: "30px",
            borderRadius: "4px",
            gap: 1,
          }}
        >
          Iteración {iteration?.iteracion}
          {setIterationToEdit && <Edit onClick={() => setIterationToEdit(iteration)} fill={"white"} />}
          {deleteIteration && <Delete
            onClick={() => deleteIteration(iteration.iteracion)}
            fill={"white"}
            tooltipText={"Eliminar iteración"}
          />}
        </Stack>

        {isExplicit ? (
          <Stack width="100%" direction="row" gap={2}>
            <IterationExplicitNoMatchElement iteration={iteration} />
            <IterationExplicitZeroElement iteration={iteration} />
          </Stack>
        ) : (
          <Stack width="100%" direction="row" gap={2}>
            <IterationImplicitNoMatchElement iteration={iteration} />
            <IterationImplicitZeroElement iteration={iteration} />
          </Stack>
        )}
      </Stack>
    </WhiteShadowBox>
  );
};

export default IterationElement;
