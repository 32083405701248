import { makeStyles } from "@mui/styles";
import { DOWNLOAD_FILES_URL } from "../../api/axios";
import { FileType } from "./types";
/* eslint-disable no-useless-escape */

export const useStyles : any = makeStyles({
  icon: {
    color: "var(--bg-main)",
    marginRight: "0.5rem",
    animation: "$colorChange 2s linear infinite",
  },
  "@keyframes colorChange": {
    "0%": {
      color: "var(--bg-main)",
    },
    "25%": {
      color: "var(--bg-secondary)",
    },
    "50%": {
      color: "var(--bg-disabled)",
    },
    "75%": {
      color: "var(--bg-secondary)",
    },
    "100%": {
      color: "var(--bg-main)",
    },
  },
});

export const downloadByType = (
  type: "FILE" | "PERIOD" | "COLUMN" | "ALL",
  data: any,
  downloadSingleFile: any,
  downloadFile: any,
  toggleDownloadFileModal: any
) => {
  switch (type) {
    case "FILE":
      downloadSingleFile(
        DOWNLOAD_FILES_URL({
          archivo: data.dato.id,
          periodo: data.periodo.periodo_id,
        }),
        undefined,
        {
          fileName: `${data.dato.nombre}-${data.periodo.periodo}.zip`,
          fileType: "aplication/zip",
        }
      );
      break;
    case "PERIOD":
      downloadFile(
        DOWNLOAD_FILES_URL({
          archivo: undefined,
          periodo: data.periodo_id,
        }),
        undefined,
        {
          fileName: `${data.periodo}.zip`,
          fileType: "aplication/zip",
        }
      );
      break;
    case "COLUMN":
      downloadFile(
        DOWNLOAD_FILES_URL({
          archivo: data,
          periodo: undefined,
        }),
        undefined,
        {
          fileName: `${data}.zip`,
          fileType: "aplication/zip",
        }
      );
      break;
    case "ALL":
      downloadFile(
        DOWNLOAD_FILES_URL({
          archivo: undefined,
          periodo: undefined,
        }),
        undefined,
        {
          fileName: `archivos-Phi.zip`,
          fileType: "aplication/zip",
        }
      );
      break;
    default:
      toggleDownloadFileModal();
      break;
  }
};

/**
 * Comprueba si un archivo está en una lista de archivos verificando coincidencias
 * en el periodo_id y el nombre del archivo.
 *
 * @param {Object} file - El archivo que se desea verificar.
 * @param {Array} list - La lista de archivos en la que se realizará la comprobación.
 * @returns {boolean} True si el archivo está en la lista; de lo contrario, false.
 */

export const checkFileInList = (file: any, list: any) => {
  if (!file || !list) return false;
  const totalNamesList: any[] = [];
  list.map((p: any) => {
    p.archivos.map((f: any) => totalNamesList.push(`${f.nombre}-${p.periodo}`));
  });
  const fileInList = totalNamesList.includes(
    file?.name.split(".")[0].toUpperCase()
  );
  return fileInList;
};

/**
 * Comprueba si un archivo está en una lista de archivos hasta un índice específico
 * y verifica si el nombre del archivo coincide con los archivos anteriores en la lista.
 *
 * @param {Object} file - El archivo que se desea verificar.
 * @param {number} index - El índice hasta el cual se debe buscar en la lista de archivos.
 * @param {Array} list - La lista de archivos en la que se realizará la comprobación.
 * @param {boolean} repeatedFiles - Indica si se han encontrado archivos repetidos.
 * @param {function(boolean): void} setRepeatedFiles - Una función para establecer el estado de archivos repetidos.
 * @returns {boolean} True si el archivo está en la lista y tiene el mismo nombre que un archivo anterior; de lo contrario, false.
 */

export const checkFileInUploadList = (
  file: File | null,
  index: number,
  list: Array<File>,
  repeatedFiles?: boolean,
  setRepeatedFiles?: (value: boolean) => void
) => {
  if (!file || !list) {
    return false;
  }
  const fileName = file.name.split(".")[0];
  const fileInList =
    list.slice(0, index).length > 0 &&
    list.slice(0, index).find((f: any) => {
      const fName = f.name.split(".")[0];
      const isSameName = fName === fileName;
      return isSameName;
    }) !== undefined;
  if (fileInList && !repeatedFiles) {
    setRepeatedFiles && setRepeatedFiles(true);
  }
  return fileInList;
};

/**
 * Comprueba si hay archivos duplicados en la lista de archivos para cargar.
 *
 * @param {Array<File> | null} filesToUpload - La lista de archivos para verificar.
 * @returns {boolean} True si se encuentran archivos duplicados en la lista; de lo contrario, false.
 */

export const checkDuplicatedFiles = (
  filesToUpload: Array<File> | null
): boolean => {
  if (filesToUpload) {
    filesToUpload.forEach((fileToUpload: any) => {
      const repeatedFile = filesToUpload.find((f: any) => {
        f.name.split(".")[0] === fileToUpload.name.split(".")[0];
      });
      if (repeatedFile) return true;
    });
    return false;
  } else {
    return false;
  }
};

/**
 * Obtiene el nombre del archivo (sin extensión) a partir de una cadena que contiene el nombre de archivo completo.
 *
 * @param {string} str - La cadena que contiene el nombre de archivo completo.
 * @returns {string} El nombre del archivo (sin extensión).
 */

export const getFileName = (str: string) => {
  let fileName = "";

  if (str.endsWith(".csv")) {
    // Verifica si la cadena termina con ".csv" y ajusta el nombre en consecuencia.
    fileName = str.slice(0, -12);
  } else if (str.endsWith(".parquet")) {
    // Verifica si la cadena termina con ".parquet" y ajusta el nombre en consecuencia.
    fileName = str.slice(0, -16);
  }
  return fileName;
};

/**
 * Comprueba si la fecha en el nombre de archivo cumple con un formato válido.
 *
 * @param {string} str - La cadena que contiene el nombre de archivo con la fecha.
 * @returns {boolean} True si la fecha en el nombre de archivo tiene un formato válido; de lo contrario, false.
 */

export const isDateFormatCorrect = (str: string) => {
  let fileEnd = "";

  // Verifica si la cadena termina con ".csv" y ajusta la extracción de la fecha en consecuencia.
  if (str.endsWith(".csv")) {
    fileEnd = str.slice(-11);
    // Verifica si la cadena termina con ".parquet" y ajusta la extracción de la fecha en consecuencia.
  } else if (str.endsWith(".parquet")) {
    fileEnd = str.slice(-15);
  }
  // Divide la parte de la fecha del nombre de archivo y verifica si sigue un formato válido.
  const date = fileEnd.split(".")[0];
  const validFormatRegExp = new RegExp(/^(0[1-9]|1[0-2])\-?([0-9]{4})$/gm);
  return validFormatRegExp.test(date);
};

/**
 * Verifica si los archivos que se van a cargar son permitidos y tienen nombres y formatos de fecha válidos.
 *
 * @param {Array<File> | null} filesToUpload - La lista de archivos que se van a cargar.
 * @param {Array<FileType> | undefined} allowedFileNames - La lista de nombres de archivos permitidos.
 * @param {function(boolean): void} setNotAllowedFiles - Una función para establecer el estado de archivos no permitidos.
 */

export const checkNotAllowedFiles = (
  filesToUpload: Array<File> | null,
  allowedFileNames: Array<FileType> | undefined,
  setNotAllowedFiles: (value: boolean) => void
) => {
  const allowedFileNamesList = allowedFileNames?.map(
    (file: FileType) => file.nombre
  );
  if (filesToUpload) {
    const notAllowedFiles = filesToUpload.filter(
      (file: any) =>
        !allowedFileNamesList?.includes(getFileName(file.name)) ||
        !isDateFormatCorrect(file.name)
    );
    if (notAllowedFiles.length > 0) {
      setNotAllowedFiles(true);
    } else {
      setNotAllowedFiles(false);
    }
  }
};

/**
 * Verifica si hay archivos repetidos en la lista de archivos para cargar.
 *
 * @param {any} filesToUpload - La lista de archivos que se van a cargar.
 * @param {Array<FileType> | undefined} allowedFileNames - La lista de nombres de archivos permitidos.
 * @param {boolean} repeatedFiles - Indica si ya se han encontrado archivos repetidos.
 * @param {function(boolean): void} setRepeatedFiles - Una función para establecer el estado de archivos repetidos.
 * @param {any} dataRowsFiles - Los datos de archivos ya cargados.
 * @returns {boolean} True si se encuentran archivos repetidos en la lista; de lo contrario, false.
 */

export const hasRepeatedFiles = (
  filesToUpload: any,
  allowedFileNames: Array<FileType> | undefined,
  repeatedFiles: boolean,
  setRepeatedFiles: (value: boolean) => void,
  dataRowsFiles: any
) => {
  const hasRepeated = filesToUpload?.some((file) => {
    const fileName = getFileName(file.name);

    // Verifica si el nombre del archivo es válido y si el formato de fecha es correcto.
    const condition1 =
      allowedFileNames
        ?.map((fileName) => fileName.nombre)
        ?.includes(fileName) && isDateFormatCorrect(file.name);

    // Verifica si el archivo actual está duplicado en la lista de archivos que se están cargando.
    const condition2 = checkFileInUploadList(
      file,
      filesToUpload.indexOf(file) + 1,
      filesToUpload,
    );

    // Verifica si el archivo actual ya existe en la lista dataRowsFiles.
    const condition3 = checkFileInList(file, dataRowsFiles);

    return condition1 && condition2 && condition3;
  });

  return hasRepeated;
};
