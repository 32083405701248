import { createContext, useContext, useEffect, useState } from "react";
import { Filter } from "../components/criterias/configCriteria/types";
import { Tab } from "../components/colorTabs/types";
import useDialog from "../hooks/useDialog";
import useTabs from "../hooks/useTabs";

const ViewDashboardContext = createContext<any>({});

const ViewDashboardProvider: any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [navegationMode, setNavegationMode] = useState<
    "normalNavigation" | "interactiveNavigation"
  >("normalNavigation");

  const [ categoricsColumns, setCategoricsColumns ] = useState<string[]>([])

  const newElementConfig =
    localStorage.getItem("newElementConfig") &&
    JSON.parse(String(localStorage.getItem("newElementConfig")));
  const [selectedPeriods, setSelectedPeriods] = useState<number[]>([]);
  const [columnSelectKey, setColumnSelectKey] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openNewViewModal, toggleNewViewModal] = useDialog();
  const [openDeleteViewModal, toggleDeleteViewModal] = useDialog();
  const [openNewElementModal, toggleNewElementModal] = useDialog();

  const [tabValue, handleChange, setTabValue] = useTabs("SIN ANALISIS");
  const [tabs, setTabs] = useState<Tab[]>([
    { value: "SIN ANALISIS", label: "SIN ANALISIS" },
  ]);

  const previousUrl = localStorage.getItem("previousUrl");

  const [viewTabValue, viewHandleChange, setViewTabValue] = useTabs(
    newElementConfig && previousUrl ? newElementConfig.viewTabValue : "DEFAULT"
  );

  const [viewTabs, setViewTabs] = useState<Tab[]>([
    { value: "DEFAULT", label: "DEFAULT" },
  ]);

  const [agrupationId, setAgrupationId] = useState<any>("");

  const [filters, setFilters] = useState<any>({});
  const [tempFilters, setTempFilters] = useState<Filter[] | undefined>([]);

  const [elementToEdit, setElementToEdit] = useState<any>();

  // se usa para todos los isLoading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, setData] = useState<any>([]);
  const [analysisViews, setAnalysisViews] = useState<any>({
    isLoading: false,
    data: [],
    refetch: () => <></>,
  });

  const [analysisPeriods, setAnalysisPeriods] = useState<any>({
    isLoading: false,
    data: [],
    getData: () => <></>,
  });

  const [agrupations, setAgrupations] = useState<any>({
    isLoading: false,
    data: [],
  });

  useEffect(() => {
    setIsLoading(
      analysisViews?.isLoading ||
        analysisPeriods?.isLoading ||
        agrupations?.isLoading
    );
  }, [
    analysisViews?.isLoading,
    analysisPeriods?.isLoading,
    agrupations?.isLoading,
  ]);

  useEffect(() => {
    if (newElementConfig) {
      setViewTabValue(newElementConfig.viewTabValue);
    } else {
      setViewTabValue("DEFAULT");
    }
  }, [tabValue]);

  useEffect(() => {
    if (analysisViews && analysisViews.data && analysisViews.data.length > 0) {
      const filteredTabValue = analysisViews.data.find(
        (analysis: any) => analysis.id === tabValue
      );
      const viewIds =
        filteredTabValue &&
        filteredTabValue?.vistas?.length > 0 &&
        filteredTabValue?.vistas.map((view: any) => view.id);

      if (viewIds && viewIds.length > 0 && !viewIds.includes(viewTabValue)) {
        localStorage.removeItem("newElementConfig");
        setViewTabValue("DEFAULT");
      }
    }
  }, [analysisViews, tabValue, viewTabValue]);

  return (
    <ViewDashboardContext.Provider
      value={{
        isLoading,
        setIsLoading,
        data,
        setData,
        analysisViews,
        setAnalysisViews,
        selectedPeriods,
        setSelectedPeriods,
        columnSelectKey,
        setColumnSelectKey,
        selectedOptions,
        setSelectedOptions,
        openFilterModal,
        setOpenFilterModal,
        tabValue,
        handleChange,
        setTabValue,
        tabs,
        setTabs,
        viewTabValue,
        viewHandleChange,
        setViewTabValue,
        viewTabs,
        setViewTabs,
        analysisPeriods,
        setAnalysisPeriods,
        openNewViewModal,
        toggleNewViewModal,
        agrupationId,
        setAgrupationId,
        openNewElementModal,
        toggleNewElementModal,
        agrupations,
        setAgrupations,
        filters,
        setFilters,
        elementToEdit,
        setElementToEdit,
        openDeleteViewModal,
        toggleDeleteViewModal,
        tempFilters,
        setTempFilters,
        navegationMode,
        setNavegationMode,
        categoricsColumns,
        setCategoricsColumns
      }}
    >
      {children}
    </ViewDashboardContext.Provider>
  );
};

export default ViewDashboardProvider;

export const useViewDashboard = (): any => {
  const context = useContext(ViewDashboardContext);
  if (!context) {
    throw new Error(
      "useViewDashboard must be used within a ViewDashboardProvider"
    );
  }
  return context;
};
