import { Stack, Typography } from "@mui/material";
import useLanguage from "../../../../../../context/LanguageProvider";
import useSingleSelect from "../../../../../../hooks/useSingleSelect";
import { useRef, useState, useEffect, useContext } from "react";
import { IdOption } from "../../../../../criterias/configCriteria/types";
import useApi from "../../../../../../hooks/useApi";
import { INCOMING_VALUES_SALES_FORECAST } from "../../../../../../api/axios";
import FormSelectLayout from "../../../../../forms/FormSelectLayout";
import VirtualSelect from "../../../../../criterias/configCriteria/VirtualSelect";
import FilterForm from "../../../../../dataModel/downloadReport/FilterForm";
import SimulationProvider from "../../../../../../context/SimulationProvider";
import ValueFilterColumnSelect from "../../../../ValueFilterColumnSelect";
interface Step1NewCancelRouteModal {
    cancelFilters: any,
    setCancelFilters: any,
    requiredCancelFilters: any,
    setRequiredCancelFilters: any,
    dataGetAnalysisById: any;
    dataGetRequiredFilters: any;
    defaultCedis: string | undefined,
    requiredFiltersSchema: any
}

const Step1NewCancelRouteModal = ({ cancelFilters, setCancelFilters, requiredCancelFilters, setRequiredCancelFilters, dataGetAnalysisById, dataGetRequiredFilters, defaultCedis, requiredFiltersSchema }: Step1NewCancelRouteModal) => {

    const { t } = useLanguage();
    const analysisId = localStorage.getItem("analysisId") ? localStorage.getItem("analysisId") : undefined
    const { simulationState } = useContext<React.ContextType<typeof SimulationProvider>>(SimulationProvider);
    const columnSelectRef: any = useRef(null);
    const [valuesSelectedOptions, setValuesSelectedOptions] = useState<IdOption[]>();

    /***************************** CARGA Y OBTENCIÓN DE DATOS **************************/

    const onColumnChange = () => {
        setSelectedValue([]);
        setValuesSelectedOptions(undefined);
    };

    const [
        columnFilterSelectedOptions,
        setColumnFilterSelectedOptions,
        columnFilterOptionsChangeHandler,
    ] = useSingleSelect(() => onColumnChange());

    const [incomingValuesOptions, setIncomingValuesOptions] = useState<any[]>([]);
    const [selectedValue, setSelectedValue] = useState<string[] | null>([]);
    const [isLoadingIncomingValuesOptions, setIsLoadingIncomingValuesOptions] =
        useState(false);
    const onSuccessIncomingValuesOptionsResponse = (data: any) => {
        setIncomingValuesOptions(data?.valores)
        setIsLoadingIncomingValuesOptions(false);
    }

    const {
        callApi: postGetIncomingValues,
    } = useApi(
        "",
        "POST",
        undefined,
        undefined,
        onSuccessIncomingValuesOptionsResponse,
        undefined,
        false
    );

    useEffect(() => {
        if (columnFilterSelectedOptions && simulationState) {
            setIsLoadingIncomingValuesOptions(true);
            const filtros = {};
            cancelFilters && cancelFilters.forEach(item => {
                const { column, values } = item;
                filtros[column.label] = values.map((value) => value.value);
            });

            requiredCancelFilters && requiredCancelFilters.forEach(item => {
                const { columna, valor } = item;
                filtros[columna] = valor;
            });
            const filterBody = {
                filtros,
                columna: columnFilterSelectedOptions?.label
            }
            postGetIncomingValues(INCOMING_VALUES_SALES_FORECAST(simulationState.salesForecast.id && simulationState.salesForecast.id), filterBody);
        } else {
            setIncomingValuesOptions([]);
        }
    }, [columnFilterSelectedOptions, simulationState.salesForecast]);


    /********************************* Filtro de opciones para select *****************************/

    const columnLabels = dataGetAnalysisById && cancelFilters && dataGetAnalysisById?.agrupacion?.columnas_a_usar?.filter((opcion) => {
        return !cancelFilters.some((param) => param.column.label === opcion.label);
    }).filter(column => !requiredCancelFilters.map((requireFilter) => {
        return requireFilter.columna
    }).includes(column.label)).filter(column => {
        // Comprobar si la columna está presente en requiredFiltersSchema
        return !requiredFiltersSchema.some(requiredColumn => requiredColumn.columna.value === column.value);
    });

    const columnSelects = (arrayColumns: any, index: number) => {
        return (arrayColumns?.map((column) => {

            const findFilterByColumn = (requiredCancelFilters, labelToFind) => {
                const filterObject = requiredCancelFilters && requiredCancelFilters.find((item) => item.label === labelToFind);
                if (filterObject) {
                    const { columna, valor } = filterObject;
                    return { label: columna, value: valor };
                }
                return undefined;
            }
            return (
                <ValueFilterColumnSelect
                    filters={cancelFilters}
                    key={`${column}`}
                    defaultValue={defaultCedis ? { value: defaultCedis, label: defaultCedis } : findFilterByColumn(requiredCancelFilters, column)}
                    requiredFilters={requiredCancelFilters}
                >
                    {({
                        dataGetIncomingValues,
                        selectedValue,
                        setValuesSelectedOptions,
                        setSelectedValue,
                        isLoadingpostGetIncomingValues,
                        setRequiredColumnFilterSelectedOptions,
                        requiredColumnFilterSelectedOptions,
                    }) => {

                        useEffect(() => {
                            if (requiredFiltersSchema && selectedValue) {
                                setRequiredColumnFilterSelectedOptions(requiredFiltersSchema?.find(filter => filter.nombre === column)?.columna)
                            }
                        }, [requiredFiltersSchema, selectedValue])

                        const addRequiredFilters = (newValue, column, columnFilterSelectedOptions) => {
                            if (newValue.length !== 0) {
                                setSelectedValue(newValue)
                                setRequiredCancelFilters && setRequiredCancelFilters([...requiredCancelFilters, {
                                    label: column,
                                    columna: columnFilterSelectedOptions.label,
                                    valor: newValue
                                }])
                            } else {
                                setSelectedValue(newValue)
                                setRequiredCancelFilters((prevFilters) =>
                                    prevFilters.filter((filter) => filter.label !== column)
                                );
                            }
                        }

                        useEffect(() => {
                            if (requiredCancelFilters && findFilterByColumn(requiredCancelFilters, column)) {
                                setSelectedValue([findFilterByColumn(requiredCancelFilters, column)?.value[0]])
                            }
                        }, [])
                        return (
                            <Stack sx={{
                                width: "100%",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.2) -6px 2px 8px -1px, rgba(0, 0, 0, 0.14) 2px 6px 20px 1px, rgba(0, 0, 0, 0.12) 0px 5px 13px 1px",
                                padding: "15px",
                                borderRadius: "10px",
                                border: requiredCancelFilters && findFilterByColumn(requiredCancelFilters, column) ? "" : "1px solid red"
                            }}>
                                <FormSelectLayout
                                    title={column}
                                    required={true}
                                    key={index}
                                >
                                    <Stack sx={{ flexDirection: "row", justifyContent: "space-around", gap: 2 }}>
                                        {/* <Select
                                            options={columnLabels}
                                            className="cc_select"
                                            name="column"
                                            onChange={requiredColumnFilterOptionsChangeHandler}
                                            closeMenuOnSelect
                                            placeholder={t("simulatorTexts.canceledRoutes.step1NewCancelRouteModal.chooseReferenColumn")}
                                            isClearable
                                            isDisabled={defaultCedis ? true : false}
                                            defaultValue={defaultCedis ? {value: defaultCedis, label: defaultCedis} : findFilterByColumn(requiredCancelFilters, column)}
                                        /> */}
                                        <VirtualSelect
                                            incomingValuesOptions={
                                                dataGetIncomingValues ? dataGetIncomingValues.valores : []
                                            }
                                            selectedValues={selectedValue}
                                            setSelectedValues={(newValue) =>
                                                addRequiredFilters(newValue, column, requiredColumnFilterSelectedOptions)
                                            }
                                            setValuesSelectedOptions={setValuesSelectedOptions}
                                            isLoadingIncomingValuesOptions={
                                                isLoadingpostGetIncomingValues
                                            }
                                            isDisabled={requiredColumnFilterSelectedOptions || requiredColumnFilterSelectedOptions?.length < 1 ? false : true}
                                            selectOneOption={true}
                                            defaultValue={selectedValue}
                                        />
                                    </Stack>
                                </FormSelectLayout>
                            </Stack>
                        );
                    }
                    }
                </ValueFilterColumnSelect>
            )
        }))
    }

    return (
        <>
            <Typography sx={{
                width: "100%",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                {t("simulatorTexts.registerRoutes.step1NewRouteModal.paramSelection")}
            </Typography>
            <Stack sx={{
                minWidth: "1000px",
                flexDirection: "column",
                justifyContent: "space-around",
                gap: "20px",
                p: "10px 50px",
            }}>
                <Stack sx={{
                    flexDirection: "column",
                    justifyContent: "space-around",
                    gap: 2
                }}>
                    {dataGetRequiredFilters && columnSelects(dataGetRequiredFilters, 1)}
                </Stack>
                <FilterForm filters={cancelFilters} setFilters={setCancelFilters} setColumnFilterSelectedOptions={setColumnFilterSelectedOptions} columnFilterSelectedRef={columnSelectRef} columnLabels={columnLabels} columnFilterOptionsChangeHandler={columnFilterOptionsChangeHandler} columnFilterSelectedOption={columnFilterSelectedOptions} analysisSelectedOption={analysisId} selectedValues={selectedValue} incomingValuesOptions={incomingValuesOptions} setValuesSelectedOptions={setValuesSelectedOptions} setSelectedValues={setSelectedValue} isLoadingIncomingValuesOptions={isLoadingIncomingValuesOptions} valuesSelectedOptions={valuesSelectedOptions} selectOneOption={true} />
                {cancelFilters?.length === 0 && (
                    <Stack
                        sx={{
                            width: "95%",
                            height: "7vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: "700", color: "var(--text-info)" }}
                        >
                            {t("simulatorTexts.registerRoutes.step1NewRouteModal.noFilterYet")}
                        </Typography>
                    </Stack>
                )}
            </Stack >
        </>
    )
}

export default Step1NewCancelRouteModal