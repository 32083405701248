import { ActionMeta, SingleValue } from "react-select";
import { getBackgroundColor, options } from "../../utils";
import { grid_item_element_styles } from "../../../../../styles/app-styles";
import { IdOption } from "../../../configCriteria/types";
import { selectStyles } from "../../../configCriteria/select.styles";
import { useEffect, useRef, useState } from "react";
import DestinyCriteriaRow from "./DestinyCriteriaRow";
import DestinyValidationRow from "./DestinyValidationRow";
import FormSelectLayout from "../../../../forms/FormSelectLayout";
import Select from "../../../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../../context/LanguageProvider";
import useSingleSelect from "../../../../../hooks/useSingleSelect";

const IterationExplicitNoMatch = ({
  newSchemaRows,
  setNewIterationSchemaRow,
  criteriaData,
  setMessage,
  iterations,
  destinyTypeSelectedOption,
  iterationToEdit,
  setIterationHasBeenModified,
}) => {
  const { t } = useLanguage();
  const criteriaSelectRef: any = useRef();
  const [noMatchCriteriaRows, setNoMatchCriteriaRows] = useState<any[]>(
    iterationToEdit
      ? iterationToEdit?.sin_match?.caso === "CRITERIO_NUEVO"
        ? iterationToEdit?.sin_match?.criterios_destinos
        : iterationToEdit?.sin_match?.caso === "PORCENTAJE"
        ? iterationToEdit?.sin_match?.porcentaje
        : []
      : []
  );
  const [noMatchValidationRows, setNoMatchValidationRows] = useState<any[]>(
    iterationToEdit
      ? iterationToEdit?.sin_match?.caso === "ELIMINAR"
        ? iterationToEdit?.sin_match?.criterios_destinos
        : []
      : []
  );

  const setNoMatchCriteriaRows1 = (newRows) => {
    setNoMatchCriteriaRows(newRows);
    setIterationHasBeenModified(true);
  };

  const setNoMatchValidationRows1 = (newRows) => {
    setNoMatchValidationRows(newRows);
    setIterationHasBeenModified(true);
  };

  const [selectedCriteriaType, setSelectedCriteriaType] = useState<any>(
    iterationToEdit &&
      iterationToEdit?.sin_match?.porcentaje &&
      !iterationToEdit?.sin_match?.porcentaje?.every(
        (percentage) => percentage.porcentaje === 0
      )
      ? {
          value: "porcentaje",
          label: t(
            "preprorationParameterization.newSchemaContent.percentage"
          ).toUpperCase(),
        }
      : {
          value: "criterio",
          label: t(
            "preprorationParameterization.preprorationParameterization.criteria"
          ).toUpperCase(),
        }
  );

  /* if (!percentagesSum100 && message?.text === "") {
    setMessage({
      text: t("periods.periodsDialogContent.percentageSum100"),
      type: "error",
    });
  } else {
    setMessage({ text: "", type: "success" });
  } */

  const criteriaTypeChangeHandler = (
    value: SingleValue<IdOption>,
    action: ActionMeta<IdOption>
  ) => {
    switch (action.action) {
      case "select-option":
        /* if (newSchemaRows && newSchemaRows?.length > 0) {
          setNewCriteriaTypeSelectedOption(value);
          toggleConfirmationCriteriaTypeModal();
        } else { */
        if (value?.value === "porcentaje") {
          setNewIterationSchemaRow((prev) => ({
            ...prev,
            sin_match: {
              caso: "PORCENTAJE",
            },
          }));
        } else if (value?.value === "criterio") {
          setNewIterationSchemaRow((prev) => ({
            ...prev,
            sin_match: { caso: "CRITERIO_NUEVO" },
          }));
        }
        setIterationHasBeenModified(true);
        setSelectedCriteriaType(value);
        setMessage({
          type: "success",
          text: "",
        });
        break;
      default:
        break;
    }
  };

  const onCustomCriteriaTypeChange = (crit) => {
    setIterationHasBeenModified(true);
    if (
      crit?.value === "nuevo" &&
      selectedCriteriaType.value === "porcentaje"
    ) {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        sin_match: { caso: "PORCENTAJE" },
      }));
    } else if (crit?.value === "nuevo") {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        sin_match: { caso: "CRITERIO_NUEVO" },
      }));
    } else {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        sin_match: { caso: "ELIMINAR" },
      }));
    }
  };

  const [
    criteriaTypeSelectedOption,
    ,
    criteriaTypeSelectedOptionChangeHandler,
  ] = useSingleSelect(
    (crit) => onCustomCriteriaTypeChange(crit),
    iterationToEdit
      ? iterationToEdit.sin_match?.caso === "CRITERIO_NUEVO" ||
        iterationToEdit.sin_match?.caso === "PORCENTAJE"
        ? { value: "nuevo", label: "Criterio nuevo" }
        : { value: "modificar", label: "Modificar un criterio" }
      : undefined
  );

  useEffect(() => {
    if (selectedCriteriaType.value === "criterio") {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        sin_match: {
          caso: prev?.sin_match?.caso,
          criterios_destinos: noMatchCriteriaRows,
        },
      }));
    } else if (selectedCriteriaType.value === "porcentaje") {
      setNewIterationSchemaRow((prev) => ({
        ...prev,
        sin_match: {
          caso: prev?.sin_match?.caso,
          porcentaje: noMatchCriteriaRows,
        },
      }));
    }
  }, [noMatchCriteriaRows]);

  useEffect(() => {
    setNewIterationSchemaRow((prev) => ({
      ...prev,
      sin_match: {
        ...prev?.sin_match,
        criterios_destinos: noMatchValidationRows,
      },
    }));
  }, [noMatchValidationRows]);

  const newCriteria = criteriaTypeSelectedOption?.value === "nuevo";
  const modifyCriteria = criteriaTypeSelectedOption?.value === "modificar";
  const areSchemaRows = newSchemaRows && newSchemaRows?.length > 0;

  return (
    <Stack width="100%" gap={1}>
      <Stack
        sx={{
          ...grid_item_element_styles(
            getBackgroundColor,
            "match",
            () => "100%"
          ),
          color: "white",
          minHeight: "30px",
          borderRadius: "4px",
        }}
      >
        Caso sin match
      </Stack>
      <Stack sx={{ width: "100%" }}>
        <FormSelectLayout
          title={t("Criterio nuevo / Modificar un criterio")}
          margin="0 0 0 0"
        >
          <Select
            reference={criteriaSelectRef}
            styles={selectStyles(criteriaTypeSelectedOption)}
            options={[
              { value: "nuevo", label: "Criterio nuevo" },
              { value: "modificar", label: "Modificar un criterio" },
            ]}
            onChange={criteriaTypeSelectedOptionChangeHandler}
            closeMenuOnSelect
            placeholder={t(
              "preprorationParameterization.newSchemaContent.chooseCriteria"
            )}
            defaultValue={criteriaTypeSelectedOption}
            isClearable
          />
        </FormSelectLayout>
      </Stack>
      {newCriteria && (
        <Stack
          direction="row"
          justifyContent={"space-between"}
          width="100%"
          gap={1}
        >
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Destino: {destinyTypeSelectedOption.label}
          </Stack>
          <Select
            value={selectedCriteriaType}
            styles={{
              singleValue: (provided) => ({
                ...provided,
                color: "white !important",
                fontWeight: "700",
              }),
            }}
            options={options(t)}
            placeholder={t(
              "preprorationParameterization.newSchemaContent.criteria"
            ).toUpperCase()}
            defaultValue={selectedCriteriaType}
            closeMenuOnSelect
            onChange={criteriaTypeChangeHandler}
            isClearable={false}
            isSearchable={false}
            className="selectDestinity"
          />
        </Stack>
      )}
      <Stack width="100%" gap={1}>
        {newCriteria &&
          areSchemaRows &&
          newSchemaRows.map((schemaRow, i) => (
            <DestinyCriteriaRow
              key={i}
              schemaRow={schemaRow}
              destinyCriteriaRows={noMatchCriteriaRows}
              setDestinyCriteriaRows={setNoMatchCriteriaRows1}
              criteriaData={criteriaData}
              selectedCriteriaType={selectedCriteriaType}
              iterationToEdit={iterationToEdit}
            />
          ))}
      </Stack>

      {modifyCriteria && (
        <Stack
          direction="row"
          justifyContent={"space-between"}
          width="100%"
          gap={1}
        >
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Destino: {destinyTypeSelectedOption.label}
          </Stack>
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Criterio a modificar
          </Stack>
          <Stack
            sx={{
              ...grid_item_element_styles(
                getBackgroundColor,
                "header",
                () => "100%"
              ),
              color: "white",
              minHeight: "30px",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            Validaciones a incluir
          </Stack>
        </Stack>
      )}
      {modifyCriteria &&
        areSchemaRows &&
        newSchemaRows.map((schemaRow, i) => (
          <DestinyValidationRow
            key={i}
            schemaRow={schemaRow}
            destinyValidationRows={noMatchValidationRows}
            setDestinyValidationRows={setNoMatchValidationRows}
            criteriaData={criteriaData}
            iterations={iterations}
            newSchemaRows={newSchemaRows}
            iterationToEdit={iterationToEdit}
            setIterationHasBeenModified={setIterationHasBeenModified}
          />
        ))}
    </Stack>
  );
};

export default IterationExplicitNoMatch;
