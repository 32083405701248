import Toolbar from "@mui/material/Toolbar";
import {
  StyledAppBar,
  menu_header_company,
  menu_header_option,
  menu_header_option_container,
  menu_items_container,
  menu_logo,
  menu_logo_container,
  menu_logo_user_container,
} from "../../styles/app-styles";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import LanguageWrapper from "../language/languageWrapper/LanguageWrapper";
import logoPhi from "../../assets/logoPhiWhiteCompleted.png";
import MenuIcon from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";
import useAuth from "../../hooks/useAuth";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import UserAvatar from "./Avatar";
interface AppBarComponentProps {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  goHome: () => void;
  handleOpenBasicModal: () => void;
}

const AppBarComponent = ({
  open,
  handleDrawerOpen,
  handleDrawerClose,
  goHome,
  handleOpenBasicModal,
}: AppBarComponentProps) => {
  const { company, userRolData }: any = useInfoContext();

  const navigate = useNavigate();
  const handleNavigate = (url: string) => {
    navigate(url);
    handleDrawerClose();
  };
  const { t } = useLanguage();  

  const backgroundColor = "var(--bg-main)";

  return (
    <StyledAppBar
      color="primary"
      sx={{ background: backgroundColor }}
      position="fixed"
      open={open}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
            color: "white",
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={menu_items_container}>
          <Box sx={menu_logo_user_container}>
            {!open && (
              <Box sx={menu_logo_container} onClick={goHome}>
                <img style={menu_logo} src={logoPhi} alt="logo" />
              </Box>
            )}
            {!!userRolData && !!userRolData.administrador.visualizacion_y_edicion && (
              <Typography
                sx={menu_header_option}
                onClick={() => handleNavigate(`/administrador`)}
              >
                {t("appBarComponent.adminDashboard")}
              </Typography>
            )}
            {company && company.value && (
              <Box
                sx={menu_header_option_container}
                onClick={handleOpenBasicModal}
              >
                <Typography sx={menu_header_company}>
                  {company.label}
                </Typography>
                <ExpandMoreIcon fontSize={"medium"} />
              </Box>
            )}
          </Box>
          <Stack sx={{flexDirection: "row", gap: "10px"}}>
           <LanguageWrapper />
            <UserAvatar />
          </Stack>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default AppBarComponent;
