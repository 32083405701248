import { AGREGATION_FUNCTIONS } from "../../data";
import { formatNewColumnRow, validateNewColumnRow } from "../../utils";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { useRef, useState } from "react";
import AddButton from "../../../buttons/AddButton";
import DeleteForever from "../../../icons/DeleteForever";
import Grid from "@mui/material/Grid/Grid";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import useLanguage from "../../../../context/LanguageProvider";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import {
  form_helper_text_styles,
  row_delete_container_styles,
} from "../../../../styles/app-styles";
interface NewColumnSelectsProps {
  titles: string[];
  setTitles: any;
  totalColumnsOptions: any[];
  rows: any[];
  setRows: any;
  setMessage: any;
}

const NewColumnSelects = ({
  titles,
  setTitles,
  totalColumnsOptions,
  rows,
  setMessage,
  setRows,
}: NewColumnSelectsProps) => {
  const { t } = useLanguage();
  const column1SelectRef: any = useRef(null);
  const column2SelectRef: any = useRef(null);
  const columns3SelectRef: any = useRef(null);
  const columns4SelectRef: any = useRef(null);
  const valueSelectRef: any = useRef(null);

  const [column1SelectedOption, setColumn1SelectedOption] = useState("");
  const [valueSelectedOption, setValueSelectedOption] = useState("");

  const onFunctionChange = (f) => {
    setColumns4SelectedOptions(undefined);
    columns4SelectRef?.current?.clearValue();
    if (f) {
      if (rows && rows.length > 0 && titles.length === 4) {
        const isRowWithDivide =
          rows.some((row) => row.funcion?.value === "DIVIDIR") ||
          f?.value === "DIVIDIR";
        const isRowWithSubstract =
          rows.some((row) => row.funcion?.value === "RESTAR") ||
          f?.value === "RESTAR";
        if (isRowWithDivide && !isRowWithSubstract) {
          setTitles([
            t("general.name"),
            t("Valor / Operación"),
            t("Valor fijo / Columnas"),
            t("general.dividingColumns"),
          ]);
        } else if (!isRowWithDivide && isRowWithSubstract) {
          setTitles([
            t("general.name"),
            t("Valor / Operación"),
            t("Valor fijo / Columnas"),
            t("general.substractingColumns"),
          ]);
        } else if (isRowWithDivide && isRowWithSubstract) {
          setTitles([
            t("general.name"),
            t("Valor / Operación"),
            t("Valor fijo / Columnas"),
            `${t("general.dividingColumns")}/${t(
              "general.substractingColumns"
            )}`,
          ]);
        } else {
          setTitles([
            t("general.name"),
            t("Valor / Operación"),
            t("Valor fijo / Columnas"),
          ]);
        }
      } else {
        if (f?.value === "DIVIDIR") {
          setTitles([
            t("general.name"),
            t("Valor / Operación"),
            t("Valor fijo / Columnas"),
            t("general.dividingColumns"),
          ]);
        } else if (f?.value === "RESTAR") {
          setTitles([
            t("general.name"),
            t("Valor / Operación"),
            t("Valor fijo / Columnas"),
            t("general.substractingColumns"),
          ]);
        }
      }
    }
  };

  const [
    column2SelectedOption,
    setColumn2SelectedOption,
    column2OptionsChangeHandler,
  ] = useSingleSelect((f) => onFunctionChange(f));

  const [
    columns3SelectedOptions,
    setColumns3SelectedOptions,
    columns3OptionsChangeHandler,
  ] = useMultiSelect();

  const [
    columns4SelectedOptions,
    setColumns4SelectedOptions,
    columns4OptionsChangeHandler,
  ] = useMultiSelect();

  const totalFunctionOptions = [
    {
      label: t("VALOR"),
      options: [{ value: "VALOR", label: "VALOR FIJO" }],
    },
    {
      label: t("OPERACIÓN"),
      options: AGREGATION_FUNCTIONS(t).filter((f) => f.value !== "VALOR"),
    },
  ];

  const cleanSelects = () => {
    setColumn1SelectedOption("");
    setColumn2SelectedOption(undefined);
    setColumns3SelectedOptions(undefined);
    setColumns4SelectedOptions(undefined);
    setValueSelectedOption("");
    column2SelectRef?.current?.clearValue();
    columns3SelectRef?.current?.clearValue();
    columns4SelectRef?.current?.clearValue();
  };

  const handleAddRow = () => {
    if (
      validateNewColumnRow(
        t,
        rows,
        setMessage,
        column1SelectedOption,
        column2SelectedOption,
        columns3SelectedOptions,
        columns4SelectedOptions,
        valueSelectedOption
      )
    ) {
      const newRow = formatNewColumnRow(
        column1SelectedOption,
        column2SelectedOption,
        columns3SelectedOptions,
        columns4SelectedOptions,
        valueSelectedOption
      );
      const newRows = rows && rows.length > 0 ? [...rows] : [];
      newRows.push(newRow);
      setRows(newRows);
      setMessage({
        type: "",
        text: "",
      });
      cleanSelects();
    }
    return true;
  };

  const handleDeleteAllRows = () => {
    setRows([]);
    setMessage({
      type: "",
      text: "",
    });
    cleanSelects();
  };

  return (
    <>
      <Grid item xs={0.5}>
        <Stack sx={row_delete_container_styles}>
          <DeleteForever
            tooltipPlacement="bottom"
            onClick={handleDeleteAllRows}
          />
        </Stack>
      </Grid>
      <Grid item xs={11 / titles.length}>
        <TextField
          ref={column1SelectRef}
          type="text"
          label={t("general.name")}
          variant="outlined"
          fullWidth
          value={column1SelectedOption}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setColumn1SelectedOption(event.target.value);
          }}
          error={column1SelectedOption === ""}
          FormHelperTextProps={{ sx: form_helper_text_styles }}
          size="small"
          required={true}
          sx={{ zIndex: 0 }}
        />
      </Grid>
      <Grid item xs={11 / titles.length}>
        <Select
          reference={column2SelectRef}
          styles={selectStyles(column2SelectedOption)}
          options={totalFunctionOptions}
          onChange={column2OptionsChangeHandler}
          closeMenuOnSelect
          placeholder={t("general.chooseColumn")}
          isClearable
          defaultValue={column2SelectedOption}
        />
      </Grid>
      {column2SelectedOption?.value === "VALOR" ? (
        <Grid item xs={11 / titles.length}>
          <TextField
            ref={valueSelectRef}
            type="text"
            label={t("Valor")}
            variant="outlined"
            fullWidth
            value={valueSelectedOption}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValueSelectedOption(event.target.value);
            }}
            error={valueSelectedOption === "" || !valueSelectedOption}
            FormHelperTextProps={{ sx: form_helper_text_styles }}
            size="small"
            required={true}
          />
        </Grid>
      ) : (
        <Grid item xs={11 / titles.length}>
          <Select
            reference={columns3SelectRef}
            styles={selectStyles(columns3SelectedOptions)}
            options={totalColumnsOptions}
            onChange={columns3OptionsChangeHandler}
            closeMenuOnSelect={false}
            placeholder={t("general.chooseColumn")}
            isClearable
            defaultValue={columns3SelectedOptions}
            isMulti
          />
        </Grid>
      )}
      {titles.length === 4 && (
        <Grid item xs={11 / titles.length}>
          <Select
            reference={columns4SelectRef}
            styles={selectStyles(columns4SelectedOptions)}
            options={totalColumnsOptions}
            onChange={columns4OptionsChangeHandler}
            closeMenuOnSelect={false}
            placeholder={t("general.chooseColumn")}
            isClearable
            defaultValue={columns4SelectedOptions}
            isMulti
            isDisabled={
              column2SelectedOption?.value === "SUMAR" ||
              column2SelectedOption?.value === "MULTIPLICAR" ||
              column2SelectedOption?.value === "VALOR"
            }
          />
        </Grid>
      )}

      <Grid item xs={0.5}>
        <AddButton align={true} onClick={() => handleAddRow()} />
      </Grid>
    </>
  );
};

export default NewColumnSelects;
