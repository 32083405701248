import { convertNumberToMoney } from "../parameterization/utils";
import { convertUnit } from "../report/utils";
import { memo, useRef, useState, useMemo } from "react";
import { Stack } from "@mui/material";
import { Switch } from "../switch";
import drilldown from "highcharts/modules/drilldown.js";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import useLanguage from "../../context/LanguageProvider";
import useSwitch from "../../hooks/useSwitch";
import accessibility from "highcharts/modules/accessibility";
import { useViewDashboard } from "../../context/ViewDashboardProvider";

drilldown(Highcharts);
accessibility(Highcharts);
interface Props {
  results: any;
  tooltip?: boolean;
  stack?: boolean; //Define si debe aparece rel switch de apilar
  stacking?: boolean;
  tooltipFormatter?: any;
  onClickFilters?: any;
}
const BarDrilldownChart = memo((props: Props) => {
  const { t } = useLanguage();
  const { results, tooltip, tooltipFormatter, stack, onClickFilters } = props;
  const chartRef: any = useRef(null);
  const [stacking, handleChangeStacking] = useSwitch(false);
  const [isDrilldown, setIsDrilldown] = useState(false);

  const options = useMemo(
    () => ({
      chart: {
        type: "column",
        events: {
          drillup: function () {
            const chart: any = this;
            setTimeout(function () {
              if (chart?.series && chart?.series[0]?.options?._levelNumber === 0) {
                setIsDrilldown(false);
              }
            }, 200);
          },
          drilldown: function () {
            const chart: any = this;
            setTimeout(function () {
              if (chart?.series && chart?.series[0]?.options?._levelNumber) {
                setIsDrilldown(true);
              }
            }, 200);
          },
        },
        style: {
          width: "100%",
        },
        zooming: {
          type: "xy",
        },
      },
      ref: chartRef,
      plotOptions: {
        series: {
          stacking: stacking ? "normal" : false,
          dataLabels: {
            enabled: false,
          },
          point: {
            events: {
              name: "",
              click: function () {
                // Captura el clic en la serie completa
                // if (onClickFilters) {
                //   onClickFilters(results?.nombre_eje_x, this.name);
                //   return false;
                // }
              },
            },
          },
          events: {
            name: "",
            data: [{ drilldown: "" }],
            legendItemClick: function () {
              if (onClickFilters && results?.columna_series) {
                onClickFilters(
                  results?.columna_series?.label,
                  results?.columna_series.value,
                  this.name
                );
                return false;
              }
            },
          },
        },
      },
      title: {
        text: results.titulo_grafico,
        style: {
          color: "var(--text-main)",
          fontSize: "18px",
          fontWeight: "bold",
          textShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
        },
      },
      colors: [
        "var(--chart-1)",
        "var(--chart-2)",
        "var(--chart-3)",
        "var(--chart-4)",
        "var(--chart-5)",
        "var(--chart-6)",
        "var(--chart-7)",
        "var(--chart-8)",
        "var(--chart-9)",
        "var(--chart-10)",
      ],
      xAxis: {
        type: "category",
        labels: {
          useHTML: true,
          value: "",
          formatter: function () {
            const categoryName = this.value;
            return `<p class="custom-x-axis-label" data-category="${
              categoryName || ""
            }">${categoryName || ""}</p>`;
          },
        },
        events: {
          value: "",
          afterRender: function () {
            // Asigna el evento 'click' manualmente a cada etiqueta
            const labels = document.querySelectorAll(".custom-x-axis-label");
            labels.forEach((label: any) => {
              label.onclick = function () {
                const categoryValue = label.textContent;

                if (onClickFilters && categoryValue && results?.eje_x && results?.eje_x !== null) {
                  onClickFilters(
                    results?.eje_x.label,
                    results?.eje_x.value,
                    categoryValue
                  );
                }
              };
            });
          },
        },
      },
      yAxis: {
        title: {
          text: results?.nombre_eje_y,
          style: {
            fontWeight: "900",
            fontSize: "16px",
            textTransform: "uppercase",
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: results?.series,
      drilldown: results?.drilldown ? results?.drilldown : {},
      accessibility: {
        enabled: true,
      },
      tooltip: {
        y: 0,
        series: {
          name: "",
        },
        point: {
          porcentaje: 0,
        },
        useHTML: tooltip,
        formatter: function () {
          return tooltip
            ? `<div>  <p>${
                this.point.porcentaje
                  ? `<span> ${t(
                      "charts.barInfiniteDrilldownChart.originalExpensePercent"
                    )}: ${convertUnit(
                      "porcentaje",
                      this.point.porcentaje
                    )} </span>`
                  : "<span></span>"
              }</p> <b>${this.series.name}: ${convertUnit(
                "moneda",
                this.y
              )}</b> <span> (${convertNumberToMoney(this.y)})</span> </div>`
            : `<p>${this.y.toFixed(2)}</p> `;
        },
      },
    }),
    [setIsDrilldown, stacking, onClickFilters, results]
  );

  return (
    <Stack sx={{ width: "100%", margin: "0px !important" }}>
      <Stack
        sx={{ alignSelf: "flex-end", height: "35px", margin: "0px !important" }}
      >
        {stack && !isDrilldown && (
          <Switch
            checked={stacking}
            handleChange={handleChangeStacking}
            label="Apilar"
            disabled={false}
            color="primary"
          />
        )}
      </Stack>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Stack>
  );
});

export default BarDrilldownChart;
