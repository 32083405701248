import "../../tablesTools/styles.css";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import Copy from "../../icons/Copy";
import Edit from "../../icons/Edit";
import Stack from "@mui/material/Stack";
import Delete from "../../icons/Delete";
import ViewInfo from "../../icons/ViewInfo";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CloseIcon from "@mui/icons-material/Close";

export const transformDataCriteriasTable: (
  t: any,
  data: any,
  actions?: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  minWidth?: number
) => {
  columns: GridColDef<any, any, any>[];
  rows: any;
} = (
  t: any,
  data: any,
  actions?: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  minWidth?: number
) => {
  const tableColumns = optionalColumns
    ? optionalColumns
    : data && data.length > 0
    ? Object.keys(data[0])
    : [];

  const arrayColumns = tableColumns
    ?.filter((item: any) => item !== "id")
    ?.map((columna: string) => ({
      field: columna,
      headerClassName: "es_header_column",
      headerName: columna,
      description: columna,
      minWidth: minWidth ? minWidth : 100,
      flex: columna === "is_active" || columna === "habilitada" ? 0.2 : 1,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong
          style={{
            color: "white",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {transformColumnNames ? transformColumnNames(columna) : columna}
        </strong>
      ),
      renderCell: (params: any) => (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"flex-start"}
        >
          {columna === "acciones" ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"flex-start"}
              width="100%"
              gap={0.8}
            >
              {actions.edit && (
                <Edit
                  tooltipText={"Editar"}
                  onClick={(e) => actions.edit(e, params.row.id)}
                />
              )}
              {actions.copy && (
                <Copy
                  tooltipText={"Copiar"}
                  onClick={(e) => actions.copy(e, params.row.id)}
                />
              )}
              {actions.info && (
                <ViewInfo
                  tooltipText={"Ver más"}
                  onClick={(e) => actions.info(e, params.row.id)}
                />
              )}
              {actions.delete && (
                <Delete
                  tooltipText={"Eliminar"}
                  onClick={(e) => actions.delete(e, params.row.id)}
                />
              )}
            </Stack>
          ) : columna === "archivo" ? (
            params.value?.label
          ) : columna === "columna" ? (
            params.value?.label
          ) : columna === "en_uso" ? (
            params.row?.buckets_usados?.length > 0 ? (
              <DoneOutlineIcon
                sx={{
                  color: "var(--icon-success)",
                }}
              />
            ) : (
              <CloseIcon
                sx={{
                  color: "var(--icon-error)",
                }}
              />
            )
          ) : columna === "buckets_usados" ? (
            params.value?.map((bucket: any) => bucket.label).join(", ") ||
            "Ninguno"
          ) : columna === "criterio_bucket" ? (
            params.value?.map((bucket: any) => bucket.label).join(", ") ||
            "Todos"
          ) : columna === "criterio_receptor_gasto" ? (
            params.value?.map((crit: any) => crit.label).join(", ") || "Todos"
          ) : (
            params.value
          )}
        </Stack>
      ),
    }));

  const arrayRows =
    data && data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          for (const key in fila) {
            obj[key] = fila[key];
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
