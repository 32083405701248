import { useContext, useEffect, useState } from "react";
import ComponentLayout from "../../layout/ComponentLayout";
import { Stack } from "@mui/material";
import useLanguage from "../../../context/LanguageProvider";
import CustomButton from "../../buttons/Button";
import { useNavigate } from "react-router";
import Step1SimulationConfig from "./Steps/Step1SimulationConfig";
import Step2SimulationConfig from "./Steps/Step2SimulationConfig";
import {
  GET_ANALYSIS_URL,
  GET_SIMULATIONS_URL,
  GET_SIMULATION_INFORMATION,
  GET_SIMULATION_URL,
  MODIFICATIONS_TYPES,
  POST_SIMULATIONS_URL,
  RULES_URL,
} from "../../../api/axios";
import { useApiQuery } from "../../../hooks/useApiQuery";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import SimulationProvider from "../../../context/SimulationProvider";
import useApi from "../../../hooks/useApi";
import { getInformationColumn, getInformationId } from "../utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { isDuplicatedName } from "../../companyParameterization/utils";
import useSingleSelect from "../../../hooks/useSingleSelect";
interface RutaModificacion {
  ruta: string;
  tipo_modificacion: number;
  valor: string;
  filtros: { columna: string; valor: string }[];
}

const SimulationConfig = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const { simulationState, simulationDispatch } =
    useContext<React.ContextType<typeof SimulationProvider>>(
      SimulationProvider
    );
  const configMode = localStorage.getItem("configMode")
    ? localStorage.getItem("configMode")
    : undefined;
  const simulationId = localStorage.getItem("simulationId")
    ? localStorage.getItem("simulationId")
    : undefined;
  const [informationsColumns, setInformationColumns] = useState<any>();

  /****************** CARGA Y OBTENCIÓN DE DATOS ******************/

  const {
    isLoading: isLoadingGetSimulationById,
    data: dataGetSimulationById,
    callApi: getSimulationById,
  } = useApi(
    undefined,
    "GET",
    t("taskManager.getSimulations.error"),
    undefined,
    undefined,
    undefined,
    false
  );

  const onSalesForecastChange = (data) => {
    simulationDispatch({ type: "SET_REGLA", payload: data });
  };

  const [
    simulationRuleSelectedOption,
    setSimulationRuleSelectedOptions,
    simulationRuleSelectedChangeHandler,
  ] = useSingleSelect(
    onSalesForecastChange,
    dataGetSimulationById ? dataGetSimulationById.regla : undefined
  );

  useEffect(() => {
    if (simulationId && (configMode === "EDIT" || configMode === "COPY")) {
      getSimulationById(
        GET_SIMULATION_URL(simulationId ? Number(simulationId) : undefined)
      );
    }
  }, [simulationId]);

  const { data: modificationTypes, isLoading: isLoadingGetModificationTypes } =
    useApiQuery(MODIFICATIONS_TYPES, false);

  const onSuccessPostPutSimulation = () => {
    navigate("/delta/simulaciones");
  };

  const { isLoading: isLoadingPostSimulationConfig, callApi: postSimulation } =
    useApi(
      "",
      "POST",
      t("delta.postSimulation.codes"),
      undefined,
      onSuccessPostPutSimulation,
      undefined,
      false
    );

  const { isLoading: isLoadingPutSimulationConfig, callApi: putSimulation } =
    useApi(
      "",
      "PUT",
      t("delta.putSimulation.codes"),
      undefined,
      onSuccessPostPutSimulation,
      undefined,
      false
    );

  const onSuccessPostInformationsValues = () => {
    setActiveStep(1);
  };

  const {
    isLoading: isLoadingInformationsValues,
    callApi: postInformationsValues,
  } = useApi(
    "",
    "POST",
    undefined,
    undefined,
    onSuccessPostInformationsValues,
    undefined,
    false
  );

  const {
    isLoading: isLoadingSimulationsRules,
    data: dataGetSimulationsRules,
  } = useApiQuery(RULES_URL, true, t("simulator.getRules.error"));

  const {
    isLoading: isLoadingSimulationsInformation,
    data: dataGetSimulationsInformation,
  } = useApiQuery(GET_SIMULATION_INFORMATION, true, undefined);

  const { isLoading: isLoadingGetSalesForecasts, data: dataGetSalesForecast } =
    useApiQuery("simulador/sales_forecast/", true, undefined);

    const { isLoading: isLoadingGetCostsForecasts, data: dataGetCostsForecast } =
    useApiQuery("simulador/costs_forecast/", true, undefined);

  const {
    isLoading: isLoadingGetAnalysisById,
    data: dataGetAnalysisById,
    callApi: getAnalysisById,
  } = useApi(
    undefined,
    "GET",
    t("dataModel.getAnalysis.codes"),
    undefined,
    undefined,
    undefined,
    false
  );
  const { isLoading: isLoadingSimulations, data: dataGetSimulations } =
    useApiQuery(GET_SIMULATIONS_URL("RUTAS"), true, undefined);

  useEffect(() => {
    if (simulationState && simulationState.regla && dataGetSalesForecast && dataGetCostsForecast) {
      const analisisLabel = dataGetSimulationsRules.find(
        (rule) => rule.id === simulationState.regla.value
      )?.analisis;
      const salesForecast =
        analisisLabel &&
        dataGetSalesForecast.concat(dataGetCostsForecast).find(
          (salesForecast) => salesForecast.analisis === analisisLabel
        );
      if (salesForecast) {
        getAnalysisById(
          GET_ANALYSIS_URL(Number(salesForecast.analisis_id), true)
        );
      } else {
        simulationDispatch({ type: "SET_SALES_FORECAST", payload: undefined });
      }
    }
  }, [simulationState.regla, dataGetSalesForecast, dataGetCostsForecast]);

  useEffect(() => {
    if (
      dataGetSimulationsInformation &&
      simulationState.principalColumns &&
      dataGetAnalysisById
    ) {
      const resultadoCruzado = dataGetSimulationsInformation.map((item) => {
        const columnOrMedida =
          simulationState.principalColumns?.find(
            (element) => element.informacion === item.id
          )?.columna || undefined;
        return {
          id: item.id,
          informacion: item.nombre,
          columna: columnOrMedida
            ? columnOrMedida.label
            : dataGetAnalysisById?.medidas?.find(
                (item) => item.id === columnOrMedida
              )?.nombre || null,
        };
      });
      setInformationColumns(resultadoCruzado);
    }
  }, [
    dataGetSimulationsInformation,
    dataGetAnalysisById,
    simulationState.principalColumns,
  ]);

  useEffect(() => {
    if (dataGetSimulationById) {
      simulationDispatch({
        type: "SET_NOMBRE",
        payload:
          configMode === "COPY"
            ? dataGetSimulationById.nombre + "_copy"
            : dataGetSimulationById.nombre,
      });
      simulationDispatch({
        type: "SET_REGLA",
        payload: dataGetSimulationById.regla,
      });
      simulationDispatch({
        type: "SET_AUTO_RUN",
        payload: dataGetSimulationById.ejecucion_automatica,
      });
    }
  }, [dataGetSimulationById, configMode]);

  useEffect(() => {
    if (informationsColumns && dataGetSimulationById) {
      const configDataEdit = dataGetSimulationById?.rutas_alta?.map(
        (ruta, idx) => {
          return {
            id: `alta-${idx}`,
            tipo: "alta",
            cantidad: ruta.cantidad,
            modalidad_proyeccion:
              ruta.modalidad_proyeccion === "Total Ruta" ? 2 : 1,
            parametros: ruta.parametros?.map((param) => {
              return {
                clave: param.clave,
                valor: param.valor,
                valor_ajustado: param.valor_ajustado,
              };
            }),
            filtros: {
              requeridos: ruta.filtros?.requeridos?.map((required) => {
                return {
                  label: required.label,
                  columna: required.columna,
                  valor: required.valor,
                };
              }),
              adicionales: ruta.filtros?.adicionales?.map((aditional) => {
                return {
                  columna: aditional.columna,
                  valor: aditional.valor,
                };
              }),
            },
          };
        }
      );

      const configCanceledDataEdit = dataGetSimulationById?.rutas_baja.map(
        (baja, idx) => {
          return {
            id: `baja-${idx}`,
            tipo: "baja",
            filtros: baja.filtros,
            rutas: baja.rutas.map((ruta) => {
              return {
                ...ruta,
              };
            }),
          };
        }
      );

      const groupedModifiedRoutes: { [key: string]: RutaModificacion[] } =
        dataGetSimulationById.rutas_modificacion.reduce((acc, curr) => {
          // Generar una clave única basada en tipo_modificacion, valor y filtros
          const key = `${curr.valor}_${JSON.stringify(curr.filtros)}`;

          // Verificar si ya existe una entrada para esta clave en el objeto
          if (!acc[key]) {
            // Si no existe, crear una nueva entrada con un array que contiene el objeto actual
            acc[key] = [curr];
          } else {
            // Si ya existe, agregar el objeto actual al array existente
            acc[key].push(curr);
          }

          return acc;
        }, {});

      // Transformar el objeto agrupado en un arreglo de arreglos de rutas
      const configModifiedDataEdit =
        groupedModifiedRoutes &&
        Object.values(groupedModifiedRoutes).map((group: any) => {
          // Retornar un objeto con la información agrupada de la ruta y los filtros
          return group.map((route) => {
            return {
              id: route.ruta,
              [`${getInformationColumn(
                informationsColumns,
                "Identificador único de ruta"
              )}`]: route.ruta,
              [`${getInformationColumn(
                informationsColumns,
                modificationTypes.find(
                  (type) => type.id === route.tipo_modificacion
                )?.nombre
              )}`]: route.valor,
              filtros: route.filtros,
            };
          });
        });
      // Iterar sobre cada subarray en dataModifiedRoutes
      const idMap = new Map<string, any>();

      // Iterar sobre cada subarray en dataModifiedRoutes
      configModifiedDataEdit.forEach((subarray, index) => {
        const groupedArray: any[] = [];
        // Iterar sobre cada objeto en el subarray
        subarray.forEach((obj) => {
          // Obtener el id del objeto
          const id = obj.id;

          // Verificar si ya existe un objeto con el mismo id en el mapa
          if (idMap.has(id)) {
            const existingObj = idMap?.get(id) && idMap.get(id);

            // Comparar las propiedades del objeto actual con las del objeto existente
            Object.keys(obj).forEach((key) => {
              if (obj[key] !== existingObj[key]) {
                // Si las propiedades son diferentes, actualizar el objeto existente
                existingObj[key] = obj[key];
              }
            });
          } else {
            // Si el objeto no existe en el mapa, agregarlo
            idMap.set(id, obj);
          }
        });

        // Agregar los objetos del mapa al array agrupado
        idMap.forEach((value) => {
          groupedArray.push(value);
        });

        // Limpiar el mapa para la siguiente iteración
        idMap.clear();

        // Reemplazar el subarray original con el nuevo subarray agrupado
        configModifiedDataEdit[index] = {
          id: index,
          rutas: groupedArray,
          tipo: "modificacion",
        };
      });

      const configurations = [
        ...(simulationState.configurations || []),
        ...configCanceledDataEdit,
        ...configDataEdit,
        ...configModifiedDataEdit,
      ];

      // Eliminar configuraciones duplicadas por ID
      const uniqueConfigurations = configurations.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      simulationDispatch({
        type: "SET_CONFIGURATIONS",
        payload: uniqueConfigurations,
      });
    }
  }, [informationsColumns, dataGetSimulationById]);

  /************************ MANEJO DE FUNCIONES ********************/

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleGoBack = () => {
    navigate("/delta/simulaciones");
    localStorage.removeItem("configMode");
    localStorage.removeItem("simulationId");
    localStorage.removeItem("analysisId");
  };

  const validateContinueStepper = () => {
    if (
      activeStep === 0 &&
      (simulationState.nombre === "" ||
        (configMode === "EDIT"
          ? dataGetSimulations &&
            dataGetSimulationById &&
            isDuplicatedName(
              simulationState.nombre,
              dataGetSimulations.filter(
                (simulation) =>
                  simulation.nombre.trim().toLowerCase() !==
                  dataGetSimulationById?.nombre?.trim().toLowerCase()
              )
            )
          : dataGetSimulations &&
            isDuplicatedName(simulationState.nombre, dataGetSimulations)) ||
        !simulationState.salesForecast ||
        !dataGetSimulationsInformation
          ?.filter((info) => info.obligatorio_simulacion)
          ?.every((item) =>
            simulationState?.principalColumns?.find(
              (stateItem) => stateItem.informacion === item.id
            )
          ))
    ) {
      return true;
    } else if (activeStep === 1 && simulationState.configurations.length < 1) {
      return true;
    }
    return false;
  };
  const handleSendInformationsValues = () => {
    const columnas_principales = simulationState.principalColumns.map(
      (column) => {
        return {
          informacion: column.informacion,
          columna: column.valor
            ? undefined
            : column.is_medida
            ? column.columna.value
            : column.columna.label,
          is_medida: column.valor ? null : column.is_medida,
          valor: column.valor && column.valor,
        };
      }
    );
    const analysisLabel = dataGetSimulationsRules.find(
      (rule) => rule.id === simulationState.regla.value
    )?.analisis;
    const analysisId =
      analysisLabel &&
      dataGetSalesForecast?.concat(dataGetCostsForecast)?.find(
        (salesForecast) => salesForecast.analisis === analysisLabel
      )?.analisis_id;
    const newConfig = {
      analisis: analysisId,
      columnas_principales,
    };
    postInformationsValues("/simulador/valor_informacion/", newConfig);
  };

  const deleteTypeFlag = (array: any) => {
    const newArray = array.map((item) => {
      const newItem = { ...item };
      delete newItem.tipo;
      delete newItem.id;
      return newItem;
    });
    return newArray;
  };

  const handleFinish = () => {
    const newDataCanceledRoutes = simulationState.configurations
      ?.filter((config) => config.tipo === "baja")
      ?.map((item) => {
        const newRutas = item.rutas.map((ruta) => {
          return {
            ruta: ruta[
              getInformationColumn(
                informationsColumns,
                "Identificador único de ruta"
              )
            ],
            retencion: ruta.retencion,
          };
        });

        return {
          ...item,
          rutas: newRutas,
        };
      });

    const data = new Set();
    const codRutaColumn = getInformationColumn(
      informationsColumns,
      "Identificador único de ruta"
    );
    simulationState.configurations
      ?.filter((config) => config.tipo === "modificacion")
      ?.forEach((config) => {
        const routes = config.rutas.map((ruta) => ruta);

        config.rutas.forEach((ruta) => {
          const columnsToTransform = Object.keys(ruta).filter(
            (key) => key !== "id" && key !== codRutaColumn && key !== "filtros"
          );

          columnsToTransform.forEach((column) => {
            if (
              modificationTypes.find(
                (type) =>
                  type.informacion ===
                  getInformationId(informationsColumns, column)
              )
            ) {
              const info = {
                rutas: routes.map((route) => {
                  return {
                    ruta: route[codRutaColumn],
                    valor_original: route[`${column}_OLD`],
                  };
                }),
                tipo_modificacion:
                  modificationTypes &&
                  modificationTypes.find(
                    (type) =>
                      type.informacion ===
                      getInformationId(informationsColumns, column)
                  ).id,
                valor: ruta[column],
                valor_original: ruta[`${column}_OLD`],
                filtros: ruta.filtros,
              };
              // Serializar el objeto info y agregarlo al conjunto
              const serializedInfo: any = JSON.stringify(info);
              data.add(serializedInfo);
            }
          });
        });
      });

    // Ahora rutas_modificacion es un conjunto que contiene objetos únicos serializados
    // Para obtener los objetos deserializados, puedes convertir cada elemento de vuelta a objeto
    const uniqueObjects = Array.from(data).map((serializedInfo) =>
      JSON.parse(String(serializedInfo))
    );
    const newDataModifiedRoutes: any = uniqueObjects;

    const columnas_principales = simulationState.principalColumns.map(
      (column) => {
        return {
          informacion: column.informacion,
          columna: column.valor
            ? undefined
            : column.is_medida
            ? column.columna.value
            : column.columna.label,
          is_medida: column.valor ? null : column.is_medida,
          valor: column.valor && column.valor,
        };
      }
    );

    const newDataRegisteredRoutes = simulationState.configurations
      .filter((config) => config.tipo === "alta")
      .map((obj) => {
        // Mapear sobre la propiedad 'parametros' de cada objeto
        const nuevosParametros = obj.parametros.map((param) => {
          // Retornar un nuevo objeto con solo la clave 'id'
          return {
            clave: param.clave.id,
            valor: param.valor,
            valor_ajustado: param.valor_ajustado,
          };
        });
        // Retornar un nuevo objeto con la propiedad 'parametros' modificada
        return {
          ...obj,
          parametros: nuevosParametros,
        };
      });

    const newConfig = {
      nombre: simulationState.nombre,
      tipo: "RUTAS",
      regla: simulationState.regla.value,
      ejecucion_automatica: simulationState.ejecucion_automatica,
      columnas_principales,
      rutas_baja: newDataCanceledRoutes
        ? deleteTypeFlag(newDataCanceledRoutes)
        : [],
      rutas_alta: deleteTypeFlag(newDataRegisteredRoutes),
      rutas_modificacion: deleteTypeFlag(newDataModifiedRoutes),
    };

    if (configMode === "EDIT") {
      putSimulation(GET_SIMULATION_URL(Number(simulationId)), newConfig);
      return;
    } else {
      postSimulation(POST_SIMULATIONS_URL, newConfig);
      return;
    }
  };

  return (
    <ComponentLayout
      title={t("deltaTexts.simulation.simulationConfig.simulationConfig")}
      icon={
        <ArrowBackIcon
          sx={{
            fontSize: "35px",
            marginRight: "10px",
            "& :hover": { cursor: "pointer", color: "#f90d4a" },
          }}
          onClick={handleGoBack}
        />
      }
    >
      <SimpleBackdrop
        open={
          isLoadingGetAnalysisById ||
          isLoadingSimulations ||
          isLoadingGetSalesForecasts ||
          isLoadingGetCostsForecasts ||
          isLoadingSimulationsInformation ||
          isLoadingSimulationsRules ||
          isLoadingPostSimulationConfig ||
          isLoadingPutSimulationConfig ||
          isLoadingGetModificationTypes ||
          isLoadingGetSimulationById ||
          isLoadingInformationsValues
        }
        message={t("general.loading")}
      />
      <Stack
        sx={{
          width: "100%",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Stack
          sx={{
            height: "100%",
            width: "100%",
            pl: 10,
            pr: 10,
            pt: 2,
            gap: 2,
          }}
        >
          {activeStep === 0 && (
            <Step1SimulationConfig
              dataGetSalesForecast={dataGetSalesForecast}
              dataGetCostsForecast={dataGetCostsForecast}
              dataGetSimulationsRules={dataGetSimulationsRules}
              dataGetSimulationsInformation={dataGetSimulationsInformation}
              dataGetAnalysisById={dataGetAnalysisById}
              dataGetSimulations={dataGetSimulations}
              dataGetSimulationById={dataGetSimulationById}
              simulationRuleSelectedOption={simulationRuleSelectedOption}
              setSimulationRuleSelectedOptions={
                setSimulationRuleSelectedOptions
              }
              simulationRuleSelectedChangeHandler={
                simulationRuleSelectedChangeHandler
              }
            />
          )}
          {activeStep === 1 && (
            <Step2SimulationConfig
              dataGetAnalysisById={dataGetAnalysisById}
              informationsColumns={informationsColumns}
              modificationTypes={modificationTypes}
              dataGetSimulationsInformation={dataGetSimulationsInformation}
            />
          )}
        </Stack>
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            pt: activeStep === 1 ? 5 : 2,
          }}
        >
          {activeStep !== 0 && (
            <CustomButton
              title={t("simulatorTexts.registerRoutes.newRouteModal.goBack")}
              color="grey"
              type="button"
              onClick={handleBack}
            />
          )}
          {activeStep === 0 && (
            <CustomButton
              title={t(
                "simulatorTexts.simulationSchema.simulationSchemaConfig.cancel"
              )}
              color="grey"
              type="button"
              onClick={handleGoBack}
            />
          )}
          {activeStep === 1 ? (
            <CustomButton
              title={t("simulatorTexts.registerRoutes.newRouteModal.finish")}
              color="blue-greeny"
              type="button"
              onClick={() => handleFinish()}
              disabled={validateContinueStepper()}
            />
          ) : (
            <CustomButton
              title={t("simulatorTexts.registerRoutes.newRouteModal.continue")}
              color="blue"
              type="button"
              onClick={() => handleSendInformationsValues()}
              disabled={validateContinueStepper()}
            />
          )}
        </Stack>
      </Stack>
    </ComponentLayout>
  );
};

export default SimulationConfig;
