import { form_subtitle_styles } from "../../../../../styles/app-styles";
import { GET_CRITERIA_FILE_COLUMN_URL } from "../../../../../api/axios";
import { selectStyles } from "../../../../criterias/configCriteria/select.styles";
import { useApiQuery } from "../../../../../hooks/useApiQuery";
import Box from "@mui/material/Box";
import Button from "../../../../buttons/Button";
import ConfirmationModal from "../../../../dialog/ConfirmationModal";
import FormSelectLayout from "../../../../forms/FormSelectLayout";
import GroupByRowSelects from "./GroupByRowSelects";
import Info from "../../../../icons/Info";
import InfoGrid from "../../../../basicGrid/InfoGrid";
import RowGroupBy from "./RowGroupBy";
import RowTitles from "../../../../basicGrid/RowTitles";
import Select from "../../../../forms/Select";
import ShadowBox from "../../../groupManagement/ShadowBox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useDialog from "../../../../../hooks/useDialog";
import useLanguage from "../../../../../context/LanguageProvider";
import {
  columnOptions,
  fileOptions,
} from "../../../../criterias/configCriteria/selectOptions";
interface OtherFontStep1Props {
  groupByRows: any[];
  setGroupByRows: any;
  fileSelectRef: any;
  fileSelectedOption: any;
  fileChangeHandler: any;
  columnsActualBaseSelectRef: any;
  columnsNewBaseSelectRef: any;
  columnsActualBaseSelectedOption: any;
  columnsActualBaseChangeHandler: any;
  columnsNewBaseSelectedOption: any;
  columnsNewBaseChangeHandler: any;
  columnSelectRef: any;
  columnSelectedOption: any;
  setColumnSelectedOption: any;
  columnOptionsChangeHandler: any;
  functionSelectRef: any;
  functionSelectedOption: any;
  functionOptionsChangeHandler: any;
  message: string;
  setMessage: any;
  handleAddGroupByRow: any;
  handleDeleteAllRows: any;
}

const OtherFontStep1 = ({
  groupByRows,
  setGroupByRows,
  fileSelectRef,
  fileSelectedOption,
  fileChangeHandler,
  columnsActualBaseSelectRef,
  columnsActualBaseSelectedOption,
  columnsActualBaseChangeHandler,
  columnsNewBaseSelectRef,
  columnsNewBaseSelectedOption,
  columnsNewBaseChangeHandler,
  columnSelectRef,
  columnSelectedOption,
  setColumnSelectedOption,
  columnOptionsChangeHandler,
  functionSelectRef,
  functionSelectedOption,
  functionOptionsChangeHandler,
  message,
  setMessage,
  handleAddGroupByRow,
  handleDeleteAllRows,
}: OtherFontStep1Props) => {
  const { t } = useLanguage();
  const [openConfirmResetGroupByModal, toggleConfirmResetGroupByModal] =
    useDialog();

  /***************************** INFO PREVIA ****************************/

  const { data: dataGetFileColumn } = useApiQuery(
    GET_CRITERIA_FILE_COLUMN_URL,
    false,
    t('configCriteria.getCriteriaFileColumn.error')
  );

  const handleResetData = () => {
    setColumnSelectedOption(undefined);
    columnSelectRef.current?.clearValue();
    setGroupByRows([]);
    setMessage("");
    toggleConfirmResetGroupByModal();
  };

  return (
    <Stack
      sx={{
        width: "90%",
        alignItems: "center",
        gap: 2,
      }}
    >
      <ShadowBox>
        <Box sx={{ width: "100%", marginBottom: "10px" }}>
          <FormSelectLayout
            title={t(
              "dataModelText.measureManagement.configMeasure.otherFontStep1.file"
            )}
            required={true}
          >
            <Select
              reference={fileSelectRef}
              styles={selectStyles(fileSelectedOption)}
              options={fileOptions(dataGetFileColumn)?.filter(
                (option) =>
                  option.label !== "VENTAS" && option.label !== "GASTOS"
              )}
              name="Archivo"
              onChange={fileChangeHandler}
              closeMenuOnSelect
              placeholder={t(
                "dataModelText.measureManagement.configMeasure.otherFontStep1.selectFile"
              )}
              isClearable
              defaultValue={fileSelectedOption}
              isDisabled={false}
            />
          </FormSelectLayout>
        </Box>
      </ShadowBox>
      <ShadowBox>
        <Stack direction={"row"} gap={4}>
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <FormSelectLayout
              title={t(
                "dataModelText.measureManagement.configMeasure.otherFontStep1.mergeColumnsCurrentBase"
              )}
              required={true}
            >
              <Select
                isMulti
                reference={columnsActualBaseSelectRef}
                styles={selectStyles(columnsActualBaseSelectedOption)}
                options={columnOptions(dataGetFileColumn, fileSelectedOption)}
                name={t(
                  "dataModelText.measureManagement.configMeasure.otherFontStep1.mergeColumnsCurrentBase"
                )}
                onChange={columnsActualBaseChangeHandler}
                closeMenuOnSelect={false}
                placeholder={t(
                  "dataModelText.measureManagement.configMeasure.otherFontStep1.chooseColumns"
                )}
                isClearable
                defaultValue={columnsActualBaseSelectedOption}
                isDisabled={false}
              />
            </FormSelectLayout>
          </Box>
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <FormSelectLayout
              title={t(
                "dataModelText.measureManagement.configMeasure.otherFontStep1.mergeColumnsSalesBase"
              )}
              required={true}
            >
              <Select
                isMulti
                reference={columnsNewBaseSelectRef}
                styles={selectStyles(columnsNewBaseSelectedOption)}
                options={columnOptions(
                  dataGetFileColumn,
                  fileOptions(dataGetFileColumn)?.filter(
                    (option) => option.label === "VENTAS"
                  )[0]
                )}
                name={t(
                  "dataModelText.measureManagement.configMeasure.otherFontStep1.mergeColumnsSalesBase"
                )}
                onChange={columnsNewBaseChangeHandler}
                closeMenuOnSelect={false}
                placeholder={t(
                  "dataModelText.measureManagement.configMeasure.otherFontStep1.chooseColumns"
                )}
                isClearable
                defaultValue={columnsNewBaseSelectedOption}
                isDisabled={false}
              />
            </FormSelectLayout>
          </Box>    
        </Stack>
        {columnsActualBaseSelectedOption?.length !== 0 &&
          columnsNewBaseSelectedOption?.length !== 0 &&
          columnsActualBaseSelectedOption?.length !==
            columnsNewBaseSelectedOption?.length && (
            <Typography
              variant="subtitle1"
              color="error"
              sx={{ fontWeight: "bold", textAlign: "center", height: "30px" }}
            >
              {t(
                "dataModelText.measureManagement.configMeasure.otherFontStep1.numberColumnsSelectedEqualSalesBase"
              )}
            </Typography>
          )}
      </ShadowBox>
      <Box sx={{ ...form_subtitle_styles, width: "100%" }}>
        {t(
          "dataModelText.measureManagement.configMeasure.otherFontStep1.columnsToAddFunctionAggregation"
        )}
        <Info
          text={t(
            "dataModelText.measureManagement.configMeasure.otherFontStep1.avoidDuplicatingValues"
          )}
          color="blue"
        />
      </Box>
      <ShadowBox>
        <Stack
          sx={{
            pl: "10px",
            pr: "10px",
            gap: 1,
          }}
        >
          <Typography
            variant="subtitle1"
            color="error"
            sx={{ fontWeight: "bold", height: "25px", textAlign: "center" }}
          >
            {message}
          </Typography>
          <InfoGrid
            info={groupByRows}
            RowTitles={RowTitles}
            RowSelects={GroupByRowSelects}
            RowInfo={RowGroupBy}
            columns={columnOptions(dataGetFileColumn, fileSelectedOption)}
            columnSelectRef={columnSelectRef}
            columnSelectedOption={columnSelectedOption}
            columnOptionsChangeHandler={columnOptionsChangeHandler}
            functionSelectRef={functionSelectRef}
            functionSelectedOption={functionSelectedOption}
            functionOptionsChangeHandler={functionOptionsChangeHandler}
            handleAddGroupByRow={handleAddGroupByRow}
            handleDeleteAllRows={handleDeleteAllRows}
            groupByRows={groupByRows}
            setGroupByRows={setGroupByRows}
          />
          <Stack
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              mt: "10px",
            }}
          >
            <Button
              title={t(
                "dataModelText.measureManagement.configMeasure.otherFontStep1.reset"
              )}
              color="grey"
              type="button"
              onClick={toggleConfirmResetGroupByModal}
              disabled={!groupByRows || groupByRows.length === 0}
            />
          </Stack>
        </Stack>
      </ShadowBox>
      <ConfirmationModal
        open={openConfirmResetGroupByModal}
        handleClose={toggleConfirmResetGroupByModal}
        handleAccept={handleResetData}
        title={t(
          "dataModelText.measureManagement.configMeasure.otherFontStep1.resetData"
        )}
        message={t(
          "dataModelText.measureManagement.configMeasure.otherFontStep1.deleteAllData"
        )}
      />
    </Stack>
  );
};

export default OtherFontStep1;
