import { criteriaOptions } from "../selectOptions";
import { grid_item_element_styles } from "../../../../../styles/app-styles";
import { selectStyles } from "../../../configCriteria/select.styles";
import { Stack } from "@mui/material";
import { useRef } from "react";
import FormSelectLayout from "../../../../forms/FormSelectLayout";
import Select from "../../../../forms/Select";
import useLanguage from "../../../../../context/LanguageProvider";
import WhiteShadowBox from "../../../../layout/WhiteShadowBox";

const ImplicitAssign = ({
  columnSelectRef,
  criteriaData,
  criteriaSelectedOption,
  criteriaSelectedOptionChangeHandler,
  columnSelectedOption,
  columnSelectedOptionChangeHandler
}) => {
  const { t } = useLanguage();
  
  const criteriaSelectRef: any = useRef();

  /**************************************** Validaciones *******************************************/

  const getBackgroundColor = (type) => {
    switch (type) {
      case "iteration":
        return "var(--bg-main)";
      case "match":
        return "var(--bg-success)";
      case "criteria_zero":
        return "var(--chart-23)";
      case "change_criteria":
        return "var(--icon-info)";
      case "header":
        return "var(--bg-secondary)";
      default:
        break;
    }
  };

  return (
    <WhiteShadowBox>
      <Stack gap={0.7} width="100%">
        <Stack
          sx={{
            ...grid_item_element_styles(
              getBackgroundColor,
              "iteration",
              () => "100%"
            ),
            color: "white",
            minHeight: "30px",
            borderRadius: "4px",
          }}
        >
          Iteración 0
        </Stack>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          spacing={1}
          width={"100%"}
        >
          <Stack width={"50%"}>
            <FormSelectLayout title={t("Criterio")} margin="0 0 0 0">
              <Select
                value={criteriaSelectedOption}
                reference={criteriaSelectRef}
                styles={selectStyles(criteriaSelectedOption)}
                options={criteriaOptions(criteriaData)}
                onChange={criteriaSelectedOptionChangeHandler}
                closeMenuOnSelect
                placeholder={t(
                  "preprorationParameterization.newSchemaContent.chooseCriteria"
                )}
                defaultValue={criteriaSelectedOption}           
              />
            </FormSelectLayout>
          </Stack>

          <Stack sx={{ width: "50%" }}>
            <FormSelectLayout
              title={t("Columna con receptor")}
              margin="0 0 0 0"
            >
              <Select
                reference={columnSelectRef}
                styles={selectStyles(columnSelectedOption)}
                options={
                  criteriaData?.find(
                    (criteria) => criteria.id === criteriaSelectedOption?.value
                  )?.columnas
                }
                onChange={columnSelectedOptionChangeHandler}
                closeMenuOnSelect
                placeholder={t(
                  "preprorationParameterization.newSchemaContent.chooseCriteria"
                )}
                defaultValue={columnSelectedOption}
                isClearable
              />
            </FormSelectLayout>
          </Stack>
        </Stack>
      </Stack>
    </WhiteShadowBox>
  );
};

export default ImplicitAssign;
