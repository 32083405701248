import { Stack, Typography } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import FilterForm from '../../../../../dataModel/downloadReport/FilterForm'
import useSingleSelect from '../../../../../../hooks/useSingleSelect';
import useApi from '../../../../../../hooks/useApi';
import { INCOMING_VALUES_SALES_FORECAST } from '../../../../../../api/axios';
import { IdOption } from '../../../../../criterias/configCriteria/types';
import useLanguage from '../../../../../../context/LanguageProvider';
import SimpleBackdrop from '../../../../../backdrop/SimpleBackdrop';
import SimulationProvider from "../../../../../../context/SimulationProvider";

const Step1NewModifyRouteModal = ({ modifyFilters, setModifyFilters, dataGetAnalysisById }: any) => {

    const { t } = useLanguage();
    const analysisId = localStorage.getItem("analysisId") ? localStorage.getItem("analysisId") : undefined
    const { simulationState } = useContext<React.ContextType<typeof SimulationProvider>>(SimulationProvider);
    const columnSelectRef: any = useRef(null);
    const [valuesSelectedOptions, setValuesSelectedOptions] = useState<IdOption[]>();

    /********************************* CARGA Y OBTENCIÓN DE DATOS *****************************/

    const onColumnChange = () => {
        setSelectedValue([]);
        setValuesSelectedOptions(undefined);
    };

    const [
        columnFilterSelectedOptions,
        setColumnFilterSelectedOptions,
        columnFilterOptionsChangeHandler,
    ] = useSingleSelect(() => onColumnChange());

    const [incomingValuesOptions, setIncomingValuesOptions] = useState<any[]>([]);
    const [selectedValue, setSelectedValue] = useState<string[] | null>([]);
    const [isLoadingIncomingValuesOptions, setIsLoadingIncomingValuesOptions] =
        useState(false);
    const onSuccessIncomingValuesOptionsResponse = (data: any) => {
        setIncomingValuesOptions(data?.valores)
        setIsLoadingIncomingValuesOptions(false);
    }

    const {
        callApi: postGetIncomingValues,
    } = useApi(
        "",
        "POST",
        undefined,
        undefined,
        onSuccessIncomingValuesOptionsResponse,
        undefined,
        false
    );
    
    useEffect(() => {
        if (columnFilterSelectedOptions && simulationState.salesForecast) {
            setIsLoadingIncomingValuesOptions(true);
            const filtros = {};
            modifyFilters && modifyFilters.forEach(item => {
                const { column, values } = item;
                filtros[column.label] = values.map((value) => value.value);
            });
            const filterBody = {
                filtros,
                columna: columnFilterSelectedOptions?.label
            }
            postGetIncomingValues(INCOMING_VALUES_SALES_FORECAST(simulationState.salesForecast.id && simulationState.salesForecast.id), filterBody);
        } else {
            setIncomingValuesOptions([]);
        }
    }, [columnFilterSelectedOptions, simulationState.salesForecast]);

    const columnLabels = dataGetAnalysisById && modifyFilters && dataGetAnalysisById?.agrupacion?.columnas_a_usar?.filter((opcion) => {
        return !modifyFilters.some((param) => param.column.label === opcion.label);
    })

    return (
        <>
            <SimpleBackdrop open={isLoadingIncomingValuesOptions} message={t("simulatorTexts.canceledRoutes.newCancelRouteModal.loading")} />
            <Typography sx={{
                width: "100%",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                {t("simulatorTexts.registerRoutes.step1NewRouteModal.paramSelection")}
            </Typography>
            <Stack sx={{
                minWidth: "1000px",
                flexDirection: "column",
                justifyContent: "space-around",
                gap: "20px",
                p: "10px 50px",
            }}>
                <FilterForm filters={modifyFilters} setFilters={setModifyFilters} setColumnFilterSelectedOptions={setColumnFilterSelectedOptions} columnFilterSelectedRef={columnSelectRef} columnLabels={columnLabels} columnFilterOptionsChangeHandler={columnFilterOptionsChangeHandler} columnFilterSelectedOption={columnFilterSelectedOptions} analysisSelectedOption={analysisId} selectedValues={selectedValue} incomingValuesOptions={incomingValuesOptions} setValuesSelectedOptions={setValuesSelectedOptions} setSelectedValues={setSelectedValue} isLoadingIncomingValuesOptions={isLoadingIncomingValuesOptions} valuesSelectedOptions={valuesSelectedOptions} selectOneOption={true} title={" "} />
                {modifyFilters?.length === 0 && (
                    <Stack
                        sx={{
                            width: "95%",
                            height: "7vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: "700", color: "var(--text-info)" }}
                        >
                            {t("simulatorTexts.registerRoutes.step1NewRouteModal.noFilterYet")}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </>
    )
}

export default Step1NewModifyRouteModal