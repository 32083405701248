import { back_arrow_styles } from "../../styles/app-styles";
import { Tab, TabValue } from "../colorTabs/types";
import { useState } from "react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ColorTabs from "../colorTabs/ColorTabs";
import CompaniesView from "./Companies/CompaniesView";
import ComponentLayout from "../layout/ComponentLayout";
import RolesView from "./Roles/RolesView";
import Stack from "@mui/material/Stack";
import useLanguage from "../../context/LanguageProvider";
import UsersView from "./Users/UsersView";

const AdministratorPanel = () => {
  const { t } = useLanguage();
  const [tabValue, setTabValue] = useState<TabValue>(
    t("administratorPanel.usersLabel")
  );
  const [userConfigOpen, setUserConfigOpen] = useState<boolean>(false);
  const [companyConfigOpen, setCompanyConfigOpen] = useState<boolean>(false);
  const [rolConfigOpen, setRolConfigOpen] = useState<boolean>(false);
  const tabs: Tab[] = [
    {
      value: t("administratorPanel.usersLabel"),
      label: t("administratorPanel.usersLabel"),
    },
    {
      value: t("administratorPanel.companiesLabel"),
      label: t("administratorPanel.companiesLabel"),
    },
    {
      value: t("administratorPanel.rolesLabel"),
      label: t("administratorPanel.rolesLabel"),
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
  };

  const handleBack = () => {
    userConfigOpen && setUserConfigOpen(false);
    companyConfigOpen && setCompanyConfigOpen(false);
    rolConfigOpen && setRolConfigOpen(false);
  };

  return (
    <ComponentLayout
      title={t("administratorPanel.administratorPanel")}
      icon={
        userConfigOpen || companyConfigOpen || rolConfigOpen ? (
          <ArrowBackIcon sx={back_arrow_styles} onClick={handleBack} />
        ) : (
          <AdminPanelSettingsIcon />
        )
      }
    >
      <Stack spacing={2} sx={{ width: "100%", alignItems: "center" }}>
        {!userConfigOpen && !companyConfigOpen && (
          <ColorTabs
            value={tabValue}
            handleChange={handleChange}
            tabs={tabs}
            marginBottom="0px"
          />
        )}
        {tabValue === t("administratorPanel.companiesLabel") && (
          <CompaniesView
            setConfigOpen={setCompanyConfigOpen}
            configOpen={companyConfigOpen}
          />
        )}
        {tabValue === t("administratorPanel.usersLabel") && (
          <UsersView
            setConfigOpen={setUserConfigOpen}
            configOpen={userConfigOpen}
          />
        )}
        {tabValue === t("administratorPanel.rolesLabel") && (
          <RolesView
            setConfigOpen={setRolConfigOpen}
            configOpen={rolConfigOpen}
          />
        )}
      </Stack>
    </ComponentLayout>
  );
};

export default AdministratorPanel;
