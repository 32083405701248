import { useContext, useEffect, useState } from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import useLanguage from "../../../context/LanguageProvider";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { transformTableDataPhiBot } from "../utils";
import ChatbotChart from "./ChatbotChart";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridColDef } from "@mui/x-data-grid";
import CustomToolbar from "../../customToolbar/CustomToolbar";
import ChatBotProvider from "../../../context/ChatBotProvider";
import { SEND_TO_CHART_CHATBOT } from "../../../api/axios";
import PersonIcon from "@mui/icons-material/Person";
import useAuth from "../../../hooks/useAuth";
import ResponseFeedback from "./ResponseFeedback";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
interface MessageBubbleProps {
  message_id: number;
  message: string;
  table?: any;
  chart_types?: string[];
  chart?: any;
  postGraphMessage?: any;
  isSent: boolean;
  options: string[];
  resetOptions?: boolean;
  maxWidth?: string;
  useful?: boolean | null;
  is_valid?: boolean;
  query?: string;
}

const MessageBubble = ({
  message_id,
  message,
  table,
  chart_types,
  chart,
  postGraphMessage,
  isSent,
  options,
  resetOptions,
  maxWidth,
  useful,
  is_valid,
  query,
}: MessageBubbleProps) => {
  const { t } = useLanguage();
  const [optionsAreDisabled, setOptionsAreDisables] = useState(false);
  const { chatBotState, chatBotDispatch } =
    useContext<React.ContextType<typeof ChatBotProvider>>(ChatBotProvider);
  const { auth }: any = useAuth();
  const [columns, setColumns] = useState<GridColDef<any, any, any>[]>([
    {
      field: "",
      headerName: "",
      width: 0,
      renderCell: () => <></>,
    },
  ]);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (table) {
      const { columns, rows } = transformTableDataPhiBot(table);
      setRows(rows), setColumns(columns);
    }
  }, [table]);

  const handleClickOption = () => {
    setOptionsAreDisables(true);
  };

  const handleSelectChartType = (type, message_id) => {
    const chatbotMessage = chatBotState.messages.find(
      (chat) => chat.message_id === message_id
    );
    const table = chatbotMessage && chatbotMessage.table;

    const newConfig = {
      chat_id: chatBotState.historyTitle.value,
      message: chatBotState?.principalQuestion,
      chart_type: type,
      table: table && table,
    };

    setOptionsAreDisables(true);

    const newMessage = {
      message: `Graficar ${type}`,
      isSent: true,
    };

    chatBotDispatch({ type: "SET_MESSAGE", payload: newMessage });
    postGraphMessage(SEND_TO_CHART_CHATBOT, newConfig);
  };

  useEffect(() => {
    if (resetOptions) {
      setOptionsAreDisables(false);
    }
  }, [resetOptions]);

  useEffect(() => {
    if (chatBotState.messages) {
      if (
        chatBotState.messages[chatBotState.messages.length - 1].message_id ===
        message_id
      ) {
        setOptionsAreDisables(false);
      } else {
        setOptionsAreDisables(true);
      }
    }
  }, [chatBotState.messages]);

  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: "8px",
        alignItems: "center",
        justifyContent: "flex-start",
        mt: isSent ? "15px" : "",
        mb: isSent ? "15px" : "",
        flexDirection: isSent ? "row-reverse" : "row",
        "&:hover": {
          ".response-feedback": {
            opacity: 1,
          },
        },
      }}
    >
      {!isSent ? (
        <Box sx={{ marginRight: "8px", mt: "2px" }}>
          <Tooltip title={"PHITO"}>
            <SmartToyIcon color={"primary"} />
          </Tooltip>
        </Box>
      ) : (
        <Box sx={{ marginLeft: "8px", mt: "2px" }}>
          <Tooltip title={auth?.user.split("@")[0].toUpperCase()}>
            <PersonIcon color={"primary"} sx={{ fontSize: "30px" }} />
          </Tooltip>
        </Box>
      )}
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          alignItems: isSent ? "flex-end" : "flex-start",
          height: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: isSent ? "var(--bg-success)" : "var(--light-grey)",
            color: isSent ? "white" : "var(--text-main)",
            maxWidth: maxWidth ? maxWidth : "350px",
            padding: "8px 12px",
            borderRadius: "16px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            display:
              message === "" && !table && !chart ? "none" : "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        >
          {query && (
            <>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "600",
                }}
              >
                {t("phibot.messageBubble.sqlQuery")}
              </Typography>
              <Box
                component="pre"
                sx={{
                  backgroundColor: "#eaeaea",
                  padding: "0px 25px 15px 25px",
                  borderRadius: "8px",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  fontFamily: "'Courier New', Courier, monospace",
                  fontSize: "14px",
                  width: "100%",
                  overflowX: "auto",
                }}
              >
                <code>{query}</code>
              </Box>
            </>
          )}
          <Typography
            variant="body2"
            sx={{
              fontWeight: "600",
            }}
          >
            {message && message.trim()}
          </Typography>
          {table && rows && columns && (
            <Stack
              sx={{
                height: `${125 + rows.length * 60}px`,
                minHeight: "125px",
                maxHeight: "650px",
                width: "100%",
                p: "25px 0px 25px 15px",
              }}
            >
              <DataGridPro
                rows={rows}
                columns={columns}
                hideFooter
                disableSelectionOnClick
                sx={{
                  boxShadow: 3,
                  fontSize: "12px",
                  minHeight: "125px",
                  height: "100%",
                }}
                disableColumnMenu={true}
                components={{
                  Toolbar: () => (
                    <CustomToolbar
                      toolbar={true}
                      exportButton={true}
                      widthButtons={"125px"}
                    />
                  ),
                }}
              />
            </Stack>
          )}
          {chart_types && chart_types.length > 0 && (
            <Box
              sx={{
                color: "var(--text-main)",
                width: maxWidth ? maxWidth : "350px",
                padding: "8px 25px 8px 12px",
                borderRadius: "16px",
                mt: "10px",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "600",
                }}
              >
                {t("phibot.messageBubble.selectOptionChart")}
              </Typography>
              <Stack
                sx={{
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "100%",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  pt: "15px",
                }}
              >
                {chart_types.map((type, index) => (
                  <Box
                    key={index}
                    sx={{
                      color: "var(--text-main)",
                      borderRadius: "16px",
                      padding: "8px 12px",

                      cursor: !optionsAreDisabled ? "pointer" : "",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                      "&:hover": {
                        backgroundColor: optionsAreDisabled
                          ? ""
                          : "rgba(0, 0, 0, 0.35)",
                      },
                      "&:active": {
                        backgroundColor: optionsAreDisabled
                          ? ""
                          : "rgba(0, 0, 0, 0.35)",
                      },
                      textAlign: "center",
                      fontWeight: "600",
                      userSelect: "none",
                    }}
                    onClick={() =>
                      optionsAreDisabled
                        ? undefined
                        : handleSelectChartType(type, message_id)
                    }
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "600",
                        userSelect: "none",
                      }}
                    >
                      {type.toUpperCase()}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Box>
          )}
          {chart ? (
            chart.status && chart.status === "Success" ? (
              <Stack
                sx={{
                  p: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "600",
                    pb: 2,
                  }}
                >
                  {t("phibot.messageBubble.chartMessage")}
                </Typography>
                <ChatbotChart optionsData={chart.parsedData} />
              </Stack>
            ) : chart.status && chart.status === "Error" ? (
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                  gap: 1,
                }}
              >
                <ErrorOutlineIcon color="error" />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "600",
                  }}
                >
                  {t("phibot.messageBubble.chartError")}
                </Typography>
              </Stack>
            ) : (
              <Stack
                sx={{
                  p: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "600",
                    pb: 2,
                  }}
                >
                  {t("phibot.messageBubble.chartMessage")}
                </Typography>
                <ChatbotChart optionsData={chart} />
              </Stack>
            )
          ) : (
            <></>
          )}
        </Box>
        {options && options.length > 0 && (
          <>
            <Box
              sx={{
                backgroundColor: "var(--light-grey)",
                color: "var(--text-main)",
                maxWidth: maxWidth ? maxWidth : "350px",
                padding: "8px 12px",
                borderRadius: "16px",
                mt: "5px",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "600",
                }}
              >
                {t("phibot.messageBubble.selectOption")}
              </Typography>
            </Box>
            <Stack
              sx={{
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
                maxWidth: "450px",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: "8px",
                pt: "5px",
              }}
            >
              {options.map((option, index) => (
                <Box
                  key={index}
                  sx={{
                    color: "var(--text-main)",
                    borderRadius: "16px",
                    padding: "8px 12px",
                    backgroundColor: "var(--light-grey)",
                    cursor: !optionsAreDisabled ? "pointer" : "",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    "&:hover": {
                      backgroundColor: optionsAreDisabled
                        ? ""
                        : "rgba(0, 0, 0, 0.35)",
                    },
                    "&:active": {
                      backgroundColor: optionsAreDisabled
                        ? ""
                        : "rgba(0, 0, 0, 0.35)",
                    },
                    textAlign: "center",
                    fontWeight: "600",
                    userSelect: "none",
                  }}
                  onClick={() => handleClickOption()}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "600",
                      userSelect: "none",
                    }}
                  >
                    {option}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </>
        )}
      </Box>
      {chatBotState.messages[0].message !== message && is_valid && (
        <Stack
          className="response-feedback"
          sx={{
            ml: "16px",
            flexDirection: "row",
            opacity:
              chatBotState.messages[chatBotState.messages.length - 1]
                .message_id === message_id
                ? 1
                : 0,
          }}
        >
          <ResponseFeedback message_id={message_id} useful={useful} />
        </Stack>
      )}
    </Box>
  );
};

export default MessageBubble;
