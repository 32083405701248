import { ActionMeta } from "react-select";
import { useRef, useState } from "react";
import Select from "../../../forms/Select";
import useLanguage from "../../../../context/LanguageProvider";
import {
  optionalSelectStyles,
  selectStyles,
} from "../../../criterias/configCriteria/select.styles";

const KeyColumnSingleSelect = ({
  defaultValue,
  columnKey,
  optional,
  setKeyColumns,
  dataGetParams,
  fileData,
}) => {
  const { t } = useLanguage();
  const keyColumnSelectRef: any = useRef(null);
  const [columnSelectedOption, setColumnSelectedOption] = useState<
    { value: string; label: string } | undefined
  >(defaultValue);

  const columnOptionsChangeHandler = (value: any, action: ActionMeta<any>) => {
    switch (action.action) {      case "select-option":
        
        setColumnSelectedOption(value);
        setKeyColumns((prev) => ({
          ...prev,
          [columnKey]: value.label,
        }));
        break;
      case "clear":
        setColumnSelectedOption(undefined);
        setKeyColumns((prev) => ({ ...prev, [columnKey]: "" }));
        break;
      default:
        break;
    }
  };

  return (
      <Select
        reference={keyColumnSelectRef}
        isClearable
        styles={
          optional
            ? optionalSelectStyles(columnSelectedOption)
            : selectStyles(columnSelectedOption)
        }
        options={
          fileData && fileData.columnas
            ? fileData.columnas?.map((item: any) => {
                return { value: item.id, label: item.nombre };
              })
            : []
        }
        className="pp_select_schema"
        id="destino"
        onChange={columnOptionsChangeHandler}
        closeMenuOnSelect
        isSearchable
        placeholder={t("general.chooseColumn")}
        defaultValue={
          dataGetParams && dataGetParams.columnas_clave[columnKey]
            ? {
                value: dataGetParams.columnas_clave[columnKey],
                label: dataGetParams.columnas_clave[columnKey],
              }
            : undefined
        }
        isDisabled={dataGetParams?.edicion_columnas_gasto === false}
      />      
  );
};

export default KeyColumnSingleSelect;
