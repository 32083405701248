import { Tooltip } from "@mui/material";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";

/**
 * @name transformDataPreview
 * @description Función que transforma los datos de la tabla preview de la vista de administración de datos.
 * @param dataColumnas 
 * @param dataFilas 
 */
export const transformDataPreview = (data: any) => {
  const arrayColumns = Object.keys(data[0]).map((columna) => ({
    field: columna,
    headerName: columna,
    headerClassName: "dm_header_column",
    description: columna,
    minWidth: 120,
    flex: 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong className="dm_column_title">{params.field}</strong>
    ),
    renderCell: (params: any) => (
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={`${params.value}`}
      >
        <p className="es_cellValue">{params.value}</p>
      </Tooltip>
    ),
  }));

  const arrayRows = data.map((fila: any, index: number) => {
    const obj: any = {};
    obj["id"] = `${index}-${fila._id}`;    
    for (const key in fila) {    
        obj[key] = fila[key];     
    }
    return obj;
  });

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
