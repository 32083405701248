import { Stack } from "@mui/material";
import { convertMarkdownToHTML } from "./utils";
import Dialog from "../dialog/Dialog";
import useLanguage from "../../context/LanguageProvider";

interface InfoChatBotModalProps {
  open: boolean;
  toggle: () => void;
}

const InfoChatBotModal = ({ open, toggle }: InfoChatBotModalProps) => {
  const { t } = useLanguage();

  return (
    <Dialog maxWidth="xl" title={"PHITO"} open={open} handleClose={toggle}>
      <Stack
        sx={{
          color: "var(--text-main)",
          width: "700px",
          pl: 2,
          pr: 2,
        }}
      >
        <div
          style={{ padding: 0 }}
          dangerouslySetInnerHTML={{
            __html:
              convertMarkdownToHTML(t("phibot.infoChatbotModal.chatbotMarkDown")),
          }}
        />
      </Stack>
    </Dialog>
  );
};

export default InfoChatBotModal;
