import { columnOptions, fileOptions } from "../../criterias/configCriteria/selectOptions";
import { selectStyles } from "../../criterias/configCriteria/select.styles";
import { SingleValue } from "react-select";
import Box from "@mui/material/Box";
import Button from "../../buttons/Button";
import FormSelectLayout from "../../forms/FormSelectLayout";
import Grid from "@mui/material/Grid";
import Select from "../../forms/Select";
import ShadowBox from "../../dataModel/groupManagement/ShadowBox";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../context/LanguageProvider";
import WaterfallGridSelects from "./WaterfallGridSelects";
import WaterfallGridTitles from "./WaterfallGridTitles";
import WaterfallRow from "./WaterfallRow";
import {
  form_steps_container,
  form_subtitle_styles,
} from "../../../styles/app-styles";
import {
  IdOption,
  IncomingFileOption,
  Message,
  OnChange,
} from "../../criterias/configCriteria/types";
import useDialog from "../../../hooks/useDialog";
import Dialog from "../../dialog/Dialog";
import EditRowModal from "./EditRowModal";
import { useRef, useState } from "react";
interface Step1WaterfallProps {
  chartName: string;
  setChartName: (value: string | number) => void;
  chartNameRef: React.RefObject<HTMLInputElement>;
  setChartFormComplete: React.Dispatch<React.SetStateAction<boolean>>;
  dataGetFileColumn: IncomingFileOption[];
  functionSelectOptions: string[];
  fileSelectedOptions: SingleValue<IdOption> | undefined;
  fileOptionsChangeHandler: OnChange;
  columnBaseSelectedOptions: SingleValue<IdOption> | undefined;
  columnBaseOptionsChangeHandler: OnChange;
  functionSelectedOptions: SingleValue<IdOption> | undefined;
  functionOptionsChangeHandler: OnChange;
  columnBaseSelectRef: React.RefObject<any>;
  fileSelectRef: React.RefObject<any>;
  functionSelectRef: React.RefObject<any>;
  activeIndicator: boolean;
  setActiveIndicator: (value: boolean | ((prev: boolean) => boolean)) => void;
  waterfallColumnNameRef: React.RefObject<HTMLInputElement>;
  waterfallColumnName: string;
  setWaterfallColumnName: (value: string) => void;
  handleAddWaterfallRow: any;
  waterfallRows: any;
  message: Message | undefined;
  handleDeleteAllRows: any;
  setWaterfallRows: any;
  setFunctionSelectedOptions: any;
  setColumnBaseSelectedOptions: any;
}

const Step1Waterfall = ({
  chartName,
  setChartName,
  chartNameRef,
  setChartFormComplete,
  dataGetFileColumn,
  functionSelectOptions,
  fileSelectedOptions,
  fileOptionsChangeHandler,
  columnBaseSelectedOptions,
  columnBaseOptionsChangeHandler,
  functionSelectedOptions,
  functionOptionsChangeHandler,
  columnBaseSelectRef,
  fileSelectRef,
  functionSelectRef,
  activeIndicator,
  setActiveIndicator,
  waterfallColumnNameRef,
  waterfallColumnName,
  setWaterfallColumnName,
  handleAddWaterfallRow,
  waterfallRows,
  message,
  handleDeleteAllRows,
  setWaterfallRows,
  setFunctionSelectedOptions,
  setColumnBaseSelectedOptions,
}: Step1WaterfallProps) => {
  const { t } = useLanguage();
  const [openEditingRowModal, toggleEditRow] = useDialog()
  const [rowToEdit, setRowToEdit] = useState<any>()

  //para el draggable
  const dragItem = useRef();
  const dragOverItem = useRef();

  //esto tambien para draggable waterfall row

  const onDropActions = (initialItem, finalItem) => {
    const finalIndex = waterfallRows.findIndex(item => item.columnName === finalItem.columnName);
    const initialIndex = waterfallRows.findIndex(item => item.columnName === initialItem.columnName);

    if (finalIndex !== initialIndex) {
      const updatedWaterfallRows = [...waterfallRows];
      const item = updatedWaterfallRows.splice(initialIndex, 1)[0];
      updatedWaterfallRows.splice(finalIndex, 0, item);
      setWaterfallRows(updatedWaterfallRows);
    }
  };

  const toggleActiveIndicator = () => {
    setActiveIndicator((prev: boolean) => !prev);
  };

  const resetRows = () => {
    setWaterfallRows([]);
    setWaterfallColumnName("");
    setFunctionSelectedOptions(undefined);
    setColumnBaseSelectedOptions(undefined);
  };

  const handleToggleEditRow = (row) => {
    setRowToEdit(row)
    toggleEditRow()
  }

  return (
    <Box sx={form_steps_container}>
      <ShadowBox margin={true}>
        <Typography variant="h2" sx={form_subtitle_styles}>
          {t("indicatorsManagementTexts.step1Charts.graphParameterization")}
        </Typography>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          gap={2}
        >
          <Box width="100%">
            <FormSelectLayout
              title={t("indicatorsManagementTexts.step1Charts.graphicTitle")}
              required={true}
            >
              <TextField
                ref={chartNameRef}
                placeholder={t(
                  "indicatorsManagementTexts.step1Charts.writeGraphicName"
                )}
                size="small"
                multiline
                variant="outlined"
                error={chartName === ""}
                fullWidth
                value={chartName}
                onChange={(e) => setChartName(e.target.value)}
                FormHelperTextProps={{
                  sx: {
                    color: "var(--text-error)",
                  },
                }}
                required
              />
            </FormSelectLayout>
          </Box>
          <Box width="100%">
            <FormSelectLayout
              title={t("indicatorsManagementTexts.step1.analysis")}
              required={true}
            >
              <Select
                reference={fileSelectRef}
                styles={selectStyles(fileSelectedOptions)}
                options={fileOptions(dataGetFileColumn)}
                className="cc_select"
                name="Archivo"
                onChange={fileOptionsChangeHandler}
                closeMenuOnSelect
                placeholder={t(
                  "indicatorsManagementTexts.step1.chooseAnalysis"
                )}
                defaultValue={fileSelectedOptions}
                isClearable
              />
            </FormSelectLayout>
          </Box>
        </Stack>
        <Stack
          sx={{
            pl: "10px",
            pr: "10px",
            gap: 1,
            width: "100%",
          }}
        >
          <Typography
            variant="subtitle1"
            color="error"
            sx={{ fontWeight: "bold", height: "25px", textAlign: "center" }}
          >
            {message?.text}
          </Typography>
          <Stack alignItems={"center"} width="100%">
            <Grid
              sx={{
                width: "100%",
              }}
              container
              spacing={2}
            >
              <WaterfallGridTitles />
              <WaterfallGridSelects
                columns={columnOptions(dataGetFileColumn, fileSelectedOptions)}
                columnBaseSelectRef={columnBaseSelectRef}
                columnSelectedOption={columnBaseSelectedOptions}
                columnOptionsChangeHandler={columnBaseOptionsChangeHandler}
                functionSelectRef={functionSelectRef}
                functionSelectedOption={functionSelectedOptions}
                functionOptionsChangeHandler={functionOptionsChangeHandler}
                handleAddGroupByRow={handleAddWaterfallRow}
                handleDeleteAllRows={handleDeleteAllRows}
                waterfallRows={waterfallRows}
                waterfallColumnNameRef={waterfallColumnNameRef}
                waterfallColumnName={waterfallColumnName}
                setWaterfallColumnName={setWaterfallColumnName}
                message={message}
              />
              {waterfallRows && waterfallRows.length ? (
                waterfallRows?.map((row: any, index: number) => (
                  <WaterfallRow
                    key={index}
                    index={index}
                    row={row}
                    waterfallRows={waterfallRows}
                    setWaterfallRows={setWaterfallRows}
                    handleToggleEditRow={handleToggleEditRow}
                    dragItem={dragItem}
                    dragOverItem={dragOverItem}
                    onDropActions={onDropActions}
                  />
                ))
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "10vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "700", color: "var(--text-info)" }}
                  >
                    {t("basicGrid.noLinesLabel")}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Stack>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} />

          <div className="cc_step_form_checkbox cc_checkbox_container">
            <input
              type="checkbox"
              className="cc_checkbox"
              name="chartActive"
              onChange={toggleActiveIndicator}
              defaultChecked={activeIndicator}
            />
            <label className="cc_step_form_label" htmlFor="chartActive">
              {t("indicatorsManagementTexts.step1Charts.activeGraphic")}
            </label>
          </div>

          <Stack
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              mt: "10px",
            }}
          >
            <Button
              title={t(
                "dataModelText.measureManagement.configMeasure.otherFontStep1.reset"
              )}
              color="grey"
              type="button"
              onClick={resetRows}
              disabled={waterfallRows.length === 0}
            />
          </Stack>
        </Stack>
      </ShadowBox>
      <EditRowModal open={openEditingRowModal} handleClose={toggleEditRow} rowToEdit={rowToEdit} waterfallRows={waterfallRows} setWaterfallRows={setWaterfallRows} />
    </Box>
  );
};

export default Step1Waterfall;
