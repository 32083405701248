import { useRef } from "react";
import ReactSelect from "react-select";
import { selectMultiStyles } from "../criterias/configCriteria/select.styles";

interface SelectWithAllOptionsProps {
  options: any;
  value: { value: string; label: string }[];
  onChange: any;
  labelSelectAll: string;
  placeholder: string;
  isDisabled: boolean;
  reference: any;
  closeMenuOnSelect?: boolean
}

export const SelectWithAllOptions = ({
  options,
  value,
  onChange,
  labelSelectAll,
  placeholder,
  isDisabled,
  reference,
  closeMenuOnSelect
}: SelectWithAllOptionsProps) => {
  const valueRef = useRef(value);
  valueRef.current = value;

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: labelSelectAll,
  };

  const isSelectAllSelected = () =>
    valueRef?.current?.length === options?.length;

  const isOptionSelected = (option) =>
    valueRef.current?.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => {
    if (options && options.length <= 1) {
      return [...options];
    }
    return options && [selectAllOption, ...options];
  };

  const getValue = () =>
    options && options.length === 0
      ? value
      : isSelectAllSelected()
      ? [selectAllOption]
      : value;

  const onChangeData = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;
    if (action === "select-option" && option.value === selectAllOption.value) {
      onChange(options, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      onChange([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      onChange(
        options?.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      onChange(newValue || [], actionMeta);
    }
  };

  return (
    <ReactSelect
      styles={selectMultiStyles(getOptions())}
      placeholder={placeholder}
      ref={reference}
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      onChange={onChangeData}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isMulti
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#c9c9c9",
          primary: "var(--text-success)",
        },
      })}
      isDisabled={isDisabled}
    />
  );
};
