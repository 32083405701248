import { FormHelperText } from "@mui/material";
import { getIdPeriodsByYear, filterDatesWithExpense } from "./utils";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListMonthsItems from "./ListMonthsItems";
import ListYearItem from "./ListYearItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useLanguage from "../../context/LanguageProvider";
import {
  filterPeriods,
  getTotalPeriods,
  removeDuplicates,
  isIdPeriodsByYearInArray,
  removeDuplicatesString,
  repeatArray,
} from "./utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 270,
    },
  },
};

type DatesType =
  | {
      id: number;
      fecha: string; //debe venir en formato "SUBITEM-ITEM" ej: "columna1-GASTOS", "02-2022"
      posee_gastos: boolean;
      exportable?: boolean;
      habilitado?: boolean;
      preprorrateado?: boolean;
    }[]
  | [];
interface IMultipleSelectCheckmarks {
  dates: DatesType;
  selectedPeriods: number[];
  setSelectedPeriods: (selectedPeriods: number[]) => void;
  width?: string;
  analysisHighCharts?: boolean;
  selectLabel?: string;
  height?: string;
}

export default function MultipleSelectCheckmarks({
  dates,
  selectedPeriods,
  setSelectedPeriods,
  width,
  analysisHighCharts,
  selectLabel,
  height,
}: IMultipleSelectCheckmarks) {
  const totalYears =
    (dates &&
      dates.length > 0 &&
      getTotalPeriods(
        analysisHighCharts ? dates : filterDatesWithExpense(dates)
      )) ||
    [];

  const [selectedYears, setSelectedYears] = useState<string[]>([]);
  const [collapsedYears, setCollapsedYears] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedPeriods>) => {
    const newValue = event.target.value as number[];
    setSelectedPeriods([...newValue]);
  };
  const { t } = useLanguage();

  useEffect(() => {
    let newYears: string[] = [...selectedYears];
    totalYears.forEach((year: string) => {
      if (
        isIdPeriodsByYearInArray(
          year,
          analysisHighCharts ? dates : filterDatesWithExpense(dates),
          selectedPeriods
        )
      ) {
        newYears.push(year);
      } else {
        const newSelectedYears = newYears.filter((item) => item !== year);
        newYears = newSelectedYears;
      }
      setSelectedYears(removeDuplicatesString(newYears));
    });
  }, [selectedPeriods]);

  useEffect(() => {
    if (analysisHighCharts) {
      setSelectedPeriods(
        selectedPeriods.sort((a, b) => {
          const indexA = dates.findIndex((item) => item.id === a);
          const indexB = dates.findIndex((item) => item.id === b);
          return indexA - indexB;
        })
      );
    }
  }, [selectedPeriods]);

  const yearHandleChange = (yearItem: string) => {
    setSelectedYears(
      selectedYears.includes(yearItem)
        ? selectedYears.filter((year: string) => year !== yearItem)
        : [...selectedYears, yearItem]
    );
    setSelectedPeriods(
      selectedYears.includes(yearItem)
        ? selectedPeriods.filter(
            (period: number) =>
              !getIdPeriodsByYear(
                yearItem,
                analysisHighCharts ? dates : filterDatesWithExpense(dates)
              ).includes(period)
          )
        : removeDuplicates([
            ...selectedPeriods,
            ...getIdPeriodsByYear(
              yearItem,
              analysisHighCharts ? dates : filterDatesWithExpense(dates)
            ),
          ])
    );
  };

  const handleToggleCollapseYear = (yearItem: string) => {
    setCollapsedYears(
      collapsedYears.includes(yearItem)
        ? collapsedYears.filter((year: string) => year !== yearItem)
        : [...collapsedYears, yearItem]
    );
  };

  return (
    <FormControl sx={{ m: 1, width: width ? width : 300, margin: 0 }}>
      {dates && dates.length > 0 && (
        <>
          {selectLabel !== "" && (
            <InputLabel sx={{ top: "-5px", zIndex: 0 }}>
              {selectLabel ? selectLabel : "Períodos"}
            </InputLabel>
          )}

          <Select
            labelId="demo-multiple-checkbox-label"
            multiple
            value={selectedPeriods}
            onChange={(e) => handleChange(e)}
            input={
              <OutlinedInput
                label={
                  selectLabel !== ""
                    ? selectLabel
                      ? selectLabel
                      : t("periods.multipleSelectCheckmarks.periods")
                    : undefined
                }
              />
            }
            renderValue={(selected) =>
              selected.map((id: number, index: number) =>
                filterPeriods(
                  analysisHighCharts ? dates : filterDatesWithExpense(dates),
                  "ID",
                  id
                )[0]
                  ? filterPeriods(
                      analysisHighCharts
                        ? dates
                        : filterDatesWithExpense(dates),
                      "ID",
                      id
                    )[0].fecha + (index !== selected.length - 1 ? ", " : "")
                  : ""
              )
            }
            MenuProps={MenuProps}
            sx={{
              height: height ? height : "45px",
              verticalAlign: "center",
            }}
          >
            {repeatArray(totalYears).map((yearItem: string, index: number) => {
              if (index % 2 === 0) {
                return (
                  <ListYearItem
                    key={yearItem + index}
                    yearItem={yearItem}
                    yearHandleChange={yearHandleChange}
                    selectedYears={selectedYears}
                    handleToggleCollapseYear={handleToggleCollapseYear}
                  />
                );
              } else {
                return collapsedYears.includes(yearItem)
                  ? ListMonthsItems(
                      analysisHighCharts
                        ? dates
                        : filterDatesWithExpense(dates),
                      selectedPeriods,
                      yearItem
                    )
                  : null;
              }
            })}
          </Select>
          {analysisHighCharts && selectedPeriods.length === 0 && (
            <FormHelperText error={true}>
              Los períodos no pueden estar vacíos
            </FormHelperText>
          )}
        </>
      )}
    </FormControl>
  );
}
