import { ActionMeta, SingleValue } from "react-select";
import { COMPANY_PARAMETERS_PNL_LEVEL_URL } from "../../../../api/axios";
import { ConfirmationModal } from "../../../dialog";
import { getValueLabelFromIdNombre } from "../../../companyParameterization/utils";
import { IdOption, OnChange } from "../../../criterias/configCriteria/types";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { Stack } from "@mui/material";
import { usePnLParamProvider } from "../../../../context/PnLParamProvider";
import { useState } from "react";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Select from "../../../forms/Select";
import useApi from "../../../../hooks/useApi";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";

const BucketLevelSelector = () => {
  const { t } = useLanguage();
  const { dataGetPnlLevels, getPnlLevels } = usePnLParamProvider();
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [newLevelValue, setNewLevelValue] = useState<
    SingleValue<IdOption> | undefined
  >(undefined);

  /************************************ Definiciones de niveles ***************************************/

  const relatedLevel = dataGetPnlLevels?.find(
    (item) => item.vinculo === "BUCKET"
  );
  const newLevelOrden =
    newLevelValue &&
    dataGetPnlLevels?.find((lev) => lev.id === newLevelValue.value)?.orden;
  const minLevel = newLevelValue
    ? relatedLevel?.orden - newLevelOrden < 0
      ? relatedLevel?.orden
      : newLevelOrden
    : 0;

  /**************************************** Gestión del select *************************************/

  const [bucketLevelOption, setBucketLevelOption] = useState<
    SingleValue<IdOption> | undefined
  >(
    dataGetPnlLevels && relatedLevel
      ? { value: relatedLevel?.id, label: relatedLevel?.nombre }
      : undefined
  );

  const bucketLevelChangeHandler: OnChange = (
    newValue: SingleValue<IdOption>,
    action: ActionMeta<IdOption>
  ) => {
    switch (action.action) {
      case "select-option":
        setNewLevelValue(newValue);
        toggleConfirmationModal();
        break;
      default:
        break;
    }
  };

  /******************************************** PUT del select ****************************************/

  const acceptChangeBucketLevel = () => {
    setBucketLevelOption(newLevelValue);
    putPnlLevels(
      `${COMPANY_PARAMETERS_PNL_LEVEL_URL}/${newLevelValue?.value}`,
      {
        vinculo: "BUCKET",
      }
    );
  };

  const onSuccessPutPnlLevels = () => {
    toggleConfirmationModal();
    getPnlLevels();
  };

  const { isLoading: isLoadingPnlLevels, callApi: putPnlLevels } = useApi(
    COMPANY_PARAMETERS_PNL_LEVEL_URL,
    "PUT",
    t("companyParam.getParams.codes"),
    undefined,
    onSuccessPutPnlLevels,
    undefined,
    false
  );

  return (
    <Stack sx={{ width: "400px" }}>
      <FormSelectLayout
        title={t("companyParam.bucketLevel")}
        required={true}
        margin="0"
      >
        {dataGetPnlLevels && <Select
          value={bucketLevelOption}
          styles={selectStyles(bucketLevelOption)}
          options={
            dataGetPnlLevels &&
            getValueLabelFromIdNombre(
              dataGetPnlLevels.filter((level) => level.vinculo !== "AGRUPADOR")
            )
          }
          className="pp_select_schema"
          onChange={bucketLevelChangeHandler}
          closeMenuOnSelect
          isSearchable
          placeholder={t("general.selectOption")}
          defaultValue={
            dataGetPnlLevels && relatedLevel
              ? { value: relatedLevel?.id, label: relatedLevel?.nombre }
              : undefined
          }
        />}
      </FormSelectLayout>
      <ConfirmationModal
        title={t("companyParam.deleteLevels")}
        message={
          t("companyParam.confirmDeleteLevels") +
          dataGetPnlLevels?.find((level) => level.orden === minLevel)?.nombre
        }
        customMessage={true}
        open={openConfirmationModal}
        handleClose={toggleConfirmationModal}
        handleAccept={() => acceptChangeBucketLevel()}
      />
    </Stack>
  );
};

export default BucketLevelSelector;
