import { Button as CustomButton } from "../../../buttons";
import { ConfirmationModal } from "../../../dialog";
import { DummySelectedValuesType } from "../../types";
import { Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useGetCriteriaFileColumn } from "../../../criterias/configCriteria/api";
import { useParameterization } from "../../../../hooks/useParameterization";
import ColorTabs from "../../../colorTabs/ColorTabs";
import GntCriteriaContent from "./GntCriteriaContent";
import GntDummyContent from "./GntDummyContent";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import useApi from "../../../../hooks/useApi";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import useSwitch from "../../../../hooks/useSwitch";
import useTabs from "../../../../hooks/useTabs";
import {
  DATA_FOR_DUMMY_URL,
  POST_CRITERIA_GNT_URL,
  POST_PROPAGATE_CRITERIA_URL,
} from "../../../../api/axios";
import {
  getPreviousValidationsFromSelectionModel,
  handleRefetchRowsDataNt,
  preparePropagateCriteriaData,
  preparePropagateGNTCriteriaData,
  prepareRowsForDummy,
  removeDuplicatedPreviousTargets,
  setGroupersForEachNonRepeatedValidation,
  tabsGNT,
  generateGroupersGroups,
} from "../../utils";
interface ICriteriaModelContent {
  rowsData: any;
  selectedGroupers: any;
  criteriaSelectedOption: any;
  expenseTargetSelectedOption: any;
  setCriteriaSelectedOption: any;
  dataGetCriteriaTarget: any;
  expenseTargetChangeHandler: any;
  criteriaSelectRef: any;
  criteriaSelectOptions: any;
  criteriaChangeHandler: any;
  getCriteria: any;
  handleClosePropagateModal: () => void;
}

const CriteriaModelContent = ({
  rowsData,
  selectedGroupers,
  criteriaSelectedOption,
  expenseTargetSelectedOption,
  setCriteriaSelectedOption,
  dataGetCriteriaTarget,
  expenseTargetChangeHandler,
  criteriaSelectRef,
  criteriaSelectOptions,
  criteriaChangeHandler,
  getCriteria,
  handleClosePropagateModal,
}: ICriteriaModelContent) => {
  const { t } = useLanguage();
  const {
    parameterizationState,
    parameterizationDispatch,
    getProrationResults,
    getProrationResultsNoTrasladados,
  } = useParameterization();
  const {
    selectedStage,
    tabValue,
    bucketSelectedOption,
    selectionModel,
    selectedIteration,
  } = parameterizationState;
  const columnSelectRef: any = useRef(null);
  const [previousValidations, setPreviousValidations] = useState<any>();
  const [validationsToSend, setValidationsToSend] = useState<any[]>([]);
  const [checked, handleChangeSwitch] = useSwitch(false); //TODO: modificar por valor del backend
  const { data: dataGetFileColumn } = useGetCriteriaFileColumn();
  const [tabValueGNT, handleChangeGNT] = useTabs("CRITERIO");
  const [dummySelectedValues, setDummySetectedValues] = useState<
    DummySelectedValuesType[] | any[]
  >([]);
  const [openConfirmationModal, handleToggleConfirmationModal] = useDialog();

  const TAB_GNT = t(
    "modelParameterizationTexts.step2.tabValueExpensesNotTransferred"
  );

  /* -------------------- Se envía info de las rows al backend para obtener datos de dummy ------------------- */

  const { data: dataForDummy, callApi: getDataForDummy } = useApi(
    DATA_FOR_DUMMY_URL,
    "POST",
    t("modelParameterization.dataForDummy.codes"),
    prepareRowsForDummy(
      selectionModel,
      rowsData,
      bucketSelectedOption,
      selectedStage,
      selectedIteration?.iteracion
    ),
    undefined,
    undefined,
    false
  );

  /****************************** Datos del modal según tabValue y parámetros **************************/

  const [
    columnFinalTargetSelectedOption,
    setColumnSelectedOptions,
    columnOptionsChangeHandler,
  ] = useSingleSelect();

  useEffect(() => {
    if (tabValueGNT === "CRITERIO") {
      setDummySetectedValues([]);
    } else {
      generateGroupersGroups(
        dummySelectedValues,
        dataForDummy,
        setDummySetectedValues
      );
    }
    tabValue === TAB_GNT && getDataForDummy();
    setCriteriaSelectedOption(undefined);
    setColumnSelectedOptions(undefined);
    columnSelectRef.current = undefined;
  }, [tabValueGNT]);

  useEffect(() => {
    const selectedPreviousValidations =
      getPreviousValidationsFromSelectionModel(selectionModel, rowsData);
    const nonRepeatedPreviuosTargets = removeDuplicatedPreviousTargets(
      selectedPreviousValidations
    );
    setPreviousValidations(
      setGroupersForEachNonRepeatedValidation(
        selectedPreviousValidations,
        nonRepeatedPreviuosTargets
      )
    );
  }, [bucketSelectedOption, selectedStage, selectionModel]);

  /******************************** Propagar criterios *********************************/

  const onSuccessPropagateCriteria = () => {
    handleClosePropagateModal();
    getProrationResults();
    handleRefetchRowsDataNt(
      parameterizationState,
      parameterizationDispatch,
      getProrationResultsNoTrasladados
    );
  };

  const {
    isLoading: propagateCriteriaLoading,
    callApi: postPropagateCriteria,
  } = useApi(
    undefined,
    "POST",
    t("modelParameterization.propagateCriteria.codes"),
    undefined,
    onSuccessPropagateCriteria,
    handleClosePropagateModal
  );

  const handlePropagateCriteria = () => {
    handleToggleConfirmationModal();    
    tabValue === "GASTOS" || tabValueGNT === "CRITERIO"
      ? postPropagateCriteria(
          POST_PROPAGATE_CRITERIA_URL,
          preparePropagateCriteriaData(
            bucketSelectedOption,
            selectedStage,
            selectedGroupers,
            criteriaSelectedOption,
            tabValue === "GASTOS" ? expenseTargetSelectedOption : expenseTargetSelectedOption?.receptor_criterio,
            validationsToSend,
            checked,
            tabValue === "GASTOS" ? undefined : selectedIteration.iteracion
          )
        )
      : postPropagateCriteria(
          POST_CRITERIA_GNT_URL,
          preparePropagateGNTCriteriaData(
            bucketSelectedOption,
            selectedStage,
            validationsToSend,
            dummySelectedValues,
            selectedIteration,
            columnFinalTargetSelectedOption
          )
        );
    parameterizationDispatch({
      type: "SET_SELECTION_MODEL",
      payload: [],
    });
  };

  const isCriteriaModalButtonDisabled =
    (tabValueGNT === "CRITERIO" &&
      (!expenseTargetSelectedOption ||
        !criteriaSelectedOption ||
        (previousValidations &&
          previousValidations?.length > 0 &&
          validationsToSend?.filter((val: any) => val.columna === undefined)
            .length !== 0))) ||
    (tabValueGNT === "TRASLADO DIRECTO DUMMY" &&
      !columnFinalTargetSelectedOption);

  return (
    <Stack
      padding="0px 10px"
      minHeight={tabValue === TAB_GNT ? "300px" : "230px"}
    >
      <SimpleBackdrop
        open={propagateCriteriaLoading}
        message={t(
          "modelParameterizationTexts.criteriaModelContent.propagatingCriteria"
        )}
      />
      {tabValue === TAB_GNT && (
        <ColorTabs
          value={tabValueGNT}
          handleChange={handleChangeGNT}
          tabs={tabsGNT}
          marginBottom="15px"
          fontSize="15px"
        />
      )}
      {tabValueGNT === "CRITERIO" && (
        <GntCriteriaContent
          expenseTargetSelectedOption={expenseTargetSelectedOption}
          dataGetCriteriaTarget={dataGetCriteriaTarget}
          expenseTargetChangeHandler={expenseTargetChangeHandler}
          rowsData={rowsData}
          criteriaSelectRef={criteriaSelectRef}
          criteriaSelectOptions={criteriaSelectOptions}
          criteriaChangeHandler={criteriaChangeHandler}
          criteriaSelectedOption={criteriaSelectedOption}
          previousValidations={previousValidations}
          dataGetFileColumn={dataGetFileColumn}
          getCriteria={getCriteria}
          validationsToSend={validationsToSend}
          setValidationsToSend={setValidationsToSend}
          checked={checked}
          handleChangeSwitch={handleChangeSwitch}
        />
      )}
      {tabValueGNT === "TRASLADO DIRECTO DUMMY" && (
        <GntDummyContent
          columnFinalTargetSelectedOption={columnFinalTargetSelectedOption}
          columnOptionsChangeHandler={columnOptionsChangeHandler}
          columnSelectRef={columnSelectRef}
          dataGetCriteriaTarget={dataGetCriteriaTarget}
          dummySelectedValues={dummySelectedValues}
          dataGetFileColumn={dataGetFileColumn}
          setDummySetectedValues={setDummySetectedValues}
        />
      )}
      <Stack
        direction="row"
        width="100%"
        justifyContent="center"
        marginTop="auto"
      >
        <CustomButton
          title={t("modelParameterizationTexts.criteriaModelContent.cancel")}
          color="grey"
          type="button"
          onClick={() => handleClosePropagateModal()}
        />
        <CustomButton
          title={t("modelParameterizationTexts.criteriaModelContent.confirm")}
          color="blue-greeny"
          type="button"
          onClick={handleToggleConfirmationModal}
          disabled={isCriteriaModalButtonDisabled}
        />
        <ConfirmationModal
          open={openConfirmationModal}
          handleClose={handleToggleConfirmationModal}
          handleAccept={handlePropagateCriteria}
          message={t(
            "modelParameterizationTexts.criteriaModelContent.confirmationMessage"
          )}
          customMessage
          title={t("modelParameterizationTexts.criteriaModelContent.confirm")}
        />
      </Stack>
    </Stack>
  );
};

export default CriteriaModelContent;
