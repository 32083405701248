import { ActionMeta, SingleValue } from "react-select";
import { Button } from "../../../buttons";
import { columnOptions } from "../../../criterias/configCriteria/selectOptions";
import { ConfirmationModal } from "../../../dialog";
import { CustomSelect as Select } from "../../../forms";
import { GET_CRITERIA_FILE_COLUMN_URL } from "../../../../api/axios";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { Stack } from "@mui/material";
import { useApiQuery } from "../../../../hooks/useApiQuery";
import { useState, useEffect, useRef, useContext } from "react";
import CustomSkeleton from "../../../skeleton/CustomSkeleton";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import GroupersProvider from "../../../../context/GroupersProvider";
import ModifyColumnsModal from "./ModifyColumnsModal";
import TableDataManagement from "../../../dataManagement/table";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import {
  IdOption,
  IsMulti,
  OnChangeValue,
} from "../../../criterias/configCriteria/types";

export const transformDataPreview = (data: any) => {
  const arrayColumns = data?.map((columna: any) => ({
    field:
      data?.filter((column: any) => column.label === columna.label).length > 1
        ? `${columna.label} (${columna.file_label})`
        : columna.label,
    headerName: columna.label,
    headerClassName: "dm_header_column",
    description: columna.label,
    minWidth: 120,
    flex: 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong className="dm_column_title">{params.field}</strong>
    ),
  }));
  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));
  return { columns };
};

const Step2 = ({
  columnSelectedOptions,
  setColumnSelectedOptions,
  columnOptionsChangeHandler,
}) => {
  const { t } = useLanguage();
  const { groupersState, groupersDispatch } =
    useContext<React.ContextType<typeof GroupersProvider>>(GroupersProvider);
  const { bases, step2 } = groupersState;
  const fileSelectRef: any = useRef(null);
  const [openRenameColumnsModal, setOpenRenameColumnsModal] = useState(false);
  const [columnsPreview, setColumnsPreview] = useState<
    GridColDef<any, any, any>[]
  >([
    {
      field: "",
      headerName: "",
      width: 0,
    },
  ]);
  const rows = [
    {
      id: 1,
    },
  ];
  const [openResetColumns, handleToggleModalResetColumns] = useDialog();
  const { data: dataGetFileColumn } = useApiQuery(
    GET_CRITERIA_FILE_COLUMN_URL,
    false,
    t("configCriteria.getCriteriaFileColumn.error")
  );

  /*************************** SELECTS COLUMNS ***********************************/

  const columnFileSelectRef: any = useRef(null);
  const columnSelectRef: any = useRef(null);

  const [
    columnSelectedFileOptions,
    setColumnSelectedFileOptions,
    columnFileOptionsChangeHandler,
  ] = useSingleSelect(undefined, step2.columna);

  const [
    fileSelectedOptions,
    setFileSelectedOptions,
    fileOptionsChangeHandler,
  ] = useSingleSelect(() => onFileColumn(), step2.archivo);

  const onFileColumn = () => {
    if (
      columnSelectedFileOptions !== undefined &&
      columnSelectedFileOptions !== null
    ) {
      setColumnSelectedFileOptions(undefined);
      columnFileSelectRef.current.clearValue();
      setColumnSelectedOptions(undefined);
      columnSelectRef.current.clearValue();
    }
  };

  const prepareColumnsToUseOptions = () => {
    let totalOptions: any[] = [];
    totalOptions = bases?.map((base: any) => {
      return {
        label: base.archivo.label,
        options: base.columnas?.map((column: any) => {
          return {
            value: column.value,
            label: column.label,
            file_id: base.archivo.value,
            file_label: base.archivo.label,
          };
        }),
      };
    });
    return totalOptions;
  };

  /*************************** TABLE DATA VIEW ***********************************/

  useEffect(() => {
    columnSelectedOptions &&
      setColumnsPreview(transformDataPreview(columnSelectedOptions)?.columns);
  }, [columnSelectedOptions]);

  const closeAndClean = () => {
    setOpenRenameColumnsModal(false);
  };

  const handleEditFile = (
    newValue: SingleValue<IdOption>,
    action: ActionMeta<IdOption>
  ) => {
    fileOptionsChangeHandler(newValue, action);
    if (newValue === null) {
      groupersDispatch({
        type: "SET_STEP_2",
        payload: { archivo: newValue, columna: null, columnasAUsar: [] },
      });
    } else {
      groupersDispatch({
        type: "SET_STEP_2",
        payload: { ...step2, archivo: newValue },
      });
    }
  };

  const handleEditColumn = (
    newValue: SingleValue<IdOption>,
    action: ActionMeta<IdOption>
  ) => {
    columnFileOptionsChangeHandler(newValue, action);
    groupersDispatch({
      type: "SET_STEP_2",
      payload: { ...step2, columna: newValue },
    });
  };
  0;
  const handleEditColumns = (
    newValue: OnChangeValue<IdOption, IsMulti>,
    action: ActionMeta<IdOption>
  ) => {
    columnOptionsChangeHandler(newValue, action);
    groupersDispatch({
      type: "SET_STEP_2",
      payload: { ...step2, columnasAUsar: newValue },
    });
  };

  /*************************** RENAME MODAL ***********************************/

  const handleToggleRenameColumnsModal = () => {
    setOpenRenameColumnsModal(!openRenameColumnsModal);
  };

  const handleAcceptRenameColumns = (data: any) => {
    groupersDispatch({
      type: "SET_STEP_2",
      payload: { ...step2, columnasAUsar: data },
    });
    handleToggleRenameColumnsModal();
    setColumnSelectedOptions(data);
  };

  const handleResetColumns = () => {
    const originalColumns: any = [];
    bases.map((base: any) => {
      base.columnas.map((columna: any) => {
        columnSelectedOptions?.map((optionSelected: any) => {
          if (columna.value === optionSelected.value) {
            originalColumns.push({
              ...columna,
              file_label: base.archivo.label,
              file_id: base.archivo.value,
            });
          }
        });
      });
    });
    groupersDispatch({
      type: "SET_STEP_2",
      payload: { ...step2, columnasAUsar: originalColumns },
    });
    setColumnSelectedOptions(originalColumns);
    handleToggleModalResetColumns();
    handleToggleRenameColumnsModal();
  };

  return (
    <Stack
      sx={{
        width: "90%",
      }}
    >
      <FormSelectLayout
        title={t(
          "dataModelText.groupManagement.step2.step2.columnToJoinResults"
        )}
        required={true}
      >
        <Stack
          sx={{
            margin: "10px 0",
            width: "100%",
            display: "flex",
            borderRadius: "20px",
            boxShadow: "3px 3px 3px 3px rgba(0, 0, 0, 0.2)",
            padding: "30px",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <Stack sx={{ width: "100%" }}>
            <FormSelectLayout
              title={t("dataModelText.groupManagement.step2.step2.file")}
              required={true}
            >
              <Select
                reference={fileSelectRef}
                styles={selectStyles(fileSelectedOptions)}
                options={bases.map((base: any) => {
                  return {
                    label: base.archivo.label,
                    value: base.archivo.value,
                  };
                })}
                className="cc_select"
                onChange={handleEditFile}
                closeMenuOnSelect
                placeholder={t(
                  "dataModelText.groupManagement.step2.step2.chooseFile"
                )}
                defaultValue={fileSelectedOptions}
                isClearable
              />
            </FormSelectLayout>
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <FormSelectLayout
              title={t("dataModelText.groupManagement.step2.step2.column")}
              required={true}
            >
              <Select
                reference={columnFileSelectRef}
                styles={selectStyles(columnSelectedFileOptions)}
                options={
                  columnOptions(dataGetFileColumn, fileSelectedOptions)
                  /* bases
                  .find((base: any) => {
                    return base.archivo.value === fileSelectedOptions?.value;
                  })
                  ?.columnas?.map((column: any) => {
                    return {
                      value: column.value,
                      label: column.label,
                    };
                  }) */
                }
                closeMenuOnSelect
                className="cc_select"
                name="ColumnaBase"
                onChange={handleEditColumn}
                placeholder={t(
                  "dataModelText.groupManagement.step2.step2.chooseColumn"
                )}
                defaultValue={columnSelectedFileOptions}
                isClearable
              />
            </FormSelectLayout>
          </Stack>
        </Stack>
      </FormSelectLayout>
      <FormSelectLayout
        title={t("dataModelText.groupManagement.step2.step2.columnsToUse")}
        required={true}
      >
        <Stack
          sx={{
            marginTop: "10px",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: "20px",
            boxShadow: "3px 3px 3px 3px rgba(0, 0, 0, 0.2)",
            padding: "30px",
            gap: "40px",
            justifyContent: "flex-end",
          }}
        >
          {!openRenameColumnsModal && (
            <Select
              reference={columnSelectRef}
              styles={selectStyles(columnSelectedOptions)}
              options={prepareColumnsToUseOptions()}
              className="cc_select"
              onChange={handleEditColumns}
              placeholder={t(
                "dataModelText.groupManagement.step2.step2.chooseColumns"
              )}
              closeMenuOnSelect={false}
              defaultValue={
                step2.columnasAUsar
                  ? step2.columnasAUsar
                  : columnSelectedOptions
              }
              isClearable
              isMulti
            />
          )}
          <Button
            title={t("dataModelText.groupManagement.step2.step2.renameColumns")}
            color="blue"
            type="button"
            onClick={handleToggleRenameColumnsModal}
            disabled={
              !columnSelectedOptions || columnSelectedOptions.length < 1
            }
          />
        </Stack>
      </FormSelectLayout>
      <Stack sx={{ width: "100%" }}>
        <h2 className="dm_title_preview">
          {t("dataModelText.groupManagement.step2.step2.preview")}
        </h2>
        {!columnSelectedOptions ||
        columnSelectedOptions?.length < 1 ||
        !columnsPreview ||
        columnsPreview?.length < 1 ? (
          <CustomSkeleton height={1} />
        ) : (
          <>
            <TableDataManagement
              columns={columnsPreview}
              rows={rows}
              rowHeight={0}
              tableHeight={100}
              hideFooter={true}
              columnsButton={false}
              filterButton={false}
              densitySelector={false}
              exportButton={false}
            />
          </>
        )}
      </Stack>
      <ModifyColumnsModal
        open={openRenameColumnsModal}
        handleClose={closeAndClean}
        handleAccept={handleAcceptRenameColumns}
        handleResetColumns={handleToggleModalResetColumns}
        columns={columnSelectedOptions}
        disabled={true}
      />
      <ConfirmationModal
        open={openResetColumns}
        handleClose={handleToggleModalResetColumns}
        handleAccept={handleResetColumns}
        message={t(
          "dataModelText.groupManagement.step2.step2.resetColumnMessage"
        )}
        title={t("dataModelText.groupManagement.step2.step2.resetColumnTitle")}
      />
    </Stack>
  );
};

export default Step2;
