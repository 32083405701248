import { GET_PRORRATEO_RESULTADOS } from "../../api/axios";
import { GridRowsProp, GridSelectionModel } from "@mui/x-data-grid-pro";
import { ProrationRowType } from "./stagesSettings/TreedataTable";
import { removeDuplicates } from "../periods/utils";
import { Tab } from "../colorTabs/types";
import { VariantType } from "notistack";
import { zoomin_icon_styles } from "../../styles/app-styles";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import CustomTypographyTableText from "./Calculation/table/CustomTypographyTableText";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import {
  Buckets,
  Criteria,
  Etapa,
  IncomingBucketStageOption,
  ParameterizationDispatch,
  PreviousTarget,
  PreviousTargetWithGroupers,
  PreviousTargetWithIdGrouper,
  Row,
} from "./types";
import {
  IncomingFileOption,
  IncomingFile_Column,
} from "../criterias/configCriteria/types";

export const getBucketIdByRowId = (
  rowId: number | undefined,
  rows: GridRowsProp
) => {
  const row = rows.find((r) => r.id === rowId);
  return row?.bucket_gasto_id;
};

export const getGrouperIdByRowId = (
  rowId: number | undefined,
  rows: GridRowsProp
) => {
  const row = rows.find((r) => r.id === rowId);
  return row?.agrupador_id;
};

export const getRowByRowId = (
  rowId: number | undefined,
  rows: GridRowsProp
) => {
  const row = rows.find((r) => r.id === rowId);
  return row;
};

export const prepareEditData = (
  destiny_type: "agrupador" | "bucket_gasto" | string,
  bucketId: number | string,
  grouperId: number | string,
  name: string | number
) => {
  return {
    tipo_destino: destiny_type,
    bucket_gasto_id: bucketId,
    agrupador_id: grouperId,
    nuevo_nombre: name,
  };
};

export const getDestinyTypeByRowId = (
  rowId: number | undefined,
  rows: GridRowsProp
) => {
  const row = rows.find((r) => r.id === rowId);
  return row?.agrupador_id ? "agrupador" : "bucket_gasto";
};

export const removeDuplicatedBuckets = (groupers: any[]) => {
  const bucketsIds = groupers.map((b) => b.bucket_gasto_id);
  return bucketsIds.filter((a, b) => bucketsIds.indexOf(a) === b);
};

type stageType = {
  bucket: number;
  agrupador: number[];
  etapas: string | number;
};

export const isRowIdABucket = (
  rowId: number | undefined,
  rows: GridRowsProp
) => {
  const row = rows.find((r) => r.id === rowId);
  return !row?.agrupador_id;
};

export const preparePostStagesData = (
  stagesValue: string | number,
  selectedIdsArray: any,
  rows: []
) => {
  if (!stagesValue || (stagesValue as number) < 1) return;
  const selectedRows = selectedIdsArray.map((id: number) => {
    return getRowByRowId(id, rows);
  });
  const grouperRows = selectedRows.filter(
    (row: ProrationRowType) => row?.agrupador_id
  );
  const bucketsIds = removeDuplicatedBuckets(grouperRows);
  const stagesData: stageType[] = [];
  bucketsIds.map((bucketId: number) => {
    stagesData.push({
      bucket: bucketId,
      agrupador: grouperRows
        .filter((g: ProrationRowType) => g.bucket_gasto_id === bucketId)
        .map((g: ProrationRowType) => g.agrupador_id),
      etapas: stagesValue,
    });
  });
  return stagesData;
};

export const convertirToThousands = (numero: any) => {
  return numero.toLocaleString("es-AR");
};

export const checkIfAllRowsHaveStages = (rows: any) => {
  if (rows) {
    const grouperRows = rows?.filter(
      (row: ProrationRowType) => row?.agrupador_id
    );
    const rowsWithStages = grouperRows.filter(
      (row: ProrationRowType) => row.etapas
    );
    return rowsWithStages.length === grouperRows.length;
  }
};

export const convertNumberToMoney = (number: number | string) => {
  if (number) {
    const num = typeof number === "string" ? parseFloat(number) : number;
    const digits = num / 10000 < 10 ? 2 : 0;
    return number.toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS",
      maximumFractionDigits: digits,
      minimumFractionDigits: digits,
    });
  } else {
    return "$0,00";
  }
};

export const truncateString = (str: any, length: number) => {
  return str.length > length ? str.slice(0, length - 1) + "..." : str;
};

export const isRowIdAGrouper = (
  rowId: number | undefined,
  rows: GridRowsProp
) => {
  const row = rows.find((r) => r.id === rowId)
    ? rows.find((r) => r.id === rowId)
    : {};
  const hasKeyDesglosados =
    row && Object.keys(row).some((key) => key == "desglosados_id");
  return hasKeyDesglosados && !row?.desglosados_id;
};

export const getStagesByBucketId = (
  bucketId: string | number,
  buckets: IncomingBucketStageOption[]
) => {
  const bucket = buckets.find((b) => b.id === bucketId);
  return (
    bucket?.etapas || [
      { etapa: 0, habilitado: false, calculado: false, iteraciones: [] },
    ]
  );
};

export const checkFullStageFields = (rows: any) => {
  const grouperRows = rows?.filter(
    (row: ProrationRowType) => row?.agrupador_id
  );
  const rowsWithStages = grouperRows.filter(
    (row: ProrationRowType) => row.etapas
  );
  return rowsWithStages.length === grouperRows.length;
};

export const hasKeyBucketId = (rows: any, rowId: number) => {
  const row = rows.find((r: any) => r.id === rowId);
  return row && Object.keys(row).some((key) => key == "bucket_gasto_id");
};

export const filterCriteriasByBucketAndExpenseTarget = (
  criterias: Criteria[],
  bucketId: string | number | null | undefined,
  expenseTargetId: string | number | null | undefined
) => {
  return criterias?.filter(
    (c: Criteria) =>
      (c.criterio_bucket.some(
        (b: { value: number; label: string }) => b.value === bucketId
      ) ||
        c.criterio_bucket.length === 0) &&
      (c.criterio_receptor_gasto.some(
        (t: { value: number; label: string }) => t.value === expenseTargetId
      ) ||
        c.criterio_receptor_gasto.length === 0)
  );
};

export const getFileFromCriteriaId = (
  criteriaId: number | string,
  criterias: any
) => {
  const criteria = criterias.find((c: any) => c.id === criteriaId);
  return criteria?.archivo;
};

const filterByAbilityToReceiveCriteria = (selectionModel: any, rows: any) => {
  const ableRows = selectionModel.filter((id: number) => {
    const row = getRowByRowId(id, rows);
    const checkIfHasUnbundledGroupers = () => {
      let otherRowsWithSameGrouperId = [];
      if (row?.agrupador_id) {
        otherRowsWithSameGrouperId = rows.filter((r: any) => {
          return r.agrupador_id === row.agrupador_id && row.causa_error === "";
        });
      }

      return otherRowsWithSameGrouperId.length > 1;
    };

    return (
      !isRowIdAGrouper(id, rows) ||
      (isRowIdAGrouper(id, rows) && checkIfHasUnbundledGroupers() === false)
    );
  });
  return ableRows;
};

export const checkRowsWithoutAssignedCriteria = (rows: any) => {
  const ableRows = rows.filter((row: any) => {
    const checkIfHasUnbundledGroupers = () => {
      let otherRowsWithSameGrouperId = [];
      if (row?.agrupador_id) {
        otherRowsWithSameGrouperId = rows.filter(
          (r: any) => r.agrupador_id === row.agrupador_id
        );
      }
      return otherRowsWithSameGrouperId.length !== 1;
    };
    return (
      !isRowIdAGrouper(row.id, rows) ||
      (isRowIdAGrouper(row.id, rows) && checkIfHasUnbundledGroupers() === false)
    );
  });
  const rowsWithoutAssignedCriteria = ableRows.filter((row: any) => {
    return row.criterio_prorrateo === "";
  });
  return rowsWithoutAssignedCriteria.length > 0;
};

//Con los ids de la selección, filtro y obtengo los agrupadores desglosados, o bien aquellos agrupadores globales que no tienen desglose
export const getDataFromSelectionModel = (selectionModel: any, rows: any) => {
  const selectedRows = filterByAbilityToReceiveCriteria(
    selectionModel,
    rows
  ).map((id: number) => {
    const row = getRowByRowId(id, rows);
    return {
      agrupador: row?.agrupador_id,
      destino: row?.destino || "",
      destino_anterior: row?.destino_anterior || "",
      aplicacionprorrateo_id: row?.aplicacionprorrateo_id || "",
      causa_error: row?.causa_error && row?.causa_error,
    };
  });
  return selectedRows;
};

//Función que obtiene todas las validaciones anteriores de la tabla (para un bucket y etapa).
export const getPreviousValidationsFromSelectionModel = (
  selectionModel: any,
  rows: any
) => {
  const previousTargets: PreviousTargetWithIdGrouper[] = [];
  filterByAbilityToReceiveCriteria(selectionModel, rows).map((id: number) => {
    const row = getRowByRowId(id, rows);
    row &&
      row.validaciones_anteriores &&
      row.validaciones_anteriores.length &&
      row.validaciones_anteriores.map((target: PreviousTarget) => {
        previousTargets.push({ ...target, agrupador_id: row.agrupador_id });
      });
  });

  return previousTargets;
};

//Función que al pasarle un array de objetos, elimina los duplicados de los objetos cuyos valores de receptor.id coincidan, etapa_previa coincidan y agrupador_id coincidan
export const removeDuplicatedPreviousTargets: (
  previousTargets: PreviousTargetWithIdGrouper[]
) => PreviousTarget[] = (previousTargets: PreviousTargetWithIdGrouper[]) => {
  const uniquePreviousTargets =
    previousTargets &&
    previousTargets
      .filter(
        (target, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.receptor.id === target.receptor.id &&
              t.etapa_previa === target.etapa_previa
          )
      )
      .map((target) => {
        return {
          receptor: target.receptor,
          etapa_previa: target.etapa_previa,
          columna_defecto: target.columna_defecto,
          validar_asignacion_receptor: target.validar_asignacion_receptor,
        };
      });
  return uniquePreviousTargets;
};

//Función que agrega el array de agrupadores relacionados con cada validación anterior.
export const setGroupersForEachNonRepeatedValidation = (
  previousTotalTargets: PreviousTargetWithIdGrouper[],
  previousNonRepeatedTargets: PreviousTarget[]
) => {
  const previousNonRepeatedTargetsWithGroupers: PreviousTargetWithGroupers[] =
    [];
  previousNonRepeatedTargets.map((targetToAddGrouper: PreviousTarget) => {
    const groupers: number[] = [];
    previousTotalTargets.map(
      (targetWithIdGrouper: PreviousTargetWithIdGrouper) => {
        if (
          targetToAddGrouper.receptor.id === targetWithIdGrouper.receptor.id &&
          targetToAddGrouper.etapa_previa === targetWithIdGrouper.etapa_previa
        ) {
          groupers.push(targetWithIdGrouper.agrupador_id);
        }
      }
    );
    previousNonRepeatedTargetsWithGroupers.push({
      ...targetToAddGrouper,
      agrupadores: removeDuplicates(groupers),
    });
  });
  return previousNonRepeatedTargetsWithGroupers;
};

export const getGrouperNameFromId = (id: number, rows: any) => {
  const row = rows.find((r: any) => r.agrupador_id === id);
  return row?.hierarchy[0];
};

export const checkIfHaveCriteria = (selectionModel: any, rows: any) => {
  const rowsWithCriteria = selectionModel.filter((id: number) => {
    const row = getRowByRowId(id, rows);
    return row && row.criterio_prorrateo !== "";
  });
  return getDataFromSelectionModel(rowsWithCriteria, rows);
};

export const getTotalAmountByBucketIdAndStage = (
  bucketId: number | string | undefined,
  stage: number,
  rows: any
) => {
  const bucket =
    rows &&
    rows.length &&
    rows?.find((row: any) => {
      return row.id === bucketId;
    });
  const bucketStage =
    bucket &&
    bucket?.etapas.find((stageRow: any) => {
      return stageRow.etapa === stage;
    });
  return bucketStage?.monto_total ? bucketStage?.monto_total : 0;
};

//Formatea las rows para el endpoint de eliminar criterios
export const transformRowsToDeleteCriteria = (rows: any) => {
  return {
    aplicacionprorrateo_ids: rows.map((row: any) => {
      return row.aplicacionprorrateo_id;
    }),
  };
};

export const getBucketById = (id: number | null, buckets: any) => {
  return buckets.find((bucket: any) => bucket.id === id);
};

export const checkIfBucketHasStage = (
  id: number,
  stage: Etapa,
  buckets: any
) => {
  const stages = getStagesByBucketId(id, buckets);
  return stages.find(
    (s: any) => s.etapa === stage.etapa && s.habilitado === stage.habilitado
  );
};

export const handleClickVariant = (
  message: string,
  variant: VariantType,
  enqueueSnackbar: any
) => {
  // variant could be success, error, warning, info, or default
  enqueueSnackbar(message, { variant });
};

function addPendingTasks(pendingTasks: any[]) {
  const temporaryPendingTasks =
    localStorage.getItem("pendingTasks")?.split(",") || [];

  pendingTasks.forEach((item: any) => {
    if (!temporaryPendingTasks?.includes(item.id.toString())) {
      temporaryPendingTasks?.push(item.id.toString());
    }
  });

  localStorage.setItem("pendingTasks", temporaryPendingTasks?.toString());
}
interface Task {
  id: number;
  periodo: number[];
  etapa: number;
  bucket: number;
  criterio: string;
  posee_errores: boolean;
}

function generateMessage(task: Task): string {
  const message = task.posee_errores
    ? `Ha finalizado con error la tarea del bucket ${task.bucket}, etapa ${task.etapa}`
    : `Ha finalizado exitosamente la tarea del bucket ${task.bucket}, etapa ${task.etapa}`;
  const periodo = task.periodo.length > 1 ? "de los periodos" : "del periodo";
  const periodoStr = `${periodo} ${task.periodo.toString().slice(0, 40)}${
    task.periodo.length > 1 ? "..." : ""
  }`;
  return `${message} ${periodoStr}`;
}

export const handlePendingTasks = (
  dataProrrateo: any,
  parameterizationDispatch: any,
  enqueueSnackbar: any,
  refetchProrrateo: any,
  refetchBuckets: any
) => {
  const pendingTasks = dataProrrateo.filter(
    (item: any) => item.estado === "Pendiente" || item.estado === "Corriendo"
  );
  if (pendingTasks.length > 0) {
    addPendingTasks(pendingTasks);
  } else {
    parameterizationDispatch({
      type: "SET_PENDING_TASKS",
      payload: false,
    });
  }

  const storedTasksIds: string[] =
    localStorage.getItem("pendingTasks")?.split(",") ?? [];

  storedTasksIds.forEach((id: string) => {
    const matchingTasks = dataProrrateo.filter(
      (task) =>
        task.id === parseInt(id) &&
        (task.estado === "Finalizada" || task.estado === "Error")
    );

    if (matchingTasks.length > 0) {
      const task = dataProrrateo.find((item) => item.id === parseInt(id));
      if (task) {
        const message = generateMessage(task);
        handleClickVariant(
          message,
          task.posee_errores ? "warning" : "success",
          enqueueSnackbar
        );
      }
    }
  });

  const pendingTasksIds = dataProrrateo
    .filter(
      (task) => task.estado === "Pendiente" || task.estado === "Corriendo"
    )
    .map((task) => task.id)
    .toString();

  localStorage.setItem("pendingTasks", pendingTasksIds);

  refetchProrrateo();
  refetchBuckets();
};

//Esta función analiza si hay un bucket y una etapa guardados en localStorage, en caso de que no, los setea con el primer bucket de la lista, en caso de que si, analiza si el bucket sigue existiendo en la lista de buckets, si es asi lo setea como bucket seleccionado, si no, setea el primer de la lista
export const checkAndSetStoragedBucket = (
  buckets: any,
  parameterizationDispatch: any
) => {
  if (buckets && buckets.length) {
    if (
      localStorage.getItem("selectedBucket") &&
      localStorage.getItem("selectedStage") &&
      localStorage.getItem("selectedBucket") !== "undefined" &&
      localStorage.getItem("selectedStage") !== "undefined"
    ) {
      const storagedBucket = JSON.parse(
        localStorage.getItem("selectedBucket") || ""
      );
      if (getBucketById(storagedBucket.value, buckets)) {
        parameterizationDispatch({
          type: "SET_BUCKET_SELECTED_OPTION",
          payload: storagedBucket,
        });
        const storagedStage = JSON.parse(
          localStorage.getItem("selectedStage") || ""
        );
        if (
          checkIfBucketHasStage(storagedBucket.value, storagedStage, buckets)
        ) {
          const updatedStage = getStagesByBucketId(
            storagedBucket.value,
            buckets
          ).find((s: any) => s.etapa === storagedStage.etapa);
          parameterizationDispatch({
            type: "SET_SELECTED_STAGE",
            payload: updatedStage,
          });

          const maxIteration = updatedStage?.iteraciones
            ? updatedStage?.iteraciones.length - 1
            : null;
          if (maxIteration != null) {
            parameterizationDispatch({
              type: "SET_SELECTED_ITERATION",
              payload: updatedStage?.iteraciones[maxIteration],
            });
          }
        } else {
          parameterizationDispatch({
            type: "HANDLE_SET_SELECTED_STAGE",
            payload: getBucketById(storagedBucket.value, buckets).etapas[0],
          });
          const maxIteration = getBucketById(storagedBucket.value, buckets)
            .etapas[0]?.iteraciones
            ? getBucketById(storagedBucket.value, buckets).etapas[0].iteraciones
                .length - 1
            : null;
          if (maxIteration != null) {
            parameterizationDispatch({
              type: "SET_SELECTED_ITERATION",
              payload: getBucketById(storagedBucket.value, buckets).etapas[0]
                .iteraciones[maxIteration],
            });
          }
        }
      } else {
        parameterizationDispatch({
          type: "SET_BUCKET_SELECTED_OPTION",
          payload: {
            value: buckets[0].id,
            label: buckets[0].nombre,
          },
        });
        localStorage.setItem(
          "selectedBucket",
          JSON.stringify({
            value: buckets[0].id,
            label: buckets[0].nombre,
          })
        );
        parameterizationDispatch({
          type: "HANDLE_SET_SELECTED_STAGE",
          payload: buckets[0].etapas[0],
        });
        const maxIteration = buckets[0].etapas[0].iteraciones
          ? buckets[0].etapas[0].iteraciones.length - 1
          : null;
        if (maxIteration != null) {
          parameterizationDispatch({
            type: "SET_SELECTED_ITERATION",
            payload: buckets[0].etapas[0].iteraciones[maxIteration],
          });
        }
      }
    } else {
      parameterizationDispatch({
        type: "SET_BUCKET_SELECTED_OPTION",
        payload: {
          value: buckets[0].id,
          label: buckets[0].nombre,
        },
      });
      localStorage.setItem(
        "selectedBucket",
        JSON.stringify({
          value: buckets[0].id,
          label: buckets[0].nombre,
        })
      );
      parameterizationDispatch({
        type: "HANDLE_SET_SELECTED_STAGE",
        payload: buckets[0].etapas[0],
      });
      const maxIteration = buckets[0].etapas[0]?.iteraciones
        ? buckets[0].etapas[0].iteraciones.length - 1
        : null;
      if (maxIteration != null) {
        parameterizationDispatch({
          type: "SET_SELECTED_ITERATION",
          payload: buckets[0].etapas[0].iteraciones[maxIteration],
        });
      }
    }
  }
};

export const handleSetSelectedBucket = (
  selectedOption: any,
  parameterizationDispatch,
  buckets
) => {
  parameterizationDispatch({
    type: "SET_BUCKET_SELECTED_OPTION",
    payload: selectedOption,
  });
  localStorage.setItem("selectedBucket", JSON.stringify(selectedOption));
  if (buckets) {
    parameterizationDispatch({
      type: "HANDLE_SET_SELECTED_STAGE",
      payload: getStagesByBucketId(selectedOption.value, buckets)[0],
    });
    const maxIteration =
      getStagesByBucketId(selectedOption.value, buckets) &&
      getStagesByBucketId(selectedOption.value, buckets).length > 0 &&
      getStagesByBucketId(selectedOption.value, buckets)[0].iteraciones
        ? getStagesByBucketId(selectedOption.value, buckets)[0].iteraciones
            .length - 1
        : null;
    if (maxIteration) {
      parameterizationDispatch({
        type: "SET_SELECTED_ITERATION",
        payload: getStagesByBucketId(selectedOption.value, buckets)[0]
          .iteraciones[maxIteration],
      });
    }
  }
  parameterizationDispatch({
    type: "SET_DIFFERENTIATED_TARGETS",
    payload: [],
  });
};

export const handleResetOptions = (
  resetType: string,
  bucketSelectedOption: any,
  selectedStage: any,
  iteration?: number
) => {
  const resetOptions: {
    bucket: string;
    etapa: string;
    criterio: boolean;
    iteracion?: number;
  } = {
    bucket: "",
    etapa: "",
    criterio: false,
    iteracion: undefined,
  };

  switch (resetType) {
    case "Resetear el cálculo de etapa actual":
      resetOptions.bucket = bucketSelectedOption.value;
      resetOptions.etapa = selectedStage.etapa;
      break;
    case "Resetear todo el cálculo":
      break;
    case "Resetear el cálculo y etapas del bucket":
      resetOptions.bucket = bucketSelectedOption.value;
      resetOptions.criterio = true;
      break;
    case "Resetear todos los cálculos y etapas":
      resetOptions.criterio = true;
      break;
    case "Resetear el cálculo de la iteración actual":
      resetOptions.bucket = bucketSelectedOption.value;
      resetOptions.etapa = selectedStage.etapa;
      resetOptions.criterio = false;
      resetOptions.iteracion = iteration;
      break;
    default:
      return;
  }

  return resetOptions;
};

export const checkEqualsExpenseTarget = (
  rows: any,
  selectionModel
): boolean => {
  const selectedRows = selectionModel.map((id) =>
    rows.find((row) => row.id === id)
  );

  const expenseTargets: any[] = [];
  let uniqueExpenseTarget: any = null;
  if (selectedRows) {
    for (const row of selectedRows as any) {
      expenseTargets.push(row.receptor_gasto);
      // Verifica si la fila actual tiene receptor_gasto diferente a las filas anteriores.
      if (uniqueExpenseTarget === null) {
        uniqueExpenseTarget = row.receptor_gasto;
      } else if (uniqueExpenseTarget !== row.receptor_gasto) {
        return false;
      }
    }
  } else {
    return false;
  }

  return uniqueExpenseTarget !== null;
};

export const preparePropagateCriteriaData = (
  bucketSelectedOption,
  selectedStage,
  selectedGroupers,
  criteriaSelectedOption,
  expenseTargetSelectedOption,
  validationsToSend,
  checked,
  selectedIteration?
) => {
  const propagateCriteriaData = {
    bucket: bucketSelectedOption.value,
    etapa: selectedStage.etapa,
    iteracion: selectedIteration,
    agrupadores: selectedGroupers.map((grouper: any) => {
      return {
        agrupador: grouper.agrupador,
        destino: grouper.destino,
        destino_anterior: grouper.destino_anterior,
        causa_error: grouper.causa_error && grouper.causa_error,
      };
    }),
    criterio: criteriaSelectedOption.value,
    receptor_gasto: expenseTargetSelectedOption?.value,
    validaciones_anteriores: validationsToSend,
    tratamiento_diferenciado: checked,
  };
  return propagateCriteriaData;
};

export const preparePropagateGNTCriteriaData = (
  bucketSelectedOption,
  selectedStage,
  validationsToSend,
  dummySelectedValues,
  selectedIteration,
  columnFinalTargetSelectedOption
) => {
  const propagateGNTCriteriaData = {
    bucket: bucketSelectedOption.value,
    etapa: selectedStage.etapa,
    iteracion: selectedIteration.iteracion,
    validaciones_anteriores: validationsToSend,
    receptor_final: columnFinalTargetSelectedOption,
    traslado_dummy: dummySelectedValues.map((group) => {
      return {
        agrupadores: group.agrupadores,
        destinos_anteriores: group.destinos_anteriores.filter(
          (destino) => destino.columna_venta !== undefined
        ),
        columnas_valida: group.columnas_valida.filter(
          (columna) => columna.columna_venta !== undefined
        ),
      };
    }),
  };

  return propagateGNTCriteriaData;
};

export const getColumnTitle = (column, t) => {
  switch (column) {
    case "causa_error":
      return t("columns.cause_error");
    case "destino_anterior":
      return (
        <>
          {t("columns.cod_rec")}
          <br />
          {t("columns.etapas_anteriores")}
        </>
      );
    case "receptor_destino":
      return (
        <>
          {t("columns.tipo_receptor")}
          <br />
          {t("columns.gasto_anterior")}
        </>
      );
    case "destino":
      return (
        <>
          {t("columns.cod_re")}
          <br />
          {t("columns.anterior")}
        </>
      );
    case "gasto":
      return t("columns.gasto");
    case "monto":
      return t("columns.gasto");
    case "receptor_criterio":
      return (
        <>
          {t('columns.receptor_de')}
          <br />
          {t("columns.gast")}
        </>
      );
    case "criterio_prorrateo":
      return (
        <>
          {t('columns.criterio_de')}
          <br />
          {t('columns.prorrateo')}
        </>
      );
    case "gastos_trasladados":
      return (
        <>
          {t('columns.gastos')}
          <br />
          {t('columns.trasladados')}
        </>
      );      
    default:
      return column;
  }
};

export const checkRowsWithoutStages = (rows: any) => {
  const buckets: any = {};
  rows.forEach((row: any) => {
    const bucket: string = row.hierarchy[0];
    if (!buckets.hasOwnProperty(bucket)) {
      buckets[bucket] = [row];
    } else {
      buckets[bucket].push(row);
    }
  });

  const bucketsWithUnassignedStages: any[] = [];
  for (const bucket in buckets) {
    const rowsWithoutStages = buckets[bucket].filter((row: any) => {
      return row.etapas === null || row.etapas === "";
    });
    if (rowsWithoutStages.length > 1) {
      bucketsWithUnassignedStages.push(buckets[bucket][0].hierarchy[0]);
    }
  }
  return bucketsWithUnassignedStages;
};

export const tabsGNT: Tab[] = [
  { value: "CRITERIO", label: "CRITERIO" },
  { value: "TRASLADO DIRECTO DUMMY", label: "TRASLADO DIRECTO DUMMY" },
];
export const checkNotTransferredExpenses = (
  bucketId: number,
  selectedStage: number,
  dataBuckets: any
) => {
  const bucket = getBucketById(bucketId, dataBuckets);
  const stageInfo = bucket.etapas.find((stage: Etapa) => {
    return stage.etapa === selectedStage + 1;
  });
  if (stageInfo && stageInfo.iteraciones && stageInfo.iteraciones.length > 0) {
    const hasNotTransferredExpenses =
      stageInfo.iteraciones[stageInfo.iteraciones.length - 1].calculado;
    return !hasNotTransferredExpenses;
  } else {
    return false;
  }
};

export const salesSelectOptions = (
  fileColumnSelectOptions: IncomingFileOption[] | undefined
) => {
  const findColumnsArray: IncomingFile_Column[] | undefined =
    fileColumnSelectOptions
      ? fileColumnSelectOptions.find(
          (option: IncomingFileOption) => option?.nombre === "VENTAS"
        )?.columna_archivo
      : [];
  return findColumnsArray?.map((option: IncomingFile_Column) => {
    return {
      value: option.id,
      label: option.nombre,
    };
  });
};

export const prepareRowsForDummy = (
  selectionModel,
  rows,
  bucketSelectedOption,
  selectedStage,
  selectedIteration
) => {
  const rowsToTransfer: any[] = [];
  selectionModel.forEach((id) => {
    const row = rows.find((row) => row.id === id);
    rowsToTransfer.push(row);
  });
  return {
    bucket: bucketSelectedOption.value,
    etapa: selectedStage.etapa,
    iteracion: selectedIteration,
    agrupadores: rowsToTransfer.map((row) => {
      return {
        agrupador: row.agrupador_id,
        destino: row.destino,
        causa_error: row.causa_error,
      };
    }),
  };
};

export const checkIfAmountIsZero = (rows: any) => {
  if (Math.ceil(rows?.monto_total) === 0) {
    return !rows.resultado.some((row) => Math.ceil(row.monto) !== 0);
  } else {
    return false;
  }
};

export function checkArraysOverlap(arrays) {
  const arraySet = new Set();

  if (!arrays || arrays.length === 0) return false;
  for (const array of arrays) {
    const arrayString = JSON.stringify(array);

    if (arraySet.has(arrayString)) {
      return true; // Se encontró un conjunto repetido
    }

    arraySet.add(arrayString);
  }

  return false; // No se encontraron conjuntos repetidos
}

/**
 * Verifica si hay hijos no seleccionados para una fila específica.
 *
 * @param {object} row - La fila actual que se está verificando.
 * @param {Array} selectedRowData - Array de filas seleccionadas.
 * @param {Array} rowsResult - Array de todas las filas disponibles.
 * @returns {boolean} - Devuelve true si hay hijos no seleccionados, de lo contrario, false.
 */

export const hasUnselectedChildren = (
  row: any,
  selectedRowData: any[],
  rowsResult: any[]
) => {
  if (rowsResult) {
    const isChild = (r: any) =>
      r.hierarchy[0] === row.hierarchy[0] && r.id !== row.id;
    const actualSelectedChildren = selectedRowData.filter(isChild);
    const totalChildren = rowsResult.filter(isChild);
    return totalChildren.length > actualSelectedChildren.length;
  }
  return false;
};

export const updateGroupedSelection = (
  selectedRowData: Row[],
  selectedBuckets: Buckets,
  rowsResult: Row[],
  parameterizationDispatch: (action: ParameterizationDispatch) => void,
  setSelectedBuckets: (buckets: Buckets) => void
): void => {
  let newRows = selectedRowData;

  const filteredSelectedBuckets = selectedRowData.filter(
    (row) => row.hierarchy.length === 1
  );

  const removedBuckets: any[] = [];

  // Filtra los buckets seleccionados previamente que se han deseleccionado o tienen hijos no seleccionados.
  selectedBuckets.forEach((bucket) => {
    if (
      !filteredSelectedBuckets.some(
        (b) => b.hierarchy[0] === bucket.hierarchy[0]
      ) ||
      hasUnselectedChildren(bucket, selectedRowData, rowsResult)
    ) {
      removedBuckets.push(bucket);
    }
  });

  // Filtra los buckets seleccionados para excluir los removidos.
  const updatedSelectedBuckets = filteredSelectedBuckets.filter(
    (bucket) =>
      !removedBuckets.some((b) => b.hierarchy[0] === bucket.hierarchy[0])
  );

  //setear los selected buckets filtrando que solo queden los que son diferentes a los removedBuckets
  /* const newSelectedBucketsWithoutRemovedOnes: any[] = [];
  filteredSelectedBuckets .forEach((row) => {
    if (!removedBuckets.some((b) => b.hierarchy[0] === row.hierarchy[0])) {
      newSelectedBucketsWithoutRemovedOnes.push(row);
    }
  }); */

  setSelectedBuckets(updatedSelectedBuckets);

  // Agrega los hijos de los buckets seleccionados a las filas seleccionadas.
  updatedSelectedBuckets.forEach((bucket) => {
    rowsResult?.forEach((childrenRow: any) => {
      if (childrenRow["hierarchy"][0] === bucket["hierarchy"][0]) {
        newRows.push(childrenRow);
      }
    });
  });

  // Elimina los hijos de los buckets removidos de las filas seleccionadas.
  removedBuckets?.forEach((bucket) => {
    if (!hasUnselectedChildren(bucket, selectedRowData, rowsResult)) {
      newRows = newRows.filter(
        (row) => row.hierarchy[0] !== bucket.hierarchy[0]
      );
    } else {
      newRows = newRows.filter((row) => row.id !== bucket.id);
    }
  });

  /* const ids: GridSelectionModel = [];
  newRows.forEach((row) => {
    ids.push(row.id);
  }); */

  // Extrae los IDs únicos de las filas seleccionadas para actualizar el modelo de selección.
  /* const uniqueIds = [...new Set(newRows.map((row) => row.id))]; */
  const uniqueIds: number[] = [];

  newRows.forEach((row) => {
    if (!uniqueIds.includes(row.id)) {
      uniqueIds.push(row.id);
    }
  });

  // Actualiza el modelo de selección eliminando IDs duplicados.
  parameterizationDispatch({
    type: "SET_SELECTION_MODEL",
    payload: removeDuplicates(uniqueIds),
  });
};

export const isParentRowWithCriteria = (
  row: any,
  groupersWithCriteria: string[]
) => {
  return groupersWithCriteria.includes(row.hierarchy[0]);
};

/**
 * Actualiza el modelo de selección para todas las filas basándose en un tipo de selección especificado.
 *
 * @param {string} selectType - Tipo de selección (por ejemplo, "Todas las filas").
 */
export const selectAllRows = (
  selectType: string,
  rowsResult: any[],
  parameterizationDispatch: (action: ParameterizationDispatch) => void,
  t: (key: string) => string
) => {
  const groupersWithCriteria: string[] = [];

  // Recorre las filas y agrega las jerarquías con criterios a la lista.
  rowsResult.forEach((row: any) => {
    if (row.etapas) {
      groupersWithCriteria.push(row.hierarchy[0]);
    }
  });

  const selectedRows: number[] = [];
  // Recorre las filas y selecciona las filas según el tipo de selección especificado.
  rowsResult.forEach((row: any) => {
    if (selectType === t("modelParameterizationTexts.modelTable.all")) {
      selectedRows.push(row.id);
    } else {
      if (!row.etapas) {
        if (row.hierarchy[1]) {
          selectedRows.push(row.id);
        } else {
          if (!isParentRowWithCriteria(row, groupersWithCriteria)) {
            selectedRows.push(row.id);
          }
        }
      }
    }
  });
  parameterizationDispatch({
    type: "SET_SELECTION_MODEL",
    payload: selectedRows,
  });
};

/**
 * Actualiza el modelo de selección para las filas sin criterios basándose en un tipo de selección especificado.
 *
 * @param {string} selectType - Tipo de selección (por ejemplo, "Todas las filas" o "Sin criterios").
 */

export const selectRowsWithoutCriteria = (
  selectType: string,
  rowsResult: any[],
  parameterizationDispatch: (action: ParameterizationDispatch) => void,
  t: (key: string) => string
) => {
  // Recorre las filas y agrega las jerarquías con criterios a la lista.
  const groupersWithCriteria: string[] = rowsResult
    .filter((row: any) => row.criterio_prorrateo)
    .map((row: any) => row.hierarchy[0]);

  const selectedRows: number[] = [];

  // Recorre las filas y selecciona las filas sin criterios según el tipo de selección especificado.
  rowsResult.forEach((row: any) => {
    if (selectType === t("modelParameterizationTexts.modelTable.all")) {
      selectedRows.push(row.id);
    } else if (
      selectType === t("modelParameterizationTexts.modelTable.withoutCritera")
    ) {
      if (!row.criterio_prorrateo) {
        if (row.hierarchy[1]) {
          selectedRows.push(row.id);
        } else {
          if (!isParentRowWithCriteria(row, groupersWithCriteria)) {
            selectedRows.push(row.id);
          }
        }
      }
    }
  });
  parameterizationDispatch({
    type: "SET_SELECTION_MODEL",
    payload: selectedRows,
  });
};

/**
 * Filtra las filas de la tabla basándose en los criterios de ex-criterio y receptor de gasto.
 * @returns {Array} - Un array de filas que cumplen con los criterios de filtrado.
 */
export const filterRowsByCriteria = (
  t,
  tabValue,
  rows,
  rowsNt,
  rowCopyCriteria
) => {
  return tabValue === t("modelParameterizationTexts.step2.tabValueExpenses")
    ? rows.resultado.filter(
        (row: any) =>
          row.receptor_criterio === rowCopyCriteria.receptor_criterio &&
          row.criterio_prorrateo === rowCopyCriteria.criterio_prorrateo
      )
    : rowsNt.resultado.filter(
        (row: any) =>
          row.receptor_criterio.value ===
            rowCopyCriteria.receptor_criterio.value &&
          row.criterio_prorrateo === rowCopyCriteria.criterio_prorrateo
      );
};

/**
 * Crea el cuerpo de la solicitud para propagar los criterios duplicados al backend.
 * @param {Array} rows - Las filas que cumplen con los criterios de filtrado.
 * @returns {Object} - El cuerpo de la solicitud para propagar los criterios duplicados.
 */
export const createPropagateCriteriaRequestBody = (
  rows: any[],
  t,
  tabValue,
  bucketSelectedOption,
  selectedStage,
  selectedIteration,
  newDuplicatedCriteria
) => {
  return {
    etapa: selectedStage?.etapa,
    iteracion:
      tabValue === t("modelParameterizationTexts.step2.tabValueExpenses")
        ? undefined
        : selectedIteration?.iteracion,
    bucket: bucketSelectedOption?.value,
    criterio: newDuplicatedCriteria,
    receptor_gasto: null,
    tratamiento_diferenciado: null,
    validaciones_anteriores: [],
    es_duplicado: true,
    agrupadores: rows.map((grouper: any) => {
      return {
        agrupador: grouper.agrupador_id,
        destino: grouper.destino,
        destino_anterior: grouper.destino_anterior,
        causa_error: grouper.causa_error && grouper.causa_error,
      };
    }),
  };
};

export const cancelPropagateCriteriaRequestBody = (
  rowCopyCriteria,
  t,
  tabValue,
  bucketSelectedOption,
  selectedStage,
  selectedIteration,
  newDuplicatedCriteria
) => {
  return {
    etapa: selectedStage?.etapa,
    iteracion:
      tabValue === t("modelParameterizationTexts.step2.tabValueExpenses")
        ? undefined
        : selectedIteration?.iteracion,
    bucket: bucketSelectedOption?.value,
    criterio: newDuplicatedCriteria,
    receptor_gasto: null,
    tratamiento_diferenciado: null,
    validaciones_anteriores: [],
    es_duplicado: true,
    agrupadores: [
      {
        agrupador: rowCopyCriteria.agrupador_id,
        destino: rowCopyCriteria.destino,
        destino_anterior: rowCopyCriteria.destino_anterior,
      },
    ],
  };
};

export const handleRefetchRowsDataNt = (
  parameterizationState,
  parameterizationDispatch,
  getProrationResultsNoTrasladados
) => {
  if (
    parameterizationState?.bucketSelectedOption &&
    parameterizationState?.selectedStage &&
    parameterizationState?.selectedIteration &&
    parameterizationState?.selectedIteration.iteracion
  ) {
    getProrationResultsNoTrasladados();
  }
  parameterizationDispatch({
    type: "SET_SELECTION_MODEL",
    payload: [],
  });
};

export const handleRefetchRowsData = (
  parameterizationState,
  parameterizationDispatch,
  getProrationResults
) => {
  if (
    parameterizationState?.bucketSelectedOption &&
    parameterizationState?.selectedStage
  ) {
    getProrationResults(
      `${GET_PRORRATEO_RESULTADOS}?bucket=${
        parameterizationState?.bucketSelectedOption?.value
      }&etapa=${
        parameterizationState?.selectedStage?.etapa
      }&mostrar_detalle=${parameterizationState?.differentiatedTargets.toString()}`
    );
  }
  parameterizationDispatch({
    type: "SET_SELECTION_MODEL",
    payload: [],
  });
};

const handleClickZoomIcon = (
  value: string,
  id: any,
  rows: any,
  setRowCopyCriteria,
  handleOpenCriteriaModal,
  handleOpenCriteriaDummyModal
) => {
  setRowCopyCriteria(getRowByRowId(id, rows));
  if (value !== "TRASLADO DIRECTO DUMMY") {
    //TODO ver con back traducción y revisar componente
    handleOpenCriteriaModal(getRowByRowId(id, rows)?.aplicacionprorrateo_id);
  } else {
    handleOpenCriteriaDummyModal(
      getRowByRowId(id, rows)?.aplicacionprorrateo_id
    );
  }
};

const renderValueNumber = (column, value, id, rows) => {
  return value === 0 ? (
    <CustomTypographyTableText
      column={column}
      isRowIdAGrouper={() => isRowIdAGrouper(id, rows)}
    >
      $ 0,00
    </CustomTypographyTableText>
  ) : (
    convertNumberToMoney(value)
  );
};

const getErrorsList = (
  list: { archivo: string; columna: string; periodo: string }[]
) => {
  return list?.map((error) => (
    <li key={error.archivo + error.columna}>
      La columna {" " + error.columna} no se encuentra en el archivo{" "}
      {" " + error.archivo} para el periodo {" " + error.periodo}
    </li>
  ));
};

export const renderCustomTypographyTableText = (
  t,
  column,
  value,
  id,
  rows,
  setRowCopyCriteria,
  handleOpenCriteriaModal,
  handleOpenCriteriaDummyModal
) => {
  const isGrouper = isRowIdAGrouper(id, rows);
  const row = getRowByRowId(id, rows);
  switch (column) {
    case "monto":
      return renderValueNumber(column, value, id, rows);
    case "gastos_trasladados":
      return renderValueNumber(column, value, id, rows);
    case "criterio_prorrateo":
      if (value?.length) {
        const truncatedValue = truncateString(value, 20);
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={0.3}
          >
            <Tooltip title={value}>
              <span>
                <CustomTypographyTableText
                  column={column}
                  isRowIdAGrouper={isGrouper}
                >
                  {truncatedValue}
                </CustomTypographyTableText>{" "}
              </span>
            </Tooltip>
            <Tooltip title={"Ver detalle"}>
              <ZoomInIcon
                onClick={() =>
                  handleClickZoomIcon(
                    value,
                    id,
                    rows,
                    setRowCopyCriteria,
                    handleOpenCriteriaModal,
                    handleOpenCriteriaDummyModal
                  )
                }
                sx={zoomin_icon_styles}
              />
            </Tooltip>
            {!!row?.tratamiento_diferenciado && (
              <Tooltip title={"Tratamiento diferenciado"}>
                <CallSplitIcon
                  sx={{ ...zoomin_icon_styles, color: "var(--orange)" }}
                />
              </Tooltip>
            )}
            {!!row?.columnas_error && row?.columnas_error.length > 0 && (
              <Tooltip title={getErrorsList(row?.columnas_error)}>
                <WarningAmberIcon
                  sx={{ ...zoomin_icon_styles, color: "var(--icon-error)" }}
                />
              </Tooltip>
            )}
          </Stack>
        );
      } else if (!value?.length && row?.aplicacionprorrateo_id) {
        return (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Tooltip title={value}>
              <span>
                <CustomTypographyTableText
                  column={column}
                  isRowIdAGrouper={isGrouper}
                >
                  {t("modelParameterizationTexts.dummyTransfer")}
                </CustomTypographyTableText>{" "}
              </span>
            </Tooltip>
            <ZoomInIcon
              onClick={() =>
                handleOpenCriteriaDummyModal(row.aplicacionprorrateo_id)
              }
              sx={zoomin_icon_styles}
            />
          </Stack>
        );
      }
      return (
        <CustomTypographyTableText column={column} isRowIdAGrouper={isGrouper}>
          {value}
        </CustomTypographyTableText>
      );
    case "receptor_criterio":
      if (typeof value !== "string") {
        if (row?.criterio_prorrateo !== "TRASLADO DIRECTO DUMMY") {
          return (
            <CustomTypographyTableText
              column={column}
              isRowIdAGrouper={isGrouper}
            >
              {value?.label}
            </CustomTypographyTableText>
          );
        } else {
          return "";
        }
      } else {
        return (
          <CustomTypographyTableText
            column={column}
            isRowIdAGrouper={() => isRowIdAGrouper(id, rows)}
          >
            {value}
          </CustomTypographyTableText>
        );
      }

    default:
      return (
        <CustomTypographyTableText column={column} isRowIdAGrouper={isGrouper}>
          {value}
        </CustomTypographyTableText>
      );
  }
};

export const generateGroupersGroups = (
  dummySelectedValues,
  dataForDummy,
  setDummySetectedValues
) => {
  const groups = dummySelectedValues;
  dataForDummy &&
    dataForDummy.forEach((data: any, index: number) => {
      groups.push({
        agrupadores: data.agrupadores,
        id_agrupadores: index,
        destinos_anteriores: data.destinos_anteriores.map((dest_ant) => {
          return {
            etapa: dest_ant.etapa,
            receptor: dest_ant.receptor,
            columna_venta: undefined,
          };
        }),
        columnas_valida: data.columnas_valida.map((col_val) => {
          return {
            columna_gasto: col_val,
            columna_venta: undefined,
          };
        }),
      });
    });
  setDummySetectedValues(groups);
};

export const checkSelectedChildren = (
  row: any,
  selectedRowData: any[],
  rows
) => {
  if (rows) {
    const actualSelectedChildren = selectedRowData.filter(
      (r) => r.hierarchy[0] === row.hierarchy[0] && r.id !== row.id
    );
    const totalChildren = rows.filter(
      (r: any) => r["hierarchy"][0] === row.hierarchy[0] && r["id"] !== row.id
    );
    return totalChildren.length > actualSelectedChildren.length;
  }
};

export const handleGrouperSelection = (
  selectedRowData: any[],
  selectedBuckets,
  rows,
  setSelectedBuckets,
  setSelectionModel
) => {
  let newRows = selectedRowData;

  const newSelectedBuckets = selectedRowData.filter(
    (row) => row.hierarchy.length === 1
  );

  const removedBuckets: any[] = [];

  selectedBuckets.forEach((row) => {
    if (
      !newSelectedBuckets.some((b) => b.hierarchy[0] === row.hierarchy[0]) ||
      checkSelectedChildren(row, selectedRowData, rows)
    ) {
      removedBuckets.push(row);
    }
  });

  //setear los selected buckets filtrando que solo queden los que son diferentes a los removedBuckets
  const newSelectedBucketsWithoutRemovedOnes: any[] = [];
  newSelectedBuckets.forEach((row) => {
    if (!removedBuckets.some((b) => b.hierarchy[0] === row.hierarchy[0])) {
      newSelectedBucketsWithoutRemovedOnes.push(row);
    }
  });
  setSelectedBuckets(newSelectedBucketsWithoutRemovedOnes);

  newSelectedBucketsWithoutRemovedOnes.forEach((row) => {
    rows?.forEach((childrenRow: any) => {
      if (childrenRow["hierarchy"][0] === row["hierarchy"][0]) {
        newRows.push(childrenRow);
      }
    });
  });

  //aca estamos removiendo de las selectedRows los hijos de los buckets removidos
  if (removedBuckets.length > 0) {
    removedBuckets.forEach((element) => {
      if (!checkSelectedChildren(element, selectedRowData, rows)) {
        newRows = newRows.filter(
          (row) => row.hierarchy[0] !== element.hierarchy[0]
        );
      } else {
        newRows = newRows.filter((row) => row.id !== element.id);
      }
    });
  }
  //por ultimo sacamos de newRows solo los ids para setearlos en el selectiom model
  const ids: GridSelectionModel = [];
  newRows.forEach((row) => {
    ids.push(row.id);
  });

  //removemos los ids repetidos
  setSelectionModel(removeDuplicates(ids as number[]));
};

export const isBucketWithStage = (row: any, bucketsWithStages: string[]) => {
  return bucketsWithStages.includes(row.hierarchy[0]);
};

export const convertNumberToPercentage = (value: number) => {
  return (
    (value * 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) + "%"
  );
};

export const getText = (percentage: "-" | number) => {
  if (percentage === "-") {
    return {
      color: "var(--text-info)",
      message: "-",
      lightColor: "var(--text-disabled)",
    };
  } else if (Math.abs(1 - percentage) <= 0.0005) {
    return {
      color: "var(--green)",
      message: convertNumberToPercentage(percentage),
      lightColor: "#b5d1c2",
    };
  } else if (Math.abs(1 - percentage) <= 0.005) {
    return {
      color: "var(--yellow)",
      message: convertNumberToPercentage(percentage),
      lightColor: "var(--light-yellow)",
    };
  } else if (Math.abs(1 - percentage) <= 0.015) {
    return {
      color: "var(--orange)",
      message: convertNumberToPercentage(percentage),
      lightColor: "var(--light-orange)",
    };
  } else {
    return {
      color: "var(--red)",
      message: convertNumberToPercentage(percentage),
      lightColor: "var(--bg-error-hover)",
    };
  }
};
