
import { useApiQuery } from "../../../../hooks/useApiQuery";
import { useParameterization } from "../../../../hooks/useParameterization";
import { useState } from "react";
import ConfirmationModal from "../../../dialog/ConfirmationModal";
import Dialog from "../../../dialog/Dialog";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import {
  GET_PRORRATION_CRITERIA_URL,
  POST_PROPAGATE_CRITERIA_URL,
} from "../../../../api/axios";
import {
  cancelPropagateCriteriaRequestBody,
  createPropagateCriteriaRequestBody,
  filterRowsByCriteria,
  handleRefetchRowsData,
  handleRefetchRowsDataNt,
} from "../../utils";
import ConfigCriteria from "../../../criterias/configCriteria/ConfigCriteria";

const ConfigCriteriaModal = () => {
  const { t } = useLanguage();
  const {
    parameterizationState,
    parameterizationDispatch,
    openEditCriteriaModal,
    handleToggleEditCriteriaModal,
    
    openReasignCriteriaConfirmationModal,
    handleToggleReasignCriteriaConfirmationModal,
    rows,
    rowsNt,
    getProrationResultsNoTrasladados,
    getProrationResults
  } = useParameterization();
  const {
    configMode,
    tabValue,
    rowCopyCriteria,
    bucketSelectedOption,
    selectedStage,
    selectedIteration,
    criteria,
  } = parameterizationState;
  const [newDuplicatedCriteria, setNewDuplicatedCriteria] = useState<any>();

  const { data: getCriteria, refetch: refetchCriteria } = useApiQuery(
    GET_PRORRATION_CRITERIA_URL,
    false,
    t("modelParameterizationTexts.modelTable.getCriteriaError")
  );

  const onSuccessPropagateCriteria = () => {
    refetchCriteria();
    tabValue === t("modelParameterizationTexts.step2.tabValueExpenses")
      ? handleRefetchRowsData(
        parameterizationState,
        parameterizationDispatch,
        getProrationResults
      )
      : handleRefetchRowsDataNt(
        parameterizationState,
        parameterizationDispatch,
        getProrationResultsNoTrasladados
      );
  };

  const {
    isLoading: propagateCriteriaLoading,
    callApi: postPropagateCriteria,
  } = useApi(
    POST_PROPAGATE_CRITERIA_URL,
    "POST",
    t("modelParameterization.propagateCriteria.codes"),
    undefined,
    onSuccessPropagateCriteria
  );

  /**
   * Maneja la acción de aceptar la reasignación de criterios.
   * Filtra las filas de la tabla que tienen el mismo ex-criterio y receptor de gasto,
   * y luego propaga los criterios duplicados al backend. */
  /*Se encarga de asignar el criterio duplicado en las filas que tenian el criterio anterior*/

  const handleAcceptReasignCriteria = () => {
    // Filtra las filas con el mismo ex-criterio y receptor de gasto
    const rowsWithSameReceptorAndCriteria = filterRowsByCriteria(
      t,
      tabValue,
      rows,
      rowsNt,
      rowCopyCriteria
    );
    // Muestra el modal de confirmación de reasignación de criterios
    handleToggleReasignCriteriaConfirmationModal();
    // Crea el cuerpo de la solicitud para propagar los criterios duplicados
    const body = createPropagateCriteriaRequestBody(
      rowsWithSameReceptorAndCriteria,
      t,
      tabValue,
      bucketSelectedOption,
      selectedStage,
      selectedIteration,
      newDuplicatedCriteria
    );
    postPropagateCriteria(POST_PROPAGATE_CRITERIA_URL, body);
  };

  const handleCancelReasignCriteria = () => {
    handleToggleReasignCriteriaConfirmationModal();
    const body = cancelPropagateCriteriaRequestBody(
      rowCopyCriteria,
      t,
      tabValue,
      bucketSelectedOption,
      selectedStage,
      selectedIteration,
      newDuplicatedCriteria
    );
    postPropagateCriteria(POST_PROPAGATE_CRITERIA_URL, body);
  };

  const handleFinish = (data: any) => {
    if (data === t("modelParameterizationTexts.step2.close")) {
      handleToggleEditCriteriaModal();
    } else {
      handleToggleEditCriteriaModal();
      refetchCriteria();
      handleRefetchRowsData(
        parameterizationState,
        parameterizationDispatch,
        getProrationResults
      )
      if (configMode === "COPY") {
        setNewDuplicatedCriteria(data.id);
        handleToggleReasignCriteriaConfirmationModal();
      }
    }
  };

  return (
    <>
      <Dialog
        open={openEditCriteriaModal}
        handleClose={handleToggleEditCriteriaModal}
        title={t("modelParameterizationTexts.step2.editCriteria")}
        maxWidth={"xl"}
      >
        <ConfigCriteria
          criteriaType={t("modelParameterizationTexts.step2.apportionment")}
          handleFinish={handleFinish}
          configMode={configMode}
          criteria={criteria}
          criteriaList={getCriteria}
          parameterization={true}
        />
      </Dialog>
      <ConfirmationModal
        open={openReasignCriteriaConfirmationModal}
        handleClose={handleCancelReasignCriteria}
        handleAccept={handleAcceptReasignCriteria}
        message={t(
          "modelParameterizationTexts.step2.reassingNewCriteriaInAllGroupersWithReceiverAndOldCriteria"
        )}
        customMessage={true}
        title={t("modelParameterizationTexts.step2.reassingCriteriaToRows")}
        greyButtonText={t('general.close')}
      />
      <SimpleBackdrop
        open={propagateCriteriaLoading}
        message={t("modelParameterization.propagateCriteria.loading")}
      />
    </>
  );
};

export default ConfigCriteriaModal;
