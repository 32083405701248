import "../../forms/step.styles.css";
import { Box, Stack, Typography } from "@mui/material";
import { CustomSelect as Select } from "../../forms";
import { form_subtitle_styles } from "../../../styles/app-styles";
import { GRUPOS_GRAFICOS } from "../../../api/axios";
import { Step1ChartProps } from "../types";
import { useEffect, useRef, useState } from "react";
import AddButton from "../../buttons/AddButton";
import AddGroupModal from "../AddGroupModal";
import CustomInput from "../../forms/CustomInput";
import Delete from "../../icons/Delete";
import DivisionSection from "../DivisionSection";
import FormLayout from "../../forms/FormLayout";
import FormSelectLayout from "../../forms/FormSelectLayout";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import {
  columnOptions,
  fileOptions,
  functionOptions,
} from "../../criterias/configCriteria/selectOptions";
import {
  optionalSelectStyles,
  selectStyles,
} from "../../criterias/configCriteria/select.styles";

const Step1Charts = ({
  chartType,
  setChartFormComplete,
  fileColumnSelectOptions,
  functionSelectOptions,
  fileSelectedOptions,
  fileOptionsChangeHandler,
  chartName,
  setChartName,
  columnAxisYSelectedOptions,
  columnAxisYOptionsChangeHandler,
  columnAxisXSelectedOptions,
  columnAxisXOptionsChangeHandler,
  columnBaseSelectedOptions,
  columnBaseOptionsChangeHandler,
  columnSeriesSelectedOptions,
  columnSeriesOptionsChangeHandler,
  columnValuesScatterSelectedOption,
  columnValuesScatterChangeHandler,
  functionSelectedOptions,
  functionOptionsChangeHandler,
  activeIndicator,
  setActiveIndicator,
  columnAxisYSelectRef,
  columnAxisXSelectRef,
  columnSeriesSelectRef,
  columnValuesScatterSelectRef,
  chartNameRef,
  fileSelectRef,
  functionSelectRef,
  axisYName,
  setAxisYName,
  axisYNameRef,
  axisXName,
  setAxisXName,
  axisXNameRef,
  columnsDrilldownQuantity,
  setColumnsDrilldownQuantity,
  columnSelects,
  setSelectedOptions,
  selectRefs,
  selectedOptions,
  columnBaseSelectRef,
  message,
  groupRef,
  groupSelectedOptions,
  groupChangeHandler,
  groupSelectOptions,
  refetchGetGroups,
  fileType,
  auxiliaryLines,
  setAuxiliaryLines,
  auxiliaryLinesColor,
  setAuxiliaryLinesColor,
  divisionRows,
  setDivisionRows,
}: Step1ChartProps) => {
  const { t } = useLanguage();
  const [openAddGroupModal, setOpenAddGroupModal] = useDialog();

  const handleChartFormComplete = () => {
    const commonValidations = {
      BOXPLOT: () =>
        !!chartName &&
        !!fileSelectedOptions &&
        !!columnBaseSelectedOptions &&
        !!columnAxisXSelectedOptions &&
        !message,
      "BARRAS CON DRILLDOWN": () =>
        !!chartName &&
        !!fileSelectedOptions &&
        !!columnAxisXSelectedOptions &&
        !!functionSelectedOptions &&
        !message &&
        (functionSelectedOptions.label !== "DIVISION" ||
          divisionRows.length > 0),
      LINEAS: () =>
        !!chartName &&
        !!fileSelectedOptions &&
        !!functionSelectedOptions &&
        !!columnAxisXSelectedOptions &&
        !message &&
        (functionSelectedOptions.label !== "DIVISION" ||
          divisionRows.length > 0),
      "TORTA CON DRILLDOWN": () =>
        !!chartName &&
        !!fileSelectedOptions &&
        !!functionSelectedOptions &&
        !!columnBaseSelectedOptions &&
        !message &&
        (functionSelectedOptions.label !== "DIVISION" ||
          divisionRows.length > 0),
      "TREEMAP CON DRILLDOWN": () =>
        !!chartName &&
        !!fileSelectedOptions &&
        !!functionSelectedOptions &&
        !!columnAxisXSelectedOptions &&
        !!columnBaseSelectedOptions &&
        !message,
    };

    const templateValidations = {
      "BARRAS CON DRILLDOWN": () =>
        !!groupSelectedOptions &&
        !!columnAxisYSelectedOptions &&
        columnAxisYSelectedOptions?.length > 0 &&
        commonValidations["BARRAS CON DRILLDOWN"](),
      LINEAS: () =>
        !!groupSelectedOptions &&
        !!columnAxisYSelectedOptions &&
        columnAxisYSelectedOptions?.length > 0 &&
        commonValidations.LINEAS(),
      BOXPLOT: () => !!groupSelectedOptions && commonValidations.BOXPLOT,
      "TORTA CON DRILLDOWN": () =>
        !!groupSelectedOptions && commonValidations["TORTA CON DRILLDOWN"],
      "TREEMAP CON DRILLDOWN": () =>
        !!groupSelectedOptions && commonValidations["TREEMAP CON DRILLDOWN"],
    };

    const analysisValidations = {
      "BARRAS CON DRILLDOWN": () =>
        !!columnAxisYSelectedOptions &&
        commonValidations["BARRAS CON DRILLDOWN"](),
      LINEAS: () => !!columnAxisYSelectedOptions && commonValidations.LINEAS(),
      BOXPLOT: commonValidations.BOXPLOT,
      "TORTA CON DRILLDOWN": commonValidations["TORTA CON DRILLDOWN"],
      "TREEMAP CON DRILLDOWN": commonValidations["TREEMAP CON DRILLDOWN"],
      "SCATTERPLOT": () =>
        !!chartName &&
        !!fileSelectedOptions &&
        !!columnAxisXSelectedOptions &&
        !!columnAxisYSelectedOptions &&
        !message,
    };

    const validationsByFileType = {
      TEMPLATE: templateValidations,
      ANALISIS: analysisValidations,
    };

    const validateForm = () => {
      if (fileType && chartType) {
        const validations = validationsByFileType[fileType] || {};
        return validations[chartType]?.() || false;
      }
      return false;
    };

    setChartFormComplete(validateForm());
  };

  useEffect(() => {
    handleChartFormComplete();
  }, [
    chartName,
    fileSelectedOptions,
    groupSelectedOptions,
    columnAxisYSelectedOptions,
    columnAxisYSelectedOptions?.length,
    columnAxisXSelectedOptions,
    functionSelectedOptions,
    columnBaseSelectedOptions,
    message,
    divisionRows,
  ]);

  const toggleActiveIndicator = () => {
    setActiveIndicator((prev: boolean) => !prev);
  };

  const toggleAuxiliaryLines = (
    line: "linea_cero" | "linea_promedio_x" | "linea_promedio_y"
  ) => {
    setAuxiliaryLines((prev: any) => ({ ...prev, [line]: !prev[line] }));
  };

  const toggleAuxiliaryLinesColor = (
    e: React.ChangeEvent<HTMLInputElement>,
    line: "linea_cero" | "linea_promedio_x" | "linea_promedio_y"
  ) => {
    setAuxiliaryLinesColor((prev: any) => ({
      ...prev,
      [line]: e?.target?.value,
    }));
  };

  const handleAddDrilldownSelect = (index: number) => {
    if (selectRefs.current[index].getValue().length) {
      setColumnsDrilldownQuantity(columnsDrilldownQuantity + 1);
    }
  };

  const handleDeleteSelectValue = (index: number) => {
    selectRefs.current[index].clearValue();
    if (index === 0) {
      setSelectedOptions([]);
    } else {
      setColumnsDrilldownQuantity(columnsDrilldownQuantity - 1);
      const auxSelectedOptions = [...selectedOptions];
      setSelectedOptions(auxSelectedOptions.slice(0, index));
      selectRefs.current.pop();
    }
  };

  const prepareAxisXOptions = () => {
    if (fileType === "TEMPLATE") {
      let totalOptions: any[] = [];
      if (columnOptions(fileColumnSelectOptions, fileSelectedOptions)) {
        const fileOptions: any[] = columnOptions(
          fileColumnSelectOptions,
          fileSelectedOptions
        ) as any[];
        totalOptions = [
          {
            label: t("indicatorsManagementTexts.step1Charts.aplicationOptions"),
            options: [
              {
                value: t("indicatorsManagementTexts.step1Charts.periods"),
                label: t(
                  "indicatorsManagementTexts.step1Charts.periods"
                ).toUpperCase(),
              },
            ],
          },
          {
            label: t("indicatorsManagementTexts.step1Charts.fileColumns"),
            options: [...fileOptions],
          },
        ];
      }
      return totalOptions;
    } else {
      return columnOptions(fileColumnSelectOptions, fileSelectedOptions);
    }
  };

  const newGroupNameRef: any = useRef(null);
  const [newGroupName, setNewGroupName] = useState("");

  const onSuccessNewGroup = () => {
    refetchGetGroups();
    setNewGroupName("");
    newGroupNameRef.value = "";
    setOpenAddGroupModal();
  };

  const { callApi: postNewGroup } = useApi(
    GRUPOS_GRAFICOS,
    "POST",
    t("indicatorsManagement.addGroup.codes"),
    undefined,
    onSuccessNewGroup
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "80%",
        margin: "auto",
      }}
    >
      <FormLayout width="58%">
        <Typography variant="h2" sx={form_subtitle_styles}>
          {t("indicatorsManagementTexts.step1Charts.graphParameterization")}
        </Typography>
        <label className="cc_step_form_label" htmlFor="chartName">
          <span>*</span>
          {t("indicatorsManagementTexts.step1Charts.graphicTitle")}
        </label>
        <CustomInput
          type="text"
          name="chartName"
          inputRef={chartNameRef}
          value={chartName}
          setValue={setChartName}
          required={true}
          placeholder={t(
            "indicatorsManagementTexts.step1Charts.writeGraphicName"
          )}
          autoFocus={true}
          maxLength={100}
          error={
            message?.text ===
            t("indicatorsManagementTexts.step1Charts.graphicNameExists")
          }
        />
        <FormSelectLayout
          title={
            fileType === "TEMPLATE"
              ? t("indicatorsManagementTexts.step1.file")
              : t("indicatorsManagementTexts.step1.analysis")
          }
          required={true}
        >
          <Select
            reference={fileSelectRef}
            styles={selectStyles(fileSelectedOptions)}
            options={fileOptions(fileColumnSelectOptions)}
            className="cc_select"
            name="Archivo"
            onChange={fileOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={
              fileType === "TEMPLATE"
                ? t("indicatorsManagementTexts.step1.chooseFile")
                : t("indicatorsManagementTexts.step1.chooseAnalysis")
            }
            defaultValue={fileSelectedOptions}
            isClearable
          />
        </FormSelectLayout>
        {chartType !== "BOXPLOT" && (
          <FormSelectLayout
            title={
              chartType === "BARRAS CON DRILLDOWN" ||
              chartType === "LINEAS" ||
              chartType === "SCATTERPLOT"
                ? t("indicatorsManagementTexts.step1Charts.axisYFunction")
                : t("indicatorsManagementTexts.step1Charts.baseColumnFunction")
            }
            required={true}
          >
            <Select
              reference={functionSelectRef}
              styles={selectStyles(functionSelectedOptions)}
              options={functionOptions(functionSelectOptions)}
              className="cc_select"
              name="Función"
              onChange={functionOptionsChangeHandler}
              closeMenuOnSelect
              placeholder={t(
                "indicatorsManagementTexts.step1Charts.chooseFunction"
              )}
              defaultValue={functionSelectedOptions}
              isClearable
            />
          </FormSelectLayout>
        )}
        {chartType === "BARRAS CON DRILLDOWN" ||
        chartType === "LINEAS" ||
        chartType === "SCATTERPLOT" ? (
          functionSelectedOptions?.label !== "DIVISION" ? (
            <FormSelectLayout
              title={t("indicatorsManagementTexts.step1Charts.axisY")}
              required={true}
            >
              <Select
                reference={columnAxisYSelectRef}
                styles={selectStyles(columnAxisYSelectedOptions)}
                options={columnOptions(
                  fileColumnSelectOptions,
                  fileSelectedOptions
                )}
                className="cc_select"
                name="Columna"
                onChange={columnAxisYOptionsChangeHandler}
                placeholder={t(
                  "indicatorsManagementTexts.step1Charts.chooseColumn"
                )}
                defaultValue={columnAxisYSelectedOptions}
                isClearable
                isMulti
                closeMenuOnSelect={false}
              />
            </FormSelectLayout>
          ) : (
            <DivisionSection
              divisionRows={divisionRows}
              setDivisionRows={setDivisionRows}
              fileColumnSelectOptions={fileColumnSelectOptions}
              fileSelectedOptions={fileSelectedOptions}
            />
          )
        ) : (
          <FormSelectLayout
            title={
              chartType === "TREEMAP CON DRILLDOWN"
                ? "Columna base operación"
                : t("indicatorsManagementTexts.step1Charts.baseColumn")
            }
            required={true}
          >
            <Select
              reference={columnBaseSelectRef}
              styles={selectStyles(columnBaseSelectedOptions)}
              options={columnOptions(
                fileColumnSelectOptions,
                fileSelectedOptions
              )}
              className="cc_select"
              name={"Columna base"}
              onChange={columnBaseOptionsChangeHandler}
              closeMenuOnSelect
              placeholder={t(
                "indicatorsManagementTexts.step1Charts.chooseColumn"
              )}
              defaultValue={columnBaseSelectedOptions}
              isClearable
            />
          </FormSelectLayout>
        )}
        {(chartType === "BARRAS CON DRILLDOWN" ||
          chartType === "LINEAS" ||
          chartType === "SCATTERPLOT" ||
          chartType === "BOXPLOT") && (
          <>
            <label className="cc_step_form_label" htmlFor="indicatorName">
              {t("indicatorsManagementTexts.step1Charts.axisYNameOptional")}
            </label>
            <CustomInput
              type="text"
              name="axisYName"
              inputRef={axisYNameRef}
              value={axisYName}
              setValue={setAxisYName}
              required={false}
              placeholder={t(
                "indicatorsManagementTexts.step1Charts.writeAxisYName"
              )}
              autoFocus={false}
              maxLength={30}
            />
          </>
        )}
      </FormLayout>
      <FormLayout width="38%">
        <Box mt="60px">
          {(chartType === "BARRAS CON DRILLDOWN" ||
            chartType === "LINEAS" ||
            chartType === "SCATTERPLOT" ||
            chartType === "BOXPLOT" ||
            chartType === "TREEMAP CON DRILLDOWN") && (
            <>
              <FormSelectLayout
                title={
                  chartType === "TREEMAP CON DRILLDOWN"
                    ? "Columna categórica"
                    : t("indicatorsManagementTexts.step1Charts.axisX")
                }
                required={true}
              >
                <Select
                  reference={columnAxisXSelectRef}
                  styles={selectStyles(columnAxisXSelectedOptions)}
                  options={prepareAxisXOptions()}
                  className="cc_select"
                  name="Columna"
                  onChange={columnAxisXOptionsChangeHandler}
                  closeMenuOnSelect
                  placeholder={t(
                    "indicatorsManagementTexts.step1Charts.chooseOption"
                  )}
                  defaultValue={columnAxisXSelectedOptions}
                  isClearable
                />
              </FormSelectLayout>
              <label className="cc_step_form_label" htmlFor="axisXName">
                {chartType === "TREEMAP CON DRILLDOWN"
                  ? "Nombre columna categórica"
                  : t(
                      "indicatorsManagementTexts.step1Charts.axisXNameOptional"
                    )}
              </label>
              <CustomInput
                type="text"
                name="axisXName"
                inputRef={axisXNameRef}
                value={axisXName}
                setValue={setAxisXName}
                required={false}
                placeholder={t(
                  "indicatorsManagementTexts.step1Charts.writeAxisXName"
                )}
                autoFocus={false}
                maxLength={30}
              />
            </>
          )}
          {(chartType === "BARRAS CON DRILLDOWN" ||
            chartType === "TORTA CON DRILLDOWN" ||
            chartType === "TREEMAP CON DRILLDOWN") &&
            functionSelectedOptions?.value !== "CONTAR.UNICO" &&
            columnSelects.map((column, index) => {
              return (
                <FormSelectLayout
                  title={`${t(
                    "indicatorsManagementTexts.step1Charts.drillDownColumn"
                  )} ${index + 1} (${t(
                    "indicatorsManagementTexts.step1Charts.optional"
                  )})`}
                  required={false}
                  key={index}
                >
                  <Stack direction="row" width="100%">
                    {column}
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {columnSelects.length === index + 1 && (
                        <>
                          <AddButton
                            onClick={() => handleAddDrilldownSelect(index)}
                          />
                          <Delete
                            tooltipPlacement="bottom"
                            onClick={() => handleDeleteSelectValue(index)}
                          />
                        </>
                      )}
                    </Stack>
                  </Stack>
                </FormSelectLayout>
              );
            })}
          {chartType === "SCATTERPLOT" && (
            <FormSelectLayout
              title={t(
                "indicatorsManagementTexts.step1Charts.valuesColumnOptional"
              )}
              required={false}
            >
              <Select
                reference={columnValuesScatterSelectRef}
                styles={optionalSelectStyles(columnValuesScatterSelectedOption)}
                options={columnOptions(
                  fileColumnSelectOptions,
                  fileSelectedOptions
                )}
                className="cc_select"
                name="Columna valores"
                onChange={columnValuesScatterChangeHandler}
                closeMenuOnSelect
                placeholder={t("indicatorsManagementTexts.step1Charts.none")}
                defaultValue={columnValuesScatterSelectedOption}
                isClearable
              />
            </FormSelectLayout>
          )}
          {(chartType === "LINEAS" ||
            chartType === "SCATTERPLOT" ||
            chartType === "BARRAS CON DRILLDOWN") &&
            ((functionSelectedOptions?.label !== "DIVISION" &&
              columnAxisYSelectedOptions?.length === 1) ||
              (functionSelectedOptions?.label === "DIVISION" &&
                divisionRows?.length === 1)) && (
              <FormSelectLayout
                title={t(
                  "indicatorsManagementTexts.step1Charts.serieColumnOptional"
                )}
                required={false}
              >
                <Select
                  reference={columnSeriesSelectRef}
                  styles={optionalSelectStyles(columnSeriesSelectedOptions)}
                  options={columnOptions(
                    fileColumnSelectOptions,
                    fileSelectedOptions
                  )}
                  className="cc_select"
                  name="Columna"
                  onChange={columnSeriesOptionsChangeHandler}
                  closeMenuOnSelect
                  placeholder={t("indicatorsManagementTexts.step1Charts.none")}
                  defaultValue={columnSeriesSelectedOptions}
                  isClearable
                />
              </FormSelectLayout>
            )}
          {chartType === "SCATTERPLOT" && (
            <>
              <FormSelectLayout
                title={t(
                  "indicatorsManagementTexts.step1Charts.auxiliaryLines"
                )}
              >
                <div className="cc_step_form_checkbox cc_checkbox_container">
                  <input
                    type="checkbox"
                    className="cc_checkbox"
                    onChange={() => toggleAuxiliaryLines("linea_cero")}
                    defaultChecked={auxiliaryLines.linea_cero}
                  />
                  <label className="cc_step_form_label" htmlFor="chartActive">
                    {t("indicatorsManagementTexts.step1Charts.line0AxisY")}
                  </label>
                  {auxiliaryLines.linea_cero && (
                    <input
                      style={{ marginLeft: "15px" }}
                      type="color"
                      value={auxiliaryLinesColor.linea_cero}
                      onChange={(e) =>
                        toggleAuxiliaryLinesColor(e, "linea_cero")
                      }
                    />
                  )}
                </div>
                <div className="cc_step_form_checkbox cc_checkbox_container">
                  <input
                    type="checkbox"
                    className="cc_checkbox"
                    onChange={() => toggleAuxiliaryLines("linea_promedio_x")}
                    defaultChecked={auxiliaryLines.linea_promedio_x}
                  />
                  <label className="cc_step_form_label" htmlFor="chartActive">
                    {t(
                      "indicatorsManagementTexts.step1Charts.averageLineAxisX"
                    )}
                  </label>
                  {auxiliaryLines.linea_promedio_x && (
                    <input
                      style={{ marginLeft: "15px" }}
                      type="color"
                      value={auxiliaryLinesColor.linea_promedio_x}
                      onChange={(e) =>
                        toggleAuxiliaryLinesColor(e, "linea_promedio_x")
                      }
                    />
                  )}
                </div>
                <div className="cc_step_form_checkbox cc_checkbox_container">
                  <input
                    type="checkbox"
                    className="cc_checkbox"
                    onChange={() => toggleAuxiliaryLines("linea_promedio_y")}
                    defaultChecked={auxiliaryLines.linea_promedio_y}
                  />
                  <label className="cc_step_form_label" htmlFor="chartActive">
                    {t(
                      "indicatorsManagementTexts.step1Charts.averageLineAxisY"
                    )}
                  </label>
                  {auxiliaryLines.linea_promedio_y && (
                    <input
                      style={{ marginLeft: "15px" }}
                      type="color"
                      value={auxiliaryLinesColor.linea_promedio_y}
                      onChange={(e) =>
                        toggleAuxiliaryLinesColor(e, "linea_promedio_y")
                      }
                    />
                  )}
                </div>
              </FormSelectLayout>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }} />
            </>
          )}
          {fileType === "TEMPLATE" && (
            <FormSelectLayout
              title={t("indicatorsManagementTexts.step1Charts.group")}
              required={true}
            >
              <Stack direction="row" width="100%">
                <Select
                  reference={groupRef}
                  styles={selectStyles(groupSelectedOptions)}
                  options={groupSelectOptions}
                  className="cc_select"
                  name="grupo"
                  id="grupo"
                  onChange={groupChangeHandler}
                  closeMenuOnSelect
                  placeholder={t(
                    "indicatorsManagementTexts.step1Charts.chooseCreateGroup"
                  )}
                  defaultValue={groupSelectedOptions}
                  isClearable
                  isSearchable
                />
                <AddButton onClick={setOpenAddGroupModal} />
              </Stack>
            </FormSelectLayout>
          )}

          <div className="cc_step_form_checkbox cc_checkbox_container">
            <input
              type="checkbox"
              className="cc_checkbox"
              name="chartActive"
              onChange={toggleActiveIndicator}
              defaultChecked={activeIndicator}
            />
            <label className="cc_step_form_label" htmlFor="chartActive">
              {t("indicatorsManagementTexts.step1Charts.activeGraphic")}
            </label>
          </div>
        </Box>
      </FormLayout>
      <AddGroupModal
        openAddGroupModal={openAddGroupModal}
        setOpenAddGroupModal={setOpenAddGroupModal}
        groupSelectOptions={groupSelectOptions}
        postNewGroup={postNewGroup}
        newGroupNameRef={newGroupNameRef}
        newGroupName={newGroupName}
        setNewGroupName={setNewGroupName}
      />
    </Box>
  );
};

export default Step1Charts;
