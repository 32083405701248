import { ANALYSIS_URL, CALCULATE_ANALYSIS } from "../../../api/axios";
import { convertAnalysisArrayToSelectOptions } from "../utils";
import { selectStyles } from "../../criterias/configCriteria/select.styles";
import { Typography } from "@mui/material";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useGetPeriodsProrrat } from "../../periods/api";
import { useRef, useState } from "react";
import Button from "../../buttons/Button";
import CheckmarkSelect from "../../periods/CheckmarkSelect";
import ComponentLayout from "../../layout/ComponentLayout";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import FormSelectLayout from "../../forms/FormSelectLayout";
import Select from "../../forms/Select";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import Stack from "@mui/material/Stack";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import useSingleSelect from "../../../hooks/useSingleSelect";
import AnalysisStatesModal from "../AnalysisStatesModal";

const AnalysisExecution = () => {
  const { t } = useLanguage();
  const analysisSelectRef: any = useRef(null);
  const [openConfirmExecuteModal, toggleConfirmExecuteModal] = useDialog();
  const [selectedPeriods, setSelectedPeriods] = useState<number[]>([]);
  const [
    analysisSelectedOptions,
    setAnalysisSelectedOptions,
    analysisOptionsChangeHandler,
  ] = useSingleSelect(undefined);
  const { isLoading: isLoadingGetAnalysis, data: dataGetAnalysis } =
    useApiQuery(ANALYSIS_URL, true, t("dataModel.getAnalysis.error"));

  const { data: totalPeriodsData } = useGetPeriodsProrrat();
  const [openAnalysisStatesModal, toggleAnalysisStatesModal] = useDialog();

  const onSuccessCalculateAnalysis = () => {
    cleanValues();
  };

  const { isLoading: isLoadingCalculateAnalysis, callApi: calculateAnalysis } =
    useApi(
      CALCULATE_ANALYSIS,
      "POST",
      t("dataModel.postCalculate.codes"),
      undefined,
      onSuccessCalculateAnalysis
    );

  const handleExecute = () => {
    toggleConfirmExecuteModal();
    calculateAnalysis(CALCULATE_ANALYSIS, {
      periodos: selectedPeriods,
      analisis: analysisSelectedOptions?.value,
    });
  };

  const cleanValues = () => {
    setAnalysisSelectedOptions(undefined);
    setSelectedPeriods([]);
    analysisSelectRef.current.clearValue();
  };
  console.log(analysisSelectedOptions, "analysisSelectedOptions");
  

  return (
    <ComponentLayout
      title={t("dataModelText.analysisExecution.analysisExecution")}
      icon={<SmartDisplayIcon />}
    >
      <SimpleBackdrop
        open={isLoadingCalculateAnalysis}
        message={t("dataModelText.analysisExecution.startingCalculation")}
      />
      <Stack
        width="60%"
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
      >
        <Stack width="100%" gap={4} justifyContent={"center"}>
          <FormSelectLayout
            title={t("dataModelText.analysisExecution.analysis")}
            required={true}
          >
            <Select
              reference={analysisSelectRef}
              styles={selectStyles(analysisSelectedOptions)}
              options={
                dataGetAnalysis &&
                convertAnalysisArrayToSelectOptions(dataGetAnalysis)
              }
              onChange={analysisOptionsChangeHandler}
              closeMenuOnSelect
              placeholder={t(
                "dataModelText.analysisExecution.selectAnalysisToRun"
              )}
              isClearable
              isDisabled={isLoadingGetAnalysis}
            />
          </FormSelectLayout>
          {analysisSelectedOptions && (
            <AnalysisStatesModal
              item={{id: analysisSelectedOptions?.value}}
            />
          )}
          {analysisSelectedOptions && totalPeriodsData && totalPeriodsData.length > 0 ? (
            <Stack width="100%">
              <CheckmarkSelect
                dates={totalPeriodsData}
                selectedPeriods={selectedPeriods}
                setSelectedPeriods={setSelectedPeriods}
                width="100%"
              />
            </Stack>
          ) : !totalPeriodsData ? (
            <Typography color={"info"}>
              {t("dataModelText.analysisExecution.noPeriods")}
            </Typography>
          ) : <></>}

          <ConfirmationModal
            open={openConfirmExecuteModal}
            handleClose={toggleConfirmExecuteModal}
            handleAccept={handleExecute}
            title={t("dataModelText.analysisExecution.analysisExecution")}
            message={t(
              "dataModelText.analysisExecution.runSelectedAnalysisSingular"
            )}
          />

          <Stack width="100%" direction="row" justifyContent={"center"}>
            <Button
              title={t("dataModelText.analysisExecution.deleteData")}
              color="grey"
              type="button"
              onClick={cleanValues}
            />
            <Button
              title={t("dataModelText.analysisExecution.runAnalysis")}
              color="blue"
              type="button"
              onClick={toggleConfirmExecuteModal}
              disabled={!analysisSelectedOptions || !selectedPeriods?.length}
            />
          </Stack>
        </Stack>
      </Stack>
    </ComponentLayout>
  );
};

export default AnalysisExecution;
