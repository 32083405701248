import { getPeriodsLabelsFromPeriodsIds } from "../utils";
import { IdOption } from "../../criterias/configCriteria/types";
import { Stack } from "@mui/material";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useRef, useState } from "react";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import CustomButton from "../../buttons/Button";
import Dialog from "../../dialog/Dialog";
import FilterForm from "../../dataModel/downloadReport/FilterForm";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import useSingleSelect from "../../../hooks/useSingleSelect";
import {
  ANALYSIS_URL,
  GET_ANALYSIS_URL,
  HIGHCHARTS_URL,
} from "../../../api/axios";

const ColumnsFilterModal = ({ PERIODS_URL, INCOMING_VALUES }: any) => {
  const { t } = useLanguage();
  const {
    tabValue,
    selectedPeriods,
    setSelectedPeriods,
    setColumnSelectKey,
    setSelectedOptions,
    openFilterModal,
    setOpenFilterModal,
    setFilters,
    viewTabValue,
    analysisPeriods,
    tempFilters,
    setTempFilters,
    setAnalysisPeriods,
    setCategoricsColumns
  } = useViewDashboard();

  const columnFilterSelectedRef: any = useRef(null);

  const [selectedValues, setSelectedValues] = useState<string[] | null>([]);

  const onColumnChange = () => {
    setSelectedValues([]);
    setValuesSelectedOptions(undefined);
  };

  const [
    columnFilterSelectedOptions,
    setColumnFilterSelectedOptions,
    columnFilterOptionsChangeHandler,
  ] = useSingleSelect(() => onColumnChange());

  const onSuccessDataGetAnalysisById = (data : any) => {
    if(data){
      const categoricsColumnsArray = data.agrupacion?.columnas_a_usar.map((column)=> column) 
      setCategoricsColumns(categoricsColumnsArray)
    }
  }

  const { data: dataGetAnalysisById, callApi: getAnalysisById } = useApi(
    undefined,
    "GET",
    t("dataModel.getAnalysis.codes"),
    undefined,
    onSuccessDataGetAnalysisById,
    undefined,
    false
  );

  const columnLabels =
    dataGetAnalysisById &&
    tempFilters &&
    dataGetAnalysisById?.agrupacion?.columnas_a_usar
      ?.filter((option) => {
        return !tempFilters.some(
          (param) => param.column.label === option.label
        );
      })
      ?.map((column) => {
        return {
          value: column.value,
          label: column.label,
        };
      });

  useEffect(() => {
    tabValue &&
      tabValue !== undefined &&
      tabValue !== "SIN ANALISIS" &&
      getAnalysisById(GET_ANALYSIS_URL(tabValue, true));
  }, [tabValue]);

  const [valuesSelectedOptions, setValuesSelectedOptions] =
    useState<IdOption[]>();
  const [incomingValuesOptions, setIncomingValuesOptions] = useState<any[]>([]);
  const [isLoadingIncomingValuesOptions, setIsLoadingIncomingValuesOptions] =
    useState(false);

  const onSuccessIncomingValuesOptionsResponse = (data: any) => {
    setIncomingValuesOptions(data?.valores);
    setIsLoadingIncomingValuesOptions(false);
  };

  const { callApi: postGetIncomingValues } = useApi(
    "",
    "POST",
    undefined,
    undefined,
    onSuccessIncomingValuesOptionsResponse,
    undefined,
    false
  );

  useEffect(() => {
    if (columnFilterSelectedOptions && tabValue) {
      setIsLoadingIncomingValuesOptions(true);
      const filtros = {};
      tempFilters &&
        tempFilters.forEach((item) => {
          const { column, values } = item;
          filtros[column.label] = values.map((value) => value.value);
        });
      let filterBody = {};
      if (selectedPeriods && selectedPeriods.length > 0) {
        filterBody = {
          filtros: {
            ...filtros,
            PERIODO: getPeriodsLabelsFromPeriodsIds(
              selectedPeriods,
              analysisPeriods.data
            ),
          },
          columna: columnFilterSelectedOptions?.label,
        };
      } else {
        filterBody = {
          filtros,
          columna: columnFilterSelectedOptions?.label,
        };
      }
      postGetIncomingValues(INCOMING_VALUES, filterBody);
    } else {
      setIncomingValuesOptions([]);
    }
  }, [columnFilterSelectedOptions, tabValue]);

  const { isLoading: isLoadingPostAnalysisFilter } = useApi(
    HIGHCHARTS_URL,
    "POST",
    t("dataModel.getAnalysisFilter"),
    undefined
  );

  const { isLoading: isLoadingGetAnalysis, data: dataGetAnalysis } =
    useApiQuery(ANALYSIS_URL, true, t("dataModel.getAnalysis.error"));

  const handleToggleFiltersModal = () => {
    setOpenFilterModal((prev) => !prev);
  };

  const handleCancel = () => {
    handleCleanColumnsSelects();
    setOpenFilterModal((prev) => !prev);
  };

  const handleCleanColumnsSelects = () => {
    setColumnSelectKey((prevKey) => prevKey + 1);
    setTempFilters([]);
    setSelectedOptions([]);
  };

  const onSuccessDataGetAnalysisPeriods = (data: any) => {
    if (data) {
      setAnalysisPeriods((prevState) => ({
        ...prevState,
        data: data,
      }));
    }
  };

  const {
    isLoading: isLoadingGetAnalysisPeriods,
    callApi: getAnalysisPeriods,
  } = useApi(
    PERIODS_URL,
    "GET",
    t("dataModel.getGrouper"),
    undefined,
    onSuccessDataGetAnalysisPeriods,
    undefined,
    false
  );

  useEffect(() => {
    if (
      tabValue &&
      tabValue !== "SIN ANALISIS" &&
      PERIODS_URL
    ) {
      getAnalysisPeriods(PERIODS_URL);
    }
  }, [tabValue, PERIODS_URL]);

  useEffect(() => {
    setSelectedOptions([]);
    setSelectedPeriods([]);
    setTempFilters([]);
    setFilters({});
  }, [tabValue, viewTabValue]);

  const postBody = () => {
    const filter = {} as any;
    tempFilters?.forEach((option: any) => {
      filter[option.column.label] = option.values.map((value) => value.value);
    });
    const periodsLabels = getPeriodsLabelsFromPeriodsIds(
      selectedPeriods,
      analysisPeriods.data
    );
    filter["PERIODO"] = periodsLabels ? periodsLabels : undefined;
    setFilters(filter);
    handleToggleFiltersModal();
  };

  return (
    <Dialog
      open={openFilterModal}
      handleClose={handleToggleFiltersModal}
      title={"Filtros"}
      maxWidth={"xl"}
    >
      <SimpleBackdrop
        open={isLoadingPostAnalysisFilter}
        message={t("highCharts.filterLoading")}
      />
      <SimpleBackdrop
        open={isLoadingGetAnalysis || isLoadingGetAnalysisPeriods}
        message={t("highCharts.loading")}
      />
      <Stack
        sx={{ width: "1000px", padding: 3, margin: "0 30px", height: "325px" }}
      >
        <FilterForm
          filters={tempFilters}
          setFilters={setTempFilters}
          setColumnFilterSelectedOptions={setColumnFilterSelectedOptions}
          columnFilterSelectedRef={columnFilterSelectedRef}
          columnLabels={columnLabels}
          columnFilterOptionsChangeHandler={columnFilterOptionsChangeHandler}
          columnFilterSelectedOption={columnFilterSelectedOptions}
          analysisSelectedOption={tabValue}
          selectedValues={selectedValues}
          incomingValuesOptions={incomingValuesOptions}
          setValuesSelectedOptions={setValuesSelectedOptions}
          setSelectedValues={setSelectedValues}
          isLoadingIncomingValuesOptions={isLoadingIncomingValuesOptions}
          valuesSelectedOptions={valuesSelectedOptions}
          selectOneOption={false}
        />
      </Stack>
      <Stack
        sx={{
          width: "100%",
          justifyContent: "center",
          flexDirection: "row",
          mb: "-10px",
        }}
      >
        <CustomButton
          title="Cerrar"
          color="grey"
          type="button"
          onClick={() => handleCancel()}
        />
        <CustomButton
          title="Limpiar"
          color="blue-greeny"
          type="button"
          onClick={() => {
            handleCleanColumnsSelects();
          }}
        />
        <CustomButton
          title="Filtrar"
          color="blue"
          type="button"
          disabled={tabValue && tabValue !== "SIN ANALISIS" ? false : true}
          onClick={() => postBody()}
        />
      </Stack>
    </Dialog>
  );
};

export default ColumnsFilterModal;
