import { mergeAndDeleteRepeatedColumns } from "../../utils";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { ITEM_TABLA_URL, TABLAS_URL } from "../../../../api/axios";
import Button from "../../../buttons/Button";
import FormLayout from "../../../forms/FormLayout";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";

const OrderRowsElement = ({
  dataGetTable,
  handleToggleModal,
  element,
  getTable,
  setElementToEdit,
}) => {
  const { t } = useLanguage();
  const [
    columnsSelectedOptions,
    setColumnsSelectedOptions,
    columnsChangeHandler,
  ] = useMultiSelect(element?.columnas ? element.columnas : undefined);

  const [orderSelectedOption, setOrderSelectedOption, orderChangeHandler] =
    useSingleSelect(
      undefined,
      element?.ascendente
        ? { value: "ascendente", label: "Ascendente" }
        : { value: "descendente", label: "Descendente" }
    );

  const onSuccessPost = () => {
    getTable(`${TABLAS_URL}${dataGetTable.id}`);
    setElementToEdit(undefined);
    handleToggleModal();
  };

  const { isLoading: isLoadingPutItem, callApi: putItem } = useApi(
    ITEM_TABLA_URL(dataGetTable?.id,element?.id), //?tipo=ANALISIS
    "PUT",
    t("indicatorsManagement.addIndicator.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const { isLoading: isLoadingPostItem, callApi: postItem } = useApi(
    `${TABLAS_URL}${dataGetTable.id}/item`, //?tipo=ANALISIS
    "POST",
    t("indicatorsManagement.addIndicator.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const handleApply = () => {
    const completedInfo = {
      tipo: "ORDENAR_FILAS",
      columnas: columnsSelectedOptions?.map((col) => col.value),
      ascendente: orderSelectedOption?.value === "ascendente" ? true : false,
    };
    if(element && element.id) {
      putItem(undefined, completedInfo)
    } else {
      postItem(undefined, completedInfo);
    }
  };

  const isValidApply = () => {
    return (
      columnsSelectedOptions &&
      columnsSelectedOptions.length > 0 &&
      orderSelectedOption
    );
  };

  const handleCancel = () => {
    setElementToEdit(undefined);
    handleToggleModal()
  }

  return (
    <>
    <SimpleBackdrop
        open={isLoadingPostItem || isLoadingPutItem}
        message={t("general.loading")}
      />
      <FormLayout width="100%" minHeight="300px">
        <Stack
          direction="row"
          width={"100%"}
          justifyContent={"space-between"}
          gap={2}
        >
          <Stack width={"100%"}>
            <FormSelectLayout
              title={t("Columna base para ordenar")}
              required={true}
              margin={"0px"}
            >
              <Select
                styles={selectStyles(columnsSelectedOptions)}
                options={mergeAndDeleteRepeatedColumns(dataGetTable)}
                className="cc_select"
                onChange={columnsChangeHandler}
                closeMenuOnSelect={false}
                placeholder={t("general.chooseColumn")}
                defaultValue={columnsSelectedOptions}
                isClearable
                isMulti
              />
            </FormSelectLayout>
          </Stack>
          <Stack width={"100%"}>
            <FormSelectLayout
              title={t("Ascendente/Descendente")}
              required={true}
              margin={"0px"}
            >
              <Select
                styles={selectStyles(orderSelectedOption)}
                options={[
                  { value: "ascendente", label: "Ascendente" },
                  { value: "descendente", label: "Descendente" },
                ]}
                className="cc_select"
                onChange={orderChangeHandler}
                closeMenuOnSelect
                placeholder={t("general.selectOption")}
                defaultValue={orderSelectedOption}
                isClearable
              />
            </FormSelectLayout>
          </Stack>
        </Stack>
      </FormLayout>

      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={handleCancel}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={!isValidApply()}
        />
      </Stack>
    </>
  );
};

export default OrderRowsElement;
