import Grid from "@mui/material/Grid/Grid";
import Stack from "@mui/material/Stack";
import { Item } from "../../../basicGrid/BasicGrid";
import Typography from "@mui/material/Typography";
import { text_styles } from "../../../../styles/app-styles";
import Select from "../../../forms/Select";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { expenseTargetOptions } from "../../../criterias/configCriteria/selectOptions";
import DummyGrouperGroup from "./DummyGrouperGroup";
import useLanguage from "../../../../context/LanguageProvider";

interface IGntDummyContent {
  columnFinalTargetSelectedOption: any;
  columnOptionsChangeHandler: any;
  columnSelectRef: any;
  dataGetCriteriaTarget: any;
  dummySelectedValues: any;
  dataGetFileColumn: any;
  setDummySetectedValues: any;
}

const GntDummyContent = ({
  columnFinalTargetSelectedOption,
  columnOptionsChangeHandler,
  columnSelectRef,
  dataGetCriteriaTarget,
  dummySelectedValues,
  dataGetFileColumn,
  setDummySetectedValues,
}: IGntDummyContent) => {
  const { t } = useLanguage();

  const onChangeDummyOption = (
    groupKey,
    dummyData,
    columnSelectedOption,
    stage?
  ) => {
    const actualValues = dummySelectedValues;
    // Caso en el que se borra una columna de venta de columna valida (ya que no hay stage)
    if (!columnSelectedOption && !stage) {
      const index = actualValues.findIndex(
        (item) => item.id_agrupadores === groupKey
      );
      const index2 = actualValues[index].columnas_valida.findIndex(
        (item) => item.columna_gasto.value === dummyData.columna_gasto.value
      );
      actualValues[index].columnas_valida[index2].columna_venta = undefined;
      setDummySetectedValues([...actualValues]);
    }
    // Caso en el que se borra una columna de venta de destino anterior (ya que si hay stage)
    if (!columnSelectedOption && stage) {
      const index = actualValues.findIndex(
        (item) => item.id_agrupadores === groupKey
      );
      const index2 = actualValues[index].destinos_anteriores.findIndex(
        (item) => item.receptor.value === dummyData.value
      );
      actualValues[index].destinos_anteriores[index2].columna_venta = undefined;
      setDummySetectedValues([...actualValues]);
    }
    // Caso en el que se selecciona una columna de venta de columna valida (ya que no hay stage)
    if (columnSelectedOption && !stage) {
      const index = actualValues.findIndex(
        (item) => item.id_agrupadores === groupKey
      );
      const index2 = actualValues[index].columnas_valida.findIndex(
        (item) => item.columna_gasto.value === dummyData.columna_gasto.value
      );
      actualValues[index].columnas_valida[index2].columna_venta =
        columnSelectedOption;
      setDummySetectedValues([...actualValues]);
    }
    // Caso en el que se selecciona una columna de venta de destino anterior (ya que si hay stage)
    if (columnSelectedOption && stage) {
      const index = actualValues.findIndex(
        (item) => item.id_agrupadores === groupKey
      );
      const index2 = actualValues[index].destinos_anteriores.findIndex(
        (item) => item.etapa === stage
      );
      actualValues[index].destinos_anteriores[index2].columna_venta =
        columnSelectedOption;
      setDummySetectedValues([...actualValues]);
    }
  };

  const hasDummySelectedValues =
    dummySelectedValues && dummySelectedValues.length !== 0;

  return (
    <Stack width={"500px"} direction="row">
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Item>
            <Typography sx={text_styles}>
              {t(
                "modelParameterizationTexts.criteriaModelContent.finalRecipientOfExpense"
              )}
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Select
            reference={columnSelectRef}
            styles={selectStyles(columnFinalTargetSelectedOption)}
            options={expenseTargetOptions(dataGetCriteriaTarget)}
            className="cc_select"
            name="Columna"
            onChange={columnOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t(
              "modelParameterizationTexts.criteriaModelContent.chooseReceiver"
            )}
            isClearable
          />
        </Grid>
        {hasDummySelectedValues &&
          dummySelectedValues.map((data: any, index: number) => {
            return (
              <DummyGrouperGroup
                key={index}
                groupKey={index}
                fileColumnSelectOptions={dataGetFileColumn}
                dummyData={data}
                onChangeDummyOption={onChangeDummyOption}
              />
            );
          })}
      </Grid>
    </Stack>
  );
};

export default GntDummyContent;
