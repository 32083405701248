import { Stack } from "@mui/material";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";

export const getCurrentTimestamp = () => {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  //const seconds = now.getSeconds().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const convertMarkdownToHTML = (markdown) => {
  let html = markdown;

  // Convertir títulos
  html = html.replace(/^###### (.*$)/gim, "<h6>$1</h6>");
  html = html.replace(/^##### (.*$)/gim, "<h5>$1</h5>");
  html = html.replace(/^#### (.*$)/gim, "<h4>$1</h4>");
  html = html.replace(/^### (.*$)/gim, "<h3>$1</h3>");
  html = html.replace(/^## (.*$)/gim, "<h2>$1</h2>");
  html = html.replace(/^# (.*$)/gim, "<h1>$1</h1>");

  // Convertir texto en negrita y cursiva
  html = html.replace(/\*\*\*(.*?)\*\*\*/gim, "<b><i>$1</i></b>");
  html = html.replace(/\*\*(.*?)\*\*/gim, "<b>$1</b>");
  html = html.replace(/\*(.*?)\*/gim, "<i>$1</i>");

  // Convertir enlaces
  html = html.replace(
    /\[(.*?)\]\((.*?)\)/gim,
    '<a href="$2" target="_blank">$1</a>'
  );

  // Convertir saltos de línea dobles en párrafos
  html = html.replace(/\n\n/gim, "</p><p>");

  // Convertir saltos de línea simples en `<br/>`
  html = html.replace(/\n/gim, "<br/>");

  // Encapsular todo en un párrafo
  html = `<p>${html}</p>`;

  return html.trim();
};

export const transformTableDataPhiBot = (table: any) => {
  const tableColumns = table && table.length > 0 ? Object.keys(table[0]) : [];

  const arrayColumns = tableColumns?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    maxHeight: 100,
    flex: 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {params.field}
      </strong>
    ),
    renderCell: (params: any) => (
      <Stack direction="row" alignItems="center">
        <p>{params.value}</p>
      </Stack>
    ),
  }));

  const arrayRows =
    table && table.length > 0
      ? table?.map((row: any, index: any) => {
          const obj: { [key: string]: any } = { id: index + 1 };
          for (const key in row) {
            obj[key] = row[key];
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any, index: number) => ({
    ...row,
    id: index,
  }));

  return { columns, rows };
};

const MIN_WIDTH = 300;
const MAX_WIDTH = 500;

export const handleMouseDownSideBarChatbot = (
  e: React.MouseEvent,
  sidebarWidth: number,
  setSidebarWidth: (numbre: number) => void
) => {
  e.preventDefault();
  const startX = e.clientX;
  const startWidth = sidebarWidth;

  const handleMouseMove = (moveEvent: MouseEvent) => {
    const newWidth = Math.min(
      MAX_WIDTH,
      Math.max(MIN_WIDTH, startWidth + (moveEvent.clientX - startX))
    );
    setSidebarWidth(newWidth);
  };

  const handleMouseUp = () => {
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  window.addEventListener("mousemove", handleMouseMove);
  window.addEventListener("mouseup", handleMouseUp);
};

export const getHistoryItemStyles = (isSelected) => ({
  width: "100%",
  borderRadius: "8px",
  padding: "10px 16px",
  position: "relative",
  userSelect: "none",
  cursor: "pointer",
  backgroundColor: isSelected ? "var(--bg-disabled)" : "",
  "&:hover": {
    backgroundColor: "var(--bg-disabled)",
    ".icon-button": { opacity: 1, display: "flex" },
  },
});

export const getIconButtonStyles = (isSelected) => ({
  transition: "opacity 0.3s ease, box-shadow 0.3s ease",
  position: "absolute",
  right: 10,
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,
  color: "var(--text-main)",
  display: isSelected ? "flex" : "none",
  backgroundColor: "var(--bg-disabled)",
  "&:hover": {
    backgroundColor: "var(--bg-disabled)",
    color: "white",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  },
});

export const getChatLabelStyles = () => ({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  flex: 1,
  color: "var(--text-main)",
  fontWeight: "600",
});

export const getIconStyles = () => ({
  backgroundColor: "var(--bg-disabled)",
  borderRadius: "50%",
});

export const getEmptyHistoryStyles = () => ({
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
});

export const getEmptyHistoryMessageStyles = () => ({
  color: "var(--text-main)",
  fontWeight: "600",
  mb: 1,
  userSelect: "none",
  textAlign: "center",
});

export const processDataChart = (data) => {
  //probamos si va a tirar error, si tira error devolvemos un mensaje de error
  if (data) {
    try {
      const dataChart = data
        ?.replace(/n\n/g, "")
        .trim()
        .replace(/\/\/.*$/gm, "")
        .replace(
          // Este es el reemplazo para las llaves del `pointFormat`
          /pointFormat\s*:\s*"(.*?{.*?}.*?)"/g,
          () => {
            // acá evitamos un error - no supe solucionalo
            // const escaped = p1.replace(/{/g, "\\{").replace(/}/g, "\\}");
            // return `"pointFormat": ${escaped}`;
            return "";
          }
        )
        .replace(
          // eslint-disable-next-line no-useless-escape
          /"value"\s*:\s*([\d.]+\s*[\+\-\*/]\s*[\d.\s+\-\*/]+)/g,
          (match, p1) => `"value": ${eval(p1)}`
        );
      const parsedData = JSON.parse(dataChart);
      return {
        status: "Success",
        parsedData: parsedData,
      };
    } catch (error: any) {
      console.error("Error al procesar o parsear el JSON:", error.message);
      return {
        status: "Error",
      };
    }
  }
};
