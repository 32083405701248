import { FUNCTION_LABELS } from "../../../constants";
import { GET_MEASURE_URL, MEASURE_URL } from "../../../../../api/axios";
import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "../../../../buttons/Button";
import ConfirmationModal from "../../../../dialog/ConfirmationModal";
import CustomStepper from "../../../../Stepper/CustomStepper";
import dataTabsOtherFont from "./dataTabsOtherFont.json";
import OtherFontStep1 from "./OtherFontStep1";
import SalesMeasure from "./SalesMeasure";
import SimpleBackdrop from "../../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useApi from "../../../../../hooks/useApi";
import useDialog from "../../../../../hooks/useDialog";
import useLanguage from "../../../../../context/LanguageProvider";
import useMultiSelect from "../../../../../hooks/useMultiSelect";
import useSingleSelect from "../../../../../hooks/useSingleSelect";
import {
  step_container_styles,
  step_title_styles,
} from "../../../../../styles/app-styles";
import {
  formatColumns2,
  formatObject,
  formatRow,
  validateGroupByRow,
} from "../../../utils";
interface OtherMeasureFontProps {
  tabValue: string;
  handleFinish: () => void;
  functionSelectedOptions: any;
  setFunctionSelectedOptions: any;
  functionOptionsChangeHandler: any;
  columnSelectedOptionsNumerator: any;
  setColumnSelectedOptionsNumerator: any;
  columnOptionsChangeHandlerNumerator: any;
  columnSelectedOptionsDenominator: any;
  setColumnSelectedOptionsDenominator: any;
  columnOptionsChangeHandlerDenominator: any;
  newActionName: string;
  setNewActionName: any;
  dataGetSales: any;
  configMode: "CREATE" | "EDIT" | "INFO" | "COPY" | "";
  dataGetMeasure?: any;
  columnSelectRef: any;
  columnSelectNumeratorRef: any;
  setConfigMode: (value: "CREATE" | "EDIT" | "INFO" | "COPY" | "") => void;
  dataGetExpenses: any;
  dataGetComposed: any;
}

const OtherMeasureFont = ({
  tabValue,
  handleFinish,
  functionSelectedOptions,
  setFunctionSelectedOptions,
  functionOptionsChangeHandler,
  columnSelectedOptionsNumerator,
  setColumnSelectedOptionsNumerator,
  columnOptionsChangeHandlerNumerator,
  columnSelectedOptionsDenominator,
  setColumnSelectedOptionsDenominator,
  columnOptionsChangeHandlerDenominator,
  newActionName,
  setNewActionName,
  configMode,
  dataGetMeasure,
  columnSelectRef,
  columnSelectNumeratorRef,
  dataGetSales,
  setConfigMode,
  dataGetExpenses,
  dataGetComposed,
}: OtherMeasureFontProps) => {
  const { t } = useLanguage();
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [activeStep, setActiveStep] = useState(0);
  const transformAgregationData = () => {
    return dataGetMeasure.columnas_agregacion?.map((row: any) => {
      const operacion = {
        value: row.operacion,
        label: FUNCTION_LABELS[row.operacion],
      };

      return formatRow(formatObject(row.columna), operacion);
    });
  };
  const [groupByRows, setGroupByRows] = useState<any[]>(
    (configMode === "EDIT" || configMode === "COPY") && dataGetMeasure
      ? transformAgregationData()
      : []
  );
  const fileSelectRef: any = useRef(null);
  const functionSelectRef: any = useRef(null);
  const columnsActualBaseSelectRef: any = useRef(null);
  const columnsNewBaseSelectRef: any = useRef(null);
  const [message, setMessage] = useState("");
  const [measureInCurrency, setMeasureInCurrency] = useState<boolean>(
    dataGetMeasure ? dataGetMeasure.medida_en_moneda : false
  );
  
  /*************************** STEPPER *********************************/

  const handleNext = () => {
    activeStep === 1
      ? toggleConfirmationModal()
      : setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /************************* Step 1 - SELECTS **************************/

  const onColumnChange = () => {
    setFunctionSelectedOption(undefined);
    functionSelectRef.current?.clearValue();
  };

  const [fileSelectedOption, setFileSelectedOption, fileChangeHandler] =
    useSingleSelect(
      () => onFileColumnChange(),
      (configMode === "EDIT" || configMode === "COPY") && dataGetMeasure
        ? formatObject(dataGetMeasure.archivo)
        : undefined
    );

  const [
    columnsActualBaseSelectedOption,
    setColumnsActualBaseSelectedOption,
    columnsActualBaseChangeHandler,
  ] = useMultiSelect(
    (configMode === "EDIT" || configMode === "COPY") && dataGetMeasure
      ? formatColumns2(dataGetMeasure.columnas_base_actual)
      : undefined
  );

  const [
    columnsNewBaseSelectedOption,
    setColumnsNewBaseSelectedOption,
    columnsNewBaseChangeHandler,
  ] = useMultiSelect(
    (configMode === "EDIT" || configMode === "COPY") && dataGetMeasure
      ? formatColumns2(dataGetMeasure.columnas_base_ventas)
      : undefined
  );

  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionsChangeHandler,
  ] = useSingleSelect(() => onColumnChange());

  const onFileColumnChange = () => {
    setColumnsActualBaseSelectedOption(undefined);
    columnsActualBaseSelectRef.current.clearValue();
    setGroupByRows([]);
  };

  const [
    functionSelectedOption,
    setFunctionSelectedOption,
    functionStep1OptionsChangeHandler,
  ] = useSingleSelect();

  /*********************** Step 1 - FUNCIONES **********************/

  const handleAddGroupByRow = () => {
    if (
      validateGroupByRow(
        columnSelectedOption,
        functionSelectedOption,
        groupByRows,
        setMessage
      )
    ) {
      const newRow = formatRow(columnSelectedOption, functionSelectedOption);
      const newGroupByRows = groupByRows;
      newGroupByRows.push(newRow);
      setGroupByRows(newGroupByRows);
      setMessage("");
      cleanSelects();
    }
  };

  const handleDeleteAllRows = () => {
    setGroupByRows([]);
    setMessage("");
    cleanSelects();
  };

  /*********************** Step 1 y 2 - VALIDACIONES ********************/

  const validStep1 =
    !!fileSelectedOption &&
    !!columnsActualBaseSelectedOption &&
    !!columnsNewBaseSelectedOption &&
    columnsActualBaseSelectedOption.length ===
      columnsNewBaseSelectedOption.length &&
    groupByRows.length > 0;

  const validStep2 =
    !!functionSelectedOptions &&
    !!columnSelectedOptionsNumerator &&
    (functionSelectedOptions?.value === "DIVIDIR" ||
    functionSelectedOptions?.value === "SUMAR" ||
    functionSelectedOptions?.value === "SUMA-PRODUCTO"
      ? columnSelectedOptionsNumerator?.length > 0
      : true);
  !!newActionName &&
    (functionSelectedOptions?.value === "DIVIDIR"
      ? columnSelectedOptionsDenominator?.length > 0
      : true);

  /*********************** Step 1 - POST ***************************/

  const closeAndClean = (data: any, newCreation?: boolean) => {
    setFileSelectedOption(undefined);
    setColumnsActualBaseSelectedOption(undefined);
    setColumnsNewBaseSelectedOption(undefined);
    setFunctionSelectedOptions(undefined);
    setColumnSelectedOptionsNumerator(undefined);
    setColumnSelectedOptionsDenominator(undefined);
    setMeasureInCurrency(false);
    setNewActionName("");
    columnSelectRef.current?.clearValue();
    columnSelectNumeratorRef.current?.clearValue();
    setGroupByRows([]);
    setMessage("");
    cleanSelects();
    setActiveStep(0);
    if (newCreation === true) {
      setConfigMode("CREATE");
    } else {
      handleFinish();
    }
  };

  const {
    isLoading: isLoadingPostOtherFontMeasure,
    callApi: postOtherFontMeasure,
  } = useApi(
    MEASURE_URL,
    "POST",
    t("dataModel.postNewMeasure.codes"),
    undefined,
    closeAndClean,
    toggleConfirmationModal
  );

  const {
    isLoading: isLoadingPutOtherFontMeasure,
    callApi: putOtherFontMeasure,
  } = useApi(
    GET_MEASURE_URL(dataGetMeasure?.id),
    "PUT",
    t("dataModel.putMeasure.codes"),
    undefined,
    closeAndClean,
    toggleConfirmationModal
  );

  const handleFinishPost = (newCreation?: boolean) => {
    toggleConfirmationModal();
    configMode === "EDIT"
      ? putOtherFontMeasure(
          GET_MEASURE_URL(dataGetMeasure?.id),
          prepareInfo(),
          newCreation
        )
      : postOtherFontMeasure(MEASURE_URL, prepareInfo(), newCreation);
  };

  const prepareInfo = () => {
    return {
      nombre: newActionName,
      tipo: "VENTAS",
      operacion: functionSelectedOptions?.value,
      columnas:
        functionSelectedOptions?.value === "DIVIDIR" ||
        functionSelectedOptions?.value === "SUMAR" ||
        functionSelectedOptions?.value === "SUMA-PRODUCTO"
          ? columnSelectedOptionsNumerator?.map((column: any) => column.value)
          : [columnSelectedOptionsNumerator.value],
      columnas_denominador: columnSelectedOptionsDenominator?.map(
        (column: any) => column.value
      ),
      archivo: fileSelectedOption?.value,
      columnas_base_actual: columnsActualBaseSelectedOption?.map(
        (column: any) => column.value
      ),

      columnas_base_ventas: columnsNewBaseSelectedOption?.map(
        (column: any) => column.value
      ),
      columnas_agregacion: groupByRows.map((row: any) => {
        return {
          columna: row.column.value,
          operacion: row.function.value,
        };
      }),
      medida_en_moneda: functionSelectedOptions?.value !== "CONTAR" &&
      functionSelectedOptions?.value !== "CONTAR.UNICO" ? measureInCurrency : false
    };
  };

  /*************************** LIMPIEZA Y CIERRE ************************/

  const cleanSelects = () => {
    setColumnSelectedOption(undefined);
    setFunctionSelectedOption(undefined);
    columnSelectRef.current?.clearValue();
    functionSelectRef.current?.clearValue();
    setMeasureInCurrency(false);
  };

  const columns: any = [];

  groupByRows &&
    groupByRows.map((info) => {
      return columns.push(info.column);
    });

  columnsActualBaseSelectedOption &&
    columnsActualBaseSelectedOption.map((column) => {
      return columns.push(column);
    });

  const seenValues = {};

  const uniqueValuesColumns = columns.filter((column) => {
    if (!seenValues[column.value]) {
      seenValues[column.value] = true;
      return true;
    }
    return false;
  });

  return (
    <>
      <CustomStepper activeStep={activeStep} data={dataTabsOtherFont} />
      <SimpleBackdrop
        open={isLoadingPostOtherFontMeasure || isLoadingPutOtherFontMeasure}
        message={
          isLoadingPostOtherFontMeasure
            ? t("dataModel.postNewMeasure.loading")
            : t("dataModel.putMeasure.loading")
        }
      />

      <Box sx={step_title_styles}>{dataTabsOtherFont.steps[activeStep]}</Box>
      <Stack
        sx={{ ...step_container_styles, overflowY: "none", height: "auto" }}
      >
        {activeStep === 0 && (
          <OtherFontStep1
            groupByRows={groupByRows}
            setGroupByRows={setGroupByRows}
            fileSelectRef={fileSelectRef}
            fileSelectedOption={fileSelectedOption}
            fileChangeHandler={fileChangeHandler}
            columnsActualBaseSelectRef={columnsActualBaseSelectRef}
            columnsActualBaseSelectedOption={columnsActualBaseSelectedOption}
            columnsActualBaseChangeHandler={columnsActualBaseChangeHandler}
            columnsNewBaseSelectRef={columnsNewBaseSelectRef}
            columnsNewBaseSelectedOption={columnsNewBaseSelectedOption}
            columnsNewBaseChangeHandler={columnsNewBaseChangeHandler}
            columnSelectRef={columnSelectRef}
            columnSelectedOption={columnSelectedOption}
            setColumnSelectedOption={setColumnSelectedOption}
            columnOptionsChangeHandler={columnOptionsChangeHandler}
            functionSelectRef={functionSelectRef}
            functionSelectedOption={functionSelectedOption}
            functionOptionsChangeHandler={functionStep1OptionsChangeHandler}
            message={message}
            setMessage={setMessage}
            handleAddGroupByRow={handleAddGroupByRow}
            handleDeleteAllRows={handleDeleteAllRows}
          />
        )}
        {activeStep === 1 && (
          <SalesMeasure
            tabValue={tabValue}
            handleFinish={handleFinish}
            fileSelectedOption={fileSelectedOption}
            functionSelectedOptions={functionSelectedOptions}
            setFunctionSelectedOptions={setFunctionSelectedOptions}
            functionOptionsChangeHandler={functionOptionsChangeHandler}
            columnSelectedOptionsNumerator={columnSelectedOptionsNumerator}
            setColumnSelectedOptionsNumerator={
              setColumnSelectedOptionsNumerator
            }
            columnOptionsChangeHandlerNumerator={
              columnOptionsChangeHandlerNumerator
            }
            columnSelectedOptionsDenominator={columnSelectedOptionsDenominator}
            setColumnSelectedOptionsDenominator={
              setColumnSelectedOptionsDenominator
            }
            columnOptionsChangeHandlerDenominator={
              columnOptionsChangeHandlerDenominator
            }
            newActionName={newActionName}
            setNewActionName={setNewActionName}
            configMode={configMode}
            columnSelectRef={columnSelectRef}
            columnSelectNumeratorRef={columnSelectNumeratorRef}
            dataGetSales={dataGetSales}
            uniqueValuesColumns={uniqueValuesColumns}
            setConfigMode={setConfigMode}
            dataGetExpenses={dataGetExpenses}
            dataGetComposed={dataGetComposed}
            groupByRows={groupByRows}
            measureInCurrency={measureInCurrency}
            setMeasureInCurrency={setMeasureInCurrency}
            dataGetMeasure={dataGetMeasure}
          />
        )}
        <Stack
          sx={{
            flexDirection: "row",
          }}
        >
          <ConfirmationModal
            open={openConfirmationModal}
            handleClose={toggleConfirmationModal}
            handleAccept={() => handleFinishPost(false)}
            message={t(
              "dataModelText.measureManagement.configMeasure.otherMeasureFont.createNewMeasure"
            )}
            title={t(
              "dataModelText.measureManagement.configMeasure.otherMeasureFont.newMeasure"
            )}
          >
            <Button
              title={"Aceptar y crear otra medida"}
              color="outlined"
              type="button"
              onClick={() => handleFinishPost(true)}
              width={320}
              margin={false}
            />
          </ConfirmationModal>
          <Button
            title={t(
              "dataModelText.measureManagement.configMeasure.otherMeasureFont.previous"
            )}
            color="blue-greeny"
            type="button"
            onClick={handleBack}
            disabled={activeStep === 0}
          />
          <Button
            title={
              activeStep === 1
                ? t(
                    "dataModelText.measureManagement.configMeasure.otherMeasureFont.finish"
                  )
                : t(
                    "dataModelText.measureManagement.configMeasure.otherMeasureFont.next"
                  )
            }
            color="blue"
            type="button"
            onClick={handleNext}
            disabled={
              activeStep === 0
                ? !validStep1
                : !validStep2 || newActionName.length < 1
            }
          />
        </Stack>
      </Stack>
    </>
  );
};

export default OtherMeasureFont;
