import { getTableTexts } from "../tablesTools/utils";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import CustomNoRowsOverlay from "../tablesTools/NoRows";
import SaveIcon from "@mui/icons-material/Save";
import Tooltip from "@mui/material/Tooltip";
import useLanguage from "../../context/LanguageProvider";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  useGridApiRef,
  GridSelectionModel,
  GridEventListener,
} from "@mui/x-data-grid-pro";
interface Props {
  columns: any;
  rows: any[];
  rowsPerPageOptions: number[];
  rowHeight: number;
  tableHeight: number | string;
  hideFooter?: boolean;
  toolbar?: boolean;
  columnsButton?: boolean;
  filterButton?: boolean;
  densitySelector?: boolean;
  exportButton?: boolean;
  setSelectedRows: (prev: any) => void;
  setColumnsPropsArray?: (prev: any) => void;
  saveViewHandler?: () => void;
  isPreproration?: boolean;
  saveViewButton?: boolean;
  columnVisibility?: any;
  setNewVisibility?: any;
  checkboxSelection?: boolean;
  setTotalFilteredAmount?: (prev: any) => void;
  selectedRows?: any;
  headerHeight?: number;
  handleCellClick?: any
}

const VirtualizatedTable = ({
  columns,
  rows,
  rowsPerPageOptions,
  rowHeight,
  tableHeight,
  hideFooter,
  toolbar,
  columnsButton,
  filterButton,
  densitySelector,
  exportButton,
  setSelectedRows,
  setColumnsPropsArray,
  saveViewHandler,
  isPreproration,
  saveViewButton,
  columnVisibility,
  setNewVisibility,
  checkboxSelection,
  setTotalFilteredAmount,
  selectedRows,
  headerHeight,
  handleCellClick
}: Props) => {
  const { t } = useLanguage();

  function CustomToolbar() {
    if (toolbar) {
      return (
        <GridToolbarContainer
          sx={{
            color: "#F90D4A",
            display: toolbar ? "flex" : "none",
            flexDirection: "row",
            alignItems: "center",
            height: "40px",
            width: "100%",
          }}
        >
          {/* @ts-ignore */}
          <GridToolbarColumnsButton
            sx={{
              display: columnsButton ? "flex" : "none",
              color: "var(--button-cancel)",
              width: "90px",
              background: "transparent",
              margin: "2px 5px 2px 18px",
              fontSize: "14px",
              fontWeight: "bold",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#F90D4A",
              },
            }}
            placeholder=""
          ></GridToolbarColumnsButton>
          {/* @ts-ignore */}
          <GridToolbarFilterButton
            sx={{
              display: filterButton ? "flex" : "none",
              color: "var(--button-cancel)",
              width: "90px",
              background: "transparent",
              margin: "2px 5px 2px 15px",
              fontSize: "14px",
              fontWeight: "bold",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#F90D4A",
              },
              "& .MuiFormControl.root": {
                backgroundColor: "yellow",
                color: "white",
              },
            }}
            placeholder=""
          />
          {/* @ts-ignore */}
          <GridToolbarDensitySelector
            sx={{
              display: densitySelector ? "flex" : "none",
              color: "var(--button-cancel)",
              width: "90px",
              background: "transparent",
              margin: "2px 5px 2px 15px",
              fontSize: "14px",
              fontWeight: "bold",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#F90D4A",
              },
            }}
            placeholder=""
          />
          <GridToolbarExport
            sx={{
              display: exportButton ? "flex" : "none",
              color: "var(--button-cancel)",
              width: "90px",
              background: "transparent",
              margin: "2px 5px 2px 15px",
              fontSize: "14px",
              fontWeight: "bold",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                color: "#F90D4A",
              },
            }}
          />
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              margin: "2px 5px 2px 15px",
            }}
            onClick={saveViewHandler}
          >
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={t("taskManager.saveViewButtonTooltip")}
              sx={{ verticalAlign: "center", fontSize: "16px" }}
            >
              <Button
                variant="text"
                sx={{
                  display: saveViewButton ? "flex" : "none",
                  color: "var(--button-cancel)",
                  background: "transparent",
                  fontSize: "14px",
                  fontWeight: "bold",
                  alignItems: "center",
                  justifyContent: "center",
                  ":hover": {
                    color: "#F90D4A",
                  },
                }}
                startIcon={<SaveIcon />}
              >
                {t("virtualizedTable.saveViewButton")}
              </Button>
            </Tooltip>
          </div>
        </GridToolbarContainer>
      );
    } else {
      return <></>;
    }
  }

  const apiRef = useGridApiRef();

  useEffect(() => {
    apiRef.current.subscribeEvent(
      "selectionChange",
      (params: GridSelectionModel) => {
        setSelectedRows(params);
      }
    );

    !isPreproration &&
      setColumnsPropsArray &&
      apiRef.current.subscribeEvent(
        "columnsChange",
        (params: string[], event, details) => {
          const columnsProps = details.api.state.columns.lookup;
          const propsArray = Object.keys(columnsProps).map((key: any) => {
            return {
              column: key,
              width: columnsProps[key].width,
              hide: columnsProps[key].hide,
            };
          });
          setColumnsPropsArray(propsArray);
        }
      );

    !isPreproration &&
      apiRef.current.subscribeEvent("stateChange", handleStateEvent);
  }, [apiRef]);

  const handleStateEvent: GridEventListener<"stateChange"> = (params) => {
    const filteredRowsMap: any = {};
    for (const key in params.filter.filteredRowsLookup) {
      if (params.filter.filteredRowsLookup[key]) {
        filteredRowsMap[key] = params.rows.idRowsLookup[key];
      }
    }
    const expenses = Object.values(filteredRowsMap).map(
      (row: any) => row?.MONTO_GASTO //TODO: Revisar traducción columna y revisar todo el comp
    );
    const totalFilteredAmount = expenses.reduce(
      (total, gasto) => total + gasto,
      0
    );
    setTotalFilteredAmount && setTotalFilteredAmount(totalFilteredAmount);
  };

  const handleSetVisibility = (newModel: any) => {
    setNewVisibility && setNewVisibility(newModel);
  };

  return (
    <div style={{ height: tableHeight, width: "100%" }}>
      <DataGridPro
        localeText={getTableTexts(t)}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        rowsPerPageOptions={rowsPerPageOptions}
        rowHeight={rowHeight}
        components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        onCellClick={handleCellClick}
        componentsProps={{
          panel: {
            sx: {
              [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
                display: "none",
              },
            },
          },
        }}
        disableColumnMenu={true}
        hideFooter={hideFooter}
        checkboxSelection={checkboxSelection === false ? false : true}
        disableSelectionOnClick
        sx={{
          boxShadow: 3,
          fontSize: "12px",
          padding: "0 10px 10px 10px",
          "& .MuiDataGrid-columnHeadersInner": {
            backgroundColor: "var(--bg-secondary)",
          },
          "& .MuiDataGrid-columnHeadersInner span": {
            color: "white",
          },
        }}
        columnBuffer={columns.length}
        columnThreshold={5}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0
            ? "es_whiteRow"
            : "es_colorRow"
        }
        initialState={{
          columns: {
            columnVisibilityModel: columnVisibility,
          },
        }}
        onColumnVisibilityModelChange={(newModel) =>
          handleSetVisibility(newModel)
        }
        selectionModel={selectedRows}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectedRows(newSelectionModel);
        }}
        headerHeight={headerHeight ? headerHeight : 50}
      />
    </div>
  );
};

export default VirtualizatedTable;
