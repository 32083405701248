import { ActionMeta, SingleValue } from "react-select";
import { criteriaOptions } from "../selectOptions";
import {
  form_helper_text_styles,
  grid_item_element_styles,
} from "../../../../../styles/app-styles";
import { formatOptions } from "../../../../../utils/util";
import { getBackgroundColor, isValidSchemaLine, options } from "../../utils";
import { IdOption } from "../../../configCriteria/types";
import { SchemaRowType } from "../../types";
import { selectStyles } from "../../../configCriteria/select.styles";
import { Stack, TextField } from "@mui/material";
import { useRef, useState } from "react";
import AddButton from "../../../../buttons/AddButton";
import ConfirmationModal from "../../../../dialog/ConfirmationModal";
import CreatableSelect from "react-select/creatable";
import SchemaRow from "../shared/SchemaRow";
import Select from "../../../../forms/Select";
import useDialog from "../../../../../hooks/useDialog";
import useLanguage from "../../../../../context/LanguageProvider";
import useSingleSelect from "../../../../../hooks/useSingleSelect";
import WhiteShadowBox from "../../../../layout/WhiteShadowBox";
import "../../../../../App.css";

const ExplicitAssign = ({
  destinyTypeSelectedOption,
  newSchemaRows,
  setNewSchemaRows,
  setMessage,
  destinitySelectOptions,
  criteriaData,
  iterations,
  setIterations,
  selectedCriteriaType,
  setSelectedCriteriaType,
}) => {
  const { t } = useLanguage();
  const criteriaSelectRef: any = useRef();
  const destinityCreatableSelectRef: any = useRef();
  const percentageRef: any = useRef<HTMLInputElement>(null);
  const [creatableDestinityValueSelected, setCreatableDestinityValueSelected] =
    useState<string>("");
  const [percentage, setPercentage] = useState<string | number>(100);
  const [
    openConfirmationCriteriaTypeModal,
    toggleConfirmationCriteriaTypeModal,
  ] = useDialog();
  const [newCriteriaTypeSelectedOption, setNewCriteriaTypeSelectedOption] =
    useState<any>();

  /*********************************************** Selects ***********************************************/

  const [
    criteriaSelectedOption,
    setCriteriaSelectedOption,
    criteriaSelectedOptionsChangeHandler,
  ] = useSingleSelect();

  /**************************************** Validaciones *******************************************/

  const criteriaTypeChangeHandler = (
    value: SingleValue<IdOption>,
    action: ActionMeta<IdOption>
  ) => {
    switch (action.action) {
      case "select-option":
        if (newSchemaRows && newSchemaRows?.length > 0) {
          setNewCriteriaTypeSelectedOption(value);
          toggleConfirmationCriteriaTypeModal();
        } else {
          setSelectedCriteriaType(value);
        }
        break;
      default:
        break;
    }
  };

  const acceptChangeCriteriaType = () => {
    setSelectedCriteriaType(newCriteriaTypeSelectedOption);
    setNewSchemaRows([]);
    setIterations([]);
    setCriteriaSelectedOption(undefined);
    criteriaSelectRef?.current?.clearValue();
    toggleConfirmationCriteriaTypeModal();
  };

  const handleChangeDestinityCreatableSelect = (
    value: any,
    action: ActionMeta<any>
  ) => {
    switch (action.action) {
      case "select-option":
        setCreatableDestinityValueSelected(value.label);
        break;
      case "clear":
        setCreatableDestinityValueSelected("");
        break;
      case "create-option":
        destinityCreatableSelectRef.current.value = value.label;
        setCreatableDestinityValueSelected(value.label);
        break;
      default:
        break;
    }
  };

  const handleAddSchemaRow = () => {
    if (
      isValidSchemaLine(
        t,
        newSchemaRows,
        selectedCriteriaType,
        creatableDestinityValueSelected,
        criteriaSelectedOption,
        percentage,
        setMessage
      )
    ) {
      if (selectedCriteriaType?.value === "criterio") {
        setNewSchemaRows([
          ...newSchemaRows,
          {
            destino: creatableDestinityValueSelected,
            criterio: criteriaSelectedOption,
          },
        ]);
      } else {
        setNewSchemaRows([
          ...newSchemaRows,
          {
            destino: creatableDestinityValueSelected,
            porcentaje: percentage as number,
          },
        ]);
      }
      setMessage({ type: "", text: "" });
      setPercentage("");
      setCreatableDestinityValueSelected("");
      destinityCreatableSelectRef.current.clearValue();
      setCriteriaSelectedOption(undefined);
      criteriaSelectRef.current?.clearValue();
      const newIterations = iterations.map((iteration) => {
        return {
          ...iteration,
          sin_match: {
            ...iteration?.sin_match,
            criterios_destinos:
              iteration?.sin_match &&
              iteration?.sin_match.caso === "CRITERIO_NUEVO"
                ? iteration?.sin_match?.criterios_destinos
                  ? [
                      ...iteration.sin_match.criterios_destinos,
                      { destino: creatableDestinityValueSelected },
                    ]
                  : [{ destino: creatableDestinityValueSelected }]
                : iteration?.sin_match?.caso === "ELIMINAR"
                ? [
                    ...iteration.sin_match.criterios_destinos,
                    {
                      destino: creatableDestinityValueSelected,
                      validaciones_conservar: [
                        {
                          criterio: undefined,
                          validaciones: [],
                        },
                      ],
                    },
                  ]
                : undefined,
            porcentaje:
              iteration?.sin_match && iteration?.sin_match.caso === "PORCENTAJE"
                ? iteration?.sin_match?.porcentaje
                  ? [
                      ...iteration.sin_match.porcentaje,
                      {
                        destino: creatableDestinityValueSelected,
                        porcentaje: 0,
                      },
                    ]
                  : [
                      {
                        destino: creatableDestinityValueSelected,
                        porcentaje: 0,
                      },
                    ]
                : undefined,
          },
          criterio_cero: {
            ...iteration?.criterio_cero,
            criterios_destinos:
              iteration?.criterio_cero &&
              iteration?.criterio_cero.caso === "CRITERIO_NUEVO"
                ? iteration?.criterio_cero?.criterios_destinos
                  ? [
                      ...iteration.criterio_cero.criterios_destinos,
                      { destino: creatableDestinityValueSelected },
                    ]
                  : [{ destino: creatableDestinityValueSelected }]
                : iteration?.criterio_cero?.caso === "ELIMINAR"
                ? [
                    ...iteration.criterio_cero.criterios_destinos,
                    {
                      destino: creatableDestinityValueSelected,
                      validaciones_conservar: [
                        {
                          criterio: undefined,
                          validaciones: [],
                        },
                      ],
                    },
                  ]
                : undefined,
            porcentaje:
              iteration?.criterio_cero &&
              iteration?.criterio_cero.caso === "PORCENTAJE"
                ? iteration?.criterio_cero?.porcentaje
                  ? [
                      ...iteration.criterio_cero.porcentaje,
                      {
                        destino: creatableDestinityValueSelected,
                        porcentaje: 0,
                      },
                    ]
                  : [
                      {
                        destino: creatableDestinityValueSelected,
                        porcentaje: 0,
                      },
                    ]
                : undefined,
          },
        };
      });
      setIterations(newIterations);
    }
  };

  const handleDeleteSchemaRow = (row) => {
    const updatedRows = newSchemaRows.filter(
      (line: SchemaRowType) => line.destino !== row.destino
    );
    setNewSchemaRows(updatedRows);
    if (updatedRows.length === 0) {
      setIterations([]);
    } else {
      const updatedIterations = iterations.map((iteration) => {
        const filtered_sin_match_nuevo =
          iteration.sin_match?.criterios_destinos?.filter(
            (criterio) => criterio.destino !== row.destino
          );
        const filtered_cero_nuevo =
          iteration.criterio_cero?.criterios_destinos?.filter(
            (criterio) => criterio.destino !== row.destino
          );
        const filtered_sin_match_porcentaje =
          iteration.sin_match?.porcentaje?.filter(
            (criterio) => criterio.destino !== row.destino
          );
        const filtered_cero_porcentaje =
          iteration.criterio_cero?.porcentaje?.filter(
            (criterio) => criterio.destino !== row.destino
          );

        if (
          (iteration.sin_match.caso === "CRITERIO_NUEVO" ||
            iteration.sin_match.caso === "ELIMINAR") &&
          (iteration.criterio_cero.caso === "CRITERIO_NUEVO" ||
            iteration.criterio_cero.caso === "ELIMINAR")
        ) {
          return {
            ...iteration,
            sin_match: {
              ...iteration.sin_match,
              criterios_destinos: filtered_sin_match_nuevo,
            },
            criterio_cero: {
              ...iteration.criterio_cero,
              criterios_destinos: filtered_cero_nuevo,
            },
          };
        } else if (
          iteration.sin_match.caso === "PORCENTAJE" &&
          iteration.criterio_cero.caso === "PORCENTAJE"
        ) {
          return {
            ...iteration,
            sin_match: {
              ...iteration.sin_match,
              porcentaje: filtered_sin_match_porcentaje,
            },
            criterio_cero: {
              ...iteration.criterio_cero,
              porcentaje: filtered_cero_porcentaje,
            },
          };
        } else if (
          (iteration.sin_match.caso === "CRITERIO_NUEVO" ||
            iteration.sin_match.caso === "ELIMINAR") &&
          iteration.criterio_cero.caso === "PORCENTAJE"
        ) {
          return {
            ...iteration,
            sin_match: {
              ...iteration.sin_match,
              criterios_destinos: filtered_sin_match_nuevo,
            },
            criterio_cero: {
              ...iteration.criterio_cero,
              porcentaje: filtered_cero_porcentaje,
            },
          };
        } else if (
          iteration.sin_match.caso === "PORCENTAJE" &&
          (iteration.criterio_cero.caso === "CRITERIO_NUEVO" ||
            iteration.criterio_cero.caso === "ELIMINAR")
        ) {
          return {
            ...iteration,
            sin_match: {
              ...iteration.sin_match,
              porcentaje: filtered_sin_match_porcentaje,
            },
            criterio_cero: {
              ...iteration.criterio_cero,
              criterios_destinos: filtered_cero_nuevo,
            },
          };
        }
      });
      setIterations(updatedIterations);
    }
  };

  return (
    <WhiteShadowBox>
      <Stack gap={0.7} width="100%">
        <Stack
          sx={{
            ...grid_item_element_styles(
              getBackgroundColor,
              "iteration",
              () => "100%"
            ),
            color: "white",
            minHeight: "30px",
            borderRadius: "4px",
          }}
        >
          Iteración 0
        </Stack>
        <Stack direction="row" justifyContent={"space-between"} spacing={1}>
          <Stack width={"50%"} gap={0.7}>
            <Stack
              sx={{
                ...grid_item_element_styles(
                  getBackgroundColor,
                  "header",
                  () => "100%"
                ),
                color: "white",
                minHeight: "37px",
                fontWeight: "bold",
                borderRadius: "4px",
                borderTop: "1px solid white",
              }}
            >
              {destinyTypeSelectedOption.label} destino
            </Stack>
            <CreatableSelect
              ref={destinityCreatableSelectRef}
              isClearable
              styles={selectStyles(creatableDestinityValueSelected)}
              options={formatOptions(destinitySelectOptions)}
              formatCreateLabel={(userInput) =>
                `${t(
                  "preprorationParameterization.newSchemaContent.add"
                )} ${userInput}`
              }
              onChange={handleChangeDestinityCreatableSelect}
              closeMenuOnSelect
              isSearchable
              placeholder={t(
                "preprorationParameterization.newSchemaContent.chooseAddValue"
              )}
              createOptionPosition="first"
            />
          </Stack>

          <Stack justifyContent={"flex-start"} sx={{ width: "50%" }} gap={0.8}>
            <Select
              value={selectedCriteriaType}
              styles={{
                singleValue: (provided) => ({
                  ...provided,
                  color: "white !important",
                  fontWeight: "700",
                }),
              }}
              options={options(t)}
              placeholder={t(
                "preprorationParameterization.newSchemaContent.criteria"
              ).toUpperCase()}
              defaultValue={selectedCriteriaType}
              closeMenuOnSelect
              onChange={criteriaTypeChangeHandler}
              isClearable={false}
              isSearchable={false}
              className="selectDestinity"
            />
            {selectedCriteriaType?.value ===
            t("preprorationParameterization.newSchemaContent.criteria") ? (
              <Select
                reference={criteriaSelectRef}
                styles={selectStyles(criteriaSelectedOption)}
                options={criteriaOptions(criteriaData)}
                onChange={criteriaSelectedOptionsChangeHandler}
                closeMenuOnSelect
                placeholder={t(
                  "preprorationParameterization.newSchemaContent.chooseCriteria"
                )}
                defaultValue={criteriaSelectedOption}
                isClearable
              />
            ) : (
              <TextField
                ref={percentageRef}
                type="number"
                variant="outlined"
                fullWidth
                value={percentage}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPercentage(event.target.value);
                }}
                error={Number(percentage) === 0 || Number(percentage) > 100}
                FormHelperTextProps={{ sx: form_helper_text_styles }}
                size="small"
                required={true}
                placeholder={t(
                  "preprorationParameterization.newSchemaContent.choosePercentage"
                )}
              />
            )}
          </Stack>
          <Stack
            justifyContent={"space-between"}
            sx={{
              margin: 0,
              justifyContent: "flex-end",
              display: "flex",
              height: "80px",
            }}
          >
            <AddButton onClick={handleAddSchemaRow} />
          </Stack>
        </Stack>
        <Stack sx={{ marginTop: "15px" }}>
          {newSchemaRows?.length > 0 &&
            newSchemaRows.map((row, i) => (
              <SchemaRow
                key={i}
                row={row}
                handleDeleteSchemaRow={handleDeleteSchemaRow}
              />
            ))}
        </Stack>
      </Stack>
      <ConfirmationModal
        title={t("Modificar tipo de criterio")}
        message={
          "¿Confirma que desea modificar el tipo de criterio? Esto eliminará los datos cargados hasta el momento."
        }
        customMessage={true}
        open={openConfirmationCriteriaTypeModal}
        handleClose={toggleConfirmationCriteriaTypeModal}
        handleAccept={acceptChangeCriteriaType}
      />
    </WhiteShadowBox>
  );
};

export default ExplicitAssign;
