import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { renderTaskCell } from "./utils";
import Stack from "@mui/material/Stack";

/**
 * @name transformDataTaskManager
 * @description Función que transforma los datos de la tabla.
 * @param dataColumnas
 * @param dataFilas
 */
export const transformDataTaskManager = (
  t: any,
  data: any,
  tabValue: string,
  buttonsActions: any
) => {
  const columnsPreProrrateo = ["fecha", "periodo", "estado", "acción"];
  const columnsProrrateo = [
    "fecha",
    "bucket",
    "etapa",
    "iteracion",
    "periodo",
    "estado",
    "acción",
  ];
  const columnsDescargas = ["archivo", "periodo", "estado", "acción"];
  const columnsDatamodel = ["fecha", "nombre", "periodo", "estado", "acción"];
  const columnsDataExport = ["fecha", "archivo", "acción"];
  const columnsSalesForecast = ["fecha", "análisis", "estado", "acción"];
  const columnsCostsForecast = ["fecha", "análisis", "estado", "acción"];
  const columnsSimulation = ["fecha", "nombre_referencia", "estado", "acción"];
  const columnsMarketBasket = [
    "fecha",
    "nombre_referencia",
    "ultimo_periodo",
    "estado",
    "acción",
  ];

  const translateColumn = (columnName) => {
    switch (columnName) {
      case "fecha":
        return t("columns.date");
      case "periodo":
        return t("columns.period");
      case "estado":
        return t("columns.state");
      case "acción":
        return t("columns.action");
      case "bucket":
        return t("columns.bucket");
      case "etapa":
        return t("columns.stage");
      case "iteracion":
        return t("columns.iteration");
      case "archivo":
        return t("columns.file");
      case "nombre":
        return t("columns.name");
      case "análisis":
        return t("columns.analysis");
      case "nombre_referencia":
        return t("columns.referenceName");
      case "ultimo_periodo":
        return t("columns.lastPeriod");
      default:
        return t(`columns.${columnName}`); // Fallback en caso de que no esté en el switch
    }
  };

  const tasksColumns: any[] =
    tabValue === t("tabs.preproration")
      ? columnsPreProrrateo
      : tabValue === t("tabs.proration")
      ? columnsProrrateo
      : tabValue === t("tabs.downloads")
      ? columnsDescargas
      : tabValue === "ANALISIS_DATAEXPORT" || tabValue === "ARCHIVOS"
      ? columnsDataExport
      : tabValue === t("tabs.salesForecast")
      ? columnsSalesForecast
      : tabValue === t("tabs.costsForecast")
      ? columnsCostsForecast
      : tabValue === t("tabs.simulation")
      ? columnsSimulation
      : tabValue === t("tabs.marketBasket")
      ? columnsMarketBasket
      : columnsDatamodel;

  const arrayColumns = tasksColumns?.map((columna) => ({
    field: columna,
    headerClassName: "es_header_column",
    headerName: columna,
    description: columna,
    minWidth: 100,
    flex:
      columna === "archivo"
        ? 2
        : columna === "periodo" ||
          columna === "ultimo_periodo" ||
          columna === "estado" ||
          columna === "acción" ||
          columna === "archivo"
        ? 1.5
        : columna === "etapa" || columna === "iteracion"
        ? 0.5
        : 1,
    renderHeader: (params: GridColumnHeaderParams) => (
      <strong
        style={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        {translateColumn(params.field)}
      </strong>
    ),
    renderCell: (params: any) => (
      <Stack direction="row" alignItems="center">
        {renderTaskCell(t, tabValue, columna, params, buttonsActions)}
      </Stack>
    ),
  }));

  const arrayRows =
    data && data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          obj["id"] = fila.id;
          for (const key in fila) {
            if (key !== "id") {
              obj[key] = fila[key];
            }
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
