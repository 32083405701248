import {
  columnOptions,
  fileOptions,
} from "../../../criterias/configCriteria/selectOptions";
import {
  columns_error_typography_styles,
  container_gap_styles,
  form_label,
  mb_container_styles,
} from "../../../../styles/app-styles";
import { Base } from "../../../../context/types";
import { IdOption } from "../../../criterias/configCriteria/types";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { useEffect, useRef, useState } from "react";
import BaseModal from "../../../dialog/BaseModal";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Select from "../../../forms/Select";
import ShadowBox from "../../../dataModel/groupManagement/ShadowBox";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import useMultiSelect from "../../../../hooks/useMultiSelect";
import useSingleSelect from "../../../../hooks/useSingleSelect";
interface MergeProps {
  open: boolean;
  handleClose: () => void;
  dataGetFileColumns: any;
  previousfileSelectedOption: IdOption | undefined;
  base?: Base;
  handleSetElement: any;
  bases: Base[];
}

const MergeModal = ({
  open,
  handleClose,
  base,
  dataGetFileColumns,
  previousfileSelectedOption,
  handleSetElement,
  bases,
}: MergeProps) => {
  const { t } = useLanguage();
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const fileSelectRef: any = useRef(null);
  const columnsSelectRef: any = useRef(null);
  const columnsPreviewBaseSelectRef: any = useRef(null);
  const columnsNewBaseSelectRef: any = useRef(null);
  const [errorFile, setErrorFile] = useState(false);
  const [firstOcurrence, setFirstOcurrence] = useState(true);
  const [lastOcurrence, setLastOcurrence] = useState(false);
  const [dontDelete, setDontDelete] = useState(false);

  const [fileSelectedOption, setFileSelectedOptions, fileChangeHandler] =
    useSingleSelect(() => onFileColumn(), undefined);

  const isFileRepeated = () => {
    return bases.some(
      (b: any) => b.archivo.value === fileSelectedOption?.value
    );
  };

  useEffect(() => {
    if (isFileRepeated()) {
      setErrorFile(true);
    } else {
      setErrorFile(false);
    }
  }, [fileSelectedOption]);

  const onFileColumn = () => {
    setColumnsSelectedOption(undefined);
    columnsSelectRef.current.clearValue();
  };

  const onChangeColumns = () => {
    setColumnsNewBaseSelectedOption(undefined);
    columnsNewBaseSelectRef.current.clearValue();
  };

  const [
    columnsSelectedOption,
    setColumnsSelectedOption,
    columnsChangeHandler,
  ] = useMultiSelect(
    base ? (base.columnas as IdOption[] | undefined) : undefined,
    onChangeColumns
  );

  const [
    columnsPreviewBaseSelectedOption,
    setColumnsPreviewBaseSelectedOption,
    columnsPreviewBaseChangeHandler,
  ] = useMultiSelect(
    base
      ? (base.merge?.columnas_base_anterior as IdOption[] | undefined)
      : undefined
  );

  const [
    columnsNewBaseSelectedOption,
    setColumnsNewBaseSelectedOption,
    columnsNewBaseChangeHandler,
  ] = useMultiSelect(
    base
      ? (base.merge?.columnas_base_nueva as IdOption[] | undefined)
      : undefined
  );

  /******************************* VALIDACIONES FORMULARIO ********************************/

  const isFormCompleted = () => {
    return (
      columnsSelectedOption === undefined ||
      columnsSelectedOption?.length === 0 ||
      columnsPreviewBaseSelectedOption === undefined ||
      columnsPreviewBaseSelectedOption?.length === 0 ||
      columnsNewBaseSelectedOption === undefined ||
      columnsNewBaseSelectedOption?.length === 0 ||
      fileSelectedOption === undefined ||
      fileSelectedOption === null ||
      columnsPreviewBaseSelectedOption.length !==
        columnsNewBaseSelectedOption.length ||
      errorFile
    );
  };

  const saveMerge = () => {
    const updatedBase = {
      archivo: fileSelectedOption,
      columnas: columnsSelectedOption,
      eliminar_duplicados: firstOcurrence
        ? "primera-ocurrencia"
        : lastOcurrence
        ? "ultima-ocurrencia"
        : "no-eliminar",
      merge: {
        columnas_base_anterior: columnsPreviewBaseSelectedOption,
        columnas_base_nueva: columnsNewBaseSelectedOption,
      },
    };
    handleSetElement(updatedBase);
    toggleConfirmationModal();
    cleanAndClose();
  };

  /******************************* LIMPIEZA DE DATOS Y CIERRE *******************************/

  const cleanAndClose = () => {
    handleClose();
    if (!base) {
      setColumnsSelectedOption([]);
      setColumnsPreviewBaseSelectedOption([]);
      setColumnsNewBaseSelectedOption(undefined);
      fileSelectRef.current.clearValue();
      setColumnsNewBaseSelectedOption(undefined);
      setLastOcurrence(false);
      setFirstOcurrence(true);
      setDontDelete(false);
    }
  };

  const toggleDeleteDuplicatedValues = (e) => {
    if (e.target.id === "dontDelete") {
      setDontDelete(true);
      setFirstOcurrence(false);
      setLastOcurrence(false);
    } else if (e.target.id === "firstOcurrence") {
      setDontDelete(false);
      setFirstOcurrence(true);
      setLastOcurrence(false);
    } else if (e.target.id === "lastOcurrence") {
      setDontDelete(false);
      setFirstOcurrence(false);
      setLastOcurrence(true);
    }
  };

  const equalColumnsLength = () => {
    return !(
      columnsPreviewBaseSelectedOption?.length !== 0 &&
      columnsPreviewBaseSelectedOption !== undefined &&
      columnsNewBaseSelectedOption?.length !== 0 &&
      columnsNewBaseSelectedOption !== undefined &&
      columnsPreviewBaseSelectedOption?.length !==
        columnsNewBaseSelectedOption?.length
    );
  };

  return (
    <BaseModal
      open={open}
      title="Merge"
      cleanAndClose={cleanAndClose}
      openConfirmationModal={openConfirmationModal}
      toggleConfirmationModal={toggleConfirmationModal}
      handleAccept={saveMerge}
      confirmationModalMessage={t(
        "dataModelText.groupManagement.step1.mergeModal.addMerge"
      )}
      disabledAcceptButton={isFormCompleted()}
      height="100%"
    >
      <Stack sx={container_gap_styles(2)}>
        <ShadowBox>
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <InputLabel sx={form_label}>
              {t("dataModelText.groupManagement.step1.mergeModal.file")}
            </InputLabel>
            <Select
              reference={fileSelectRef}
              styles={selectStyles(fileSelectedOption)}
              options={fileOptions(dataGetFileColumns)}
              name="Archivo"
              onChange={fileChangeHandler}
              closeMenuOnSelect
              placeholder={t(
                "dataModelText.groupManagement.step1.mergeModal.selectFile"
              )}
              isClearable
              isDisabled={false}
              defaultValue={fileSelectedOption}
            />
            {errorFile && (
              <Typography sx={{ color: "var(--red)" }}>
                {t(
                  "dataModelText.groupManagement.step1.mergeModal.alreadyExistBaseWithFile"
                )}
              </Typography>
            )}
          </Box>
          <Box sx={mb_container_styles("0 0 10px 0")}>
            <InputLabel sx={form_label}>
              {t("dataModelText.groupManagement.step1.mergeModal.columns")}
            </InputLabel>
            <Select
              isMulti
              reference={columnsSelectRef}
              styles={selectStyles(columnsSelectedOption)}
              options={columnOptions(dataGetFileColumns, fileSelectedOption)}
              name="Columnas"
              onChange={columnsChangeHandler}
              closeMenuOnSelect={false}
              placeholder={t(
                "dataModelText.groupManagement.step1.mergeModal.columns"
              )}
              isClearable
              defaultValue={columnsSelectedOption}
              isDisabled={false}
            />
          </Box>
        </ShadowBox>
        <ShadowBox>
          <Stack direction={"row"} gap={4}>
            <Box sx={mb_container_styles("0 0 10px 0")}>
              <InputLabel sx={form_label}>
                {t(
                  "dataModelText.groupManagement.step1.mergeModal.columnsMergePreviousBase"
                )}
              </InputLabel>
              <Select
                reference={columnsPreviewBaseSelectRef}
                styles={selectStyles(columnsPreviewBaseSelectedOption)}
                options={columnOptions(
                  dataGetFileColumns,
                  previousfileSelectedOption
                )}           
                className="cc_select"
                name="Columna"
                onChange={columnsPreviewBaseChangeHandler}
                placeholder={t(
                  "dataModelText.groupManagement.step1.mergeModal.chooseColumns"
                )}
                closeMenuOnSelect={false}
                defaultValue={columnsPreviewBaseSelectedOption}
                isClearable
                isMulti
              />
            </Box>
            <Box sx={mb_container_styles("0 0 10px 0")}>
              <InputLabel sx={form_label}>
                {t(
                  "dataModelText.groupManagement.step1.mergeModal.columnsMergeNewBase"
                )}
              </InputLabel>
              <Select
                isMulti
                reference={columnsNewBaseSelectRef}
                styles={selectStyles(columnsNewBaseSelectedOption)}
                options={columnsSelectedOption}
                name="Columnas merge - Base nueva"
                onChange={columnsNewBaseChangeHandler}
                closeMenuOnSelect={false}
                placeholder={t(
                  "dataModelText.groupManagement.step1.mergeModal.chooseColumns"
                )}
                isClearable
                defaultValue={columnsNewBaseSelectedOption}
                isDisabled={false}
              />
            </Box>
          </Stack>
          {!equalColumnsLength() && (
            <Typography
              variant="subtitle1"
              sx={columns_error_typography_styles}
            >
              {t("validations.configValidations.notEqualColumnsLength")}
            </Typography>
          )}
        </ShadowBox>
      </Stack>
      <Stack
        sx={{
          gap: "10px",
          mt: "20px",
          width: "100%",
          flexDirection: "column",
          mb: "20px",
        }}
      >
        <ShadowBox>
          <Typography sx={form_label}>
            {t(
              "dataModelText.groupManagement.step1.mergeModal.removeDuplicates"
            )}
          </Typography>
          <Stack direction="row" marginTop="5px">
            <Stack direction="row" marginRight="25px">
              <label
                htmlFor="firstOcurrence"
                style={{
                  color: "var(--text-main)",
                  fontWeight: 500,
                }}
              >
                <input
                  type="radio"
                  className="cc_checkbox"
                  name="duplicatedValues"
                  id="firstOcurrence"
                  onChange={(e) => toggleDeleteDuplicatedValues(e)}
                  checked={firstOcurrence}
                />
                {t(
                  "dataModelText.groupManagement.step1.mergeModal.keepFirstOcurrence"
                )}
              </label>
            </Stack>
            <Stack direction="row" marginRight="35px">
              <label
                style={{
                  color: "var(--text-main)",
                  fontWeight: 500,
                }}
                htmlFor="lastOcurrence"
              >
                <input
                  type="radio"
                  className="cc_checkbox"
                  id="lastOcurrence"
                  name="duplicatedValues"
                  onChange={(e) => toggleDeleteDuplicatedValues(e)}
                  checked={lastOcurrence}
                />
                {t(
                  "dataModelText.groupManagement.step1.mergeModal.keepLastOcurrence"
                )}
              </label>
            </Stack>
            <Stack direction="row">
              <label
                style={{
                  color: "var(--text-main)",
                  fontWeight: 500,
                }}
                htmlFor="dontDelete"
              >
                <input
                  type="radio"
                  className="cc_checkbox"
                  name="duplicatedValues"
                  id="dontDelete"
                  onChange={(e) => toggleDeleteDuplicatedValues(e)}
                  checked={dontDelete}
                />
                {t(
                  "dataModelText.groupManagement.step1.mergeModal.doNotDelete"
                )}
              </label>
              <Tooltip
                title={t(
                  "dataModelText.groupManagement.step1.mergeModal.notRecommended"
                )}
              >
                <ReportGmailerrorredIcon
                  color="primary"
                  sx={{ fontSize: "24px", marginLeft: "5px" }}
                />
              </Tooltip>
            </Stack>
          </Stack>
        </ShadowBox>
      </Stack>
    </BaseModal>
  );
};

export default MergeModal;
