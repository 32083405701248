import { checkRepeatedName, getRolconfigLabels } from "../utils";
import { ROLES_URL } from "../../../api/axios";
import { Message } from "../../criterias/configCriteria/types";
import { RolConfig, emptyRolConfig } from "../data";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useRef, useState } from "react";
import Button from "../../buttons/Button";
import CheckboxOption from "../../forms/CheckboxOption";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import CustomInput from "../../forms/CustomInput";
import FormLayout from "../../forms/FormLayout";
import FormSelectLayout from "../../forms/FormSelectLayout";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import {
  criteria_buttons_container,
  form_layouts_container_styles2,
  key_column_label_styles,
  param_steps_container,
  step_message_styles,
  step_title_styles,
} from "../../../styles/app-styles";
interface RolFormProps {
  configMode: "CREATE" | "EDIT" | "COPY" | "";
  rol: any;
  handleFinish: () => void;
}

const RolForm = ({ configMode, rol, handleFinish }: RolFormProps) => {
  const { t } = useLanguage();
  const rolNameRef: any = useRef(null);
  const [message, setMessage] = useState<Message | undefined>();
  const [rolName, setRolName] = useState(
    configMode === "EDIT" && rol?.nombre
      ? rol?.nombre
      : configMode === "COPY" && rol?.nombre
      ? rol?.nombre.concat(t("copyGenericText"))
      : ""
  );
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [rolConfig, setRolConfig] = useState<RolConfig>(
    rol ? rol.rol_config : emptyRolConfig
  );

  /**************************************** Llamadas al back *****************************************/

  const { data: dataRoles } = useApiQuery(ROLES_URL, false);

  const { isLoading: isLoadingPostRol, callApi: postRol } = useApi(
    "",
    "POST",
    t("administratorPanel.roles.createRole.codes"),
    undefined,
    handleFinish
  );

  const { isLoading: isLoadingPutRol, callApi: putRol } = useApi(
    "",
    "PUT",
    t("administratorPanel.roles.editRole.codes"),
    undefined,
    handleFinish
  );

  /************************************ Cambios de estados Nombre y checkbox ************************/

  useEffect(() => {
    setMessage(undefined);
    checkRepeatedName(rolName, dataRoles, t, configMode, setMessage, rol);
  }, [rolName]);

  const toggleCheckbox = (title, subTitle) => {
    setRolConfig((prevConfig) => ({
      ...prevConfig,
      [title]: {
        ...prevConfig[title],
        [subTitle]: !prevConfig[title][subTitle],
      },
    }));
  };

  /************************************** Guardar cambios ***********************************************/

  const saveRol = () => {
    const newRol = {
      nombre: rolName,
      habilitado: true,
      rol_config: rolConfig,
    };
    if (configMode === "CREATE" || configMode === "COPY") {
      postRol(ROLES_URL, newRol);
    } else if (configMode === "EDIT") {
      rol && putRol(`${ROLES_URL}${rol?.id}`, newRol);
    }
    toggleConfirmationModal();
  };

  return (
    <Stack sx={param_steps_container}>
      <Typography sx={step_title_styles}>
        {configMode !== "EDIT"
          ? t("administratorPanel.roles.newRole")
          : t("administratorPanel.roles.editRoleLabel")}
      </Typography>
      <SimpleBackdrop
        open={isLoadingPostRol || isLoadingPutRol}
        message={
          isLoadingPostRol
            ? t("administratorPanel.roles.creatingRole")
            : t("administratorPanel.roles.updatingRole")
        }
      />
      <Stack sx={form_layouts_container_styles2}>
        <FormLayout width="70%">
          {message && (
            <Typography sx={step_message_styles(message.type)}>
              {message.text}
            </Typography>
          )}
          <FormSelectLayout
            title={t("administratorPanel.companies.name")}
            required={true}
          >
            <CustomInput
              type="text"
              name="rolName"
              inputRef={rolNameRef}
              required={true}
              value={rolName}
              setValue={setRolName as (value: string | number) => void}
              placeholder={t("administratorPanel.roles.placeholderName")}
              autoFocus={true}
              maxLength={200}
            />
          </FormSelectLayout>
          {rolConfig &&
            Object.entries(rolConfig).map(([title, subConfig]) => (
              <div key={title}>
                <Typography sx={key_column_label_styles}>
                  {getRolconfigLabels(title)}
                </Typography>
                {Object.entries(subConfig).map(([subTitle, isChecked]) => (
                  <CheckboxOption
                    key={`${subTitle}-${title}`}
                    toggleCheckbox={() => toggleCheckbox(title, subTitle)}
                    checked={isChecked}
                    id={`${subTitle}-${title}`}
                    labelText={getRolconfigLabels(subTitle)}
                  />
                ))}
              </div>
            ))}
        </FormLayout>
        <ConfirmationModal
          open={openConfirmationModal}
          handleClose={toggleConfirmationModal}
          handleAccept={saveRol}
          message={
            configMode === "EDIT"
              ? t("administratorPanel.roles.editRoleAction")
              : t("administratorPanel.roles.createRoleAction")
          }
          title={
            configMode === "EDIT"
              ? t("administratorPanel.roles.editRoleLabel")
              : t("administratorPanel.roles.createRoleLabel")
          }
        />
      </Stack>
      <Stack sx={criteria_buttons_container}>
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={handleFinish}
        />
        <Button
          title={t("general.accept")}
          color="blue"
          type="button"
          onClick={() => toggleConfirmationModal()}
          disabled={
            message?.type === "error" || !rolName || rolName.length === 0
          }
        />
      </Stack>
    </Stack>
  );
};

export default RolForm;
