import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import BarInfiniteDrilldownChart from "../../Charts/BarInfiniteDrilldownChart";
import Boxplot from "../../Charts/Boxplot";
import LinesChart from "../../Charts/LinesChart";
import PieDrilldownChart from "../../Charts/PieDrilldownChart";
import ScatterPlot from "../../Charts/ScatterPlot";
import TreemapNewDrilldownChart from "../../Charts/TreemapNewDrilldownChart";
import WaterfallChart from "../../Charts/Waterfall";

interface RenderChartProps {
  dataGetElement: any;
  item: any;
  onClickFilters?: any;
}

const RenderChart = ({
  dataGetElement,
  item,
  onClickFilters,
}: RenderChartProps) => {
  const Chart = () => {
    switch (dataGetElement.tipo) {
      case "WATERFALL":
        return <WaterfallChart results={dataGetElement} />;
      case "SCATTERPLOT":
        return (
          <ScatterPlot
            results={dataGetElement}
            onClickFilters={onClickFilters}
          />
        );
      case "LINEAS":
        return (
          <LinesChart
            results={dataGetElement}
            onClickFilters={onClickFilters}
          />
        );
      case "BARRAS CON DRILLDOWN":
        return (
          <BarInfiniteDrilldownChart
            results={dataGetElement}
            stack={true}
            onClickFilters={onClickFilters}
          />
        );
      case "TORTA CON DRILLDOWN":
        return (
          <PieDrilldownChart
            results={dataGetElement}
            onClickFilters={onClickFilters}
          />
        );
      case "TREEMAP CON DRILLDOWN":
        return (
          <TreemapNewDrilldownChart
            results={dataGetElement}
            onClickFilters={onClickFilters}
          />
        );
      case "BOXPLOT":
        return <Boxplot results={dataGetElement} />;
      default:
        return null;
    }
  };

  return Chart();
};

export default RenderChart;
