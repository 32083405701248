import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ConstructionIcon from "@mui/icons-material/Construction";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import useLanguage from "../../context/LanguageProvider";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
interface SpeedDialTooltipOpenProps {
  id: number
  handleEdit?: (e: any, id: number) => void;
  handleCopy?: (e: any, id: number) => void;
  handleInfo?: (e: any, id: number) => void;
  handleDelete?: (e: any, id: number) => void;
}

export default function SpeedDialTooltipOpen({
  id,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
}: SpeedDialTooltipOpenProps) {
  const { t } = useLanguage();
  const actions = [
    {
      icon: <EditIcon sx={{ fontSize: "24px" }} />,
      name: t("buttons.speedDialTooltipOpen.edit"),
      action: handleEdit,
      active: handleEdit !== undefined && !!handleEdit,
    },
    {
      icon: <FileCopyIcon sx={{ fontSize: "24px" }} />,
      name: t("buttons.speedDialTooltipOpen.duplicate"),
      action: handleCopy ,
      active: handleCopy !== undefined && !!handleCopy
    },
    {
      icon: <ZoomInIcon sx={{ fontSize: "24px" }} />,
      name: t("buttons.speedDialTooltipOpen.seeMore"),
      action: handleInfo,
      active: handleInfo !== undefined && !!handleInfo
    },
    {
      icon: <DeleteForeverIcon sx={{ fontSize: "24px" }} />,
      name: t("buttons.speedDialTooltipOpen.delete"),
      action: handleDelete,
      active: handleDelete !== undefined && !!handleDelete
    },
  ];  

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ height: 40, transform: "translateZ(0px)", flexGrow: 0.5 }}>
      <SpeedDial
        ariaLabel="SpeedDial tooltip"
        icon={<ConstructionIcon sx={{ fontSize: "30px" }} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="left"
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          "& .MuiFab-sizeLarge": {
            backgroundColor: "var(--bg-success)",
            width: "45px",
            height: "45px",
            marginLeft: "0px",
          },
          "& .MuiFab-sizeSmall": {
            backgroundColor: "#cccfd9",
            margin: "4px",
            width: "34px",
            height: "28px",
          },
          "& .MuiFab-sizeLarge:hover": { backgroundColor: "var(--bg-success)" },
          "& .MuiSpeedDial-actions":{
            backgroundColor: "white"
          },
          "& .MuiSpeedDial-actionsClosed": {
            backgroundColor: "transparent"
          }
        }}
      >
        {actions.map((action) => (
          action.active && 
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            /*  tooltipOpen */
            onClick={(e: any) => action.action?.(e, id)}
            tooltipPlacement="top-start"
            sx={{ margin: 0 }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
