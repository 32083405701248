import { makeStyles } from '@mui/styles';
import { add_button_styles } from "../../styles/app-styles";
import AddIcon from "@mui/icons-material/Add";
interface AddButtonProps {
  onClick?: (() => void) | ((e: any) => void) | undefined;
  disabled?: boolean;
  align?: boolean;
}

const AddButton = ({ onClick, disabled, align }: AddButtonProps) => {
  const useStyles : any = makeStyles({
    button: add_button_styles(disabled, align),
  });

  const classes = useStyles();

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={classes.button}
    >
      <AddIcon sx={{ color: "white !important", fill: "white !important" }} />
    </button>
  );
};

export default AddButton;
