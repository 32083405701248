import { useEffect, useState, useCallback } from "react";
import { Typography, Stack, Divider, Button, Tooltip, IconButton, CircularProgress } from "@mui/material";
import { DoneOutline as DoneOutlineIcon, PlayCircleFilledWhite as PlayCircleFilledWhiteIcon, Info as InfoIcon } from "@mui/icons-material";
import { useNavigate } from "react-router";
import SpeedDialTooltipOpen from "../../buttons/SpeedDialTooltipOpen";
import useLanguage from "../../../context/LanguageProvider";
import useApi from "../../../hooks/useApi";
import { GET_TASKS_URL, RERUN_URL } from "../../../api/axios";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { truncateString } from "../../parameterization/utils";

const SimulationCard = ({
    item,
    handleEdit,
    handleCopy,
    handleInfo,
    handleDelete,
    refetchData
}: any) => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [reRunClicked, setReRunClicked] = useState(false);

    const handleHover = useCallback(() => {
        setIsHovered(prev => !prev);
    }, []);    

    const handleViewResultInfo = useCallback(() => {
        if (item) {
            navigate(`/delta/simulacion-resultado`);
            localStorage.setItem("simulationView", String(item.vista_id));
            localStorage.setItem("analysisId", String(item.analisis_id));
            localStorage.setItem("simulationId", String(item.id));
        }
    }, [item, navigate]);

    const onSuccessReRun = useCallback(() => {
        refetchData();
    }, [refetchData]);

    const { isLoading: isLoadingReRunSimulation, callApi: reRunSimulation } = useApi(
        "",
        "POST",
        undefined,
        undefined,
        onSuccessReRun
    );

    const {
        data: simulationTasks,
        refetch: refetchSimulationTasks,
    } = useApiQuery(
        `${GET_TASKS_URL}/?tipo=simulacion`,
        true,
        t("taskManager.getSimulations.error")
    );

    useEffect(() => {
        if (isLoadingReRunSimulation) {
            refetchSimulationTasks()
        }
    }, [isLoadingReRunSimulation, refetchSimulationTasks])


    useEffect(() => {
        if (simulationTasks) {
            const hasPendingTask = simulationTasks.some(task => task.analisis === item.analisis && task.estado.toLowerCase() === "pendiente");
            setReRunClicked(hasPendingTask);
        }
    }, [simulationTasks, item.analisis]);

    return (
        <>
            <Stack sx={{ padding: "3px 5px 5px 5px" }}>
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        height: "40px",
                        wordBreak: "break-word",
                        position: "relative"
                    }}
                >
                    <Stack sx={{
                        position: "absolute",
                        top: 0,
                        left: 0
                    }}>
                        {item.complete && item.ultimo_periodo_validado ? (
                            <Tooltip title={t("deltaTexts.salesForecast.salesForecastCard.allPeriodsExecuted")} placement="right">
                                <Stack sx={{
                                    gap: 1,
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}>
                                    <IconButton
                                        sx={{
                                            backgroundColor: "var(--cream)",
                                        }}
                                    >
                                        <DoneOutlineIcon
                                            sx={{
                                                color: "var(--icon-success)",
                                            }}
                                        />
                                    </IconButton>
                                </Stack>
                            </Tooltip>
                        ) : (
                            <Tooltip title={reRunClicked ? t("general.loading") : !item.ultimo_periodo_validado ? t("deltaTexts.simulation.simulationCard.lastPeriodDataExportNeedToBePredicted") : t("deltaTexts.salesForecast.salesForecastCard.notAllPeriodsExecuted")} placement="right">
                                <Stack sx={{
                                    gap: 1,
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}>
                                    {reRunClicked ?
                                        <IconButton
                                            sx={{
                                                backgroundColor: "var(--cream)",
                                                width: "40px",
                                                height: "40px",
                                            }}
                                        >
                                            <CircularProgress
                                                sx={{
                                                    color: "var(--bg-secondary) !important",
                                                    width: "100% !important",
                                                    height: "100% !important"
                                                }}
                                            />
                                        </IconButton>
                                        : <PlayCircleFilledWhiteIcon sx={{
                                            color: item.ultimo_periodo_validado ? "var(--text-main)" : "var(--text-disabled)",
                                            cursor: "pointer",
                                            fontSize: 40,
                                            transition: "transform 0.2s ease-in-out",
                                            transform: isHovered ? "scale(1.05)" : "scale(1)",
                                        }}
                                            onMouseEnter={!item.ultimo_periodo_validado ? undefined : handleHover}
                                            onMouseLeave={!item.ultimo_periodo_validado ? undefined : handleHover}
                                            onClick={!item.ultimo_periodo_validado ? () => undefined : () => {
                                                setReRunClicked(true)
                                                reRunSimulation(RERUN_URL("simulaciones", item.id))
                                            }}
                                        />
                                    }
                                </Stack>
                            </Tooltip>
                        )}
                    </Stack>
                    <Tooltip title={item.nombre}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: "var(--text-main)",
                                fontWeight: "800",
                                textTransform: "uppercase",
                                fontSize: "15px",
                                lineHeight: "1",
                                width: "98%",
                            }}
                        >
                            {item?.nombre}
                        </Typography>
                    </Tooltip>
                </Stack>
                <Divider sx={{ margin: "5px 0" }} />
                <Stack>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            textAlign: "left",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            letterSpacing: "-0.5px",
                        }}
                        color="var(--text-main)"
                    >
                        {t("general.rule")}: {" "}
                        <span
                            style={{
                                color: "var(--text-disabled)",
                                textAlign: "left",
                                display: "inline",
                                textTransform: "capitalize",
                                fontWeight: "300",
                                wordBreak: "break-all"
                            }}
                        >
                            {item?.regla || item?.analisis}
                        </span>
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            textAlign: "left",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            letterSpacing: "-0.5px",
                        }}
                        color="var(--text-main)"
                    >
                        {t("deltaTexts.simulation.simulationCard.autoRun")}: {" "}
                        {
                            <span
                                style={{
                                    color: "var(--text-info)",
                                    textAlign: "left",
                                    display: "inline",
                                    textTransform: "capitalize",
                                    fontWeight: "300",
                                }}
                            >
                                {item.ejecucion_automatica ? t("general.yes") + "." : t("general.no") + "."}
                            </span>
                        }
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            textAlign: "left",
                            textTransform: "uppercase",
                            fontWeight: "700",
                            letterSpacing: "-0.5px",
                        }}
                        color="var(--text-main)"
                    >
                        {t("deltaTexts.salesForecast.salesForecastCard.enabledPeriodsNonExecuted")}: {" "}
                        {
                            <span
                                style={{
                                    color: "var(--text-info)",
                                    textAlign: "left",
                                    display: "inline",
                                    textTransform: "capitalize",
                                    fontWeight: "300",
                                }}
                            >
                                {item.periodos_faltantes && item.periodos_faltantes.length > 0 ? truncateString(item.periodos_faltantes.map((period) => {
                                    return period
                                }).join(", "), 98) : t("general.none") + "."}
                            </span>
                        }
                    </Typography>
                    {!item.ultimo_periodo_validado ?
                        <Stack sx={{
                            width: "100%",
                            flexDirection: "row",
                            gap: 1
                        }}>
                            <InfoIcon color="primary" fontSize="medium" />
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    textTransform: "uppercase",
                                    fontWeight: "700",
                                    letterSpacing: "-0.5px",
                                }}
                                color="var(--text-main)"
                            >
                                {t("deltaTexts.simulation.simulationCard.lastPeriodDataExportNeedToBePredicted")}
                            </Typography>
                        </Stack>
                        : <></>}
                </Stack>
                <Stack
                    sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        pt: 1
                    }}
                >
                    <Button variant="contained"
                        disabled={item.vista_id === null}
                        sx={{
                            borderRadius: "30px",
                            backgroundColor: item.vista_id !== null ? "var(--bg-success)" : "var(--button-disabled)",
                            border: item.vista_id !== null ? "1px solid var(--bg-success)" : "var(--bg-disabled)",
                            "&:hover": {
                                backgroundColor: item.vista_id !== null ? "white" : "",
                                color: "var(--text-success)",
                            },
                            "&:active": {
                                animation: "MoveUpDown 0.3s linear",
                                animationFillMode: "forwards"
                            },
                        }}
                        onClick={handleViewResultInfo}
                    >
                        {t("simulatorTexts.simulationResultById.results")}
                    </Button>
                    <SpeedDialTooltipOpen
                        id={item.id}
                        handleEdit={handleEdit}
                        handleCopy={handleCopy}
                        handleInfo={handleInfo}
                        handleDelete={handleDelete}
                    />
                </Stack>
            </Stack>
        </>
    );
};

export default SimulationCard;