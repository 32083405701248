import { fileOptions } from "../../../criterias/configCriteria/selectOptions";
import { handleElementRepeatedNameWithObject } from "../utils";
import { periodicityOptions } from "../data";
import { useEffect } from "react";
import { useMarketBasketConfigProvider } from "../../../../context/MarketBasketConfigProvider";
import AdvancedParametersModal from "./AdvancedParametersModal";
import AnalysisLevel from "./AnalysisLevel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CustomButton from "../../../buttons/Button";
import Dialog from "../../../dialog/Dialog";
import FormLayout from "../../../forms/FormLayout";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Grid from "@mui/material/Grid/Grid";
import MergeModal from "./MergeModal";
import MergeRow from "./MergeRow";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  optionalSelectStyles,
  selectStyles,
} from "../../../criterias/configCriteria/select.styles";
import {
  form_helper_text_styles,
  right_bottom_button_container_styles,
  right_bottom_button_styles,
  row_sb_100_styles,
  warning_icon_styles,
  warning_text_container,
} from "../../../../styles/app-styles";

const FormStep1 = () => {
  const { t } = useLanguage();
  const {
    message,
    setMessage,
    setMarketBasketElement,
    dataAnalysisMBs,
    dataGetFileColumnsType,
    totalColumnOptions,
    marketBasketElement,
    fileSelectRef,
    columnsSKUSelectRef,
    columnCanalSelectRef,
    ventaMonedaSelectRef,
    ventaUnidadesSelectRef,
    marcaSelectRef,
    periodicidadSelectRef,
    columnaTransaccionSelectRef,
    columnasExtraSelectRef,
    mbName,
    setMbName,
    fileSelectedOption,
    setFileSelectedOption,
    fileOptionChangeHandler,
    columnSKUSelectedOption,
    setColumnSKUSelectedOptions,
    columnSKUOptionChangeHandler,
    columnCanalSelectedOption,
    setColumnCanalSelectedOptions,
    columnCanalOptionChangeHandler,
    ventaMonedaSelectedOption,
    setVentaMonedaSelectedOption,
    ventaMonedaOptionChangeHandler,
    ventaUnidadesSelectedOption,
    setVentaUnidadesSelectedOption,
    ventaUnidadesOptionChangeHandler,
    marcaSelectedOption,
    setMarcaSelectedOption,
    marcaOptionChangeHandler,
    setNivelAnalisisSelectedOption,
    periodicidadSelectedOption,
    setPeriodicidadSelectedOption,
    periodicidadOptionChangeHandler,
    columnaTransaccionSelectedOption,
    setColumnaTransaccionSelectedOption,
    columnaTransaccionOptionChangeHandler,
    columnasExtraSelectedOptions,
    setColumnasExtraSelectedOptions,
    columnasExtraOptionsChangeHandler,
  } = useMarketBasketConfigProvider();
  const configMode = localStorage.getItem("configMode");
  const [openAdvancedParametersModal, handleToggleAdvancedParametersModal] =
    useDialog();
  const [otherFontModal, toggleOtherFontModal] = useDialog();

  /******************************* Seteo inicial de data ***********************************/

  useEffect(() => {
    if (configMode === "EDIT" || configMode === "COPY") {
      configMode === "EDIT" && setMbName(marketBasketElement?.nombre);
      configMode === "COPY" &&
        setMbName(`${marketBasketElement?.nombre}_copia`);
      setFileSelectedOption(marketBasketElement?.archivo);
      setColumnSKUSelectedOptions(marketBasketElement?.columna_SKU);
      setColumnCanalSelectedOptions(marketBasketElement?.canal);
      setVentaMonedaSelectedOption(marketBasketElement?.venta_moneda);
      setVentaUnidadesSelectedOption(marketBasketElement?.venta_unidades);
      setMarcaSelectedOption(marketBasketElement?.marca);
      setNivelAnalisisSelectedOption(marketBasketElement?.nivel_de_analisis);
      setPeriodicidadSelectedOption(marketBasketElement?.periodicidad);
      setColumnaTransaccionSelectedOption(
        marketBasketElement?.columna_transaccion
      );
      setColumnasExtraSelectedOptions(marketBasketElement?.columnas_extra);
    }
  }, [marketBasketElement?.id]);

  /************************************* Validaciones ************************************/

  useEffect(() => {
    if (mbName === "") {
      setMessage({ type: "error", text: t("general.requiredField") });
    } else {
      setMessage({ type: "", text: "" });
    }
    handleElementRepeatedNameWithObject(
      t,
      mbName,
      dataAnalysisMBs,
      marketBasketElement?.id,
      configMode,
      setMessage
    );
  }, [mbName]);

  /************************************* Seteos ***********************************************/

  const handleSetElement = (element: any) => {
    setMarketBasketElement((prev) => {
      return {
        ...prev,
        archivos_merge: [...prev.archivos_merge, element],
      };
    });
  };

  const totalBases = () => {
    const updatedBases =
      marketBasketElement?.archivos_merge &&
      marketBasketElement.archivos_merge.length
        ? [...marketBasketElement.archivos_merge]
        : [];
    if (fileSelectedOption) {
      updatedBases.push({ archivo: fileSelectedOption });
    }
    return updatedBases;
  };

  return (
    <>
      <FormLayout width="48%" boxHeight="100%" padding="10px 25px 140px 25px">
        <TextField
          type="text"
          label={t("general.name")}
          variant="outlined"
          fullWidth
          value={mbName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setMbName(event.target.value);
          }}
          error={message.text !== "" || mbName === ""}
          helperText={message.text}
          FormHelperTextProps={{ sx: form_helper_text_styles }}
          size="small"
          required={true}
        />
        <FormSelectLayout
          title={t("marketBasket.config.fileOrAnalysisWithInformation")}
          required={true}
        >
          <Stack sx={row_sb_100_styles}>
            <Box width={"70%"}>
              <Select
                reference={fileSelectRef}
                styles={selectStyles(fileSelectedOption)}
                options={fileOptions(dataGetFileColumnsType)}
                onChange={fileOptionChangeHandler}
                closeMenuOnSelect
                placeholder={t("general.selectFile")}
                defaultValue={marketBasketElement?.archivo}
                isClearable
              />
              
            </Box>
            <CustomButton
              title={t("marketBasket.config.addFont")}
              color="blue-greeny"
              onClick={() => toggleOtherFontModal()}
              type="button"
              margin={false}
              disabled={!fileSelectedOption}
            />
          </Stack>
          <Stack sx={warning_text_container}>
                <WarningAmberIcon sx={warning_icon_styles} />
                {t("marketBasket.config.warningText")}
              </Stack>
          <MergeModal
            open={otherFontModal}
            handleClose={toggleOtherFontModal}
            base={undefined}
            dataGetFileColumns={dataGetFileColumnsType}
            previousfileSelectedOption={fileSelectedOption}
            handleSetElement={handleSetElement}
            bases={totalBases()}
          />
        </FormSelectLayout>
        {marketBasketElement?.archivos_merge &&
          marketBasketElement?.archivos_merge.length > 0 && (
            <FormSelectLayout title={t("marketBasket.config.otherFiles")}>
              <Grid
                width="100%"
                container
                spacing={1}
                sx={{ ml: 0, mt: 1, mb: 1 }}
              >
                {marketBasketElement?.archivos_merge?.map((file) => (
                  <MergeRow
                    key={file.archivo.value}
                    row={file}
                    marketBasketElement={marketBasketElement}
                    setMarketBasketElement={setMarketBasketElement}
                  />
                ))}
              </Grid>
            </FormSelectLayout>
          )}

        <FormSelectLayout
          title={t("marketBasket.skuColumn")}
          required={true}         
        >
          <Select
            reference={columnsSKUSelectRef}
            styles={selectStyles(columnSKUSelectedOption)}
            options={totalColumnOptions}
            onChange={columnSKUOptionChangeHandler}
            closeMenuOnSelect={true}
            placeholder={t("general.chooseColumn")}
            defaultValue={
              marketBasketElement &&
              marketBasketElement?.columna_SKU &&
              marketBasketElement?.columna_SKU
            }
            isClearable
          />
        </FormSelectLayout>
        <FormSelectLayout title={t("marketBasket.channel")} required={true}>
          <Select
            reference={columnCanalSelectRef}
            styles={selectStyles(columnCanalSelectedOption)}
            options={totalColumnOptions}
            onChange={columnCanalOptionChangeHandler}
            closeMenuOnSelect={true}
            placeholder={t("general.chooseColumn")}
            defaultValue={
              marketBasketElement &&
              marketBasketElement?.canal &&
              marketBasketElement?.canal
            }
            isClearable
          />
        </FormSelectLayout>
        <FormSelectLayout
          title={t("marketBasket.currencySales")}
          required={true}
        >
          <Select
            reference={ventaMonedaSelectRef}
            styles={selectStyles(ventaMonedaSelectedOption)}
            options={totalColumnOptions}
            onChange={ventaMonedaOptionChangeHandler}
            closeMenuOnSelect={true}
            placeholder={t("general.chooseColumn")}
            defaultValue={marketBasketElement?.venta_moneda}
            isClearable
          />
        </FormSelectLayout>
        <FormSelectLayout title={t("marketBasket.unitSales")} required={true}>
          <Select
            reference={ventaUnidadesSelectRef}
            styles={selectStyles(ventaUnidadesSelectedOption)}
            options={totalColumnOptions}
            onChange={ventaUnidadesOptionChangeHandler}
            closeMenuOnSelect={true}
            placeholder={t("general.chooseColumn")}
            defaultValue={marketBasketElement?.venta_unidades}
            isClearable
          />
        </FormSelectLayout>
      </FormLayout>

      <FormLayout width="48%" boxHeight="100%" boxJustifyContent="flex-Start" >
        <FormSelectLayout title={t("marketBasket.brand")} required={false}>
          <Select
            reference={marcaSelectRef}
            styles={optionalSelectStyles(marcaSelectedOption)}
            options={totalColumnOptions}
            onChange={marcaOptionChangeHandler}
            closeMenuOnSelect={true}
            placeholder={t("general.chooseColumn")}
            defaultValue={marketBasketElement?.marca}
            isClearable
          />
        </FormSelectLayout>
        <FormSelectLayout
          title={t("marketBasket.analysisLevel")}
          required={true}
        >
          <AnalysisLevel
            defaultValue={marketBasketElement.tipo_nivel_de_analisis}
          />
        </FormSelectLayout>
        <FormSelectLayout title={t("marketBasket.periodicity")} required={true}>
          <Select
            reference={periodicidadSelectRef}
            styles={selectStyles(periodicidadSelectedOption)}
            options={periodicityOptions(t)}
            onChange={periodicidadOptionChangeHandler}
            closeMenuOnSelect={true}
            placeholder={t("general.chooseColumn")}
            defaultValue={marketBasketElement?.periodicidad}
            isClearable
          />
        </FormSelectLayout>
        {periodicidadSelectedOption?.value === "no_consolidar" && (
          <FormSelectLayout
            title={t("marketBasket.transactionIdentifierColumn")}
            required={true}
          >
            <Select
              reference={columnaTransaccionSelectRef}
              styles={selectStyles(columnaTransaccionSelectedOption)}
              options={totalColumnOptions}
              onChange={columnaTransaccionOptionChangeHandler}
              closeMenuOnSelect={true}
              placeholder={t("general.chooseColumn")}
              defaultValue={marketBasketElement?.columna_transaccion}
              isClearable
            />
          </FormSelectLayout>
        )}
        {dataGetFileColumnsType?.find(
          (file) => file.id === fileSelectedOption?.value
        )?.tipo === "TEMPLATE" && (
          <FormSelectLayout
            title={t("marketBasket.extraColumnsForAnalysis")}
            required={false}
          >
            <Select
              reference={columnasExtraSelectRef}
              styles={optionalSelectStyles(columnasExtraSelectedOptions)}
              options={totalColumnOptions}
              onChange={columnasExtraOptionsChangeHandler}
              closeMenuOnSelect={false}
              placeholder={t("general.chooseColumn")}
              defaultValue={marketBasketElement?.columnas_extra}
              isClearable
              isMulti
            />
          </FormSelectLayout>
        )}
        <Stack sx={right_bottom_button_container_styles}>
          <Button
            color="primary"
            onClick={() => handleToggleAdvancedParametersModal()}
            sx={right_bottom_button_styles}
          >
            {t('marketBasket.advancedParameters')}
          </Button>
        </Stack>
        <Dialog
          open={openAdvancedParametersModal}
          handleClose={handleToggleAdvancedParametersModal}
          title={t("marketBasket.advancedParametersOfAlgoritm")}
          maxWidth={"sm"}
        >
          <Stack sx={{ minHeight: "200px" }}>
            <AdvancedParametersModal
              handleToggleModal={handleToggleAdvancedParametersModal}
            />
          </Stack>
        </Dialog>
      </FormLayout>
    </>
  );
};

export default FormStep1;
