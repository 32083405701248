import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import useLanguage from "../../context/LanguageProvider";
interface DeleteIconProps {
  tooltipPlacement?: "top" | "bottom" | "left" | "right";
  onClick: (ref1?: any, ref2?: any) => void;
  ref1?: any;
  ref2?: any;
  tooltipText?: string;
  color?: string;
  fill?: string;
}

/* export interface DeleteIconProps {
  tooltipPlacement:
    | "left"
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined;
  onClick: (ref1?: string | number, ref2?: string | number) => void;
  ref1?: string | number;
  ref2?: string | number;
  tooltipText?: string;
} */

const Delete = ({
  tooltipPlacement,
  onClick,
  ref1,
  ref2,
  tooltipText,
  color,
  fill
}: DeleteIconProps) => {
  const { t } = useLanguage();
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={tooltipText ? tooltipText : t("icons.delete")}
      placement={tooltipPlacement}
    >
      <DeleteIcon
        onClick={() => onClick(ref1, ref2)}
        sx={{
          color: color ? color : "var(--text-info)",
          padding: "0",
          margin: "0",
          "& :hover": { color: "#F90D4A", cursor: "pointer" },
          textAlign: "center",
          fill: fill ? fill : "var(--text-info)",
        }}
      />
    </Tooltip>
  );
};

export default Delete;
