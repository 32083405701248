import { Grid, Stack, Typography } from "@mui/material";
import Dialog from "../../dialog/Dialog";
import FormSelectLayout from "../../forms/FormSelectLayout";
import { Button } from "../../buttons";
import Select from "../../forms/Select";
import useLanguage from "../../../context/LanguageProvider";
import useSingleSelect from "../../../hooks/useSingleSelect";
import { useEffect, useRef, useState } from "react";
import { selectStyles } from "../../criterias/configCriteria/select.styles";
import { useApiQuery } from "../../../hooks/useApiQuery";
import {
  GET_ANALYSIS_URL,
  GET_SALES_FORECAST_URL,
  GET_SIMULATION_INFORMATION,
} from "../../../api/axios";
import useApi from "../../../hooks/useApi";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import {
  buscarPorId,
  buscarPorLabel,
  isOptionInMedidas,
  isOptionInOptions,
  prepareColumnsToUseOptions,
} from "../utils";
import InfoIcon from "@mui/icons-material/Info";
import { IdOption } from "../../criterias/configCriteria/types";
import PrincipalColumnsSelects from "../PrincipalColumnsSelects";
import { ConfirmationModal } from "../../dialog";
import useDialog from "../../../hooks/useDialog";
import { SelectWithAllOptions } from "../../forms/SelectWithAllOptions";

const quantityPeriodsToForecastOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
];
interface PrincipalColumns {
  informacion: string | number;
  columna: IdOption | undefined;
  is_medida: boolean; // tipo_valor: "medida" | "columna"
  valor?: string;
}
interface NewSalesForecastModalProps {
  open: boolean;
  handleClose: () => void;
  dataGetAnalysis: any[];
  dataToShow: any;
  refetchSalesForecast: any;
  setDataToShow: (param: undefined) => void;
  dataGetSalesForecast: any;
}

const NewSalesForecastModal = ({
  open,
  handleClose,
  dataGetAnalysis,
  dataToShow,
  refetchSalesForecast,
  setDataToShow,
  dataGetSalesForecast,
}: NewSalesForecastModalProps) => {
  const { t } = useLanguage();
  const analysisRef: any = useRef(null);
  const periodToIniciateRef: any = useRef(null);
  const periodsToForecastRef: any = useRef(null);
  const measuresRef: any = useRef(null);
  const [informations, setInformations] = useState<PrincipalColumns[]>([]);
  const configMode = localStorage.getItem("configMode") || undefined;
  const [
    openEditForecastConfirmationModal,
    handleToggleEditForecastConfirmationModal,
    setOpenEditForecastConfirmationModal,
  ] = useDialog();

  /********************** CARGA Y OBTENCIÓN DE DATOS *****************************/

  const analysisChange = () => {
    setInformations([]);
  };

  const [
    analysisSelectedOption,
    setAnalysisSelectedOptions,
    analysisOptionsChangeHandler,
  ] = useSingleSelect(
    analysisChange,
    dataToShow && { value: dataToShow.analisis_id, label: dataToShow.analisis }
  );

  const [measuresSelectedOptions, setMeasureselectedOptions] = useState([]);

  const [
    periodsToForecast,
    setPeriodsToForecast,
    periodsToForecastHandlerChange,
  ] = useSingleSelect(
    undefined,
    dataToShow && {
      value: dataToShow?.cantidad_periodos,
      label: dataToShow?.cantidad_periodos,
    }
  );

  const [periodToIniciate, setPeriodToIniciate, periodToIniciateHandlerChange] =
    useSingleSelect(
      undefined,
      dataToShow && {
        value: dataToShow?.periodo_inicial,
        label: dataToShow?.periodo_inicial,
      }
    );

  const {
    isLoading: isLoadingSimulationsInformation,
    data: dataGetSimulationsInformation,
  } = useApiQuery(GET_SIMULATION_INFORMATION, true, undefined);

  const onSuccessPostSalesForecast = () => {
    closeAndClean();
    refetchSalesForecast();
  };

  const { isLoading: isLoadingPostSalesForecast, callApi: postSalesForecast } =
    useApi(
      "",
      "POST",
      t("delta.postSalesForecast.codes"),
      undefined,
      onSuccessPostSalesForecast,
      undefined,
      false
    );

  const { isLoading: isLoadingPutSalesForecast, callApi: putSalesForecast } =
    useApi(
      "",
      "PUT",
      t("delta.putSalesForecast.codes"),
      undefined,
      onSuccessPostSalesForecast,
      undefined,
      false
    );

  const {
    isLoading: isLoadingGetAnalysisById,
    data: dataGetAnalysisById,
    callApi: getAnalysisById,
  } = useApi(
    undefined,
    "GET",
    t("dataModel.getAnalysis.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if (analysisSelectedOption) {
      getAnalysisById(
        GET_ANALYSIS_URL(Number(analysisSelectedOption.value), true)
      );
    }
  }, [analysisSelectedOption]);

  useEffect(() => {
    dataToShow &&
      setAnalysisSelectedOptions({
        value: dataToShow.analisis_id,
        label: dataToShow.analisis,
      });
  }, [dataToShow]);

  useEffect(() => {
    dataToShow && dataToShow.periodo_inicial
      ? setPeriodToIniciate(dataToShow.periodo_inicial)
      : dataGetAnalysisById &&
        setPeriodToIniciate(dataGetAnalysisById.periodos_forecast[0]);

    dataToShow && dataToShow.medidas_ventas
      ? setMeasureselectedOptions(dataToShow.medidas_ventas)
      : dataGetAnalysisById &&
        setMeasureselectedOptions(
          dataGetAnalysisById?.medidas
            ?.filter(
              (medida: any) => !medida.bucket_agrupador && !medida.medidas
            )
            ?.map((medida: any) => ({
              value: medida.id,
              label: medida.nombre,
            }))
        );
  }, [dataToShow, dataGetAnalysisById]);

  useEffect(() => {
    if (dataToShow && dataGetAnalysisById) {
      const principalColumns = dataToShow.columnas_principales.map(
        (columna) => {
          if (columna.is_medida && columna.columna) {
            const medidaEncontrada =
              dataGetAnalysisById &&
              buscarPorId(columna.columna, dataGetAnalysisById.medidas);
            if (medidaEncontrada) {
              return {
                ...columna,
                columna: {
                  value: medidaEncontrada.id,
                  label: medidaEncontrada.nombre,
                },
              };
            }
          } else if (!columna.is_medida) {
            const agrupationColumnEncontrada =
              dataGetAnalysisById &&
              buscarPorLabel(
                columna.columna,
                dataGetAnalysisById.agrupacion.columnas_a_usar
              );
            if (agrupationColumnEncontrada) {
              return {
                ...columna,
                columna: {
                  value: agrupationColumnEncontrada.value,
                  label: agrupationColumnEncontrada.label,
                },
              };
            }
          } else if (columna.valor) {
            return {
              ...columna,
              valor: columna.valor,
            };
          }
          return columna;
        }
      );
      setInformations(principalColumns);
      setPeriodsToForecast({
        value: dataToShow?.cantidad_periodos,
        label: dataToShow?.cantidad_periodos,
      });
    }
  }, [dataToShow, dataGetAnalysisById]);

  /********************** MANEJO DE FUNCIONES *****************************/

  const setPrincipalColumnsReferences = (
    column_id: string | number,
    reference: IdOption | undefined,
    is_medida: boolean
  ) => {
    const config = {
      informacion: column_id,
      columna: reference,
      is_medida: is_medida,
    };

    // Verifica si la información ya existe en el estado
    const existingIndex = informations.findIndex(
      (principalColumns) => principalColumns.informacion === column_id
    );

    // Crea una copia mutable del estado actual
    const updatedInformations = [...informations];

    // Si la información ya existe, reemplázala; de lo contrario, agrégala
    if (existingIndex !== -1) {
      updatedInformations[existingIndex] = config;
    } else {
      updatedInformations.push(config);
    }
    // Actualiza el estado con la nueva información
    setInformations(updatedInformations);
  };

  const columnsSelects = (columnsArray: any[]) => {
    return columnsArray.map((column: any) => {
      const referenceExists = informations.find(
        (principalColumns) => principalColumns.informacion === column.id
      );
      const opcionesFiltradas =
        dataGetAnalysisById &&
        dataGetAnalysisById &&
        prepareColumnsToUseOptions(
          dataGetAnalysisById.medidas,
          t,
          dataGetAnalysisById?.agrupacion?.columnas_a_usar?.map((column) => {
            return {
              value: column.value,
              label: column.label,
            };
          })
        ).map((categoria) => {
          const opcionesFiltradasCategoria = categoria.options?.filter(
            (opcion) => {
              return !informations.some((principalColumna) => {
                // Comparar por valor o label según la estructura de tus datos
                if (principalColumna.columna) {
                  return (
                    principalColumna.columna.value === opcion.value ||
                    principalColumna.columna.label === opcion.label
                  );
                }
              });
            }
          );
          return {
            label: categoria.label,
            options: opcionesFiltradasCategoria,
          };
        });

      return (
        <PrincipalColumnsSelects
          key={`${column.id}`}
          defaultValue={referenceExists ? referenceExists.columna : undefined}
        >
          {({
            principalColumnFilterSelectedOptions,
            principalColumnFilterOptionsChangeHandler,
            setPrincipalColumnSelectedOptions,
          }) => {
            useEffect(() => {
              if (principalColumnFilterSelectedOptions) {
                if (
                  isOptionInMedidas(
                    principalColumnFilterSelectedOptions,
                    dataGetAnalysisById?.medidas
                  )
                ) {
                  setPrincipalColumnsReferences(
                    column.id,
                    principalColumnFilterSelectedOptions,
                    true
                  );
                } else if (
                  isOptionInOptions(
                    principalColumnFilterSelectedOptions,
                    dataGetAnalysisById?.agrupacion?.columnas_a_usar
                  )
                ) {
                  setPrincipalColumnsReferences(
                    column.id,
                    principalColumnFilterSelectedOptions,
                    false
                  );
                }
              } else {
                const principalColumnsFiltered = informations?.filter(
                  (principalColumns) => {
                    return principalColumns.informacion !== column.id;
                  }
                );
                const dependencyToDelete =
                  dataGetSimulationsInformation &&
                  dataGetSimulationsInformation?.find(
                    (info) => info.id === column.id
                  )?.nombre;

                const idsDepende =
                  dependencyToDelete &&
                  dataGetSimulationsInformation &&
                  dataGetSimulationsInformation
                    ?.filter((item) => item.depende === dependencyToDelete)
                    ?.map((item) => item.id);

                const filteredData = principalColumnsFiltered?.filter(
                  (item) => !idsDepende.includes(item.informacion)
                );
                setInformations(
                  filteredData ? filteredData : principalColumnsFiltered
                );
              }
            }, [principalColumnFilterSelectedOptions]);

            useEffect(() => {
              if (referenceExists) {
                if (typeof referenceExists.columna === "string") {
                  setPrincipalColumnSelectedOptions(referenceExists.columna);
                } else {
                  const medidaEncontrada = dataGetAnalysisById.medidas.find(
                    (medida) => medida.id === referenceExists.columna
                  );
                  if (medidaEncontrada) {
                    setPrincipalColumnSelectedOptions(referenceExists.columna);
                  }
                }
              } else {
                setPrincipalColumnSelectedOptions([]);
              }
            }, [referenceExists]);

            return (
              <Grid
                key={column.nombre}
                item
                xs={6}
                sx={{
                  width: "100px",
                }}
              >
                <FormSelectLayout
                  required={column.obligatorio_sales_forecast}
                  title={column.nombre}
                >
                  <Select
                    styles={{
                      ...selectStyles(analysisSelectedOption),
                      control: () => ({
                        display: "flex",
                        borderRadius: "5px",
                        border: column.obligatorio_sales_forecast
                          ? referenceExists
                            ? "1px solid #d7d7d7"
                            : "1px solid red"
                          : "1px solid #d7d7d7",
                      }),
                    }}
                    options={opcionesFiltradas && opcionesFiltradas}
                    className="cc_select"
                    name="analysisToUse"
                    onChange={principalColumnFilterOptionsChangeHandler}
                    closeMenuOnSelect
                    placeholder={t(
                      "simulatorTexts.simulationSchema.step1NewSimulationSchema.chooseReferenceColumn"
                    )}
                    isClearable
                    value={
                      referenceExists
                        ? referenceExists.columna
                        : principalColumnFilterSelectedOptions
                    }
                  />
                </FormSelectLayout>
              </Grid>
            );
          }}
        </PrincipalColumnsSelects>
      );
    });
  };

  const closeAndClean = () => {
    localStorage.removeItem("configMode");
    handleClose();
    setOpenEditForecastConfirmationModal(false);
    analysisRef.current?.clearValue();
    setAnalysisSelectedOptions(undefined);
    periodToIniciateRef.current?.clearValue();
    setPeriodToIniciate(undefined);
    periodsToForecastRef.current?.clearValue();
    setPeriodsToForecast(undefined);
    measuresRef.current?.clearValue();
    setMeasureselectedOptions([]);
    setDataToShow(undefined);
  };

  const disabledAddButton = () => {
    if (
      !periodsToForecast ||
      !analysisSelectedOption ||
      (dataGetSimulationsInformation &&
        !dataGetSimulationsInformation
          ?.filter(
            (filter) =>
              filter?.obligatorio_sales_forecast && filter?.sales_forecast
          )
          .every((info) =>
            informations.some((column) => column.informacion === info.id)
          )) ||
      !periodToIniciate ||
      !measuresSelectedOptions ||
      measuresSelectedOptions.length < 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleFinish = () => {
    const uniqueInfos = new Set();

    // Filtrar la lista original para eliminar duplicados
    const uniquesInfosFiltered = informations.filter((info) => {
      if (uniqueInfos.has(info.informacion)) {
        return false; // Si el valor ya está en el Set, no incluirlo en el array filtrado
      } else {
        uniqueInfos.add(info.informacion); // Si no está en el Set, agregarlo y mantenerlo en el array filtrado
        return true;
      }
    });

    const filteredSimulations = dataGetSimulationsInformation.filter(
      (item) => item.sales_forecast
    );

    // Paso 2: Crear una lista de ids de los elementos filtrados
    const validIds = filteredSimulations.map((item) => item.id);

    // Paso 3: Filtrar informations basado en los ids válidos
    const filteredInformations =
      uniquesInfosFiltered.filter((info) =>
        validIds.includes(info.informacion)
      ) || [];

    const newConfig = {
      analisis: analysisSelectedOption?.value,
      columnas_principales: filteredInformations.map((column) => {
        return {
          informacion: column.informacion,
          columna: column.is_medida
            ? column?.columna?.value
            : column?.columna?.label,
          is_medida: column.is_medida,
        };
      }),
      cantidad_periodos: periodsToForecast && periodsToForecast.value,
      periodo_inicial: periodToIniciate && periodToIniciate.value,
      medidas_ventas: measuresSelectedOptions.map(
        (medida: IdOption) => medida.value
      ),
    };
    if (dataToShow) {
      putSalesForecast(GET_SALES_FORECAST_URL + dataToShow.id, newConfig);
    } else {
      postSalesForecast(GET_SALES_FORECAST_URL, newConfig);
    }
  };

  return (
    <Dialog
      open={open}
      handleClose={closeAndClean}
      title={`${dataToShow ? t("general.edit") : t("general.create")} ${t(
        "simulatorTexts.salesForecast.newSalesForecastModal.salesForecast"
      )}`}
      maxWidth="xl"
      actions={
        <>
          <Button
            title={t("simulatorTexts.assignVariabilityModal.cancel")}
            color="grey"
            type="button"
            onClick={closeAndClean}
          />
          <Button
            title={dataToShow ? t("general.edit") : t("general.create")}
            color="blue"
            type="button"
            disabled={disabledAddButton()}
            onClick={
              configMode === "EDIT"
                ? handleToggleEditForecastConfirmationModal
                : handleFinish
            }
          />
        </>
      }
    >
      <SimpleBackdrop
        open={
          isLoadingGetAnalysisById ||
          isLoadingSimulationsInformation ||
          isLoadingPostSalesForecast ||
          isLoadingPutSalesForecast
        }
        message={t("general.loading")}
      />
      <Stack
        sx={{
          minWidth: "700px",
          minHeight: "350px",
          flexDirection: "column",
          gap: 2,
          p: "10px 30px 20px 30px",
        }}
      >
        <FormSelectLayout
          title={t("dataModelText.analysisExecution.analysis")}
          required
        >
          <Select
            reference={analysisRef}
            styles={selectStyles(analysisSelectedOption)}
            options={
              configMode === "EDIT"
                ? dataGetAnalysis &&
                  dataGetAnalysis.map((analysis) => {
                    return {
                      value: analysis.analisis_id,
                      label: analysis.analisis,
                    };
                  })
                : dataGetAnalysis &&
                  dataGetSalesForecast &&
                  dataGetAnalysis
                    .map((analysis) => {
                      return {
                        value: analysis.analisis_id,
                        label: analysis.analisis,
                      };
                    })
                    .filter(
                      (objeto) =>
                        !dataGetSalesForecast
                          .map((salesForecast) => salesForecast.analisis_id)
                          ?.includes(objeto.value)
                    )
            }
            form="cc_form"
            className="cc_select"
            name="análisis"
            onChange={analysisOptionsChangeHandler}
            placeholder={t(
              "dataModelText.groupManagement.step1.newBaseFile.chooseAnalysis"
            )}
            closeMenuOnSelect={true}
            defaultValue={
              dataToShow && {
                value: dataToShow.analisis_id,
                label: dataToShow.analisis,
              }
            }
            data-testid="select_column_component"
            isClearable
          />
        </FormSelectLayout>
        {!analysisSelectedOption && (
          <Stack
            sx={{
              width: "100%",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <InfoIcon color="primary" fontSize="medium" />
            <Typography
              style={{ color: "var(--text-main)", fontWeight: "600" }}
            >
              {t(
                "simulatorTexts.salesForecast.newSalesForecastModal.cannotRepitAnalysis"
              )}
            </Typography>
          </Stack>
        )}
        {analysisSelectedOption && dataGetAnalysisById && (
          <Grid
            container
            spacing={2}
            sx={{
              p: 2,
              pr: 3,
              pl: 3,
              height: "100%",
              width: "700px",
            }}
          >
            <Grid item xs={6}>
              <FormSelectLayout
                title={t(
                  "deltaTexts.salesForecast.newSalesForecastModal.initialPeriod"
                )}
                required
              >
                <Select
                  reference={periodToIniciateRef}
                  styles={selectStyles(periodToIniciate)}
                  options={
                    dataGetAnalysisById && dataGetAnalysisById.periodos_forecast
                  }
                  form="cc_form"
                  className="cc_select"
                  name="periods"
                  onChange={periodToIniciateHandlerChange}
                  placeholder={t(
                    "deltaTexts.salesForecast.newSalesForecastModal.initialPeriod"
                  )}
                  closeMenuOnSelect={true}
                  defaultValue={
                    dataToShow && dataToShow.periodo_inicial
                      ? dataToShow.periodo_inicial
                      : dataGetAnalysisById &&
                        dataGetAnalysisById.periodos_forecast[0]
                  }
                  data-testid="select_column_component"
                  isClearable
                />
              </FormSelectLayout>
            </Grid>
            <Grid item xs={6}>
              <FormSelectLayout
                title={t(
                  "deltaTexts.salesForecast.newSalesForecastModal.periodsToForecast"
                )}
                required
              >
                <Select
                  reference={periodsToForecastRef}
                  styles={selectStyles(periodsToForecast)}
                  options={quantityPeriodsToForecastOptions}
                  form="cc_form"
                  className="cc_select"
                  name="periods"
                  onChange={periodsToForecastHandlerChange}
                  placeholder={t(
                    "deltaTexts.salesForecast.newSalesForecastModal.choosePeriods"
                  )}
                  closeMenuOnSelect={true}
                  defaultValue={
                    dataToShow && {
                      value: dataToShow.cantidad_periodos,
                      label: dataToShow.cantidad_periodos,
                    }
                  }
                  data-testid="select_column_component"
                  isClearable
                />
              </FormSelectLayout>
            </Grid>
            <Grid item xs={12}>
              <FormSelectLayout
                title={t("deltaTexts.salesForecast.newSalesForecastModal.salesMeasuresToForecast")}
                required
              >
                <SelectWithAllOptions
                  options={
                    dataGetAnalysisById &&
                    dataGetAnalysisById?.medidas
                      ?.filter(
                        (medida: any) =>
                          !medida.bucket_agrupador && !medida.medidas
                      )
                      ?.map((medida: any) => ({
                        value: medida.id,
                        label: medida.nombre,
                      }))
                  }
                  value={measuresSelectedOptions}
                  onChange={setMeasureselectedOptions}
                  labelSelectAll={t(
                    "deltaTexts.salesForecast.newSalesForecastModal.all"
                  )}
                  isDisabled={false}
                  placeholder={t("dataModelText.downloadReport.chooseMeasures")}
                  reference={measuresRef}
                />
              </FormSelectLayout>
            </Grid>
            {dataGetSimulationsInformation &&
              columnsSelects(
                dataGetSimulationsInformation
                  .sort(
                    (a, b) =>
                      b.obligatorio_sales_forecast -
                      a.obligatorio_sales_forecast
                  )
                  ?.filter((info) => info.sales_forecast)
              )}
          </Grid>
        )}
      </Stack>
      <ConfirmationModal
        open={openEditForecastConfirmationModal}
        handleClose={handleToggleEditForecastConfirmationModal}
        handleAccept={handleFinish}
        message={t(
          "deltaTexts.salesForecast.salesForecastAdmin.editSalesForecast"
        )}
        title={t("general.confirm")}
      >
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <InfoIcon color="primary" fontSize="medium" />
          <Typography style={{ color: "var(--text-main)", fontWeight: "600" }}>
            {t(
              "deltaTexts.salesForecast.salesForecastAdmin.deleteAssociatedSimulationsAndRules"
            )}
          </Typography>
        </Stack>
      </ConfirmationModal>
    </Dialog>
  );
};

export default NewSalesForecastModal;
