import ComponentLayout from "../layout/ComponentLayout";
import { IconButton, Tooltip, Stack, Box } from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { useContext, useEffect, useRef, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import MenuIcon from "@mui/icons-material/Menu";
import AddBoxIcon from "@mui/icons-material/AddBox";
import useLanguage from "../../context/LanguageProvider";
import useDialog from "../../hooks/useDialog";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import ChatBotProvider from "../../context/ChatBotProvider";
import { useApiQuery } from "../../hooks/useApiQuery";
import { GET_HISTORY_CHATBOT } from "../../api/axios";
import SideBarChatBot from "./sideBarChat/SideBarChatBot";
import ChatBotMessages from "./ChatBotMessages";
import InfoChatBotModal from "./InfoChatBotModal";
import { processDataChart } from "./utils";
import useApi from "../../hooks/useApi";
import { IdOption } from "../criterias/configCriteria/types";

const ChatBot = () => {
  const { t } = useLanguage();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [openInfoModal, toggleInfoModal] = useDialog();
  const { chatBotState, chatBotDispatch } =
    useContext<React.ContextType<typeof ChatBotProvider>>(ChatBotProvider);
  const [sidebarWidth, setSidebarWidth] = useState<number>(300);
  const messageEndRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedHistory, setSelectedHistory] = useState<
    IdOption | undefined
  >();

  /************************* CARGA Y OBTENCIÓN DE DATOS **************************************/

  const {
    data: dataGetChatsHistory,
    isLoading: isLoadingGetChatsHistory,
    refetch: refetchChatsHistory,
  } = useApiQuery(GET_HISTORY_CHATBOT, false);

  useEffect(() => {
    if (chatBotState.history.length < 1) {
      chatBotDispatch({ type: "NEW_CHAT" });
    }
  }, [chatBotState.history]);

  useEffect(() => {
    dataGetChatsHistory &&
      chatBotDispatch({ type: "SET_HISTORY", payload: dataGetChatsHistory });
  }, [dataGetChatsHistory]);

  useEffect(() => {
    if (chatBotState.messages.length > 1) {
      refetchChatsHistory();
    }
  }, [chatBotState.messages]);

  const onSuccessGetChatHistoryById = (data: any) => {
    if (data) {
      chatBotDispatch({ type: "NEW_CHAT" });
      let chatId;
      data.messages &&
        data.messages.map((message: any) => {
          const newUserMessage = {
            message: message.content.user,
            isSent: true,
          };

          chatBotDispatch({ type: "SET_MESSAGE", payload: newUserMessage });

          const dataChart =
            message.content.ia.grafico &&
            typeof message.content.ia.grafico === "string"
              ? processDataChart(message.content.ia.grafico)
              : message.content.ia.grafico;

          if (!chatId) {
            chatId = message.chat;
          }
          const newIaMessage = {
            data: message.content.ia,
            message_id: message.id,
            message: message.content.ia.message,
            isSent: false,
            useful: message.useful,
            table: message.content.ia.table && message.content.ia.table,
            chart_types:
              message.content.ia.chart_types && message.content.ia.chart_types,
            chart: dataChart && dataChart,
            is_valid: message.content.ia.grafico
              ? true
              : message.content.ia.is_valid,
            query: message.content.ia.query && message.content.ia.query,
          };
          chatBotDispatch({ type: "SET_MESSAGE", payload: newIaMessage });
        });
      chatBotDispatch({
        type: "SET_HISTORY_TITLE",
        payload: {
          value: chatId,
          label: data.title,
        },
      });
      if (messageEndRef.current) {
        messageEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const {
    isLoading: isLoadingGetChatHistoryById,
    callApi: getChatHistoryById,
    data: dataGetChatHistoryById,
  } = useApi(
    ``,
    "GET",
    undefined,
    undefined,
    onSuccessGetChatHistoryById,
    undefined,
    false
  );

  /************************* MANEJO DE FUNCIONES **************************************/

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const handleNewChat = () => {
    chatBotDispatch({ type: "NEW_CHAT" });
  };

  useEffect(() => {
    isSidebarOpen ? setSidebarWidth(300) : setSidebarWidth(0);
  }, [isSidebarOpen]);

  return (
    <ComponentLayout title={"PHITO"} icon={<SmartToyIcon />}>
      <SimpleBackdrop
        open={isLoadingGetChatsHistory || isLoadingGetChatHistoryById}
        message={t("general.loading")}
      />
      <Box
        sx={{
          width: "100%",
          position: "relative",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <Stack
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 10,
            backgroundColor: "white",
          }}
        >
          <Tooltip
            title={
              isSidebarOpen ? t("phibot.closeSideBar") : t("phibot.openSideBar")
            }
          >
            <IconButton
              size="small"
              onClick={() => toggleSidebar()}
              sx={{
                "&:hover": {
                  backgroundColor: "var(--light-grey)",
                },
              }}
            >
              <MenuIcon
                color={"primary"}
                sx={{
                  fontSize: "30px",
                }}
              />
            </IconButton>
          </Tooltip>
          {!isSidebarOpen && chatBotState.messages.length > 1 && (
            <Tooltip
              title={
                chatBotState.messages.length === 1 ? "" : t("phibot.newChat")
              }
            >
              <span>
                <IconButton
                  size="small"
                  onClick={handleNewChat}
                  sx={{
                    "&:hover": {
                      backgroundColor: "var(--light-grey)",
                    },
                  }}
                >
                  <AddBoxIcon
                    color={
                      chatBotState.messages.length === 1
                        ? "disabled"
                        : "primary"
                    }
                    sx={{ fontSize: "30px" }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Stack>
        <SideBarChatBot
          isSidebarOpen={isSidebarOpen}
          sidebarWidth={sidebarWidth}
          setSidebarWidth={setSidebarWidth}
          refetchChatsHistory={refetchChatsHistory}
          getChatHistoryById={getChatHistoryById}
          isLoadingMessage={isLoading}
          selectedHistory={selectedHistory}
          setSelectedHistory={setSelectedHistory}
        />
        <ChatBotMessages
          messageEndRef={messageEndRef}
          dataGetChatHistoryById={dataGetChatHistoryById}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          setSelectedHistory={setSelectedHistory}
          isSidebarOpen={isSidebarOpen}
        />
        <Tooltip title={t("general.information")}>
          <Stack
            sx={{
              position: "absolute",
              top: 10,
              right: 30,
              zIndex: 100,
            }}
          >
            <IconButton size="small" onClick={toggleInfoModal}>
              <InfoIcon
                color={"primary"}
                sx={{
                  fontSize: "35px",
                  transform: "scaleX(-1)",
                }}
              />
            </IconButton>
          </Stack>
        </Tooltip>
      </Box>
      <InfoChatBotModal toggle={toggleInfoModal} open={openInfoModal} />
    </ComponentLayout>
  );
};

export default ChatBot;
