import { AddButton } from "../../../../../buttons";
import { Button } from "../../../../../buttons";
import { GET_COLUMN_VALUES } from "../../../../../../api/axios";
import { getInformationColumn } from "../../../../utils";
import { IdOption } from "../../../../../criterias/configCriteria/types";
import { InputLabel } from "../../../../../forms";
import { selectStyles } from "../../../../../criterias/configCriteria/select.styles";
import { Stack, Typography, Grid } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import DeleteForever from "../../../../../icons/DeleteForever";
import Dialog from "../../../../../dialog/Dialog";
import FilterValueRows from "../../../../../dataModel/downloadReport/FilterValueRows";
import FormSelectLayout from "../../../../../forms/FormSelectLayout";
import Select from "../../../../../forms/Select";
import SimulationProvider from "../../../../../../context/SimulationProvider";
import useApi from "../../../../../../hooks/useApi";
import useLanguage from "../../../../../../context/LanguageProvider";
import useSingleSelect from "../../../../../../hooks/useSingleSelect";
import VirtualSelect from "../../../../../criterias/configCriteria/VirtualSelect";
interface ModifyCancelRouteModalProps {
  open: boolean;
  handleClose: () => void;
  configToEdit: any;
  informationsColumns: any;
  modificationTypes: any;
}

const ModifyModificationRouteModal = ({
  handleClose,
  open,
  configToEdit,
  informationsColumns,
  modificationTypes,
}: ModifyCancelRouteModalProps) => {
  const { t } = useLanguage();
  const [columnsValues, setColumnsValues] = useState<any>([]);
  const columnSelectedRef: any = useRef(null);
  const analysisId = localStorage.getItem("analysisId")
    ? localStorage.getItem("analysisId")
    : undefined;
  const [columnsOptions, setColumnsOptions] = useState<any>([]);
  const [valuesSelectedOptions, setValuesSelectedOptions] =
    useState<IdOption[]>();
  const [incomingValuesOptions, setIncomingValuesOptions] = useState<any[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[] | null>([]);
  const [isLoadingIncomingValuesOptions, setIsLoadingIncomingValuesOptions] =
    useState(false);
  const { simulationState, simulationDispatch } =
    useContext<React.ContextType<typeof SimulationProvider>>(
      SimulationProvider
    );
  const [columnModificationsTypes, setColumnModificationsTypes] = useState<
    string[]
  >([]);

  const valores =
    columnModificationsTypes &&
    columnModificationsTypes.map((columna) => {
      const valor =
        configToEdit && configToEdit.rutas && configToEdit?.rutas[0][columna];
      return { columna, valor };
    });

  /********************************* CARGA Y OBTENCIÓN DE DATOS *****************************/

  const onColumnChange = () => {
    setSelectedValues([]);
    setValuesSelectedOptions(undefined);
  };

  const [
    columnFilterSelectedOption,
    setColumnFilterSelectedOptions,
    columnFilterOptionsChangeHandler,
  ] = useSingleSelect(() => onColumnChange());

  const onSuccessIncomingValuesOptionsResponse = (data: any) => {
    setIncomingValuesOptions(data);
    setIsLoadingIncomingValuesOptions(false);
  };

  const { callApi: postGetIncomingValues } = useApi(
    "",
    "POST",
    undefined,
    undefined,
    onSuccessIncomingValuesOptionsResponse,
    undefined,
    false
  );

  useEffect(() => {
    if (columnFilterSelectedOption && analysisId) {
      setIsLoadingIncomingValuesOptions(true);
      const filterBody = {
        filtros: [],
      };
      postGetIncomingValues(
        GET_COLUMN_VALUES(
          Number(analysisId && analysisId),
          String(columnFilterSelectedOption?.label),
          "sales_forecast"
        ),
        filterBody
      );
    } else {
      setIncomingValuesOptions([]);
    }
  }, [columnFilterSelectedOption, analysisId]);

  useEffect(() => {
    if (modificationTypes && informationsColumns) {
      const options = modificationTypes.map((type) => {
        setColumnModificationsTypes((prevData) => [
          ...prevData,
          getInformationColumn(informationsColumns, type.nombre),
        ]);
        return {
          value: getInformationColumn(informationsColumns, type.nombre),
          label: getInformationColumn(informationsColumns, type.nombre),
        };
      });

      setColumnsOptions(
        options.filter((columnLabel) => {
          return !columnsValues.some(
            (valueObj) => valueObj.id === columnLabel.value
          );
        })
      );
    }
  }, [modificationTypes, columnsValues, informationsColumns]);

  /********************************* MANEJO DE FUNCIONES *****************************/

  const handleDeleteAllFilters = () => {
    setColumnsValues([]);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions([]);
    columnSelectedRef?.current?.clearValue();
    setSelectedValues([]);
  };

  const handleAddColumnValue = () => {
    if (!columnFilterSelectedOption) {
      // Si no hay una opción de columna seleccionada, no se puede agregar un filtro.
      return;
    }
    const newFilter = {
      id: columnFilterSelectedOption?.value,
      column: columnFilterSelectedOption,
      values:
        valuesSelectedOptions && valuesSelectedOptions.length > 0
          ? valuesSelectedOptions
          : undefined,
    };
    const updatedFilters = columnsValues
      ? [
          ...columnsValues.filter(
            (filter) => filter.column.value !== columnFilterSelectedOption.value
          ),
          newFilter,
        ]
      : [newFilter];
    setColumnsValues(updatedFilters);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions([]);
    setSelectedValues([]);
    columnSelectedRef.current.clearValue();
  };

  const handleFinish = () => {
    const modifiedConfigurationsWithOutEdited =
      simulationState.configurations.filter(
        (config) => config.id !== configToEdit.id
      );
    const modifiedConfigToEdit = {
      ...configToEdit,
      rutas: configToEdit.rutas.map((route: any) => {
        // Mapear sobre las columnas y actualizar los valores correspondientes en cada ruta
        return columnsValues.reduce(
          (updatedRoute: any, columnValue: any) => {
            return {
              ...updatedRoute,
              [columnValue.column.value]: columnValue.values[0].value,
            };
          },
          { ...route }
        ); // Mantener las otras propiedades de la ruta intactas
      }),
    };
    const newDataModifiedConfigurations = [
      ...modifiedConfigurationsWithOutEdited,
      modifiedConfigToEdit,
    ];
    simulationDispatch({
      type: "SET_CONFIGURATIONS",
      payload: newDataModifiedConfigurations,
    });
    setColumnsValues([]);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t(
        "simulatorTexts.modifiedRoutes.modifyModificationRouteModal.modifyRoutes"
      )}
      maxWidth="xl"
      actions={
        <>
          <Button
            title={t("simulatorTexts.assignVariabilityModal.cancel")}
            color="grey"
            type="button"
            onClick={handleClose}
          />
          <Button
            title={t(
              "simulatorTexts.canceledRoutes.modifyCancelRouteModal.modify"
            )}
            color="blue"
            type="button"
            onClick={handleFinish}
            disabled={columnsValues.length < 1}
          />
        </>
      }
    >
      <Grid
        container
        sx={{
          width: "900px",
          pl: "80px",
          pr: "80px",
        }}
      >
        {valores.length > 0 && (
          <>
            <Typography
              style={{ color: "var(--text-main)", fontWeight: "600" }}
            >
              {t(
                "deltaTexts.simulation.modifyModicationModal.previouslySelectedValues"
              )}
              :
            </Typography>
            <Stack
              sx={{
                width: "100%",
                justifyContent: "center",
                flexDirection: "row",
                pt: 2,
                pb: 1,
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "var(--text-main)",
                }}
              >
                {valores[0].columna}
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "var(--text-main)",
                }}
              >
                {valores[0].valor}
              </Typography>
            </Stack>
          </>
        )}
        <Stack
          sx={{
            flexDirection: "row",
            width: "900px",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            pt: 2,
            pb: 2,
          }}
        >
          {columnsValues && columnsValues.length > 0 && (
            <Stack
              sx={{
                position: "absolute",
                left: -65,
              }}
            >
              <FormSelectLayout>
                <InputLabel title="" transparent={true}>
                  <DeleteForever
                    tooltipPlacement="bottom"
                    onClick={handleDeleteAllFilters}
                    width="30px"
                  />
                </InputLabel>
              </FormSelectLayout>
            </Stack>
          )}
          <InputLabel title={t("configCriteria.step1.column")}>
            <Select
              reference={columnSelectedRef}
              styles={
                columnsValues && columnsValues.length < 1
                  ? selectStyles(columnsValues)
                  : undefined
              }
              options={columnsOptions && columnsOptions}
              className="cc_select"
              name="column"
              onChange={columnFilterOptionsChangeHandler}
              closeMenuOnSelect
              placeholder={t("configCriteria.step1.selectColumn")}
              defaultValue={columnFilterSelectedOption}
              isClearable
            />
          </InputLabel>
          <InputLabel title={t("configCriteria.step2.values")}>
            <VirtualSelect
              incomingValuesOptions={
                valores.length > 0 &&
                selectedValues !== null &&
                incomingValuesOptions?.length > 0
                  ? incomingValuesOptions
                      .filter((value) => !selectedValues.includes(value))
                      .filter((valor) => valor !== valores[0].valor)
                  : []
              }
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              setValuesSelectedOptions={setValuesSelectedOptions}
              isLoadingIncomingValuesOptions={isLoadingIncomingValuesOptions}
              selectOneOption={true}
              isDisabled={incomingValuesOptions.length < 1}
            />
          </InputLabel>
          <Stack
            sx={{
              right: -70,
              position: "absolute",
            }}
          >
            <InputLabel title="" transparent={true}>
              <AddButton
                onClick={handleAddColumnValue}
                disabled={
                  (selectedValues !== null && selectedValues.length < 1) ||
                  (!columnFilterSelectedOption &&
                    columnFilterSelectedOption === null)
                }
              />
            </InputLabel>
          </Stack>
        </Stack>
        <Stack
          sx={{
            maxHeight: "100px",
            overflowY: "auto",
            width: "100%",
            mt: 1,
            mb: -1,
          }}
        >
          <FilterValueRows
            filters={columnsValues}
            setFilters={setColumnsValues}
          />
        </Stack>
        {columnsValues?.length === 0 && (
          <Stack
            sx={{
              width: "95%",
              height: "7vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "700", color: "var(--text-info)" }}
            >
              {t(
                "simulatorTexts.modifiedRoutes.step3NewModifyRouteModal.noValuesYet"
              )}
            </Typography>
          </Stack>
        )}
      </Grid>
    </Dialog>
  );
};

export default ModifyModificationRouteModal;
