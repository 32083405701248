
import { add_new_elements_button } from "../../../../styles/app-styles";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useLanguage from "../../../../context/LanguageProvider";

interface AddIterationButtonProps {
  onClick: () => void;
  isDisabled: boolean;
}

const AddIterationButton = ({
  onClick,
  isDisabled,
}: AddIterationButtonProps) => {
  const { t } = useLanguage();

  return (
    <IconButton
      onClick={onClick}
      disabled={isDisabled}
      sx={{
        ...add_new_elements_button(() => isDisabled),
        backgroundColor: isDisabled
          ? "var(--bg-disabled) !important"
          : "var(--bg-success)",
        border: isDisabled ? "var(--bg-info)" : "1px solid var(--bg-success)",
      }}
    >
      {t("Nueva iteracion")}
      <AddIcon />
    </IconButton>
  );
};

export default AddIterationButton;
