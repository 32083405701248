import React from "react";
import Tooltip from "@mui/material/Tooltip";
import useLanguage from "../../../context/LanguageProvider";
import { criteria_buttons_tooltip_styles } from "../../../styles/app-styles";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";

interface RuleButtunsProps {
  selectionModel: any;
  handleToggleMultipleAssign: any;
  handleToggleDeleteAssignationsModal: any;
  selectionModelHasDriversOrVariabilities: boolean;
}

const RuleButtons = ({
  selectionModel,
  handleToggleMultipleAssign,
  handleToggleDeleteAssignationsModal,
  selectionModelHasDriversOrVariabilities,
}: RuleButtunsProps) => {
  const { t } = useLanguage();

  const RenderDeleteButton = ({ children }) => {
    return !selectionModelHasDriversOrVariabilities ||
      (selectionModel && selectionModel.length === 0) ? (
      <Tooltip
        placement="bottom"
        title={t(
          "deltaTexts.simulationRules.ruleButtons.noRowsWithAssignationsToDelete"
        )}
        sx={criteria_buttons_tooltip_styles}
      >
        <div>{children}</div>
      </Tooltip>
    ) : (
      children
    );
  };

  const RenderMultipleAssignButton = ({ children }) => {
    return selectionModel && selectionModel.length === 0 ? (
      <Tooltip
        placement="bottom"
        title={t("modelParameterizationTexts.customToolbar.noRowsSelected")}
        sx={criteria_buttons_tooltip_styles}
      >
        <div>{children}</div>
      </Tooltip>
    ) : (
      children
    );
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        height: "100%",
        spacing: 1,
      }}
    >
      <RenderDeleteButton>
        <Button
          variant="text"
          onClick={() =>
            !selectionModelHasDriversOrVariabilities &&
            selectionModel &&
            selectionModel.length < 1 ? (
              <> </>
            ) : (
              handleToggleDeleteAssignationsModal()
            )
          }
          sx={{
            cursor:
              !selectionModelHasDriversOrVariabilities ||
              (selectionModel && selectionModel.length < 1)
                ? "default"
                : "pointer",
            alignItems: "center",
            background: "transparent",
            color:
              !selectionModelHasDriversOrVariabilities ||
              (selectionModel && selectionModel.length < 1)
                ? "var(--middle-grey)"
                : "white",
            display: "flex",
            fontSize: "14px",
            fontWeight: "bold",
            justifyContent: "center",
            ":hover": {
              color:
                !selectionModelHasDriversOrVariabilities ||
                (selectionModel && selectionModel.length < 1)
                  ? "var(--middle-grey)"
                  : "#F90D4A",
            },
          }}
        >
          {t("deltaTexts.simulationRules.ruleButtons.deleteAssignations")}
        </Button>
      </RenderDeleteButton>
      <RenderMultipleAssignButton>
        <Button
          variant="text"
          onClick={() =>
            selectionModel && selectionModel.length < 1 ? (
              <> </>
            ) : (
              handleToggleMultipleAssign()
            )
          }
          sx={{
            cursor:
              selectionModel && selectionModel.length < 1
                ? "default"
                : "pointer",
            alignItems: "center",
            background: "transparent",
            color:
              selectionModel && selectionModel.length < 1
                ? "var(--middle-grey)"
                : "white",
            display: "flex",
            fontSize: "14px",
            fontWeight: "bold",
            justifyContent: "center",
            ":hover": {
              color:
                selectionModel && selectionModel.length < 1
                  ? "var(--middle-grey)"
                  : "#F90D4A",
            },
          }}
        >
          {t("deltaTexts.simulationRules.ruleButtons.multipleAssign")}
        </Button>
      </RenderMultipleAssignButton>
    </Stack>
  );
};

export default RuleButtons;
