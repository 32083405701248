import { getPeriodsLabelsFromPeriodsIds } from "../utils";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import Chip from "@mui/material/Chip/Chip";
import Stack from "@mui/material/Stack";

const ChipFilters = () => {
  const {
    filters,
    setFilters,
    tempFilters,
    setTempFilters,
    setSelectedPeriods,
  } = useViewDashboard();  

  const handleDeleteFilter = (e, filterToDelete) => {
    // Filtra los filtros temporales, eliminando el que coincide con filterToDelete
    const updatedTempFilters = tempFilters?.filter(
      (tempFilter) => tempFilter.column.label !== filterToDelete.column.label
    );

    setTempFilters(updatedTempFilters);

    // Copia el estado actual de filters
    const updatedFilters = { ...filters };

    // Elimina la clave del filtro que coincide
    delete updatedFilters[filterToDelete.column.label];

    if (filterToDelete.column.label === "PERIODO") {
      setSelectedPeriods([]); // Restablece los períodos seleccionados
      updatedFilters["PERIODO"] = [];
    }

    setFilters(updatedFilters);
  };

  const transformFiltersToArray = () => {
    const filtersArray = [] as any;
    Object.keys(filters).forEach((key) => {
      filtersArray.push({
        column: {
          label: key,
          value: tempFilters.find((filter) => filter.column.label === key)
            ?.column.value,
        },
        values: filters[key].map((value: any) => ({
          label: value,
          value: value,
        })),
      });
    });
    return filtersArray;
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ marginTop: "15px", justifyContent: "flex-start", width: "100%" }}
    >
      {filters &&
        transformFiltersToArray()?.map((filter) => {
          return (
            filter.values.map((value) => value.label).length !== 0 && (
              <Chip
                key={filter.column.label}
                label={`${filter.column.label}: ${filter.values
                  .map((value) => value.label)
                  .join(", ")}`}
                onDelete={(e) => handleDeleteFilter(e, filter)}
              />
            )
          );
        })}
    </Stack>
  );
};

export default ChipFilters;
