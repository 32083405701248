import { Stack, Typography } from "@mui/material";
import useLanguage from "../../../../../../context/LanguageProvider";
import useSingleSelect from "../../../../../../hooks/useSingleSelect";
import { useRef, useState, useEffect, useContext } from "react";
import { Filter, IdOption } from "../../../../../criterias/configCriteria/types";
import useApi from "../../../../../../hooks/useApi";
import { INCOMING_VALUES_SALES_FORECAST } from "../../../../../../api/axios";
import FilterForm from "../../../../../dataModel/downloadReport/FilterForm";
import FormSelectLayout from "../../../../../forms/FormSelectLayout";
import VirtualSelect from "../../../../../criterias/configCriteria/VirtualSelect";
import SimulationProvider from "../../../../../../context/SimulationProvider";
import ValueFilterColumnSelect from "../../../../ValueFilterColumnSelect";
interface Step1NewRegisterRouteModal {
    filters: Filter[],
    setFilters: any,
    requiredFilters: any[],
    setRequiredFilters: any,
    dataGetAnalysisById: any;
    dataGetRequiredFilters: any;
    noRoutesMessage: string,
    defaultCedis: string | undefined,
    requiredFiltersSchema: any
}

const Step1NewRegisterRouteModal = ({ filters, setFilters, requiredFilters, setRequiredFilters, dataGetAnalysisById, dataGetRequiredFilters, noRoutesMessage, defaultCedis, requiredFiltersSchema }: Step1NewRegisterRouteModal) => {

    const analysisId = localStorage.getItem('analysisId') ? localStorage.getItem('analysisId') : undefined
    const { simulationState } = useContext<React.ContextType<typeof SimulationProvider>>(SimulationProvider);
    const { t } = useLanguage();
    const columnSelectRef: any = useRef(null);
    const [valuesSelectedOptions, setValuesSelectedOptions] = useState<IdOption[]>();

    const onColumnChange = () => {
        setSelectedValue([]);
        setValuesSelectedOptions(undefined);
    };

    const [
        columnFilterSelectedOptions,
        setColumnFilterSelectedOptions,
        columnFilterOptionsChangeHandler,
    ] = useSingleSelect(() => onColumnChange());


    /********************************* Filtro de opciones para select *****************************/

    const [incomingValuesOptions, setIncomingValuesOptions] = useState<any[]>([]);
    const [selectedValue, setSelectedValue] = useState<string[] | null>([]);
    const [isLoadingIncomingValuesOptions, setIsLoadingIncomingValuesOptions] =
        useState(false);
    const onSuccessIncomingValuesOptionsResponse = (data: any) => {
        setIncomingValuesOptions(data?.valores)
        setIsLoadingIncomingValuesOptions(false);
    }

    const {
        callApi: postGetIncomingValues,
    } = useApi(
        "",
        "POST",
        undefined,
        undefined,
        onSuccessIncomingValuesOptionsResponse,
        undefined,
        false
    );
    
    useEffect(() => {
        if (columnFilterSelectedOptions && simulationState.salesForecast) {
            setIsLoadingIncomingValuesOptions(true);
            const filtros = {};
            filters && filters.forEach(item => {
                const { column, values } = item;
                filtros[column.label] = values.map((value) => value.value);
            });

            requiredFilters && requiredFilters.forEach(item => {
                const { columna, valor } = item;
                filtros[columna] = valor;
            });
            const filterBody = {
                filtros,
                columna: columnFilterSelectedOptions?.label
            }            
            postGetIncomingValues(INCOMING_VALUES_SALES_FORECAST(simulationState.salesForecast.id && simulationState.salesForecast.id), filterBody);
        } else {
            setIncomingValuesOptions([]);
        }
    }, [columnFilterSelectedOptions, simulationState.salesForecast]);
  

    const columnLabels = requiredFiltersSchema && dataGetAnalysisById && filters && dataGetAnalysisById?.agrupacion?.columnas_a_usar?.filter((opcion) => {
        return !filters.some((param) => param.column.label === opcion.label);
    }).filter(column => !requiredFilters.map((requireFilter) => {
        return requireFilter.columna
    }).includes(column.label)).filter(column => {
        // Comprobar si la columna está presente en requiredFiltersSchema
        return !requiredFiltersSchema.some(requiredColumn => requiredColumn.columna.value === column.value);
    });

    const columnSelects = (arrayColumns: any, index: number) => {
        return (arrayColumns?.map((column) => {

            const findFilterByColumn = (requiredFilters, labelToFind) => {
                const filterObject = requiredFilters && requiredFilters.find((item) => item.label === labelToFind);
                if (filterObject) {
                    const { columna, valor } = filterObject;
                    return { label: columna, value: valor };
                }
                return undefined;
            }

            return (
                <ValueFilterColumnSelect
                    key={`${column}`}
                    filters={filters}
                    defaultValue={defaultCedis && column === "Centro de Distribución" ? { value: defaultCedis, label: defaultCedis } : findFilterByColumn(requiredFilters, column)}
                    requiredFilters={requiredFilters}
                >
                    {({
                        dataGetIncomingValues,
                        selectedValue,
                        setValuesSelectedOptions,
                        setSelectedValue,
                        isLoadingpostGetIncomingValues,
                        setRequiredColumnFilterSelectedOptions,
                        requiredColumnFilterSelectedOptions,
                    }) => {

                        useEffect(() => {
                            if (requiredFiltersSchema && selectedValue) {
                                setRequiredColumnFilterSelectedOptions(requiredFiltersSchema?.find(filter => filter.nombre === column)?.columna)
                            }
                        }, [requiredFiltersSchema, selectedValue])

                        const addRequiredFilters = (newValue, column, requiredColumnFilterSelectedOptions) => {
                            if (newValue.length !== 0) {
                                setSelectedValue(newValue)
                                setRequiredFilters && setRequiredFilters([...requiredFilters, {
                                    label: column,
                                    columna: requiredColumnFilterSelectedOptions.label,
                                    valor: newValue
                                }])
                            } else {
                                setSelectedValue(newValue)
                                setRequiredFilters((prevFilters) =>
                                    prevFilters.filter((filter) => filter.label !== column)
                                );
                            }
                        }

                        useEffect(() => {
                            if (requiredFilters && findFilterByColumn(requiredFilters, column)) {
                                setSelectedValue([findFilterByColumn(requiredFilters, column)?.value[0]])
                            }
                        }, [])

                        return (
                            <Stack sx={{
                                width: "100%",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.2) -6px 2px 8px -1px, rgba(0, 0, 0, 0.14) 2px 6px 20px 1px, rgba(0, 0, 0, 0.12) 0px 5px 13px 1px",
                                padding: "15px",
                                borderRadius: "10px",
                                border: requiredFilters && findFilterByColumn(requiredFilters, column) ? "" : "1px solid red"
                            }}>
                                <FormSelectLayout
                                    title={column}
                                    required={true}
                                    key={index}
                                >
                                    <Stack sx={{ flexDirection: "row", justifyContent: "space-around", gap: 2 }}>
                                        {/* <Select
                                            options={columnLabels}
                                            className="cc_select"
                                            name="column"
                                            onChange={requiredColumnFilterOptionsChangeHandler}
                                            closeMenuOnSelect
                                            placeholder={"Elegir columna de referencia"}
                                            isClearable
                                            isDisabled={defaultCedis && column === "Centro de Distribución" ? true : false}
                                            defaultValue={defaultCedis && column === "Centro de Distribución" ? { value: defaultCedis, label: defaultCedis } : findFilterByColumn(requiredFilters, column)}
                                        /> */}
                                        <VirtualSelect
                                            incomingValuesOptions={
                                                dataGetIncomingValues ? dataGetIncomingValues.valores : []
                                            }
                                            selectedValues={selectedValue}
                                            setSelectedValues={(newValue) =>
                                                addRequiredFilters(newValue, column, requiredColumnFilterSelectedOptions)
                                            }
                                            setValuesSelectedOptions={setValuesSelectedOptions}
                                            isLoadingIncomingValuesOptions={
                                                isLoadingpostGetIncomingValues
                                            }
                                            isDisabled={requiredColumnFilterSelectedOptions || requiredColumnFilterSelectedOptions?.length < 1 ? false : true}
                                            selectOneOption={true}
                                            defaultValue={selectedValue}
                                        />
                                    </Stack>
                                </FormSelectLayout>
                            </Stack>
                        );
                    }
                    }
                </ValueFilterColumnSelect>
            )
        }))
    }

    return (
        <>
            <Typography sx={{
                width: "100%",
                fontWeight: "bold",
                textAlign: "center",
            }}>
                {t("simulatorTexts.registerRoutes.step1NewRouteModal.paramSelection")}
            </Typography>
            {noRoutesMessage !== "" && <Typography
                variant="body1"
                sx={{
                    fontWeight: "500",
                    color: "var(--text-error)",
                    textAlign: "center",
                }}
            >
                {noRoutesMessage}
            </Typography>}
            <Stack sx={{
                minWidth: "1000px",
                flexDirection: "column",
                justifyContent: "space-around",
                gap: "20px",
                p: "10px 50px",
            }}>
                <Stack sx={{
                    flexDirection: "column",
                    justifyContent: "space-around",
                    gap: 2
                }}>
                    {dataGetRequiredFilters && columnSelects(dataGetRequiredFilters, 1)
                    }
                </Stack>
                <FilterForm filters={filters} setFilters={setFilters} setColumnFilterSelectedOptions={setColumnFilterSelectedOptions} columnFilterSelectedRef={columnSelectRef} columnLabels={columnLabels} columnFilterOptionsChangeHandler={columnFilterOptionsChangeHandler} columnFilterSelectedOption={columnFilterSelectedOptions} analysisSelectedOption={analysisId} selectedValues={selectedValue} incomingValuesOptions={incomingValuesOptions} setValuesSelectedOptions={setValuesSelectedOptions} setSelectedValues={setSelectedValue} isLoadingIncomingValuesOptions={isLoadingIncomingValuesOptions} valuesSelectedOptions={valuesSelectedOptions} selectOneOption={true} />
            </Stack >
            {filters?.length === 0 && (
                <Stack
                    sx={{
                        width: "95%",
                        height: "7vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: "700", color: "var(--text-info)" }}
                    >
                        {t("simulatorTexts.registerRoutes.step1NewRouteModal.noFilterYet")}
                    </Typography>
                </Stack>
            )}
        </>
    )
}

export default Step1NewRegisterRouteModal