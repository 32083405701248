import { Box } from "@mui/material";
import { renderCustomTypographyTableText } from "../../utils";
import { useParameterization } from "../../../../hooks/useParameterization";
import useApi from "../../../../hooks/useApi";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import ViewDummyModal from "./ViewDummyModal";
import ViewInfoModal from "../../../criterias/criteriasManagement/ViewInfoModal";
import {
  GET_CRITERIA_DATA_URL,
  GET_CRITERIA_DUMMY_URL,
} from "../../../../api/axios";
import {
  container_100_styles,
  custom_model_cell_container,
} from "../../../../styles/app-styles";

interface CustomModelCellProps {
  id: number;
  value: any;
  rows: any;
  column: any;
  handleCopyCriteria: any;
  setRowCopyCriteria: any;
}

const CustomModelCell = ({
  id,
  value,
  rows,
  column,
  handleCopyCriteria,
  setRowCopyCriteria,
}: CustomModelCellProps) => {
  const { t } = useLanguage();
  const [openCriteriaModal, handleToggleCriteriaModal] = useDialog();
  const [openDummyModal, handleToggleDummyModal] = useDialog();
  const {
    parameterizationDispatch,
    handleToggleEditCriteriaModal,
  } = useParameterization();

  const onSuccessgetCriteriaData = (data) => {
    parameterizationDispatch({
      type: "SET_CRITERIA",
      payload: data,
    });
    handleToggleCriteriaModal();
  }

  const {
    data: dataCriteria,
    error: errorCriteria,
    isLoading: isLoadingCriteriaData,
    callApi: getCriteriaData,
  } = useApi(
    GET_CRITERIA_DATA_URL,
    "GET",
    t("modelParameterization.getCriteriaData.codes"),
    undefined,
    onSuccessgetCriteriaData,
    undefined,
    false
  );

  const {
    data: dataCriteriaDummy,
    error: errorCriteriaDummy,
    isLoading: isLoadingCriteriaDummy,
    callApi: getCriteriaDummy,
  } = useApi(
    GET_CRITERIA_DUMMY_URL,
    "GET",
    t("modelParameterization.getCriteriaData.codes"),
    undefined,
    handleToggleDummyModal,
    undefined,
    false
  );

  const handleOpenCriteriaModal = (aplicacionprorrateo_id: number) => {
    getCriteriaData(GET_CRITERIA_DATA_URL(aplicacionprorrateo_id));   
  };

  const handleOpenCriteriaDummyModal = (aplicacionprorrateo_id: number) => {
    getCriteriaDummy(GET_CRITERIA_DUMMY_URL(aplicacionprorrateo_id));
  };

  const handleEdit = () => {   
    parameterizationDispatch({
      type: "SET_CONFIG_MODE",
      payload: "EDIT",
    });
    handleToggleEditCriteriaModal();
    handleToggleCriteriaModal();
  };

  const handleCopy = () => {
    handleCopyCriteria(dataCriteria);
    handleToggleCriteriaModal();
  };

  return (
    <Box sx={container_100_styles}>
      <Box sx={custom_model_cell_container}>
        {renderCustomTypographyTableText(
          t,
          column,
          value,
          id,
          rows,
          setRowCopyCriteria,
          handleOpenCriteriaModal,
          handleOpenCriteriaDummyModal
        )}
      </Box>
      <ViewInfoModal
        open={openCriteriaModal && !isLoadingCriteriaData && !errorCriteria}
        title={""}
        handleClose={handleToggleCriteriaModal}
        criteria={dataCriteria}
        handleEdit={handleEdit}
        handleCopy={handleCopy}
        isModelPageModal
      />
      <ViewDummyModal
        open={openDummyModal && !isLoadingCriteriaDummy && !errorCriteriaDummy}
        handleClose={handleToggleDummyModal}
        dummyData={dataCriteriaDummy}
      />
    </Box>
  );
};

export default CustomModelCell;
