import { expenseTargetOptions } from "../../../criterias/configCriteria/selectOptions";
import { getFileFromCriteriaId } from "../../utils";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { Switch } from "../../../switch";
import { useParameterization } from "../../../../hooks/useParameterization";
import Info from "../../../icons/Info";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";
import ValidationDropdown from "./ValidationDropdown";
import {
  criteria_modal_error_styles,
  step_form_label_styles,
} from "../../../../styles/app-styles";
interface ICriteriaContent {
  expenseTargetSelectedOption: any;
  dataGetCriteriaTarget: any;
  expenseTargetChangeHandler: any;
  rowsData: any;
  criteriaSelectRef: any;
  criteriaSelectOptions: any;
  criteriaChangeHandler: any;
  criteriaSelectedOption: any;
  previousValidations: any;
  dataGetFileColumn: any;
  getCriteria: any;
  validationsToSend: any;
  setValidationsToSend: any;
  checked: boolean;
  handleChangeSwitch: any;
}

const GntCriteriaContent = ({
  expenseTargetSelectedOption,
  dataGetCriteriaTarget,
  expenseTargetChangeHandler,
  rowsData,
  criteriaSelectRef,
  criteriaSelectOptions,
  criteriaChangeHandler,
  criteriaSelectedOption,
  previousValidations,
  dataGetFileColumn,
  getCriteria,
  validationsToSend,
  setValidationsToSend,
  checked,
  handleChangeSwitch,
}: ICriteriaContent) => {
  const { t } = useLanguage();
  const { parameterizationState } = useParameterization();
  const { tabValue, selectionModel } = parameterizationState;

  const hasEmptyFields =
    !expenseTargetSelectedOption ||
    !criteriaSelectedOption ||
    (previousValidations &&
      previousValidations?.length > 0 &&
      validationsToSend?.filter((val: any) => val.columna === undefined)
        .length !== 0);

  const hasSelectedFields =
    expenseTargetSelectedOption &&
    criteriaSelectedOption &&
    previousValidations;

  const getKey = (previousValidation) => {
    return (
      previousValidation.receptor.nombre + "-" + previousValidation.etapa_previa
    );
  };

  const fileSelectedOption =
    criteriaSelectedOption &&
    getFileFromCriteriaId(criteriaSelectedOption.value, getCriteria);

  const getDefaultValue =
    tabValue ===
    t("modelParameterizationTexts.step2.tabValueExpensesNotTransferred")
      ? selectionModel
          .map((id) => rowsData.find((row) => row.id === id))
          .find((row) => row.receptor_criterio)?.receptor_criterio
      : undefined;

  return (
    <Stack width={"500px"}>
      <Stack direction="row" justifyContent={"space-between"} gap={"10px"}>
        <Stack mb={"10px"} width="100%">
          <label style={step_form_label_styles}>Receptor de Gasto:</label>
          <Select
            styles={selectStyles(expenseTargetSelectedOption)}
            options={expenseTargetOptions(dataGetCriteriaTarget)}
            className="cc_expense_target_select"
            name="Receptores de Gasto"
            onChange={expenseTargetChangeHandler}
            closeMenuOnSelect={true}
            placeholder={t(
              "modelParameterizationTexts.criteriaModelContent.selectReceiver"
            )}
            isClearable={true}
            isSearchable={true}
            defaultValue={getDefaultValue}
            isDisabled={
              tabValue ===
              t(
                "modelParameterizationTexts.step2.tabValueExpensesNotTransferred"
              )
            }
          />
        </Stack>
        <Stack mb={"10px"} width="100%">
          <label style={step_form_label_styles}>
            {t(
              "modelParameterizationTexts.criteriaModelContent.apportionmentCriteria"
            )}
            :
          </label>
          <Select
            reference={criteriaSelectRef}
            styles={selectStyles(criteriaSelectedOption)}
            options={criteriaSelectOptions}
            className="cc_expense_target_select"
            onChange={criteriaChangeHandler}
            closeMenuOnSelect={true}
            placeholder={t(
              "modelParameterizationTexts.criteriaModelContent.selectCriteria"
            )}
            isClearable={true}
            isSearchable={true}
          />
        </Stack>
      </Stack>
      {tabValue === "GASTOS" && (
        <Stack direction="row" alignItems="center">
          <Switch
            checked={checked}
            handleChange={handleChangeSwitch}
            label={t(
              "modelParameterizationTexts.criteriaModelContent.destinationCriteria"
            )}
            color="primary"
          />
          <Info
            text={t(
              "modelParameterizationTexts.criteriaModelContent.only50rows"
            )}
            color="blue"
          />
        </Stack>
      )}
      <>
        {hasSelectedFields &&
          previousValidations.map((previousValidation: any) => {
            return (
              <ValidationDropdown
                key={getKey(previousValidation)}
                fileColumnSelectOptions={dataGetFileColumn}
                fileSelectedOption={fileSelectedOption}
                previousValidation={previousValidation}
                setValidationsToSend={setValidationsToSend}
                validationsToSend={validationsToSend}
                rows={rowsData}
              />
            );
          })}
      </>
      <>
        {hasEmptyFields && (
          <p style={criteria_modal_error_styles}>
            {t(
              "modelParameterizationTexts.criteriaModelContent.completeFields"
            )}
          </p>
        )}
      </>
    </Stack>
  );
};

export default GntCriteriaContent;
