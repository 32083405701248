import { convertObjectArrayToText, convertToText } from "../utils";
import { CustomizedTable } from "../../customizedTable";
import { info_section_container_styles } from "../../../styles/app-styles";
import CopyButton from "../../buttons/CopyButton";
import Dialog from "../../dialog/Dialog";
import EditCriteriaButton from "../../buttons/EditCriteriaButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useInfoContext from "../../../hooks/useInfoContext";
import useLanguage from "../../../context/LanguageProvider";

interface ViewInfoProps {
  criteria: any;
  open: boolean;
  handleClose: () => void;
  handleEdit?: (e: any, id: number) => void;
  handleCopy?: (e: any, id: number) => void;
  title: string | undefined;
  isModelPageModal?: boolean;
}

const ViewInfoModal = ({
  criteria,
  open,
  handleClose,
  handleEdit,
  handleCopy,
  title,
  isModelPageModal,
}: ViewInfoProps) => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const COLUMNS1 = ["Columna", "Criterio", "Valores"]; 
  const ROWS1 = criteria?.criterio_columna_filtra
    ?.filter((item: any) => {
      return !item.externo;
    })
    .map((item: any) => {
      return {
        Columna: item.columna,
        Criterio: item.tipo_de_filtro,
        Valores: convertObjectArrayToText(item.criterio_valor_filtra, t),
      };
    });

  const COLUMNS2 = ["Columna Gastos", `Columna ${criteria?.archivo?.label}`];
  const ROWS2 = criteria?.criterio_columna_valida
    ?.filter((item: any) => {
      return !item.externo;
    })
    .map((item: any) => {
      return {
        "Columna Gastos": item?.columna_gastos_o_externo,
        "Columna Base Criterio": item?.columna_archivo_base,
      };
    });

  const COLUMNS3 = ["Columna", "Criterio", "Valores"];
  const ROWS3 = criteria?.criterio_columna_filtra
    ?.filter((item: any) => {
      return item.externo;
    })
    .map((item: any) => {
      return {
        Columna: item.columna,
        Criterio: item.tipo_de_filtro,
        Valores: convertObjectArrayToText(item.criterio_valor_filtra, t),
      };
    });

  const COLUMNS4 = [
    `Columna archivo ${criteria?.archivo_externo_validacion?.label}`,
    `Columna archivo ${criteria?.archivo?.label}`,
  ];
  const ROWS4 = criteria?.criterio_columna_valida
    ?.filter((item: any) => {
      return item.externo;
    })
    .map((item: any) => {
      return {
        "Columna archivo externo": item?.columna_gastos_o_externo,
        "Columna Base Criterio": item?.columna_archivo_base,
      };
    });

  const COLUMNS_PREVIOUS_VALIDATIONS = [
    "Receptor",
    `Columna en ${criteria?.archivo.label}`,
    `Etapa`,
  ];
  const ROWS_PREVIOUS_VALIDATIONS = criteria?.validaciones_anteriores?.map(
    (item: any) => {
      return {
        receptor_gasto: item?.receptor_gasto,
        Columna: item?.columna_valida,
        Etapa: item?.etapa,
      };
    }
  );

  const handleEditAndClose = (e: any, id: number) => {
    handleEdit && handleEdit(e, id);
    handleClose();
  };

  const handleCopyAndClose = (e: any, id: number) => {
    handleCopy && handleCopy(e, id);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        handleClose={handleClose}
        title={t("criteriaManagementTexts.viewInfoModal.criteriaInfo")}
      >
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          padding="0 100px 30px 100px"
          position="relative"
        >
          <Stack sx={info_section_container_styles}>
            <Typography
              variant="subtitle1"
              sx={{
                color: "var(--text-main)",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {title === "" ? criteria?.nombre : title}
            </Typography>
            <p
              style={{
                color: "var(--text-secondary)",
                margin: "0",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              {criteria?.tratamiento_diferenciado
                ? "(" +
                  t(
                    "criteriaManagementTexts.viewInfoModal.differentiatedTreatment"
                  ) +
                  ")"
                : "(" +
                  t(
                    "criteriaManagementTexts.viewInfoModal.noDifferentiatedTreatment"
                  ) +
                  ")"}
            </p>
            <p style={{ color: "var(--text-main)", margin: "5px" }}>
              <span style={{ fontWeight: "bold" }}>
                {t("criteriaManagementTexts.viewInfoModal.criteriaBase")}{" "}
              </span>{" "}
              {criteria?.archivo?.label}
            </p>
            <p style={{ color: "var(--text-main)", margin: "5px" }}>
              <span style={{ fontWeight: "bold" }}>
                {t("criteriaManagementTexts.viewInfoModal.criteriaColumn")}{" "}
              </span>{" "}
              {criteria?.columna?.label}
            </p>
            <p style={{ color: "var(--text-main)", margin: "5px" }}>
              <span style={{ fontWeight: "bold" }}>
                {t("criteriaManagementTexts.viewInfoModal.aplicatedFunction")}{" "}
              </span>{" "}
              {criteria?.funcion}
            </p>
            <p style={{ color: "var(--text-main)", margin: "5px" }}>
              <span style={{ fontWeight: "bold" }}>
                {t(
                  "criteriaManagementTexts.viewInfoModal.convertNegativesToPositives"
                )}{" "}
              </span>{" "}
              {criteria?.valores_absolutos
                ? t("criteriaManagementTexts.viewInfoModal.yes")
                : t("criteriaManagementTexts.viewInfoModal.no")}
            </p>
            <p style={{ color: "var(--text-main)", margin: "5px" }}>
              <span style={{ fontWeight: "bold" }}>
                {t(
                  "criteriaManagementTexts.viewInfoModal.convertNegativesToZero"
                )}
              </span>{" "}
              {criteria?.negativos_a_cero
                ? t("criteriaManagementTexts.viewInfoModal.yes")
                : t("criteriaManagementTexts.viewInfoModal.no")}
            </p>

            {criteria?.funcion === "SUMA.UNICO" && (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t("criteriaManagementTexts.viewInfoModal.columnsToValidate")}
                </span>{" "}
                {criteria?.columna_criterio_unico.label}
              </p>
            )}

            {criteria?.funcion === "SUMA.PRODUCTO" && (
              <p style={{ color: "var(--text-main)", margin: "5px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t(
                    "criteriaManagementTexts.viewInfoModal.columnsToMultipliy"
                  )}
                </span>{" "}
                {convertToText(criteria?.criterio_columna_multiplicar, t)}
              </p>
            )}
          </Stack>
          {ROWS1?.length && (
            <Stack sx={info_section_container_styles}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "var(--text-main)",
                  fontWeight: "bold",
                  paddingBottom: "5px",
                  textTransform: "uppercase",
                }}
              >
                {t("criteriaManagementTexts.viewInfoModal.filterInFile")}{" "}
                {criteria?.archivo?.label}
              </Typography>
              <CustomizedTable COLUMNS={COLUMNS1} ROWS={ROWS1} width={450} />
            </Stack>
          )}
          {ROWS2?.length && (
            <Stack sx={info_section_container_styles}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "var(--text-main)",
                  fontWeight: "bold",
                  paddingBottom: "5px",
                  textTransform: "uppercase",
                }}
              >
                {t("criteriaManagementTexts.viewInfoModal.expensesCrossover")}{" "}
                {criteria?.archivo?.label}
              </Typography>
              <CustomizedTable COLUMNS={COLUMNS2} ROWS={ROWS2} width={400} />
            </Stack>
          )}
          {ROWS4?.length && (
            <Stack sx={info_section_container_styles}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "var(--text-main)",
                  fontWeight: "bold",
                  paddingBottom: "20px",
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                {t("criteriaManagementTexts.viewInfoModal.filterBy")}{" "}
                {criteria?.archivo?.label}{" "}
                {t("criteriaManagementTexts.viewInfoModal.withInfo")}{" "}
                {criteria?.archivo_externo_validacion?.label}
              </Typography>
              {ROWS3.length !== 0 && (
                <>
                  {" "}
                  <Typography
                    variant="body2"
                    sx={{
                      color: "var(--text-main)",
                      fontWeight: "bold",
                      paddingBottom: "5px",
                      textTransform: "uppercase",
                    }}
                  >
                    {t("criteriaManagementTexts.viewInfoModal.previousFilters")}
                    {criteria?.archivo_externo_validacion?.label}
                  </Typography>
                  <CustomizedTable
                    COLUMNS={COLUMNS3}
                    ROWS={ROWS3}
                    width={450}
                  />{" "}
                </>
              )}
              <Typography
                variant="body2"
                sx={{
                  color: "var(--text-main)",
                  fontWeight: "bold",
                  paddingBottom: "5px",
                  paddingTop: "30px",
                  textTransform: "uppercase",
                }}
              >
                {t("criteriaManagementTexts.viewInfoModal.crossoverValues")}
                {criteria?.archivo_externo_validacion?.label}{" "}
                {t("criteriaManagementTexts.viewInfoModal.with")}{" "}
                {criteria?.archivo?.label}
              </Typography>
              <CustomizedTable COLUMNS={COLUMNS4} ROWS={ROWS4} width={450} />
            </Stack>
          )}
          {isModelPageModal && criteria?.validaciones_anteriores.length && (
            <>
              <Typography
                variant="body2"
                sx={{
                  color: "var(--text-main)",
                  fontWeight: "bold",
                  paddingBottom: "5px",
                  paddingTop: "0px",
                  marginTop: "0px !important",
                  textTransform: "uppercase",
                }}
              >
                {t(
                  "criteriaManagementTexts.viewInfoModal.previousDestinitiesValidations"
                )}
              </Typography>
              <CustomizedTable
                COLUMNS={COLUMNS_PREVIOUS_VALIDATIONS}
                ROWS={ROWS_PREVIOUS_VALIDATIONS}
                width={450}
              />
            </>
          )}
          {handleEdit &&
            userRolData &&
            userRolData.administracion_de_criterios.visualizacion_y_edicion && (
              <EditCriteriaButton
                handleClick={handleEditAndClose}
                selectedCriteria={criteria}
              />
            )}
          {isModelPageModal &&
            userRolData &&
            userRolData.administracion_de_criterios.visualizacion_y_edicion && (
              <CopyButton
                handleClick={handleCopyAndClose}
                selectedCriteria={criteria}
              />
            )}
        </Stack>
      </Dialog>
    </div>
  );
};

export default ViewInfoModal;
