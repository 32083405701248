import { ConfirmationModal } from "../../dialog";
import { IdOption } from "../configCriteria/types";
import { MultiValue } from "react-select";
import { row_sb_100_styles } from "../../../styles/app-styles";
import { SCHEMA_URL, SCHEMAS_LIST_URL } from "../../../api/axios";
import { sortByName, sortByIdDesc } from "./utils";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddConfigButton from "../../buttons/AddConfigButton";
import BasicGrid from "../../basicGrid/BasicGrid";
import ChipFilters from "../../chipFilters/ChipFilters";
import ComponentLayout from "../../layout/ComponentLayout";
import ConstructionIcon from "@mui/icons-material/Construction";
import CustomButton from "../../buttons/Button";
import Dialog from "../../dialog/Dialog";
import NoDataBox from "../../layout/NoDataBox";
import SearchInput from "../../forms/SearchInput";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import ToggleSort from "./ToggleSort";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useInfoContext from "../../../hooks/useInfoContext";
import useLanguage from "../../../context/LanguageProvider";
import ViewInfoModalContainer from "../../viewInfoModal/ViewInfoModalContainer";
import ViewInfoModalContent from "./ViewInfoModalContent";
import CriteriaCard from "./CriteriaCard";
import FilterCriteriaModal from "./FilterCriteriaModal";

const SchemaManagement = () => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const navigate = useNavigate();

  const {
    data: dataGetSchemas,
    isLoading: isLoadingSchemas,
    isSuccess: isSuccessSchemas,
    isError: isErrorSchemas,
    refetch: refetchSchemas,
  } = useApiQuery(
    SCHEMAS_LIST_URL,
    false,
    t("criteriaManagement.getCriteria.error")
  );

  type Criteria = {
    id: number;
    nombre: string;
    buckets_usados: {
      value: number;
      label: string;
    }[];
    criterio_bucket: any;
    criterio_receptor_gasto: any;
  };
  
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<typeof dataGetSchemas>();
  const [openViewInfoModal, handleToggleViewInfoModal] = useDialog();
  const [
    openDeleteSchemaConfirmationModal,
    toggleDeleteSchemaConfirmationModal,
  ] = useDialog();
  const [selectedSchema, setSelectedSchema] = useState<Criteria>();
  const [schemaToDelete, setSchemaToDelete] = useState<string | number>();
  const [orderCriteria, setOrderCriteria] = useState("alphabet");
  const [openFilterModal, toggleFilterModal] = useDialog();
  const [filterSelectedOption, setFilterSelectedOption] = useState<string>(
    "withoutCriteriaFilter"
  );
  const [chipsList, setChipsList] = useState<
    MultiValue<IdOption> | { value: number; label: string }[] | undefined
  >(undefined);
  const [bucketsList, setBucketsList] = useState<any>([]);
  const [searchedList, setSearchedList] = useState<any>([]);
  const [modalFilterList, setModalFilterList] = useState<any>([]);
  const [totalList, setTotalList] = useState<any>([]);

  useEffect(() => {
    if (dataGetSchemas) {
      const dataByTabValue = dataGetSchemas;
      setFilteredData(dataByTabValue);
      if (
        (searchValue === "" || !searchValue) &&
        filterSelectedOption === "withoutCriteriaFilter"
      ) {
        setSearchedList(dataByTabValue);
        setModalFilterList(dataByTabValue);
        setTotalList(dataByTabValue);
      } else {
        getTotalList(searchedList, modalFilterList);
      }
    }
  }, [dataGetSchemas]);

  const handleAddCriteria = () => {
    localStorage.setItem("configMode", "CREATE");
    navigate("/configuracion-esquemas");
  };

  const onSuccessGetSchema = (data) => {
    localStorage.setItem("schema", JSON.stringify(data));
    navigate("/configuracion-esquemas");
  };

  const { isLoading: isLoadingGetSchema, callApi: getSchema } = useApi(
    SCHEMA_URL,
    "GET",
    undefined,
    undefined,
    onSuccessGetSchema,
    undefined,
    false
  );

  const handleEdit = (e: any, id: number) => {
    localStorage.setItem("configMode", "EDIT");
    getSchema(SCHEMA_URL(id));
  };

  const handleCopy = (e: any, id: number) => {
    localStorage.setItem("configMode", "COPY");
    getSchema(SCHEMA_URL(id));
    navigate("/configuracion-esquemas");
  };

  const onSuccessGetSchemaInfo = (data) => {
    setSelectedSchema(data);
    handleToggleViewInfoModal();
  };

  const { isLoading: isLoadingGetSchemaInfo, callApi: getSchemaInfo } = useApi(
    SCHEMA_URL,
    "GET",
    undefined,
    undefined,
    onSuccessGetSchemaInfo,
    undefined,
    false
  );

  const handleInfo = (e: any, id: number) => {
    getSchemaInfo(SCHEMA_URL(id));
  };

  //Eliminar criterio

  const onSuccessDeleteCriteria = () => {
    refetchSchemas();
  };

  const { isLoading: isLoadingDeleteSchema, callApi: deleteSchemaById } =
    useApi(
      SCHEMA_URL(schemaToDelete),
      "DELETE",
      undefined, 
      undefined,
      onSuccessDeleteCriteria
    );

  const handleDelete = () => {
    deleteSchemaById();
    toggleDeleteSchemaConfirmationModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setSchemaToDelete(id);
    toggleDeleteSchemaConfirmationModal();
  };

  //Filtrar criterios

  const filterSearchData = (data: any, value: string) => {
    if (
      //TODO buscar la manera de hacer esto mejor para que sea "all" en inglés, etc
      value.toLowerCase() === "t" ||
      value.toLowerCase() === "to" ||
      value.toLowerCase() === "tod" ||
      value.toLowerCase() === "todo" ||
      value.toLowerCase() === "todos"
    ) {
      return data?.filter((criteria: Criteria) => {
        return (
          criteria.nombre?.toLowerCase().includes(value?.toLowerCase()) ||
          criteria.criterio_bucket.some((bucket: any) =>
            bucket.label?.toLowerCase().includes(value?.toLowerCase())
          ) ||
          criteria.criterio_bucket.length === 0 ||
          ""
        );
      });
    } else {
      return data?.filter((criteria: Criteria) => {
        return (
          criteria.nombre?.toLowerCase().includes(value?.toLowerCase()) ||
          criteria.criterio_bucket.some((bucket: any) =>
            bucket.label?.toLowerCase().includes(value?.toLowerCase())
          ) ||
          ""
        );
      });
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setSearchedList(filterSearchData(filteredData, value));
    } else {
      setSearchedList(filteredData);
    }
    getTotalList(filterSearchData(filteredData, value), modalFilterList);
  };

  const handleSubmitFilterModal = (filterValue, buckets) => {
    if (filterValue === "criteriaBuckets") {
      const filteredByBucket = filteredData.filter((criteria: Criteria) => {
        return criteria.buckets_usados.some((bucket: any) => {
          return buckets?.some((selectedBucket: any) => {
            return selectedBucket.value === bucket.value;
          });
        });
      });
      setModalFilterList(filteredByBucket);
      getTotalList(searchedList, filteredByBucket);
    } else if (filterValue === "activeCriteria") {
      const filterByActiveCriteria = filteredData.filter(
        (criteria: Criteria) => criteria.buckets_usados.length > 0
      );
      setModalFilterList(filterByActiveCriteria);
      getTotalList(searchedList, filterByActiveCriteria);
    } else if (filterValue === "inactiveCriteria") {
      const filterByInactiveCriteria = filteredData.filter(
        (criteria: Criteria) => criteria.buckets_usados.length === 0
      );
      setModalFilterList(filterByInactiveCriteria);
      getTotalList(searchedList, filterByInactiveCriteria);
    } else {
      setModalFilterList(filteredData);
      getTotalList(searchedList, filteredData);
    }
    if (openFilterModal) toggleFilterModal();
    setChipsList(chipsByFilter(filterValue, buckets));
  };

  const chipsByFilter = (filterValue, buckets) => {
    if (filterValue === "criteriaBuckets") {
      return buckets;
    } else if (filterValue === "activeCriteria") {
      return [{ value: 1, label: "Criterios activos" }];
    } else if (filterValue === "inactiveCriteria") {
      return [{ value: 2, label: "Criterios inactivos" }];
    } else {
      return [];
    }
  };

  const arraysIntersection = (array1, array2) => {
    return array1.filter((item1) => {
      return array2.some((item2) => item2.id === item1.id);
    });
  };

  const getTotalList = (searchedList, modalFilterList) => {
    if (searchedList.length > 0 && modalFilterList.length > 0) {
      setTotalList(arraysIntersection(searchedList, modalFilterList));
    } else {
      setTotalList([]);
    }
  };

  const handleDeleteFilter = (e, filter) => {
    if (filterSelectedOption === "criteriaBuckets") {
      const newBucketsList = bucketsList.filter(
        (bucket) => bucket.value !== filter.value
      );
      if (newBucketsList.length === 0) {
        setFilterSelectedOption("withoutCriteriaFilter");
        handleSubmitFilterModal("withoutCriteriaFilter", []);
        setBucketsList([]);
      } else {
        setBucketsList(newBucketsList);
        handleSubmitFilterModal(filterSelectedOption, newBucketsList);
      }
    } else {
      setFilterSelectedOption("withoutCriteriaFilter");
      handleSubmitFilterModal("withoutCriteriaFilter", []);
      setBucketsList([]);
    }
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingSchemas}
        message={t("schemaManagementTexts.schemaManagement.lookingSchema")}
      />
      <SimpleBackdrop
        open={isLoadingDeleteSchema}
        message={t("schemaManagementTexts.schemaManagement.deletingSchema")}
      />
      <SimpleBackdrop
        open={isLoadingGetSchema}
        message={t("general.loading")}
      />
      <ComponentLayout
        title={t("schemaManagementTexts.schemaManagement.schemaManagement")}
        icon={<ConstructionIcon />}
      >
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Stack sx={{ ...row_sb_100_styles, gap: 1 }}>
            <Stack width="100%">
              <SearchInput
                value={searchValue}
                handleChange={(e) => handleSearch(e.target.value)}
                placeholder={t(
                  "schemaManagementTexts.schemaManagement.searchBySchemaBucket"
                )}
                clearIcon={true}
                handleDelete={() => handleSearch("")}
                size="small"
              />
            </Stack>
            <Stack
              flexDirection="row"
              width="50%"
              justifyContent={"space-between"}
            >
              <ToggleSort
                firstState={"alphabet"}
                secondState={"dateTime"}
                state={orderCriteria}
                setState={setOrderCriteria}
                firstTitle={t(
                  "criteriaManagementTexts.criteriaManagement.sortAlphabet"
                )}
                secondTitle={t(
                  "criteriaManagementTexts.criteriaManagement.sortDate"
                )}
              />
              <CustomButton
                title={t("criteriaManagementTexts.criteriaManagement.filter")}
                color="blue"
                type="button"
                onClick={toggleFilterModal}
                margin={false}
              />
            </Stack>
          </Stack>
          <ChipFilters
            filters={chipsList}
            handleDeleteFilter={handleDeleteFilter}
          />
          {totalList?.length > 0 ? (
            <BasicGrid
              data={
                orderCriteria === "dateTime"
                  ? sortByIdDesc(totalList)
                  : sortByName(totalList)
              }
              Card={CriteriaCard}
              handleEdit={
                userRolData &&
                userRolData.administracion_de_criterios
                  .visualizacion_y_edicion &&
                handleEdit
              }
              handleCopy={
                userRolData &&
                userRolData.administracion_de_criterios
                  .visualizacion_y_edicion &&
                handleCopy
              }
              handleInfo={handleInfo}
              handleDelete={
                userRolData &&
                userRolData.administracion_de_criterios
                  .visualizacion_y_edicion &&
                handleClickOnDelete
              }
              showInfo={/* tabValue === t("tabs.proration") */ false}
              xs={4}
            />
          ) : (
            isSuccessSchemas &&
            !isLoadingSchemas && (
              <NoDataBox
                text={t(
                  "schemaManagementTexts.schemaManagement.noSchemaAvailable"
                )}
              />
            )
          )}
          {isErrorSchemas && (
            <NoDataBox
              text={t("schemaManagementTexts.schemaManagement.notGetSchema")}
              error={true}
            />
          )}
          {userRolData &&
            (userRolData.administracion_de_criterios.visualizacion_y_creacion ||
              userRolData.administracion_de_criterios
                .visualizacion_y_edicion) && (
              <AddConfigButton handleClick={handleAddCriteria} />
            )}
        </Stack>
      </ComponentLayout>
      <ViewInfoModalContainer
        item={selectedSchema}
        open={openViewInfoModal}
        title={selectedSchema?.nombre}
        handleClose={handleToggleViewInfoModal}
        content={<ViewInfoModalContent item={selectedSchema} />}
        handleEdit={handleEdit}
        /* handleCopy={handleEdit} */
        isEditRol={
          userRolData &&
          userRolData.administracion_de_criterios.visualizacion_y_edicion
        }
        width="1000px"
        maxWidth="xl"
      />
      <ConfirmationModal
        open={openDeleteSchemaConfirmationModal}
        handleClose={toggleDeleteSchemaConfirmationModal}
        handleAccept={handleDelete}
        message={t("schemaManagementTexts.schemaManagement.deleteSchema")}
        title={t("dialog.confirmTitle")}
      />
      <Dialog
        open={openFilterModal}
        handleClose={toggleFilterModal}
        title={t("dialog.filter")}
        maxWidth="lg"
      >
        <FilterCriteriaModal
          filterSelectedOption={filterSelectedOption}
          setFilterSelectedOption={setFilterSelectedOption}
          bucketsList={bucketsList}
          setBucketsList={setBucketsList}
          toggleFilterModal={toggleFilterModal}
          handleApply={(filterValue, buckets) =>
            handleSubmitFilterModal(filterValue, buckets)
          }
        />
      </Dialog>
    </>
  );
};

export default SchemaManagement;
