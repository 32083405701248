import { tooltip_text_styles } from "../../../styles/app-styles";
import { transformText } from "../../criterias/utils";
import { truncateString } from "../../parameterization/utils";
import { convertUnit } from "../../report/utils";
import { convertArrayToText } from "../../taskManager/utils";

const formatNumberToMoney = (value: number) => {
  const numeroFormateado = value.toLocaleString("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return numeroFormateado;
};

export const convertUnitAllNumber = (
  unit: string,
  value: number,
  minimumFractionDigits?: number,
  decimals?: number
) => {
  const num = typeof value === "string" ? parseFloat(value) : value;

  const digits =
    num / 10000 < 1 ? (decimals || decimals === 0 ? decimals : 2) : 0;
  switch (unit) {
    case "moneda":
      return value.toLocaleString("es-AR", {
        //TODO: hacer que tome el locale del usuario
        style: "currency",
        currency: "ARS",
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
      });
    case "porcentaje":
      return value
        ? value.toLocaleString("es-ES", {
            style: "percent",
            minimumFractionDigits: minimumFractionDigits
              ? minimumFractionDigits
              : 2,
          })
        : undefined;
    case "unidades":
      return parseFloat(num.toFixed(digits));
    default:
      return value;
  }
};

export const transformColumnNameMarketBasket = (t, columna) => {
  switch (columna) {
    case "COGS_PORCENTUAL":
      return t("marketBasket.view.COGS_PORCENTUAL");
    case "PRECIO_UNITARIO":
      return t("marketBasket.view.PRECIO_UNITARIO");
    case "VENTA_BRUTA_POTENCIAL":
      return t("marketBasket.view.VENTA_BRUTA_POTENCIAL");
    case "CONTRIBUCION_BRUTA_POTENCIAL":
      return t("marketBasket.view.CONTRIBUCION_BRUTA_POTENCIAL");
    case "VOLUMEN_PROMEDIO":
      return t("marketBasket.view.VOLUMEN_PROMEDIO");
    case "CONTRIBUCION_BRUTA_UNITARIA":
      return t("marketBasket.view.CONTRIBUCION_BRUTA_UNITARIA");
    case "ROTACION_PROMEDIO":
      return t("marketBasket.view.ROTACION_PROMEDIO");
    case "ANTECEDENTS":
      return t("marketBasket.view.ANTECEDENTS");
    case "ANTECEDENT SUPPORT":
      return t("marketBasket.view.ANTECEDENT_SUPPORT");
    case "SKU SUPPORT":
      return t("marketBasket.view.SKU_SUPPORT");
    case "SKU & ANTECEDENTS SUPPORT":
      return t("marketBasket.view.SKU_ANTECEDENTS_SUPPORT");
    case "CONFIDENCE":
      return t("marketBasket.view.CONFIDENCE");
    case "LIFT":
      return t("marketBasket.view.LIFT");
    case "LEVERAGE":
      return t("marketBasket.view.LEVERAGE");
    case "CONVICTION":
      return t("marketBasket.view.CONVICTION");
    case "ZHANGS_METRIC":
      return t("marketBasket.view.ZHANGS_METRIC");
    case "ROTACION_CLIENTE":
      return t("marketBasket.view.ROTACION_CLIENTE");
    case "MES_DESDE_ULTIMA_COMPRA":
      return t("marketBasket.view.MES_DESDE_ULTIMA_COMPRA");
    case "VENTA_MONEDA_PROMEDIO":
      return t("marketBasket.view.VENTA_MONEDA_PROMEDIO");
    case "VENTA_UNIDADES_PROMEDIO":
      return t("marketBasket.view.VENTA_UNIDADES_PROMEDIO");
    case "VENTA_MONEDA":
      return t("marketBasket.view.VENTA_MONEDA");
    case "VENTA_UNIDADES":
      return t("marketBasket.view.VENTA_UNIDADES");
    case "CLIENTES_CON_OPORTUNIDAD":
      return t("marketBasket.view.CLIENTES_CON_OPORTUNIDAD");
    case "OPORTUNIDAD":
      return t("marketBasket.view.OPORTUNIDAD");
    case "OPORTUNIDAD_PARA_FILTRO":
      return t("marketBasket.view.OPORTUNIDAD_PARA_FILTRO");
    default:
      return columna;
  }
};

export const renderCellMarketBasket = (
  params,
  Stack,
  Typography,
  onClickSKU,
  SearchIcon,
  t
) => {
  switch (params.field) {
    case "COD_SKU":
      return (
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              color: "var(--text-success)",
              cursor: "pointer",
              display: "flex",
              fontSize: "15px",
              fontWeight: "600",
              justifyContent: "center",
            }}
            onClick={() => onClickSKU(params.row.COD_SKU)}
          >
            {params.value}
            <SearchIcon
              sx={{ fontSize: "20px", marginTop: "1px", marginLeft: "5px" }}
            ></SearchIcon>
          </Typography>
        </Stack>
      );
    case "COGS_PORCENTUAL":
      return (
        <Stack direction="row" alignItems="center">
          {convertUnit("porcentaje", params.value)}
        </Stack>
      );
    case "CONFIANZA":
      return (
        <Stack direction="row" alignItems="center">
          {convertUnit("porcentaje", params.value)}
        </Stack>
      );
    case "PRECIO_UNITARIO":
      return (
        <Stack direction="row" alignItems="center">
          {formatNumberToMoney(params.value)}
        </Stack>
      );
    case "VENTA_BRUTA_POTENCIAL":
      return (
        <Stack direction="row" alignItems="center">
          {formatNumberToMoney(params.value)}
        </Stack>
      );
    case "CONTRIBUCION_BRUTA_POTENCIAL":
      return (
        <Stack direction="row" alignItems="center">
          {formatNumberToMoney(params.value)}
        </Stack>
      );
    case "VOLUMEN_PROMEDIO":
      return (
        <Stack direction="row" alignItems="center">
          {parseFloat(params.value).toFixed(2)}
        </Stack>
      );
    case "CONTRIBUCION_BRUTA_UNITARIA":
      return (
        <Stack direction="row" alignItems="center">
          {formatNumberToMoney(params.value)}
        </Stack>
      );
    case "ROTACION_PROMEDIO":
      return (
        <Stack direction="row" alignItems="center">
          {parseFloat(params.value).toFixed(2)}
        </Stack>
      );
    case "FECHAS":
      return (
        <Stack
          direction="row"
          alignItems="center"      
        >
          {transformText(convertArrayToText(params.value), 10, t)}
        </Stack>
      );
    case "ANTECEDENTS":
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            whiteSpace: "normal",
            height: "80px",
            textAlign: "center",
            verticalAlign: "middle",
            overflowY: params.value.length > 2 ? "scroll" : "auto",
          }}
        >
          {convertArrayToText(params.value)}
        </Stack>
      );
    case "ANTECEDENT SUPPORT":
      return (
        <Stack direction="row" alignItems="center">
          {convertUnit("porcentaje", params.value, 1)}
        </Stack>
      );
    case "SKU SUPPORT":
      return (
        <Stack direction="row" alignItems="center">
          {convertUnit("porcentaje", params.value, 1)}
        </Stack>
      );
    case "SKU & ANTECEDENTS SUPPORT":
      return (
        <Stack direction="row" alignItems="center">
          {convertUnit("porcentaje", params.value, 1)}
        </Stack>
      );
    case "CONFIDENCE":
      return (
        <Stack direction="row" alignItems="center">
          {convertUnit("porcentaje", params.value, 1)}
        </Stack>
      );
    case "LIFT":
      return (
        <Stack direction="row" alignItems="center">
          {parseFloat(params.value).toFixed(2)}
        </Stack>
      );
    case "LEVERAGE":
      return (
        <Stack direction="row" alignItems="center">
          {parseFloat(params.value).toFixed(2)}
        </Stack>
      );
    case "CONVICTION":
      return (
        <Stack direction="row" alignItems="center">
          {parseFloat(params.value).toFixed(2)}
        </Stack>
      );
    case "ZHANGS_METRIC":
      return (
        <Stack direction="row" alignItems="center">
          {parseFloat(params.value).toFixed(2)}
        </Stack>
      );
    case "VENTA_UNIDADES_PROMEDIO":
      return (
        <Stack direction="row" alignItems="center">
          {parseFloat(params.value).toFixed(2)}
        </Stack>
      );
    case "VENTA_MONEDA":
      return (
        <Stack direction="row" alignItems="center">
          {formatNumberToMoney(params.value)}
        </Stack>
      );
    case "VENTA_UNIDADES":
      return (
        <Stack direction="row" alignItems="center">
          {parseFloat(params.value).toFixed(2)}
        </Stack>
      );
    case "VENTA_MONEDA_PROMEDIO":
      return (
        <Stack direction="row" alignItems="center">
          {formatNumberToMoney(params.value)}
        </Stack>
      );
    case "CLIENTES_CON_OPORTUNIDAD":
      return (
        <Stack direction="row" alignItems="center">
          {parseFloat(params.value).toFixed(0)}
        </Stack>
      );
    case "OPORTUNIDAD":
      return (
        <Stack direction="row" alignItems="center">
          {transformText(convertArrayToText(params.value), 10, t)}
        </Stack>
      );
    case "OPORTUNIDAD_PARA_FILTRO":
      return (
        <Stack direction="row" alignItems="center">
          {transformText(convertArrayToText(params.value), 10, t)}
        </Stack>
      );
    default:
      return params.value;
  }
};

export const renderCellSimulationMarketBasket = (
  params,
  Stack,
  Typography,
  InputAdornment,
  Input,
  simulationValues,
  handleChangeValue
) => {
  const tasa = simulationValues.find((item) => item.id === params.row.id).tasa;
  const valorValue = simulationValues.find(
    (item) => item.id === params.row.id
  ).valorValue;
  switch (params.field) {
    case "COD_SKU":
      return (
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              color: "var(--text-success)",
              display: "flex",
              fontSize: "15px",
              fontWeight: "600",
              justifyContent: "center",
            }}
          >
            {params.value}
          </Typography>
        </Stack>
      );
    case "VENTA_UNIDADES_PROMEDIO":
      return (
        <Stack direction="row" alignItems="center">
          {parseFloat(params.value).toFixed(2)}
        </Stack>
      );
    case "TASA DE AJUSTE VENTAS":
      return (
        <Input
          endAdornment={<InputAdornment position="start">%</InputAdornment>}
          color="primary"
          type="number"
          sx={{
            "& input": {
              textAlign: "center",
              color: tasa < -100 ? "var(--text-error)" : "var(--text-main)",
            },
          }}
          onChange={(e) => handleChangeValue(e, "tasa", params.row)}
          value={tasa}
          inputProps={{
            min: -100,
          }}
          error={tasa < -100 || tasa === undefined || tasa === ""}
        />
      );
    case "VALOR AJUSTADO VENTAS":
      return (
        <Input
          color="primary"
          type="number"
          onChange={(e) => handleChangeValue(e, "valor", params.row)}
          value={valorValue}
          sx={{
            "& input": {
              textAlign: "center",
              color: valorValue < 0 ? "var(--text-error)" : "var(--text-main)",
            },
          }}
          inputProps={{
            min: 0,
          }}
          error={
            valorValue < 0 || valorValue === undefined || valorValue === ""
          }
        />
      );
    default:
      return params.value;
  }
};
