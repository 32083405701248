import { checkRowsWithoutAssignedCriteria } from "../components/parameterization/utils";
import { createContext, useReducer, useState } from "react";
import { ParameterizationAction, ParameterizationState } from "./types";
import { useApiQuery } from "../hooks/useApiQuery";
import useApi from "../hooks/useApi";
import useDialog from "../hooks/useDialog";
import useLanguage from "./LanguageProvider";
import {
  GET_BUCKETS_URL,
  GET_CHARTS_RESULTS_URL,
  GET_PRORRATEO_RESULTADOS,
  GET_PRORRATEO_RESULTADOS_NO_TRASLADADOS,
  STAGES_URL,
} from "../api/axios";

export const ParameterizationContext = createContext<any>({});

const ParameterizationProvider: any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useLanguage();
  const chartsController = new AbortController();
  const rowsController = new AbortController();
  const rowsNtController = new AbortController();
  const [openConfirmationModal, handleToggleConfirmationModal] = useDialog();
  const [
    openReasignCriteriaConfirmationModal,
    handleToggleReasignCriteriaConfirmationModal,
  ] = useDialog();
  const [openCriteriaModal, handleToggleCriteriaModal] = useDialog();
  const [openEditCriteriaModal, handleToggleEditCriteriaModal] = useDialog();
  const [rowsNt, setRowsNt] = useState<any>([]);

  /********************** Initial State **************************/

  const initialParameterizationState: ParameterizationState = {
    bucketSelectedOption: undefined,
    selectedStage: undefined,
    differentiatedTargets: [],
    configType: "",
    actionType: "",
    tabValue: "GASTOS",
    selectedIteration: {
      iteracion: 1,
      calculado: false,
    },
    rowsWithoutAssignedCriteria: true,
    ntRowsWithoutAssignedCriteria: true,
    pendingTasks: false,
    selectionModel: [],
    configMode: "EDIT",
    criteria: {},
    rowCopyCriteria: {},
    isExpanded: false,
    newName: "",
    openTargetsModal: false,
    isExpenseTargetEqual: true,
  };

  const [parameterizationState, parameterizationDispatch] = useReducer<
    React.Reducer<ParameterizationState, ParameterizationAction>
  >(parameterizationReducer, initialParameterizationState);

  /****************** Apis Info *************************/
  const {
    data: buckets,
    refetch: refetchBuckets,
  } = useApiQuery(
    `${GET_BUCKETS_URL}?etapas=true&porcentaje=true`,
    false,
    t("modelParameterizationTexts.buckets.error")
  );

  const onSuccessGetRows = (rows) => {
    if (rows && rows.resultado) {
      parameterizationDispatch({
        type: "SET_ROWS_WITHOUT_ASSIGNED_CRITERIA",
        payload: checkRowsWithoutAssignedCriteria(rows.resultado),
      });
    }
  };

  const {
    data: rows,
    isLoading: isLoadingRows,
    callApi: getProrationResults,
    error: errorRows,
  } = useApi(
    `${GET_PRORRATEO_RESULTADOS}?bucket=${
      parameterizationState?.bucketSelectedOption?.value
    }&etapa=${
      parameterizationState.selectedStage?.etapa
    }&mostrar_detalle=${parameterizationState.differentiatedTargets.toString()}`,
    "GET",
    t("modelParameterization.getProrationResults.codes"),
    undefined,
    onSuccessGetRows,
    undefined,
    false,
    rowsController
  );

  const onSuccessRowsNt = (data: any) => {
    setRowsNt(data);
    if (data && data.resultado) {
      parameterizationDispatch({
        type: "SET_NT_ROWS_WITHOUT_ASSIGNED_CRITERIA",
        payload: checkRowsWithoutAssignedCriteria(data.resultado),
      });
    }
  };

  const onErrorRowsNt = () => {
    setRowsNt([]);
  };

  const {
    isLoading: isLoadingRowsNt,
    callApi: getProrationResultsNoTrasladados,
    error: errorRowsNt,
  } = useApi(
    `${GET_PRORRATEO_RESULTADOS_NO_TRASLADADOS}?bucket=${parameterizationState?.bucketSelectedOption?.value}&etapa=${parameterizationState?.selectedStage?.etapa}&iteracion=${parameterizationState?.selectedIteration?.iteracion}`,
    "GET",
    t("modelParameterization.getProrationResults.codes"),
    undefined,
    onSuccessRowsNt,
    onErrorRowsNt,
    false,
    rowsNtController
  );

  const {
    data: chartsResults,
    isLoading: chartsResultsLoading,
    callApi: getChartsResults,
  } = useApi(
    `${GET_CHARTS_RESULTS_URL}?bucket_id=${parameterizationState?.bucketSelectedOption?.value}`,
    "GET",
    t("modelParameterization.getProrationResults.codes"),
    undefined,
    undefined,
    undefined,
    false,
    chartsController
  );

  const { data: getCompletedStages, refetch: refetchCompletedStages } =
    useApiQuery(
      STAGES_URL,
      false,
      t("dataManagement.getCompletedStages.error")
    );

  /********************************* Reducer *******************************/

  function parameterizationReducer(
    parameterizationState: ParameterizationState,
    action: ParameterizationAction
  ) {
    switch (action.type) {
      case "SET_BUCKET_SELECTED_OPTION":
        return {
          ...parameterizationState,
          bucketSelectedOption: action.payload,
        };
      case "SET_SELECTED_STAGE":
        return {
          ...parameterizationState,
          selectedStage: action.payload,
        };
      case "SET_DIFFERENTIATED_TARGETS":
        return {
          ...parameterizationState,
          differentiatedTargets: action.payload,
        };
      case "HANDLE_SET_SELECTED_STAGE": {
        localStorage.setItem("selectedStage", JSON.stringify(action.payload));
        return {
          ...parameterizationState,
          differentiatedTargets: [],
          selectedStage: action.payload,
        };
      }
      case "SET_CONFIG_TYPE":
        return {
          ...parameterizationState,
          configType: action.payload,
        };
      case "SET_ACTION_TYPE":
        return {
          ...parameterizationState,
          actionType: action.payload,
        };
      case "SET_TAB_VALUE":
        return {
          ...parameterizationState,
          tabValue: action.payload,
        };
      case "SET_SELECTED_ITERATION":
        return {
          ...parameterizationState,
          selectedIteration: action.payload,
        };
      case "SET_ROWS_WITHOUT_ASSIGNED_CRITERIA":
        return {
          ...parameterizationState,
          rowsWithoutAssignedCriteria: action.payload,
        };
      case "SET_NT_ROWS_WITHOUT_ASSIGNED_CRITERIA":
        return {
          ...parameterizationState,
          ntRowsWithoutAssignedCriteria: action.payload,
        };
      case "SET_PENDING_TASKS":
        return {
          ...parameterizationState,
          pendingTasks: action.payload,
        };
      case "SET_SELECTION_MODEL":
        return {
          ...parameterizationState,
          selectionModel: action.payload,
        };
      case "SET_CONFIG_MODE":
        return {
          ...parameterizationState,
          configMode: action.payload,
        };
      case "SET_CRITERIA":
        return {
          ...parameterizationState,
          criteria: action.payload,
        };
      case "SET_ROW_COPY_CRITERIA":
        return {
          ...parameterizationState,
          rowCopyCriteria: action.payload,
        };
      case "HANDLE_EXPAND":
        return {
          ...parameterizationState,
          isExpanded: !parameterizationState.isExpanded,
        };
      case "SET_NEW_NAME":
        return {
          ...parameterizationState,
          newName: action.payload,
        };
      case "SET_OPEN_TARGETS_MODAL":
        return {
          ...parameterizationState,
          openTargetsModal: action.payload,
        };
      case "SET_IS_EXPENSE_TARGET_EQUAL":
        return {
          ...parameterizationState,
          isExpenseTargetEqual: action.payload,
        };

      default:
        throw new Error(`Acción no reconocida: ${action}`);
    }
  }

  return (
    <ParameterizationContext.Provider
      value={{
        initialParameterizationState,
        parameterizationReducer,
        parameterizationState,
        parameterizationDispatch,
        buckets,
        refetchBuckets,
        openConfirmationModal,
        handleToggleConfirmationModal,
        openReasignCriteriaConfirmationModal,
        handleToggleReasignCriteriaConfirmationModal,
        rows,
        isLoadingRows,
        getProrationResults,
        errorRows,
        chartsResults,
        chartsResultsLoading,
        getChartsResults,
        rowsNt,
        setRowsNt,
        errorRowsNt,
        openCriteriaModal,
        handleToggleCriteriaModal,
        openEditCriteriaModal,
        handleToggleEditCriteriaModal,
        isLoadingRowsNt,
        rowsController,
        rowsNtController,
        chartsController,
        getProrationResultsNoTrasladados,
        refetchCompletedStages,
        getCompletedStages,
      }}
    >
      {children}
    </ParameterizationContext.Provider>
  );
};

export default ParameterizationProvider;
