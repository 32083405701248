import { stepper_container_styles } from "../../../styles/app-styles";
import { stepperData } from "./data";
import { useCompanyParamProvider } from "../../../context/CompanyParamProvider";
import { useEffect } from "react";
import CustomStepper from "../../Stepper/CustomStepper";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import StepperButtons from "../../forms/StepperButtons";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import {
  COMPANY_PARAMETERS_GASTOS_URL,
  COMPANY_PARAMETERS_VENTAS_URL,
  EXCHANGE_RATE_CONFIRMATION_URL,
  RELATIONSHIPS_PARAMS_URL,
} from "../../../api/axios";

const StepperView = ({ children, setIsEditOpen }) => {
  const { t } = useLanguage();
  const {
    activeStep,
    setActiveStep,
    isStepComplete,
    expensesFileName,
    keyExpensesColumns,
    bucketsList,
    getExpensesParams,
    salesFileName,
    keyTransactionalColumns,
    maxLevelDetail,
    keyDescriptiveColumns,
    getSalesParams,
    getOtherFilesParams,
    getRelationshipsParams,
    relationships,
    getExchangeParams,
    exchangeTableData,
    currencySelectedOptionState,
    foreignCurrencySelectedOptionState,
    countrySelectedOptionState,
  } = useCompanyParamProvider();  

  /**************************************** Handlers de botones *************************************************/

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      postExchangeRateParams(undefined, {
        tabla: exchangeTableData,
        moneda: currencySelectedOptionState?.value,
        tipo_de_cambio: foreignCurrencySelectedOptionState,
        pais: countrySelectedOptionState?.value,
      });
    } else if (activeStep === 1) {
      postExpensesParams(undefined, {
        nombre: expensesFileName,
        columnas_clave: keyExpensesColumns,
        buckets: bucketsList,
      });
    } else if (activeStep === 2) {
      postSalesParams(undefined, {
        nombre: salesFileName,
        columnas_clave: {
          ...keyTransactionalColumns,
          ...keyDescriptiveColumns,
        },
        maximo_detalle:
          typeof maxLevelDetail && maxLevelDetail.label
            ? [maxLevelDetail.label]
            : maxLevelDetail.map((item) => item.label),
      });
    } else if (activeStep === 3) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1); //Archivos-columnas
    } else if (activeStep === 4) {      
      postRelationships(undefined, relationships);
    } 
  };

  const handleRestart = () => {
    setActiveStep(0);
  };

  const onFinishClick = () => {
    setIsEditOpen(false);
    handleNext();
  };

  /***************************************** Validaciones de botones **********************************************/

  const isBackButtonDisabled = activeStep === 0;

  const isFinishButtonDisabled = !isStepComplete[`step${activeStep}`];

  const isNextButtonDisabled =
    activeStep === stepperData(t).steps.length ||
    !isStepComplete[`step${activeStep}`];

  /**************************************** Post de datos ********************************************************/

  const onSuccessPost = () => {
    if (activeStep === 0) {
      getExchangeParams();
    } else if (activeStep === 1) {
      getExpensesParams();
    } else if (activeStep === 2) {
      getSalesParams();
    } else if (activeStep === 3) {
      getOtherFilesParams();    
    } else if (activeStep === 4) {
      getRelationshipsParams();
      setIsEditOpen(false);
    }      
    if (activeStep !== 4) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const {
    isLoading: isLoadingPostExchangeRateParams,
    callApi: postExchangeRateParams,
  } = useApi(
    EXCHANGE_RATE_CONFIRMATION_URL,
    "POST",
    t("companyParam.postParam.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const {
    isLoading: isLoadingPostExpensesParams,
    callApi: postExpensesParams,
  } = useApi(
    COMPANY_PARAMETERS_GASTOS_URL,
    "POST",
    t("companyParam.postParam.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const { isLoading: isLoadingPostSalesParams, callApi: postSalesParams } =
    useApi(
      COMPANY_PARAMETERS_VENTAS_URL,
      "POST",
      t("companyParam.postParam.codes"),
      undefined,
      onSuccessPost,
      undefined,
      true
    );

  const { isLoading: isLoadingPostRelationships, callApi: postRelationships } =
    useApi(
      RELATIONSHIPS_PARAMS_URL,
      "POST",
      t("companyParam.postParam.codes"),
      undefined,
      onSuccessPost,
      undefined,
      true
    );

  return (
    <Stack sx={stepper_container_styles}>
      <SimpleBackdrop
        open={
          isLoadingPostExchangeRateParams ||
          isLoadingPostExpensesParams ||
          isLoadingPostSalesParams ||
          isLoadingPostRelationships
        }
        message={`${t("companyParam.postParam.loading")}`}
      />
      <CustomStepper activeStep={activeStep} data={stepperData(t)} />
      {children}
      <StepperButtons
        handleBack={handleBack}
        handleNext={handleNext}
        handleRestart={handleRestart}
        onFinishClick={onFinishClick}
        isBackButtonDisabled={isBackButtonDisabled}
        isFinishButtonDisabled={isFinishButtonDisabled}
        isNextButtonDisabled={isNextButtonDisabled}
      />
    </Stack>
  );
};

export default StepperView;
