import { Button } from "../../buttons";
import { EDIT_PASSWORD_URL } from "../../../api/axios";
import { form_label } from "../../../styles/app-styles";
import { Message } from "../../criterias/configCriteria/types";
import { Typography } from "@mui/material";
import { User } from "../types";
import { useState, useEffect, useRef } from "react";
import CustomInput from "../../forms/CustomInput";
import FormSelectLayout from "../../forms/FormSelectLayout";
import Stack from "@mui/material/Stack";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface EditPasswordProps {
  handleClose: () => void;
  NEW_PASSWORD_URL: any;
  user?: User;
}

const EditPasswordModal = ({
  handleClose,
  user,
  NEW_PASSWORD_URL,
}: EditPasswordProps) => {
  const { t } = useLanguage();

  const onSuccessGetCode = (data) => {
    if (data.detail === "exento") {
      sendCode(undefined);
    } else {
      setStep2(true);
    }
  };

  const { isLoading: isLoadingSendCode, callApi: getCode } = useApi(
    EDIT_PASSWORD_URL,
    "GET",
    t("administratorPanel.users.sendCode.codes"),
    undefined,
    onSuccessGetCode,
    false
  );

  const onErrorCode = () => {
    setCodeVerificationMessage({ type: "error", text: errorCode as string });
  };

  const {
    isLoading: isLoadingPostCode,
    callApi: postCode,
    error: errorCode,
  } = useApi(
    EDIT_PASSWORD_URL,
    "POST",
    t("administratorPanel.users.postCode.codes"),
    undefined,
    handleClose,
    onErrorCode,
    true
  );

  const [newPasswordMessage, setNewPasswordMessage] = useState<Message>();
  const [repeatPasswordMessage, setRepeatPasswordMessage] = useState<Message>();
  const [formComplete, setFormComplete] = useState(false);
  const [step2, setStep2] = useState(false);

  const [newPassword, setNewPassword] = useState<string>("");
  const newPasswordRef: any = useRef(null);

  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const repeatPasswordRef: any = useRef(null);

  const [codeVerificationMessage, setCodeVerificationMessage] =
    useState<Message>();
  const [codeVerification, setCodeVerification] = useState<string>("");
  const codeVerificationRef: any = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const handlePassword = () => {
    newPasswordRef.current.type = showPassword ? "password" : "text";
    setShowPassword((prev) => !prev);
  };

  const handlePasswordRepeat = () => {
    repeatPasswordRef.current.type = showPasswordRepeat ? "password" : "text";
    setShowPasswordRepeat((prev) => !prev);
  };

  //chequea si la contraseña cumple con el formato correcto
  useEffect(() => {
    setNewPasswordMessage(undefined);
    if (newPassword) {
      const validFormatRegExp = new RegExp(
        /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/gm
      );
      const validFormat = validFormatRegExp.test(newPassword);
      if (validFormat) {
        setNewPasswordMessage(undefined);
      } else {
        setNewPasswordMessage({
          type: "error",
          text: t("administratorPanel.users.incorrectFormat"),
        });
      }
    }
  }, [newPassword]);

  //chequea si las contraseñas coinciden
  useEffect(() => {
    setRepeatPasswordMessage(undefined);
    if (repeatPassword) {
      if (repeatPassword === newPassword) {
        setRepeatPasswordMessage(undefined);
      } else {
        setRepeatPasswordMessage({
          type: "error",
          text: t("administratorPanel.users.duplicatedEmail"),
        });
      }
    }
  }, [repeatPassword]);

  const handleFormComplete = () => {
    if (newPassword && repeatPassword) {
      setFormComplete(true);
    } else {
      setFormComplete(false);
    }
  };

  useEffect(() => {
    handleFormComplete();
  }, [newPassword, repeatPassword]);

  const sendPassword = () => {
    getCode();
  };

  const sendCode = (code) => {
    const data = {
      id: user?.id,
      password: newPassword,
      codigo_reseteo_password: code,
    };
    postCode(NEW_PASSWORD_URL, data);
  };

  return (
    <Stack style={{ alignItems: "center", width: "400px", height: "370px" }}>
      {!step2 && (
        <>
          <Stack sx={{ width: "100%", position: "relative" }}>
            <FormSelectLayout
              title={t("administratorPanel.users.newPassword")}
              required={true}
            >
              <CustomInput
                value={newPassword}
                setValue={setNewPassword as (value: string | number) => void}
                inputRef={newPasswordRef}
                placeholder={t("administratorPanel.users.newPassword")}
                required={false}
                type="password"
                autoFocus={true}
              />
            </FormSelectLayout>
            {showPassword ? (
              <VisibilityIcon
                sx={{
                  bottom: "7px",
                  position: "absolute",
                  right: "10px",
                  top: "35px",
                  color: "var(--icon-main)",
                }}
                onClick={handlePassword}
              />
            ) : (
              <VisibilityOffIcon
                sx={{
                  bottom: "7px",
                  position: "absolute",
                  right: "10px",
                  top: "35px",
                  color: "var(--icon-main)",
                }}
                onClick={handlePassword}
              />
            )}
            <Stack sx={{ height: "15px", marginBottom: "6px" }}>
              {newPasswordMessage && (
                <Typography
                  sx={{
                    color: "var(--red)",
                    fontSize: "14px",
                    marginLeft: "2px",
                  }}
                >
                  {t("administratorPanel.users.incorrectPasswordFormat")}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack sx={{ width: "100%", position: "relative" }}>
            <FormSelectLayout
              title={t("administratorPanel.users.repeatPassword")}
              required={true}
            >
              <CustomInput
                value={repeatPassword}
                setValue={setRepeatPassword as (value: string | number) => void}
                inputRef={repeatPasswordRef}
                placeholder={t("administratorPanel.users.repeatPassword")}
                required={false}
                type="password"
                autoFocus={true}
              />
            </FormSelectLayout>
            <Stack sx={{ height: "15px", marginBottom: "6px" }}>
              {repeatPasswordMessage && (
                <Typography
                  sx={{
                    color: "var(--red)",
                    fontSize: "14px",
                    marginLeft: "2px",
                    marginBottom: "5px",
                  }}
                >
                  {t("administratorPanel.users.notMatchingPasswords")}
                </Typography>
              )}
              {showPasswordRepeat ? (
                <VisibilityIcon
                  sx={{
                    bottom: "7px",
                    position: "absolute",
                    right: "10px",
                    top: "35px",
                    color: "var(--icon-main)",
                  }}
                  onClick={handlePasswordRepeat}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{
                    bottom: "7px",
                    position: "absolute",
                    right: "10px",
                    top: "35px",
                    color: "var(--icon-main)",
                  }}
                  onClick={handlePasswordRepeat}
                />
              )}
            </Stack>
          </Stack>
          <div style={{ width: "100%" }}>
            <p style={{ fontSize: "14px", color: "var(--text-main)" }}>
              {t("administratorPanel.users.passwordFormatLabel")}
            </p>
          </div>
          <Button
            title="Aplicar"
            color="blue-greeny"
            type="button"
            onClick={sendPassword}
            disabled={
              !formComplete ||
              newPasswordMessage !== undefined ||
              repeatPasswordMessage !== undefined
            }
          />{" "}
        </>
      )}
      {step2 && (
        <>
          <Stack sx={{ width: "100%", position: "relative" }}>
            <Typography sx={{ ...form_label, fontSize: "18px" }}>
              {t("administratorPanel.users.authCode")}
            </Typography>
            <div style={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "var(--text-main)",
                  marginTop: "30px",
                  marginBottom: "20px",
                }}
              >
                {t("administratorPanel.users.authCodeSentToEmail")}
              </Typography>
            </div>
            <CustomInput
              value={codeVerification}
              setValue={setCodeVerification as (value: string | number) => void}
              inputRef={codeVerificationRef}
              placeholder={t("administratorPanel.users.authCode")}
              required={false}
              type="text"
              autoFocus={true}
            />
            <Stack sx={{ height: "15px", marginBottom: "6px" }}>
              {codeVerificationMessage && (
                <Typography
                  sx={{
                    color: "var(--red)",
                    fontSize: "14px",
                    marginLeft: "2px",
                  }}
                >
                  {t("administratorPanel.users.incorrectAuthCode")}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack sx={{ marginTop: "100px" }}>
            <Button
              title="Validar"
              color="blue-greeny"
              type="button"
              onClick={() => sendCode(codeVerification)}
              disabled={codeVerification.length < 4}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default EditPasswordModal;
