import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../context/LanguageProvider";
import Dialog from "../../dialog/Dialog";
import WhiteShadowBox from "../../layout/WhiteShadowBox";
import CustomizedTable from "../../customizedTable/CustomizedTable";
import { convertToText, transformText } from "../../criterias/utils";
import {
  info_modal_container_styles,
  info_modal_subtitle_styles,
  info_modal_subtitle_value_styles,
  info_modal_title_styles,
  info_section_container_styles,
} from "../../../styles/app-styles";
import { getMatchLabelByCode } from "../utils";
interface ViewInfoProps {
  info: any;
  open: boolean;
  handleClose: () => void;
  handleEdit?: (e: any, id: number) => void;
  handleCopy?: (e: any, id: number) => void;
  title: string | undefined;
  isModelPageModal?: boolean;
}

const ViewInfoCodeMatchingModal = ({
  info,
  open,
  handleClose,
  title,
}: ViewInfoProps) => {
  const { t } = useLanguage();
  const COLUMNS1 = ["Archivo", "Columnas"];
  const ROWS = COLUMNS1.map((column: string, index: number) => {
    return {
      Archivo:
        index === 0 ? info?.izquierda?.archivo?.label : info?.derecha?.archivo?.label,
      Columnas: info ?
        index === 0 
          ? transformText(convertToText(info?.izquierda?.columnas, t), 1000, t)
          : transformText(convertToText(info?.derecha?.columnas, t), 1000, t) : "",
    };
  });

  return (
    <div>
      <Dialog
        open={open}
        handleClose={handleClose}
        title={t("validations.validationsAdmin.viewInfoModal.title")}
      >
        <Stack sx={info_modal_container_styles}>
          <WhiteShadowBox>
            <Typography variant="subtitle1" sx={info_modal_title_styles}>
              {title}
            </Typography>

            <p style={info_modal_subtitle_styles}>
              <span style={info_modal_subtitle_value_styles}>
                {t(
                  "validations.validationsAdmin.codeMatchingCard.completeMatchIndicator"
                )}
              </span>{" "}
              {getMatchLabelByCode(info, t)}
            </p>
          </WhiteShadowBox>
          {ROWS?.length && (
            <Stack sx={info_section_container_styles}>
              <CustomizedTable COLUMNS={COLUMNS1} ROWS={ROWS} width={450} />
            </Stack>
          )}
        </Stack>
      </Dialog>
    </div>
  );
};

export default ViewInfoCodeMatchingModal;
