import { error_no_data_styles } from "../../styles/app-styles";
import Stack from "@mui/material/Stack";
import useLanguage from "../../context/LanguageProvider";

interface NoDataErrorProps {
  text?: string;
}

const NoDataError = ({ text }: NoDataErrorProps) => {
  const { t } = useLanguage();

  return (
    <Stack sx={error_no_data_styles}>
      <p>{text ? text : t("dashboard.noDataError")}</p>
    </Stack>
  );
};

export default NoDataError;
