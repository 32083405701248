import {
  ALLOWED_FILE_NAMES_URL,
  COMPANY_PARAMETERS_FILE_URL,
  COMPANY_PARAMETERS_GASTOS_URL,
  COMPANY_PARAMETERS_PNL_LEVEL_URL,
  COMPANY_PARAMETERS_PNL_URL,
  COMPANY_PARAMETERS_VENTAS_URL,
  CURRENCY_LIST_URL,
  EXCHANGE_RATE_PARAMS_URL,
  FILE_COLUMNS_TYPE_URL,
  RELATIONSHIPS_PARAMS_URL,
} from "../api/axios";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { IdOption } from "../components/criterias/configCriteria/types";
import { MultiValue } from "react-select";
import { PnlElementType } from "../components/companyParameterization/StepperView/data";
import useApi from "../hooks/useApi";
import useLanguage from "./LanguageProvider";

const CompanyParamContext = createContext<any>({});

const CompanyParamProvider: any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useLanguage();

  /**************************** Api Querys **********************************/
  const currencyController = new AbortController();

  useEffect(() => {
    getCountryCurrency();
    refetchAllowedFileNames();
    getExchangeParams();
    getExpensesParams();
    getSalesParams();    
    getOtherFilesParams();
    getRelationshipsParams();
    getFilesListParams()
  }, []);

  const onSuccessPostExchangeParams = (data: any) => {
    setExchangeTableData(data.tabla);
  };

  const {    
    callApi: getCountryCurrency,
    data: dataCountryCurrency,
  } = useApi(
    CURRENCY_LIST_URL,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    isLoading: isLoadingExchangeParams,
    callApi: getExchangeParams,
    data: dataExchangeParams,
  } = useApi(
    EXCHANGE_RATE_PARAMS_URL,
    "POST",
    undefined,
    undefined,
    onSuccessPostExchangeParams,
    undefined,
    false,
    currencyController
  );

  const {
    isLoading: isLoadingGetExpensesParams,
    callApi: getExpensesParams,
    data: dataGetExpensesParams,
  } = useApi(
    COMPANY_PARAMETERS_GASTOS_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    isLoading: isLoadingGetSalesParams,
    callApi: getSalesParams,
    data: dataGetSalesParams,
  } = useApi(
    COMPANY_PARAMETERS_VENTAS_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPostExpensesParams,
    callApi: postExpensesParams,
  } = useApi(
    COMPANY_PARAMETERS_GASTOS_URL,
    "POST",
    t("companyParam.postParam.codes"),
    undefined,
    () => getExpensesParams(),
    undefined,
    false
  );

  const {
    isLoading: isLoadingPnlParams,
    callApi: getPnlParams,
    data: dataGetPnlParams,
  } = useApi(
    COMPANY_PARAMETERS_PNL_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPnlLevels,
    callApi: getPnlLevels,
    data: dataGetPnlLevels,
  } = useApi(
    COMPANY_PARAMETERS_PNL_LEVEL_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    () => getPnlParams(),
    undefined,
    false
  );

  const {
    isLoading: isLoadingOtherFilesParams,
    callApi: getOtherFilesParams,
    data: dataGetOtherFilesParams,
  } = useApi(
    COMPANY_PARAMETERS_FILE_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    callApi: getFilesListParams,
    data: dataGetFilesListParams,
  } = useApi(
    `${FILE_COLUMNS_TYPE_URL}?tipo=TEMPLATE`,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  

  const { data: allowedFileNames, callApi: refetchAllowedFileNames } = useApi(
    ALLOWED_FILE_NAMES_URL,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    isLoading: isLoadingRelationshipsParams,
    callApi: getRelationshipsParams,
    data: dataGetRelationshipsParams,
  } = useApi(
    RELATIONSHIPS_PARAMS_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    undefined,
    undefined,
    false
  );  

  /************************************** Estados generales ************************************/

  const [activeStep, setActiveStep] = useState(0);
  const [isStepComplete, setIsStepComplete] = useState({
    step0: false,
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
  });
  const dataFilesList = []; //TODO: Preguntar por el nombre que viene del back
  const [message, setMessage] = useState("");
  const [stepToEdit, setStepToEdit] = useState<number | undefined>(undefined);

  /*************************************** Estados Step 0 **************************************/
  const countrySelectRef = useRef<any>(null);
  const currencySelectRef = useRef<any>(null);
  const foreignCurrencySelectRef = useRef<any>(null);
  const [exchangeTableData, setExchangeTableData] = useState<any>([]);
  const [countrySelectedOptionState, setCountrySelectedOptionState] = useState<any>();
  const [currencySelectedOptionState, setCurrencySelectedOptionState] =
    useState<any>();
  const [
    foreignCurrencySelectedOptionState,
    setForeignCurrencySelectedOptionState,
  ] = useState<any>([]);
  const [exchangeFileToUpload, setExchangeFileToUpload] = useState<File[]>([]);
  const [country, setCountry] = useState<any>({});


  /*************************************** Estados Step 1 **************************************/

  const expensesFileNameRef = useRef<HTMLInputElement>(null);
  const [expensesFileName, setExpensesFileName] = useState("");
  const [expensesFileToUpload, setExpensesFileToUpload] = useState<File[]>([]);
  const [keyExpensesColumns, setKeyExpensesColumns] = useState<any>({
    monto_gasto: "",
    bucket: "",
    agrupador: "",
  });
  const [bucketsList, setBucketsList] = useState<any[]>([]);

  /*************************************** Estados Step 2 **************************************/

  const salesFileNameRef = useRef<HTMLInputElement>(null);
  const [salesFileName, setSalesFileName] = useState(t("general.sales"));
  const [salesFileToUpload, setSalesFileToUpload] = useState<File[]>([]);
  const [keyTransactionalColumns, setKeyTransactionalColumns] = useState<any>({
    venta_bruta: "",
    venta_kg: "",
    venta_m3: "",
    venta_unidades: "",
    venta_cajas: "",
    venta_tarimas: "",
    cogs: "",
    descuentos: "",
    devoluciones: "",
  });
  const [keyDescriptiveColumns, setKeyDescriptiveColumns] = useState<any>({
    codigo_cliente: "",
    codigo_ruta: "",
    codigo_producto: "",
    marca: "",
    unidad_negocio: "",
    canal: "",
    modelo_atencion: "",
    tipo_ruta: "",
    fecha: "",
  });
  const [maxLevelDetail, setMaxLevelDetail] = useState<
    IdOption | MultiValue<IdOption>
  >();

  /*************************************** Estados Step 3 **************************************/

  const [pnlElements, setPnlElements] = useState<PnlElementType[]>([]);
  const [pnlElementConfig, setPnlElementConfig] = useState<PnlElementType>();
  const [selectedLevel, setSelectedLevel] = useState<number | undefined>(1);
 

  /*************************************** Estados Step 4 **************************************/
  const [fileColumns, setFileColumns] = useState<any[]>([]);

  /*************************************** Estados Step 5 **************************************/
  const [otherFileToUpload, setOtherFileToUpload] = useState<File[]>([]);
  const [filesColumnsTypes, setFilesColumnsTypes] = useState<any[]>([]);

  /*************************************** Estados Step 6 **************************************/
  const [relationships, setRelationships] = useState<any[]>([]);

  /*************************************** Estados Step 7 **************************************/
  const [expensesTargets, setExpensesTargets] = useState<any[]>([]);

  return (
    <CompanyParamContext.Provider
      value={{
        activeStep,
        setActiveStep,
        isStepComplete,
        setIsStepComplete,
        stepToEdit,
        setStepToEdit,
        countrySelectRef,
        currencySelectRef,
        isLoadingExchangeParams,
        getExchangeParams,
        dataExchangeParams,
        currencyController,
        dataCountryCurrency,
        country, setCountry,
        foreignCurrencySelectRef,
        exchangeTableData,
        setExchangeTableData,
        exchangeFileToUpload,
        setExchangeFileToUpload,
        countrySelectedOptionState,
        setCountrySelectedOptionState,
        currencySelectedOptionState,
        setCurrencySelectedOptionState,
        foreignCurrencySelectedOptionState,
        setForeignCurrencySelectedOptionState,
        expensesFileNameRef,
        expensesFileName,
        setExpensesFileName,
        message,
        setMessage,
        keyExpensesColumns,
        setKeyExpensesColumns,      
        isLoadingPnlParams,
        getPnlParams,
        dataGetPnlParams,
        bucketsList,
        setBucketsList,
        pnlElements,
        setPnlElements,
        pnlElementConfig, setPnlElementConfig,   
        isLoadingPnlLevels,
        getPnlLevels,
        dataGetPnlLevels,
        selectedLevel,
        setSelectedLevel,
        salesFileNameRef,
        salesFileName,
        setSalesFileName,
        dataFilesList,
        expensesFileToUpload,
        setExpensesFileToUpload,
        salesFileToUpload,
        setSalesFileToUpload,
        getExpensesParams,
        isLoadingGetExpensesParams,
        dataGetExpensesParams,
        postExpensesParams,
        isLoadingPostExpensesParams,
        keyTransactionalColumns,
        setKeyTransactionalColumns,
        keyDescriptiveColumns,
        setKeyDescriptiveColumns,
        getSalesParams,
        dataGetSalesParams,
        maxLevelDetail,
        setMaxLevelDetail,
        isLoadingOtherFilesParams,
        getOtherFilesParams,
        dataGetOtherFilesParams,        
        dataGetFilesListParams,
        getFilesListParams,
        fileColumns,
        setFileColumns,
        otherFileToUpload,
        setOtherFileToUpload,
        allowedFileNames,
        refetchAllowedFileNames,
        filesColumnsTypes,
        setFilesColumnsTypes,
        relationships,
        setRelationships,
        isLoadingRelationshipsParams,
        getRelationshipsParams,
        dataGetRelationshipsParams,
        expensesTargets,
        setExpensesTargets,
        isLoadingGetSalesParams,
      }}
    >
      {children}
    </CompanyParamContext.Provider>
  );
};

export default CompanyParamProvider;

export const useCompanyParamProvider = (): any => {
  const context = useContext(CompanyParamContext);
  if (!context) {
    throw new Error(
      "useCompanyParamProvider must be used within a CompanyParamProvider"
    );
  }
  return context;
};
