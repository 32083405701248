import "./App.css";
import { CodeVerification } from "./components/codeVerification";
import CriteriaManagement from "./components/criterias/criteriasManagement/CriteriaManagement";
import { DataManagement } from "./components/dataManagement";
import { ExpensesSettings } from "./components/expensesSettings";
import { GroupersProvider } from "./context/GroupersProvider";
import { InfoProvider } from "./context/InfoProvider";
import { LanguageProvider } from "./context/LanguageProvider";
import { Layout } from "./components/layout";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { Login } from "./components/login";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Routes, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import AdministratorPanel from "./components/administrator/AdministratorPanel";
import AdminValidations from "./components/validations/AdminValidations/AdminValidations";
import AnalysisAdmin from "./components/dataModel/analysisManagement/AnalysisAdmin";
import AnalysisExecution from "./components/dataModel/analysisExecution/AnalysisExecution";
import SimulationsRulesAdmin from "./components/delta_phi/simulationRule/SimulationsRulesAdmin";
import ConfigCodesMatching from "./components/validations/configCodesMatching/ConfigCodesMatching";
import MatchCodeDashboard from "./components/validations/MatchCodeDashboard/MatchCodeDashboard";
import Calculation from "./components/parameterization/Calculation/Calculation";
import CalculationReport from "./components/report/CalculationReport";
import Charts from "./components/Charts/AllCharts";
import CompanyParameterization from "./components/companyParameterization/CompanyParameterization";
import CompanyParamProvider from "./context/CompanyParamProvider";
import ConfigChartsAnalysis from "./components/dashboardManagement/configAnalysis/ConfigChartsAnalysis";
import ConfigChartsFiles from "./components/dashboardManagement/configFiles/ConfigChartsFiles";
import ConfigIndicatorsAnalysis from "./components/dashboardManagement/configAnalysis/ConfigIndicatorsAnalysis";
import ConfigIndicatorsFiles from "./components/dashboardManagement/configFiles/ConfigIndicatorsFiles";
import ConfigSimulationRule from "./components/delta_phi/simulationRule/ConfigSimulationRule";
import Dashboard from "./components/dashboard/Dashboard";
import DashboardManagement from "./components/dashboardManagement/DashboardManagement";
import Error from "./components/error";
import GeneratedDownloads from "./components/generatedDownloads/GeneratedDownloads";
import GroupAdmin from "./components/dataModel/groupManagement/GroupAdmin";
import IndicatorsView from "./components/IndicatorsView/IndicatorsView";
import MeasureAdmin from "./components/dataModel/measureManagement/MeasureAdmin";
import PageInProgress from "./components/PageInProgress.tsx";
import ParameterizationProvider from "./context/ParameterizationProvider";
import PersistLogin from "./components/login/PersistLogin.component";
import RequireAuth from "./components/auth/RequireAuth";
import StagesSettings from "./components/parameterization/stagesSettings/StagesSettings";
import TaskManager from "./components/taskManager/TaskManager";
import Home from "./components/home/Home";
import MarketBasketProvider from "./context/MarketBasketProvider";
import MarketBasketAnalysisView from "./components/marketBasket/AnalysisView/MarketBasketAnalysisView";
import MarketBasketAdminProvider from "./context/MarketBasketConfigProvider";
import MarketBasketAnalysisManagement from "./components/marketBasket/admin/MarketBasketAnalysisManagement";
import SimulationAdmin from "./components/delta_phi/simulations/SimulationAdmin";
import SimulationConfig from "./components/delta_phi/simulations/SimulationConfig";
import { SimulationProvider } from "./context/SimulationProvider";
import SalesForecastAdmin from "./components/delta_phi/salesForecast/SalesForecastAdmin";
import SalesForecastResult from "./components/delta_phi/salesForecast/SalesForecastResult";
import MarketBasketAnalysisConfig from "./components/marketBasket/MarketBasketConfig/MarketBasketAnalysisConfig";
import PreDownloadReport from "./components/dataModel/downloadReport/PreDownloadReport";
import ViewDashboardProvider from "./context/ViewDashboardProvider";
import AnalysisDashboard from "./components/viewDashboardNew/AnalysisDashboard";
import ConfigTablesAnalysis from "./components/dashboardManagement/configAnalysis/ConfigTablesAnalysis";
import ConfigTablesFiles from "./components/dashboardManagement/configFiles/ConfigTablesFiles";
import SimulationResult from "./components/delta_phi/simulations/SimulationResult";
import SchemaManagement from "./components/criterias/schemaManagement/SchemaManagement";
import CompanySelector from "./components/company/CompanySelector";
import CostForecastAdmin from "./components/delta_phi/costForecast/CostForecastAdmin";
import CostForecastResult from "./components/delta_phi/costForecast/CostForecastResult";
import ChatBot from "./components/chatBot/ChatBot";
import { ChatBotProvider } from "./context/ChatBotProvider";
import CostStructure from "./components/pnLParameterization/CostStructure/CostStructure";
import PnLDefinition from "./components/pnLParameterization/PnLDefinition/PnLDefinition";
import PnLParamProvider from "./context/PnLParamProvider";
import PreprorationParameterization from "./components/preprorationParameterization/PreprorationParameterization";
import ConfigSchema from "./components/criterias/schemaManagement/configSchema/ConfigSchema";
import ExpensesTargetManagement from "./components/criterias/expensesTarget/ExpensesTargetManagement";
import ExpensesTargetConfig from "./components/criterias/expensesTarget/ExpensesTargetConfig";

/**
 * @component
 * @name App
 * @description - Componente que muestra la aplicación con sus rutas.
 * @returns {JSX.Element}
 * @category App
 */

function App() {
  const queryClient = new QueryClient();
  LicenseInfo.setLicenseKey(
    "7d37cc8b460d3324f9c037b1c6de01e9Tz04MDAzMyxFPTE3MzM0MjUwNzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );

  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <SnackbarProvider maxSnack={3} className="">
          <InfoProvider>
            <Routes>
              <Route element={<PersistLogin />}>
                <Route path="login" element={<Login />} />
              </Route>
              <Route path="unauthorized" element={<Error />} />

              <Route path="verificacion" element={<CodeVerification />} />

              <Route element={<PersistLogin />}>
                <Route element={<RequireAuth />}>
                  <Route path="empresa" element={<CompanySelector />} />
                </Route>
              </Route>

              <Route element={<PersistLogin />}>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route path="/inicio" element={<Home />} />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="/administrador"
                      element={<AdministratorPanel />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="parametrizacion-empresa"
                      element={
                        <CompanyParamProvider>
                          <CompanyParameterization />
                        </CompanyParamProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="administracion-datos"
                      element={<DataManagement />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="configuracion-gastos"
                      element={<ExpensesSettings />}
                    />
                  </Route>
                </Route>

                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route 
                      path="parametrizacion-p-l/estructura-costos"
                      element={
                        <PnLParamProvider>
                          <CostStructure />
                        </PnLParamProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="parametrizacion-p-l/definicion"
                      element={
                        <PnLParamProvider>
                          <PnLDefinition />
                        </PnLParamProvider>
                      }
                    />
                  </Route>
                </Route>
                {/* <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="administracion-criterios"
                      element={<CriteriaManagement />}
                    />
                  </Route>
                </Route> */}
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="administracion-esquemas"
                      element={<SchemaManagement />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="configuracion-esquemas"
                      element={<ConfigSchema />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="receptores-gastos"
                      element={<ExpensesTargetManagement />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="configuracion-receptores"
                      element={<ExpensesTargetConfig />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="criterios-preprorrateo"
                      element={<CriteriaManagement type={"preprorrateo"}/>}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="criterios-prorrateo"
                      element={<CriteriaManagement type={"prorrateo"}/>}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="administracion-validaciones"
                      element={<AdminValidations />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="configuracion/cruce-codigos"
                      element={<ConfigCodesMatching />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="dashboard/cruce-codigos"
                      element={<MatchCodeDashboard />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="parametrizacion-preprorrateo"
                      element={<PreprorationParameterization />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="parametrizacion-modelo/asignacion-etapas"
                      element={<StagesSettings />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="parametrizacion-modelo/calculo"
                      element={
                        <ParameterizationProvider>
                          <Calculation />
                        </ParameterizationProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="parametrizacion-modelo/reporte"
                      element={<CalculationReport />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route path="gestor-tareas" element={<TaskManager />} />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route path="dashboard/tableros" element={<Dashboard />} />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="dashboard/indicadores"
                      element={<IndicatorsView />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route path="dashboard/graficos" element={<Charts />} />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="configurar-gastos-preprorrateo"
                      element={<PageInProgress />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="tablero-control"
                      element={<PageInProgress />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="administracion-dashboard"
                      element={<DashboardManagement />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="configuracion/archivo/indicador"
                      element={<ConfigIndicatorsFiles />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="configuracion/archivo/grafico"
                      element={<ConfigChartsFiles />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="configuracion/archivo/tabla"
                      element={<ConfigTablesFiles />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="configuracion/analisis/indicador"
                      element={<ConfigIndicatorsAnalysis />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="configuracion/analisis/grafico"
                      element={<ConfigChartsAnalysis />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="configuracion/analisis/tabla"
                      element={<ConfigTablesAnalysis />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="data-modelling/administracion-medidas"
                      element={<MeasureAdmin />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="data-modelling/administracion-agrupaciones"
                      element={
                        <GroupersProvider>
                          <GroupAdmin />
                        </GroupersProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="data-modelling/administracion-analisis"
                      element={<AnalysisAdmin />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/*" element={<Error />} />
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="dashboard/indicadores"
                      element={<IndicatorsView />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route path="dashboard/graficos" element={<Charts />} />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="dashboard/highcharts"
                      element={
                        <ViewDashboardProvider>
                          <AnalysisDashboard />
                        </ViewDashboardProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="configurar-gastos-preprorrateo"
                      element={<PageInProgress />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="tablero-control"
                      element={<PageInProgress />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="data-modelling/administracion-medidas"
                      element={<MeasureAdmin />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="data-modelling/administracion-agrupaciones"
                      element={
                        <GroupersProvider>
                          <GroupAdmin />
                        </GroupersProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="data-modelling/administracion-analisis"
                      element={<AnalysisAdmin />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="data-modelling/ejecucion-analisis"
                      element={<AnalysisExecution />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="data-modelling/descargar-reporte"
                      element={<PreDownloadReport />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="/descargas-generadas"
                      element={<GeneratedDownloads />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="market-basket/administracion-analisis"
                      element={<MarketBasketAnalysisManagement />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="market-basket/configuracion-analisis"
                      element={
                        <MarketBasketAdminProvider>
                          <MarketBasketAnalysisConfig />
                        </MarketBasketAdminProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="market-basket/analisis"
                      element={
                        <MarketBasketProvider>
                          <MarketBasketAnalysisView />
                        </MarketBasketProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="delta/sales-forecast"
                      element={<SalesForecastAdmin />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="delta/sales-forecast-resultado"
                      element={
                        <ViewDashboardProvider>
                          <SalesForecastResult />
                        </ViewDashboardProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="delta/cost-forecast"
                      element={
                        <CostForecastAdmin />
                      }
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="delta/cost-forecast-resultado"
                      element={
                        <ViewDashboardProvider>
                          <CostForecastResult />
                        </ViewDashboardProvider>}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="delta/administracion-reglas"
                      element={<SimulationsRulesAdmin />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="delta/administracion-reglas/configuracion-regla"
                      element={<ConfigSimulationRule />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="delta/simulaciones"
                      element={<SimulationAdmin />}
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="delta/configuracion-simulaciones"
                      element={
                        <SimulationProvider>
                          <SimulationConfig />
                        </SimulationProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="delta/simulacion-resultado"
                      element={
                        <ViewDashboardProvider>
                          <SimulationResult />
                        </ViewDashboardProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route element={<RequireAuth />}>
                  <Route path="/" element={<Layout />}>
                    <Route
                      path="chatbot"
                      element={
                        <ChatBotProvider>
                          <ChatBot />
                        </ChatBotProvider>
                      }
                    />
                  </Route>
                </Route>
              </Route>
              <Route element={<RequireAuth />}>
                <Route path="/*" element={<Error />} />
              </Route>
            </Routes>
          </InfoProvider>
        </SnackbarProvider>
      </LanguageProvider>
    </QueryClientProvider>
  );
}

export default App;
