import { createContext, ReactNode, useEffect, useReducer } from "react";
import useLanguage from "./LanguageProvider";

export type ChatBotAction =
  | { type: "SET_MESSAGE"; payload: Message }
  | { type: "UPDATE_FIRST_MESSAGE"; payload: string }
  | { type: "SET_PRINCIPAL_QUESTION"; payload: string }
  | {
      type: "SET_HISTORY_TITLE";
      payload: ChatHistory;
    }
  | {
      type: "SET_HISTORY_TITLE_NAME";
      payload: string;
    }
  | { type: "SET_HISTORY"; payload: ChatHistory[] }
  | { type: "NEW_CHAT" }
  | { type: "SET_HISTORY_TITLE_LOADING"; payload: ChatHistory | null }
  | { type: "CLEAN" };

interface Table {
  columns: string[];
  data: (string | number)[][];
}

interface ChatHistory {
  value: number | null;
  label: string;
  date?: Date | string;
}

interface Message {
  message: string;
  options: string[];
  isSent: boolean;
  message_id?: number;
  table?: Table;
  chart_types?: string[] | undefined;
  chart?: {
    status: string;
    parsedData?: any;
  };
  is_useful?: boolean | null;
  is_valid?: boolean;
  query?: string;
}

interface ChatBot {
  messages: Message[];
  principalQuestion: string;
  historyTitle: ChatHistory;
  history: ChatHistory[];
  historyTitleIsLoading: ChatHistory | null;
}

const ChatBotContext = createContext<any>({});

export const ChatBotProvider = ({ children }: { children: ReactNode }) => {
  const { t, language } = useLanguage();
  const initialChatBotState: ChatBot = {
    messages: [
      {
        message: t("phibot.firstMessage"),
        options: [],
        isSent: false,
      },
    ],
    principalQuestion: "",
    historyTitle: {
      value: null,
      label: "",
      date: "",
    },
    history: [],
    historyTitleIsLoading: null,
  };  

  function chatBotReducer(state: ChatBot, action: ChatBotAction): ChatBot {
    switch (action.type) {
      case "SET_MESSAGE":
        return {
          ...state,
          messages: [...state.messages, action.payload],
        };
      case "UPDATE_FIRST_MESSAGE":
        return {
          ...state,
          messages: state.messages.map((msg, index) =>
            index === 0 ? { ...msg, message: action.payload } : msg
          ),
        };
      case "SET_PRINCIPAL_QUESTION":
        return {
          ...state,
          principalQuestion: action.payload,
        };
      case "SET_HISTORY_TITLE":
        return {
          ...state,
          historyTitle: action.payload,
        };

      case "SET_HISTORY_TITLE_NAME":
        return {
          ...state,
          historyTitle : {
            ...state.historyTitle,
            label: action.payload
          }
        };
      case "SET_HISTORY":
        return {
          ...state,
          history: action.payload,
        };
      case "NEW_CHAT":
        return {
          ...state,
          messages: [
            {
              message: t("phibot.firstMessage"),
              options: [],
              isSent: false,
            },
          ],
          principalQuestion: "",
          historyTitle: {
            value: null,
            label: "",
          },
        };
      case "SET_HISTORY_TITLE_LOADING":
        return {
          ...state,
          historyTitleIsLoading: action.payload,
        };
      case "CLEAN":
        return {
          messages: [
            {
              message: t("phibot.firstMessage"),
              options: [],
              isSent: false,
            },
          ],
          principalQuestion: "",
          historyTitle: {
            value: null,
            label: "",
          },
          history: [],
          historyTitleIsLoading: null,
        };
      default:
        throw new Error(`Unhandled action type`);
    }
  }

  const [chatBotState, chatBotDispatch] = useReducer(
    chatBotReducer,
    initialChatBotState
  );

  useEffect(() => {
    chatBotDispatch({
      type: "UPDATE_FIRST_MESSAGE",
      payload: t("phibot.firstMessage"),
    });
  }, [language, t]);

  return (
    <ChatBotContext.Provider value={{ chatBotState, chatBotDispatch }}>
      {children}
    </ChatBotContext.Provider>
  );
};

export default ChatBotContext;
