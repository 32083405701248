import {
  back_arrow_styles,
  form_layouts_container_styles2,
} from "../../../styles/app-styles";
import {
  ALLOWED_FILE_NAMES_URL,
  EXPENSES_TARGET_PARAMS_URL,
} from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ComponentLayout from "../../layout/ComponentLayout";
import FormLayout from "../../forms/FormLayout";
import MessageBlock from "../../forms/MessageBlock";
import React, { useEffect } from "react";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import TargetsFilesInputs from "./TargetsFilesInputs";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";

const ExpensesTargetConfig = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [message, setMessage] = React.useState({ type: "", text: "" });
  const configMode = localStorage.getItem("configMode");
  const expenseTarget = JSON.parse(localStorage.getItem("expenseTarget") as string);

  const handleBack = () => {
    localStorage.removeItem("expenseTarget");
    localStorage.removeItem("configMode");
    navigate("/receptores-gastos");
  };

  const {
    isLoading: isLoadingPostExpensesParams,
    callApi: postExpensesParams,
  } = useApi(
    EXPENSES_TARGET_PARAMS_URL,
    "POST",
    t("companyParam.postParam.codes"),
    undefined,
    handleBack,
    undefined,
    false
  );

  const {
    isLoading: isLoadingPutExpensesParams,
    callApi: putExpensesParams,
  } = useApi(
    `${EXPENSES_TARGET_PARAMS_URL}/${expenseTarget?.id}`,
    "PUT",
    t("companyParam.postParam.codes"),
    undefined,
    handleBack,
    undefined,
    false
  );

  const handleFinish = (newTarget: any) => {
    setMessage({ type: "", text: "" });   
    if(configMode === "EDIT") {
      putExpensesParams(undefined, newTarget);
    } else {
      postExpensesParams(undefined, newTarget);
    }    
  };

  /********************************** Seteo inicial de variables *************************************/

  const {
    isLoading: isLoadingExpenseTargetParams,
    callApi: getExpenseTargetParams,
    data: dataGetExpenseTargetParams,
  } = useApi(
    EXPENSES_TARGET_PARAMS_URL,
    "GET",
    t("companyParam.getParams.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    setMessage({ type: "", text: "" });
    getExpenseTargetParams();
  }, []);

  const { data: allowedFileNames, callApi: refetchAllowedFileNames } = useApi(
    ALLOWED_FILE_NAMES_URL,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    setMessage({ type: "", text: "" });
    refetchAllowedFileNames(); 
  }, [dataGetExpenseTargetParams]);

  return (
    <ComponentLayout
      title={t("Configuración de receptores de gasto")}
      icon={<ArrowBackIcon sx={back_arrow_styles} onClick={handleBack} />}
    >
      <SimpleBackdrop
        open={isLoadingPostExpensesParams || isLoadingExpenseTargetParams || isLoadingPutExpensesParams}
        message={t("general.loading")}
      />
      <MessageBlock message={message} />
      <Stack sx={form_layouts_container_styles2}>
        <FormLayout width="90%">
          <TargetsFilesInputs
            message={message}
            setMessage={setMessage}
            expensesTargets={dataGetExpenseTargetParams}
            allowedFileNames={allowedFileNames}
            handleFinish={handleFinish}
          />
        </FormLayout>
      </Stack> 
    </ComponentLayout>
  );
};

export default ExpensesTargetConfig;
