import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect, useRef } from "react";
import Select from "../../../forms/Select";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";

const CurrencySelect = ({ setForeignCurrencySelectedOption }) => {
  const currencySelectRef = useRef<any>(null);
  const { t } = useLanguage();
  const {
    dataCountryCurrency,
    countrySelectedOption,
    setCurrencySelectedOptionState,
    dataExchangeParams,
    setForeignCurrencySelectedOptionState,
    foreignCurrencySelectRef,
  } = useCompanyParamProvider();

  const currencyByCountry = dataCountryCurrency?.find((country) => {
    return country.value === countrySelectedOption?.value;
  })?.moneda;

  /* useEffect(() => {
    setCurrencySelectedOptionState(
      dataExchangeParams && dataExchangeParams?.moneda
        ? dataExchangeParams?.moneda
        : currencyByCountry?.value
        ? currencyByCountry
        : undefined
    );
  }, [dataExchangeParams, currencyByCountry]); */

  const onCurrencyChange = (currency: any) => {
    console.log(currency, "currency en onCurrencyChange");
    
    setCurrencySelectedOptionState(currency);
    setForeignCurrencySelectedOptionState([]);
    setForeignCurrencySelectedOption([]);
    foreignCurrencySelectRef?.current?.clearValue();
  };

  const [
    currencySelectedOption,
    setCurrencySelectedOption,
    currencyOptionChangeHandler,
  ] = useSingleSelect(
    (newValue) => onCurrencyChange(newValue),
    dataExchangeParams && dataExchangeParams?.moneda
      ? dataExchangeParams?.moneda
      : currencyByCountry?.value
      ? currencyByCountry
      : undefined
  );

  return (
    <Select
      reference={currencySelectRef}
      isClearable
      styles={selectStyles(currencySelectedOption)}
      options={dataCountryCurrency
        .map((item) => item?.moneda && item.moneda)
        .filter((item) => Object.keys(item).length > 0)}
      className="pp_select_schema"
      onChange={currencyOptionChangeHandler}
      closeMenuOnSelect
      isSearchable
      placeholder={t("general.selectOption")}
      defaultValue={
        dataExchangeParams && dataExchangeParams?.moneda
          ? dataExchangeParams?.moneda
          : currencyByCountry?.value
          ? currencyByCountry
          : undefined
      }
    />
  );
};

export default CurrencySelect;
