import { Box, Skeleton } from "@mui/material";

interface CustomSkeletonProps {
  height: number;
}

const CustomSkeleton = ({ height }: CustomSkeletonProps) => {
  const arr = new Array(height).fill("");

  return (
    <Box mt="15px" sx={{ width: "100%" }}>
      {arr.map((item, i) => (
        <Box key={i}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      ))}
    </Box>
  );
};

export default CustomSkeleton;
