import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import drilldown from "highcharts/modules/drilldown.js";
import HC_more from "highcharts/highcharts-more";
import Treemap from "highcharts/modules/treemap";
import accessibility from "highcharts/modules/accessibility";

exporting(Highcharts);
accessibility(Highcharts);
interface ChatbotChartProps {
  optionsData: any;
}

const ChatbotChart = ({ optionsData }: ChatbotChartProps) => {

  const isTreemap = optionsData?.chart && optionsData.chart.type === "treemap";

  const options = optionsData && {
    ...optionsData,
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: [
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
          ],
        },
      },
    },
  };

  if (isTreemap) {
    drilldown(Highcharts);
    Treemap(Highcharts);
    HC_more(Highcharts);
  }

  return (
    <>
      {options && <HighchartsReact highcharts={Highcharts} options={options} />}
    </>
  );
};

export default ChatbotChart;
