import { grid_container2_styles } from "../../../../styles/app-styles";
import { mergeAndDeleteRepeatedColumns } from "../../utils";
import { Message } from "../../../criterias/configCriteria/types";
import { ITEM_TABLA_URL, TABLAS_URL } from "../../../../api/axios";
import { useState } from "react";
import Button from "../../../buttons/Button";
import FormLayout from "../../../forms/FormLayout";
import Grid from "@mui/material/Grid/Grid";
import GridFiveTitles from "../../../marketBasket/MarketBasketConfig/Shared/GridTitles";
import MessageBlock from "../../../forms/MessageBlock";
import NewColumnRow from "./NewColumnRow";
import NewColumnSelects from "./NewColumnSelects";
import NoLinesComponent from "../../../forms/NoLinesComponent";
import Stack from "@mui/material/Stack";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import { AGREGATION_FUNCTIONS } from "../../data";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";

const NewColumnElement = ({
  dataGetTable,
  handleToggleModal,
  element,
  getTable,
  setElementToEdit,
}) => {
  const { t } = useLanguage();
  const [message, setMessage] = useState<Message>({
    type: "success",
    text: "",
  });

  const handleTitles = () => {
    const isRowWithDivide = element?.agregaciones?.some(
      (row) => row.funcion?.value === "DIVIDIR" || row.funcion === "DIVIDIR"
    );
    const isRowWithSubstract = element?.agregaciones?.some(
      (row) => row.funcion?.value === "RESTAR" || row.funcion === "RESTAR"
    );
    if (isRowWithDivide && !isRowWithSubstract) {
      return [
        t("general.name"),
        t("Valor / Operación"),
        t("Valor fijo / Columnas"),
        t("general.dividingColumns"),
      ];
    } else if (!isRowWithDivide && isRowWithSubstract) {
      return [
        t("general.name"),
        t("Valor / Operación"),
        t("Valor fijo / Columnas"),
        t("general.substractingColumns"),
      ];
    } else if (isRowWithDivide && isRowWithSubstract) {
      return [
        t("general.name"),
        t("Valor / Operación"),
        t("Valor fijo / Columnas"),
        `${t("general.dividingColumns")}/${t("general.substractingColumns")}`,
      ];
    } else {
      return [
        t("general.name"),
        t("Valor / Operación"),
        t("Valor fijo / Columnas"),
      ];
    }
  };
  const [titles, setTitles] = useState(handleTitles());
  const [rows, setRows] = useState(
    element?.agregaciones?.map((row: any) => {
      return {
        nombre: row?.nombre,
        funcion: AGREGATION_FUNCTIONS(t).find((f) => f.value === row?.funcion),
        columnas: row?.columnas,
        columnas_secundarias: row?.columnas_secundarias,
        valor: row?.valor,
      };
    }) || []
  );

  const onSuccessPost = () => {
    getTable(`${TABLAS_URL}${dataGetTable.id}`);
    setElementToEdit(undefined);
    handleToggleModal();
  };

  const { isLoading: isLoadingPostItem, callApi: postItem } = useApi(
    `${TABLAS_URL}${dataGetTable.id}/item`, //?tipo=ANALISIS
    "POST",
    t("indicatorsManagement.addIndicator.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const { isLoading: isLoadingPutItem, callApi: putItem } = useApi(
    ITEM_TABLA_URL(dataGetTable?.id, element?.id), //?tipo=ANALISIS
    "PUT",
    t("indicatorsManagement.addIndicator.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const handleApply = () => {
    const completedInfo = {
      tipo: "NUEVA_COLUMNA",
      agregaciones: rows.map((row: any) => {
        return {
          nombre: row.nombre,
          funcion: row.funcion.value,
          columnas: row?.columnas?.map((column) => column.value),
          columnas_secundarias: row.columnas_secundarias?.map(
            (column) => column.value
          ),
          valor: row?.valor,
        };
      }),
    };
    if (element && element.id) {
      putItem(undefined, completedInfo);
    } else {
      postItem(undefined, completedInfo);
    }
  };

  const isValidApply = () => {
    return rows && rows.length > 0;
  };

  const handleCancel = () => {
    setElementToEdit(undefined);
    handleToggleModal();
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingPostItem || isLoadingPutItem}
        message={t("general.loading")}
      />
      <FormLayout width="100%">
        <MessageBlock message={message} />
        <Grid sx={grid_container2_styles} container spacing={1}>
          <GridFiveTitles titles={titles} />
          <NewColumnSelects
            titles={titles}
            setTitles={setTitles}
            totalColumnsOptions={mergeAndDeleteRepeatedColumns(dataGetTable)}
            rows={rows}
            setRows={setRows}
            setMessage={setMessage}
          />
          {rows && rows.length ? (
            rows.map((row: any, index: number) => (
              <NewColumnRow
                key={index}
                row={row}
                rows={rows}
                setRows={setRows}
                titles={titles}
              />
            ))
          ) : (
            <NoLinesComponent height={"200px"} />
          )}
        </Grid>
      </FormLayout>
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={handleCancel}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={!isValidApply()}
        />
      </Stack>
    </>
  );
};

export default NewColumnElement;
