import "./styles.css";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { transformColumnDate } from "../taskManager/utils";

/**
 * @name transformDataTaskManager
 * @description Función que transforma los datos de la tabla. Debe recibir la data con las key como columnas, o bien se puede enviar un array con las columnas ya predefinidas.
 * @param data
 */
export const transformDataSimpleTable: (
  data: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  t?: any,
  minWidth?: number,
) => {
  columns: GridColDef<any, any, any>[];
  rows: any;
} = (
  data: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  t?: any,
  minWidth?: number,
) => {  
  const tableColumns = optionalColumns
    ? optionalColumns
    : data && data.length > 0
    ? Object.keys(data[0])
    : [];

  const arrayColumns = tableColumns
    ?.filter((item: any) => item !== "id")
    ?.map((columna: string) => ({
      field: columna,
      headerClassName:"es_header_column",
      headerName: columna,
      description: columna,
      minWidth: minWidth ? minWidth : 100,
      flex:
        columna ===
          t("tablesTools.transformDataSimpleTable.message").toUpperCase() ||
        columna ===
          t("tablesTools.transformDataSimpleTable.message").toLowerCase() ||
        columna === t("tablesTools.transformDataSimpleTable.message")
          ? 4
          : 1, //TODO: traducir
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong
          style={{
            color: "white",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {transformColumnNames ? transformColumnNames(columna) : columna}
        </strong>
      ),
      renderCell: (params: any) => (
        <Stack direction="row" alignItems="center">
          {params.field === "mensaje" ? (
            <Typography
              variant="body2"
              sx={{ whiteSpace: "normal", height: "auto", textAlign: "center" }}
            >
              {params.value}
            </Typography>
          ) : (
            params.value
          )}
        </Stack>
      ),
    }));

  const arrayRows =
    data && data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          for (const key in fila) {
            obj[key] = fila[key];
          }
          return obj;
        })
      : [];      

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
