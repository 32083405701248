import { AddButton } from "../../../../../buttons";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getColumnsAverages } from "./getColumnsAverages";
import { getInformationColumn } from "../../../../utils";
import { Grid, Stack, Typography } from "@mui/material";
import { IdOption } from "../../../../../criterias/configCriteria/types";
import { InputLabel } from "../../../../../forms";
import { selectStyles } from "../../../../../criterias/configCriteria/select.styles";
import { useEffect, useRef, useState } from "react";
import DeleteForever from "../../../../../icons/DeleteForever";
import FilterValueRows from "../../../../../dataModel/downloadReport/FilterValueRows";
import FormSelectLayout from "../../../../../forms/FormSelectLayout";
import Select from "../../../../../forms/Select";
import SimpleBackdrop from "../../../../../backdrop/SimpleBackdrop";
import useApi from "../../../../../../hooks/useApi";
import useLanguage from "../../../../../../context/LanguageProvider";
import useSingleSelect from "../../../../../../hooks/useSingleSelect";
import VirtualSelect from "../../../../../criterias/configCriteria/VirtualSelect";
import {
  GET_COLUMN_VALUES,
  POST_GET_AVERAGE_VALUES,
} from "../../../../../../api/axios";

const Step3NewModifyRouteModal = ({
  modificationTypes,
  informationsColumns,
  columnsValues,
  setColumnsValues,
  rowsAveragesValues,
  dataPostGetFilteredRoutes,
  selectedModifyRoutes,
}: any) => {
  const { t } = useLanguage();
  const analysisId = localStorage.getItem("analysisId")
    ? localStorage.getItem("analysisId")
    : undefined;
  const [rowsAverageValuesFilters, setRowsAverageValuesFilters] = useState<
    any[]
  >([]);
  const columnSelectedRef: any = useRef(null);
  const [valuesSelectedOptions, setValuesSelectedOptions] =
    useState<IdOption[]>();
  const [incomingValuesOptions, setIncomingValuesOptions] = useState<any[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[] | null>([]);
  const [isLoadingIncomingValuesOptions, setIsLoadingIncomingValuesOptions] =
    useState(false);
  const [columnsOptions, setColumnsOptions] = useState<any>([]);

  /********************************* CARGA Y OBTENCIÓN DE DATOS *****************************/

  const [
    columnFilterSelectedOption,
    setColumnFilterSelectedOptions,
    columnFilterOptionsChangeHandler,
  ] = useSingleSelect(() => onColumnChange());

  const onSuccessIncomingValuesOptionsResponse = (data: any) => {
    setIncomingValuesOptions(data);
    setIsLoadingIncomingValuesOptions(false);
  };

  const { callApi: postGetIncomingValues } = useApi(
    "",
    "POST",
    undefined,
    undefined,
    onSuccessIncomingValuesOptionsResponse,
    undefined,
    false
  );

  const onSuccessGetAverageValues = (data: any) => {
    if (data) {
      data.id = data._id;
      delete data._id;
      setRowsAverageValuesFilters([data]);
    }
  };

  const {
    isLoading: isLoadingPostGetAverageValues,
    callApi: postGetAverageValues,
  } = useApi(
    "",
    "POST",
    undefined,
    undefined,
    onSuccessGetAverageValues,
    undefined,
    false
  );

  useEffect(() => {
    if (columnsValues.length < 1) {
      setRowsAverageValuesFilters([]);
    }
  }, [columnsValues]);

  useEffect(() => {
    if (columnFilterSelectedOption && analysisId) {
      setIsLoadingIncomingValuesOptions(true);
      const filterBody = {
        filtros: [],
      };

      postGetIncomingValues(
        GET_COLUMN_VALUES(
          Number(analysisId && analysisId),
          String(columnFilterSelectedOption?.label),
          "dataexport"
        ),
        filterBody
      );
    } else {
      setIncomingValuesOptions([]);
    }
  }, [columnFilterSelectedOption, analysisId]);

  const onColumnChange = () => {
    setSelectedValues([]);
    setValuesSelectedOptions(undefined);
  };

  /********************************* MANEJO DE FUNCIONES *****************************/

  const handleDeleteAllFilters = () => {
    setColumnsValues([]);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions([]);
    columnSelectedRef?.current?.clearValue();
    setSelectedValues([]);
  };

  const handleAddColumnValue = () => {
    if (!columnFilterSelectedOption) {
      // Si no hay una opción de columna seleccionada, no se puede agregar un filtro.
      return;
    }
    const newFilter = {
      id: columnFilterSelectedOption?.value,
      column: columnFilterSelectedOption,
      values:
        valuesSelectedOptions && valuesSelectedOptions.length > 0
          ? valuesSelectedOptions
          : undefined,
    };
    const updatedFilters = columnsValues
      ? [
          ...columnsValues.filter(
            (filter) => filter.column.value !== columnFilterSelectedOption.value
          ),
          newFilter,
        ]
      : [newFilter];
    setColumnsValues(updatedFilters);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions([]);
    setSelectedValues([]);
    columnSelectedRef.current.clearValue();
  };

  useEffect(() => {
    if (modificationTypes) {
      const options = modificationTypes.map((type) => {
        return {
          value: getInformationColumn(informationsColumns, type.nombre),
          label: getInformationColumn(informationsColumns, type.nombre),
        };
      });

      setColumnsOptions(
        options.filter((columnLabel) => {
          return !columnsValues.some(
            (valueObj) => valueObj.id === columnLabel.value
          );
        })
      );
    }
  }, [modificationTypes, columnsValues]);

  useEffect(() => {
    if (columnsValues && columnsValues.length > 0) {
      const valores = columnsValues
        .map((item) => ({
          [item.id]: item.values[0].value,
        }))
        .reduce((resultado, objeto) => {
          return { ...resultado, ...objeto };
        }, {});
      const modificationTypeNames = modificationTypes.map(
        (modType) => modType.nombre
      );
      const codRuta =
        informationsColumns &&
        informationsColumns?.find(
          (info) => info.informacion === "Identificador único de ruta"
        )?.columna;
      const filteredData =
        dataPostGetFilteredRoutes &&
        dataPostGetFilteredRoutes
          .filter(
            (item) =>
              selectedModifyRoutes &&
              selectedModifyRoutes.includes(item._id as number)
          )
          .map((item) => {
            const newItem = {
              _id: item[codRuta],
              [getInformationColumn(
                informationsColumns,
                "Centro de Distribución"
              )]:
                item[
                  getInformationColumn(
                    informationsColumns,
                    "Centro de Distribución"
                  )
                ],
            };
            modificationTypeNames.forEach((typeName) => {
              newItem[
                `${getInformationColumn(informationsColumns, typeName)}_OLD`
              ] = item[getInformationColumn(informationsColumns, typeName)];
            });
            return newItem;
          });
      const getAveragesValuesConfig = {
        ...valores,
        [getInformationColumn(informationsColumns, "Centro de Distribución")]:
          filteredData.map(
            (item) =>
              item[
                getInformationColumn(
                  informationsColumns,
                  "Centro de Distribución"
                )
              ]
          ),
        analisis: analysisId,
      };

      postGetAverageValues(POST_GET_AVERAGE_VALUES, getAveragesValuesConfig);
    }
  }, [columnsValues]);

  return (
    <Grid
      container
      sx={{
        width: "900px",
        pl: "60px",
        pr: "60px",
      }}
    >
      <SimpleBackdrop
        open={isLoadingPostGetAverageValues || isLoadingIncomingValuesOptions}
        message={t("simulatorTexts.canceledRoutes.newCancelRouteModal.loading")}
      />
      <Typography
        sx={{
          width: "100%",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {t(
          "simulatorTexts.registerRoutes.newRouteModal.steps.routesToModify"
        ).toUpperCase()}
      </Typography>
      <Stack
        sx={{
          flexDirection: "row",
          width: "100%",
          gap: 2,
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          pt: 2,
          pb: 2,
        }}
      >
        {columnsValues && columnsValues.length > 0 && (
          <Stack
            sx={{
              position: "absolute",
              left: -65,
            }}
          >
            <FormSelectLayout>
              <InputLabel title="" transparent={true}>
                <DeleteForever
                  tooltipPlacement="bottom"
                  onClick={handleDeleteAllFilters}
                  width="30px"
                />
              </InputLabel>
            </FormSelectLayout>
          </Stack>
        )}
        <InputLabel title={t("configCriteria.step1.column")}>
          <Select
            reference={columnSelectedRef}
            styles={
              columnsValues && columnsValues.length < 1
                ? selectStyles(columnsValues)
                : undefined
            }
            options={columnsOptions && columnsOptions}
            className="cc_select"
            name="column"
            onChange={columnFilterOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t("configCriteria.step1.selectColumn")}
            defaultValue={columnFilterSelectedOption}
            isClearable
          />
        </InputLabel>
        <InputLabel title={t("configCriteria.step2.values")}>
          <VirtualSelect
            incomingValuesOptions={
              selectedValues !== null && incomingValuesOptions?.length > 0
                ? incomingValuesOptions.filter(
                    (value) => !selectedValues.includes(value)
                  )
                : []
            }
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            setValuesSelectedOptions={setValuesSelectedOptions}
            isLoadingIncomingValuesOptions={isLoadingIncomingValuesOptions}
            selectOneOption={true}
            isDisabled={incomingValuesOptions.length < 1}
          />
        </InputLabel>
        <Stack
          sx={{
            right: -70,
            position: "absolute",
          }}
        >
          <InputLabel title="" transparent={true}>
            <AddButton
              onClick={handleAddColumnValue}
              disabled={
                (selectedValues !== null && selectedValues.length < 1) ||
                (!columnFilterSelectedOption &&
                  columnFilterSelectedOption === null)
              }
            />
          </InputLabel>
        </Stack>
      </Stack>
      <Stack
        sx={{
          maxHeight: "100px",
          overflowY: "auto",
          width: "100%",
          mt: 1,
          mb: -1,
        }}
      >
        <FilterValueRows
          filters={columnsValues}
          setFilters={setColumnsValues}
        />
      </Stack>
      {columnsValues?.length === 0 && (
        <Stack
          sx={{
            width: "95%",
            height: "7vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: "700", color: "var(--text-info)" }}
          >
            {t(
              "simulatorTexts.modifiedRoutes.step3NewModifyRouteModal.noValuesYet"
            )}
          </Typography>
        </Stack>
      )}
      <Stack
        sx={{
          width: "100%",
          pt: 2,
        }}
      >
        <FormSelectLayout
          title={t(
            "deltaTexts.simulation.step3NewModifyRouteModal.averageSelectedRoutes"
          )}
        >
          <Stack
            sx={{
              width: "100%",
              pt: 2,
              pb: 2,
              height: "125px",
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "var(--bg-secondary)",
              },
              "& .MuiDataGrid-columnHeadersInner span": {
                color: "white",
              },
            }}
          >
            <DataGridPro
              rows={rowsAveragesValues ? rowsAveragesValues : []}
              columns={
                rowsAveragesValues &&
                getColumnsAverages(rowsAveragesValues, informationsColumns)
              }
              rowsPerPageOptions={[5, 10, 20]}
              rowHeight={35}
              componentsProps={{
                panel: {
                  sx: {
                    [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
                      display: "none",
                    },
                  },
                },
              }}
              disableColumnMenu
              hideFooter
              disableSelectionOnClick
            />
          </Stack>
        </FormSelectLayout>
      </Stack>
      {columnsValues && rowsAverageValuesFilters.length < 1 ? (
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography style={{ color: "var(--text-main)", fontWeight: "600" }}>
            {t(
              "simulatorTexts.modifiedRoutes.step3NewModifyRouteModal.valueSelectedInfo"
            )}
          </Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            width: "100%",
            pt: 2,
          }}
        >
          <FormSelectLayout
            title={t(
              "deltaTexts.simulation.step3NewModifyRouteModal.averageRoutesBasesOnSelectedValues"
            )}
          >
            <Stack
              sx={{
                width: "100%",
                pt: 2,
                pb: 2,
                height: "125px",
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "var(--bg-secondary)",
                },
                "& .MuiDataGrid-columnHeadersInner span": {
                  color: "white",
                },
              }}
            >
              <DataGridPro
                rows={rowsAverageValuesFilters ? rowsAverageValuesFilters : []}
                columns={
                  rowsAverageValuesFilters &&
                  getColumnsAverages(
                    rowsAverageValuesFilters,
                    informationsColumns
                  )
                }
                rowsPerPageOptions={[5, 10, 20]}
                rowHeight={35}
                componentsProps={{
                  panel: {
                    sx: {
                      [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
                        display: "none",
                      },
                    },
                  },
                }}
                disableColumnMenu
                hideFooter
                disableSelectionOnClick
              />
            </Stack>
          </FormSelectLayout>
        </Stack>
      )}
    </Grid>
  );
};

export default Step3NewModifyRouteModal;
