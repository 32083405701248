import useLanguage from "../../../context/LanguageProvider";
import ComponentLayout from "../../layout/ComponentLayout";
import SearchInput from "../../forms/SearchInput";
import React, { useEffect, useState } from "react";
import BasicGrid from "../../basicGrid/BasicGrid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import FixedAddButton from "../../buttons/FixedAddButton";
import useDialog from "../../../hooks/useDialog";
import { ConfirmationModal } from "../../dialog";
import useInfoContext from "../../../hooks/useInfoContext";
import { Button } from "../../buttons";
import { useApiQuery } from "../../../hooks/useApiQuery";
import {
  COST_FORECAST_ID_URL,
  GET_COST_FORECAST_URL,
} from "../../../api/axios";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import { filterDataByName } from "../utils";
import InfoIcon from "@mui/icons-material/Info";
import costForecastCard from "./CostForecastCard";
import ViewInfoCostForecastModal from "./ViewInfoCostForecastModal";
import NewCostForecastModal from "./NewCostForecastModal";

const CostForecastAdmin = () => {
  const { userRolData }: any = useInfoContext();
  const { t } = useLanguage();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [openAddCostForecastModal, toggleAddCostForecastModal] = useDialog();
  const [openViewInfoCostForecastModal, toggleViewInfoCostForecastModal] =
    useDialog();
  const [forecastToDelete, setForecastToDelete] = useState<number>();
  const [
    openDeleteForecastConfirmationModal,
    handleToggleDeleteForecastConfirmationModal,
  ] = useDialog();
  const [dataToShow, setDataToShow] = useState<any>();

  /********************** CARGA Y OBTENCIÓN DE DATOS *****************************/

  useEffect(() => {
    localStorage.removeItem("simulationId");
    localStorage.removeItem("salesForecastId");
    localStorage.removeItem("costsForecastId");
    localStorage.removeItem("analysisId");
    localStorage.removeItem("newElementConfig");
    localStorage.removeItem("salesForecastView");
    localStorage.removeItem("costForecastView");
    localStorage.removeItem("simulationView");
    localStorage.removeItem("tabValueEndpoint");
    localStorage.removeItem("previousUrl");
  });

  const { isLoading: isLoadingGetAnalysis, data: dataGetAnalysis } =
    useApiQuery(
      "/simulador/costs_forecast/analisis/",
      true,
      t("dataModel.getAnalysis.error")
    );

  const {
    isLoading: isLoadingGetCostForecasts,
    data: dataGetCostForecast,
    refetch: refetchCostForecast,
  } = useApiQuery(
    GET_COST_FORECAST_URL,
    true,
    t("delta.getCostForecast.codes.400")
  );

  const {
    isLoading: isLoadingDeleteCostForecast,
    callApi: deleteCostForecastById,
  } = useApi(
    COST_FORECAST_ID_URL(forecastToDelete ? forecastToDelete : undefined),
    "DELETE",
    t("delta.deleteCostForecast.codes"),
    undefined,
    refetchCostForecast
  );

  const onSuccessGetCostForecastById = (data: any) => {
    setDataToShow(data);
  };

  const {
    isLoading: isLoadingGetCostForecastById,
    callApi: getCostForecastById,
  } = useApi(
    undefined,
    "GET",
    undefined,
    undefined,
    onSuccessGetCostForecastById,
    undefined,
    false
  );

  const onSuccessGetCostForecastByIdEdit = (data: any) => {
    setDataToShow(data);
    toggleAddCostForecastModal();
  };

  const {
    isLoading: isLoadingGetCostForecastByIdEdit,
    callApi: getCostForecastByIdEdit,
  } = useApi(
    undefined,
    "GET",
    undefined,
    undefined,
    onSuccessGetCostForecastByIdEdit,
    undefined,
    false
  );

  /****************************** MANEJO DE FUNCIONES ******************************/

  const handleToggleViewInfoCostForecastModal = () => {
    toggleViewInfoCostForecastModal();
  };

  const handleCreate = () => {
    toggleAddCostForecastModal();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    filterDataByName(dataGetCostForecast, e.target.value, setFilteredData);
  };

  const handleEdit = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    getCostForecastByIdEdit(COST_FORECAST_ID_URL(id));
    localStorage.setItem("configMode", "EDIT");
  };

  // const handleCopy = (e: React.ChangeEvent<HTMLInputElement>, id: number | undefined) => {
  //     localStorage.setItem("configMode", "COPY");
  // };

  const handleInfo = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    getCostForecastById(COST_FORECAST_ID_URL(id));
    handleToggleViewInfoCostForecastModal();
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setForecastToDelete(id);
    handleToggleDeleteForecastConfirmationModal();
  };

  const handleDelete = () => {
    deleteCostForecastById();
    handleToggleDeleteForecastConfirmationModal();
  };

  return (
    <ComponentLayout
      title={t("deltaTexts.costForecast.title")}
      icon={<SmartDisplayIcon />}
    >
      <SimpleBackdrop
        open={
          isLoadingGetCostForecastById ||
          isLoadingGetAnalysis ||
          isLoadingGetCostForecasts ||
          isLoadingDeleteCostForecast ||
          isLoadingGetCostForecastByIdEdit
        }
        message={t("general.loading")}
      />
      {dataGetCostForecast && dataGetCostForecast.length > 0 && (
        <>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <SearchInput
              value={searchValue}
              handleChange={handleSearch}
              placeholder={t(
                "simulatorTexts.simulationsRulesAdmin.searchByName"
              )}
            />
          </Stack>
          {userRolData &&
            (userRolData.simulador.visualizacion_y_edicion ||
              userRolData.simulador.visualizacion_y_creacion) && (
              <FixedAddButton handleClick={handleCreate} />
            )}
        </>
      )}
      {filteredData?.length || dataGetCostForecast?.length ? (
        <>
          <BasicGrid
            data={filteredData ? filteredData : dataGetCostForecast}
            Card={costForecastCard}
            refetchData={refetchCostForecast}
            handleEdit={
              userRolData &&
              userRolData.simulador.visualizacion_y_edicion &&
              handleEdit
            }
            // handleCopy={handleCopy}
            handleInfo={handleInfo}
            handleDelete={
              userRolData &&
              userRolData.simulador.visualizacion_y_edicion &&
              handleClickOnDelete
            }
            showInfo={true}
            xs={2}
          />
        </>
      ) : (
        <Box
          sx={{
            width: "95%",
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "700",
              color: "var(--text-info)",
              textAlign: "center",
            }}
          >
            {t("deltaTexts.costForecast.costForecastAdmin.noPredictionsYet")}
            <br />
            {t("deltaTexts.costForecast.costForecastAdmin.startCreatingOne")}
          </Typography>
          <Button
            title={
              <Typography
                sx={{
                  fontSize: "15px",
                  ml: 0.5,
                  fontWeight: "bold",
                }}
              >
                {t(
                  "deltaTexts.costForecast.costForecastAdmin.newCostForecast"
                )}
              </Typography>
            }
            width={300}
            color="blue-greeny"
            type="button"
            onClick={handleCreate}
          />
        </Box>
      )}
      {dataToShow && (
        <ViewInfoCostForecastModal
          data={dataToShow}
          open={openViewInfoCostForecastModal}
          handleClose={toggleViewInfoCostForecastModal}
        />
      )}
      <NewCostForecastModal
        open={openAddCostForecastModal}
        handleClose={handleCreate}
        dataGetAnalysis={dataGetAnalysis}
        dataToShow={dataToShow}
        refetchCostsForecast={refetchCostForecast}
        setDataToShow={setDataToShow}
        dataGetCostForecast={dataGetCostForecast}
      />
      <ConfirmationModal
        open={openDeleteForecastConfirmationModal}
        handleClose={handleToggleDeleteForecastConfirmationModal}
        handleAccept={handleDelete}
        message={t(
          "deltaTexts.costForecast.costForecastAdmin.deleteCostForecast"
        )}
        title={t("general.confirm")}
      >
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <InfoIcon color="primary" fontSize="medium" />
          <Typography style={{ color: "var(--text-main)", fontWeight: "600" }}>
            {t(
              "deltaTexts.costForecast.costForecastAdmin.deleteAssociatedSimulationsAndRules"
            )}
          </Typography>
        </Stack>
      </ConfirmationModal>
    </ComponentLayout>
  );
};

export default CostForecastAdmin;
