import { createContext, useReducer } from "react";
import { IdOption } from "../components/criterias/configCriteria/types";

export type SimulationAction =
    | { type: "SET_NOMBRE"; payload: string[] }
    | { type: "SET_REGLA"; payload: Rule }
    | { type: "SET_AUTO_RUN"; payload: boolean }
    | { type: "SET_SALES_FORECAST"; payload: Rule }
    | { type: "SET_PRINCIPAL_COLUMNS"; payload: PrincipalColumns }
    | { type: "SET_DEFAULT_DATA", payload: Simulation }
    | { type: "SET_CONFIGURATIONS", payload: any }
    | { type: "CLEAN" }

interface Rule {
    value: number;
    label: string
}
interface PrincipalColumns {
    informacion: number,
    columna: IdOption,
    is_medida: boolean,  // tipo_valor: "medida" | "valor" | "columna"
    valor?: string
}
interface Simulation {
    nombre: string,
    regla: Rule | undefined,
    ejecucion_automatica: boolean,
    salesForecast: Rule | undefined,
    principalColumns: PrincipalColumns[],
    configurations: any[]
}

const SimulationContext = createContext<any>({});

export const SimulationProvider: any = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const initialSimulationState: Simulation = {
        nombre: "",
        regla: undefined,
        ejecucion_automatica: false,
        salesForecast: undefined,
        principalColumns: [],
        configurations: []
    };

    function simulationReducer(simulationState: Simulation, action: any) {
        switch (action.type) {
            case "SET_NOMBRE":
                return {
                    ...simulationState,
                    nombre: action.payload,
                };
            case "SET_REGLA":
                return {
                    ...simulationState,
                    regla: action.payload,
                };
            case "SET_AUTO_RUN":
                return {
                    ...simulationState,
                    ejecucion_automatica: action.payload
                };
            case "SET_SALES_FORECAST":
                return {
                    ...simulationState,
                    salesForecast: action.payload,
                };
            case "SET_PRINCIPAL_COLUMNS":
                return {
                    ...simulationState,
                    principalColumns: action.payload,
                };
            case "SET_CONFIGURATIONS":
                return {
                    ...simulationState,
                    configurations: action.payload
                };
            case "SET_DEFAULT_DATA":
                return action.payload
            case "CLEAN":
                return {
                    ...simulationState,
                    nombre: "",
                    regla: undefined,
                };
            default:
                throw new Error();
        }
    }

    const [simulationState, simulationDispatch] = useReducer<
        React.Reducer<Simulation, SimulationAction>
    >(simulationReducer, initialSimulationState);
    return (
        <SimulationContext.Provider value={{ simulationState, simulationDispatch }}>{children}</SimulationContext.Provider>
    );
};

export default SimulationContext;
