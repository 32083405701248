import Tooltip from "@mui/material/Tooltip";
import useLanguage from "../../../context/LanguageProvider";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteFileIcon = ({ file, handleDeleteFile }) => {
  const { t } = useLanguage();
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={t("dataManagementTexts.uploadModalContent.deleteFileFromListing")}
      sx={{ verticalAlign: "center", fontSize: "16px" }}
    >
      <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => handleDeleteFile(file.name)}
      >
        <DeleteIcon
          sx={{
            color: "var(--bg-neutral-hover) !important",
            cursor: "pointer",
            transition: "all 1s linear",
            "& :hover": { color: "var(--icon-error)" },
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteFileIcon;
