import { GRAFICOS_ARCHIVOS, INDICADORES_ARCHIVOS } from "../../api/axios";
import { Tab } from "../colorTabs/types";
import { useEffect } from "react";
import { useViewDashboard } from "../../context/ViewDashboardProvider";
import AddCriteriaButton from "../criterias/AddCriteriaButton";
import AnalysisDashboardTabs from "./viewTabs/AnalysisDashboardTabs";
import ChipFilters from "./filters/ChipFilters";
import ColumnsFilterModal from "./filters/ColumnsFilterModal";
import DeleteViewModal from "./DeleteViewModal";
import Grid2 from "@mui/material/Unstable_Grid2";
import NoCardsError from "../error/NoCardsError";
import PeriodFilter from "./filters/PeriodFilter";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import useLanguage from "../../context/LanguageProvider";
import ChartsGrid from "./chartsGrid/ChartsGrid";
import NewElementModal from "./newElement/NewElementModal";
import NewViewModal from "./NewViewModal";
import useApi from "../../hooks/useApi";
interface ViewDashboardProps {
  thereArePeriodFilter: boolean;
  thereAreChipFilters: boolean;
  thereAreViews: boolean;
  thereAreTabs: boolean;
  rolEdit: boolean;
  rolCreate: boolean;
  actualUrl: string;
  ELEMENTS_URL: (vista_id: any) => string;
  ELEMENT_URL: (vista_id: any, elemento_id: any) => string;
  PERIODS_URL: any;
  INCOMING_VALUES: any;
  PUT_ORDEN_ELEMENT_URL: any;
  dashboardType;
}

const ViewDashboard = ({
  thereArePeriodFilter,
  thereAreChipFilters,
  thereAreViews,
  thereAreTabs,
  rolEdit,
  rolCreate,
  actualUrl,
  ELEMENTS_URL,
  ELEMENT_URL,
  PERIODS_URL,
  INCOMING_VALUES,
  PUT_ORDEN_ELEMENT_URL,
  dashboardType,
}: ViewDashboardProps) => {
  const { t } = useLanguage();
  const {
    isLoading,
    toggleNewElementModal,
    viewTabValue,
    tabs,
    setTabs,
    tabValue,
    analysisViews,
    setViewTabs,
    setTabValue,
    setViewTabValue,
    analysisPeriods,
    agrupations,
    setData,
    viewTabs,
  } = useViewDashboard();

  const salesForecastId = localStorage.getItem("salesForecastId")
    ? localStorage.getItem("salesForecastId")
    : undefined;
    const costsForecastId = localStorage.getItem("costsForecastId")
    ? localStorage.getItem("costsForecastId")
    : undefined;
  const simulationId = localStorage.getItem("simulationId")
    ? localStorage.getItem("simulationId")
    : undefined;
  const newElementConfig =
    localStorage.getItem("newElementConfig") &&
    JSON.parse(String(localStorage.getItem("newElementConfig")));

  const handleAdd = () => {
    localStorage.removeItem("newElementConfig");
    localStorage.removeItem("previousUrl");
    toggleNewElementModal();
  };

  const { callApi: putOrder } = useApi(
    PUT_ORDEN_ELEMENT_URL,
    "PUT",
    undefined,
    undefined,
    analysisViews && analysisViews?.refetch,
    undefined,
    false
  );

  useEffect(() => {
    if (thereAreTabs && analysisViews?.data && analysisViews?.data.length > 0) {
      if (tabValue === "SIN ANALISIS") {
        // Validación para que sea solo al ppio
        const tabs: Tab[] = analysisViews?.data.map((analysis) => {
          return {
            value: analysis.id,
            label: analysis.nombre,
          };
        });
        const firstTabValue = tabs[0]?.value;
        const allFirstViewTabs = analysisViews?.data[0]?.vistas?.map(
          (view: any) => {
            return { value: view.id, label: view.nombre };
          }
        );
        const firstViewTabValue =
          allFirstViewTabs && allFirstViewTabs[0]?.value;
        tabs && tabs.length && setTabs(tabs);
        allFirstViewTabs &&
          allFirstViewTabs.length &&
          setViewTabs([
            { value: "DEFAULT", label: "DEFAULT" },
            ...allFirstViewTabs,
          ]);
        newElementConfig
          ? setTabValue(newElementConfig.tabValue)
          : firstTabValue && setTabValue(firstTabValue);

        firstViewTabValue && firstViewTabValue.value !== undefined
          ? setViewTabValue(firstViewTabValue.value)
          : newElementConfig
          ? setViewTabValue(newElementConfig.viewTabValue)
          : setViewTabValue("DEFAULT");
      } else {
        //actualizar igual
        const tabs: Tab[] = analysisViews?.data.map((analysis) => {
          return {
            value: analysis.id,
            label: analysis.nombre,
          };
        });
        tabs && tabs.length && setTabs(tabs);
      }
    }
  }, [analysisViews?.data, thereAreTabs]);

  useEffect(() => {
    if (
      thereAreTabs &&
      analysisViews?.data &&
      analysisViews?.data.length > 0 &&
      tabValue &&
      tabValue !== "SIN ANALISIS"
    ) {
      const analysis = analysisViews?.data?.find(
        (analysis) => analysis.id === tabValue
      );
      const views =
        analysis?.vistas &&
        analysis?.vistas.length > 0 &&
        analysis?.vistas?.map((view: any) => ({
          value: view.id,
          label: view.nombre,
        }));

      views && views.length > 0
        ? setViewTabs(views) //[{ value: "DEFAULT", label: "DEFAULT" }, ...views]
        : setViewTabs([{ value: "DEFAULT", label: "DEFAULT" }]);
    }
  }, [tabValue, analysisViews?.data, analysisViews?.data.length, thereAreTabs]);

  const findAnalysisViewById = (data, id) => {
    return data?.find((item) => item.id === Number(id));
  };

  const findViewById = (analysis, viewTabValue) => {
    return analysis?.vistas?.find((view) => view.id === Number(viewTabValue));
  };

  useEffect(() => {
    if (analysisViews && tabValue && dashboardType) {
      
      let selectedAnalysis;
      if (dashboardType === "ANALISIS") {
        selectedAnalysis = findAnalysisViewById(analysisViews.data, tabValue);
      } else if (
        dashboardType === "SIMULACION" ||
        dashboardType === "SALES_FORECAST" || 
        dashboardType === "COSTS_FORECAST"
      ) {
        const selectedId = simulationId || salesForecastId || costsForecastId || undefined;
        selectedAnalysis = findAnalysisViewById(analysisViews.data, selectedId);
      }

      const selectedView =
        selectedAnalysis && findViewById(selectedAnalysis, viewTabValue);        
      setData(selectedView ? selectedView.elementos : []);
    }
  }, [
    analysisViews?.data,
    analysisViews?.data.length,
    tabValue,
    viewTabValue,
    dashboardType,
  ]);

  return (
    <>
      <SimpleBackdrop open={isLoading} message={t("general.loading")} />
      {thereAreTabs &&
      tabs &&
      tabs.length === 1 &&
      tabValue === "SIN ANALISIS" ? (
        <NoCardsError height={"70vh"} />
      ) : (
        <>
          {thereAreTabs && thereAreViews && analysisViews?.data && (
            <AnalysisDashboardTabs rolEdit={rolEdit} rolCreate={rolCreate} />
          )}
          {analysisViews && (
            <Grid2 container direction={"column"} md={12} mt={1}>
              {thereArePeriodFilter &&
                analysisPeriods?.data &&
                (dashboardType !== "ANALISIS" || viewTabs?.length > 1 || viewTabs[0]?.value !== "DEFAULT") && (
                  <PeriodFilter />
                )}
              {thereAreChipFilters && <ChipFilters />}
              <ChartsGrid
                isUserRolAllowed={rolEdit && rolCreate}
                refetchGetAnalysisViews={analysisViews?.refetch}
                ELEMENT_URL={ELEMENT_URL}
                putOrder={putOrder}
                PUT_ORDEN_ELEMENT_URL={PUT_ORDEN_ELEMENT_URL}
                dashboardType={dashboardType}
              />
            </Grid2>
          )}
        </>
      )}
      {rolEdit &&
        rolCreate &&
        (dashboardType !== "ANALISIS" ? true : viewTabValue !== "DEFAULT") && (
          <AddCriteriaButton handleClick={handleAdd} />
        )}
      {agrupations?.data && (
        <ColumnsFilterModal
          getAnalysisPeriods={analysisPeriods.getData}
          dataGetAgrupations={agrupations.data}
          dataGetAnalysisPeriod={analysisPeriods.data}
          PERIODS_URL={PERIODS_URL}
          INCOMING_VALUES={INCOMING_VALUES}
        />
      )}
      {thereAreViews && <NewViewModal />}
      {ELEMENTS_URL && ELEMENT_URL && analysisViews && (
        <NewElementModal
          ELEMENTS_URL={ELEMENTS_URL}
          ELEMENT_URL={ELEMENT_URL}
          GRAFICOS_ARCHIVOS_URL={GRAFICOS_ARCHIVOS}
          INDICADORES_ARCHIVOS_URL={INDICADORES_ARCHIVOS}
          actualUrl={actualUrl}
          refetchGetAnalysisViews={analysisViews?.refetch}
          dashboardType={dashboardType}
        />
      )}
      {thereAreViews && <DeleteViewModal />}
    </>
  );
};

export default ViewDashboard;
