import { useContext, useEffect, useState } from "react";
import { IdOption } from "../criterias/configCriteria/types";
import SimulationProvider from "../../context/SimulationProvider";
import useSingleSelect from "../../hooks/useSingleSelect";
import useApi from "../../hooks/useApi";
import { INCOMING_VALUES_SALES_FORECAST } from "../../api/axios";

interface ValueFilterColumnSelectProps{
    children: any,
    filters: any,
    defaultValue: any, 
    requiredFilters: any,
}

export default function ValueFilterColumnSelect({ children, filters, defaultValue, requiredFilters } : ValueFilterColumnSelectProps) {
    const [selectedValue, setSelectedValue] = useState<any>([]);
    const [valuesSelectedOptions, setValuesSelectedOptions] = useState<IdOption[]>();
    const analysisId = localStorage.getItem('analysisId') ? localStorage.getItem('analysisId') : undefined
    const { simulationState } = useContext<React.ContextType<typeof SimulationProvider>>(SimulationProvider);

    useEffect(()=>{
        defaultValue && setRequiredColumnFilterSelectedOptions(defaultValue);
    },[defaultValue])
    
    const [
        requiredColumnFilterSelectedOptions,
        setRequiredColumnFilterSelectedOptions,
        requiredColumnFilterOptionsChangeHandler,
    ] = useSingleSelect(undefined, defaultValue ? defaultValue : undefined);

    const {
        isLoading: isLoadingpostGetIncomingValues,
        callApi: postGetIncomingValues,
        data: dataGetIncomingValues,
    } = useApi(
        "",
        "POST",
        undefined,
        undefined,
        undefined,
        undefined,
        false
    );

    useEffect(() => {
        if (analysisId && requiredColumnFilterSelectedOptions) {
            const filtros = {};
            filters && filters.forEach(item => {
                const { column, values } = item;
                filtros[column.label] = values.map((value) => value.value);
            });
            requiredFilters && requiredFilters.forEach(item => {
                const { columna, valor } = item;
                filtros[columna] = valor;
            });
            const filterBody = {
                filtros,
                columna: requiredColumnFilterSelectedOptions?.label
            }            
            postGetIncomingValues(INCOMING_VALUES_SALES_FORECAST(simulationState.salesForecast.id && simulationState.salesForecast.id), filterBody);
        }
    }, [analysisId, requiredColumnFilterSelectedOptions, selectedValue, filters, requiredFilters, simulationState.salesForecast])

    return children({
        selectedValue,
        setSelectedValue,
        valuesSelectedOptions,
        setValuesSelectedOptions,
        dataGetIncomingValues,
        isLoadingpostGetIncomingValues,
        requiredColumnFilterSelectedOptions,
        requiredColumnFilterOptionsChangeHandler,
        setRequiredColumnFilterSelectedOptions
    });
}
