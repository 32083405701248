import ListItem from "@mui/material/ListItem";
import { isOptionSelected, redirectMenuItem } from "./utils";
import {
  list_item_button_styles,
  list_item_icon_styles,
  list_item_selected_styles,
  list_item_text_styles,
} from "../../styles/app-styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "./MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CollapseList from "./CollapseList";
import useLanguage from "../../context/LanguageProvider";
import useApi from "../../hooks/useApi";
import { VIEW_ACCESS_URL } from "../../api/axios";

interface ListItemComponentProps {
  item: any;
  navigate: any;
  open: boolean;
  handleClickOpenMenu: any;
  handleOpenStates: any;
  handleCollapseList: any;
  handleDrawerOpen: any;
  handleDrawerClose: any;
}

const ListItemComponent = ({
  item,
  navigate,
  open,
  handleClickOpenMenu,
  handleOpenStates,
  handleCollapseList,
  handleDrawerOpen,
  handleDrawerClose,
}: ListItemComponentProps) => {
  const { t } = useLanguage();
  const handleClickItemMenu = () => {
    if (item.url === "") {
      handleDrawerOpen();
      handleClickOpenMenu(item.title);
    }
  };
  const { callApi: postAccessView } = useApi(
    VIEW_ACCESS_URL,
    "POST",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  const getViewTitle = (optionTitle: string | undefined) => {
    if (item.url !== "") {
      postAccessView(undefined, {
        vista: item.title,
      });
    } else {
      postAccessView(undefined, {
        vista: `${item.title}/${optionTitle}`,
      });
    }
  };

  const handleItemClick = (event: React.MouseEvent) => {
    if (event.button === 1 && item.url) {
      // Clic con rueda del mouse: abre en una nueva pestaña sin cambiar el foco
      window.open(`/${item.url}`, "", "noopener,noreferrer");
    } else if (event.button !== 1) {
      // Clic izquierdo: navegación estándar
      redirectMenuItem(item, navigate, handleDrawerClose, getViewTitle);
    }
  };

  const renderListWithAnchor = () => {
    return (
      <ListItem
        disablePadding
        sx={list_item_selected_styles(isOptionSelected(item.title, t))}
      >
        <a
          href={`/${item.url}`}
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItemButton sx={list_item_button_styles(open)}>
            <ListItemIcon sx={list_item_icon_styles(open)}>
              <MenuItem
                MenuIcon={item.icon}
                title={item.title}
                handleClickItemMenu={handleClickItemMenu}
              />
            </ListItemIcon>
            {item.url !== "" ? (
              <ListItemText
                primary={item.title}
                sx={list_item_text_styles(open)}
              />
            ) : (
              open && (
                <ListItemButton
                  onClick={() => handleClickOpenMenu(item.title)}
                  sx={{ padding: "0 !important", margin: "0 !important" }}
                >
                  <ListItemText
                    primary={item.title}
                    sx={list_item_text_styles(open)}
                  />
                  {handleOpenStates(item.title) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
              )
            )}
          </ListItemButton>
        </a>
      </ListItem>
    );
  };

  const renderListWithoutAnchor = () => {
    return (
      <ListItem
        disablePadding
        onMouseDown={handleItemClick}
        sx={list_item_selected_styles(isOptionSelected(item.title, t))}
      >
        <ListItemButton sx={list_item_button_styles(open)}>
          <ListItemIcon sx={list_item_icon_styles(open)}>
            <MenuItem
              MenuIcon={item.icon}
              title={item.title}
              handleClickItemMenu={handleClickItemMenu}
            />
          </ListItemIcon>
          {item.url !== "" ? (
            <ListItemText
              primary={item.title}
              sx={list_item_text_styles(open)}
            />
          ) : (
            open && (
              <ListItemButton
                onClick={() => handleClickOpenMenu(item.title)}
                sx={{ padding: "0 !important", margin: "0 !important" }}
              >
                <ListItemText
                  primary={item.title}
                  sx={list_item_text_styles(open)}
                />
                {handleOpenStates(item.title) ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )
          )}
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <div key={item.title}>
      {item.url !== "" ? renderListWithAnchor() : renderListWithoutAnchor()}
      {open && item.url === "" && (
        <CollapseList
          openMenuList={handleOpenStates(item.title)}
          optionsList={() => handleCollapseList(item.title)}
          handleDrawerClose={handleDrawerClose}
          getViewTitle={getViewTitle}
        />
      )}
    </div>
  );
};

export default ListItemComponent;
