import { Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useLanguage from "../../../context/LanguageProvider";
import Dialog from "../../dialog/Dialog";
import { Button } from "../../buttons";
interface ChangeHistoryNameModalProps {
  open: boolean;
  handleClose: () => void;
  changeHistoryName: any;
  selectedHistory: any;
  setSelectedHistory: any;
  newName: string;
  setNewName: (name : string) => void;
}

const ChangeHistoryNameModal = ({
  open,
  handleClose,
  changeHistoryName,
  selectedHistory,
  setSelectedHistory,
  newName,
  setNewName
}: ChangeHistoryNameModalProps) => {
  const { t } = useLanguage();
  
  const handleChangeName = (e: any) => {
    setNewName(e.target.value);
  };

  useEffect(() => {
    selectedHistory &&
      selectedHistory.label &&
      setNewName(selectedHistory?.label);
  }, [selectedHistory]);

  const handleAccept = () => {
    const newConfig = {
      titulo: newName
    }
    changeHistoryName(selectedHistory.value, newConfig)
  }

  const closeAndClean = () => {
    setSelectedHistory(undefined);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      handleClose={closeAndClean}
      maxWidth="xl"
      title={t("phibot.sideBarChatBot.editName")}
    >
      <Stack
        sx={{
          padding: "",
          alignItems: "center",
          width: "500px",
          height: "200px",
          justifyContent: "space-evenly",
        }}
      >
        <TextField
          type="text"
          value={newName}
          onChange={handleChangeName}
          variant="outlined"
          fullWidth
          required
          label={"Nuevo nombre"}
          autoFocus
          error={newName === ""}
          helperText={newName === "" && t("phibot.changeHistoryNameModal.nameCannotBeEmpty")}
          size="small"
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
              margin: 0,
            },
          }}
        />
        <Stack direction="row" sx={{ mt: "40px" }}>
          <Button
            title={t("dialog.cancel")}
            type="button"
            onClick={closeAndClean}
            color="light-grey"
          />
          <Button
            title={t("dialog.accept")}
            type="button"
            onClick={handleAccept}
            color="blue-greeny"
            disabled={newName === ""}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ChangeHistoryNameModal;
