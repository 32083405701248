import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import useLanguage from "../../../context/LanguageProvider";
import { useContext, useEffect, useState } from "react";
import useDialog from "../../../hooks/useDialog";
import { ConfirmationModal } from "../../dialog";
import ChatBotProvider from "../../../context/ChatBotProvider";
import useApi from "../../../hooks/useApi";
import { DELETE_ALL_HISTORY, MESSAGE_BYID_CHATBOT } from "../../../api/axios";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import {
  getEmptyHistoryMessageStyles,
  handleMouseDownSideBarChatbot,
} from "../utils";
import DeleteIcon from "@mui/icons-material/Delete";
import HistorySection from "./HistorySection";
import ChangeHistoryNameModal from "./ChangeHistoryNameModal";
import { IdOption } from "../../criterias/configCriteria/types";
interface SideBarChatBotProps {
  isSidebarOpen: boolean;
  sidebarWidth: number;
  setSidebarWidth: (number: number) => void;
  refetchChatsHistory: () => void;
  getChatHistoryById: any;
  isLoadingMessage: boolean;
  selectedHistory: IdOption | undefined;
  setSelectedHistory: (param: IdOption | undefined) => void;
}

const SideBarChatBot = ({
  isSidebarOpen,
  sidebarWidth,
  setSidebarWidth,
  refetchChatsHistory,
  getChatHistoryById,
  isLoadingMessage,
  selectedHistory,
  setSelectedHistory,
}: SideBarChatBotProps) => {
  const { t } = useLanguage();
  const [anchorElHistory, setAnchorElHistory] = useState<null | HTMLElement>(
    null
  );
  const [openDeleteHistory, handleToggleDeleteHistory] = useDialog();
  const [openChangeNameHistory, handleToggleChangeNameHistory] = useDialog();
  const [openDeleteAllHistory, handleToggleDeleteAllHistory] = useDialog();
  const { chatBotState, chatBotDispatch } =
    useContext<React.ContextType<typeof ChatBotProvider>>(ChatBotProvider);
  const [todayHistory, setTodayHistory] = useState<any[]>();
  const [yesterdayHistory, setYesterdayHistory] = useState<any[]>();
  const [beforeHistory, setBeforeHistory] = useState<any[]>();
  const [sevenDaysPreviousHistory, setSevenDaysPreviousHistory] =
    useState<any[]>();
  const [newName, setNewName] = useState<string>("");

  const settings = [
    t("phibot.sideBarChatBot.see"),
    t("phibot.sideBarChatBot.editName"),
    t("phibot.sideBarChatBot.delete"),
  ];

  /************************* CARGA Y OBTENCIÓN DE DATOS **************************************/

  const onSuccessDeleteChatHistory = () => {
    handleToggleDeleteHistory();
    refetchChatsHistory();
    selectedHistory?.value === chatBotState.historyTitle.value &&
      chatBotDispatch({ type: "NEW_CHAT" });
  };

  const { isLoading: isLoadingDeleteChatHistory, callApi: deleteChatHistory } =
    useApi(
      MESSAGE_BYID_CHATBOT(selectedHistory?.value),
      "DELETE",
      undefined,
      undefined,
      onSuccessDeleteChatHistory,
      undefined,
      false
    );

  const onSuccessChangeChatHistoryName = () => {
    handleToggleChangeNameHistory();
    chatBotDispatch({ type: "SET_HISTORY_TITLE_NAME", payload: newName });
    refetchChatsHistory();
    setNewName("");
  };

  const {
    isLoading: isLoadingChangeChatHistoryName,
    callApi: changeChatHistoryName,
  } = useApi(
    ``,
    "PUT",
    undefined,
    undefined,
    onSuccessChangeChatHistoryName,
    undefined,
    false
  );

  useEffect(() => {
    if (chatBotState && chatBotState.history) {
      const hoy = new Date();
      const hoyISO = hoy.toISOString().split("T")[0];

      const ayer = new Date(hoy);
      ayer.setDate(hoy.getDate() - 1);
      const ayerISO = ayer.toISOString().split("T")[0];

      // Hace 7 días
      const haceSieteDias = new Date(ayer);
      haceSieteDias.setDate(haceSieteDias.getDate() - 7);
      const haceSieteDiasISO = haceSieteDias.toISOString().split("T")[0];

      const haceOchoDias = new Date(hoy);
      haceOchoDias.setDate(haceOchoDias.getDate() - 8);

      // Ordenar por fecha de más nuevo a más antiguo
      const dataOrdenada = chatBotState.history.sort(
        (a: any, b: any) =>
          new Date(b.fecha).getTime() - new Date(a.fecha).getTime()
      );

      // Filtrar los elementos de hoy
      const todayHistory = dataOrdenada.filter((item) =>
        item.fecha.startsWith(hoyISO)
      );

      // Filtrar los elementos de ayer
      const yesterdayHistory = dataOrdenada.filter((item) =>
        item.fecha.startsWith(ayerISO)
      );

      // Filtrar los elementos de los últimos 7 días (excluyendo hoy y ayer)
      const sevenDaysPreviousHistory = dataOrdenada.filter(
        (item) =>
          new Date(item.fecha) >= new Date(haceSieteDiasISO) &&
          new Date(item.fecha) < new Date(ayerISO)
      );

      // Filtrar los elementos de antes de 7 días
      const beforeHistory = dataOrdenada.filter(
        (item) => new Date(item.fecha) < new Date(haceSieteDiasISO)
      );

      // Setear los estados correspondientes
      setTodayHistory(todayHistory);
      setYesterdayHistory(yesterdayHistory);
      setSevenDaysPreviousHistory(sevenDaysPreviousHistory);
      setBeforeHistory(beforeHistory);
    }
  }, [chatBotState.history]);

  const onSuccessDeleteAllHistory = () => {
    chatBotDispatch({ type: "SET_HISTORY", payload: [] });
    handleToggleDeleteAllHistory()
  };

  const { isLoading: isLoadingDeleteHistory, callApi: deleteAllHistory } =
    useApi(
      ``,
      "POST",
      undefined,
      undefined,
      onSuccessDeleteAllHistory,
      undefined,
      false
    );

  /************************* MANEJO DE FUNCIONES **************************************/

  useEffect(() => {
    if (
      !openChangeNameHistory &&
      !openDeleteHistory &&
      (anchorElHistory === null || !anchorElHistory)
    ) {
      setAnchorElHistory(null);
      setSelectedHistory(undefined);
    }
  }, [openChangeNameHistory, openDeleteHistory, anchorElHistory]);

  const handleCloseHistoryMenu = (setting?: any) => {
    setting && handleSetting(setting, selectedHistory);
    setAnchorElHistory(null);
  };

  const handleOpenHistoryMenu = (
    event: React.MouseEvent<HTMLElement>,
    history
  ) => {
    setAnchorElHistory(event.currentTarget);
    setSelectedHistory(history);
  };

  const handleSetting = (setting: string, history: any) => {
    switch (setting) {
      case t("phibot.sideBarChatBot.see"):
        handleItemClick(history);
        break;
      case t("phibot.sideBarChatBot.delete"):
        handleToggleDeleteHistory();
        break;
      case t("phibot.sideBarChatBot.editName"):
        handleToggleChangeNameHistory();
        break;
      default:
        break;
    }
  };

  const deleteHistory = () => {
    deleteChatHistory();
  };

  const handleItemClick = (history: any) => {
    setSelectedHistory(history);
    getChatHistoryById(MESSAGE_BYID_CHATBOT(history.value));
  };

  const changeHistoryName = (id: any, newConfig: any) => {
    changeChatHistoryName(MESSAGE_BYID_CHATBOT(id), newConfig);
  };

  const handleDeleteHistory = () => {
    deleteAllHistory(DELETE_ALL_HISTORY);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: `${sidebarWidth}px`,
        transition: "width 0.3s ease",
        overflowX: "hidden",
        border: "1px solid var(--light-grey)",
        borderTop: "none",
        borderRight: !isSidebarOpen ? "none" : "1px solid var(--light-grey)",
        height: `calc(100vh - 155px)`,
        display: "flex",
        flexDirection: "column",
        resize: "horizontal",
        pointerEvents: isLoadingMessage ? "none" : "auto", // Deshabilitar eventos
        opacity: isLoadingMessage ? 0.5 : 1,
      }}
    >
      {chatBotState.history.length > 0 && (
        <Stack
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 100,
          }}
        >
          <Tooltip title={t("phibot.deleteHistory")}>
            <span>
              <IconButton
                size="medium"
                onClick={handleToggleDeleteAllHistory}
                sx={{
                  "&:hover": {
                    backgroundColor: "var(--light-grey)",
                  },
                }}
              >
                <DeleteIcon
                  color={"primary"}
                  sx={{ fontSize: "30px", transform: "scaleX(-1)" }}
                />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      )}
      <SimpleBackdrop
        open={
          isLoadingChangeChatHistoryName ||
          isLoadingDeleteChatHistory ||
          isLoadingDeleteHistory
        }
        message={t("general.loading")}
      />
      <Stack
        sx={{
          position: "sticky",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          pt: 2.5,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "var(--text-main)",
            fontWeight: "600",
            mb: 1,
            userSelect: "none",
          }}
        >
          {t("phibot.sideBarChatBot.history")}
        </Typography>
        <Divider
          sx={{
            mt: 2,
            width: "100%",
            mb: 1,
          }}
        />
      </Stack>
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden",
          padding: "0px 10px",
        }}
      >
        <Stack
          sx={{
            width: "100%",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          {chatBotState.history && chatBotState.history.length > 0 ? (
            <>
              <HistorySection
                title={t("phibot.sideBarChatBot.today")}
                history={todayHistory}
                selectedHistory={selectedHistory}
                onClickItem={handleItemClick}
                handleOpenMenu={handleOpenHistoryMenu}
              />
              {yesterdayHistory && yesterdayHistory.length > 0 && (
                <HistorySection
                  title={t("phibot.sideBarChatBot.yesterday")}
                  history={yesterdayHistory}
                  selectedHistory={selectedHistory}
                  onClickItem={handleItemClick}
                  handleOpenMenu={handleOpenHistoryMenu}
                />
              )}
              {sevenDaysPreviousHistory &&
                sevenDaysPreviousHistory.length > 0 && (
                  <HistorySection
                    title={t("phibot.sideBarChatBot.sevenDaysPrevious")}
                    history={sevenDaysPreviousHistory}
                    selectedHistory={selectedHistory}
                    onClickItem={handleItemClick}
                    handleOpenMenu={handleOpenHistoryMenu}
                  />
                )}
              {beforeHistory && beforeHistory.length > 0 && (
                <HistorySection
                  title={t("phibot.sideBarChatBot.before")}
                  history={beforeHistory}
                  selectedHistory={selectedHistory}
                  onClickItem={handleItemClick}
                  handleOpenMenu={handleOpenHistoryMenu}
                />
              )}
            </>
          ) : (
            <Stack
              sx={{
                height: `calc(100vh - 295px)`,
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" sx={getEmptyHistoryMessageStyles()}>
                {t("phibot.historySection.notHistoryYet")} {<br />}
                {t("phibot.historySection.doYourFirstConsult")}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Box>
      <Stack
        sx={{
          position: "sticky",
          width: "100%", // O el ancho que desees
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          pt: 2.5,
        }}
      >
        <Divider
          sx={{
            width: "100%",
            mb: 1,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            color: "var(--text-main)",
            fontWeight: "600",
            mb: 2,
            userSelect: "none",
          }}
        >
          PHITO
        </Typography>
      </Stack>
      <Menu
        sx={{
          mt: "25px",
        }}
        id="menu-appbar"
        anchorEl={anchorElHistory}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElHistory)}
        onClose={handleCloseHistoryMenu}
      >
        {settings.map((setting) => (
          <MenuItem
            key={setting}
            onClick={() => handleCloseHistoryMenu(setting)}
            sx={{
              "&:hover": {
                backgroundColor: "var(--light-grey)",
              },
            }}
          >
            <Typography
              textAlign="center"
              sx={{
                color: "var(--text-main)",
              }}
            >
              {setting}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <ConfirmationModal
        open={openDeleteHistory}
        handleClose={handleToggleDeleteHistory}
        handleAccept={() => deleteHistory()}
        message={t("phibot.sideBarChatBot.deleteHistory").toLowerCase()}
        title={t("phibot.sideBarChatBot.deleteHistory")}
      />
      <ConfirmationModal
        open={openDeleteAllHistory}
        handleClose={handleToggleDeleteAllHistory}
        handleAccept={() => handleDeleteHistory()}
        message={t("phibot.sideBarChatBot.deleteAllHistory").toLowerCase()}
        title={t("phibot.sideBarChatBot.deleteAllHistory")}
      />
      <ChangeHistoryNameModal
        open={openChangeNameHistory}
        handleClose={handleToggleChangeNameHistory}
        changeHistoryName={changeHistoryName}
        selectedHistory={selectedHistory}
        setSelectedHistory={setSelectedHistory}
        newName={newName}
        setNewName={setNewName}
      />
      <Box
        onMouseDown={(e) =>
          handleMouseDownSideBarChatbot(e, sidebarWidth, setSidebarWidth)
        }
        sx={{
          position: "absolute",
          width: "5px",
          right: 0,
          top: 0,
          bottom: 0,
          color: "red",
          cursor: "ew-resize",
          zIndex: 2,
          backgroundColor: "transparent",
        }}
      />
    </Box>
  );
};

export default SideBarChatBot;
