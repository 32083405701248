import { AddButton } from "../../buttons";
import { Box, Typography } from "@mui/material";
import { columnOptions, conditionOptions } from "./selectOptions";
import { CustomSelect as Select } from "../../forms";
import { GET_DASHBOARD_COLUMN_VALUES_URL } from "../../../api/axios";
import { getColumnValues } from "./api";
import { handleDeleteById, isDuplicatedColumn, parseFilterId } from "./utils";
import { InputLabel } from "../../forms";
import { selectDisabledStyles, optionalSelectStyles } from "./select.styles";
import { Step2Props, Filter, Message } from "./types";
import { useState, useRef, useEffect } from "react";
import CustomInput from "../../forms/CustomInput";
import data from "./data.json";
import DeleteForever from "../../icons/DeleteForever";
import FilterRow from "./FilterRow";
import FormLayout from "../../forms/FormLayout";
import FormSelectLayout from "../../forms/FormSelectLayout";
import useApi from "../../../hooks/useApi";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useLanguage from "../../../context/LanguageProvider";
import VirtualSelect from "./VirtualSelect";

const Step2 = ({
  isConfigCriteria,
  fileColumnSelectOptions,
  fileSelectedOptions,
  columnFilterSelectedOptions,
  setColumnFilterSelectedOptions,
  columnFilterOptionsChangeHandler,
  conditionSelectedOptions,
  setConditionSelectedOptions,
  conditionOptionsChangeHandler,
  valueFilterRef,
  valueFilter,
  setValueFilter,
  valuesSelectedOptions,
  setValuesSelectedOptions,
  selectedValues,
  setSelectedValues,
  filterTypeSelectedOptions,
  setFilterTypeSelectedOptions,
  filterTypeOptionsChangeHandler,
  filters,
  setFilters,
  external,
  width,
  validationsStep4Completed,
  externalFileSelectedOptions,
  isAnalysis,
}: Step2Props) => {
  const columnSelectRef: any = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const conditionSelectRef: any = useRef(null);
  const filterTypeSelectRef: any = useRef(null);
  const [incomingValuesOptions, setIncomingValuesOptions] = useState<any[]>([]);
  const [message, setMessage] = useState<Message>();
  const [isLoadingIncomingValuesOptions, setIsLoadingIncomingValuesOptions] =
    useState(false);

  const { t } = useLanguage();

  //Cuando se selecciona una columna, chequea que no exista ya en el state de filters
  useEffect(() => {
    if (columnFilterSelectedOptions) {
      const columnId = columnFilterSelectedOptions.value;
      const isDuplicated = isDuplicatedColumn(columnId, filters);
      if (isDuplicated) {
        setMessage({
          type: "info",
          text: t("configCriteria.step2.repeatedFilterError"),
        });
      } else {
        setMessage(undefined);
      }
    }
  }, [columnFilterSelectedOptions]);

  const incomingValuesOptionsResponse = (data: any) => {
    setIncomingValuesOptions(data);
    setIsLoadingIncomingValuesOptions(false);
  };

  const { callApi: getAnalysisColumnValues } = useApi(
    GET_DASHBOARD_COLUMN_VALUES_URL(fileSelectedOptions?.value),
    "POST",
    undefined,
    {
      filtros: {},
      columna: columnFilterSelectedOptions?.label
    },
    incomingValuesOptionsResponse,
    undefined,
    false
  ); 

  //Este useEffect está atento a escuchar cuando se modifica el valor de la columna seleccionada y mediante una llamada al backend trae todos los valores correspondientes a esa columna y los setea en el state incomingValuesOptions
  useEffect(() => {
    if (columnFilterSelectedOptions) {
      setIsLoadingIncomingValuesOptions(true);      
      isAnalysis? getAnalysisColumnValues(GET_DASHBOARD_COLUMN_VALUES_URL(fileSelectedOptions?.value)) :
      getColumnValues(
        axiosPrivate,
        columnFilterSelectedOptions.value,
        incomingValuesOptionsResponse
      );
    } else {
      setIncomingValuesOptions([]);
    }
  }, [columnFilterSelectedOptions]);

  //Al cambiar el valor del Select Condition, se cambian todos los valores de esa columna con el valor seleccionado.
  useEffect(() => {
    if (conditionSelectedOptions && filters) {
      setFilters(
        filters.map((filter) => {
          filter.condition = conditionSelectedOptions.value;
          return filter;
        })
      );
    }
  }, [conditionSelectedOptions]);

  //Si validationsStep4Completed es false, se limpian todos los filtros, los select y los ref correspondientes.
  useEffect(() => {
    if (external) {
      if (!validationsStep4Completed) {
        setFilters([]);
        setColumnFilterSelectedOptions(undefined);
        setConditionSelectedOptions(undefined);
        setValuesSelectedOptions(undefined);
        setFilterTypeSelectedOptions(undefined);
        conditionSelectRef.current?.clearValue();
        columnSelectRef.current?.clearValue();
        setSelectedValues([]);
        filterTypeSelectRef.current?.clearValue();
      }
    }
  }, [validationsStep4Completed]);

  //Al seleccionar el botón de MÁS, se valida que no hayan campos vacíos, se valida que no se repita el filtro y se setea en el state filters.  Luego se limpian los Select.
  const handleAddFilter = () => {
    setMessage(undefined);
    if (
      (filters && filters.length > 0 && !conditionSelectedOptions) ||
      !columnFilterSelectedOptions ||
      ((filterTypeSelectedOptions?.value ===
        t("configCriteria.step2.include") ||
        filterTypeSelectedOptions?.value ===
          t("configCriteria.step2.exclude")) &&
        !valuesSelectedOptions) ||
      ((filterTypeSelectedOptions?.value ===
        t("configCriteria.step2.include") ||
        filterTypeSelectedOptions?.value ===
          t("configCriteria.step2.exclude")) &&
        valuesSelectedOptions?.length === 0) ||
      !filterTypeSelectedOptions ||
      (filterTypeSelectedOptions?.value !== t("configCriteria.step2.include") &&
        filterTypeSelectedOptions?.value !==
          t("configCriteria.step2.exclude") &&
        filterTypeSelectedOptions?.value !== undefined &&
        (valueFilter === undefined || valueFilter.toString().length === 0))
    ) {
      setMessage({
        type: "error",
        text: data.filterMessages.emptyError,
      });
      return;
    }
    const newFilters: any[] = [];
    //Si el filtro contiene una columna que ya existe, se pushea a newFilters todos los filters menos el que está duplicado
    if (
      filters &&
      isDuplicatedColumn(columnFilterSelectedOptions?.value, filters)
    ) {
      newFilters.push(
        ...filters.filter(
          (filter) => filter.column.value !== columnFilterSelectedOptions?.value
        )
      );
    } else {
      filters && newFilters.push(...filters);
    }

    const filter: Filter = {
      id: parseFilterId(
        columnFilterSelectedOptions?.label as string,
        filterTypeSelectedOptions?.value as string,
        valuesSelectedOptions,
        valueFilter
      ),
      condition: conditionSelectedOptions?.value,
      column: columnFilterSelectedOptions,
      values:
        valuesSelectedOptions && valuesSelectedOptions?.length > 0
          ? valuesSelectedOptions
          : [{ value: valueFilter, label: valueFilter }],
      filterType: filterTypeSelectedOptions?.value,
    };
    newFilters.push(filter);
    setFilters(newFilters);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions([]);
    setFilterTypeSelectedOptions(undefined);
    columnSelectRef.current.clearValue();
    setSelectedValues([]);
    filterTypeSelectRef.current.clearValue();
  };

  //Elimina todos los filtros del state filters y limpia los Select.
  const handleDeleteAllFilters = () => {
    setFilters([]);
    setMessage(undefined);
    setConditionSelectedOptions(undefined);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions([]);
    setFilterTypeSelectedOptions(undefined);
    conditionSelectRef.current.clearValue();
    columnSelectRef.current.clearValue();
    setSelectedValues([]);
    filterTypeSelectRef.current.clearValue();
  };

  return (
    <FormLayout
      width={width}
      backgroundColor={
        external && !validationsStep4Completed ? "var(--bg-light)" : "white"
      }
    >
      <div className="cc_filters_container">
        {external && (
          <div className="cc_filters_title">
            <h3>
              {t("configCriteria.step2.previousFilter")}
              {externalFileSelectedOptions &&
                t("configCriteria.step2.in") +
                  externalFileSelectedOptions?.label}
            </h3>
          </div>
        )}
        {message && (
          <div className="cc_info_filters_box">
            <p className={`cc_info_filters_${message.type}`}>{message.text}</p>
          </div>
        )}
        <div className="cc_filter_row_container rowWithoutPadding">
          <div className="cc_filter_column_6"></div>
        </div>
        <div className="cc_filter_row_container">
          {filters && filters.length > 0 && (
            <div className="cc_filter_column_1">
              <FormSelectLayout>
                <InputLabel title="" transparent={true}>
                  <div>
                    <DeleteForever
                      tooltipPlacement="bottom"
                      onClick={handleDeleteAllFilters}
                    />
                  </div>
                </InputLabel>
              </FormSelectLayout>
            </div>
          )}
          <div className="cc_filter_column_2">
            <FormSelectLayout>
              <InputLabel
                title={t("configCriteria.step2.condition")}
                hasTooltip={true}
                tooltipText={t("configCriteria.step2.conditionTooltip")}
                disabled={
                  !filters ||
                  filters.length === 0 ||
                  (external && !validationsStep4Completed)
                }
              >
                {filters && filters.length !== 0 && (
                  <Select
                    reference={conditionSelectRef}
                    styles={
                      external && !validationsStep4Completed
                        ? selectDisabledStyles(conditionSelectedOptions)
                        : optionalSelectStyles(conditionSelectedOptions)
                    }
                    options={conditionOptions(data.incomingConditionOptions)}
                    className="cc_select"
                    name="condition"
                    onChange={conditionOptionsChangeHandler}
                    closeMenuOnSelect
                    placeholder=""
                    defaultValue={conditionSelectedOptions}
                    isDisabled={external && !validationsStep4Completed}
                    isClearable
                  />
                )}
              </InputLabel>
            </FormSelectLayout>
          </div>
          <div className="cc_filter_column_3">
            <FormSelectLayout>
              <InputLabel
                title={t("configCriteria.step1.column")}
                disabled={external && !validationsStep4Completed}
              >
                <Select
                  reference={columnSelectRef}
                  styles={
                    external && !validationsStep4Completed
                      ? selectDisabledStyles(columnFilterSelectedOptions)
                      : optionalSelectStyles(columnFilterSelectedOptions)
                  }
                  options={
                    external
                      ? columnOptions(
                          fileColumnSelectOptions,
                          externalFileSelectedOptions
                        )
                      : columnOptions(
                          fileColumnSelectOptions,
                          fileSelectedOptions
                        )
                  }
                  className="cc_select"
                  name="column"
                  onChange={columnFilterOptionsChangeHandler}
                  closeMenuOnSelect
                  placeholder={t("configCriteria.step1.selectColumn")}
                  defaultValue={columnFilterSelectedOptions}
                  isDisabled={external && !validationsStep4Completed}
                  isClearable
                />
              </InputLabel>
            </FormSelectLayout>
          </div>
          <div className="cc_filter_column_5">
            <FormSelectLayout>
              <InputLabel
                title={t("configCriteria.step2.filterType")}
                disabled={external && !validationsStep4Completed}
              >
                <Select
                  reference={filterTypeSelectRef}
                  styles={
                    external && !validationsStep4Completed
                      ? selectDisabledStyles(filterTypeSelectedOptions)
                      : optionalSelectStyles(filterTypeSelectedOptions)
                  }
                  options={conditionOptions(
                    isConfigCriteria
                      ? data.incomingFilterTypeOptionsCriteria
                      : data.incomingFilterTypeOptions
                  )}
                  className="cc_select"
                  name="filterType"
                  onChange={filterTypeOptionsChangeHandler}
                  closeMenuOnSelect
                  placeholder={t("configCriteria.step2.type")}
                  defaultValue={filterTypeSelectedOptions}
                  isDisabled={external && !validationsStep4Completed}
                  isClearable
                />
              </InputLabel>
            </FormSelectLayout>
          </div>
          {(filterTypeSelectedOptions?.value ===
            t("configCriteria.step2.include") ||
            filterTypeSelectedOptions?.value ===
              t("configCriteria.step2.exclude") ||
            filterTypeSelectedOptions?.value === undefined ||
            !isConfigCriteria) && (
            <div className="cc_filter_column_4">
              <FormSelectLayout>
                <InputLabel
                  title={t("configCriteria.step2.values")}
                  disabled={
                    (external && !validationsStep4Completed) ||
                    filterTypeSelectedOptions?.value === undefined
                  }
                >
                  <VirtualSelect
                    incomingValuesOptions={incomingValuesOptions}
                    selectedValues={selectedValues}
                    setSelectedValues={setSelectedValues}
                    setValuesSelectedOptions={setValuesSelectedOptions}
                    isLoadingIncomingValuesOptions={
                      isLoadingIncomingValuesOptions
                    }
                  />
                </InputLabel>
              </FormSelectLayout>
            </div>
          )}
          {filterTypeSelectedOptions?.value !==
            t("configCriteria.step2.include") &&
            filterTypeSelectedOptions?.value !==
              t("configCriteria.step2.exclude") &&
            filterTypeSelectedOptions?.value !== undefined &&
            valueFilter !== undefined &&
            setValueFilter !== undefined && (
              <div className="cc_filter_column_4">
                <FormSelectLayout>
                  <InputLabel
                    title={t("configCriteria.step2.value")}
                    disabled={external && !validationsStep4Completed}
                  >
                    <CustomInput
                      type="number"
                      name="filterValue"
                      inputRef={valueFilterRef}
                      value={valueFilter}
                      setValue={setValueFilter}
                      required={true}
                      placeholder={t("configCriteria.step2.valuePlaceholder")}
                      autoFocus={true}
                      maxLength={100}
                    />
                  </InputLabel>
                </FormSelectLayout>
              </div>
            )}
          <div className="cc_filter_column_6">
            <InputLabel title="" transparent={true}>
              <AddButton
                onClick={handleAddFilter}
                disabled={external && !validationsStep4Completed}
              />
            </InputLabel>
          </div>
        </div>
        {filters &&
          filters.map((filter, index) => (
            <FilterRow
              key={filter.id}
              id={filter.id}
              condition={filter.condition}
              column={filter.column}
              values={filter.values}
              filterType={filter.filterType}
              index={index}
              handleDeleteFilter={handleDeleteById}
              filters={filters}
              setFilters={setFilters}
            />
          ))}
        {!filters?.length && (
          <Box
            sx={{
              width: "95%",
              height: "10vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "700", color: "var(--text-info)" }}
            >
              {t("configCriteria.step2.noFiltersWarning")}
            </Typography>
          </Box>
        )}
      </div>
    </FormLayout>
  );
};

export default Step2;
