import { truncateString } from "../parameterization/utils";

export const isDuplicatedName = (name: string, list: any[]) => {
  return list?.some(
    (item) => item.nombre.trim().toLowerCase() === name?.trim().toLowerCase()
  );
};

export const handleElementRepeatedName = (
  t,
  elementToEdit,
  newName: string,
  elementsList: string[],
  configMode,
  setMessage
) => {
  if (newName === "" || !newName) {
    setMessage(t("general.requiredField"));
  } else if (elementsList && elementsList.length && newName) {
    const elementNameExists = elementsList?.find(
      (el: any) => el?.trim()?.toLowerCase() === newName.trim()?.toLowerCase()
    );
    const elementIndex = elementToEdit
      ? elementsList.findIndex(
          (el: any) =>
            el?.trim()?.toLowerCase() === elementToEdit?.trim()?.toLowerCase()
        )
      : -1;

    const isOwnName =
      elementIndex !== -1
        ? elementsList[elementIndex]?.trim().toLowerCase() ===
          newName?.trim()?.toLowerCase()
        : false;

    if (elementNameExists && configMode === "CREATE") {
      setMessage(t("general.repeatedName"));
    } else if (elementNameExists && configMode === "EDIT" && !isOwnName) {
      setMessage(t("general.repeatedName"));
    } else {
      setMessage("");
    }
  } else {
    setMessage("");
  }
};

export const handleElementRepeatedNameValueLabel = (
  t,
  elementToEdit: {
    value: string;
    label: string;
  },
  newName: string,
  elementsList: any[],
  configMode,
  setMessage
) => {
  if (newName === "" || !newName) {
    setMessage(t("general.requiredField"));
  } else if (elementsList && newName) {
    const elementNameExists = elementsList?.find(
      (el: any) =>
        el?.label?.trim().toLowerCase() === newName?.trim().toLowerCase()
    );
    const elementIndex =
      elementToEdit && elementToEdit.value && elementToEdit.value !== ""
        ? elementsList.findIndex(
            (el: any) =>
              el?.label.trim().toLowerCase() ===
              elementToEdit?.label.trim()?.toLowerCase()
          )
        : -1;

    const isOwnName =
      elementIndex !== -1
        ? elementsList[elementIndex]?.label.trim().toLowerCase() ===
          newName.trim().toLowerCase()
        : false;

    if (elementNameExists && configMode === "CREATE") {
      setMessage(t("general.repeatedName"));
    } else if (elementNameExists && configMode === "EDIT" && !isOwnName) {
      setMessage(t("general.repeatedName"));
    } else {
      setMessage("");
    }
  } else {
    setMessage("");
  }
};

export const handleElementRepeatedNameTypeText = (
  t,
  elementToEdit,
  newName: string,
  elementsList: string[],
  configMode,
  setMessage
) => {
  if (newName === "" || !newName) {
    setMessage({ text: t("general.requiredField"), type: "error" });
  } else if (elementsList && elementsList.length && newName) {
    const elementNameExists = elementsList?.find(
      (el: any) => el?.trim()?.toLowerCase() === newName.trim()?.toLowerCase()
    );
    const elementIndex = elementToEdit
      ? elementsList.findIndex(
          (el: any) =>
            el?.trim()?.toLowerCase() === elementToEdit?.trim()?.toLowerCase()
        )
      : -1;

    const isOwnName =
      elementIndex !== -1
        ? elementsList[elementIndex]?.trim().toLowerCase() ===
          newName?.trim()?.toLowerCase()
        : false;
    if (elementNameExists && (configMode === "CREATE" || configMode === "COPY")) {
      setMessage({ text: t("general.repeatedName"), type: "error" });
    } else if (elementNameExists && configMode === "EDIT" && !isOwnName) {
      setMessage({ text: t("general.repeatedName"), type: "error" });
    } else {
      setMessage({ text: "", type: "error" });
    }
  } else {
    setMessage({ text: "", type: "error" });
  }
};

export const getValueLabel = (data) =>
  data?.map((item: any) => {
    return { value: item.archivo_id, label: item.nombre };
  });

  export const getValueLabel1 = (data) =>
    data?.map((item: any) => {
      return { value: item.id, label: item.nombre };
    });

export const getValueLabelFromIdNombre = (data) =>
  data?.map((item: any) => {
    return { value: item.id, label: item.nombre };
  });

export const getFileColumns = (data, file) => {
  if (!file) return [];
  const selectedFile = data?.find((item: any) => {
    return item.archivo_id === file.value;
  });
  return selectedFile?.columnas;
};

export const getFileColumns1 = (data, file) => {
  if (!file) return [];
  const selectedFile = data?.find((item: any) => {
    return item.id === file.value;
  });
  return selectedFile?.columna_archivo.map(
    (item: any) => {
      return { value: item.id, label: item.nombre };
    }
  );
};

export const parseByNumber = (value) => {
  if (isNaN(parseFloat(value))) {
    return "-";
  }
  const parsedValue = parseFloat(value);
  return parsedValue < 1
    ? parsedValue.toFixed(6)
    : parsedValue < 10
    ? parsedValue.toFixed(4)
    : parsedValue.toFixed(2);
};

export const renderCellExchangeTable = (params, Stack) => {
  switch (params.field) {
    case "PERIODO":
      return params.value;
    default:
      return (
        <Stack direction="row" alignItems="center">
          {parseByNumber(params.value)}
        </Stack>
      );
  }
};

export const getValueLabelFromSingleOptions = (data) =>
  data?.map((item: any) => {
    return { value: item, label: item };
  });

export const getBackgroundColor = (element, hover) => {
  if (element.tipo === "INGRESO") {
    return hover ? "rgba(22, 115, 138, 0.6)" : "var(--bg-success-hover)";
  } else if (element.tipo === "EGRESO") {
    return hover ? "rgba(249, 13, 74, 0.5)" : "rgba(249, 13, 74, 0.3) ";
  } else if (element.tipo === "RESULTADO") {
    return hover ? "var(--bg-disabled)" : "var(--bg-light)";
  } else {
    return "white";
  }
};

export const truncateStringByLevelsQty = (levelsQuantity, str) =>
  levelsQuantity < 4
    ? truncateString(str, 35)
    : levelsQuantity === 4
    ? truncateString(str, 26)
    : levelsQuantity === 5
    ? truncateString(str, 20)
    : truncateString(str, 78 / levelsQuantity);
