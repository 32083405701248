export const esTranslations = {
  administratorPanel: {
    sendCode: {
      codes: {
        200: "El código ha sido enviado con éxito",
        400: "Se produjo un error y no fue posible enviar el código",
      },
    },
    postCode: {
      codes: {
        200: "La contraseña se ha actualizado con éxito",
        400: "El código es incorrecto",
      },
    },
    companies: {
      createCompany: {
        codes: {
          200: "La empresa se ha creado con éxito",
          201: "La empresa se ha creado con éxito",
          400: "Se produjo un error y no fue posible crear la empresa",
        },
      },
      editCompany: {
        codes: {
          200: "La empresa se ha editado con éxito",
          400: "Se produjo un error y no fue posible editar La empresa",
        },
      },
      loadingCompanies: "Cargando empresas...",
      searchCompanyPlaceholder: "Búsqueda por nombre de empresa",
      activeCompanies: "Empresas activas",
      activeCompany: "Empresa activa",
      inactiveCompanies: "Empresas inactivas",
      noActiveCompanies: "No hay empresas activas",
      noInactiveCompanies: "No hay empresas inactivas",
      switchLabelActive: "Activo",
      switchLabelInactive: "Inactivo",
      duplicatedCompanyName: "Ya existe una empresa con ese nombre",
      companyNameFormat:
        "El nombre solo puede contener números, letras y guión bajo (_)",
      newCompany: "Alta de nueva empresa",
      editCompanyLabel: "Editar empresa",
      createCompanyLabel: "Crear empresa",
      creatingCompany: "Creando empresa...",
      updatingCompany: "Actualizando empresa...",
      copyingCompany: "Copiando empresa...",
      companyInformation: "Información de la empresa",
      name: "Nombre",
      visualName: "Nombre visual",
      editCompanyAction: "editar la empresa",
      createCompanyAction: "crear la empresa",
      cleanAll: "Limpiar todo",
      finish: "Finalizar",
    },
    users: {
      createUser: {
        codes: {
          200: "El usuario se ha creado con éxito",
          201: "El usuario se ha creado con éxito",
          400: "Se produjo un error y no fue posible crear el usuario",
        },
      },
      editUser: {
        codes: {
          200: "El usuario se ha editado con éxito",
          400: "Se produjo un error y no fue posible editar el usuario",
        },
      },
      editPassword: {
        codes: {
          200: "La contraseña se ha actualizado con éxito",
          400: "Se produjo un error y no fue posible actualizar la contraseña",
        },
      },
      incorrectFormat: "El formato no es el correcto",
      incorrectPasswordFormat: "El formato de la contraseña no es el correcto",
      duplicatedEmail: "Ya existe un usuario con este email",
      newPassword: "Nueva contraseña",
      repeatPassword: "Repetir contraseña",
      notMatchingPasswords: "Las contraseñas no coinciden",
      passwordFormatLabel:
        "*La contraseña debe tener una longitud de mínimo 8 caracteres, incluyendo una letra mayúscula, una letra minúscula y al menos un número o caracter especial.",
      authCode: "Código de autenticación",
      authCodeSentToEmail:
        "Hemos enviado un código de autenticación a tu correo electrónico para validar tu identidad.",
      incorrectAuthCode: "El código de autenticación no es el correcto",
      rol: "Rol",
      active: "Activo",
      inactive: "Inactivo",
      notPossibleToDisableUserLabel: "No es posible deshabilitar tu usuario",
      notPossibleToDisableUserError:
        "No es posible deshabilitar tu propio usuario. Sólo otro usuario administrador puede realizar esta acción.",
      accept: "Aceptar",
      administrator: "Administrador",
      consultant: "Consultor",
      client: "Cliente",
      editUserLabel: "Editar usuario",
      newUserLabel: "Alta de nuevo usuario",
      userInformation: "Información del usuario",
      optionalName: "Nombre (opcional)",
      optionalLastName: "Apellido (opcional)",
      name: "Nombre",
      lastName: "Apellido",
      email: "Correo electrónico",
      generatePassword: "Generar contraseña",
      companiesLabel: "Empresas",
      userEnabledCompanies: "Empresas habilitadas para el usuario",
      companyEnabledUsers: "Usuarios habilitados para la empresa",
      multipleOptions: "Elegir una o más opciones...",
      assignedCompany: "Empresa asignada",
      chooseCompanyLabel: "Elegir empresa seleccionada...",
      enableUser: "Habilitar usuario",
      activeUser: "Usuario activo",
      editPasswordLabel: "Editar contraseña",
      editUserAction: "editar el usuario",
      createUserAction: "crear el usuario",
      createUserLabel: "Crear usuario",
      cleanAll: "Limpiar todo",
      finish: "Finalizar",
      errorLoadingUsers:
        "Hubo un error al cargar los usuarios.  Por favor, intente más tarde.",
      loadingUsers: "Cargando usuarios...",
      searchUserLabel: "Búsqueda por nombre o email del usuario",
      activeUsers: "Usuarios activos",
      inactiveUsers: "Usuarios inactivos",
      noActiveUsers: "No hay usuarios activos",
      noInactiveUsers: "No hay usuarios inactivos",
    },
    roles: {
      createRole: {
        codes: {
          200: "El rol se ha creado con éxito",
          201: "El rol se ha creado con éxito",
          400: "Se produjo un error y no fue posible crear el rol",
        },
      },
      editRole: {
        codes: {
          200: "El rol se ha editado con éxito",
          400: "Se produjo un error y no fue posible editar el rol",
        },
      },
      deleteRole: {
        codes: {
          200: "El rol se ha eliminado con éxito",
          400: "Se produjo un error y no fue posible eliminar el rol",
        },
        loading: "Eliminando rol...",
      },
      searchRolePlaceholder: "Búsqueda por nombre de rol",
      activeRoles: "Roles activos",
      activeRole: "Rol activo",
      inactiveRoles: "Roles inactivos",
      noActiveRoles: "No hay roles activos",
      noInactiveRoles: "No hay roles inactivos",
      duplicatedRoleName: "Ya existe un rol con ese nombre",
      roleNameFormat: "El nombre solo puede contener letras y números",
      newRole: "Alta de nuevo rol",
      editRoleLabel: "Editar rol",
      createRoleLabel: "Crear rol",
      creatingRole: "Creando rol...",
      updatingRole: "Actualizando rol...",
      copyingRole: "Copiando rol...",
      roleInformation: "Información del rol",
      name: "Nombre",
      placeholderName: "Nombre del rol...",
      editRoleAction: "editar el rol",
      createRoleAction: "crear el rol",
      cleanAll: "Limpiar todo",
      finish: "Finalizar",
      deleteRol: "eliminar el rol",
    },
    usersLabel: "USUARIOS",
    companiesLabel: "EMPRESAS",
    rolesLabel: "ROLES",
    administratorPanel: "Panel de administrador",
    switchLabelActive: "Activo",
    switchLabelInactive: "Inactivo",
  },
  appBarComponent: {
    adminDashboard: "Panel de administrador",
  },
  avatar: {
    logoutLoading: "Cerrando sesión...",
    logout: "Cerrar sesión",
    changePassword: "Cambiar contraseña",
  },
  basicGrid: {
    noLinesLabel: "Aún no agregaste lineas",
    column: "Columna",
    function: "Función",
    name: "Nombre",
  },
  buttons: {
    speedDialTooltipOpen: {
      edit: "Editar",
      duplicate: "Duplicar",
      seeMore: "Ver más",
      delete: "Eliminar",
      result: "Resultados",
    },
  },
  calculation: {
    getCompletedStages: {
      loading: "Obteniendo etapas ...",
      error: "Se produjo un error y no fue posible descargar las etapas.",
    },
  },
  charts: {
    allCharts: {
      groupsError:
        "Hubo un error y no se pudieron descargar los grupos.  Por favor, intente más tarde.",
      chartsLabel: "Gráficos",
      periodsLabel: "Períodos",
      downloadingGroups: "Descargando grupos...",
      downloadingCharts: "Descargando gráficos...",
      groupsDownloadigError: "Hubo un error al descargar los grupos.",
      chartsDownloadingError: "Hubo un error al descargar los gráficos.",
      noActiveChartsError: "Aún no hay gráficos creados o activos.",
    },
    barInfiniteDrilldownChart: {
      originalExpensePercent: "Porcentaje respecto al monto origen",
    },
  },
  chartsManagement: {
    addChart: {
      codes: {
        201: "El gráfico se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear el gráfico",
      },
    },
    editChart: {
      codes: {
        200: "El gráfico se ha actualizado con éxito",
        400: "Se produjo un error y no fue posible editar el gráfico",
      },
    },
    deleteChart: {
      codes: {
        200: "El gráfico se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el gráfico",
      },
    },
    putState: {
      codes: {
        200: "El estado se ha modificado con éxito",
        201: "El estado se ha modificado con éxito",
        400: "Se produjo un error y no fue posible modificar el estado",
      },
    },
    getGroup: {
      error: "Se produjo un error y no fue posible obtener los grupos",
    },
  },
  codeVerification: {
    twoStepsVerification: "Verificación de 2 pasos",
    completeWithTheCodeSentToEmail:
      "Por favor, completar con el código que se ha enviado a su correo electrónico. No comparta este código con nadie.",
    codeSentToEmail: "Se envió el código a su casilla de correo electrónico.",
    cancel: "Cancelar",
    enter: "Ingresar",
  },
  company: {
    getCompanyError: "No fue posible cargar la empresa",
    getCompaniesError: "No fue posible cargar las empresas",
    loadingCompanies: "Descargando empresas...",
    selectCompany: "Seleccione su empresa",
    chooseCompany: "Elegir empresa",
    userCompanyError: "No fue posible obtener la empresa para este usuario",
    companiesError: "No fue posible obtener las empresas",
    changeCompanyError:
      "Ocurrió un error al cambiar de empresa, por favor vuelve a intentarlo",
    closingSession: "Cerrando sesión...",
    cancel: "Cancelar",
    start: "Comenzar",
    codes: {
      200: "La empresa se ha seteado con éxito",
      400: "Se produjo un error y no fue posible cambiar la empresa",
      404: "Se produjo un error y no fue posible cambiar la empresa",
    },
  },
  companyParam: {
    fileNameToUse: "Nombre de archivo a utilizar",
    stepsTitles: {
      step0: "Tipo de cambio",
      step1: "Parametrización archivo GASTOS",
      step2: "Parametrización archivo VENTAS",
      step3: "Parametrización archivos y columnas",
      step4: "Relaciones de archivos",
      step5: "Receptores de Gastos",
    },
    foreignCurrencyExchangeRate: "Tipo de cambio por moneda extranjera",
    help1:
      "El tipo de cambio es expresado en valor de moneda extranjera por cada unidad de moneda local.",
    help2: "El flujo sugerido para la carga de datos es:",
    help3: "Seleccionar país y moneda local.",
    help4: "Seleccionar todas las monedas extranjeras a utilizar.",
    help5:
      "Agregar periodos (si ya existen archivos cargados, aparecerán esos periodos automáticamente, pero también es posible agregar más).",
    help6:
      "Agregar valores de tipo de cambio manualmente haciendo doble click en alguna celda de la tabla, o cargar un archivo con los valores.",
    help7:
      "Para cargar un archivo, se debe descargar la plantilla, completarla y subirla en el mismo formato. Desde el excel se pueden agregar más monedas siempre y cuando coincida el código de la moneda con alguna existente (no recomendado). Utilizar unicamente formato numérico.",
    help8:
      "Tener en cuenta que al cargar un archivo, se pisarán los valores modificados posteriormente a descargar la plantilla, por lo cual se recomienda dejar las modificaciones manuales para el final.",
    addCurrencies: "Agregar monedas...",
    addPeriods: "Agregar períodos",
    addValuesFromFile: "Cargar valores desde archivo",
    columnName: "Nombre de la columna",
    dataType: "Tipo de dato",
    description: "Descripción",
    fileDescription: "Descripción del archivo...",
    textNotAllowed:
      "porque existen archivos cargados con este nombre. Para poder eliminarlo, primero se deben eliminar los archivos cargados con este nombre.",
    downloadTemplateDescription:
      "Para la carga del archivo con los tipos de cambio, se debe respetar el formato de periodos y nombre de monedas. Hacer click en el botón de abajo para descargar la plantilla y completar con los datos correspondientes. Luego subir el archivo completo.",
    fileOrFileSlice: "Archivo o muestra de archivo",
    dragFile: "Arrastre aquí el archivo o haga click para seleccionar.",
    fileToUpload: "Archivo a subir (click en 'Subir Archivo' para confirmar)",
    fileUpload: "Carga de archivo",
    downloadTemplate: "Descargar plantilla",
    templateDownload: "Descarga de plantilla",
    noCurrencies: "No hay monedas seleccionadas.",
    uploadFile: "Subir archivo",
    deleteFile: "Eliminar archivo",
    fillFromExternalSource: "Completar desde fuente externa",
    confirmFillValues:
      "¿Confirma que desea completar los valores desde una fuente externa? Esto puede ocasionar que se pierdan valores actualizados manualmente.",
    easyNextSteps: "Esto facilitará los pasos siguientes.",
    identifyKeyColumns: "Identificar columnas clave",
    abmPnl: "Líneas P&L (arrastrar para reordenar)",
    pnlStructure: "Estructura P&L",
    abmBuckets: "Buckets",
    transactional: "Transaccionales",
    descriptive: "Descriptivas",
    maxLevelDetail: "Máximo nivel de detalle",
    existentColumn: "Columna existente",
    multipleColumns: "Múltiples Columnas",
    addFileAndDescription: "Agregar archivo y su descripción",
    otherFilesRows: {
      automaticImport: "Importar automáticamente",
    },
    customerMaster: "Maestro de Clientes",
    routeMaster: "Maestro de Rutas",
    ProductSKUMaster: "Maestro de Productos/SKU",
    assetAssignment: "Asignación de activos",
    visitLog: "Bitácora de visitas",
    replaceNoNumeric: "Reemplazar valores no numéricos por",
    complete: "Completo",
    incomplete: "Incompleto",
    columnTyping: "Tipado de columnas (debe haber un archivo cargado)",
    existentCombination: "Esta combinación ya existe",
    relationshipName: "Nombre de la relación",
    file: "Archivo",
    chooseAnOption: "Elegir una opción",
    column: "Columna",
    deleteRelationship: "Eliminar relación",
    newRelationship: "Agregar relación",
    expenseTargetName: "Nombre del receptor de gasto",
    deleteExpenseTarget: "Eliminar receptor de gasto",
    newTarget: "Nuevo receptor",
    required: "Requerida",
    allowNullValues: "Permitir valores nulos",
    onlyUniqueValues: "Sólo valores únicos",
    average: "Promedio",
    value: "Valor...",
    selectAll: "Seleccionar todas",
    assignToMultiple: "Asignar a varios",
    elementType: "Tipo de elemento",
    newExpense: "Nuevo Egreso",
    incomeType: "Tipo de Ingreso",
    expenseType: "Tipo de Egreso",
    bucketLevel: "Nivel vinculado a BUCKET",
    deleteLevels: "Eliminar niveles",
    deleteLevel: "Eliminar nivel",
    confirmDeleteLevels:
      "¿Confirma que desea modificar el nivel de BUCKET? Esto eliminará todos los niveles a partir del nivel  ",
    filterByLevel: "Filtrar por nivel",
    addLevel: "Añadir nivel",
    editName: "Editar nombre",
    confirmCreateLevel: "¿Confirma que desea crear el nivel?",
    minimunNumberLevels: "La cantidad mínima de niveles es 3.",
    confirmEditLevelName: "¿Confirma que desea editar el nombre del nivel?",
    writeNewLevelName: "Escribir el nombre para el nuevo nivel...",
    writeNewName: "Escribir el nuevo nombre...",
    deleteThisLevel: "eliminar este nivel",
    differentLevelError: "No se puede mover el elemento a un nivel diferente.",
    differentTypeError:
      "No se puede mover el elemento a un tipo de elemento diferente.",
    differentFileError:
      "No se puede mover el elemento a otro con diferente archivo.",
    addElement: "Añadir elemento",
    addTable: "Añadir tabla",
    editTable: "Editar tabla",
    addSubelement: "Añadir subelemento",
    deleteThisElement: "eliminar este elemento",
    deleteElement: "Eliminar elemento",
    createPnlElement: "Crear elemento de P&L",
    confirmEditName: "¿Confirma que desea editar el nombre del elemento?",
    postParam: {
      codes: {
        200: "La parametrización se ha guardado con éxito",
        201: "La parametrización se ha guardado con éxito",
        400: "Se produjo un error y no fue posible guardar la parametrización",
      },
      loading: "Guardando parametrización...",
    },
    getParams: {
      codes: {
        200: "La parametrización se ha obtenido con éxito",
        400: "Se produjo un error y no fue posible obtener la parametrización",
      },
      loading: "Obteniendo parametrización...",
    },
    postParamFile: {
      codes: {
        200: "El archivo se ha subido con éxito",
        201: "El archivo se ha subido con éxito",
        400: "Se produjo un error y no fue posible subir el archivo",
      },
      loading: "Subiendo archivo...",
    },
  },
  columns: {
    action: "acción",
    analysis: "análisis",
    apportionment_criteria: "criterio_prorrateo",
    bucket: "bucket",
    cause_error: "causa_error",
    column: "Columna",
    criteria: "Criterio",
    date: "fecha",
    destination_receiver: "receptor_destino",
    destination: "destino",
    expenses_transferred: "gastos_trasladados",
    file: "archivo",
    iteration: "iteracion",
    lastPeriod: "último periodo",
    mount: "monto",
    name: "nombre",
    period: "periodo",
    previous_destination: "destino_anterior",
    receiver_criteria: "receptor_criterio",
    referenceName: "nombre referencia",
    stage: "etapa",
    stages: "etapas",
    state: "estado",
    values: "Valores",
    cod_rec: "Cod receptores",
    etapas_anteriores: "etapas anteriores",
    tipo_receptor: "Tipo receptor",
    gasto_anterior: "gasto anterior",
    cod_re: "Cod receptor",
    anterior: "anterior",
    gasto: "gasto",
    receptor_de: "receptor de",
    criterio_de: "Criterio de",
    prorrateo: "prorrateo",
    gastos: "Gastos",
    trasladados: "trasladados",
    gast: "gasto",
    agrupador: "agrupador",
  },
  configCriteria: {
    addTargetModal: {
      repeatedTargetNameError: "Ya existe un objetivo con ese nombre",
      emptyFieldsError: "Los campos no pueden quedar vacíos",
      succesCreatedTarget: "El receptor de gasto se ha creado con éxito",
      errorCreatedTarget:
        "Ha ocurrido un error al crear el receptor, intentar nuevamente.",
      loadingPostTarget: "Creando receptor de gasto...",
      newTarget: "Nuevo receptor",
      cancel: "Cancelar",
      accept: "Aceptar",
      name: "Nombre",
      target: "Receptor de gasto",
      newTargetName: "Nombre del nuevo receptor",
      targetPlaceholder: "Nombre de la columna del archivo (ej. COD_RUTA)",
      validatePreviousDestinity: "Validar destino previo",
    },
    configCriteriaComponent: {
      criteriaAdmin: "Administración del criterio",
      nonAppliedFilters:
        "Hay filtros sin agregar. ¿Confirma que desea crear el criterio de todas formas?",
      nonAppliedFilterWarning:
        "Hay filtros sin agregar. ¿Desea continuar igualmente?",
      previous: "Anterior",
      resetSteps: "Resetear todos los pasos del criterio",
      cleanAll: "Limpiar todo",
      finish: "Finalizar",
      next: "Siguiente",
      editCriteria: "editar el criterio",
      createCriteria: "crear el criterio",
      createAndAssignCriteria:
        "¿Confirma que desea copiar el criterio y reasignarlo a la fila actual?",
      editCriteriaTitle: "Editar criterio",
      createCriteriaTitle: "Crear criterio",
      noFilters: "No hay filtros",
      goBack: "Volver",
      continue: "Continuar",
      incomingConditionOptions: ["y", "ó"],
      incomingFilterTypeOptionsCriteria: [
        "INCLUIR",
        "EXCLUIR",
        "MENOR A",
        "MENOR O IGUAL A",
        "IGUAL A",
        "MAYOR A",
        "MAYOR O IGUAL A",
        "DIFERENTE A",
      ],
      incomingFilterTypeOptions: ["INCLUIR", "EXCLUIR"],
      incomingValuesOptions: [
        { valor: "CROSSDOCKS" },
        { valor: "PLANTS" },
        { valor: "T1" },
      ],
      steps: [
        "Parametrización inicial de criterios",
        "Filtros de archivo",
        "Validación del archivo GASTOS con archivo",
        "Filtros de archivo con información externa",
      ],
      filterMessages: {
        emptyError:
          "Se deben completar todos los campos para agregar un filtro.",
        duplicatedError: "Ya existen filtros con esos criterios de validación.",
        conditionTooltipInfo:
          "Sólo es posible aplicar una de las opciones para todos los filtros.  Modificar esta opción afectará a todos los filtros seleccionados.",
        duplicatedCriteriaNameError: "Ya existe un criterio con ese nombre.",
      },
      tooltips: {
        conditionTooltip:
          "Sólo es posible aplicar una de las opciones para todos los filtros. Modificar esta opción afectará a todos los filtros seleccionados.",
      },
    },
    filterRow: {
      filterWarning:
        "Sólo es posible aplicar una de las opciones para todos los filtros.  Modificar esta opción afectará a todos los filtros seleccionados.",
    },
    step1: {
      apportionment: "PRORRATEO",
      description: "Descripción",
      criteriaName: "Nombre del criterio",
      applyIn: "Aplica en",
      bucket: "Bucket",
      all: "Todos",
      expenseTargets: "Receptores de gastos",
      calcParameterization: "Parametrización de cálculo",
      file: "Archivo",
      selectFile: "Elegir archivo...",
      column: "Columna",
      selectColumn: "Elegir columna...",
      function: "Función",
      selectFunction: "Elegir función...",
      countFunction: "CONTAR",
      countUniqueFunction: "CONTAR.UNICO",
      sumUniqueFunction: "SUMA.UNICO",
      sumProductFunction: "SUMA.PRODUCTO",
      sumProductFunctionLabel: "Suma producto",
      sumUniqueFunctionLabel: "Suma único",
      criteriaUniqueColumn: "Columna de criterio único",
      notModifyValues: "No modificar valores negativos",
      convertNegativesToPositives: "Convertir valores negativos en positivos",
      convertNegativesToZero: "Transformar valores negativos a cero",
      columnToMultiply: "Columna a multiplicar",
      selectOptions: "Elegir una o más opciones...",
      changeFileWarning:
        "¿Confirma que desea editar el archivo del criterio? Esta acción reseteará los pasos 2, 3 y 4 del criterio si lo utilizan.",
      editFileLabel: "Editar  archivo del criterio",
      targetExpenses: "Receptores de Gasto",
    },
    step2: {
      repeatedFilterError:
        "Ya existe un filtro para esta columna.  En caso de agregarlo, se reemplazará el filtro anterior.",
      include: "INCLUIR",
      exclude: "EXCLUIR",
      previousFilter: "Filtros previos",
      in: " en ",
      condition: "Condición",
      filterType: "Tipo de filtro",
      type: "Tipo...",
      values: "Valores",
      value: "Valor",
      valuePlaceholder: "Escribe un valor númerico",
      noFiltersWarning: "Aún no agregaste filtros",
      conditionTooltip:
        "Only one of the options can be applied to all filters. Modifying this option will affect all selected filters.",
    },
    step3: {
      expensesColumn: "Columna gastos",
      noValidationsWarning: "Aún no agregaste validaciones",
    },
    step4: {
      crossoverValues: "Cruce de valores",
      validationFile: "Archivo validación",
      columnValidationFile: "Columna archivo validación",
      columnFile: "Columna archivo ",
    },
    virtualSelect: {
      loading: "Cargando...",
      noAvailableOptions: "No hay opciones disponibles",
      selectValues: "Seleccione uno o más valores...",
      selectOneValue: "Seleccione un valor...",
      registeredRoutesSelectValues: "Seleccione un valor...",
    },
    getCriteriaBucket: {
      error:
        "Se produjo un error y no fue posible descargar los buckets del criterio",
    },
    getCriteriaTarget: {
      error:
        "Se produjo un error y no fue posible descargar los receptores del criterio.",
    },
    getCriteriaFileColumn: {
      error:
        "Se produjo un error y no fue posible descargar los archivos del criterio.",
    },
    getCriteriaFunctions: {
      error:
        "Se produjo un error y no fue posible descargar las funciones del criterio.",
    },
    getExpensesColumn: {
      error:
        "Se produjo un error y no fue posible descargar los gastos del criterio.",
    },
    sendCriteria: {
      loading: "Creando criterio...",
      codes: {
        200: "El criterio se ha creado con éxito",
        201: "El criterio se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear el criterio",
      },
    },
    putCriteria: {
      loading: "Modificando criterio...",
      codes: {
        200: "El criterio se ha modificado con éxito",
        201: "El criterio se ha modificado con éxito",
        400: "Se produjo un error y no fue posible modificar el criterio",
      },
    },
  },
  copyGenericText: "_copia",
  criteriaManagement: {
    deleteCriteria: {
      loading: "Eliminando criterio...",
      codes: {
        200: "El criterio se ha eliminado con éxito",
        201: "El criterio se ha eliminado con éxito",
        203: "El criterio se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el criterio",
      },
    },
    getCriteria: {
      loading: "Obteniendo criterios...",
      error: "Se produjo un error y no fue posible descargar los criterios.",
    },
  },
  criteriaManagementTexts: {
    criteriaCard: {
      receivers: "Receptores",
      filters: "Filtros",
      validations: "Validaciones",
      inUseBuckets: "En uso, asociado a los buckets",
      inUseBucket: "En uso, asociado al bucket",
      notUsed: "No usado",
    },
    criteriaManagement: {
      lookingCriteria: "Buscando criterios...",
      deletingCriteria: "Eliminando criterio...",
      criteriaManagement: "Administración de criterios",
      sortAlphabet: "ORDENAR POR ALFABETO",
      sortDate: "ORDENAR POR FECHA",
      filter: "Filtrar",
      searchByCriteriaBucket: "Búsqueda por nombre de criterio o bucket",
      noCriteriaAvailable: "Aún no hay criterios disponibles.",
      notGetCriteria:
        "No fue posible obtener los criterios. Vuelva a intentarlo más tarde.",
      deleteCriteria: "eliminar el criterio",
      noActiveCriteria: "Criterios Inactivos",
      activeCriteria: "Criterios Activos",
      noFilters: "Sin filtros",
      bucketsWhereItsUsed: "Buckets donde se utiliza",
    },
    viewInfoModal: {
      criteriaInfo: "Información general de criterio",
      criteriaBase: "Base para criterio:",
      criteriaColumn: "Columna de criterio:",
      aplicatedFunction: "Función aplicada:",
      convertNegativesToPositives: " Convertir valores negativos en positivos:",
      convertNegativesToZero: "Transformar valores negativos a cero:",
      columnsToValidate: "Columna a validar únicos:",
      yes: "Sí",
      no: "No",
      columnsToMultiply: "Columnas a multiplicar",
      filterInFile: "Filtros en archivo",
      expensesCrossover: "Cruce de valores de GASTOS con",
      filterBy: "Filtrado de ",
      withInfo: "con información de",
      previousFilters: "Filtros previos en archivo",
      crossoverValues: "Cruce de valores de",
      with: "con",
      previousDestinitiesValidations: "Validación de destinos anteriores",
      differentiatedTreatment: "Tratamiento diferenciado",
      noDifferentiatedTreatment: "Sin tratamiento diferenciado",
    },
  },
  dashboard: {
    duplicated: "Ya existe un tablero con este nombre.",
    invalidLink: "El link debe ser un link válido de Power BI",
    noData: "No hay tableros para mostrar.",
    get: {
      loading: "Obteniendo tableros...",
      error:
        "Ha ocurrido un error al obtener los tableros, intentar nuevamente.",
    },
    post: {
      codes: {
        200: "El tablero se ha agregado con éxito.",
        201: "El tablero se ha agregado con éxito.",
        400: "Ha ocurrido un error al agregar el tablero, intentar nuevamente.",
        500: "Ha ocurrido un error al agregar el tablero, intentar nuevamente.",
      },
    },
    delete: {
      success: "El tablero se ha eliminado con éxito.",
      error:
        "Ha ocurrido un error al eliminar el tablero, intentar nuevamente.",
      codes: {
        200: "El tablero se ha eliminado con éxito.",
        400: "Ha ocurrido un error al eliminar el tablero, intentar nuevamente.",
        500: "Ha ocurrido un error al eliminar el tablero, intentar nuevamente.",
      },
    },
    newView: {
      codes: {
        200: "La vista se ha creado con éxito.",
        201: "La vista se ha creado con éxito.",
        400: "Ha ocurrido un error al crear la vista, intentar nuevamente.",
        500: "Ha ocurrido un error al crear la vista, intentar nuevamente.",
      },
      loading: "Creando vista...",
      createView: "¿Confirma que desea crear la vista?",
    },
    deleteView: {
      codes: {
        200: "La vista se ha eliminado con éxito.",
        201: "La vista se ha eliminado con éxito.",
        400: "Ha ocurrido un error al eliminar la vista, intentar nuevamente.",
        500: "Ha ocurrido un error al eliminar la vista, intentar nuevamente.",
      },
      loading: "Eliminando vista...",
      modalTitle: "Eliminar vista",
      selectViewToDelete: "¿Confirma que desea eliminar la vista?",
      selectView: "Seleccionar vista a eliminar...",
    },
    newElement: {
      codes: {
        200: "El elemento se ha creado con éxito.",
        201: "El elemento se ha creado con éxito.",
        400: "Ha ocurrido un error al crear el elemento, intentar nuevamente.",
        500: "Ha ocurrido un error al crear el elemento, intentar nuevamente.",
      },
      loading: "Creando elemento...",
    },
    deleteElement: {
      codes: {
        200: "El elemento se ha eliminado con éxito.",
        201: "El elemento se ha eliminado con éxito.",
        400: "Ha ocurrido un error al eliminar el elemento, intentar nuevamente.",
        500: "Ha ocurrido un error al eliminar el elemento, intentar nuevamente.",
      },
      loading: "Eliminando elemento...",
      confirmationMessage: "¿Confirma que desea eliminar el elemento?",
      modalTitle: "Eliminar elemento",
    },
    editElement: {
      codes: {
        200: "El elemento se ha editado con éxito.",
        201: "El elemento se ha editado con éxito.",
        400: "Ha ocurrido un error al editar el elemento, intentar nuevamente.",
        500: "Ha ocurrido un error al editar el elemento, intentar nuevamente.",
      },
      loading: "Editando elemento...",
    },
    importFrom: "Importar de:",
    analysis: "ANÁLISIS",
    simulation: "SIMULACIÓN",
    salesForecast: "SALES FORECAST",
    costsForecast: "COSTS FORECAST",
    chart: "Gráfico",
    indicator: "Indicador",
    information: "Información",
    table: "Tabla",
    createNewElement: "Nuevo elemento",
    putElement: "Editar elemento",
    typeOfElement: "Tipo de elemento",
    selectType: "Seleccionar tipo...",
    columnsQuantity: "Ancho del elemento (cantidad de columnas a ocupar)",
    rowsQuantity: "Alto del elemento (cantidad de filas a ocupar)",
    toUse: "a utilizar",
    moveElement: "Arrastrar para mover el elemento",
    editName: "Editar nombre",
    create: "Crear",
    import: "Importar",
    waterfall: {
      positive: "Positivo",
      negative: "Negativo",
      intermediateSum: "Suma intermedia",
      totalSum: "Suma total",
    },
    createView: "Crear vista",
    insertName: "Ingrese el nombre de la nueva vista",
    placeholderWriteName: "Escribir nombre de la nueva vista",
    noDataError: "Hubo un error y no fue posible obtener los datos.",
    noElementsYet: "Aún no se ha añadido ningún elemento.",
  },
  dashboardTexts: {
    dashboard: {
      addDashboard: "Editar tablero",
      addNewDashboard: "Agregar nuevo tablero",
    },
    newDashboardModalContent: {
      deletingDashboard: "Eliminando tablero...",
      name: "Nombre:",
      dashboardName: "Nombre del tablero...",
      linkDashboard: "Link del tablero...",
      deleteDashboardFromListing: "Eliminar tablero del listado.",
      close: "Cerrar",
      deleteDashboard: "Eliminar tablero",
    },
  },
  dataManagement: {
    downloadFile: {
      loading: "Descargando archivo...",
      codes: {
        200: "El archivo se ha descargado con éxito",
        201: "El archivo se ha descargado con éxito",
        400: "Se produjo un error y no fue posible descargar el archivo",
      },
      empty: "No hay archivos para descargar",
    },
    getRowsPreview: {
      loading: "Obteniendo vista previa...",
      codes: {
        200: "Se pudo obtener la vista previa",
        400: "Se produjo un error y no fue posible obtener la vista previa",
      },
    },
    getColumnsFiles: {
      loading: "Obteniendo columnas...",
      error: "Se produjo un error y no fue posible descargar las columnas.",
    },
    getRowsFiles: {
      loading: "Obteniendo filas...",
      error: "Se produjo un error y no fue posible descargar las filas.",
    },
    getAllowedFileNames: {
      loading: "Obteniendo nombres de archivos permitidos...",
      error:
        "Se produjo un error y no fue posible descargar los nombres de archivos permitidos.",
    },
    getCompletedStages: {
      codes: {
        400: "Se produjo un error y no fue posible descargar las etapas.",
      },
      error: "Se produjo un error y no fue posible descargar las etapas.",
    },
    deleteFile: {
      codes: {
        200: "El archivo se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el archivo",
        500: "Se produjo un error y no fue posible eliminar el archivo",
      },
      loading: "Eliminando archivo...",
    },
    deletePeriod: {
      codes: {
        200: "El período se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el período",
        500: "Se produjo un error y no fue posible eliminar el período",
      },
    },
    uploadModal: {
      restartCalculation: "Resetear cálculo",
    },
  },
  dataManagementTexts: {
    allowFilesModalContent: {
      fileNameExists: "Ya existe un archivo con este nombre",
      nameRules:
        "El nombre sólo puede contener letras, números, guiones y una longitud de hasta 30 carácteres",
      nameFileDeleted: "El nombre de archivo se ha eliminado con éxito.",
      errorAllowFileDeleting:
        "Ha ocurrido un error al eliminar el archivo permitido, intentar nuevamente.",
      successAllowFileDeleting:
        "El nombre de archivo permitido se ha agregado con éxito.",
      errorAddAllowFile:
        "Ha ocurrido un error al agregar el archivo permitido, intentar nuevamente",
      addNewAllowFile: "Agregar nuevo nombre de archivo permitido",
      fileName: "Nombre del archivo...",
      allowedFiles: "Archivos permitidos",
      deleteAllowedFile: "Eliminar archivo permitido",
      close: "Cerrar",
      delete: "eliminar",
      offAllowdFileNames: "de los nombres de archivos permitidos",
      deleteFile: "Eliminar archivo",
    },
    notAllowedToAddFileModal: {
      cannotAddName: "No es posible agregar el nombre",
      notPossibleExpensesName:
        "No es posible agregar el nombre PRE_GASTOS a los nombres de archivos permitidos",
      accept: "Aceptar",
    },
    notAllowedToDeleteFileModal: {
      cannotAddName: "No es posible agregar el nombre",
      notPossibleDelete_1: "No es posible eliminar ",
      notPossibleDelete_2:
        "de los nombres de archivos permitidos porque existen archivos cargados con este nombre. Es necesario que sean eliminados previamente",
      accept: "Aceptar",
    },
    dataManagement: {
      taskManager: "/gestor-tareas",
      fileStillLoading:
        "Este archivo aún se está cargando, aguarde unos instantes y vuelva a intentarlo.",
      cannotGetPreView:
        "No se pudo obtener la previsualización de este archivo",
      deletingPeriodFiles: "Eliminando archivos del periodo...",
      downloadingFile: "Descargando archivo...",
      dataManagement: "Administración de datos",
      errorCouldNotDownload:
        "Se produjo un error y no fue posible descargar la información.",
      uploadFiles: "Subir Archivos",
      allowedFiles: "Archivos Permitidos",
      cannotGetAllowedFiles: "No fue posible obtener los archivos permitidos",
      fileUpload: "Carga de archivos",
      close: "Cerrar",
      deleteSelectedPeriod: "eliminar el período seleccionado",
      deletePeriod: "Eliminar Período",
      confirmDeleteFileType: "¿Confirma que desea eliminar",
      confirmDeleteFile_1: "¿Confirma que desea eliminar el archivo",
      confirmDeleteFile_2:
        "? Esto eliminará las tareas que estén relacionadas con este archivo.",
      deleteFile: "Eliminar Archivo",
      preview: "Vista Previa",
      uploading: "Subiendo",
      files: "archivos",
      closingWindowStopUpload:
        "Si se cierra la ventana se detendrá el proceso de carga y se perderán los archivos que aún no comenzaron el proceso de carga.  ¿Desea detener la carga?",
      closeFileUpload: "Cerrar carga de Archivos",
      fileDownload: "Descarga de archivos",
      goDownloads: "Ir a descargas",
      downloadProccesStarted: "Comenzó el proceso de descarga",
      viewDownloadStatus: `Para ver el estado de la descarga y descargar el archivo, haga click en el botón "Ir a descargas". También se puede visualizar desde "Gestor de Tareas" opción Descargas.`,
      importFromAlpha: "Importar de Alpha",
    },
    alphaImportTexts: {
      filesToImport: "Seleccionar archivos a importar",
      selectedFolders: "Carpetas seleccionadas",
      selectedFiles: "Archivos seleccionados",
      noFilesToImport: "No hay archivos para importar",
    },
    linearDeterminate: {
      loading: "Cargando",
      completedLoading: "Carga completada",
    },
    successUploadModalContent: {
      connectionError:
        "Hubo un error con la conexión y no fue posible enviar el archivo.",
      onlyUpload:
        "El archivo, luego de ser subido, necesita ser procesado.  El estado del procesamiento se mostrará en la tabla de archivos y periodos.",
      stopLoad:
        "Refrescar la página, cerrar esta ventana o el navegador detendrá la carga y se perderán aquellos archivos que aún no comenzaron el proceso de carga.",
      charge: "cargar",
      reTry: "Reintentar",
      cancel: "cancelar",
      chargeAbort: "Carga cancelada",
      errorFileNotLoaded: "Hubo un error y no fue posible cargar el archivo",
      loading: "Cargando...",
      waiting: "Pendiente",
    },
    transformDataManagement: {
      downloadAllFilesFrom: "Descargar todos los archivos de",
      deleteAllFilesFrom: "Eliminar todos los archivos de",
      preview: "Previsualizar",
      download: "Descargar",
      delete: "Eliminar",
      validated: "Validado",
      notValidated: "No validado",
      notFound: "No se encuentra",
      downloadAllFiles: "Descargar todos los archivos",
      downloadAllPeriodFiles: "Descargar todos los archivos del período",
      deletePeriod: "Eliminar Periodo",
    },
    uploadModalContent: {
      fileNameFormat_1: "Los nombres de los archivos deben seguir el formato",
      fileNameFormat_2: "NOMBRE-MES-AÑO.csv ó NOMBRE-MES-AÑO.parquet",
      fileNameFormat_3: "por ej. VENTAS-02-2022.csv",
      allowedName: "Los nombres permitidos son:",
      cannotUploadNames:
        "No se han cargado los nombres de los archivos permitidos",
      dragSelectFiles:
        "Arrastre aquí los archivos, o haga click para seleccionar",
      filesToUpload: "Archivos a subir:",
      addAllowedFilesName: "Agregar nombre de archivos permitidos.",
      deleteFileFromListing: "Eliminar archivo del listado.",
      fileAlreadyExistsRestatedCalculation:
        " Este archivo ya existe y reemplazará al anterior.",
      itWillBeRestarted: "Se reiniciará el cálculo del periodo",
      fileAlreadyIncluded: " - Este archivo ya está incluido en el listado",
      fileNameNotAllowed: " - Nombre de archivo no permitido",
      cannotGetAllowedFiles: "No fue posible obtener los archivos permitidos",
      cancel: "Cancelar",
      filledIllegalNames: "Hay archivos con nombre no permitido.",
      accept: "Aceptar",
    },
  },
  dataModel: {
    getMeasures: {
      error: "Se produjo un error y no fue posible obtener las medidas",
      codes: {
        200: "Se obtuvieron las medidas",
        400: "Se produjo un error y no fue posible obtener las medidas",
        404: "Se produjo un error y no fue posible obtener las medidas",
      },
    },
    getMeasure: {
      error: "Se produjo un error y no fue posible obtener la medida",
      codes: {
        200: "Se obtuvo la medida",
        400: "Se produjo un error y no fue posible obtener la medida",
        404: "Se produjo un error y no fue posible obtener la medida",
      },
    },
    getGroups: {
      error: "Se produjo un error y no fue posible obtener las agrupaciones",
      codes: {
        200: "Se obtuvieron las agrupaciones",
        400: "Se produjo un error y no fue posible obtener las agrupaciones",
        404: "Se produjo un error y no fue posible obtener las agrupaciones",
      },
    },
    getGroup: {
      error: "Se produjo un error y no fue posible obtener la agrupación",
      codes: {
        200: "Se obtuvo la agrupación",
        400: "Se produjo un error y no fue posible obtener la agrupacion",
        404: "Se produjo un error y no fue posible obtener la agrupacion",
      },
    },
    getPeriods: {
      error: "Se produjo un error y no fue posible obtener los periodos",
      codes: {
        200: "Se obtuvo los periodos",
        400: "Se produjo un error y no fue posible obtener los periodos",
        404: "Se produjo un error y no fue posible obtener los periodos",
      },
    },
    deleteGroup: {
      loading: "Eliminando agrupación...",
      codes: {
        200: "La agrupación se ha eliminado con éxito",
        204: "La agrupación se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar la agrupación",
      },
    },
    deleteMeasure: {
      loading: "Eliminando medida...",
      codes: {
        200: "La medida se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar la medida",
      },
    },
    getAnalysis: {
      error: "Se produjo un error y no fue posible obtener los análisis",
      codes: {
        200: "Se obtuvieron los análisis",
        400: "Se produjo un error y no fue posible obtener los análisis",
        404: "Se produjo un error y no fue posible obtener los análisis",
      },
    },
    getAnalysisViews: {
      error: "Se produjo un error y no fue posible obtener las vistas",
      codes: {
        200: "Se obtuvieron las vistas",
        400: "Se produjo un error y no fue posible obtener las vistas",
        404: "Se produjo un error y no fue posible obtener las vistas",
      },
    },
    postNewAnalysis: {
      error: "Se produjo un error y no fue ",
    },
    deleteAnalysis: {
      loading: "Eliminando análisis...",
      codes: {
        200: "El análisis se ha eleminado con éxito",
        201: "El análisis se ha eleminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el análisis",
      },
    },
    postCalculate: {
      codes: {
        200: "El cálculo se ha iniciado con éxito",
        201: "El cálculo se ha iniciado con éxito",
        400: "Se produjo un error y no fue posible iniciar el cálculo",
      },
    },
    postNewMeasure: {
      loading: "Creando medida...",
      codes: {
        200: "La medida se ha creado con éxito",
        201: "La medida se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear la medida",
      },
    },
    putMeasure: {
      loading: "Modificando medida...",
      codes: {
        200: "La medida se ha modificado con éxito",
        201: "La medida se ha modificado con éxito",
        400: "Se produjo un error y no fue posible modificar la medida",
      },
    },
    postNewGrouper: {
      loading: "Creando agrupación...",
      codes: {
        200: "La agrupación se ha creado con éxito",
        201: "La agrupación se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear la agrupación",
      },
    },
    putGrouper: {
      loading: "Editando agrupación...",
      codes: {
        200: "La agrupación se ha editado con éxito",
        201: "La agrupación se ha editado con éxito",
        400: "Se produjo un error y no fue posible editar la agrupación",
      },
    },
    postDownloadAnalysis: {
      loading: "Descagando reporte...",
      codes: {
        200: "La descarga se ha realizado con éxito",
        201: "La descarga se ha realizado con éxito",
        400: "Se produjo un error y no fue posible iniciar la descarga el análisis",
      },
    },
  },
  dataModelText: {
    analysisExecution: {
      analysisExecution: "Ejecución de análisis",
      startingCalculation: "Comenzando cálculo...",
      analysis: "Análisis",
      selectAnalysisToRun: "Seleccionar los análisis a ejecutar",
      periods: "Periodos",
      selectPeriods: "Seleccionar periodos",
      runSelectedAnalysisPlural: "ejecutar los análisis seleccionados",
      runSelectedAnalysisSingular: "ejecutar los análisis seleccionados",
      deleteData: "Borrar datos",
      runAnalysis: "Ejecutar análisis",
      noPeriods: "No hay períodos con buckets prorrateados totalmente para el análisis.",
    },
    analysisManagement: {
      configAnalysis: {
        creatingAnalysis: "Creando análisis...",
        editingAnalysis: "Editando análisis...",
        analysisAlreadyCalculated:
          "Este análisis ya fue calculado. En caso de editarlo, se eliminará el cálculo actual.",
        analysisName: "Nombre del análisis",
        analysisAlreadyName: "Ya existe una medida con ese nombre",
        groupers: "Agrupaciones:",
        chooseGrouper: "Elegir agrupación...",
        measures: "Medidas:",
        cancel: "Cancelar",
        accept: "Aceptar",
        modifyAnalysis: "modificar el análisis",
        createNewAnalysis: "crear un nuevo análisis",
        newAnalysis: "Nuevo análisis",
      },
      transferList: {
        measureDisabled:
          "La medida se encuentra deshabilitada porque no se eligieron todas las medidas primarias necesarias.",
        analysisAdmin: "Administración de Análisis",
        bucketGrouperInMeasures:
          "Si un bucket-agrupador se usa en dos medidas elegidas, éstas estarán con otro color.",
        compositeWithPrimary:
          "Las medidas compuestas solo estarán habilitadas si se eligieron todas las medidas primarias necesarias para esa medida.",
        noMeasuresAvailable: "No hay medidas disponibles para este tipo.",
      },
      analysisAdmin: {
        searchingAnalysis: "Buscando análisis...",
        analysisAdmin: "Administración de análisis",
        periods: "Períodos",
        searchByName: "Búsqueda por nombre",
        noAnalysisYet: "Aún no hay análisis disponibles.",
        deleteAnalysisConfirmCalculate:
          "¿Confirma que desea eliminar el análisis? Esta acción eliminará también el cálculo del mismo.",
        deleteAnalysisConfirm: "¿Confirma que desea eliminar el análisis?",
        confirm: "Confirmar",
        writeDelete: "Escribir la palabra 'eliminar'",
        delete: "eliminar",
      },
      analysisCard: {
        grouper: "Agrupación:",
        measures: "MEDIDAS:",
        calculated: "Calculado",
        noCalculated: "No calculado",
      },
      viewInfoAnalysisModal: {
        grouper: "AGRUPACIÓN:",
        measures: "MEDIDAS:",
        analysisInfo: "Información general del análisis",
      },
    },
    downloadReport: {
      aggregationOperatorsLabels: {
        sum: "SUMAR",
        average: "PROMEDIAR",
        maximum: "MÁXIMO",
        minimum: "MÍNIMO",
        count: "CONTAR",
        count_unique: "CONTAR_UNICO",
        first: "PRIMERO",
        last: "ULTIMO",
        unique_values: "VALORES_UNICOS",
      },
      year: "AÑO",
      month: "MES",
      monthly: "MENSUAL",
      quarterly: "TRIMESTRAL",
      biannual: "SEMESTRAL",
      annual: "ANUAL",
      downloadReport: "Descargar reporte",
      saveReportConfig: "Guardar configuración",
      confirmSaveReportConfig:
        "¿Confirma que desea guardar la configuración de descarga del reporte?",
      configReportName: "Nombre de la configuración",
      getReportConfig: "Cargar configuración",
      reports: "Reportes",
      loading: "Cargando...",
      referenceName: "Nombre de referencia",
      referenceNameAlreadyExists:
        "El nombre de referencia ya está en uso. Si continúa, se sobrescribirá el existente.",
      notAnalysisExecuted: "Todavía no hay análisis ejecutados",
      analysis: "Análisis",
      chooseAnalysisToDownload: "Seleccionar análisis a descargar...",
      filters: "Filtros",
      columns: "Columnas",
      chooseColumns: "Elegir columnas...",
      periodicity: "Periodicidad",
      choosePeriodicity: "Elegir periocidad...",
      measures: "Medidas",
      selectAllMeasures: "SELECCIONAR TODAS LAS MEDIDAS",
      noOptionsMeasuresAvailable: "No hay medidas disponibles...",
      all: "TODAS",
      chooseMeasures: "Elegir medidas...",
      aggregationOperators: "Operadores de agregación",
      chooseAggregationOperators: "Elegir un operador...",
      deleteData: "Borrar datos",
      download: "Descargar",
      downloadAnalysisSelected: "descargar el análisis seleccionado",
      preview: "Vista Previa",
      periods: "Períodos",
    },
    groupManagement: {
      step1: {
        addBaseButton: {
          newBase: "Nueva Base",
        },
        mergeModal: {
          firstOcurrence: "primera-ocurrencia",
          lastOcurrence: "ultima-ocurrencia",
          dontDelete: "no-eliminar",
          editMerge: "editar el merge",
          addMerge: "agregar el merge",
          file: "Archivo",
          selectFile: "Seleccionar archivo",
          alreadyExistBaseWithFile:
            "Ya existe una base con el archivo seleccionado",
          columns: "Columnas",
          columnsMergePreviousBase: "Columnas merge - Base anterior",
          chooseColumns: "Elegir columnas...",
          columnsMergeNewBase: "Columnas merge - Base nueva",
          removeDuplicates: "Eliminar duplicados:",
          keepFirstOcurrence: "Mantener primera ocurrencia",
          keepLastOcurrence: "Mantener última ocurrencia",
          doNotDelete: "No eliminar",
          notRecommended:
            "(No recomendado) No eliminar duplicados puede causar que se dupliquen valores.",
        },
        newBaseFile: {
          columnBeingUsed:
            "La columna eliminada está siendo utilizada en un merge, reconfigura las bases con error para poder continuar",
          file: "Archivo",
          chooseFile: "Elegir archivo...",
          chooseAnalysis: "Elegir análisis...",
          columns: "Columnas",
          chooseColumns: "Elegir columnas...",
          removeBase: "Eliminar base",
          confirmRemoveBase:
            "¿Confirma que desea eliminar la base? Esta acción eliminará tambien las bases siguientes y deberás reconfigurar el paso 2 si alguna de ellas estaba siendo utilizada.",
          editBaseFile: "Editar archivo de la base",
          confirmEditFiles:
            "¿Confirma que desea editar el archivo elegido para la base? Esta acción reseteará el paso 2 y deberá ser reconfigurado.",
          editBaseColumns: "Editar columnas de la base",
          confirmEditColumns:
            "¿Confirma que desea editar las columnas elegidas? Esta acción reseteará el paso 2 y deberá ser reconfigurado.",
        },
        step1: {
          grouperName: "Nombre de la agrupación",
          grouperNameAlreadyExists: "Ya existe una agrupación con este nombre",
          nameRules: "El nombre debe tener entre 3 y 60 caracteres",
        },
      },
      step2: {
        customColumnInput: {
          nameColumnEmpty: "El nombre de la columa no puede estar vacio",
          columnsNameAlreadyExists: "Ya existe una columna con este nombre",
        },
        modifyColumnsModal: {
          renameColumns: "Renombrar columnas",
          resetColumns: "Restablecer columnas",
          columnsOf: "Columnas de",
          cancel: "Cancelar",
          accept: "Aceptar",
        },
        step2: {
          columnToJoinResults: "Columna para unir con resultados",
          file: "Archivo",
          chooseFile: "Elegir archivo...",
          column: "Columna",
          chooseColumn: "Elegir columna...",
          columnsToUse: "Columnas a usar",
          chooseColumns: "Elegir columnas...",
          renameColumns: "Renombrar Columnas",
          preview: "Vista Previa",
          resetColumnTitle: "Restablecer columnas",
          resetColumnMessage: "restablecer columnas",
        },
      },
      baseModal: {
        cancel: "Cancelar",
        accept: "Aceptar",
      },
      configGroup: {
        newGroup: "Nueva agrupación",
        previous: "Anterior",
        nonPossibleContinueTwoDataBases:
          "No es posible continuar porque hay dos bases con el mismo archivo",
        columnsWithSameName:
          "Hay columnas con el mismo nombre, se debe renombrar alguna para continuar.",
        finish: "Finalizar",
        next: "Siguiente",
        editGroup: "Editar agrupación",
        createGroup: "Crear agrupación",
      },
      groupAdmin: {
        groupsAdmin: "Administración de agrupaciones",
        searchByName: "Búsqueda por nombre",
        nonGroupsYet: "Aún no hay agrupaciones disponibles.",
        removeGroup: "eliminar la agrupación",
        confirm: "Confirmar",
      },
      groupCard: {
        usedColumns: "Columnas utilizadas:",
      },
      viewGrouperInfo: {
        name: "Nombre:",
        base: "Base:",
        columns: "Columnas:",
        column: "Columna:",
        generalInfoGroup: "Información general de la agrupación",
        mergeColumnsPreviousBase: "Columnas merge - base anterior:",
        mergeColumnsCurrentBase: "Columnas merge - base actual:",
        removeDuplicates: "Eliminar duplicados:",
        finalColumns: "Columnas finales",
        usedColumns: "Columnas utilizadas:",
      },
    },
    measureManagement: {
      configMeasure: {
        measureInCurrency: "Medida en moneda",
        configCompounds: {
          operatorsCalculator: {
            operators: "Operadores",
          },
        },
        configCompoundsMeasure: {
          parenthesesMatches:
            "Asegúrate de que todos los paréntesis estén correctamente emparejados",
          formulaFinishInOperator:
            "Asegúrate que la fórmula no termine en un operador",
          measureName: "Nombre de la medida",
          measureNameAlreadyExists: "Ya existe una medida con ese nombre",
          formula: "Fórmula",
          enterFormula: "Ingrese la fórmula",
          measureExpenses: "Medidas gastos",
          measureSales: "Medidas ventas",
          measureCompounds: "Medidas compuestas",
          cancel: "Cancelar",
          accept: "Aceptar",
          createNewMeasure: "crear una nueva medida",
          newMeasure: "Nueva medida",
        },
        configExpensesMeasure: {
          newMeasureName: "Nombre nueva medida",
          cancel: "Cancelar",
          accept: "Aceptar",
          searchByBucketGrouper: "Búsqueda por Bucket o Agrupador",
          editMeasure: "Editar medida",
          newMeasure: "Nueva medida",
          newName: "Nuevo nombre",
          repeatedMeasureName: "Ya existe una medida con ese nombre",
        },
        configSalesMeasure: {
          tabs: {
            sales: "VENTAS",
            otherBase: "OTRA FUENTE",
          },
        },
        groupByRowSelects: {
          chooseColumn: "Elegir columna...",
          chooseFunction: "Elegir función...",
          columnName: "Nombre de la columna",
          nameAlreadyExists: "El nombre ya existe",
        },
        otherFontStep1: {
          file: "Archivo",
          selectFile: "Seleccionar archivo",
          mergeColumnsCurrentBase: "Columnas merge - Base actual",
          chooseColumns: "Elegir columnas",
          mergeColumnsSalesBase: "Columnas merge - Base VENTAS",
          numberColumnsSelectedEqualSalesBase:
            "La cantidad de columnas seleccionadas de la base actual debe ser igual a la de la base VENTAS",
          columnsToAddFunctionAggregation:
            "Columnas a agregar y función agregación",
          avoidDuplicatingValues:
            "Para evitar que se dupliquen valores, la información que se agregue se hará mediante un group-by previo, usando las columnas elegidas para el merge",
          reset: "Resetear",
          resetData: "Resetear datos",
          deleteAllData: "borrar todos los datos",
        },
        otherMeasureFont: {
          createNewMeasure: "crear una nueva medida",
          newMeasure: "Nueva medida",
          previous: "Anterior",
          finish: "Finalizar",
          next: "Siguiente",
        },
        salesMeasure: {
          loading: "Cargando...",
          measureName: "Nombre Medida",
          newMeasureName: "Nombre nueva medida",
          measureNameAlreadyExists: "Ya existe una medida con ese nombre",
          operation: "Operación",
          chooseOperation: "Elegir operación...",
          numerators: "Numeradores",
          chooseColumns: "Elegir columnas...",
          chooseColumn: "Elegir columna...",
          denominators: "Denominadores",
          columnsAddInfo:
            "Las columnas agregadas en cada input se sumarán automáticamente.",
          columns: "Columnas",
          column: "Columna",
          modifyMeasure: "modificar la medida",
          createNewMeasure: "crear una nueva medida",
          newMeasure: "Nueva medida",
          cancel: "Cancelar",
          accept: "Aceptar",
        },
      },
      measureAdmin: {
        tabs: {
          expenses: "GASTOS",
          sales: "VENTAS",
          compounds: "COMPUESTOS",
          created: "CREADAS POR EL USUARIO",
          default: "CREADAS POR DEFECTO",
          bucket: "BUCKET",
          bucketAgrupator: "BUCKET-AGRUPADOR",
        },
        cannotDeleteMeasureAsBeingUsed:
          "No se puede eliminar esta medida ya que está siendo usada por una medida compuesta.",
        searchingMeasures: "Buscando medidas...",
        loadingMeasures: "Cargando medida...",
        measureAdmin: "Administración de medidas",
        searchByName: "Búsqueda por nombre",
        notMeasuresYet: "Aún no hay medidas disponibles.",
        removeMeasure: "eliminar la medida",
        confirm: "Confirmar",
      },
      viewInfoCompoundsModal: {
        generalInfoMeasure: "Información general de la medida",
        formula: "Fórmula",
      },
      viewInfoSalesModal: {
        file: "Archivo",
        currentBaseColumns: "Columnas base actual:",
        baseColumnsSales: "Columnas base Ventas:",
        operation: "Operación:",
        columns: "Columnas:",
        denominatorColumns: "Columnas denominador:",
        groupers: "Agrupaciones",
      },
    },
  },
  delta: {
    getSalesForecast: {
      loading: "Obteniendo Sales Forecasts...",
      codes: {
        200: "Se obtuvieron los Sales Forecasts con éxito",
        201: "Se obtuvieron los Sales Forecasts con éxito",
        400: "Se produjo un error y no fue posible obtener los Sales Forecasts",
      },
    },
    postSalesForecast: {
      loading: "Creando Sales Forecast...",
      codes: {
        200: "El Sales Forecast se ha creado con éxito",
        201: "El Sales Forecast se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear el Sales Forecast",
      },
    },
    putSalesForecast: {
      loading: "Editando Sales Forecast...",
      codes: {
        200: "El Sales Forecast se ha editado con éxito",
        201: "El Sales Forecast se ha editado con éxito",
        400: "Se produjo un error y no fue posible editar el Sales Forecast",
      },
    },
    deleteSalesForecast: {
      codes: {
        200: "El Sales Forecast se ha eliminado con éxito",
        201: "El Sales Forecast se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el sales forecast",
      },
      loading: "Eliminando Sales Forecast...",
    },
    getCostForecast: {
      loading: "Obteniendo Costs Forecasts...",
      codes: {
        200: "Se obtuvieron los Costs Forecasts con éxito",
        201: "Se obtuvieron los Costs Forecasts con éxito",
        400: "Se produjo un error y no fue posible obtener los Costs Forecasts",
      },
    },
    loading: "Obteniendo análisis...",
    postCostForecast: {
      loading: "Creando Cost Forecast...",
      codes: {
        200: "El Cost Forecast se ha creado con éxito",
        201: "El Cost Forecast se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear el Cost Forecast",
      },
    },
    putCostForecast: {
      loading: "Editando Cost Forecast...",
      codes: {
        200: "El Cost Forecast se ha editado con éxito",
        201: "El Cost Forecast se ha editado con éxito",
        400: "Se produjo un error y no fue posible editar el Cost Forecast",
      },
    },
    deleteCostForecast: {
      codes: {
        200: "El Cost Forecast se ha eliminado con éxito",
        201: "El Cost Forecast se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el Cost Forecast",
      },
      loading: "Eliminando Cost Forecast...",
    },
    deleteSimulation: {
      codes: {
        200: "La simulación se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar la simulación",
      },
      loading: "Eliminando la simulación...",
    },
    postSimulation: {
      loading: "Creando la Simulación...",
      codes: {
        200: "La Simulación se ha creado con éxito",
        201: "La Simulación se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear la Simulación",
      },
    },
    putSimulation: {
      loading: "Editando la Simulación...",
      codes: {
        200: "La Simulación se ha editado con éxito",
        201: "La Simulación se ha editado con éxito",
        400: "Se produjo un error y no fue posible editar la Simulación",
      },
    },
  },
  deltaTexts: {
    salesForecast: {
      title: "Sales Forecast",
      salesForecastAdmin: {
        noPredictionsYet: "Aún no hay predicciones generadas",
        startCreatingOne: "Empieza creando una nueva a partir de un análisis",
        newSalesForecast: "Nuevo Sales Forecast",
        deleteSalesForecast: "eliminar el Sales Forecast",
        editSalesForecast: "editar el Sales Forecast",
        deleteAssociatedSimulationsAndRules:
          "Se eliminarán todas las reglas y simulaciones asociadas.",
      },
      newSalesForecastModal: {
        initialPeriod: "Periodo de inicio",
        chooseInitialPeriod: "Elegir periodo de inicio...",
        all: "TODAS",
        salesMeasuresToForecast: "Medidas de ventas a pronosticar",
        periodsToForecast: "Cantidad de periodos a predecir",
        choosePeriods: "Elegir cantidad de periodos",
      },
      salesForecastCard: {
        allPeriodsExecuted: "Todos los periodos habilitados fueron ejecutados.",
        notAllPeriodsExecuted:
          "No todos los periodos habilitados fueron ejecutados.",
        enabledPeriodsNonExecuted: "Periodos habilitados no ejecutados",
        none: "Ninguno",
      },
    },
    costForecast: {
      title: "Costs Forecast",
      costForecastAdmin: {
        noPredictionsYet: "Aún no hay predicciones generadas",
        startCreatingOne: "Empieza creando una nueva a partir de un análisis",
        newCostForecast: "Nuevo Cost Forecast",
        deleteCostForecast: "eliminar Cost Forecast",
        editCostForecast: "editar Cost Forecast",
        deleteAssociatedSimulationsAndRules:
          "Se eliminarán todas las reglas y simulaciones asociadas.",
      },
    },
    simulation: {
      title: "Administración de Simulaciones",
      simulation: "SIMULACIÓN",
      simulationAdmin: {
        theSimulation: "la simulación",
      },
      simulationCard: {
        errorTryAgain: "Hubo un error, intenta nuevamente.",
        autoRun: "Ejecución automática",
        lastPeriodDataExportNeedToBePredicted:
          "Falta predecir el último período del Data Export",
      },
      simulationConfig: {
        simulationConfig: "Configuración de Simulaciones",
        autoRun: "Ejecución automática",
        onceRuleChosen:
          "Una vez seleccionada la Regla de Simulación, los datos correspondientes del Sales Forecast se cargarán y quedarán bloqueados para su modificación.",
      },
      viewInfoModal: {
        routeConfig: "Configuración de Ruta",
        configType: "TIPO CONFIGURACIÓN",
        cancelRoute: "BAJA",
        modificationRoute: "MODIFICACIÓN",
        registeredRoute: "ALTA",
        filters: "FILTROS",
        modifications: "MODIFICACIONES",
        projectionMode: "MODALIDAD DE PROYECCIÓN",
        params: "PARÁMETROS",
        routesToAdd: "RUTAS A AGREGAR",
        rule: "REGLA",
        simulationName: "NOMBRE SIMULACIÓN",
      },
      modifyModicationModal: {
        previouslySelectedValues: "Valores seleccionados anteriormente",
      },
      step3NewModifyRouteModal: {
        averageSelectedRoutes: "Promedio rutas seleccionadas:",
        averageRoutesBasesOnSelectedValues:
          "Promedio rutas en base a valores seleccionados:",
      },
      step2SimulationConfig: {
        noConfigurationsYet: "Aún no hay configuraciones",
        deleteConfigs: "eliminar las configuraciones",
      },
    },
    simulationRules: {
      multipleAssignModal: {
        multipleAssign: "Asignaciones múltiples",
        assignCostDriver: "Asignar Driver de Costo",
      },
      configSimulationRule: {
        deleteAllAssignations: "eliminar las asignaciones",
        deleteAssignations: "Eliminar asignaciones",
      },
      ruleButtons: {
        noRowsWithAssignationsToDelete:
          "No hay filas seleccionadas con asignaciones",
        deleteAssignations: "Eliminar Asignaciones",
        multipleAssign: "Asignar a Varios",
      },
      transformDataRuleTable: {
        measure: "MEDIDA",
        variability: "VARIABILIDAD",
        costDriver: "DRIVER DE COSTO",
        cagrsText: "Tasa compuesta anual de crecimiento",
        variabilityText: "Medida de la fluctuación en los costos",
        driverText: "Variable que impulsa los cambios en el costo",
        correlationBetween: "Correlación entre",
        and: "y"
      },
    },
  },
  dialog: {
    cofirmThat: "¿Confirma que desea ",
    cancel: "Cancelar",
    accept: "Aceptar",
    confirmTitle: "Confirmar",
    filter: "Filtrar",
  },
  errorTexts: {
    pageNotExist: "Esta página no existe",
    backToHome: "Volver al inicio",
  },
  expensesSettings: {
    sendTransformations: {
      loading: "Guardando transformaciones...",
      codes: {
        200: "Las transformaciones se han aplicado con éxito.",
        201: "Las transformaciones se han aplicado con éxito.",
        400: "Se produjo un error y no fue posible aplicar las transformaciones",
        500: "Se produjo un error y no fue posible aplicar las transformaciones",
      },
    },
    getExpenses: {
      error: "Se produjo un error y no fue posible obtener los gastos",
    },
    getView: {
      error: "Se produjo un error y no fue posible obtener la vista",
    },
    saveView: {
      loading: "Guardando vista...",
      codes: {
        200: "La vista se ha guardado con éxito.",
        201: "La vista se ha guardado con éxito.",
        400: "Se produjo un error y no fue posible guardar la vista.",
      },
    },
    expensesSettingsTexts: {
      expensesConfig: "Configuración de Gastos",
      applyingTrans: "Aplicando transformaciones...",
      savingView: "Guardando vista...",
      filteredExpense: "Gasto Filtrado",
      totalExpense: "Gasto Total",
      noRowsSelected: "No hay filas seleccionadas",
      modify: "Modificar",
      errorDownload:
        "Se produjo un error y no fue posible descargar la información.",
      unaddedTransf:
        "Hay transformaciones sin agregar.  ¿Confirma igualmente que desea aplicar transformaciones?",
      wantToApply: "¿Confirma que desea aplicar estas transformaciones?",
    },
    modalContent: {
      valueExists: "*El valor ya existe",
      emptyFieldsCannotAdded: "*No se pueden agregar campos vacíos",
      chooseColumn: "Elegir columna...",
      add: "Agregar",
      chooseAddValue: "Elegir o agregar un valor...",
      newValue: "Nuevo valor",
      deleteRow: "Eliminar fila",
      noTransToApply: "No hay transformaciones para aplicar.",
      changeWillAffect: "Este cambio afectará a ",
      rows: "filas",
      cancel: "Cancelar",
      accept: "Aceptar",
    },
  },
  forms: {
    text: "Texto...",
    select: {
      loading: "Cargando...",
      noOptionsAvailable: "No hay opciones disponibles",
    },
  },
  general: {
    yes: "Si",
    no: "No",
    accept: "Aceptar",
    add: "SUMAR",
    all: "Todos",
    apply: "Aplicar",
    average: "PROMEDIAR",
    analysis: "Análisis",
    bimonthly: "Bimestral",
    bucket: "Bucket",
    buckets: "Buckets",
    bucketGrouper: "Bucket-Agrupador",
    cancel: "Cancelar",
    chooseColumn: "Elegir columna...",
    chooseColumns: "Elegir columnas...",
    clean: "Limpiar",
    cleanAll: "Limpiar todo",
    close: "Cerrar",
    column: "Columna",
    columns: "Columnas",
    composed: "Compuestas",
    confirm: "Confirmar",
    consolidateDestinations: "Consolidar destinos",
    consolidatePeriods: "Consolidar períodos",
    count: "CONTAR",
    countUnique: "CONTAR VALORES UNICOS",
    country: "País",
    create: "Crear",
    created: "Creadas",
    currency: "moneda",
    currencyy: "Moneda",
    delete: "Eliminar",
    deleteAll: "eliminar todos los elementos",
    divide: "DIVIDIR",
    dividingColumn: "Columna divisora",
    dividingColumns: "Columnas divisoras",
    doNotConsolidate: "No consolidar",
    edit: "Editar",
    emptyInputs: "Se deben completar todos los campos.",
    error: "Se produjo un error y no fue posible descargar la información.",
    errorEmptyField: "Este campo no puede estar vacío",
    expenses: "Gastos",
    file: "Archivo",
    filter: "Filtrar",
    findByName: "Buscar por nombre",
    finish: "Finalizar",
    first: "PRIMERO",
    foreignCurrency: "Monedas extranjeras",
    function: "Función",
    grouper: "Agrupador",
    home: "Inicio",
    indicator: "Indicador",
    information: "Información",
    import: "Importar",
    last: "ULTIMO",
    loading: "Cargando...",
    maximum: "MAXIMO",
    minimum: "MINIMO",
    minValueIs: "El valor mínimo es",
    month: "Mes",
    monthly: "Mensual",
    multiply: "MULTIPLICAR",
    name: "Nombre",
    next: "Siguiente",
    none: "Ninguno",
    noCardsYet: "Aún no hay información.",
    percentage: "porcentaje",
    previous: "Anterior",
    quarterly: "Trimestral",
    rule: "Regla",
    repeatedName: "Ya existe un elemento con este nombre",
    repeatedRowsError: "No se pueden agregar filas repetidas",
    repeatedRowsName: "No se pueden agregar filas con el mismo nombre",
    requiredDividingColumn: "Se debe seleccionar una columna divisora",
    requiredField: "Campo requerido",
    sales: "Ventas",
    searchByName: "Búsqueda por nombre",
    seeMore: "ver más",
    selectFile: "Seleccionar archivo...",
    selectOption: "Seleccionar opción...",
    selectOptions: "Seleccionar opciones...",
    selectValue: "Seleccionar valor...",
    selectValues: "Seleccionar valores...",
    since: "Desde",
    subtract: "RESTAR",
    substractingColumns: "Columnas a restar",
    sumProduct: "SUMA.PRODUCTO",
    type: "Tipo",
    uniqueCount: "CONTAR VALORES UNICOS",
    uniqueValues: "VALORES UNICOS",
    units: "unidades",
    until: "Hasta",
    usedElement:
      "Este elemento está siendo utilizado por otro y no puede eliminarse",
    value: "Valor",
    valueBetween: "El valor debe estar entre",
    variable: "Variable",
    writeName: "Escribir el nombre...",
    year: "Año",
    equal: "Igual",
    in: "existe en",
    notIn: "no existe en",
    greaterThan: "Mayor que",
    greaterThanOrEqual: "Mayor o igual que",
    lessThan: "Menor que",
    lessThanOrEqual: "Menor o igual que",
  },
  generatedDownloads: {
    downloads: "Descargas",
  },
  header: {
    settings: "Ajustes",
  },
  highCharts: {
    getAnalysisFilter: {
      error: "Se produjo un error y no fue posible filtrar el análisis",
      codes: {
        200: "El análisis se filtró con éxito",
        201: "El análisis se filtró con éxito",
        400: "Se produjo un error y no fue posible filtrar el análisis",
      },
    },
    analysis: "Análisis",
    periods: "Periodos",
    loading: "Cargando...",
    filterLoading: "Filtrando el análisis...",
    chooseAnalysis: "Elegir análisis...",
    filters: "Filtros",
    normalNavigation: "Navegación Normal",
    interactiveNavigation: "Navegación Interactiva",
  },
  icons: {
    delete: "Eliminar fila",
    deleteAll: "Eliminar todos",
    edit: "Editar",
  },
  importElements: {
    importTheElements: "importar los elementos",
    importElements: "Importar elementos",
    willDeleteAll:
      "Importar elementos de P&L eliminará todos los actuales y los reemplazará por los nuevos.",
  },
  indicators: {
    getGroups: {
      error:
        "Se produjo un error y no fue posible obtener los grupos de indicadores.",
    },
  },
  indicatorsManagement: {
    addGroup: {
      error: "Se produjo un error y no fue posible obtener los grupos",
      codes: {
        200: "El grupo se ha agregado con éxito",
        201: "El grupo se ha agregado con éxito",
        400: "Se produjo un error y no fue posible agregar el grupo",
      },
    },
    addIndicator: {
      codes: {
        201: "El indicador se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear el indicador",
      },
    },
    editIndicator: {
      codes: {
        200: "El indicador se ha actualizado con éxito",
        400: "Se produjo un error y no fue posible editar el indicador",
      },
    },
    deleteIndicator: {
      codes: {
        200: "El indicador se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el indicador",
      },
    },
    putState: {
      codes: {
        200: "El estado se ha modificado con éxito",
        201: "El estado se ha modificado con éxito",
        400: "Se produjo un error y no fue posible modificar el estado",
      },
    },
  },
  indicatorsManagementTexts: {
    addGroupModal: {
      groupNameAlreadyExists: "Ya existe un grupo con ese nombre",
      fieldCannotEmpty: "El campo no puede quedar vacío",
      newGroup: "Nuevo grupo",
      cancel: "Cancelar",
      accept: "Aceptar",
      name: "Nombre",
      newGroupName: "Nombre del nuego grupo",
    },
    chartCard: {
      modifyingChartState: "Modificando estado de gráfico...",
      baseFile: "Archivo base",
      analysis: "Análisis",
      axisY: "Eje Y",
      of: "de",
      graphicType: "Tipo de gráfico",
    },
    configCharts: {
      none: "Ninguna",
      creatingGraphic: "Creando gráfico...",
      modifyingGraphic: "Modificando gráfico...",
      editGraphic: "Editar gráfico",
      createGraphic: "Crear gráfico",
      editTheGraphic: "editar el gráfico",
      createTheGraphic: "crear el gráfico",
      resetAllGraphSteps: "Resetear todos los pasos del gráfico",
      cleanAll: "Limpiar todo",
      finish: "Finalizar",
      next: "Siguiente",
    },

    configIndicators: {
      exclude: "EXCLUIR",
      include: "INCLUIR",
      totalInExpense: "Total en gastos",
      totalInResult: "Total en resultados",
      totalTransferred: "Total trasladado",
      bucketPeriodsWithError: "Bucket-períodos con error",
      nameIndicatorExists: "Ya existe un indicador con este nombre",
      creatingIndicator: "Creando indicador...",
      modifyingIndicator: "Modificando indicador...",
      editIndicator: "Editar indicador",
      createIndicator: "Crear indicador",
      editTheIndicator: "editar el indicador",
      createTheIndicator: "crear el indicador",
      former: "Anterior",
      resetAllIndicatorSteps: "Resetear todos los pasos del indicador",
      cleanAll: "Limpiar todo",
      finish: "Finalizar",
      next: "Siguiente",
    },
    configTables: {
      creatingTable: "Creando tabla...",
      modifyingTable: "Modificando tabla...",
      editTable: "Editar tabla",
      createTable: "Crear tabla",
      editTheTable: "editar la tabla",
      createTheTable: "crear la tabla",
      resetAllTableSteps: "Resetear todos los pasos de la tabla",
      cleanAll: "Limpiar todo",
      finish: "Finalizar",
      next: "Siguiente",
      confirmDeleteElement:
        "Se eliminarán todos los elementos subsiguientes.  ¿Confirma que desea eliminar este elemento?",
      tableSample: "Muestra de la tabla",
      newAction: "Nueva acción",
      steps: {
        initalParametrization: "Parametrización inicial de la tabla",
        limitResults: "Limitar resultados",
      },
      selectionAnAction: "Seleccione una acción",
      theAnalysis: "El análisis",
      theSalesForecast: "El sales forecast",
      theCostsForecast: "El costs forecast",
      theSimulation: "La simulación",
      theTemplate: "El archivo",
      sourceCannotModify:
        "no se podrá modificar después de agregar un elemento.",
    },
    indicatorCard: {
      unitOfMeasurement: "Unidad de medida",
      group: "Grupo",
      active: "Activo",
      inactive: "Inactivo",
    },
    indicatorsManagement: {
      tabs: {
        indicators: "INDICADORES",
        graphics: "GRÁFICOS",
        files: "ARCHIVOS",
        analysis: "ANÁLISIS",
        simulation: "SIMULACIÓN",
        salesForecast: "SALES_FORECAST",
        costForecast: "COST_FORECAST",
        tables: "TABLAS",
      },
      indicatorConfigFile: "Configuración de Indicador - Archivo",
      indicatorConfigAnalysis: "Configuración de Indicador - Análisis",
      indicatorConfigAnalysisSimulation:
        "Configuración de Indicador - Simulación",
      indicatorConfigAnalysisSalesForecast:
        "Configuración de Indicador - Sales Forecast",
      indicatorConfigAnalysisCostForecast:
        "Configuración de Indicador - Costs Forecast",
      indicatorConfigTableFile: "Configuración de Tabla - Archivo",
      indicatorConfigTableAnalysis: "Configuración de Tabla - Análisis",
      indicatorConfigTableSimulation: "Configuración de Tabla - Simulación",
      indicatorConfigTableSalesForecast:
        "Configuración de Tabla - Sales Forecast",
      indicatorConfigTableCostForecast:
        "Configuración de Tabla - Costs Forecast",
      graphicConfigFile: "Configuración de Gráfico - Archivo",
      graphicConfigAnalysis: "Configuración de Gráfico - Análisis",
      graphicConfigAnalysisSimulation: "Configuración de Gráfico - Simulación",
      graphicConfigAnalysisSalesForecast:
        "Configuración de Gráfico - Sales Forecast",
      graphicConfigAnalysisCostForecast:
        "Configuración de Gráfico - Costs Forecast",
      indicatorAdmin: "Administración de dashboard",
      uploadingIndicators: "Cargando indicadores...",
      uploadingGráficos: "Cargando gráficos...",
      uploadingTables: "Cargando tablas...",
      deletingIndicator: "Eliminando indicador...",
      deletingGraphics: "Eliminando gráfico...",
      deletingTable: "Eliminando tabla...",
      searchByNameIndicatorGroup: "Búsqueda por nombre de indicador o grupo",
      searchByTableName: "Búsqueda por nombre de tabla",
      activeIndicators: "INDICADORES ACTIVOS",
      nonActiveIndicators: "No hay indicadores activos.",
      inactiveIndicators: "INDICADORES INACTIVOS",
      nonInactiveIndicators: "No hay indicadores inactivos.",
      activeTables: "TABLAS ACTIVAS",
      nonActiveTables: "No hay tablas activas.",
      inactiveTables: "TABLAS INACTIVAS",
      nonInactiveTables: "No hay tablas inactivas.",
      nonPossibleGetIndicators:
        "No fue posible obtener los indicadores. Vuelva a intentarlo más tarde.",
      nonPossibleGetTables:
        "No fue posible obtener las tablas. Vuelva a intentarlo más tarde.",
      searchByNameGraphicCoord: "Búsqueda por nombre de gráfico o coordenadas",
      activeGraphics: "GRÁFICOS ACTIVOS",
      nonActiveGraphics: "No hay gráficos activos.",
      inactiveGraphics: "GRÁFICOS INACTIVOS",
      nonInactiveGraphics: "No hay gráficos inactivos.",
      nonPossibleGetGraphics:
        "No fue posible obtener los gráficos. Vuelva a intentarlo más tarde.",
      deleteTheIndicator: "eliminar el indicador",
      deleteTheGraphic: "eliminar el gráfico",
      deleteTheTable: "eliminar la tabla",
      confirm: "Confirmar",
    },
    step1: {
      simulation: "Simulación",
      salesForecast: "Sales Forecast",
      coin: "Moneda",
      percentage: "Porcentaje",
      units: "Unidades",
      creatingIndicatorGroup: "Creando grupo de indicador...",
      description: "Descripción",
      indicatorName: "Nombre del Indicador",
      unitOfMeasurement: "Unidad de medida",
      chooseUnit: "Elegir unidad...",
      chooseCreateGroup: "Elegir o crear grupo...",
      parameterizationIndicator: "Parametrización del indicador",
      file: "Archivo",
      analysis: "Análisis",
      chooseFile: "Elegir archivo...",
      chooseAnalysis: "Elegir Análisis...",
      chooseSimulation: "Elegir Simulación...",
      chooseSalesForecast: "Elegir Sales Forecast...",
      column: "Columna",
      chooseColumn: "Elegir columna...",
      function: "Función",
      chooseFunction: "Elegir función...",
      inactiveIndactor: "Indicator Inactivo",
      activeIndicator: "Indicator Activo",
      singleSum: "Suma único",
      uniqueCriteriaColumn: "Columna de criterio único",
      productSum: "Suma producto",
      columnToMultiply: "Columna a multiplicar",
      chooseOneOrMoreOptions: "Elegir una o más opciones...",
      numerator: "Numerador",
      denominator: "Denominadores",
    },
    step1Charts: {
      aplicationOptions: "OPCIONES DE APLICACIÓN",
      periods: "periodos",
      fileColumns: "COLUMNAS DE ARCHIVO",
      creatingIndicatorGroup: "Creando grupo de indicador...",
      graphParameterization: "Parametrización del gráfico",
      graphicTitle: "Título del gráfico",
      writeGraphicName: "Escribir nombre del gráfico",
      graphicNameExists: "Ya existe un gráfico con este nombre",
      file: "Archivo",
      chooseFile: "Elegir archivo...",
      axisY: "Eje Y",
      chooseColumn: "Elegir columna...",
      baseColumn: "Columna base",
      axisYFunction: "Función eje y",
      baseColumnFunction: "Función columna base",
      chooseFunction: "Elegir función...",
      axisYNameOptional: "Nombre del eje Y (opcional)",
      writeAxisYName: "Escribir nombre del eje Y",
      axisX: "Eje X",
      chooseOption: "Elegir una opción...",
      axisXNameOptional: "Nombre del eje X (opcional)",
      writeAxisXName: "Escribir nombre del eje X",
      drillDownColumn: "Columna drilldown",
      optional: "optional",
      serieColumnOptional: "Columna series (opcional)",
      valuesColumnOptional: "Columna valores (opcional)",
      none: "Ninguna",
      group: "Grupo",
      chooseCreateGroup: "Elegir o crear grupo...",
      activeGraphic: "Gráfico Activo",
      auxiliaryLines: "Líneas auxiliares",
      line0AxisY: "Línea eje Y = 0",
      averageLineAxisX: "Línea promedio eje X",
      averageLineAxisY: "Línea promedio eje Y",
    },
    viewInfoModal: {
      columns: {
        column: "Columna",
        values: "Valores",
        criteria: "Criterio",
        function: "Función",
        name: "Nombre",
      },
      exlude: "Excluir",
      include: "Incluir",
      fileColumn: "Columna archivo",
      externFileColumn: "Columna archivo externo",
      criteriaBaseColumn: "Columna Base Criterio",
      generalInfoGraph: "Información general del gráfico",
      generalInfoIndicator: "Información general del indicador",
      unitOfMeasurement: "Unidad de medida",
      graphType: "Tipo de gráfico",
      group: "Grupo",
      baseFile: "Archivo base",
      analysis: "Análisis",
      baseColumn: "Columna base",
      axisY: "Eje Y",
      axisYName: "Nombre eje Y",
      appliedFunction: "Función aplicada",
      validateUniqueColumn: "Columna a validar únicos",
      multiplyColumn: "Columnas a multiplicar",
      axisX: "Eje X",
      axisXName: "Nombre eje X",
      drilldownColumn: "Columnas drilldown",
      serialColumns: "Columnas series",
      valuesColumn: "Columna valores",
      filtersOnFile: "Filtros en archivo",
      filterOf_one: "Filtrado de ",
      filterOf_two: "con información de",
      prefilterOnFile: "Filtros previos en archivo",
      crossoverValues: "Cruce de valores de",
      with: "con",
    },
    validateWaterfallRow: {
      rowNameAlreadyExists: "Ya existe una fila con este nombre",
      repeteadLines: "Hay filas repetidas",
      allFieldsMustBeCompleted: "Se deben completar todos los campos",
    },
  },
  indicatorsView: {
    getIndicators: {
      error: "Se produjo un error y no fue posible obtener los indicadores",
      loading: "Obteniendo indicadores...",
    },
    getIndicatorChart: {
      codes: {
        200: "Se pudo obtener el gráfico",
        400: "Se produjo un error y no fue posible obtener el gráfico del indicador",
      },
    },
    indicators: "Indicadores",
    periods: "Períodos",
    nonPossibleGetIndicators:
      "No fue posible obtener los indicadores. Vuelva a intentarlo más tarde.",
    noIndicatorsCreated: "Aún no hay indicadores creados.",
  },
  language: {
    languages: "Idiomas",
  },
  login: {
    loginLoading: "Iniciando sesión...",
    getInto: "Ingresar",
    login: "Iniciar Sesión",
    email: "Correo Electrónico",
    password: "Contraseña",
    loginError: "El usuario o la contraseña son incorrectos.",
    loginProblem:
      "Ha ocurrido un error al iniciar sesión. Intentalo nuevamente",
    rememberAuth: "Recordar código de autenticación",
    storeAuth:
      "Almacena por una semana el código de autenticación de dos pasos",
    trust: "Confiar en este equipo",
    trustLogin:
      "Mantiene la sesión iniciada en este equipo durante 24hs.  Usar solamente en equipos no públicos.",
  },
  marketBasket: {
    mergeFiles: "Archivos de merge",
    channel: "Canal",
    skuColumn: "Columna SKU",
    transactionColumn: "Columna transacción",
    transactionIdentifierColumn: "Columna identificadora de transacción",
    extraColumns: "Columnas extra",
    extraColumnsForAnalysis: "Columnas extra para el análisis",
    charts: "Gráficos",
    destinyIndicators: "Indicadores destino",
    brand: "Marca",
    analysisLevel: "Nivel de análisis",
    currencySales: "Venta moneda",
    unitSales: "Venta unidades",
    periodicity: "Periodicidad",
    primarySkuMetrics: "Métricas SKU primarias",
    secondarySkuMetrics: "Métricas SKU secundarias",
    primaryDestinyMetrics: "Métricas destino primarias",
    secondaryDestinyMetrics: "Métricas destino secundarias",
    objectives: "Objetivos",
    optional: "Opcional",
    advancedParameters: "Parámetros avanzados",
    advancedParametersOfAlgoritm: "Parámetros avanzados de algoritmo",
    initialParameterization: "Parametrización inicial",
    skuLevelMetrics: "Métricas a nivel SKU",
    destinyLevelMetrics: "Métricas a nivel destino",
    chartsa: "Gráficas",
    indicators: "Indicadores",
    admin: {
      title: "Administración de análisis Market Basket",
      searchByName: "Búsqueda por nombre",
      noAnalysisYet: "Aún no hay análisis disponibles.",
      deleteAnalysisConfirm: "¿Confirma que desea eliminar el análisis?",
      confirm: "Confirmar",
      analysisAdmin: "Administración de Análisis",
      periods: "Períodos",
      analysisInfo: "Información general del análisis",
      viewInfoModal: {
        title: "Información general del análisis Market Basket",
        mergeFiles: "Archivos de merge",
        channel: "Canal",
        skuColumn: "Columna SKU",
        transactionColumn: "Columna transacción",
        extraColumns: "Columnas extra",
        charts: "Gráficos",
        destinyIndicators: "Indicadores destino",
        brand: "Marca",
        analysisLevel: "Nivel de análisis",
        currencySales: "Venta moneda",
        unitSales: "Venta unidades",
        periodicity: "Periodicidad",
        primarySkuMetrics: "Métricas SKU primarias",
        secondarySkuMetrics: "Métricas SKU secundarias",
        primaryDestinyMetrics: "Métricas destino primarias",
        secondaryDestinyMetrics: "Métricas destino secundarias",
        objectives: "Objetivos",
        advancedParameters: "Parámetros avanzados",
      },
      delete: {
        codes: {
          200: "El análisis se ha eliminado con éxito",
          400: "Se produjo un error y no fue posible eliminar el análisis",
        },
        loading: "Eliminando análisis...",

        confirmationModal: {
          title: "Eliminar análisis",
          message: "¿Confirma que desea eliminar el análisis?",
        },
      },
      getAnalysis: {
        codes: {
          200: "Se obtuvo el análisis con éxito",
          400: "Se produjo un error y no fue posible obtener el análisis",
        },
        loading: "Obteniendo análisis...",
      },
    },
    config: {
      title: "Configuración de análisis Market Basket",
      analysisName: "Nombre del análisis",
      codes: {
        200: "El análisis se ha guardado con éxito.",
        201: "El análisis se ha guardado con éxito.",
        400: "Se produjo un error y no fue posible guardar el análisis.",
      },
      calculate: {
        codes: {
          200: "Se ha iniciado el cálculo con éxito.",
          201: "Se ha iniciado el cálculo con éxito.",
          400: "Se produjo un error y no fue posible iniciar el cálculo.",
        },
      },
      loading: "Guardando análisis...",
      calculating: "Calculando análisis...",
      confirmationModal: {
        title: "Guardar análisis",
        message: "¿Confirma que desea guardar el análisis?",
      },
      noFieldsTitle: "Campos sin agregar",
      noFieldsText: "Hay campos sin agregar, ¿desea continuar de todas formas?",
      indicators: "Indicadores",
      customerChain: "Cadena cliente",
      pointOfSale: "Punto de venta",
      fileOrAnalysisWithInformation: "Archivo/Análisis con la información",
      warningText:
        "Modificar o eliminar el archivo limpiará todos los campos relacionados.",
      addFont: "Agregar fuente",
      otherFiles: "Otros archivos a unir con el archivo principal",
      primaryMetricsAtSkuLevel: "Métricas a nivel SKU - Primarias",
      secondaryMetricsAtSkuLevel: "Métricas a nivel SKU - Secundarias",
      secondaryMetricsCalculation: "Cálculo de métricas secundarias",
      primaryMetricsAtDestinyLevel: "Métricas a nivel destino - Primarias",
      secondaryMetricsAtDestinyLevel: "Métricas a nivel destino - Secundarias",
      groupingVariable: "Variable para agrupar",
      chooseIndicator: "Elegir indicador...",
      newChart: "Nuevo gráfico",
      clickOnIndicator:
        "Hacer click en el indicador para ver el detalle de la evolución mensual.",
      indicatorExample: "Ejemplo de indicador",
      generateIndicatorFor: "Generar indicador para",
      generateDrilldownMonthlyEvolution:
        "Generar drilldown de evolución mensual",
      monthlyEvolutionDrilldown: "Drilldown de evolución mensual",
      objectivesCollection: "Colección objetivos",
      columnTargetValue: "Columna con valor objetivo",
      ownerIdentifierTarget: "Identificador dueño objetivo",
      targetColumnInAnalysis: "Columna de objetivo en análisis",
      ownerIdentifierTargetInAnalysis:
        "Identificador dueño objetivo en análisis",
      confirmCleanAll: "¿Confirma que desea limpiar todos los campos?",
      minimumSupportApriori: "Soporte mínimo Apriori",
      minimumSupportSet: "Soporte mínimo conjunto",
      minimumSupportAntecedent: "Soporte mínimo antecedente",
      minimumSupportConsequent: "Soporte mínimo consecuente",
      minimumConfidence: "Confianza mínima",
      minimumLift: "Lift mínimo",
      unitError: "Hay indicadores sin unidad de medida asignada.",
    },
    view: {
      rateNotLessMinus100: "La tasa no puede ser menor a -100%",
      valueNotLessZero: "El valor no puede ser menor a 0",
      simulateScenario: "Simular escenario",
      deleteSimulation: "Eliminar simulación",
      modifyRateOrValues:
        "Para simular un escenario, modifique los valores de tasa o volumen de ventas.",
      oportunityForSku: "Oportunidad por SKUs ya comprados",
      forSku: "para el SKU",
      scenariosSimulation: "Simulación de escenarios",
      simulationDescription:
        "Para simular un escenario, seleccione las filas de la tabla que desea ajustar y haga click en el botón 'Simular escenario'.  Los resultados se verán reflejados en la gráficas.",
      COGS_PORCENTUAL: "COGS PORCENTUAL",
      PRECIO_UNITARIO: "PRECIO UNITARIO",
      VENTA_BRUTA_POTENCIAL: "VENTA BRUTA POTENCIAL",
      CONTRIBUCION_BRUTA_POTENCIAL: "CONTRIBUCION BRUTA POTENCIAL",
      VOLUMEN_PROMEDIO: "VOLUMEN PROMEDIO",
      CONTRIBUCION_BRUTA_UNITARIA: "CONTRIBUCION BRUTA UNITARIA",
      ROTACION_PROMEDIO: "ROTACION PROMEDIO",
      ANTECEDENTS: "ANTECEDENTES",
      ANTECEDENT_SUPPORT: "SOPORTE DE ANTECEDENTE",
      SKU_SUPPORT: "SOPORTE DE SKU",
      SKU_ANTECEDENTS_SUPPORT: "SOPORTE DE SKU Y ANTECEDENTES",
      CONFIDENCE: "CONFIANZA",
      LIFT: "LIFT",
      LEVERAGE: "LEVERAGE",
      CONVICTION: "CONVICCIÓN",
      ZHANGS_METRIC: "METRICA DE ZHANGS",
      ROTACION_CLIENTE: "ROTACION CLIENTE",
      MES_DESDE_ULTIMA_COMPRA: "MESES DESDE ULTIMA COMPRA",
      VENTA_MONEDA_PROMEDIO: "VENTA MONEDA PROMEDIO",
      VENTA_UNIDADES_PROMEDIO: "VENTA UNIDADES PROMEDIO",
      VENTA_UNIDADES: "VENTA UNIDADES",
      VENTA_MONEDA: "VENTA MONEDA",
      CLIENTES_CON_OPORTUNIDAD: "CLIENTES CON OPORTUNIDAD",
      OPORTUNIDAD: "OPORTUNIDAD",
      OPORTUNIDAD_PARA_FILTRO: "OPORTUNIDAD PARA FILTRO",
    },
  },
  menuItemsOptions: {
    companyParameterization: {
      title: "Parametrización de la empresa",
      url: "parametrizacion-empresa",
    },
    dataManagement: {
      title: "Administración de datos",
      url: "administracion-datos",
    },
    validations: {
      title: "Validaciones",
      adminValidations: {
        title: "Administración de validaciones",
        url: "administracion-validaciones",
      },
      configCodeMatching: {
        title: "Configuración de cruce de valores",
        subtitleColumns: "Columnas",
      },
      matchesDashboard: {
        title: "Tablero de cruce de valores",
        url: "dashboard/cruce-codigos",
      },
    },
    criteriaManagement: {
      title: "Criterios",
      expensesTarget: {
        title: "Receptores de gasto",
        url: "receptores-gastos",
      },
      preprorrationCriteria: {
        title: "Criterios de preprorrateo",
        url: "criterios-preprorrateo",
      },
      schemasManagement: {
        title: "Administración de esquemas",
        url: "administracion-esquemas",
      },
      prorationCriteria: {
        title: "Criterios de prorrateo",
        url: "criterios-prorrateo",
      },
    },
    schemaManagement: {
      title: "Administración de esquemas",
      url: "administracion-esquemas",
    },
    preProrationParameterization: {
      title: "Parametrización preprorrateo",
      url: "parametrizacion-preprorrateo",
    },
    configPreProratedExpenses: {
      title: "Configurar gastos preprorrateo",
      url: "configurar-gastos-preprorrateo",
    },
    configProRataExpenses: {
      title: "Configurar gastos prorrateo",
      url: "configuracion-gastos",
    },
    modelParameterization: {
      title: "Parametrización del modelo",
    },
    parameterization: {
      title: "Parametrización",
    },
    controlPanel: {
      title: "Control panel",
      url: "tablero-control",
    },
    dashboard: {
      title: "Dashboard",
      url: "",
    },
    taskManager: {
      title: "Gestor de tareas",
      url: "gestor-tareas",
    },
    indicatorManagement: {
      title: "Administración de dashboard",
      url: "administracion-dashboard",
    },
    dataExportModelling: {
      title: "Data Export Modelling",
      url: "",
    },
    pnLParameterization: {
      title: "Parametrización P&L",
      costStructure: {
        title: "Estructura de Costos",
        url: "/parametrizacion-p-l/estructura-costos",
      },
      pnLDefinition: {
        title: "Definición P&L",
        url: "/parametrizacion-p-l/definicion",
      },
    },
    simulator: {
      title: "Delta",
    },
    simulatorCollapseList: {
      costForecast: {
        title: "Costs Forecast",
        url: "/delta/cost-forecast",
      },
      salesForecast: {
        title: "Sales Forecast",
        url: "/delta/sales-forecast",
      },
      simulationRules: {
        title: "Reglas de simulación",
        url: "/delta/administracion-reglas",
      },
      simulations: {
        title: "Administración de simulaciones",
        url: "/delta/simulaciones",
      },
      registeredRoutes: {
        title: "Administración de Rutas",
        url: "/delta/administracion-rutas",
      },
      simulationsResults: {
        title: "Resultados de simulación",
        url: "/delta/resultados-simulacion",
      },
    },
    generatedDownloads: {
      title: "Descargas",
      url: "descargas-generadas",
    },
    parameterizationCollapseList: {
      assignStages: {
        title: "Asignar Etapas",
        url: "parametrizacion-modelo/asignacion-etapas",
      },
      criteriaCalculation: {
        title: "Criterios y cálculo",
        url: "parametrizacion-modelo/calculo",
      },
      calculationReport: {
        title: "Reporte de cálculo",
        url: "parametrizacion-modelo/reporte",
      },
    },
    dashboardCollapseList: {
      indicators: {
        title: "Indicadores",
        url: "dashboard/indicadores",
      },
      graphics: {
        title: "Gráficos",
        url: "dashboard/graficos",
      },
      analysis: {
        title: "Análisis",
        url: "dashboard/highcharts",
      },
      tableros: {
        title: "Tableros",
        url: "dashboard/tableros",
      },
    },
    dataExportModellingCollapseList: {
      measureManagement: {
        title: "Administración de medidas",
        url: "data-modelling/administracion-medidas",
      },
      groupingsManagement: {
        title: "Administración de agrupaciones",
        url: "data-modelling/administracion-agrupaciones",
      },
      analysisManagement: {
        title: "Administración de análisis",
        url: "data-modelling/administracion-analisis",
      },
      analysisExecution: {
        title: "Ejecución de análisis",
        url: "data-modelling/ejecucion-analisis",
      },
      downloadReport: {
        title: "Descargar Reporte",
        url: "data-modelling/descargar-reporte",
      },
    },
    administrator: {
      title: "Administrador",
      url: "administrador",
    },
    marketBasketCollapseList: {
      title: "Market Basket",
      admin: {
        title: "Administración de análisis",
        url: "market-basket/administracion-analisis",
      },
      execution: {
        title: "Ejecución de análisis",
        url: "market-basket/ejecucion-analisis",
      },
      analysis: {
        title: "Análisis de Market Basket",
        url: "market-basket/analisis",
      },
    },
  },
  modelParameterization: {
    sendStages: {
      loading: "Guardando etapas...",
      codes: {
        200: "Las etapas se asignaron con éxito.",
        201: "Las etapas se asignaron con éxito.",
        400: "Se produjo un error y no fue posible asignar las etapas.",
      },
    },
    postCalculateProrration: {
      codes: {
        200: "El cálculo se ha iniciado con éxito",
      },
    },
    postResetCalculation: {
      codes: {
        200: "El cálculo se ha reseteado con éxito",
        400: "Se produjo un error y no fue posible resetear el cálculo",
      },
    },
    propagateCriteria: {
      loading: "Propagando criterios...",
      codes: {
        200: "El criterio se ha propagado con éxito",
        400: "Se produjo un error y no fue posible propagar el criterio",
      },
    },
    deleteCriteria: {
      singularCodes: {
        200: "El criterio se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar el criterio",
      },
      singularLoading: "Eliminando criterio...",
      pluralCodes: {
        200: "Los criterios se han eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar los criterios",
      },
      pluralLoading: "Eliminando criterios...",
    },
    dataForDummy: {
      codes: {
        200: "Los datos se han cargado con éxito",
        400: "Se produjo un error y no fue posible cargar los datos",
      },
    },
    getGrouperRows: {
      loading: "Obteniendo información...",
      codes: {
        400: "Se produjo un error y no fue posible descargar las filas.",
      },
    },
    getCriteriaData: {
      loading: "Obteniendo información...",
      codes: {
        400: "Se produjo un error y no fue posible descargar la información del criterio.",
      },
    },
    getProrationResults: {
      codes: {
        200: "Se obtuvieron los resultados exitosamente.",
        400: "Se produjo un error y no fue posible descargar los resultados",
        500: "Se produjo un error y no fue posible descargar los resultados",
      },
      loading: "Obteniendo resultados...",
    },
  },
  modelParameterizationTexts: {
    buckets: {
      error: "Se produjo un error y no fue posible obtener los buckets",
    },
    calculateBucketButton: {
      rowsWithoutAssignedCriteria:
        "Es necesario que todas las filas tegan un criterio asignado para iniciar el cálculo del Bucket.",
      amountIsZero:
        "No es posible iniciar el cálculo del bucket si el gasto es $0",
      calculated: "Calculado",
      notCalculated: "Calcular bucket",
      calculateActualBucket: "calcular el bucket actual",
      calculateBucketWithNotTransferred:
        "calcular bucket con gastos no trasladados",
    },
    calculations: {
      title: "Parametrización del modelo: Asignación de criterio y cálculo",
      periods: "Períodos",
    },
    charts: {
      origin: "Origen",
      percentage: "Porcentaje",
      stage: "Etapa",
      coin: "Moneda",
      formatterTitle: "Porcentaje respecto al gasto origen",
    },
    criteriaModelContent: {
      propagatingCriteria: "Propagando criterios...",
      selectReceiver: "Seleccionar receptor",
      selectCriteria: "Seleccionar criterio",
      apportionmentCriteria: "Criterio de Prorrateo",
      destinationCriteria: "Asignar criterios diferenciados por destino",
      only50rows: "Sólo será válido hasta 50 filas",
      completeFields: "Completar todos los campos",
      finalRecipientOfExpense: "RECEPTOR FINAL DE GASTO",
      chooseReceiver: "Elegir receptor...",
      confirm: "Confirmar",
      cancel: "Cancelar",
      confirmationMessage:
        "¿Confirma que desea asignar los criterios? Esta acción reseteará el cálculo si ya habia sido iniciado.",
    },
    customModelCell: {
      errorDownload:
        "Se produjo un error y no fue posible descargar la información.",
    },
    customToolbar: {
      confirmationWithRowsFirstPart:
        "¿Confirma que desea eliminar los criterios de ",
      confirmationWithRowsSecondPart:
        "filas? Esta acción reseteará el cálculo.",
      confirmationsWithoutRows:
        "¿Confirma que desea eliminar los criterios de la fila? Esta acción reseteará el cálculo.",
      downloadingCSV: "Descargando archivo csv...",
      deleteCriteria: "Eliminando criterio",
      exportCSV: "Exportar a CSV",
      totalCost: "Gasto Total",
      noCostDifInStageZero: "No hay gastos diferenciados en la etapa 0.",
      noCostDif: "No hay agrupadores que tengan gastos diferenciados.",
      difByDestiny: "Diferenciar por destino",
      noRowsSelectedWithCriterias:
        "No hay filas seleccionadas con criterios para eliminar.",
      deleteCriterias: "Eliminar criterios",
      noRowsSelected: "No hay filas seleccionadas.",
      difReceivers:
        "No se puede asignar criterios a agrupadores con receptores diferentes.",
      noCriteriaStage0: "No se puede asignar criterios a la etapa 0.",
      assingCriteria: "Asignar Criterios",
      confirm: "Confirmar",
      close: "Cerrar",
      accept: "Aceptar",
    },
    customTypographyTableText: {
      mount: "monto",
    },
    dummyGrouperGroup: {
      groupers: "Agrupadores",
      expenseColumn: "Columna Gasto",
      salesFileColumn: "Columna archivo ventas",
    },
    iterationsButtons: {
      iterations: "ITERACIONES",
    },
    getGroupingColDef: {
      grouper: "Agrupador",
      collapse: "Colapsar",
      expand: "Expandir",
    },
    calculationTabValue: {
      nonTransferredExpenses: "GASTOS NO TRASLADADOS",
    },
    modelTable: {
      succesEditName: "Se ha editado el nombre correctamente",
      errorEditName: "Ha ocurrido un error al editar el nombre",
      grouper: "agrupador",
      all: "todas",
      withoutCritera: "sin criterio",
      editName: "Editar nombre",
      newName: "Nuevo nombre...",
      apply: "Aplicar",
      editingName: "Editando nombre...",
      putNewName: {
        codes: {
          200: "Se ha editado el nombre correctamente",
          400: "Ha ocurrido un error al editar el nombre",
        },
        loading: "Editando nombre...",
      },
      getCriteriaTargetError:
        "Se produjo un error y no fue posible obtener los receptores",
      getCriteriaError:
        "Se produjo un error y no fue posible obtener los criterios",
    },
    onChangeSelectValue: {
      stage: "etapa",
      column: "columna",
      doNotSend: "no enviar",
    },
    useRadioGroup: {
      doNotValidate: "noValidar",
      doNotValidateLabel: "No Validar",
      flaw: "defecto",
      flawLabel: "Por defecto",
      other: "otra",
      otherLabel: "Otra columna",
      file: "Archivo",
      column: "Columna",
      chooseColumn: "Elegir columna",
    },
    resetButton: {
      toReset: "Resetear",
      resetOptions: "OPCIONES DE RESETEO",
      currentStageCalculation: "el cálculo de etapa actual",
      resetCurrentStageCalculation: "Resetear cálculo de etapa actual",
      allCalculation: "todo el cálculo",
      resetAllCalculation: "Resetear todo el cálculo",
      calculationStageBucket: "el cálculo y etapas del bucket",
      resetCalculationStageBucket: "Resetear cálculo y etapas del bucket",
      allCalculationBucketStages: "todos los cálculos y etapas",
      resetAllCalculationStages: "Resetear todos los cálculos y etapas",
      currentIterationCalculation: "el cálculo de la iteración actual",
      resetCurrentIterationCalculation: "Resetear cálculo de iteración actual",
    },
    stagesButtons: {
      stage: "ETAPA",
    },
    step2: {
      tabValueExpenses: "GASTOS",
      tabValueExpensesNotTransferred: "GASTOS NO TRASLADADOS",
      calculateEverything: "calcular todo",
      calculateEverythingTitle: "Calcular Todo",
      startingCalculation: "Iniciando cálculo...",
      resetting: "Reseteando...",
      checkPreviousStageCalculationError:
        "Hubo un error y quedaron agrupadores duplicados. Revisar cálculo de la etapa anterior.",
      assignCriteria: "Asignar Criterio",
      resetCalcultatonStageBucket: "resetear el cálculo y etapas del bucket",
      resetAllCalculationsStages: "Resetear todos los cálculos y etapas",
      confirmPartOne: "Confirma que desea ",
      confirmPartTwo:
        " Esta acción lo redirigirá nuevamente a la asignación de etapas",
      confirm: "Confirmar",
      stillExpensesNotTransferredStage:
        "Aún hay gastos no trasladados de la etapa",
      confirmStartCalculationAnyway:
        "Confirma que desea iniciar el cálculo de todas formas",
      confirmStartCalculation: "Confirmar inicio del cálculo",
      editCriteria: "Editar criterio",
      apportionment: "PRORRATEO",
      reassingNewCriteriaInAllGroupersWithReceiverAndOldCriteria:
        "¿Desea reasignar el nuevo criterio en todos los agrupadores con el mismo receptor y criterio anterior?",
      reassingCriteriaToRows: "Reasignar criterio a filas",
      close: "cerrar",
      resetCalculationCurrentIteration:
        "Resetear el cálculo de la iteración actual",
      all: "todos",
      calculateCurrentBucket: "calcular el bucket actual",
    },
    switchListSecondary: {
      onlyGroupersWithDifferentiatedExpenses:
        "Solo se muestran agrupadores que tienen gastos diferenciados.",
      recordedInGrouperMessagePartOne:
        "registros en este agrupador. Por performance, el límite para diferenciar gastos por destino es ",
      recordedInGrouperMessagePartTwo: "registros",
      noGroupersWithDifferentiatedExpenses:
        "No hay agrupadores que tengan gastos diferenciados.",
    },
    totalAmount: {
      expense: "Gasto",
    },
    transformData: {
      destination: "destino",
      oldReceiverCode: "cod receptor anterior",
      totalExpense: "Gasto total",
    },
    nestedList: {
      flaw: "defecto",
      relatedGroupers: "Agrupadores relacionados:",
    },
    viewDummyModal: {
      expensesColumn: "Columna Gastos",
      fileSalesColumn: "Columna archivo ventas",
      stage: "etapa",
      dummyDirectTransfer: "Traslado Directo Dummy",
      criteriaInfo: "Información del criterio",
      finalRecieverExpense: "Receptor final de gasto",
    },
    columnsTreeData: {
      getColumns: {
        grouper: "agrupador",
        amount: "monto",
        stages: "etapas",
        amountExpense: "MONTO GASTO",
      },
      getGroupingColDef: {
        bucketExpense: "BUCKET GASTO",
        collapse: "Colapsar",
        expand: "Expandir",
      },
    },
    formattedRows: {
      missingAssingStages: "Falta asignar etapas",
      assignStages: "Etapas asignadas",
    },
    stages: {
      stages: "Etapas",
      nowRowsSelected: "No hay filas seleccionadas",
      assignToSelected: "Asignar a seleccionados",
      stage: "etapa",
    },
    stagesSettings: {
      stagesSettingTitle: "Parametrización del modelo: Asignación de etapas",
      periods: "Períodos",
    },
    step1: {
      stagesGreaterThan10: "El número de etapas no puede ser mayor a 10",
      stagesGreaterThan0: "El número de etapas debe ser mayor a 0",
      missingToAssingStages: "Faltar asignar etapas a algunos agrupadores.",
      editingCauseCleared:
        "Editar el número de etapas de un agrupador causará que se borre su asignación de criterio si tenia una.",
      assigningStages: "Asignando etapas",
    },
    treeDataCustomGroupingColumn: {
      successfullyEditName: "Se ha editado el nombre correctamente",
      errorEditName: "Ha ocurrido un error al editar el nombre",
      all: "todas",
      noRows: "sin fila",
      errorNotPossibleToDownload:
        "Se produjo un error y no fue posible descargar la información",
      editName: "Editar nombre",
      newName: "Nuevo nombre...",
      apply: "Aplicar",
      unifiedBucketsGroupers:
        "Si el nuevo nombre del bucket coincide con el nombre de un bucket ya existente, éstos y sus agrupadores se unificarán. Esta acción no puede deshacerse",
      editingName: "Editando nombre...",
    },
    customCheckbox: {
      all: "Todas",
      withoutCriteria: "sin criterio",
      rowsWithoutCriteria: "Filas sin criterio asignado",
      withoutRow: "sin fila",
      rowsWithoutStages: "Filas sin etapa asignada",
    },
    customGridTreeDataGroupingCell: {
      editName: "Editar nombre:",
    },
    utils: {},
    dummyTransfer: "TRASLADO DUMMY",
    validationDropdown: {
      codeValidation: "Validación de códigos",
      stage: "Etapa",
    },
  },
  pageinProgress: {
    underDevelopment: "Esta página aún se encuentra en desarrollo",
  },
  periods: {
    multipleSelectCheckmarks: {
      periods: "Períodos",
    },
    enabledPeriodsTable: {
      year: "Año",
      enabledPeriods: "Períodos habilitados",
      notEnabledPeriods: "Períodos no habilitados",
      nonExistingPeriods: "Períodos no existentes",
    },
    periodsDialogContent: {
      filterPeriodsSuccess:
        "Se aplicaron los filtros para períodos exitosamente.",
      errorSendingPeriods: "Error al enviar los periodos",
      applyingPeriods: "Aplicando periodos...",
      filter: "Filtrar",
      noPeriodsExported: "No hay períodos exportados",
      selectAll: "Seleccionar Todos",
      cleanAll: "Limpiar Todos",
      enabledPeriods: "Períodos habilitados",
      onlyWithExpenses: "se muestran únicamente los que tienen gastos",
      noPeriodsAvailableYet: "Aún no hay períodos disponibles",
      cancel: "Cancelar",
      apply: "Aplicar",
    },
    periodsFilter: {
      periods: "Períodos",
    },
  },
  persistLogin: {
    logoutLoading: "Cerrando sesión...",
    role: "Cliente",
  },
  phibot: {
    closeSideBar: "Cerrar barra lateral",
    openSideBar: "Abrir barra lateral",
    newChat: "Nuevo chat",
    deleteHistory: "Eliminar historial",
    firstMessage:
      "¡Hola! Qué bueno tenerte por acá. Soy PHITO, tu asistente virtual y estoy para ayudarte en tus consultas.",
    changeHistoryNameModal: {
      nameCannotBeEmpty: "El nombre no puede estar vacío.",
    },
    chatBotMessages: {
      writing: "Escribiendo",
      cleanChat: "Limpiar chat",
      writeMessageHere: "Escribe tu mensaje aquí...",
      loading: "Cargando...",
      sendMessage: "Enviar mensaje",
      chatbotInfo:
        "PHITO es una herramienta poderosa, pero puede tener imprecisiones. Verifica la información crítica para asegurarte de tomar decisiones informadas.",
      errorToResponse:
        "Estoy teniendo dificultades para responder en este momento. Por favor, intenta de nuevo más tarde.",
    },
    infoChatbotModal: {
      chatbotMarkDown: `PHITO es tu **asistente virtual** que utiliza tecnología avanzada para ayudarte en tus consultas.

## Funciones Principales

                  1. **Asistencia Instantánea**: PHITO responde tus preguntas y te brinda soporte en tiempo real.
                  2. **Interacción Natural**: Puedes comunicarte con PHITO usando lenguaje natural.
                  3. **Acceso Rápido a Información**: Obtén información útil para tomar decisiones informadas.
                  4. **Mejora Continua**: PHITO aprende y se adapta con el tiempo para mejorar su precisión.

                **Nota**: Aunque PHITO es muy útil, te recomendamos verificar los datos importantes antes de tomar decisiones basadas en sus respuestas.
              `,
    },
    messageBubble: {
      selectOptionChart:
        "A partir de los datos sugiero las siguientes opciones para graficar:",
      selectOption: "Seleccione una opción:",
      sqlQuery: "Consulta SQL:",
      chartMessage:
        "Aquí tienes el gráfico que visualiza la información que pediste. Espero que te sea útil:",
      chartError:
        "Parece que hubo un problema al cargar el gráfico. Inténtalo de nuevo más tarde.",
    },
    sideBarChatBot: {
      history: "HISTORIAL",
      today: "Hoy",
      sevenDaysPrevious: "7 días anteriores",
      yesterday: "Ayer",
      before: "Antes",
      deleteHistory: "Eliminar este historial",
      deleteAllHistory: "Eliminar todo el historial",
      see: "Ver",
      delete: "Eliminar",
      editName: "Editar nombre",
      options: "Opciones",
    },
    responseFeedback: {
      appropriateResponse: "Respuesta adecuada",
      inappropriateResponse: "Respuesta inadecuada",
      errorMessage: "Hubo un error, intentelo más tarde.",
    },
    historySection: {
      notHistoryYet: "Aún no tienes historial",
      notHistoryToday: "Aún no tienes historial hoy",
      doYourFirstConsult: "¡Haz tu primera consulta!",
    },
  },
  preprorration: {
    preprorrationCalculate: {
      loading: "Comenzando cálculo...",
      codes: {
        200: "El cálculo se ha iniciado con éxito",
        201: "El cálculo se ha iniciado con éxito",
      },
    },
  },
  preprorationParameterization: {
    getPreprorationBuckets: {
      loading: "Obteniendo buckets...",
      error: "Se produjo un error y no fue posible descargar los buckets.",
    },
    getPreprorationGrouper: {
      loading: "Obteniendo agrupadores...",
      error: "Se produjo un error y no fue posible descargar los agrupadores.",
    },
    schemaInfoModal: {
      bucketExpenseUnderscore: "BUCKET_GASTO",
      bucketExpense: "BUCKET GASTO",
      grouper: "AGRUPADOR",
      percentage: "Porcentaje",
      criteriaOutline: "Esquema de criterios",
    },
    api: {
      getPreprorationCriterias: {
        errorReceiveCriteria: "Error recibir los criterios",
      },
    },
    newSchemaContent: {
      criteria: "criterio",
      percentage: "Porcentaje",
      schemaWithNameAlreadyExists: "Ya existe un esquema con este nombre",
      necessaryCompleteFields: "Es necesario completar todos los campos",
      alreadyCriteriaApplied:
        "Ya existe un criterio aplicado en el destino seleccionado",
      percentageCannotLessOne: "El porcentaje no puede ser menor a 1%",
      alreadyPercentageApplied:
        "Ya hay un porcentaje aplicado en el destino seleccionado",
      percentageCannotExceedOneHundred:
        "El porcentaje total no puede superar el 100%",
      necessaryCompletFieldsForSchema:
        "Es necesario completar todos los campos para crear un esquema",
      totalPercentageMustBeOneHundred:
        "El porcentaje total de los criterios debe ser 100%",
      schemaName: "Nombre del esquema",
      bucketExpense: "Bucket Gasto",
      bucketDestination: "BUCKET DESTINO",
      grouperDestination: "agrupador destino",
      add: "Agregar",
      chooseAddValue: "Elegir o agregar valor...",
      chooseCriteria: "Elegir criterio...",
      cancel: "Cancelar",
      save: "Guardar",
      choosePercentage: "Elegir porcentaje...",
    },
    preprorationParameterization: {
      tabs: {
        bucketExpense: "Bucket Gasto",
        grouper: "Agrupador",
        noExpensesToPreprorate: "No hay gastos para preprorratear en",
      },
      schemaTabs: {
        selectSchema: "Seleccionar Esquema",
        createSchema: "Crear Esquema",
      },
      applySchemas: {
        codes: {
          200: "La aplicación de esquema/s fue exitosa",
          201: "La aplicación de esquema/s fue exitosa",
          400: "Error al aplicar esquema/s",
        },
      },
      createSchema: {
        codes: {
          200: "Se creó el esquema correctamente",
          201: "Se creó el esquema correctamente",
          400: "Error al crear el esquema",
        },
      },
      reverseCalculationMessages: {
        codes: {
          200: "Se revirtió el cálculo correctamente",
          201: "Se revirtió el cálculo correctamente",
          400: "Error al revertir el cálculo",
        },
      },
      criteria: "criterio",
      successApplySchemas: "Esquemas aplicados correctamente.",
      successApplySchema: "Esquema aplicado correctamente.",
      errorApplySchema: "Error al aplicar el esquema",
      successCreateSchema: "Se creó el esquema correctamente",
      errorCreateSchema: "Error al crear el esquema",
      bucketExpensesUnderscore: "BUCKET_GASTO",
      successReversedCalculation: "Se revirtió el cálculo correctamente.",
      errorReversedCalculation: "Error al revertir el cálculo",
      schemasToApplyMissing:
        "No se puede realizar el cálculo, faltan esquemas por aplicar",
      noDataToCalculation: "No hay datos para realizar el cálculo",
      applyingSchemas: "Aplicando esquemas...",
      applyingSchema: "Aplicando esquema...",
      creatingSchema: "Creando esquema...",
      reversingCalculation: "Revirtiendo cálculo...",
      settingUpPreproration: "Parametrización de preprorrateo",
      periods: "Períodos",
      allRowsNeedSchemas:
        "Es necesario que todas las filas tengan esquemas aplicados para iniciar el cálculo",
      calculate: "Calcular",
      reverseCalculation: "Revertir cálculo",
      assingSchema: "Asignar esquema",
      errorDownloadingInfo:
        "Se produjo un error y no fue posible descargar la información.",
      noExpensesToPreprorate: "No hay gastos para preprorratear en",
      criteriaOutline: "Esquema de criterios",
      startCalculation: "iniciar el cálculo de preprorrateo",
      resetCalculation: "resetear el cálculo de preprorrateo",
      confirmCalculation: "Confirmar cálculo",
      confirmReset: "Confirmar reseteo",
    },
    selectSchemaContent: {
      bucketExpense: "Bucket Gasto",
      bucketExpenseUnderscore: "BUCKET_GASTO",
      grouper: "Agrupador",
      criteria: "Criterio",
      chooseExistingSchema: "Elegir un esquema existente",
      selectSchema: "Seleccionar esquema...",
      detail: "Detalle",
      schemaWillAplly: "El esquema se aplicará a",
      row: "fila",
      selected: "seeleccionada",
      cancel: "Cancelar",
      apply: "Aplicar",
    },
  },
  report: {
    percentage: "Porcentaje",
    incorrectCalculation: "Cálculo incorrecto",
    expensesTotal: "Total en gastos",
    resultsTotal: "Total en resultados",
    totalTransferred: "Total trasladado",
    bucketPeriodsError: "Bucket-períodos con error",
    getPercentages: {
      loading: "Obteniendo datos de períodos...",
      error:
        "Ha ocurrido un error al obtener los datos de períodos, intentar nuevamente.",
    },
    getKpis: {
      loading: "Obteniendo KPIs...",
      error: "Ha ocurrido un error al obtener los KPIs, intentar nuevamente.",
    },
    getFiles: {
      loading: "Obteniendo archivos...",
      error:
        "Ha ocurrido un error al obtener los archivos, intentar nuevamente.",
    },
    getAnalysis: {
      loading: "Obteniendo análisis...",
      error:
        "Ha ocurrido un error al obtener los análisis, intentar nuevamente.",
    },
    getPeriods: {
      loading: "Obteniendo periodos...",
      codes: {
        200: "Se obtuvieron los períodos exitosamente.",
        400: "Se produjo un error y no fue posible descargar los períodos",
        500: "Se produjo un error y no fue posible descargar los períodos",
      },
      error:
        "Ha ocurrido un error al obtener los periodos, intentar nuevamente.",
    },
    getCharts: {
      loading: "Obteniendo gráficos...",
      error:
        "Ha ocurrido un error al obtener los gráficos, intentar nuevamente.",
    },
  },
  reportTexts: {
    graphicsTab: {
      failedDownload: "No se pudo descargar información",
      downloadingIndicators: "Descargando indicadores...",
      resultsIndicators: "INDICADORES DE RESULTADOS",
      period: "Período",
    },
    preprorrationErrorsTable: {
      failedDownload: "No se pudo descargar información",
      preProrrated: "PREPRORRATEO",
    },
    preprorrationReportTab: {
      tabs: {
        transferredPercentage: "PORCENTAJE TRASLADADO",
        destinationsByGroup: "DESTINOS POR GRUPO",
      },
    },
    preprorrationSumTable: {
      failedDownload: "No se pudo descargar información",
      preProrrated: "PREPRORRATEO",
    },
    transformDataForSumTable: {
      columns: {
        group: "GRUPO",
        destination: "DESTINO",
        period: "PERIODO",
        amountExpense: "MONTO_GASTO",
        destinationType: "TIPO_DESTINO",
        percentage: "PORCENTAJE",
        grouper: "agrupador",
        transferred: "trasladado",
        amountExpenseResult: "MONTO_GASTO_RESULTADO",
        amountExpenseOrigin: "MONTO_GASTO_ORIGEN",
        relativeError: "ERROR_RELATIVO",
        correctCalculation: "CALCULO_CORRECTO",
      },
      coin: "moneda",
    },
    prorrationDifTable: {
      failedDownload: "No se pudo descargar información",
      apportionment: "PRORRATEO",
    },
    prorrationErrorTable: {
      failedDownload: "No se pudo descargar información",
      apportionment: "PRORRATEO",
    },
    prorrationIterationTable: {
      failedDownload: "No se pudo descargar información",
      apportionment: "PRORRATEO",
    },
    prorrationReportTab: {
      tabs: {
        percentageTransfer: "PORCENTAJE DE TRASLADO",
        notTransferredExpenses: "GASTOS NO TRASLADADOS",
        transferredPerIterationExpenses: "GASTOS TRASLADADOS POR ITERACIÓN",
      },
    },
    transformDataForDifTable: {
      columns: {
        period: "periodo",
        bucketExpense: "bucket-gasto",
        grouper: "agrupador",
        mount: "monto",
        result: "resultado",
        difference: "diferencia",
        transferred: "trasladado",
      },
    },
    transformDataForErrorsTable: {
      destination1: "DESTINO_1",
      destination: "DESTINO",
      expenseMount: "MONTO_GASTO",
      coin: "moneda",
    },
    getColumnsForErrorsTable: {
      columns: {
        bucket: "BUCKET",
        destination: "DESTINO",
        stage: "ETAPA",
        period: "PERIODO",
      },
    },
    transformDataForIterationTable: {
      columns: {
        solved: "SOLUCIONADO",
        expenseMount: "MONTO_GASTO",
        coin: "moneda",
      },
    },
    getColumnsForIterationTable: {
      columns: {
        mount: "MONTO",
        grouper: "AGRUPADOR",
        channel: "CANAL",
        period: "PERIODO",
        stage: "ETAPA",
        bucket: "BUCKET",
        iteration: "ITERACIÓN",
      },
    },
    calculationReport: {
      tabs: {
        mount: "MONTO",
        graphics: "GRÁFICOS",
        approrateReport: "REPORTE DE PRORRATEO",
        preProrateReport: "REPORTE DE PREPRORRATEO",
      },
      calculationReport: "Reporte de cálculo",
    },
    kpi: {
      coin: "moneda",
      units: "unidades",
      totalInExpenses: "Total en gastos",
      totalInResults: "Total en resultados",
      totalTransferred: "Total trasladado",
      bucketPeriodsError: "Bucket-períodos con error",
    },
    kpiError: {
      incorrectCalculation: "Cálculo incorrecto",
    },
    periodChart: {
      periods: "Períodos",
      percentageExchange: "% de cambio",
      percentage: "porcentaje",
    },
  },
  schemaManagementTexts: {
    schemaCard: {
      receivers: "Receptores",
      filters: "Filtros",
      validations: "Validaciones",
      inUseBuckets: "En uso, asociado a los buckets",
      inUseBucket: "En uso, asociado al bucket",
      notUsed: "No usado",
    },
    schemaManagement: {
      lookingSchema: "Buscando esquemas...",
      deletingSchema: "Eliminando esquema...",
      schemaManagement: "Administración de esquemas",
      sortAlphabet: "ORDENAR POR ALFABETO",
      sortDate: "ORDENAR POR FECHA",
      filter: "Filtrar",
      searchBySchemaBucket: "Búsqueda por nombre de esquema o bucket",
      noSchemaAvailable: "Aún no hay esquemas disponibles.",
      notGetSchema:
        "No fue posible obtener los esquemas. Vuelva a intentarlo más tarde.",
      deleteSchema: "eliminar el esquema",
      noActiveSchema: "Esquemas Inactivos",
      activeSchema: "Esquemas Activos",
      noFilters: "Sin filtros",
      bucketsWhereItsUsed: "Buckets donde se utiliza",
    },
    viewInfoModal: {
      schemaInfo: "Información general de esquema",
      schemaBase: "Base para esquema:",
      schemaColumn: "Columna de esquema:",
      aplicatedFunction: "Función aplicada:",
      convertNegativesToPositives: " Convertir valores negativos en positivos:",
      convertNegativesToZero: "Transformar valores negativos a cero:",
      columnsToValidate: "Columna a validar únicos:",
      yes: "Sí",
      no: "No",
      columnsToMultiply: "Columnas a multiplicar",
      filterInFile: "Filtros en archivo",
      expensesCrossover: "Cruce de valores de GASTOS con",
      filterBy: "Filtrado de ",
      withInfo: "con información de",
      previousFilters: "Filtros previos en archivo",
      crossoverValues: "Cruce de valores de",
      with: "con",
      previousDestinitiesValidations: "Validación de destinos anteriores",
      differentiatedTreatment: "Tratamiento diferenciado",
      noDifferentiatedTreatment: "Sin tratamiento diferenciado",
    },
  },
  simulator: {
    postRule: {
      loading: "Creando regla...",
      codes: {
        200: "La regla se ha creado con éxito",
        201: "La regla se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear la regla",
      },
    },
    putRule: {
      codes: {
        200: "La regla se ha modificado con éxito",
        201: "La regla se ha modificado con éxito",
        400: "Se produjo un error y no fue posible modificar la regla",
      },
    },
    getRules: {
      error: "Se produjo un error y no fue posible obtener las reglas",
      codes: {
        200: "Se obtuvieron las reglas",
        400: "Se produjo un error y no fue posible obtener las reglas",
        404: "Se produjo un error y no fue posible obtener las reglas",
      },
    },
    deleteRules: {
      error: "Se produjo un error y no fue posible eliminar la regla",
      codes: {
        200: "Se eliminó la regla",
        400: "Se produjo un error y no fue posible eliminar la regla",
        404: "Se produjo un error y no fue posible eliminar la regla",
      },
    },
    getSchemas: {
      error: "Se produjo un error y no fue posible obtener los esquemas",
      codes: {
        200: "Se obtuvieron los esquemas",
        400: "Se produjo un error y no fue posible obtener los esquemas",
        404: "Se produjo un error y no fue posible obtener los esquemas",
      },
    },
    postSchema: {
      loading: "Creando esquema...",
      codes: {
        200: "El esquema se ha creado con éxito",
        201: "El esquema se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear el esquema",
      },
    },
    putSchema: {
      codes: {
        200: "El esquema se ha modificado con éxito",
        201: "El esquema se ha modificado con éxito",
        400: "Se produjo un error y no fue posible modificar el esquema",
      },
    },
    deleteSchema: {
      error: "Se produjo un error y no fue posible eliminar el esquema",
      codes: {
        200: "Se eliminó  el esquema",
        400: "Se produjo un error y no fue posible eliminar  el esquema",
        404: "Se produjo un error y no fue posible eliminar  el esquema",
      },
    },
    postRoute: {
      loading: "Creando ruta...",
      codes: {
        200: "La ruta se ha creado con éxito",
        201: "La ruta se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear la ruta",
      },
    },
    putRoute: {
      codes: {
        200: "La ruta se ha modificado con éxito",
        201: "La ruta se ha modificado con éxito",
        400: "Se produjo un error y no fue posible modificar la ruta",
      },
    },
    getRoutes: {
      error: "Se produjo un error y no fue posible obtener las rutas",
      codes: {
        200: "Se obtuvieron las rutas",
        400: "Se produjo un error y no fue posible obtener las rutas",
        404: "Se produjo un error y no fue posible obtener las rutas",
      },
    },
    deleteRoutes: {
      error: "Se produjo un error y no fue posible eliminar la configuración",
      codes: {
        200: "Se eliminó  la configuración",
        400: "Se produjo un error y no fue posible eliminar  la configuración",
        404: "Se produjo un error y no fue posible eliminar  la configuración",
      },
    },
    getAnalysisValues: {
      error: "Se produjo un error y no fue posible obtener los parámetros",
      codes: {
        200: "Se obtuvieron los parámetros",
        400: "Se produjo un error y no fue posible obtener los parámetros",
        404: "Se produjo un error y no fue posible obtener los parámetros",
      },
    },
    getSimulationsResults: {
      error: "Se produjo un error y no fue posible obtener las simulaciones",
      codes: {
        200: "Se obtuvieron las simulaciones",
        400: "Se produjo un error y no fue posible obtener las simulaciones",
        404: "Se produjo un error y no fue posible obtener las simulaciones",
      },
    },
    getSimulationResult: {
      error: "Se produjo un error y no fue posible obtener la simulación",
      codes: {
        200: "Se obtuvo la simulación",
        400: "Se produjo un error y no fue posible obtener la simulación",
        404: "Se produjo un error y no fue posible obtener la simulación",
      },
    },
  },
  simulatorTexts: {
    simulations: {
      simulationAdmin: {
        simulationAdmin: "Administración de Simulaciones",
        noSimulationsYet: "Aún no hay simulaciones disponibles.",
        step1SimulationConfig: {
          simulationName: "Nombre de Simulación",
        },
      },
      simulationConfig: {
        simulationConfig: "Configuración de Simulaciones",
      },
    },
    salesForecast: {
      newSalesForecastModal: {
        create: "Crear",
        salesForecast: "Sales Forecast",
        cannotRepitAnalysis: "No se puede repetir un análisis.",
      },
    },
    simulationsRulesAdmin: {
      simulationAdmin: "Reglas de Simulación",
      loading: "Cargando...",
      deletingRule: "Eliminando regla...",
      searchByName: "Búsqueda por nombre",
      noSimulationsRulesYet: "Aún no hay reglas disponibles.",
      deleteSimulationRule: "eliminar la regla de simulación",
      confirm: "Confirmar",
      cannotDeleteRuleAsBeingUsed:
        "No se puede eliminar esta regla, ya que, está siendo utilizada.",
      cannotDeleteSchemaAsBeingUsed:
        "No se puede eliminar este esquema, ya que, está siendo utilizado.",
      deleteAssociatedSimulation:
        "Se eliminarán todas las simulaciones asociadas.",
    },
    configSimulationRule: {
      simulationRuleConfig: "Configuración de Regla de simulación",
      loading: "Cargando...",
      simulationName: "Nombre de la simulación",
      ruleName: "Nombre de la regla",
      alreadyExisteRuleName: "Ya existe una regla con ese nombre",
      analysisToUse: "Análisis a usar",
      chooseAnalysis: "Elegir Análisis",
      noValuesCalculated:
        "No se han calculado los valores necesarios para crear la regla de simulación. Verifica los datos y vuelve a intentarlo.",
      costVariability: "Variabilidad de Costos",
      assignMultiple: "Asignar a varios",
      dependentVariable: "Driver de costo...",
      cancel: "Cancelar",
      edit: "Editar",
      save: "Guardar",
      editSimulationRule: "Editar regla",
      createSimulationRule: "crear un nueva regla de simuación",
      newSimulationRule: "Nueva regla",
      compundsMeasures: "MEDIDAS COMPUESTAS",
      salesMeasures: "MEDIDAS VENTAS",
      default: "POR DEFECTO",
      quantityRoutes: "CANTIDAD_RUTAS",
      selectAll: "TODO",
      selectAllWithOutDriver: "SIN DRIVER",
      selectAllWithOutPL: "SIN P&L",
      editRuleWillDeleteSimulationsPeriods:
        "Las periodos de las simulaciones asociadas se volverán a ejecutar",
    },
    simulationRuleCard: {
      analysisToUse: "Análisis a Usar:",
    },
    viewInfoRuleModal: {
      infoSimulationRule: "Información de Regla de simulación",
      rule: "Regla",
      analysisToUse: "Análisis a Usar:",
      columns: {
        measure: "MEDIDA",
        variability: "VARIABILIDAD",
        costDriver: "DRIVER DE COSTO",
        plLine: "LÍNEA P&L",
      },
    },
    inputSlider: {
      fixed: "fijo",
      variable: "variable",
    },
    assignVariabilityModal: {
      assignVariability: "Asignar variabilidad",
      cancel: "Cancelar",
      accept: "Aceptar",
      dependentVariable: "Driver de costo...",
      plLine: "Asignar a línea P&L...",
    },
    registerRoutes: {
      configRegisterRoute: {
        registeredRoute: "ALTA DE RUTA",
        canceledRoute: "BAJA DE RUTA",
        modifiedRoute: "MODIFICACIÓN DE RUTA",
        registered: "ALTA",
        canceled: "BAJA",
        modification: "MODIFICACIÓN",
        registeredRouteConfig: "Configuración de Ruta",
        loading: "Cargando...",
        configName: "Nombre de la configuración",
        routeNameAlreadyExists: "Ya existe una configuración con ese nombre",
        simulationRule: "Regla de simulación",
        chooseSimulationRule: "Elegir regla de simulación...",
        simulationSchema: "Esquema de simulación",
        chooseSimulationSchema: "Elegir esquema de simulación...",
        simulationSchemaCannotChange:
          "Una vez agregada una configuración, no se podrá cambiar el esquema de simulación.",
        newRegisteredRoute: "Alta de ruta",
        modifyAclaration:
          "Para modificar, selecciona solo una configuración de ruta a la vez.",
        modify: "Modificar",
        delete: "Eliminar",
        noRegistrationConfigYet: "Aún no hay configuraciones de Alta de Ruta",
        noCancelationConfigYet: "Aún no hay configuraciones de Baja de Ruta",
        noModificationConfigYet:
          "Aún no hay configuraciones de Modificación de Ruta",
        cancel: "Cancelar",
        edit: "Editar",
        save: "Guardar",
        editConfiguration: "editar la configuración",
        saveConfiguration: "guardar la configuración",
        newRegisterRoute: "Configuración de ruta",
        deleteRegisterRouteConfiguration: {
          title: "Eliminar configuración",
          message: "eliminar la configuración",
        },
        columnGroupingModel: {
          balance: "Balance",
          currentRoutes: "Rutas Actuales",
          registeredRoutes: "Altas de Rutas",
          newRoutes: "Rutas Totales",
          simulationParams: "Parámetros de Simulación",
          volumeProjectioMode: "Modalidad de Proyección de Volumen",
          averageMonthlyVolumePerRoute: "Volumen Mensual Promedio por Ruta",
          averageMonthlyVolumePerRouteActual: "VMPxR: Actual",
          averageMonthlyVolumePerRouteAdjustment: "VMPxR: Ajuste",
          averageMonthlyVolumePerClient: "Volumen mensual promedio por Cliente",
          averageMonthlyVolumePerClientActual: "VMPxC: Actual",
          averageMonthlyVolumePerClientAdjustment: "VMPxC: Ajuste",
          averagePrice: "Precio Promedio",
          clientePerRoute: "Cliente por Ruta",
          clientePerRouteActual: "CxR: Actual",
          clientePerRouteAdjustment: "CxR: Ajuste",
        },
      },
      inputAdjustmentRate: {
        adjustmentRate: "Tasa de Ajuste",
        adjustedValue: "Valor Ajustado",
      },
      newRouteModal: {
        noRoutesSelectedFiltered:
          "No existen rutas para los filtros seleccionados",
        steps: {
          paramSelection: "Selección de filtros",
          settings: "Ajustes",
          routeResults: "Resultado de rutas",
          routesSelection: "Selección de rutas",
          cancelRoutes: "Rutas a dar de baja",
          routesToModify: "Rutas a modificar",
        },
        rows: {
          volumeProjectionMode: "Modalidad de Proyección de Volumen",
          averageMonthlyVolumePerRouteActual: "VMPxR: Actual",
          averageMonthlyVolumePerRouteAdjustment: "VMPxR: Ajuste",
          volumePerOutletActual: "VMPxC: Actual",
          volumePerOuletAdjustment: "VMPxC: Ajuste",
          averagePrice: "Precio Promedio",
          customerPerRouteActual: "CxR: Actual",
          customerPerRouteAdjustment: "CxR: Ajuste",
          actualRoutes: "Rutas Actuales",
          registerRoutes: "Altas de Rutas",
          newRoutes: "Rutas Totales",
        },
        goBack: "Atrás",
        finish: "Finalizar",
        continue: "Continuar",
        closeModalWithInfo:
          "cerrar la configuración de ruta, aunque exista información importante",
        routeRegistration: "Alta de Ruta",
        loading: "Cargando...",
      },
      registerRouteCard: {
        schema: "Esquema",
      },
      registerRoutesAdmin: {
        searchByName: "Búsqueda por nombre",
        registerRoute: "Administración de Rutas",
        loading: "Cargando...",
        notRegisteredRouteYet:
          "Aún no hay configuraciones de rutas disponibles",
        deleteRegisterRoute: "eliminar la configuración de ruta",
        confirm: "Confirmar",
      },
      step1NewRouteModal: {
        paramSelection: "SELECCIÓN DE FILTROS",
        column: "Columna",
        selectColumn: "Elegir columna...",
        values: "Valores",
        chooseValue: "Elegir valor...",
        noFilterYet: "Aún no agregaste ningún filtro adicional.",
      },
      step2NewRouteModal: {
        volumenProjectionModeOptions: {
          averageMonthlyVolumePerClient: "Volumen mensual promedio por Cliente",
          totalRoute: "Total Ruta",
        },
        adjustments: "AJUSTES",
        volumeProjectionMode: "Modalidad de Proyección de Volumen",
        chooseProjectionMode: "Elegir modelo...",
        totalAverageMonthlyVolumePerRoute:
          "Volumen mensual promedio Total por Ruta",
        averageMonthlyVolumePerClient: "Volumen mensual promedio por Cliente",
        clientsPerRoute: "Clientes por Ruta",
        averagePrice: "Precio Promedio",
      },
      step3NewRouteModal: {
        totalRoutesResult: "RESULTADO TOTAL RUTAS",
        actualRoutes: "Rutas actuales",
        routesToAdd: "Rutas a agregar",
        newRoutes: "Rutas totales",
        cancelRoutes: "RUTAS A DAR DE BAJA",
      },
      viewInfoRegisterRouteModal: {
        registerRouteInfo: "Información de la Configuración de Ruta",
        loading: "Cargando...",
        registerRouteName: "Nombre de la Configuración de ruta",
        simulationSchema: "Esquema de simulación",
        numbersNewRoutes: "Cantidad rutas nuevas",
        volumeProjectionMode: "Modalidad de Proyección de Volumen",
        requiredFilters: "Filtros Requeridos",
        aditionalFilters: "Filtros Adicionales",
        params: "Parámetros",
        actualValue: "Valor actual",
        adjustmentValue: "Valor ajustado",
      },
      getColumnsRegisteredRoutes: {
        projectionMode: "MODALIDAD DE PROYECCIÓN",
        routeRegistrations: "ALTA DE RUTA",
        customersByCurrentRoute: "CLIENTES POR RUTA ACTUAL",
        customersByAdjustedRoute: "CLIENTES POR RUTA AJUSTADO",
        averagePrice: "PRECIO PROMEDIO",
        averageMonthlyVolumePerCurrentRoute:
          "VOLUMEN MENSUAL PROMEDIO TOTAL POR RUTA ACTUAL",
        averageMonthlyVolumePerAdjustedRoute:
          "VOLUMEN MENSUAL PROMEDIO TOTAL POR RUTA AJUSTADO",
        currentAverageVolumePerClient:
          "VOLUMEN MENSUAL PROMEDIO POR CLIENTE ACTUAL",
        adjustedAverageVolumePerClient:
          "VOLUMEN MENSUAL PROMEDIO POR CLIENTE AJUSTADO",
      },
    },
    canceledRoutes: {
      editingOptions: "OPCIONES DE EDICIÓN",
      assignRetentionsModal: {
        assign: "Asignar",
        salesRetentionPercentage: "PORCENTAJE DE RETENCIÓN DE VENTAS",
      },
      modifyCancelRouteModal: {
        modifyRetentions: "Modificar retenciones",
        modify: "modificar",
        assignToVarios: "ASIGNAR A VARIOS",
        retentionPercentageInfo:
          "El porcentaje de retención de ventas se distribuirá en las rutas activas",
      },
      newCancelRouteModal: {
        cancelRoute: "Baja de Ruta",
        loading: "Cargando...",
      },
      step1NewCancelRouteModal: {
        chooseReferenColumn: "Elegir columna de referencia",
        chosenCedis:
          "Una vez seleccionada una columna de referencia, ésta se aplicará en todas las demás configuraciones.",
      },
      step2NewCancelRouteModal: {
        routesSelection: "SELECCIÓN DE RUTAS",
      },
      step3NewCancelRouteModal: {
        routesToCanceled: "RUTAS A DAR DE BAJA",
      },
      step3ModelTable: {
        clients: "CLIENTES",
        salesRetention: "% DE RETENCIÓN DE VENTAS",
      },
    },
    modifiedRoutes: {
      modifiedRoute: "Modificación de Ruta",
      step3NewModifyRouteModal: {
        noValuesYet: "Aún no asignaste ningún valor.",
        valueSelectedInfo:
          "Después de seleccionar el o los valores, se mostrarán los promedios de las rutas basados en ese o esos valores seleccionados.",
      },
      modifyModificationRouteModal: {
        modifyRoutes: "Modificar rutas",
      },
    },
    simulationRules: {
      nonSimulationsYet: "Aún no hay resultados disponibles.",
      simulationResults: "Resultados de Simulación",
    },
    simulationResultById: {
      previousMonth: "VS. MES ANTERIOR",
      noChangesSimulation: "VS. SIMULADO SIN CAMBIOS",
      graphics: "GRÁFICOS",
      cleanFilters: "Limpiar filtros",
      noFiltersYet: "No hay filtros",
      loading: "Cargando...",
      simulationResultCardById: {
        before: "ANTES",
        after: "DESPUÉS",
        variation: "VAR",
      },
      filterModal: {
        simulationResultFilter: "Filtros para el resultado de la simulación",
        cancel: "Cancelar",
        apply: "Aplicar",
      },
      waterfallSimulationResult: {
        titleText: "Impacto por componente del P&L",
      },
      columns: {
        measure: "MEDIDA",
        previousMonth: "MES ANTERIOR",
        simulatedMonthwithoutchanges: "MES SIMULADO SIN CAMBIOS",
        simulatedMonth: "MES ESCENARIO SIMULADO",
        percentageVariation: "VARIACIÓN PORCENTUAL",
        absoluteVariation: "VARIACIÓN ABSOLUTA",
      },
      simulatorLineChart: {
        grossSalesChartTitle: "Análisis de Ventas Brutas en Múltiples Rutas",
        costToServeChartTitle:
          "Análisis del Costo de servicio en Múltiples Rutas",
        profitToServeChartTitle:
          "Análisis de Beneficios para servir en Múltiples Rutas",
      },
      results: "Resultados",
      simulationResultInfoModal: {
        routeConfiguration: "CONFIGURACIÓN DE RUTAS",
        schema: "ESQUEMA",
        type: "TIPO",
        costVariability: "VARIABILIDAD DE COSTOS",
        ruleSimulation: "REGLA DE SIMULACIÓN",
      },
    },
    simulationSchema: {
      viewInfoSchemaModal: {
        schemaSimulation: "Esquema de Simulación",
        loading: "Cargando...",
        name: "NOMBRE",
        rule: "REGLA",
        principalColumns: "COLUMNAS PRINCIPALES",
        defaultIndicators: "INDICADORES POR DEFECTO",
        cards: "FORMATO TARJETA",
        personalizedIndicators: "INDICADORES PERSONALIZADOS",
        variability: "FORMATO TABLA",
      },
      simulationSchemaConfig: {
        steps: {
          principalColumns: "Columnas Principales",
          indicators: "Indicadores en Formato Tarteja",
          kpis: "Indicadores en Formato Tabla",
        },
        schemaConfig: "Configuración de Esquema",
        loading: "Cargando...",
        saving: "Guardando...",
        cancel: "Cancelar",
      },
      simulationSchemaAdmin: {
        schemaAdmin: "Esquemas de Simulación",
        deletingSchema: "Eliminando esquema...",
        noSchemasYet: "Aún no hay esquemas disponibles.",
        deleteSchema: "eliminar el esquema de simulación",
      },
      step3NewSimulationSchema: {
        defaultKpis: "KPIs por defecto",
        personalizedKpis: "KPIs Personalizados",
        noPersonalizedKpisYet: "Aún no agregaste ningún KPI Personalizado.",
        kpiTableExample: "Ejemplo tabla KPIs",
        newKpi: "Nuevo Kpi",
      },
      step2NewSimulationSchema: {
        defaultIndicators: "Indicadores por Defecto",
        indicatorExample: "Ejemplo indicador",
        personalizedIndicators: "Indicadores Personalizados",
        noPersonalizedIndicators:
          "Aún no agregaste ningún Indicador Personalizado.",
        newIndicator: "Nuevo indicador",
      },
      step1NewSimulationSchema: {
        chooseReferenceColumn: "Elegir columna de referencia...",
        schemaName: "Nombre del esquema",
        schemaNameAlreadyExists: "Ya existe un esquema con ese nombre",
        principalColumns: "Columnas Principales",
        dependency: "Dependencia",
      },
      personalizedIndicatorInfoModal: {
        close: "Cerrar",
        operator: "Operador",
        function: "Función",
        denominators: "Denominadores",
      },
      newPersonalizedKpiModal: {
        newKpi: "Nuevo Kpi",
        add: "Agregar",
        kpiName: "Nombre del Kpi",
        kpiNameAlreadyExists: "Ya existe un kpi con este nombre",
        numerator: "Numeradores",
        chooseMeasures: "Elegir medidas...",
        denominators: "Denominadores",
        chooseDenominators: "Elegir denominadores...",
        denominatorsRepresentation:
          "Las denominadores representan las columnas que seleccionaste en el paso 1.",
        measureUnit: "Unidad de medida",
        chooseMeasureUnit: "Elegir unidad de medida...",
        newDenominator: "Nuevo Denominador",
      },
      newPersonalizedIndicatorModal: {
        measurement_units: {
          coin: "MONEDA",
          kilograms: "KILOGRAMOS",
          units: "UNIDADES",
        },
        newIndicator: "Nuevo indicador",
        add: "Agregar",
        indicatorName: "Nombre del indicador",
        indicatorNameAlreadyExists: "Ya existe un indicador con este nombre",
        unitOfMeasurement: "Unidad de medida",
        chooseUnitOfMeasurement: "Elegir unidad de medida...",
      },
      newDenominatorModal: {
        newDenominator: "Nuevo denominador",
        add: "Agregar",
        denominatorName: "Nombre del denominador",
        denominatorNameAlreadyExists:
          "Ya existe un denominador con este nombre",
        denominatorNameMatchesWithInformation:
          "El nombre no puede coincidir con una columna principal",
        measures: "Medidas",
        chooseMeasures: "Elegir medidas...",
      },
    },
    routeAdministration: {
      getColumnsCanceledRoutes: {
        route: "RUTAS",
        retentionSales: "% DE RETENCIÓN DE VENTAS",
      },
    },
  },
  stagesSettings: {
    withoutStages: "Hay agrupadores sin etapas asignadas.",
    noFiles: "No hay archivos cargados.",
    editName: {
      codes: {
        200: "Se ha editado el nombre correctamente",
        400: "Ha ocurrido un error al editar el nombre",
      },
    },
  },
  tablesManagement: {
    addTable: {
      codes: {
        201: "La tabla se ha creado con éxito",
        400: "Se produjo un error y no fue posible crear la tabla",
      },
    },
    editTable: {
      codes: {
        200: "La tabla se ha actualizado con éxito",
        400: "Se produjo un error y no fue posible editar la tabla",
      },
    },
    deleteTable: {
      codes: {
        200: "La tabla se ha eliminado con éxito",
        400: "Se produjo un error y no fue posible eliminar la tabla",
      },
    },
    putState: {
      codes: {
        200: "El estado se ha modificado con éxito",
        201: "El estado se ha modificado con éxito",
        400: "Se produjo un error y no fue posible modificar el estado",
      },
    },
    getGroup: {
      error: "Se produjo un error y no fue posible obtener los grupos",
    },
    getTablesError: "Se produjo un error y no fue posible obtener las tablas",
  },
  tablesTools: {
    errorRows: "Ocurrió un error y no fue posible cargar los datos.",
    noRows: "No hay datos.",
    transformDataSimpleTable: {
      message: "Mensaje",
    },
  },
  tabs: {
    preproration: "PRE_PRORRATEO",
    proration: "PRORRATEO",
    downloads: "DESCARGAS",
    datamodel: "DATAMODEL",
    simulation: "SIMULACIÓN",
    salesForecast: "SALES FORECAST",
    costsForecast: "COSTS FORECAST",
    marketBasket: "MARKET BASKET",
    prepro: "PREPRORRATEO",
  },
  taskManager: {
    loadingCancelTask: "Cancelando tarea...",
    loadingDownloadFile: "Descargando...",
    error: "Se produjo un error y no fue posible descargar la información.",
    errorDetail: "Detalle de errores",
    ejecutionDetail: "Detalle de ejecución",
    noDetailYet: "No hay información disponible.",
    cancelTitle: "Cancelar Tarea",
    cancelThisTask: "Cancelar esta tarea",
    saveViewButtonTooltip:
      "Guarda la configuración de ancho y visibilidad de columnas para este usuario.",
    getTasks: {
      error: "Se produjo un error y no fue posible obtener las tareas",
    },
    getDownloads: {
      error: "Se produjo un error y no fue posible obtener las descargas",
    },
    getSimulations: {
      error: "Se produjo un error y no fue posible obtener las simulaciones",
    },
    getSalesForecast: {
      error: "Se produjo un error y no fue posible obtener los sales forecast",
    },
    getCostsForecast: {
      error: "Se produjo un error y no fue posible obtener los costs forecast",
    },
    cancelTask: {
      codes: {
        200: "La tarea se ha cancelado con éxito",
        201: "La tarea se ha cancelado con éxito",
        204: "La tarea se ha cancelado con éxito",
        400: "Se produjo un error y no fue posible cancelar la tarea",
        500: "Se produjo un error y no fue posible cancelar la tarea",
      },
    },
    getTaskDetails: {
      codes: {
        200: "Se obtuvieron los detalles de la tarea",
        400: "Se produjo un error y no fue posible obtener los detalles de la tarea",
      },
      loading: "Obteniendo detalles de la tarea...",
    },
    states: {
      pending: "Pendiente",
      running: "Corriendo",
      finishedWithErrors: "Finalizada con errores",
      finishedSuccessfully: "Finalizada con éxito",
      error: "Error al calcular",
      downloadError: "Error al descargar",
      canceled: "Cancelada",
    },
    actions: {
      viewDetail: "Ver detalle",
      notViewYet: "Aún no hay detalle",
      cancel: "Cancelar",
      download: "Descargar",
      edit: "Editar",
      delete: "Eliminar",
    },
  },
  titles: {
    taskManager: "Gestor de Tareas",
    matchesDashboard: "Tablero de cruces de valores",
  },
  validations: {
    validationsAdmin: {
      title: "Administración de Validaciones",
      tabs: {
        codeMatching: "CRUCE DE VALORES",
      },
      notCodeMatchingYet: "Aún no hay cruce de valores disponibles.",
      getCodeMatching: {
        error:
          "Se produjo un error y no fue posible obtener el cruce de valores",
        loading: "Obteniendo los cruces de valores...",
      },
      codeMatchingCard: {
        file1: "Archivo 1:",
        file2: "Archivo 2:",
        matchIndicator: "Indicador:",
        completeMatchIndicator: "Indicador de cruce:",
      },
      viewInfoModal: {
        title: "Información general de la validación",
      },
      deleteValidation: {
        codes: {
          200: "La validación se ha eliminado con éxito",
          400: "Se produjo un error y no fue posible eliminar la validación",
        },
        loading: "Eliminando validación...",
        confirmationModal: {
          title: "Eliminar validación",
          message: "¿Confirma que desea eliminar la validación?",
        },
      },
    },
    configValidations: {
      subtitleColumns: "Columnas",
      titleIndicatorMatchSelect: "Indicador de cruce",
      matchName: "Nombre de cruce de valores",
      errorEmptyName: "El nombre no puede estar vacío",
      errorDuplicatedName: "Ya existe una validación con ese nombre",
      textIndicatorMatchSelect: {
        percentaje: "% de códigos de",
        in: "en",
      },
      placeholderIndicatorMatchSelect: "Elegir indicador de cruce...",
      codes: {
        200: "Se ha guardado la validación con éxito.",
        201: "Se ha guardado la validación con éxito.",
        400: "Se produjo un error y no fue posible guardar la validación.",
      },
      calculate: {
        codes: {
          200: "Se ha iniciado el cálculo con éxito.",
          201: "Se ha iniciado el cálculo con éxito.",
          400: "Se produjo un error y no fue posible iniciar el cálculo.",
        },
      },
      loading: "Guardando validación...",
      calculating: "Calculando...",
      confirmationModal: {
        title: "Guardar validación",
        message: "¿Confirma que desea guardar la validación?",
      },
      notEqualColumnsLength:
        "La cantidad de columnas seleccionadas debe ser igual para ambos archivos.",
      getValidation: {
        codes: {
          200: "Se obtuvo la validación con éxito",
          400: "Se produjo un error y no fue posible obtener la validación",
        },
        loading: "Obteniendo validación...",
      },
    },
    matchCodeResults: {
      getValidations: {
        error: "Se produjo un error y no fue posible obtener las validaciones",
      },
      getVennModalInfo: {
        codes: {
          200: "Se obtuvo la información del resultado con éxito",
          400: "Se produjo un error y no fue posible obtener la información del resultado",
        },
        loading: "Obteniendo información del resultado...",
      },
    },
  },
  verificationCode: {
    codes: {
      200: "El código es correcto",
      400: "El código es incorrecto",
      500: "Hubo un error y no fue posible enviar el código, intentelo nuevamente",
    },
    loading: "Enviando el código de autenticación...",
    resend: {
      codes: {
        200: "El código se ha reenviado con éxito",
        400: "El código no se ha podido reenviar",
        500: "Hubo un error y no fue posible reenviar el código, intentelo nuevamente",
      },
    },
  },
  virtualizedTable: {
    saveViewButton: "Guardar vista",
    transformData: {
      appliedSchema: "ESQUEMA_APLICADO",
      totalExpense: "GASTO_TOTAL",
    },
    toolbars: {
      columns: "Columnas",
      selectColumns: "Seleccionar columnas",
      findColumn: "Buscar columna",
      columnTitle: "Título de la columna",
      reorderColum: "Reordenar columna",
      columnsPanelShowAllButton: "Mostrar todo",
      columnsPanelHideAllButton: "Ocultar todo",
      filters: "filtros",
      filterPlaceHolder: "Filtrar valores",
      addFilter: "Añadir filtro",
      filterHide: "Ocultar filtros",
      filterShow: "Mostrar filtros",
      filterOperator: "Operador",
      andFilter: "Y",
      orFilter: "O",
      filterInputLabel: "Valor",
      density: "densidad",
      compact: "compacto",
      standard: "estándar",
      comfortable: "cómodo",
      export: "exportar",
      print: "Imprimir",
      asCSV: "Descargar como CSV",
    },
  },
  unauthorized: {
    title: "No autorizado",
    message: "No tiene permisos para acceder a esta página",
    goBack: "Volver al inicio",
  },
  useLogout: {
    errorLogout: "Error al cerrar sesión",
  },
};
