import { convertArrayToText } from "../../taskManager/utils";
import { IdOption, Filter } from "./types";

export const handleDeleteById = (
  id: string | number,
  data: any,
  setData: any
) => {
  setData(data.filter((item: Filter) => item.id !== id));
};

//Obtiene valores no repetidos de un array y elimina los valores vacíos
export const getUniqueValues = (array: any[]) => {
  return array
    .filter((item, index) => array.indexOf(item) === index)
    .filter((item) => item !== "");
};

//Chequea si el valor de column ya existe en el array de filters
export const isDuplicatedColumn = (
  column: IdOption["value"],
  array: any
): boolean => {
  let duplicatedColumns = [];
  if (array) {
    duplicatedColumns = array.filter((filter: any) => {
      return filter.column.value === column;
    }).length;
  }
  return duplicatedColumns.length > 0;
};

export const isDuplicatedUnion = (
  array: any,
  columnSelectedOptions: IdOption,
  expensesColumnSelectedOptions: IdOption
): boolean => {
  let duplicatedUnions = [];
  if (array) {
    duplicatedUnions = array.filter((union: any) => {
      return (
        union.column.value === columnSelectedOptions?.value &&
        union.expensesColumn.value === expensesColumnSelectedOptions.value
      );
    }).length;
  }
  return duplicatedUnions.length > 0;
};

export const isDuplicatedExternalFilter = (
  array: any,
  externalColumnSelectedOptions: IdOption,
  columnSelectedOptions: IdOption
): boolean => {
  let duplicatedFilters = [];
  if (array) {
    duplicatedFilters = array.filter((filter: any) => {
      return (
        filter.externalColumn.value === externalColumnSelectedOptions?.value &&
        filter.columnFile.value === columnSelectedOptions?.value
      );
    }).length;
  }
  return duplicatedFilters.length > 0;
};

export const parseFilterId = (
  columnFilterSelectedOptions: string,
  filterTypeSelectedOptions: string,
  valuesSelectedOptions: any,
  valueFilter: string | number | undefined
): string => {
  const value =
    valuesSelectedOptions && valuesSelectedOptions?.length > 0
      ? convertArrayToText(
          valuesSelectedOptions.map((value: any) => `${value.label}`)
        )
      : valueFilter;
  return columnFilterSelectedOptions + filterTypeSelectedOptions + value;
};
