import { AddButton } from "../../buttons";
import { Box, Typography } from "@mui/material";
import { columnOptions, expenseColumnOptions } from "./selectOptions";
import { CustomSelect as Select } from "../../forms";
import { handleDeleteById, isDuplicatedUnion } from "./utils";
import { optionalSelectStyles } from "./select.styles";
import { Step3Props } from "./types";
import { useState, useRef } from "react";
import data from "./data.json";
import DeleteForever from "../../icons/DeleteForever";
import FormLayout from "../../forms/FormLayout";
import FormSelectLayout from "../../forms/FormSelectLayout";
import UnionRow from "./UnionRow";
import useLanguage from "../../../context/LanguageProvider";

const Step3 = ({
  fileColumnSelectOptions,
  fileSelectedOptions,
  expensesColumnSelectOptions,
  columnValidationSelectedOptions,
  setColumnValidationSelectedOptions,
  columnValidationOptionsChangeHandler,
  expensesColumnSelectedOptions,
  setExpensesColumnSelectedOptions,
  expensesColumnOptionsChangeHandler,
  unions,
  setUnions,
}: Step3Props) => {
  const columnSelectRef: any = useRef(null);
  const expensesColumnSelectRef: any = useRef(null);
  const [unionsError, setUnionsError] = useState<string>("");

  const { t } = useLanguage();

  const handleAddUnion = () => {
    setUnionsError("");
    if (!columnValidationSelectedOptions || !expensesColumnSelectedOptions) {
      setUnionsError(data.filterMessages.emptyError);
      return;
    }
    const newUnions: any[] = [];
    if (
      !isDuplicatedUnion(
        unions,
        columnValidationSelectedOptions,
        expensesColumnSelectedOptions
      )
    ) {
      const union = {
        id: `${columnValidationSelectedOptions?.value}-${expensesColumnSelectedOptions?.value}`,
        expensesColumn: expensesColumnSelectedOptions,
        column: columnValidationSelectedOptions,
      };
      newUnions.push(union);
      unions ? setUnions([...unions, ...newUnions]) : setUnions(newUnions);
      setColumnValidationSelectedOptions(undefined);
      setExpensesColumnSelectedOptions(undefined);
      columnSelectRef.current.clearValue();
      expensesColumnSelectRef.current.clearValue();
    } else {
      setUnionsError(t("configCriteria.configCriteriaComponent.filterMessages.duplicatedError"));
    }
  };

  const handleDeleteAllFilters = () => {
    setUnions([]);
    setUnionsError("");
    setColumnValidationSelectedOptions(undefined);
    setExpensesColumnSelectedOptions(undefined);
    columnSelectRef.current.clearValue();
    expensesColumnSelectRef.current.clearValue();
  };

  return (
    <FormLayout width="60%">
      <div className="cc_filters_container">
        {unionsError && (
          <div className="cc_info_filters_box">
            <p className="cc_info_filters_error">{unionsError}</p>
          </div>
        )}
        <div className="cc_union_row_container">
          <div
            className="cc_union_column_1"
            style={{
              backgroundColor: "#32648e",
              color: "white",
              fontWeight: "bold",
              borderRadius: "5px",
            }}
          ></div>
          <div
            className="cc_union_expenses_column"
            style={{
              backgroundColor: "#32648e",
              color: "white",
              fontWeight: "bold",
              padding: "6px 0",
              borderRadius: "5px",
            }}
          >
            {t("configCriteria.step3.expensesColumn")}
          </div>
          <div
            className="cc_union_file_column"
            style={{
              backgroundColor: "#32648e",
              color: "white",
              fontWeight: "bold",
              padding: "6px 0",
              borderRadius: "5px",
            }}
          >{`Columna archivo ${fileSelectedOptions?.label}`}</div>
          <div className="cc_union_column_4"></div>
        </div>
        <div className="cc_union_row_container">

          <div className="cc_union_column_1">
            {unions && unions.length > 0 && (
              <DeleteForever
                tooltipPlacement="bottom"
                onClick={handleDeleteAllFilters}
              />
            )}
          </div>

          <div className="cc_union_expenses_column">
            <FormSelectLayout>
              <Select
                reference={expensesColumnSelectRef}
                styles={optionalSelectStyles(expensesColumnSelectedOptions)}
                options={expenseColumnOptions(expensesColumnSelectOptions)}
                className="cc_select"
                name="expensesColumns"

                onChange={expensesColumnOptionsChangeHandler}
                closeMenuOnSelect

                placeholder={t("configCriteria.step1.selectColumn")}
                defaultValue={expensesColumnSelectedOptions}
                isClearable
              />
            </FormSelectLayout>
          </div>
          <div className="cc_union_file_column">
            <FormSelectLayout>
              <Select
                reference={columnSelectRef}
                styles={optionalSelectStyles(columnValidationSelectedOptions)}
                options={columnOptions(
                  fileColumnSelectOptions,
                  fileSelectedOptions
                )}
                className="cc_select"
                name="column"

                onChange={columnValidationOptionsChangeHandler}
                closeMenuOnSelect

                placeholder={t("configCriteria.step1.selectColumn")}
                defaultValue={columnValidationSelectedOptions}
                isClearable
              />
            </FormSelectLayout>
          </div>
          <div className="cc_union_column_4">
            <AddButton onClick={handleAddUnion} />
          </div>
        </div>
        {unions &&
          unions.map((union) => (
            <UnionRow
              key={union.id}
              id={union.id}
              expensesColumn={union.expensesColumn}
              column={union.column}
              handleDeleteFilter={handleDeleteById}
              unions={unions}
              setUnions={setUnions}
            />
          ))}
        {!unions?.length && <Box sx={{ width: "95%", height: "10vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h6" sx={{ fontWeight: "700", color: "var(--text-info)" }}>
            {t("configCriteria.step3.noValidationsWarning")}
          </Typography>
        </Box>}
      </div>
    </FormLayout>
  );
};

export default Step3;
