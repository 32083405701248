import { ConfirmationModal } from "../../dialog";
import { fileOptions } from "../../criterias/configCriteria/selectOptions";
import { selectStyles } from "../../criterias/configCriteria/select.styles";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useRef, useState } from "react";
import AddElementModal from "./Step1Elements/AddElementModal";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "../../dialog/Dialog";
import ElementComponent from "./Step1Elements/ElementComponent";
import Fab from "@mui/material/Fab";
import FormSelectLayout from "../../forms/FormSelectLayout";
import InfoIcon from "@mui/icons-material/Info";
import Select from "../../forms/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import VirtualizatedTable from "../../virtualizatedTable/VirtualizedTable";
import {
  GET_CRITERIA_FILE_COLUMN_URL,
  ITEM_TABLA_URL,
  TABLAS_URL,
  TABLA_VISTA_PREVIA_URL,
} from "../../../api/axios";
import { Typography } from "@mui/material";

const Step1Tables = ({
  fileType,
  defaultElement,
  message,
  dataGetTables,
  tableName,
  setTableName,
  dataGetTable,
  fileSelectedOption,
  fileOptionsChangeHandler,
  getTable,
  idTable,
  handlePostTable,
}) => {
  const { t } = useLanguage();
  const fileSelectRef: any = useRef();
  const [openAddElementModal, toggleAddElementModal] = useDialog();
  const configMode = localStorage.getItem("configMode");
  const newElementConfig =
    localStorage.getItem("newElementConfig") &&
    JSON.parse(String(localStorage.getItem("newElementConfig")));
  const [elementToEdit, setElementToEdit] = useState<any>();
  const [openDetailModal, toggleDetailModal] = useDialog();
  const [openDetailError, toggleDetailError] = useDialog();
  const [openConfirmDeleteElement, toggleConfirmDeleteElement] = useDialog();
  const [detailError, setDetailError] = useState<any>(null);

  const { data: dataGetFileColumn } = useApiQuery(
    `${GET_CRITERIA_FILE_COLUMN_URL}?tipo=${fileType}`,
    false,
    t("report.getAnalysis.error")
  );  

  const onSuccessDeleteItem = () => {
    getTable(`${TABLAS_URL}${dataGetTable?.id}`);
    setElementToEdit(undefined);
  };

  const { callApi: deleteItem } = useApi(
    ITEM_TABLA_URL(dataGetTable?.id, elementToEdit?.id),
    "DELETE",
    t("indicatorsManagement.editIndicator.codes"),
    undefined,
    onSuccessDeleteItem,
    undefined,
    true
  );

  const onSuccessGetPreview = () => {
    toggleDetailModal();
  };

  const {
    callApi: getPreview,
    data: dataPreview,
  } = useApi(
    TABLA_VISTA_PREVIA_URL(dataGetTable?.id, elementToEdit?.id),
    "GET",
    t("indicatorsManagement.editIndicator.codes"),
    undefined,
    onSuccessGetPreview,
    undefined,
    true
  );

  /****************************************************** Validaciones *****************************************/

  const isItemsInfo =
    dataGetTable &&
    dataGetTable.items &&
    dataGetTable.items.length > 0 &&
    fileSelectedOption &&
    tableName &&
    tableName !== "";

  const handleEditElement = (e, element) => {
    e.stopPropagation();
    setElementToEdit(element);
    toggleAddElementModal();
  };

  const deleteElement = (e, element) => {
    e.stopPropagation();
    toggleConfirmDeleteElement();
    setElementToEdit(element);
  };

  const confirmDeleteElement = () => {
    toggleConfirmDeleteElement();
    deleteItem(ITEM_TABLA_URL(defaultElement?.id, elementToEdit?.id));
  };

  const handleViewInfo = (e, element) => {
    e.stopPropagation();
    if (element.mensajes_error && element.mensajes_error.length > 0) {
      setDetailError(element.mensajes_error);
      toggleDetailError();
    } else {
      getPreview(TABLA_VISTA_PREVIA_URL(dataGetTable?.id, element?.id));
    }
  };

  const handleCloseAddElementModal = () => {
    toggleAddElementModal();
    setElementToEdit(undefined);
  };

  return (
    <Stack width="90%">
      <Stack
        sx={{
          width: "100%",
          pb: "10px",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <TextField
          type="text"
          label={t("general.name")}
          variant="outlined"
          fullWidth
          value={tableName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTableName(event.target.value);
          }}
          error={!!message?.text || !tableName || tableName === ""}
          helperText={message.text}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
              margin: 0,
            },
          }}
          size="small"
        />

        {!message.text && tableName && tableName !== "" && (
          <>
            <Stack width="600px">
              <FormSelectLayout
                title={
                  fileType === "TEMPLATE"
                    ? t("indicatorsManagementTexts.step1.file")
                    : fileType === "ANALISIS"
                    ? t("indicatorsManagementTexts.step1.analysis")
                    : fileType === "SIMULACION"
                    ? t("indicatorsManagementTexts.step1.simulation")
                    : t("indicatorsManagementTexts.step1.salesForecast")
                }
                required={true}
                margin={"0px"}
              >
                <Select
                  reference={fileSelectRef}
                  styles={selectStyles(fileSelectedOption)}
                  options={fileOptions(dataGetFileColumn)}
                  className="cc_select"
                  onChange={fileOptionsChangeHandler}
                  closeMenuOnSelect
                  placeholder={
                    fileType === "TEMPLATE"
                      ? t("indicatorsManagementTexts.step1.chooseFile")
                      : fileType === "ANALISIS"
                      ? t("indicatorsManagementTexts.step1.chooseAnalysis")
                      : fileType === "SIMULACION"
                      ? t("indicatorsManagementTexts.step1.chooseSimulation")
                      : t("indicatorsManagementTexts.step1.chooseSalesForecast")
                  }
                  defaultValue={
                    fileSelectedOption ||
                    (newElementConfig && newElementConfig.archivo)
                  }
                  isDisabled={dataGetTable && dataGetTable.items && dataGetTable.items?.length > 0}
                  isClearable
                />
              </FormSelectLayout>
            </Stack>
            <Stack direction={"row"} justifyContent={"center"}>
              {!idTable ? (
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => handlePostTable(fileSelectedOption)}
                  disabled={
                    !message.text && tableName !== "" && !fileSelectedOption
                  }
                  sx={{
                    mt: "15px",
                    "&:hover": {
                      backgroundColor: "var(--transparent-blue-greeny)",
                    },
                  }}
                >
                  <Tooltip title={t("companyParam.addTable")} placement="top">
                    <AddIcon />
                  </Tooltip>
                </Fab>
              ) : (
                <>
                  <Fab
                    size="small"
                    color="primary"
                    onClick={() => handlePostTable(fileSelectedOption)}
                    disabled={
                      !message.text && tableName !== "" && !fileSelectedOption
                    }
                    sx={{
                      mt: "15px",
                      "&:hover": {
                        backgroundColor: "var(--transparent-blue-greeny)",
                      },
                    }}
                  >
                    <Tooltip
                      title={t("companyParam.editTable")}
                      placement="top"
                    >
                      <EditIcon />
                    </Tooltip>
                  </Fab>
                </>
              )}
            </Stack>
          </>
        )}
      </Stack>
      <Stack
        sx={{
          width: "100%",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
          pb: 1,
        }}
      >
        <InfoIcon color="primary" fontSize="medium" />
        <Typography style={{ color: "var(--text-main)", fontWeight: "600" }}>
          {fileType === "TEMPLATE"
            ? t("indicatorsManagementTexts.configTables.theTemplate")
            : fileType === "ANALISIS"
            ? t("indicatorsManagementTexts.configTables.theAnalysis")
            : fileType === "SALES_FORECAST"
            ? t("indicatorsManagementTexts.configTables.theSalesForecast")
            : fileType === "COSTS_FORECAST"
            ? t("indicatorsManagementTexts.configTables.theCostsForecast")
            : fileType === "SIMULACION"
            ? t("indicatorsManagementTexts.configTables.theSimulation")
            : ""}
          {" "}{t("indicatorsManagementTexts.configTables.sourceCannotModify")}
        </Typography>
      </Stack>
      <Stack gap={2}>
        {isItemsInfo &&
          dataGetTable.items.map((item) => {
            return (
              <ElementComponent
                key={item.id}
                element={item}
                handleEditElement={handleEditElement}
                handleViewInfo={handleViewInfo}
                deleteElement={deleteElement}
              />
            );
          })}
      </Stack>
      <Stack direction={"row"} width={"100%"} justifyContent={"center"}>
        <Fab
          size="small"
          color="primary"
          onClick={() => toggleAddElementModal()}
          disabled={
            !fileSelectedOption ||
            !tableName ||
            tableName === "" ||
            !!message.text ||
            !idTable
          }
          sx={{
            mt: "15px",
            "&:hover": {
              backgroundColor: "var(--transparent-blue-greeny)",
            },
          }}
        >
          <Tooltip title={t("companyParam.addElement")} placement="top">
            <AddIcon />
          </Tooltip>
        </Fab>
      </Stack>
      {openAddElementModal && (
        <Dialog
          open={openAddElementModal}
          handleClose={handleCloseAddElementModal}
          title={t("indicatorsManagementTexts.configTables.newAction")}
          maxWidth={"xl"}
        >
          <Stack sx={{ width: "1200px", minHeight: "450px" }}>
            <AddElementModal
              handleToggleModal={handleCloseAddElementModal}
              dataGetTables={dataGetTables}
              dataGetTable={dataGetTable}
              element={elementToEdit}
              getTable={getTable}
              setElementToEdit={setElementToEdit}
            />
          </Stack>
        </Dialog>
      )}
      <Dialog
        open={openDetailModal}
        handleClose={toggleDetailModal}
        title={t("indicatorsManagementTexts.configTables.tableSample")}
        maxWidth={"lg"}
      >
        <Stack sx={{ minWidth: "70vw" }}>
          {dataPreview && (
            <VirtualizatedTable
              columns={
                transformDataSimpleTable(dataPreview, undefined, undefined, t)
                  .columns
              }
              rows={
                transformDataSimpleTable(dataPreview, undefined, undefined, t)
                  .rows
              }
              rowsPerPageOptions={[5, 10, 20]}
              rowHeight={35}
              tableHeight={"70vh"}
              hideFooter={true}
              toolbar={true}
              columnsButton={true}
              filterButton={true}
              exportButton={true}
              checkboxSelection={false}
              setSelectedRows={() => <></>}
            />
          )}
        </Stack>
      </Dialog>

      <Dialog
        open={openDetailError}
        handleClose={toggleDetailError}
        title={t("indicatorsManagementTexts.configTables.tableSample")}
        maxWidth={"lg"}
      >
        {detailError && (
          <Stack
            sx={{
              whiteSpace: "normal",
              height: "auto",
              textAlign: "start",
              color: "var(--text-main)",
            }}
          >
            <ul style={{ width: "100%", color: "var(--text-main)" }}>
              {detailError?.map((error: any, index: number) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </Stack>
        )}
      </Dialog>

      <ConfirmationModal
        open={openConfirmDeleteElement}
        handleClose={toggleConfirmDeleteElement}
        handleAccept={confirmDeleteElement}
        message={
          elementToEdit?.tipo === "NUEVA_COLUMNA" ||
          elementToEdit?.tipo === "AGRUPAR_VALORES"
            ? t("indicatorsManagementTexts.configTables.confirmDeleteElement")
            : t("companyParam.deleteThisElement")
        }
        customMessage={
          elementToEdit?.tipo === "NUEVA_COLUMNA" ||
          elementToEdit?.tipo === "AGRUPAR_VALORES"
            ? true
            : false
        }
        title={t("companyParam.deleteElement")}
      />
    </Stack>
  );
};

export default Step1Tables;
