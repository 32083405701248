import { convertObjectArrayToText, convertToText } from "../../criterias/utils";
import { CustomizedTable } from "../../customizedTable";
import { convertArrayToText } from "../../taskManager/utils";

export const getColumnsAndRows = (indicator: any, type: string, t) => {
  const columnsMap: any = {
    default: [
      t("indicatorsManagementTexts.viewInfoModal.columns.column"),
      t("indicatorsManagementTexts.viewInfoModal.columns.values"),
      t("indicatorsManagementTexts.viewInfoModal.columns.criteria"),
    ],
    external: [
      t(""),
      t("indicatorsManagementTexts.viewInfoModal.columns.values"),
      t("indicatorsManagementTexts.viewInfoModal.columns.criteria"),
    ],
    validation: [
      `${t("indicatorsManagementTexts.viewInfoModal.fileColumn")} ${
        indicator?.archivo_externo_validacion?.label
      }`,
      `${t("indicatorsManagementTexts.viewInfoModal.fileColumn")} ${
        indicator?.archivo?.label
      }`,
    ],
  };

  const rowsMap: any = {
    default: !indicator?.tipo
      ? indicator?.indicador_archivo_columna_filtra
          ?.filter((item: any) => !item.externo)
          .map((item: any) => ({
            Columna: item.columna,
            Valores: convertObjectArrayToText(
              item.indicador_archivo_valor_filtra,
              t
            ),
            Criterio: item.excluir
              ? t("indicatorsManagementTexts.viewInfoModal.exclude")
              : t("indicatorsManagementTexts.viewInfoModal.include"),
          }))
      : indicator?.grafico_archivo_columna_filtra
          ?.filter((item: any) => !item.externo)
          .map((item: any) => ({
            Columna: item.columna,
            Valores: convertObjectArrayToText(
              item.grafico_archivo_valor_filtra,
              t
            ),
            Criterio: item.excluir
              ? t("indicatorsManagementTexts.viewInfoModal.exclude")
              : t("indicatorsManagementTexts.viewInfoModal.include"),
          })),
    external: !indicator?.tipo
      ? indicator?.indicador_archivo_columna_filtra
          ?.filter((item: any) => item.externo)
          .map((item: any) => ({
            Columna: item.columna,
            Valores: convertObjectArrayToText(
              item.indicador_archivo_valor_filtra,
              t
            ),
            Criterio: item.excluir
              ? t("indicatorsManagementTexts.viewInfoModal.columns.exclude")
              : t("indicatorsManagementTexts.viewInfoModal.columns.include"),
          }))
      : indicator?.grafico_archivo_columna_filtra
          ?.filter((item: any) => item.externo)
          .map((item: any) => ({
            Columna: item.columna,
            Valores: convertObjectArrayToText(
              item.grafico_archivo_valor_filtra,
              t
            ),
            Criterio: item.excluir
              ? t("indicatorsManagementTexts.viewInfoModal.exclude")
              : t("indicatorsManagementTexts.viewInfoModal.include"),
          })),
    validation: !indicator?.tipo
      ? indicator?.indicador_archivo_columna_valida
          ?.filter((item: any) => item.externo)
          .map((item: any) => ({
            [t("indicatorsManagementTexts.viewInfoModal.externFileColumn")]:
              item?.indicador_columna_gastos_o_externo,
            [t("indicatorsManagementTexts.viewInfoModal.criteriaBaseColumn")]:
              item?.indicador_columna_archivo_base,
          }))
      : indicator?.grafico_archivo_columna_valida
          ?.filter((item: any) => item.externo)
          .map((item: any) => ({
            [t("indicatorsManagementTexts.viewInfoModal.externFileColumn")]:
              item?.grafico_columna_gastos_o_externo,
            [t("indicatorsManagementTexts.viewInfoModal.criteriaBaseColumn")]:
              item?.grafico_columna_archivo_base,
          })),
  };

  return {
    columns: columnsMap[type],
    rows: rowsMap[type],
  };
};

export const renderTables = (indicator: any, t) => {
  const sections = [
    {
      type: "default",
      label: t("indicatorsManagementTexts.viewInfoModal.filtersOnFile"),
    },
    {
      type: "external",
      label: t("indicatorsManagementTexts.viewInfoModal.filtersOnExternalFile"),
    },
    {
      type: "validation",
      label: t("indicatorsManagementTexts.viewInfoModal.columnValidations"),
    },
  ];

  return sections.map((section) => {
    const { columns, rows } = getColumnsAndRows(indicator, section.type, t);
    if (rows && rows.length > 0) {
      return (
        <div key={section.type}>
          <CustomizedTable COLUMNS={columns} ROWS={rows} width={450} />
        </div>
      );
    }
    return null;
  });
};

export const titleByType = (t, type) => {    
  if (!type) {
    return t("indicatorsManagementTexts.viewInfoModal.generalInfoIndicator");
  } else {
    switch (type) {
      case "TABLA":
        return t("Información general de la tabla");
      default:
        return t("indicatorsManagementTexts.viewInfoModal.generalInfoGraph");
    }
  }
};

export const specialFunctions = (t, indicator) => {
 return [
    {
      condition: indicator?.funcion === "SUMA.UNICO",
      key: t("indicatorsManagementTexts.viewInfoModal.validateUniqueColumn"),
      value: indicator?.columna_indicador_unico?.label,
    },
    {
      condition: indicator?.funcion === "CONTAR.UNICO",
      key: t("indicatorsManagementTexts.viewInfoModal.validateUniqueColumn"),
      value: indicator?.columna_grafico_unico?.label,
    },
    {
      condition: indicator?.funcion === "SUMA.PRODUCTO",
      key: t("indicatorsManagementTexts.viewInfoModal.multiplyColumn"),
      value: indicator?.indicador_archivo_columna_multiplicar &&
        convertToText(indicator?.indicador_archivo_columna_multiplicar, t),
    },
    {
      condition: indicator?.funcion === "DIVISION",
      key: t("indicatorsManagementTexts.step1.denominator"),
      value: indicator?.columna_eje_y && indicator?.columna_eje_y?.length !== 0
        ? convertArrayToText(
            indicator?.columna_eje_y?.map(
              (item) => item.columna_divisor?.label
            )
          )
        : indicator?.columna_eje_y && indicator?.columna_eje_y[0]?.label,
    },
  ];
  
}