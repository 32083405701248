import { Box, Divider, Stack, Typography, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  getChatLabelStyles,
  getEmptyHistoryMessageStyles,
  getEmptyHistoryStyles,
  getHistoryItemStyles,
  getIconButtonStyles,
  getIconStyles,
} from "../utils";
import ChatBotProvider from "../../../context/ChatBotProvider";
import { useContext } from "react";
import useLanguage from "../../../context/LanguageProvider";

const HistorySection = ({
  title,
  history,
  selectedHistory,
  onClickItem,
  handleOpenMenu,
}) => {
  const { t } = useLanguage();
  const { chatBotState } =
    useContext<React.ContextType<typeof ChatBotProvider>>(ChatBotProvider);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          position: "sticky",
          backgroundColor: "white",
          width: "100%",
          top: 0,
          zIndex: 2,
          pb: 1,
          pt: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "var(--text-main)",
            fontWeight: "600",
            mb: 1,
            userSelect: "none",
          }}
        >
          {title}
        </Typography>
      </Box>
      {history && history.length > 0 ? (
        history.map((chat, index) => (
          <Stack
            key={chat.value}
            sx={{
              ...getHistoryItemStyles(
                selectedHistory?.value === chat.value ||
                  chatBotState?.historyTitle?.value === chat.value
              ),
              mt: index === 0 ? "" : "5px",
            }}
            onClick={() =>
              chatBotState?.historyTitle?.value !== chat.value
                ? onClickItem(chat)
                : undefined
            }
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" sx={getChatLabelStyles()}>
                {chat.label}
              </Typography>
            </Stack>
            <IconButton
              size="small"
              sx={getIconButtonStyles(
                selectedHistory?.value === chat.value ||
                  chatBotState?.historyTitle?.value === chat.value
              )}
              className="icon-button"
              onClick={(e) => {
                e.stopPropagation(); // Evita la propagación del evento - Stack padre - impide el onClickItem
                handleOpenMenu(e, chat);
              }}
            >
              <MoreHorizIcon sx={getIconStyles()} />
            </IconButton>
          </Stack>
        ))
      ) : (
        <Stack sx={getEmptyHistoryStyles()}>
          <Typography variant="body2" sx={getEmptyHistoryMessageStyles()}>
            {t("phibot.historySection.notHistoryToday")} {<br />}
            {t("phibot.historySection.doYourFirstConsult")}
          </Typography>
        </Stack>
      )}
      {title !== t("phibot.sideBarChatBot.before") && (
        <Divider sx={{ width: "100%", mt: 1, mb: 1 }} />
      )}
    </Box>
  );
};

export default HistorySection;
