import { Button } from "../../buttons";
import { Company } from "../types";
import { ConfirmationModal } from "../../dialog";
import { Message } from "../../criterias/configCriteria/types";
import { selectStyles } from "../../criterias/configCriteria/select.styles";
import { Typography } from "@mui/material";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useGetCompanies } from "../../company/api";
import { useState, useEffect, useRef } from "react";
import CustomInput from "../../forms/CustomInput";
import FormLayout from "../../forms/FormLayout";
import FormSelectLayout from "../../forms/FormSelectLayout";
import Select from "../../forms/Select";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useApi from "../../../hooks/useApi";
import useInfoContext from "../../../hooks/useInfoContext";
import useLanguage from "../../../context/LanguageProvider";
import useMultiSelect from "../../../hooks/useMultiSelect";
import {
  COMPANY_URL,
  COPY_COMPANY_URL,
  EDIT_COMPANY_URL,
  GET_ALL_USERS_URL,
} from "../../../api/axios";
import {
  criteria_buttons_container,
  form_label,
  form_subtitle_styles,
  step_message_styles,
  step_title_styles,
} from "../../../styles/app-styles";
import ElementComponent from "./ElementComponent";
interface UserFormProps {
  handleFinish: (() => void) | ((status: number | undefined) => void);
  configMode: "CREATE" | "EDIT" | "COPY" | "";
  company?: Company;
}

const UserForm = ({ configMode, company, handleFinish }: UserFormProps) => {
  const { t } = useLanguage();
  const { setCompany, company: companyContext }: any = useInfoContext();
  const { data: companies } = useGetCompanies();
  const [message, setMessage] = useState<Message>();
  const [nameError, setNameError] = useState<Message>();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [companyFormComplete, setCompanyFormComplete] = useState(false);
  const [activeCompany, setActiveCompany] = useState(
    (configMode === "EDIT" || configMode === "COPY") &&
      company?.habilitada !== undefined
      ? company?.habilitada
      : true
  );
  const companyNameRef: any = useRef(null);
  const [companyName, setCompanyName] = useState(
    configMode === "EDIT" && company?.nombre
      ? company?.nombre
      : configMode === "COPY" && company?.nombre
      ? company?.nombre.concat(t("copyGenericText"))
      : ""
  );
  const companyVisualNameRef: any = useRef(null);
  const [companyVisualName, setCompanyVisualName] = useState(
    configMode === "EDIT" && company?.nombre_visual
      ? company?.nombre_visual
      : configMode === "COPY" && company?.nombre_visual
      ? company?.nombre_visual.concat(t("copyGenericText"))
      : ""
  );
  const userSelectRef: any = useRef(null);
  const [
    usersSelectedOptions,
    setUsersSelectedOptions,
    usersOptionsChangeHandler,
  ] = useMultiSelect(
    configMode === "EDIT" ? company?.usuarios_habilitados : undefined
  );
  const [usersByDashboard, setUsersByDashboard] = useState<any[]>(
    configMode === "EDIT" ? company?.dashboards_bi || [] : []
  );

  const { data: dataUsers } = useApiQuery(
    GET_ALL_USERS_URL,
    true,
    t("administratorPanel.users.errorLoadingUsers")
  );

  const { isLoading: isLoadingPostCompany, callApi: postCompany } = useApi(
    "",
    "POST",
    t("administratorPanel.companies.createCompany.codes"),
    undefined,
    handleFinish
  );

  const handleFinishPut = () => {
    handleFinish(undefined);
    if (company?.id === companyContext.value) {
      setCompany({ value: company?.id, label: companyVisualName });
    }
  };

  const { isLoading: isLoadingPutCompany, callApi: putCompany } = useApi(
    "",
    "PUT",
    t("administratorPanel.companies.editCompany.codes"),
    undefined,
    handleFinishPut
  );

  const { isLoading: isLoadingCopyCompany, callApi: copyCompany } = useApi(
    "",
    "POST",
    t("administratorPanel.companies.createCompany.codes"),
    undefined,
    handleFinish
  );

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  useEffect(() => {
    setMessage(undefined);
    if (companyVisualName) {
      const companyNameExists = companies?.find(
        (company: any) =>
          company.nombre_visual.toLowerCase() ===
          companyVisualName.toLowerCase()
      );
      if (
        companyNameExists &&
        (configMode === "CREATE" || configMode === "COPY")
      ) {
        setMessage({
          type: "error",
          text: t("administratorPanel.companies.duplicatedCompanyName"),
        });
      } else if (
        companyNameExists &&
        (configMode === "EDIT" || configMode === "COPY") &&
        company?.nombre_visual.toLowerCase() !== companyVisualName.toLowerCase()
      ) {
        setMessage({
          type: "error",
          text: t("administratorPanel.companies.duplicatedCompanyName"),
        });
      } else {
        setMessage(undefined);
      }
    }
  }, [companyVisualName]);

  useEffect(() => {
    setMessage(undefined);
    if (companyName) {
      const companyNameExists = companies?.find(
        (company: any) =>
          company.nombre.toLowerCase() === companyName.toLowerCase()
      );
      if (
        companyNameExists &&
        (configMode === "CREATE" || configMode === "COPY")
      ) {
        setMessage({
          type: "error",
          text: t("administratorPanel.companies.duplicatedCompanyName"),
        });
      } else if (
        companyNameExists &&
        (configMode === "EDIT" || configMode === "COPY") &&
        company?.nombre.toLowerCase() !== companyName.toLowerCase()
      ) {
        setMessage({
          type: "error",
          text: t("administratorPanel.companies.duplicatedCompanyName"),
        });
      } else {
        setMessage(undefined);
      }
    }
  }, [companyName]);

  useEffect(() => {
    setNameError(undefined);
    if (companyName) {
      const validFormatRegExp = new RegExp("^[a-zA-Z0-9_]*$");
      const validFormat = validFormatRegExp.test(companyName);
      if (!validFormat) {
        setNameError({
          type: "error",
          text: t("administratorPanel.companies.companyNameFormat"),
        });
      } else {
        setNameError(undefined);
      }
    } else {
      setNameError(undefined);
    }
  }, [companyName]);

  const handleUserFormComplete = () => {
    if (!!companyName && !!companyVisualName) {
      setCompanyFormComplete(true);
    } else {
      setCompanyFormComplete(false);
    }
  };

  useEffect(() => {
    handleUserFormComplete();
  }, [companyName, companyVisualName]);

  const handleRestart = () => {
    setCompanyName("");
    setCompanyVisualName("");
    companyNameRef.value = "";
    companyVisualNameRef.value = "";
  };

  const saveCompany = () => {
    const newCompany = {
      nombre: companyName,
      nombre_visual: companyVisualName,
      habilitada: activeCompany,
      usuarios_habilitados: usersSelectedOptions?.map((option) => option.value),
      dashboards_bi:
        usersByDashboard?.map((dashboard: any) => ({
          id: dashboard.id,
          usuarios: dashboard?.usuarios?.map((user: any) => user.value) || [],
        })) || [],
    };
    if (configMode === "CREATE") {
      postCompany(COMPANY_URL, newCompany);
    } else if (configMode === "EDIT") {
      company && putCompany(EDIT_COMPANY_URL(company?.id), newCompany);
    } else if (configMode === "COPY") {
      company && copyCompany(COPY_COMPANY_URL(company?.id), newCompany);
    }
    handleCloseConfirmationModal();
  };
  const toggleActiveCompany = () => {
    setActiveCompany((prev: boolean) => !prev);
  };

  const dashboardsToShow =
    configMode === "EDIT" ? company?.dashboards_bi || [] : [];

  return (
    <>
      <Typography sx={step_title_styles}>
        {configMode !== "EDIT"
          ? t("administratorPanel.companies.newCompany")
          : t("administratorPanel.companies.editCompanyLabel")}
      </Typography>
      <SimpleBackdrop
        open={
          isLoadingPutCompany || isLoadingPostCompany || isLoadingCopyCompany
        }
        message={
          isLoadingPutCompany
            ? t("administratorPanel.companies.updatingCompany")
            : isLoadingPostCompany
            ? t("administratorPanel.companies.creatingCompany")
            : isLoadingCopyCompany
            ? t("administratorPanel.companies.copyingCompany")
            : ""
        }
      />
      {message && (
        <Typography sx={step_message_styles(message.type)}>
          {message.text}
        </Typography>
      )}
      {nameError && (
        <div className="cc_info_filters_box">
          <p className={`cc_info_filters_${nameError.type}`}>
            {nameError.text}
          </p>
        </div>
      )}
      <Stack
        sx={{
          alignItems: "stretch",
          width: "90%",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 2,
          minHeight: "400px",
        }}
      >
        <FormLayout width="50%">
          <Typography variant="h2" sx={form_subtitle_styles}>
            {t("administratorPanel.companies.companyInformation")}
          </Typography>
          <FormSelectLayout
            title={t("administratorPanel.companies.name")}
            required={true}
          >
            <CustomInput
              type="text"
              name="companyName"
              inputRef={companyNameRef}
              disabled={configMode === "EDIT"}
              required={true}
              value={companyName}
              setValue={setCompanyName as (value: string | number) => void}
              placeholder={t("administratorPanel.companies.name")}
              autoFocus={true}
              maxLength={60}
            />
          </FormSelectLayout>

          <FormSelectLayout
            title={t("administratorPanel.companies.visualName")}
            required={true}
          >
            <CustomInput
              type="text"
              name="companyVisualName"
              required={true}
              inputRef={companyVisualNameRef}
              value={companyVisualName}
              setValue={
                setCompanyVisualName as (value: string | number) => void
              }
              placeholder={t("administratorPanel.companies.visualName")}
              autoFocus={true}
              maxLength={50}
            />
          </FormSelectLayout>
          <FormSelectLayout
            title={t("administratorPanel.users.companyEnabledUsers")}
            required={true}
          >
            <Select
              reference={userSelectRef}
              styles={selectStyles(usersSelectedOptions)}
              options={dataUsers
                ?.filter((user) => user?.is_active)
                ?.map((user) => {
                  return { value: user.id, label: user.email };
                })}
              form="cc_form"
              className="cc_select"
              name="companies"
              onChange={usersOptionsChangeHandler}
              closeMenuOnSelect={false}
              isMulti
              placeholder={t("administratorPanel.users.multipleOptions")}
              defaultValue={usersSelectedOptions?.map((option) => option)}
              isClearable
            />
          </FormSelectLayout>
          <div className="cc_step_form_checkbox cc_checkbox_container">
            <input
              type="checkbox"
              className="cc_checkbox"
              id="companyActive"
              onChange={toggleActiveCompany}
              defaultChecked={activeCompany}
            />
            <label style={form_label} htmlFor="companyActive">
              {t("administratorPanel.companies.activeCompany")}
            </label>
          </div>
        </FormLayout>
        <FormLayout width="50%">
          <Typography variant="h2" sx={form_subtitle_styles}>
            {t("Permisos para tableros")}
          </Typography>
          <Stack width="100%" gap={1}>
            {dashboardsToShow?.map((dashboard: any) => {
              return (
                <ElementComponent
                  key={dashboard.id}
                  element={dashboard}
                  dataUsers={usersSelectedOptions || []}
                  configMode={configMode}
                  setUsersByDashboard={setUsersByDashboard}
                />
              );
            })}
          </Stack>
        </FormLayout>
      </Stack>
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        handleAccept={saveCompany}
        message={
          configMode === "EDIT"
            ? t("administratorPanel.companies.editCompanyAction")
            : t("administratorPanel.companies.createCompanyAction")
        }
        title={
          configMode === "EDIT"
            ? t("administratorPanel.companies.editCompanyLabel")
            : t("administratorPanel.companies.createCompanyLabel")
        }
      />
      <Stack sx={criteria_buttons_container}>
        <Button
          title={t("administratorPanel.companies.cleanAll")}
          color="grey"
          type="button"
          onClick={handleRestart}
        />
        <Button
          title={t("administratorPanel.companies.finish")}
          color="blue"
          type="button"
          onClick={() => setOpenConfirmationModal(true)}
          disabled={
            !companyFormComplete ||
            message?.type === "error" ||
            nameError?.type === "error"
          }
        />
      </Stack>
    </>
  );
};

export default UserForm;
