import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import React, { useContext, useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.css";
import useLanguage from "../../context/LanguageProvider";
import ChatBotProvider from "../../context/ChatBotProvider";
import useApi from "../../hooks/useApi";
import { processDataChart } from "./utils";
import { SEND_MESSAGE_CHATBOT } from "../../api/axios";
import CustomButton from "../buttons/Button";
import ChatbotTextField from "./chatBotComponents/ChatbotTextField";
import MessageBubble from "./chatBotComponents/MessageBubble";
import { title_layout_styles } from "../../styles/app-styles";
import AddBoxIcon from "@mui/icons-material/AddBox";

const ChatBotMessages = ({
  messageEndRef,
  dataGetChatHistoryById,
  setIsLoading,
  isLoading,
  setSelectedHistory,
  isSidebarOpen
}: any) => {
  const { t } = useLanguage();
  const [messageValue, setMessageValue] = useState<string>("");
  const { chatBotState, chatBotDispatch } =
    useContext<React.ContextType<typeof ChatBotProvider>>(ChatBotProvider);
  const [visibleMessages, setVisibleMessages] = useState(
    dataGetChatHistoryById &&
      chatBotState?.historyTitle?.value ===
        dataGetChatHistoryById?.messages[0]?.chat
      ? 4
      : 100
  );
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
  const textFieldRef = useRef<HTMLInputElement>(null);

  /************************* CARGA Y OBTENCIÓN DE DATOS **************************************/

  const onSuccessSendMessage = (data: any) => {
    if (data) {
      const dataChart =
        data.grafico && typeof data.grafico === "string"
          ? processDataChart(data.grafico)
          : data.grafico;

      const newConfig = {
        data: data,
        message_id: data.message_id,
        message: data.message,
        isSent: false,
        table: data.table && data.table,
        chart_types: data.chart_types && data.chart_types,
        useful: null,
        is_valid: data.is_valid,
        chart: dataChart && dataChart,
        query: data.query && data.query,
      };
      data.chat_id &&
        data.title &&
        chatBotDispatch({
          type: "SET_HISTORY_TITLE",
          payload: {
            value: data.chat_id,
            label: data.title,
          },
        });
      chatBotDispatch({ type: "SET_MESSAGE", payload: newConfig });
      setTimeout(() => {
        if (textFieldRef.current) {
          textFieldRef.current.focus();
        }
      }, 0);
      setVisibleMessages((prevData) => prevData + 1);
    }
  };

  const onErrorSendMessage = () => {
    const newConfig = {
      message: t("phibot.chatBotMessages.errorToResponse"),
      isSent: false,
      is_valid: false,
    };

    chatBotDispatch({ type: "SET_MESSAGE", payload: newConfig });
    chatBotDispatch({ type: "SET_PRINCIPAL_QUESTION", payload: "" });
    setTimeout(() => {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }, 0);
  };

  const { isLoading: isLoadingSendMessage, callApi: postMessage } = useApi(
    "",
    "POST",
    undefined,
    undefined,
    onSuccessSendMessage,
    onErrorSendMessage,
    false,
    undefined,
    undefined,
    false
  );

  const onSuccessSendGraphMessage = (data: any) => {
    if (data) {
      if (data.grafico) {
        const dataChart =
          typeof data.grafico === "string"
            ? processDataChart(data.grafico)
            : data.grafico;

        const newConfig = {
          message_id: data.message_id,
          message: data.message || "",
          isSent: false,
          chart: dataChart && dataChart,
          useful: null,
          is_valid: true,
          query: data.query && data.query,
        };

        chatBotDispatch({ type: "SET_MESSAGE", payload: newConfig });
      } else {
        const newConfig = {
          message_id: data.message_id,
          message: data.message || "",
          isSent: false,
          options: data.options.map((action) => action.toUpperCase()),
          useful: null,
          is_valid: data.is_valid,
          query: data.query && data.query,
        };
        chatBotDispatch({ type: "SET_MESSAGE", payload: newConfig });
      }
    }
    setTimeout(() => {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }, 0);
  };

  const { isLoading: isLoadingGraphMessage, callApi: postGraphMessage } =
    useApi(
      "",
      "POST",
      t("administratorPanel.editUser.codes"),
      undefined,
      onSuccessSendGraphMessage,
      undefined,
      false
    );

  useEffect(() => {
    if (messageEndRef.current || isLoading) {
      messageEndRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [chatBotState.messages, messageEndRef.current, isLoading]);

  useEffect(() => {
    dataGetChatHistoryById &&
      dataGetChatHistoryById?.messages[0]?.chat ===
        chatBotState.historyTitle.value &&
      setVisibleMessages(4);
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [dataGetChatHistoryById, chatBotState.historyTitle.value, textFieldRef]);

  useEffect(() => {
    setMessageValue("");
  }, [chatBotState.historyTitle.value]);

  useEffect(() => {
    if (isLoadingGraphMessage || isLoadingSendMessage) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingGraphMessage, isLoadingSendMessage]);

  /************************* MANEJO DE FUNCIONES **************************************/

  const sendMessage = (message: string) => {
    const newMessageData = {
      message: message,
      isSent: true,
    };

    chatBotDispatch({ type: "SET_MESSAGE", payload: newMessageData });
    chatBotDispatch({ type: "SET_PRINCIPAL_QUESTION", payload: message });

    const newConfig = {
      chat_id: chatBotState.historyTitle.value || null,
      message: message,
    };

    postMessage(SEND_MESSAGE_CHATBOT, newConfig);
    setMessageValue("");
    setVisibleMessages((prevData) => prevData + 1);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      messageValue.trim() !== ""
    ) {
      event.preventDefault();
      sendMessage(messageValue);
    }
  };

  const handleMessageValue = (message: string) => {
    setMessageValue(message);
  };

  const loadMoreMessages = () => {
    setLoadingMoreMessages(true);
    setTimeout(() => {
      setVisibleMessages((prev) => prev + 4);
      setLoadingMoreMessages(false);
    }, 1000);
  };

  const handleNewChat = () => {
    setSelectedHistory(undefined);
    chatBotDispatch({ type: "NEW_CHAT" });
  };

  return (
    <Box
      sx={{
        borderLeft: "1px solid white",
        borderRight: "1px solid var(--light-grey)",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        transition: "width 0.3s ease",
        height: `calc(100vh - 155px)`,
      }}
    >
      {isSidebarOpen && chatBotState.messages.length > 1 && (
        <Tooltip title={t("phibot.newChat")}>
          <Stack
            sx={{
              position: "absolute",
              top: 10,
              ml: "20px",
              zIndex: 100,
            }}
          >
            <IconButton size="small" onClick={handleNewChat}>
              <AddBoxIcon
                color={"primary"}
                sx={{
                  fontSize: "35px",
                }}
              />
            </IconButton>
          </Stack>
        </Tooltip>
      )}
      {chatBotState.historyTitle?.label !== "" && (
        <Stack
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid var(--light-grey)",
            pb: "14px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              ...title_layout_styles,
              mt: 2,
            }}
          >
            {chatBotState.historyTitle.label}
          </Typography>
        </Stack>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          p: "25px 75px 25px 75px",
          flexDirection: "column",
          overflowX: "auto",
          height: `calc(100vh - 250px)`,
          position: "relative",
        }}
      >
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          {loadingMoreMessages ? (
            <CircularProgress />
          ) : (
            chatBotState?.messages.length > visibleMessages && (
              <CustomButton
                title={t("buttons.speedDialTooltipOpen.seeMore")}
                color="blue"
                type="button"
                onClick={loadMoreMessages}
              />
            )
          )}
        </Stack>
        {chatBotState?.messages &&
          chatBotState?.messages
            .slice(-visibleMessages)
            .map((message, index) => (
              <MessageBubble
                key={index}
                message_id={message.message_id}
                message={message.message}
                table={message.table}
                chart_types={message.chart_types}
                chart={message.chart}
                postGraphMessage={postGraphMessage}
                isSent={message.isSent}
                options={message?.options || []}
                maxWidth={"770px"}
                useful={message.useful}
                is_valid={message.is_valid}
                query={message.query}
              />
            ))}
        <Stack
          ref={messageEndRef}
          sx={{
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {isLoading && (
            <>
              <Box
                sx={{
                  marginRight: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Tooltip title={"PHITO"}>
                  <SmartToyIcon color={"primary"} />
                </Tooltip>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  padding: "12px 0px 12px 12px",
                  fontWeight: "600",
                  color: "var(--text-main)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("phibot.chatBotMessages.writing")}
                <span style={{ animation: "blink 1s infinite" }}>.</span>
                <span style={{ animation: "blink 1s infinite 0.3s" }}>.</span>
                <span style={{ animation: "blink 1s infinite 0.6s" }}>.</span>
              </Typography>
            </>
          )}
        </Stack>
      </Box>
      <Divider
        sx={{
          mt: 1,
          width: "100%",
          mb: 3,
        }}
      />
      <Stack
        sx={{
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          p: "0 75px 0 75px",
          borderBottom: "1px solid var(--light-grey)",
        }}
      >
        <ChatbotTextField
          isLoading={isLoading}
          messageValue={messageValue}
          handleMessageValue={handleMessageValue}
          handleKeyDown={handleKeyDown}
          sendMessage={sendMessage}
          textFieldRef={textFieldRef}
        />
        <Typography
          variant="body2"
          sx={{
            flex: 1,
            p: 1,
            color: "var(--text-main)",
            fontWeight: "600",
            userSelect: "none",
            textAlign: "center",
          }}
        >
          {t("phibot.chatBotMessages.chatbotInfo")}
        </Typography>
      </Stack>
    </Box>
  );
};

export default ChatBotMessages;
