import { ActionMeta } from "react-select";
import { handleClickVariant } from "../../../parameterization/utils";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { VALIDATE_COLUMN_PREPRORRATION_URL } from "../../../../api/axios";
import Select from "../../../forms/Select";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import useApi from "../../../../hooks/useApi";
import useLanguage from "../../../../context/LanguageProvider";
import {
  optionalSelectStyles,
  selectStyles,
} from "../../../criterias/configCriteria/select.styles";

const KeyColumnMultiSelect = ({
  defaultValue,
  columnKey,
  optional,
  setKeyColumns,
  dataGetParams,
  fileData
}) => {
  const { t } = useLanguage();
  const { enqueueSnackbar } = useSnackbar();
  const keyColumnSelectRef: any = useRef(null);
  const [columnSelectedOptions, setColumnSelectedOptions] = useState<
    { value: string; label: string }[] | undefined
  >(defaultValue);

  const onSuccessfulValidateColumn = (data: any, extraData) => {
    const columnisUsed = data;
    const columnToDelete = extraData;
    if (!columnisUsed || columnisUsed === false) {
      const updatedColumns = columnSelectedOptions?.filter(
        (item) => item.value !== columnToDelete.value
      );
      if (updatedColumns?.length === 0) {
        setColumnSelectedOptions(undefined);
        setKeyColumns((prev) => ({
          ...prev,
          [columnKey]: "",
        }));
      } else {
        setColumnSelectedOptions(updatedColumns);
        setKeyColumns((prev) => ({
          ...prev,
          [columnKey]: updatedColumns?.map(
            (item: { value: string; label: string }) => item.label
          ),
        }));
      }
    } else {
      handleClickVariant(
        t(
          "La columna no se puede eliminar ya que tiene flujos asignados.  Para poder eliminarlo, se deben eliminar los flujos asignados en la vista de parametrización de preprorrateo."
        ),
        "error",
        enqueueSnackbar
      );
    }
  };

  const { isLoading: isLoadingValidateColumn, callApi: validateColumn } =
    useApi(
      VALIDATE_COLUMN_PREPRORRATION_URL,
      "GET",
      undefined,
      undefined,
      (data, extraData) => onSuccessfulValidateColumn(data, extraData),
      undefined,
      false
    );

  const columnOptionsChangeHandler = (value: any, action: ActionMeta<any>) => {
    switch (action.action) {
      case "select-option":
        setColumnSelectedOptions(value);
        setKeyColumns((prev) => ({
          ...prev,
          [columnKey]: value.map(
            (item: { value: string; label: string }) => item.label
          ),
        }));
        break;
      case "remove-value":
        validateColumn(
          VALIDATE_COLUMN_PREPRORRATION_URL(action.removedValue?.value),
          undefined,
          action?.removedValue
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingValidateColumn}
        message={t("general.loading")}
      />
      <Select
        value={columnSelectedOptions}
        reference={keyColumnSelectRef}
        styles={
          optional
            ? optionalSelectStyles(columnSelectedOptions)
            : selectStyles(columnSelectedOptions)
        }
        options={
          fileData &&
          fileData.columnas
            ? fileData.columnas
                .map((item: any) => {
                  return { value: item.id, label: item.nombre };
                })
                /* .filter((item: { value: string; label: string }) => {
                  return (
                    item.value !== dataGetParams?.columnas_clave.agrupador &&
                    item.value !== dataGetParams?.columnas_clave.monto_gasto &&
                    item.value !== dataGetParams?.columnas_clave.bucket
                  );
                }) */
            : []
        }
        className="pp_select_schema"
        isClearable={false}
        isMulti
        onChange={columnOptionsChangeHandler}
        closeMenuOnSelect={false}
        isSearchable
        placeholder={t("general.chooseColumn")}
        defaultValue={
          dataGetParams &&
          dataGetParams.columnas_clave[columnKey] &&
          dataGetParams.columnas_clave[columnKey].length > 0
            ? dataGetParams.columnas_clave[columnKey].map((column: string) => ({
                value: column,
                label: column,
              }))
            : undefined
        }
        isDisabled={false}
      />
    </>
  );
};

export default KeyColumnMultiSelect;
