import { Box } from "@mui/material";
import { Button } from "../buttons";
import { DASHBOARDS_URL } from "../../api/axios";
import { DashboardType } from "./types";
import { findDashboardById } from "./utils";
import { Tab } from "../colorTabs/types";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import ColorTabs from "../colorTabs/ColorTabs";
import ComponentLayout from "../layout/ComponentLayout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Dialog from "../dialog/Dialog";
import NewDashboardContentModal from "./NewDashboardContentModal";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import useDialog from "../../hooks/useDialog";
import useLanguage from "../../context/LanguageProvider";
import useTabs from "../../hooks/useTabs";
import useInfoContext from "../../hooks/useInfoContext";
import NoDataBox from "../layout/NoDataBox";

const Dashboard = () => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const [openNewDashboardModal, toggleNewDashboardModal] = useDialog();
  const [tabValue, handleChange, setTabValue] = useTabs("");
  const [tabs, setTabs] = useState<Tab[]>([{ value: "", label: "" }]);
  const {
    data: dataGetDashboards,
    isLoading: isLoadingGetDashboards,
    isError: isErrorGetDashboards,
    refetch: refetchGetDashboards,
    isFetching: isFetchingGetDashboards,
  } = useApiQuery(DASHBOARDS_URL, false, t("dashboard.get.error"));

  useEffect(() => {
    if (dataGetDashboards && dataGetDashboards.length > 0) {
      const dashboards: DashboardType[] = dataGetDashboards || [];
      const tabs: Tab[] = dashboards.map((dashboard: DashboardType) => {
        return {
          value: dashboard.id.toString(),
          label: dashboard.descripcion,
        };
      });
      setTabs(tabs);
      setTabValue(tabs[0].value);
    }
  }, [dataGetDashboards]);

  return (
    <ComponentLayout
      title="Dashboard tableros"
      icon={<DashboardIcon />}
      rightComponent={
        userRolData &&
        (userRolData.dashboards.visualizacion_y_creacion ||
          userRolData.dashboards.visualizacion_y_edicion) && (
          <Button
            title={"Crear/" + t("dashboardTexts.dashboard.addDashboard")}
            color="blue-greeny"
            type="button"
            onClick={() => toggleNewDashboardModal()}
          />
        )
      }
    >
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minWidth: "75vw",
        }}
      >
        {isLoadingGetDashboards && (
          <SimpleBackdrop
            open={isLoadingGetDashboards || isFetchingGetDashboards}
            message={t("dashboard.get.loading")}
          />
        )}
        <Dialog
          open={openNewDashboardModal}
          handleClose={toggleNewDashboardModal}
          title={t("dashboardTexts.dashboard.addNewDashboard")}
          maxWidth="lg"
        >
          <NewDashboardContentModal
            dataGetDashboards={dataGetDashboards}
            refetchGetDashboards={refetchGetDashboards}
            toggleNewDashboardModal={toggleNewDashboardModal}
          />
        </Dialog>
        {dataGetDashboards && dataGetDashboards.length > 0 ? (
          <Box sx={{ width: "100%", marginRight: "50px" }}>
            <ColorTabs
              value={tabValue}
              handleChange={handleChange}
              tabs={tabs}
              scrollable
              marginBottom="10px"
            />
          </Box>
        ) : (
          <NoDataBox text={"No hay tableros disponibles para mostrar."} />
        )}
        <Stack width="100%" height="100%" sx={{ maxHeight: "auto" }}>
          {isLoadingGetDashboards ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : isErrorGetDashboards ? (
            <NoDataBox text={t("dashboard.get.error")} />
          ) : (
            <Stack>
              {dataGetDashboards && tabValue && (
                <iframe
                  src={
                    findDashboardById(dataGetDashboards, tabValue)?.source || ""
                  }
                  frameBorder="0"
                  style={{
                    width: "100% !important",
                    height: "100vh",
                    maxHeight: "auto !important",
                  }}
                ></iframe>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </ComponentLayout>
  );
};

export default Dashboard;
