import { getFileColumns1, getValueLabel1 } from "../../utils";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { useCompanyParamProvider } from "../../../../context/CompanyParamProvider";
import { useEffect, useRef } from "react";
import AddButton from "../../../buttons/AddButton";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Grid2 from "@mui/material/Unstable_Grid2";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField/TextField";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
interface RelationshipInputProps {
  setNewRelationship: any;
  newName: string;
  setNewName: any;
  relationshipNameRef: any;
  newRelationship: any;
  fileBaseSelectedOption: any;
  fileBaseOptionChangeHandler: any;
  fileBaseSelectRef: any;
  columnBaseSelectRef: any;
  columnBaseSelectedOption: any;
  columnBaseOptionChangeHandler: any;
}

const RelationshipInput = ({
  setNewRelationship,
  newName,
  setNewName,
  relationshipNameRef,
  newRelationship,
  fileBaseSelectedOption,
  fileBaseOptionChangeHandler,
  fileBaseSelectRef,
  columnBaseSelectRef,
  columnBaseSelectedOption,
  columnBaseOptionChangeHandler,
}: RelationshipInputProps) => {
  const { t } = useLanguage();

  const fileSelectRef: any = useRef(null);
  const columnSelectRef: any = useRef(null);
  const relTypeRef: any = useRef(null);
  const { message, setMessage, relationships, dataGetFilesListParams} =
    useCompanyParamProvider();

  const onFileColumn = (data) => {
    setColumnSelectedOption(undefined);
    columnSelectRef?.current?.clearValue();
  };

  const [fileSelectedOption, setFileSelectedOption, fileOptionChangeHandler] =
    useSingleSelect((data) => onFileColumn(data), undefined);

  const [
    columnSelectedOption,
    setColumnSelectedOption,
    columnOptionChangeHandler,
  ] = useSingleSelect(undefined, undefined);

  const [relTypeOption, setRelTypeOption, relTypeChangeHandler] =
    useSingleSelect();

  useEffect(() => {    
    setNewRelationship({
      nombre: newName,
      involucrados: [],
    });
  }, []);

  const handleChangeName = (e: any) => {
    setNewName(e.target.value);
    setNewRelationship((prev: any) => {
      return { ...prev, nombre: e.target.value };
    });
    const namesList = relationships?.map((item: any) =>
      item.nombre.trim().toLowerCase()
    );
    if (namesList?.includes(e.target.value.trim().toLowerCase())) {
      setMessage({
        type: "error",
        text: t("general.repeatedName"),
      });
    } else {
      setMessage({
        type: "",
        text: "",
      });
    }
  };

  const handleAddRow = () => {
    if (!fileSelectedOption || !columnSelectedOption) return;
    const fileColumnList = newRelationship.involucrados?.map(
      (item: any) => item.archivo.label + item.columna.label
    );
    const newFileColumn =
      fileSelectedOption?.label + columnSelectedOption?.label;
    if (fileColumnList?.includes(newFileColumn)) {
      setMessage({
        type: "warning",
        text: t("companyParam.existentCombination"),
      });
      return;
    } else {
      setMessage({
        type: "",
        text: "",
      });
    }

    setNewRelationship((prev) => {
      return {
        ...prev,
        involucrados: [
          ...prev.involucrados,
          {
            archivo: fileSelectedOption,
            columna: columnSelectedOption,
            tipo_relacion: relTypeOption,
          },
        ],
      };
    });
    setFileSelectedOption(undefined);
    setColumnSelectedOption(undefined);
    setRelTypeOption(undefined);
    fileSelectRef.current.clearValue();
    columnSelectRef.current.clearValue();
    relTypeRef.current.clearValue();
  };

  const relTypeOptions = [
    { value: "1a1", label: "1 a 1" },
    {
      value: "1aN",
      label: "1 a N",
    },
    {
      value: "Na1",
      label: "N a 1",
    },
    {
      value: "NaN",
      label: "N a N",
    },
  ];

  return (
    <Grid2 container direction={"row"} xs={12} mt={2} mb={2} gap={2}>
      <Grid2 xs={12}>
        <TextField
          type="text"
          ref={relationshipNameRef}
          value={newName}
          onChange={handleChangeName}
          variant="outlined"
          fullWidth
          required
          label={t("companyParam.relationshipName")}
          autoFocus
          size="small"
          error={
            message.type === "error" && (message?.text !== "" || newName === "")
          }
          helperText={message.type === "error" && message?.text}
          FormHelperTextProps={{
            sx: {
              color: "var(--text-error)",
              margin: 0,
            },
          }}
        />
      </Grid2>
      {/* Archivo Base */}
      <Grid2 xs={0.3}></Grid2>
      <Grid2 xs={4.2}>
        <Stack sx={{ width: "100%" }}>
          <FormSelectLayout title={t("Archivo base")} required={false}>
            <Select
              reference={fileBaseSelectRef}
              isClearable
              styles={selectStyles(fileBaseSelectedOption)}
              options={getValueLabel1(dataGetFilesListParams)}
              className="pp_select_schema"
              onChange={fileBaseOptionChangeHandler}
              closeMenuOnSelect
              isSearchable
              placeholder={t("companyParam.chooseAnOption")}
            />
          </FormSelectLayout>
        </Stack>
      </Grid2>

      <Grid2 xs={4.2}>
        {
          <Stack sx={{ width: "100%" }}>
            <FormSelectLayout title={t("companyParam.column")} required={false}>
              <Select
                reference={columnBaseSelectRef}
                isClearable
                styles={selectStyles(columnBaseSelectedOption)}
                options={getFileColumns1(
                  dataGetFilesListParams,
                  fileBaseSelectedOption
                )}
                className="pp_select_schema"
                onChange={columnBaseOptionChangeHandler}
                closeMenuOnSelect
                isSearchable
                placeholder={t("companyParam.chooseAnOption")}
              />
            </FormSelectLayout>
          </Stack>
        }
      </Grid2>
      <Grid2 xs={2}></Grid2>
      <Grid2 xs={0.5}></Grid2>
      {fileBaseSelectedOption && columnBaseSelectedOption && (
        <>
          <Grid2 xs={0.3}></Grid2>
          <Grid2 xs={4.2}>
            <Stack sx={{ width: "100%" }}>
              <FormSelectLayout title={t("companyParam.file")} required={false}>
                <Select
                  reference={fileSelectRef}
                  isClearable
                  styles={selectStyles(fileSelectedOption)}
                  options={getValueLabel1(dataGetFilesListParams)}
                  className="pp_select_schema"
                  onChange={fileOptionChangeHandler}
                  closeMenuOnSelect
                  isSearchable
                  placeholder={t("companyParam.chooseAnOption")}
                />
              </FormSelectLayout>
            </Stack>
          </Grid2>
          <Grid2 xs={4.2}>
            {
              <Stack sx={{ width: "100%" }}>
                <FormSelectLayout
                  title={t("companyParam.column")}
                  required={false}
                >
                  <Select
                    reference={columnSelectRef}
                    isClearable
                    styles={selectStyles(columnSelectedOption)}
                    options={getFileColumns1(
                      dataGetFilesListParams,
                      fileSelectedOption
                    )}
                    className="pp_select_schema"
                    onChange={columnOptionChangeHandler}
                    closeMenuOnSelect
                    isSearchable
                    placeholder={t("companyParam.chooseAnOption")}
                  />
                </FormSelectLayout>
              </Stack>
            }
          </Grid2>
          <Grid2 xs={2}>
            {
              <Stack sx={{ width: "100%" }}>
                <FormSelectLayout
                  title={t("Tipo de relación")}
                  required={false}
                >
                  <Select
                    reference={relTypeRef}
                    isClearable
                    styles={selectStyles(relTypeOption)}
                    options={relTypeOptions}
                    className="pp_select_schema"
                    onChange={relTypeChangeHandler}
                    closeMenuOnSelect
                    isSearchable
                    placeholder={t("companyParam.chooseAnOption")}
                  />
                </FormSelectLayout>
              </Stack>
            }
          </Grid2>
          <Grid2 xs={0.5}>
            <Stack justifyContent={"flex-end"} height="85%">
              <AddButton
                onClick={() => handleAddRow()}
                disabled={
                  columnSelectedOption === undefined ||
                  fileSelectedOption === undefined ||
                  relTypeOption === undefined
                }
              />
            </Stack>
          </Grid2>
        </>
      )}
    </Grid2>
  );
};

export default RelationshipInput;
