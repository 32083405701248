import { fileOptions } from "../../criterias/configCriteria/selectOptions";
import { selectStyles } from "../../criterias/configCriteria/select.styles";
import { Slider, Stack } from "@mui/material";
import { TABLAS_URL } from "../../../api/axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import CustomButton from "../../buttons/Button";
import Dialog from "../../dialog/Dialog";
import FormSelectLayout from "../../forms/FormSelectLayout";
import Select from "../../forms/Select";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import ToggleSort from "../../criterias/ToggleSort";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import useSingleSelect from "../../../hooks/useSingleSelect";

const sourcesToImportOptions = (t) => [
  { value: "ANALISIS", label: t("dashboard.analysis") },
  { value: "SIMULACION", label: t("dashboard.simulation") },
  { value: "SALES_FORECAST", label: t("dashboard.salesForecast") },
  { value: "COSTS_FORECAST", label: t("dashboard.costsForecast") },
];

const typeOptions = (t) =>  [
  { value: "grafico", label: t("dashboard.chart") },
  { value: "indicador", label: t("dashboard.indicator") },
  { value: "tabla", label: t("dashboard.table") }
];

const getLabelByValue = (value, t) => {
  const options = sourcesToImportOptions(t);
  const option = options.find((option) => option.value === value);
  return option ? option.label : null;
};

const NewElementModal = ({
  ELEMENTS_URL,
  ELEMENT_URL,
  GRAFICOS_ARCHIVOS_URL,
  INDICADORES_ARCHIVOS_URL,
  actualUrl,
  refetchGetAnalysisViews,
  dashboardType,
}) => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const {
    tabValue,
    viewTabValue,
    setViewTabValue,
    openNewElementModal,
    toggleNewElementModal,
    elementToEdit,
    setElementToEdit,
    analysisViews,
  } = useViewDashboard();

  const previousUrl = localStorage.getItem("previousUrl");
  const newElementConfig =
    localStorage.getItem("newElementConfig") &&
    JSON.parse(String(localStorage.getItem("newElementConfig")));

  const [newElementAction, setNewElementAction] = useState("create");

  const [element, setElement] = useState({
    tipo: undefined,
    ancho: 12,
    alto: 1,
    pk_elemento: undefined,
  } as any);

  const onTypeChange = () => {
    setElementSelectedOption(undefined);
    elementSelectRef?.current?.clearValue();
    setSourcesToImportSelectedOption(undefined);
    sourcesToImportRef?.current?.clearValue();
  };

  const typeSelectRef: any = useRef(null);
  const elementSelectRef: any = useRef(null);
  const sourcesToImportRef: any = useRef(null);
  const [typeSelectedOption, setTypeSelectedOption, typeOptionsChangeHandler] =
    useSingleSelect(
      () => onTypeChange(),
      newElementConfig ? newElementConfig.tipo : undefined
    );

  const onSourceChange = () => {
    setElementSelectedOption(undefined);
    elementSelectRef?.current?.clearValue();
  };

  const [
    sourcesToImportSelectedOption,
    setSourcesToImportSelectedOption,
    sourcesToImportOptionsChangeHandler,
  ] = useSingleSelect(() => onSourceChange());

  const getData = () => {
    if (typeSelectedOption?.value === "grafico") {
      getDataCharts();
    } else if (typeSelectedOption?.value === "indicador") {
      getDataKpis();
    } else {
      getDataTables();
    }
  };

  useEffect(() => {
    if (
      sourcesToImportSelectedOption &&
      ["ANALISIS", "SIMULACION", "SALES_FORECAST", "COSTS_FORECAST"].includes(
        String(sourcesToImportSelectedOption.value)
      )
    ) {
      getData();
    }
  }, [sourcesToImportSelectedOption, typeSelectedOption, elementToEdit]);

  useEffect(() => {
    if (typeSelectedOption) {
      const tipo = typeSelectedOption.value;
      const alto = tipo === "indicador" ? 1 : 3;
      setElement((prev: any) => ({
        ...prev,
        tipo,
        alto: elementToEdit?.tipo === tipo ? elementToEdit.alto : alto,
      }));
    }
  }, [typeSelectedOption]);

  const [
    elementSelectedOption,
    setElementSelectedOption,
    elementOptionsChangeHandler,
  ] = useSingleSelect(undefined);

  useEffect(() => {
    if (elementToEdit) {
      setTypeSelectedOption({
        value: elementToEdit.tipo,
        label:
          elementToEdit.tipo === "grafico"
            ? t("dashboard.chart")
            : elementToEdit.tipo === "indicador"
            ? t("dashboard.indicator")
            : t("dashboard.table"),
      });
      setElementSelectedOption({
        value: elementToEdit.info.id,
        label: elementToEdit.info.titulo,
      });
      setSourcesToImportSelectedOption({
        value: elementToEdit.info.archivo,
        label: getLabelByValue(elementToEdit.info.archivo, t),
      });
      setElement({
        tipo: elementToEdit.tipo,
        alto: elementToEdit.alto,
        ancho: elementToEdit.ancho,
        pk_elemento: elementToEdit.info.id,
      });
    }
  }, [elementToEdit]);

  const {
    data: dataGetCharts,
    callApi: getDataCharts,
    isLoading: isLoadingGetDataCharts,
  } = useApi(
    `${GRAFICOS_ARCHIVOS_URL}?tipo=${
      sourcesToImportSelectedOption && sourcesToImportSelectedOption.value
    }`,
    "GET",
    t("report.getKpis.error"),
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    data: dataGetKpis,
    callApi: getDataKpis,
    isLoading: isLoadingGetDataKpis,
  } = useApi(
    `${INDICADORES_ARCHIVOS_URL}?tipo=${
      sourcesToImportSelectedOption && sourcesToImportSelectedOption.value
    }`,
    "GET",
    t("report.getKpis.error"),
    undefined,
    undefined,
    undefined,
    false
  );

  const {
    data: dataGetTables,
    callApi: getDataTables,
    isLoading: isLoadingGetDataTables,
  } = useApi(
    `${TABLAS_URL}?tipo=${
      sourcesToImportSelectedOption && sourcesToImportSelectedOption.value
    }`, //todavia no está la parte de Delta
    "GET",
    t("report.getKpis.error"),
    undefined,
    undefined,
    undefined,
    false
  );

  const elementWidthChange = (event: any, newValue: number | number[]) => {
    setElement({ ...element, ancho: newValue as number });
  };

  const elementHeightChange = (event: any, newValue: number | number[]) => {
    if (Number(newValue) <= 3 && typeSelectedOption?.value !== "indicador") {
      setElement({ ...element, alto: 3 as number });
    } else {
      setElement({ ...element, alto: newValue as number });
    }
  };

  const handleCleanNewElement = () => {
    setTypeSelectedOption(undefined);
    setElementSelectedOption(undefined);
    setSourcesToImportSelectedOption(undefined);
    typeSelectRef?.current?.clearValue();
    elementSelectRef?.current?.clearValue();
    sourcesToImportRef?.current?.clearValue();
    setElement({
      tipo: "indicador",
      ancho: 12,
      alto: 1,
      pk_elemento: undefined,
    });
  };

  const onSuccessPostNewElement = () => {
    refetchGetAnalysisViews();
    closeNewElementModal();
  };

  const { isLoading: isLoadingPostNewElement, callApi: postNewElement } =
    useApi(
      ELEMENTS_URL(viewTabValue),
      "POST",
      t("dashboard.newElement.codes"),
      undefined,
      onSuccessPostNewElement
    );

  const onSuccessPutElement = () => {
    refetchGetAnalysisViews();
    closeNewElementModal();
  };

  const { isLoading: isLoadingPutElement, callApi: putElement } = useApi(
    `${ELEMENT_URL(viewTabValue, elementToEdit?.id)}`,
    "PUT",
    t("dashboard.editElement.codes"),
    undefined,
    onSuccessPutElement
  );

  const handleNewElement = () => {
    if (typeSelectedOption && elementSelectedOption) {
      const newElement = {
        tipo: typeSelectedOption?.value,
        alto: element.alto,
        ancho: element.ancho,
        pk_elemento: elementSelectedOption?.value,
      };
      if (elementToEdit) {
        putElement(undefined, newElement);
      } else {
        postNewElement(undefined, newElement);
      }
    }
  };

  const closeNewElementModal = () => {
    toggleNewElementModal();
    setTimeout(() => {
      setElementToEdit(undefined);
      handleCleanNewElement();
    }, 1000);
  };

  useEffect(() => {
    //Este se ejecuta cundo se hace el success del postelement en config
    if (newElementConfig && !previousUrl) {
      /* setTabValue(newElementConfig.tabValue) */
      setViewTabValue(newElementConfig.viewTabValue);
    } else if (newElementConfig && previousUrl) {
      //Esto se esjecuta cuando vuelve desde config hacia atrás
      toggleNewElementModal();
      setElement({
        ancho: newElementConfig.ancho,
        alto: newElementConfig.alto,
      });
    } else {
      setTypeSelectedOption(undefined);
    }
    localStorage.removeItem("tabValueEndpoint");
  }, []);

  const simulationId = localStorage.getItem("simulationId") || undefined;
  const salesForecastId = localStorage.getItem("salesForecastId") || undefined;
  const costsForecastId = localStorage.getItem("costsForecastId") || undefined;

  const navigateNewDashboard = () => {
    const analysisIdToFind = simulationId || salesForecastId || costsForecastId || tabValue;

    const newElementConfig = {
      tipo: typeSelectedOption,
      ancho: element.ancho,
      alto: element.alto,
      url: actualUrl,
      archivo: {
        value: analysisViews?.data?.find(
          (analysis) => analysis.id == analysisIdToFind
        )?.archivo_id,
        label: analysisViews?.data?.find(
          (analysis) => analysis.id == analysisIdToFind
        )?.nombre,
      },
      viewTabValue: viewTabValue,
      tabValue: tabValue,
    };
    localStorage.setItem("newElementConfig", JSON.stringify(newElementConfig));
    localStorage.setItem("configMode", "CREATE");
    localStorage.setItem("tabValueEndpoint", dashboardType);
    if (typeSelectedOption?.value === "grafico") {
      navigate("/configuracion/analisis/grafico");
    }
    if (typeSelectedOption?.value === "indicador") {
      navigate("/configuracion/analisis/indicador");
    }
    if (typeSelectedOption?.value === "tabla") {
      navigate("/configuracion/analisis/tabla");
    }
  };

  return (
    <Dialog
      open={openNewElementModal}
      handleClose={closeNewElementModal}
      title={
        elementToEdit
          ? t("dashboard.putElement")
          : t("dashboard.createNewElement")
      }
    >
      <SimpleBackdrop
        open={
          isLoadingGetDataTables ||
          isLoadingGetDataCharts ||
          isLoadingGetDataKpis
        }
        message={t("general.loading")}
      />
      <SimpleBackdrop
        open={isLoadingPostNewElement}
        message={t("dashboard.newElement.loading")}
      />
      <SimpleBackdrop
        open={isLoadingPutElement}
        message={t("dashboard.editElement.loading")}
      />
      <Stack
        sx={{
          width: "100%",
        }}
      >
        {!elementToEdit && (
          <Stack
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ToggleSort
              firstState={"create"}
              secondState={"import"}
              state={newElementAction}
              setState={setNewElementAction}
              firstTitle={t("dashboard.create")}
              secondTitle={t("dashboard.import")}
            />
          </Stack>
        )}
        <FormSelectLayout title={t("dashboard.typeOfElement")} required={true}>
          <Select
            reference={typeSelectRef}
            styles={selectStyles(typeSelectedOption)}
            options={typeOptions(t)}
            className="cc_select"
            name="type"
            onChange={typeOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t("dashboard.selectType")}
            defaultValue={
              typeSelectedOption
                ? typeSelectedOption
                : elementToEdit
                ? {
                    value: elementToEdit.tipo,
                    label:
                      elementToEdit.tipo === "grafico"
                        ? t("dashboard.chart")
                        : elementToEdit.tipo === "indicador"
                        ? t("dashboard.indicator")
                        : t("dashboard.table"),
                  }
                : undefined
            }
            isClearable
            isDisabled={elementToEdit}
          />
        </FormSelectLayout>

        {typeSelectedOption &&
          (elementToEdit || newElementAction === "import") && (
            <>
              <FormSelectLayout
                title={t("dashboard.importFrom")}
                required={true}
              >
                <Select
                  reference={sourcesToImportRef}
                  styles={selectStyles(sourcesToImportSelectedOption)}
                  options={sourcesToImportOptions(t)}
                  className="cc_select"
                  name="type"
                  onChange={sourcesToImportOptionsChangeHandler}
                  closeMenuOnSelect
                  placeholder={t("dashboard.selectType")}
                  isClearable
                  defaultValue={sourcesToImportSelectedOption}
                />
              </FormSelectLayout>
              <FormSelectLayout
                title={`${typeSelectedOption?.label} ${t("dashboard.toUse")} `}
                required={true}
              >
                <Select
                  reference={elementSelectRef}
                  styles={selectStyles(elementSelectedOption)}
                  options={
                    typeSelectedOption?.value === "grafico"
                      ? fileOptions(dataGetCharts)
                      : typeSelectedOption?.value === "indicador"
                      ? fileOptions(dataGetKpis)
                      : fileOptions(dataGetTables)
                  }
                  className="cc_select"
                  name="type"
                  onChange={elementOptionsChangeHandler}
                  closeMenuOnSelect
                  placeholder={t("dashboard.selectType")}
                  defaultValue={
                    elementToEdit
                      ? {
                          value: elementToEdit.info.id,
                          label: elementToEdit.info.titulo,
                        }
                      : undefined
                  }
                  isClearable
                />
              </FormSelectLayout>
            </>
          )}
      </Stack>
      <Stack width="95%" mt="15px">
        <FormSelectLayout
          title={t("dashboard.columnsQuantity")}
          required={false}
        >
          <Slider
            value={element.ancho}
            getAriaValueText={(value: number) => value.toString()}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={12}
            sx={{
              height: "2px",
            }}
            onChange={(e, value: number | number[]) =>
              elementWidthChange(e, value)
            }
          />
        </FormSelectLayout>
      </Stack>
      <Stack width="95%" mt="15px">
        <FormSelectLayout title={t("dashboard.rowsQuantity")} required={false}>
          <Slider
            value={element.alto}
            getAriaValueText={(value: number) => value.toString()}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={12}
            sx={{
              height: "2px",
            }}
            onChange={(e, value: number | number[]) =>
              elementHeightChange(e, value)
            }
          />
        </FormSelectLayout>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <CustomButton
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={() => closeNewElementModal()}
        />
        <CustomButton
          title={t("general.clean")}
          color="magenta"
          type="button"
          onClick={() => {
            handleCleanNewElement();
          }}
        />
        <CustomButton
          title={
            elementToEdit
              ? t("general.accept")
              : newElementAction === "create"
              ? t("general.next")
              : t("general.accept")
          }
          color="blue"
          type="button"
          disabled={
            (newElementAction === "import" && !elementSelectedOption) ||
            !typeSelectedOption
          }
          onClick={
            newElementAction === "create" && !elementToEdit
              ? navigateNewDashboard
              : handleNewElement
          }
        />
      </Stack>
    </Dialog>
  );
};

export default NewElementModal;
