import "./styles.css";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { icon_table_styles } from "../../styles/app-styles";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";

export const transformDataDetail: (
  data: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  minWidth?: number,
  handleClickStatusIcon?: any
) => {
  columns: GridColDef<any, any, any>[];
  rows: any;
} = (
  data: any,
  optionalColumns?: any[],
  transformColumnNames?: any,
  minWidth?: number,
  handleClickStatusIcon?: any
) => {
  const tableColumns = optionalColumns
    ? optionalColumns
    : data && data.length > 0
    ? Object.keys(data[0])
    : [];

  const arrayColumns = tableColumns
    ?.filter((item: any) => item !== "id")
    ?.map((columna: string) => ({
      field: columna,
      headerClassName: "es_header_column",
      headerName: columna,
      description: columna,
      minWidth: minWidth ? minWidth : 100,
      flex: columna === "fecha" || columna === "bucket" ? 2 : 1,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong
          style={{
            color: "white",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {transformColumnNames ? transformColumnNames(columna) : columna}
        </strong>
      ),
      renderCell: (params: any) => (
        <Stack direction="row" alignItems="center">
          {params.value === "exito" && (
            <Tooltip title={"Se ejecutó correctamente"}>
              <Box>
                <CheckCircleIcon
                  onClick={() => handleClickStatusIcon(columna, params.row)}
                  sx={icon_table_styles(
                    "var(--icon-success)",
                    "var(--icon-success)"
                  )}
                />
              </Box>
            </Tooltip>
          )}
          {params.value === "pendiente" && (
            <Tooltip title={"En proceso"}>
              <Box>
                <HourglassFullIcon
                  onClick={() => handleClickStatusIcon(columna, params.row)}
                  sx={icon_table_styles("var(--icon-info)", "var(--icon-info)")}
                />
              </Box>
            </Tooltip>
          )}
          {params.value === "error" && (
            <Tooltip title={"Hubo un error"}>
              <Box>
                <HighlightOffIcon
                  onClick={() => handleClickStatusIcon(columna, params.row)}
                  sx={icon_table_styles(
                    "var(--icon-error)",
                    "var(--icon-error)"
                  )}
                />
              </Box>
            </Tooltip>
          )}
          {params.value === "alerta" && (
            <Tooltip title={"Hubo una advertencia"}>
              <Box sx={{ padding: "0 2px" }}>
                <WarningIcon
                  onClick={() => handleClickStatusIcon(columna, params.row)}
                  sx={icon_table_styles(
                    "var(--icon-warning)",
                    "var(--icon-warning)"
                  )}
                />
              </Box>
            </Tooltip>
          )}

          {params.value !== "error" &&
            params.value !== "alerta" &&
            params.value !== "exito" &&
            params.value !== "pendiente" && (
              <Typography>{params.value}</Typography>
            )}
        </Stack>
      ),
    }));

  const arrayRows =
    data && data.length > 0
      ? data?.map((fila: any) => {
          const obj: any = {};
          for (const key in fila) {
            obj[key] = fila[key];
          }
          return obj;
        })
      : [];

  const columns: GridColDef[] = arrayColumns.map((col) => ({
    ...col,
    headerAlign: "center",
    align: "center",
  }));

  const rows = arrayRows?.map((row: any) => ({
    ...row,
  }));

  return { columns, rows };
};
