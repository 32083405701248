import Stack from "@mui/material/Stack";
import Dialog from "../../dialog/Dialog";
import useLanguage from "../../../context/LanguageProvider";
import { Divider, Typography } from "@mui/material";
import useApi from "../../../hooks/useApi";
import { GET_ANALYSIS_URL } from "../../../api/axios";
import { useEffect } from "react";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";

interface ViewInfoCostForecastModalProps {
  data: any;
  open: boolean;
  handleClose: () => void;
}

const ViewInfoCostForecastModal = ({
  data,
  open,
  handleClose,
}: ViewInfoCostForecastModalProps) => {
  const { t } = useLanguage();

  const {
    isLoading: isLoadingGetAnalysisById,
    data: dataGetAnalysisById,
    callApi: getAnalysisById,
  } = useApi(
    undefined,
    "GET",
    t("dataModel.getAnalysis.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    data &&
      open &&
      getAnalysisById(GET_ANALYSIS_URL(Number(data.analisis_id), true));
  }, [data, open]);

  return (
    <>
      <SimpleBackdrop
        open={isLoadingGetAnalysisById}
        message={t("general.loading")}
      />
      {data && dataGetAnalysisById && (
        <Dialog
          open={open}
          handleClose={handleClose}
          title={t("deltaTexts.costForecast.title")}
          maxWidth={"lg"}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            padding="0 50px 30px 50px"
            position="relative"
            sx={{
              width: "720px",
              height: "100%",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                wordBreak: "break-word",
              }}
              color="var(--text-main)"
            >
              {t("general.analysis")}:{" "}
              <span
                style={{
                  color: "var(--text-disabled)",
                  wordBreak: "break-all",
                }}
              >
                {data && data.analisis}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                wordBreak: "break-word",
              }}
              color="var(--text-main)"
            >
              {t(
                "deltaTexts.salesForecast.salesForecastCard.enabledPeriodsNonExecuted"
              )}
              :{" "}
              {
                <span
                  style={{
                    color: "var(--text-disabled)",
                    wordBreak: "break-all",
                  }}
                >
                  {data.periodos_faltantes && data.periodos_faltantes.length > 0
                    ? data.periodos_faltantes.map((period, idx) => {
                        if (data.periodos_faltantes.length - 1 === idx) {
                          return period + ".";
                        } else {
                          return period + ", ";
                        }
                      })
                    : t("deltaTexts.salesForecast.salesForecastCard.none")}
                </span>
              }
            </Typography>
            <Divider sx={{ margin: 2, width: "100%" }} />
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                wordBreak: "break-word",
              }}
              color="var(--text-main)"
            >
              {t(
                "deltaTexts.salesForecast.newSalesForecastModal.initialPeriod"
              )}
              :{" "}
              {
                <span
                  style={{
                    color: "var(--text-disabled)",
                    wordBreak: "break-all",
                  }}
                >
                  {data.periodo_inicial.label || "1"}
                </span>
              }
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                textAlign: "left",
                textTransform: "uppercase",
                fontWeight: "700",
                letterSpacing: "-0.5px",
                wordBreak: "break-word",
              }}
              color="var(--text-main)"
            >
              {t(
                "deltaTexts.salesForecast.newSalesForecastModal.periodsToForecast"
              )}
              :{" "}
              {
                <span
                  style={{
                    color: "var(--text-disabled)",
                    wordBreak: "break-all",
                  }}
                >
                  {data.cantidad_periodos || "1"}
                </span>
              }
            </Typography>
          </Stack>
        </Dialog>
      )}
    </>
  );
};

export default ViewInfoCostForecastModal;
