import * as React from 'react';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { Typography, Stack } from "@mui/material";
import useLanguage from '../../context/LanguageProvider';

const Input = styled(MuiInput)`
    width: 30px;
`;

interface InputSliderProps {
    variableValue: number,
    setVariableValue: any,
    width?: string,
    nonFixedNonVariable?: boolean,
    isDisabled?: boolean
}

const InputSlider = ({
    variableValue,
    setVariableValue,
    width,
    nonFixedNonVariable,
    isDisabled
}: InputSliderProps) => {

    const { t } = useLanguage();
    const fixedValue = 100;
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setVariableValue(newValue as number);
    };

    const handleInputChangeVariableValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(event.target.value) > 100) {
            setVariableValue(100)
        } else {
            setVariableValue(event.target.value === '' ? 0 : Number(event.target.value));
        }

    };

    const handleBlur = () => {
        if (variableValue < 0) {
            setVariableValue(0);
        } else if (variableValue > 100) {
            setVariableValue(100);
        }
    };


    return (
        <Stack sx={{ width: width, flexDirection: "row", gap: "20px", alignItems: "center", pt: "5px" }}>
            {!nonFixedNonVariable ? <Stack sx={{
                flexDirection: 'row',
                width: "200px",
                gap: "10px",
                justifyContent: "flex-end",
            }}>
                <Typography sx={{ color: "var(--text-main)" }}>
                    {fixedValue - variableValue} %  {t("simulatorTexts.inputSlider.fixed")}
                </Typography>
            </Stack>
                : ""}
            <Slider
                sx={{ width: "500px" }}
                value={typeof variableValue === 'number' ? variableValue : 0}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
                disabled={isDisabled}
            />
            <Stack sx={{
                flexDirection: 'row',
                width: !nonFixedNonVariable ? "200px" : "100px",
                gap: "10px",
            }}>
                <Input
                disabled={isDisabled}
                    value={variableValue} // Agregamos el símbolo "%" después del valor                    
                    size="small"
                    onChange={handleInputChangeVariableValue}
                    onBlur={handleBlur}
                    sx={{
                        color: "var(--text-main)"
                    }}
                    inputProps={{
                        step: 10,
                        min: 0,
                        max: 100,
                        type: 'text', // Cambiamos el tipo a 'text' para permitir el símbolo "%"
                        'aria-labelledby': 'input-slider',
                    }}
                />
                <Typography sx={{ color: "var(--text-main)" }}>
                    % {!nonFixedNonVariable ? t("simulatorTexts.inputSlider.variable") : ""}
                </Typography>
            </Stack>
        </Stack>
    );
}

export default InputSlider