import { form_subtitle_styles } from "../../../../styles/app-styles";
import { monthOptions, getYears } from "../data";
import Button from "../../../buttons/Button";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Grid2 from "@mui/material/Unstable_Grid2";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import {
  optionalSelectStyles,
  selectStyles,
} from "../../../criterias/configCriteria/select.styles";

const AddPeriodsModal = ({ handleToggleModal, setInfo }) => {
  const { t } = useLanguage();
  const [
    sinceMonthSelectedOption,
    setSinceMonthSelectedOption,
    sinceMonthOptionChangeHandler,
  ] = useSingleSelect();
  const [
    sinceYearSelectedOption,
    setSinceYearSelectedOption,
    sinceYearOptionChangeHandler,
  ] = useSingleSelect();
  const [
    untilMonthSelectedOption,
    setUntilMonthSelectedOption,
    untilMonthOptionChangeHandler,
  ] = useSingleSelect();
  const [
    untilYearSelectedOption,
    setUntilYearSelectedOption,
    untilYearOptionChangeHandler,
  ] = useSingleSelect();

  const handleApply = () => {
    setInfo({
      mes_inicial: sinceMonthSelectedOption?.value,
      año_inicial: sinceYearSelectedOption?.value,
      mes_final: untilMonthSelectedOption?.value,
      año_final: untilYearSelectedOption?.value,
    });
    handleToggleModal();
  };

  return (
    <Stack
      sx={{
        width: "60vw",
        minHeight: "400px",
        justifyContent: "space-between",
      }}
    >
      <Grid2 container direction={"row"} xs={12} gap={2}>
        <Grid2 xs={12}>
          <Typography sx={{ ...form_subtitle_styles, margin: 0 }}>
            {t("general.since")}
          </Typography>
        </Grid2>
        <Grid2 xs={5.8}>
          <FormSelectLayout title={t("general.month")} required={false}>
            <Select
              isClearable
              styles={optionalSelectStyles(sinceMonthSelectedOption)}
              options={monthOptions}
              className="pp_select_schema"
              onChange={sinceMonthOptionChangeHandler}
              closeMenuOnSelect
              isSearchable
              placeholder={t("general.selectOption")}
            />
          </FormSelectLayout>
        </Grid2>
        <Grid2 xs={5.8}>
          <FormSelectLayout title={t("general.year")} required={true}>
            <Select
              isClearable
              styles={selectStyles(sinceYearSelectedOption)}
              options={getYears()}
              className="pp_select_schema"
              onChange={sinceYearOptionChangeHandler}
              closeMenuOnSelect
              isSearchable
              placeholder={t("general.selectOption")}
            />
          </FormSelectLayout>
        </Grid2>
        <Grid2 xs={12}>
          <Typography sx={{ ...form_subtitle_styles, margin: 0 }}>
            {t("general.until")}
          </Typography>
        </Grid2>
        <Grid2 xs={5.8}>
          <FormSelectLayout title={t("general.month")} required={false}>
            <Select
              isClearable
              styles={optionalSelectStyles(untilMonthSelectedOption)}
              options={monthOptions}
              className="pp_select_schema"
              onChange={untilMonthOptionChangeHandler}
              closeMenuOnSelect
              isSearchable
              placeholder={t("general.selectOption")}
            />
          </FormSelectLayout>
        </Grid2>
        <Grid2 xs={5.8}>
          <FormSelectLayout title={t("general.year")} required={true}>
            <Select
              isClearable
              styles={selectStyles(untilYearSelectedOption)}
              options={getYears()}
              className="pp_select_schema"
              onChange={untilYearOptionChangeHandler}
              closeMenuOnSelect
              isSearchable
              placeholder={t("general.selectOption")}
            />
          </FormSelectLayout>
        </Grid2>
      </Grid2>

      <Stack
        direction="row"
        justifyContent="center"
        sx={{ width: "100%", alignSelf: "flex-end" }}
      >
        <Button
          title={t("periods.periodsDialogContent.cancel")}
          color="grey"
          type="button"
          onClick={handleToggleModal}
        />
        <Button
          title={t("periods.periodsDialogContent.apply")}
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={!sinceYearSelectedOption || !untilYearSelectedOption}
        />
      </Stack>
    </Stack>
  );
};

export default AddPeriodsModal;
