import { useEffect } from "react";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ComponentLayout from "../../layout/ComponentLayout";
import useLanguage from "../../../context/LanguageProvider";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import ViewDashboard from "../../viewDashboardNew/ViewDashboard";
import {
  INCOMING_VALUES_COSTS_FORECAST,
  PUT_ORDEN_SIMULATION_ELEMENTS,
  SIMULATION_PERIODS,
  SIMULATION_VIEWS,
} from "../../../api/axios";
import { useApiQuery } from "../../../hooks/useApiQuery";
import useApi from "../../../hooks/useApi";
import useInfoContext from "../../../hooks/useInfoContext";

const CostForecastResult = () => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const navigate = useNavigate();
  const analysisId = localStorage.getItem("analysisId") || undefined;
  const costForecastView = localStorage.getItem("costForecastView")
    ? localStorage.getItem("costForecastView")
    : undefined;
  const costsForecastId = localStorage.getItem("costsForecastId")
    ? localStorage.getItem("costsForecastId")
    : undefined;

  const {
    isLoading,
    setTabValue,
    setAnalysisViews,
    setViewTabValue,
    setAnalysisPeriods,
  } = useViewDashboard();

  /****************************** CARGA Y OBTENCIÓN DE DATOS ******************************/

  costForecastView && setViewTabValue(costForecastView);
  analysisId && setTabValue(analysisId);

  const {
    isLoading: isLoadingGetAnalysisViews,
    data: dataAnalysisViews,
    refetch: refetchGetAnalysisViews,
    isError: isErrorGetAnalysisViews,
  } = useApiQuery(
    SIMULATION_VIEWS("COSTS_FORECAST"),
    false,
    t("dataModel.getAnalysisViews.error")
  );

  useEffect(()=>{
    isErrorGetAnalysisViews && navigate("/delta/cost-forecast")
  },[isErrorGetAnalysisViews])
  

  useEffect(() => {
    if (dataAnalysisViews) {
      setAnalysisViews({
        isLoading: isLoadingGetAnalysisViews,
        data: dataAnalysisViews,
        refetch: refetchGetAnalysisViews,
      });
    }
  }, [dataAnalysisViews, isLoadingGetAnalysisViews]);

  const {
    isLoading: isLoadingGetAnalysisPeriods,
    callApi: getAnalysisPeriods,
    data: dataGetAnalysisPeriod,
  } = useApi(
    SIMULATION_PERIODS("costsforecast", costsForecastId),
    "GET",
    t("dataModel.getGrouper"),
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    setAnalysisPeriods({
      isLoading: isLoadingGetAnalysisPeriods,
      data: dataGetAnalysisPeriod,
      getData: getAnalysisPeriods,
    });
  }, [dataGetAnalysisPeriod, isLoadingGetAnalysisPeriods]);

  /****************************** MANEJO DE FUNCIONES ******************************/

  const handleGoBack = () => {
    navigate("/delta/cost-forecast");
    localStorage.removeItem("analysisId");
    localStorage.removeItem("costForecastView");
    localStorage.removeItem("costsForecastId");
  };

  return (
    <>
      <SimpleBackdrop
        open={
          isLoading || isLoadingGetAnalysisViews || isLoadingGetAnalysisPeriods
        }
        message={t("general.loading")}
      />
      {dataAnalysisViews && (
        <ComponentLayout
          title={`COSTS FORECAST - ${
            dataAnalysisViews &&
            costForecastView &&
            dataAnalysisViews
              .flatMap((view) => view.vistas)
              ?.find((vista) => vista.id == costForecastView)
              ?.nombre?.replace("COSTS FORECAST ", "")
          }`}
          icon={
            <ArrowBackIcon
              sx={{
                fontSize: "35px",
                marginRight: "10px",
                "& :hover": { cursor: "pointer", color: "#f90d4a" },
              }}
              onClick={handleGoBack}
            />
          }
        >
          <SimpleBackdrop open={isLoading} message={t("general.loading")} />
          <ViewDashboard
            thereArePeriodFilter={true}
            thereAreChipFilters={true}
            thereAreViews={false}
            thereAreTabs={false}
            rolEdit={
              userRolData && userRolData.simulador.visualizacion_y_edicion
            }
            rolCreate={
              userRolData && userRolData.simulador.visualizacion_y_creacion
            }
            actualUrl="/delta/cost-forecast-resultado"
            ELEMENTS_URL={() =>
              `simulador/vistas/${costForecastView}/elementos`
            }
            ELEMENT_URL={(costForecastView, elemento_id) =>
              `simulador/vistas/${costForecastView}/elementos/${elemento_id}`
            }
            PERIODS_URL={SIMULATION_PERIODS("costs_forecast", costsForecastId)}
            INCOMING_VALUES={
              costsForecastId && INCOMING_VALUES_COSTS_FORECAST(costsForecastId)
            }
            PUT_ORDEN_ELEMENT_URL={PUT_ORDEN_SIMULATION_ELEMENTS}
            dashboardType={"COSTS_FORECAST"}
          />
        </ComponentLayout>
      )}
    </>
  );
};

export default CostForecastResult;
