import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useLanguage from "../../../context/LanguageProvider";
import useApi from "../../../hooks/useApi";
import { MESSAGE_FEEDBACK_CHATBOT } from "../../../api/axios";
import ChatBotProvider from "../../../context/ChatBotProvider";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";

interface ResponseFeedbackProps {
  message_id: number;
  useful?: boolean | null;
}

const ResponseFeedback = ({ message_id, useful }: ResponseFeedbackProps) => {
  const { t } = useLanguage();
  const [upThumb, setUpThumb] = useState<boolean>(false);
  const [downThumb, setDownThumb] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { chatBotState } =
    useContext<React.ContextType<typeof ChatBotProvider>>(ChatBotProvider);

  useEffect(() => {
    if (useful === true) {
      setUpThumb(true);
      setDownThumb(false);
    } else if (useful === false) {
      setUpThumb(false);
      setDownThumb(true);
    } else {
      setDownThumb(false);
      setUpThumb(false);
    }
    setErrorMessage("");
  }, [useful, message_id]);

  const onErrorPostFeedback = () => {
    setDownThumb(false);
    setUpThumb(false);
    setErrorMessage(t("phibot.responseFeedback.errorMessage"));
  };

  const { callApi: postFeedback, isLoading: isLoadingPostFeedback } = useApi(
    "",
    "POST",
    undefined,
    undefined,
    undefined,
    onErrorPostFeedback,
    false
  );

  const handleThumbUpClick = () => {
    setUpThumb(true);
    setErrorMessage("");
    const newConfig = {
      is_useful: true,
    };
    postFeedback(
      MESSAGE_FEEDBACK_CHATBOT(chatBotState.historyTitle.value, message_id),
      newConfig
    );
  };

  const handleThumbDownClick = () => {
    setDownThumb(true);
    setErrorMessage("");
    const newConfig = {
      is_useful: false,
    };
    postFeedback(
      MESSAGE_FEEDBACK_CHATBOT(chatBotState.historyTitle.value, message_id),
      newConfig
    );
  };

  return (
    <Stack
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <SimpleBackdrop
        open={isLoadingPostFeedback}
        message={t("general.loading")}
      />
      {errorMessage !== "" && errorMessage}
      <Stack
        sx={{
          flexDirection: "row",
        }}
      >
        {!downThumb && (
          <Tooltip title={t("phibot.responseFeedback.appropriateResponse")}>
            <IconButton
              onClick={!upThumb ? handleThumbUpClick : () => undefined}
              sx={{
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            >
              {upThumb ? (
                <ThumbUpAltIcon color="primary" />
              ) : (
                <ThumbUpOffAltIcon />
              )}
            </IconButton>
          </Tooltip>
        )}
        {!upThumb && (
          <Tooltip title={t("phibot.responseFeedback.inappropriateResponse")}>
            <IconButton
              onClick={!downThumb ? handleThumbDownClick : () => undefined}
              sx={{
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            >
              {downThumb ? (
                <ThumbDownAltIcon color="error" />
              ) : (
                <ThumbDownOffAltIcon />
              )}
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default ResponseFeedback;
