import {
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import useLanguage from "../../../context/LanguageProvider";
import SendIcon from "@mui/icons-material/Send";
import { RefObject } from "react";

interface ChatbotTextFieldProps {
  isLoading: boolean;
  messageValue: string;
  handleMessageValue: (e: any) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  sendMessage: (messageValue: string) => void;
  textFieldRef: RefObject<HTMLInputElement>; // Cambiado a RefObject
}

const ChatbotTextField = ({
  isLoading,
  messageValue,
  handleMessageValue,
  handleKeyDown,
  sendMessage,
  textFieldRef,
}: ChatbotTextFieldProps) => {
  const { t } = useLanguage();

  return (
    <Stack
      sx={{
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <TextField
        inputRef={textFieldRef}
        fullWidth
        disabled={isLoading}
        multiline
        maxRows={5}
        minRows={1}
        placeholder={t("phibot.chatBotMessages.writeMessageHere")}
        variant="outlined"
        size="medium"
        value={messageValue}
        onChange={(e) => handleMessageValue(e.target.value)}
        onKeyDown={handleKeyDown}
        sx={{
          border: "none",
          userSelect: "none",
          "& .MuiOutlinedInput-root": {
            borderRadius: "25px",
            backgroundColor: "#f1f1f1",
            pt: 2,
            pb: 2,
            pl: 4,
            pr: 4,
            "&:hover fieldset": {
              borderColor: isLoading ? "" : "var(--bg-main)",
            },
            "&.Mui-focused fieldset": {
              borderColor: isLoading ? "" : "var(--bg-main)",
            },
          },
        }}
      />
      <Tooltip
        title={
          isLoading
            ? t("phibot.chatBotMessages.loading")
            : t("phibot.chatBotMessages.sendMessage")
        }
      >
        <span>
          <IconButton
            size="medium"
            disabled={messageValue === ""}
            onClick={() =>
              !isLoading && messageValue !== ""
                ? sendMessage(messageValue)
                : undefined
            }
            sx={{
              "&:hover": {
                backgroundColor: !isLoading ? "" : "var(--light-grey)",
              },
            }}
          >
            {isLoading ? (
              <CircularProgress
                sx={{ color: "var(--icon-secondary) !important" }}
              />
            ) : (
              <SendIcon sx={{ fontSize: "30px" }} />
            )}
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  );
};

export default ChatbotTextField;
