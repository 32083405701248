import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import Box from "@mui/material/Box";
import ChartSkeleton from "../../skeleton/ChartSkeleton";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import DeleteButton from "../../buttons/DeleteButton";
import EditCriteriaButton from "../../buttons/EditCriteriaButton";
import Kpi from "../../report/Kpi";
import NoDataError from "../../error/NoDataError";
import RenderChart from "./RenderChart";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import {
  box_grid_draggable_styles,
  chart_box_draggable_styles,
  view_compact_icon_styles,
  view_compact_space_styles,
} from "../../../styles/app-styles";
import DashboardTable from "./DashboardTable";

const GridCard = ({
  item,
  index,
  dragStart,
  dragEnter,
  drop,
  isUserRolAllowed,
  refetchGetAnalysisViews,
  ELEMENT_URL,
}) => {
  const { t } = useLanguage();
  const {
    viewTabValue,
    toggleNewElementModal,
    setElementToEdit,
    elementToEdit,
    setFilters,
    filters,
    navegationMode,
    tempFilters,
    setTempFilters,
    selectedPeriods,
    analysisPeriods,
    setSelectedPeriods,
  } = useViewDashboard();
  const [onHover, setOnHover] = useState(false);
  const [openConfirmationDeleteModal, toggleConfirmationDeleteModal] =
    useDialog();

  /************************************* Llamadas a la api ************************************/

  const {
    isLoading: isLoadingGetElement,
    data: dataGetElement,
    callApi: getElement,
  } = useApi(
    ELEMENT_URL && ELEMENT_URL,
    "POST",
    undefined,
    filters ? filters : undefined,
    undefined,
    undefined,
    false
  );

  const onSuccessDeleteElement = () => {
    toggleConfirmationDeleteModal();
    refetchGetAnalysisViews();
  };

  const { isLoading: isLoadingDeleteElement, callApi: deleteElement } = useApi(
    ELEMENT_URL(viewTabValue, item.id),
    "DELETE",
    t("dashboard.deleteElement.codes"),
    undefined,
    onSuccessDeleteElement,
    undefined,
    false
  );

  /********************************** Actualización de gráfico con filtros y pestañas **********************************/

  useEffect(() => {
    if (item && viewTabValue !== "DEFAULT" && ELEMENT_URL) {
      getElement(ELEMENT_URL(viewTabValue, item.id));
    }
  }, [filters, item.id, elementToEdit, viewTabValue]);

  /************************************************* Edición de gráfico ************************************************/

  const handleEditElement = () => {
    setElementToEdit(item);
    toggleNewElementModal();
  };  

  /************************************************* Filtrado interactivo ************************************************/
  
  const onClickFilters = (columnName, columnNameValue, filterValue) => {
    const updateFilterList = (filterList, column, value) => {
      const updatedFilters = { ...filterList };
      
      if (!updatedFilters[column]) {
        // Si no existe el filtro, lo agregamos con el valor
        updatedFilters[column] = [value];
      } else {
        const valueIndex = updatedFilters[column].indexOf(value);
        if (valueIndex === -1) {
          // Si el valor no está, lo agregamos
          updatedFilters[column].push(value);
        } else {
          // Si ya está, lo eliminamos
          updatedFilters[column].splice(valueIndex, 1);
          // Si el array queda vacío, eliminamos la clave
          if (updatedFilters[column].length === 0) {
            delete updatedFilters[column];
          }
        }
      }
      return updatedFilters;
    };
  
    // Si el filtro es "PERIODO", lo manejamos de forma especial
    if (columnName === "PERIODO") {
      const selectedPeriod = analysisPeriods?.data?.periodos?.find(
        (analysisPeriod) => analysisPeriod.label === filterValue
      );
      
      if (selectedPeriod) {
        if (selectedPeriods.includes(selectedPeriod.value)) {
          // Si el período ya está seleccionado, lo quitamos
          setSelectedPeriods((prevPeriods) => prevPeriods.filter(
            (period) => period !== selectedPeriod.value
          ));
        } else {          
          // Si no está seleccionado, lo agregamos
          setSelectedPeriods((prevPeriods) => [...prevPeriods, selectedPeriod.value]);
        }
      }
    } else {
      // Para otros filtros distintos de "PERIODO", actualizamos los tempFilters
      const newTempFilters = [...tempFilters];
      const newFilter = {
        id: columnNameValue,
        column: { value: columnNameValue, label: columnName },
        values: [{ value: filterValue, label: filterValue }],
      };
  
      const existingTempFilterIndex = newTempFilters.findIndex(
        (filter) => filter.id === newFilter.id
      );
  
      if (existingTempFilterIndex === -1) {
        // Si no existe el filtro, lo agregamos
        newTempFilters.push(newFilter);
      } else {
        const existingFilter = newTempFilters[existingTempFilterIndex];
        const valueIndex = existingFilter.values.findIndex(
          (valueObj) => valueObj.value === filterValue
        );
  
        if (valueIndex === -1) {
          // Si el valor no está, lo agregamos
          existingFilter.values.push({
            value: filterValue,
            label: filterValue,
          });
        } else {
          // Si ya está, lo eliminamos
          existingFilter.values.splice(valueIndex, 1);
          // Si no quedan valores, eliminamos el filtro completo
          if (existingFilter.values.length === 0) {
            newTempFilters.splice(existingTempFilterIndex, 1);
          }
        }
      }
  
      setTempFilters(newTempFilters); // Actualizamos los filtros temporales
    }
  
    // Actualizamos el estado general de los filtros
    const updatedFilters = updateFilterList(filters, columnName, filterValue);
    setFilters(updatedFilters);
  };
  
  return (
    <Box
      gridColumn={`span ${item.ancho}`}
      gridRow={`span ${item.alto}`}
      sx={box_grid_draggable_styles}
      onDragStart={(e) => dragStart(e, index)}
      onDragEnter={(e) => dragEnter(e, index)}
      onDragEnd={drop}
      draggable
      onMouseOver={!isLoadingGetElement ? () => setOnHover(true) : () => <></>}
      onMouseLeave={() => setOnHover(false)}
    >
      <SimpleBackdrop
        open={isLoadingDeleteElement}
        message={t("dashboard.deleteElement.loading")}
      />
      <Stack sx={chart_box_draggable_styles(onHover)}>
        {onHover ? (
          <Tooltip title={t("dashboard.moveElement")}>
            <ViewCompactIcon sx={view_compact_icon_styles} />
          </Tooltip>
        ) : (
          <></>
        )}
      </Stack>
      <Stack sx={view_compact_space_styles}></Stack>
      {isLoadingGetElement && <ChartSkeleton />}
      {!dataGetElement && !isLoadingGetElement && <NoDataError />}
      {dataGetElement && !isLoadingGetElement && item.tipo === "grafico" && (
        <RenderChart
          dataGetElement={dataGetElement}
          item={item}
          onClickFilters={
            navegationMode === "interactiveNavigation" && onClickFilters
          }
        />
      )}
      {dataGetElement && item.tipo === "indicador" && (
        <Kpi
          id={dataGetElement.id}
          valor={dataGetElement.valor}
          unidad={dataGetElement.unidad}
          kpi={dataGetElement.nombre}
          isAnalysis={true}
        />
      )}
      {dataGetElement && item.tipo === "tabla" && (
        <DashboardTable
          id={dataGetElement.id}
          dataGetElement={dataGetElement}
          onClickFilters={
            navegationMode === "interactiveNavigation" && onClickFilters
          }
        />
      )}
      {onHover ? (
        isUserRolAllowed && (
          <>
            <EditCriteriaButton
              handleClick={handleEditElement}
              selectedCriteria={item}
              bottom="30px"
              backgroundColor="white"
            />
            <DeleteButton
              handleClick={toggleConfirmationDeleteModal}
              selectedCriteria={item}
              bottom="90px"
              backgroundColor="white"
            />
          </>
        )
      ) : (
        <></>
      )}
      <ConfirmationModal
        open={openConfirmationDeleteModal}
        handleClose={toggleConfirmationDeleteModal}
        handleAccept={() => deleteElement(ELEMENT_URL(viewTabValue, item.id))} //parametro
        message={t("dashboard.deleteElement.confirmationMessage")}
        customMessage={true}
        title={t("dashboard.deleteElement.modalTitle")}
      />
    </Box>
  );
};

export default GridCard;
