import { getPeriodsLabelsFromPeriodsIds } from "../utils";
import { useViewDashboard } from "../../../context/ViewDashboardProvider";
import CheckmarkSelect from "../../periods/CheckmarkSelect";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../context/LanguageProvider";
import { Button, Tooltip } from "@mui/material";
import CustomButton from "../../buttons/Button";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const PeriodFilter = () => {
  const { t } = useLanguage();

  const addButton = document.querySelector('[data-testid="AddIcon"]');

  const {
    selectedPeriods,
    setSelectedPeriods,
    analysisPeriods,
    tabValue,
    setOpenFilterModal,
    setFilters,
    navegationMode,
    setNavegationMode,
  } = useViewDashboard();

  const handleToggleFiltersModal = () => {
    setOpenFilterModal((prev) => !prev);
  };

  const handleSelectedPeriods = (info) => {
    setSelectedPeriods(info);
    const periodsLabels = getPeriodsLabelsFromPeriodsIds(
      info,
      analysisPeriods.data
    );
    setFilters((prev: any) => {
      return {
        ...prev,
        PERIODO: periodsLabels ? periodsLabels : undefined,
      };
    });
  };

  const handleChangeNavigationMode = () => {
    if (navegationMode === "normalNavigation") {
      setNavegationMode("interactiveNavigation");
    } else if (navegationMode === "interactiveNavigation") {
      setNavegationMode("normalNavigation");
    }
  };

  return (
    <Stack
      sx={{
        width: "100%",
        pt: "10px",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <CheckmarkSelect
        width={"100%"}
        dates={
          analysisPeriods?.data && analysisPeriods?.data.periodos?.length
            ? analysisPeriods?.data.periodos.map((period) => {
                return {
                  id: period.value,
                  fecha: period.label,
                  posee_gastos: true,
                };
              })
            : []
        }
        selectedPeriods={selectedPeriods}
        setSelectedPeriods={handleSelectedPeriods}
      />
      <CustomButton
        title={t("highCharts.filters")}
        color={"blue"}
        type={"button"}
        onClick={() => handleToggleFiltersModal()}
        disabled={tabValue && tabValue !== "SIN ANALISIS" ? false : true}
      />

      <Tooltip
        placement="left"
        title={
          navegationMode === "normalNavigation"
            ? t("highCharts.normalNavigation")
            : t("highCharts.interactiveNavigation")
        }
      >
        <Button
          onClick={() => handleChangeNavigationMode()}
          variant="contained"
          sx={{
            position: "fixed",
            bottom: addButton ? "110px" : "25px",
            right: "35px",
            borderRadius: "100%",
            width: "50px",
            height: "64px",
            boxShadow: "5px 5px 10px var(--text-info)",
            transition: "all 0.4s",
            backgroundColor: "var(--bg-main)",
            ":hover": {
              scale: "1.1",
              backgroundColor: "var(--bg-main)",
              boxShadow: "5px 5px 10px var(--text-info)",
            },
            ":active": {
              scale: "0.95",
              backgroundColor: "var(--bg-main)",
            },
          }}
        >
          {navegationMode === "normalNavigation" ? (
            <FilterAltIcon sx={{ fontSize: "46px", fontWeight: "bold" }} />
          ) : (
            <AdsClickIcon sx={{ fontSize: "46px", fontWeight: "bold" }} />
          )}
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default PeriodFilter;
