import Tooltip from "@mui/material/Tooltip";
import useLanguage from "../../context/LanguageProvider";
import ZoomInIcon from '@mui/icons-material/ZoomIn';

interface ViewInfoIconProps {
  tooltipPlacement?: "top" | "bottom" | "left" | "right";
  onClick: (ref1?: any, ref2?: any) => void;
  ref1?: any;
  ref2?: any;
  tooltipText?: string;
  color?: string;
  fill?: string;
}

const ViewInfo = ({
  tooltipPlacement,
  onClick,
  ref1,
  ref2,
  tooltipText,
  color,
  fill
}: ViewInfoIconProps) => {
  const { t } = useLanguage();
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={tooltipText ? tooltipText : t("icons.ViewInfo")}
      placement={tooltipPlacement}
    >
      <ZoomInIcon
        onClick={() => onClick(ref1, ref2)}
        sx={{
          color: color ? color : "var(--icon-secondary)",
          padding: "0",
          margin: "0",
          "& :hover": { color: "var(--icon-error)", cursor: "pointer" },
          textAlign: "center",
          fill: fill ? fill : "var(--icon-secondary)",
        }}
      />
    </Tooltip>
  );
};

export default ViewInfo