import { Checkbox, Grid, Stack, TextField, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React, { useContext, useEffect, useState } from "react";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Select from "../../../forms/Select";
import useLanguage from "../../../../context/LanguageProvider";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import SimulationProvider from "../../../../context/SimulationProvider";
import { IdOption } from "../../../criterias/configCriteria/types";
import {
  buscarPorId,
  buscarPorLabel,
  isOptionInMedidas,
  isOptionInOptions,
  prepareColumnsToUseOptions,
} from "../../utils";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import useApi from "../../../../hooks/useApi";
import { isDuplicatedName } from "../../../companyParameterization/utils";
import { ActionMeta, SingleValue } from "react-select";
import PrincipalColumnsSelects from "../../PrincipalColumnsSelects";

interface Step1SimulationConfigProps {
  dataGetCostsForecast: any;
  dataGetSalesForecast: {
    id: number;
    analisis: string;
    analisis_id: string;
    complete: boolean;
    periodos_faltantes: string[];
    vista_id: number | null;
  }[];
  dataGetSimulationsRules: {
    id: string;
    analisis: string;
    nombre: string;
  }[];
  dataGetSimulationsInformation: {
    id: number;
    nombre: string;
    empresa: number;
    obligatorio_sales_forecast: boolean;
    obligatorio_simulacion: boolean;
    por_defecto: boolean;
    sales_forecast: boolean;
    simulacion: boolean;
    depende?: any;
  }[];
  dataGetAnalysisById: {
    nombre: string;
    agrupacion: any;
    medidas: any[];
  };
  dataGetSimulations: any[];
  dataGetSimulationById: any;
  simulationRuleSelectedOption: SingleValue<IdOption> | undefined;
  setSimulationRuleSelectedOptions: (option: IdOption | null) => void;
  simulationRuleSelectedChangeHandler: (
    newValue: SingleValue<IdOption>,
    actionMeta: ActionMeta<IdOption>
  ) => void;
}

const Step1SimulationConfig = ({
  dataGetSalesForecast,
  dataGetCostsForecast,
  dataGetSimulationsRules,
  dataGetSimulationsInformation,
  dataGetAnalysisById,
  dataGetSimulations,
  dataGetSimulationById,
  simulationRuleSelectedOption,
  setSimulationRuleSelectedOptions,
  simulationRuleSelectedChangeHandler,
}: Step1SimulationConfigProps) => {
  const { t } = useLanguage();
  const { simulationState, simulationDispatch } =
    useContext<React.ContextType<typeof SimulationProvider>>(
      SimulationProvider
    );
  const [salesForecastSelected, setSalesForecastSelected] = useState<any>();
  const [costsForecastSelected, setCostsForecastSelected] = useState<any>();
  const configMode = localStorage.getItem("configMode") || undefined;

  /******************* CARGA Y OBTENCIÓN DE DATOS *********************/

  useEffect(() => {
    if (
      dataGetSimulationById &&
      (configMode === "EDIT" || configMode === "COPY")
    ) {
      setSimulationRuleSelectedOptions(dataGetSimulationById.regla);
    }
  }, [dataGetSimulationById, configMode]);

  const onSuccessGetSalesForecastById = (data: any) => {
    setSalesForecastSelected(data);
  };

  const {
    isLoading: isLoadingGetSalesForecastById,
    callApi: getSalesForecastById,
  } = useApi(
    undefined,
    "GET",
    t("simulator.getSchemas.error"),
    undefined,
    onSuccessGetSalesForecastById,
    undefined,
    false
  );

  const onSuccessGetCostsForecastById = (data: any) => {
    setCostsForecastSelected(data);
  };

  const {
    isLoading: isLoadingGetCostsForecastById,
    callApi: getCostsForecastById,
  } = useApi(
    undefined,
    "GET",
    t("simulator.getSchemas.error"),
    undefined,
    onSuccessGetCostsForecastById,
    undefined,
    false
  );

  useEffect(() => {
    if (
      simulationRuleSelectedOption &&
      dataGetSalesForecast &&
      dataGetSimulationsRules &&
      dataGetCostsForecast
    ) {
      const analisisLabel = dataGetSimulationsRules.find(
        (rule) => rule.id === simulationRuleSelectedOption.value
      )?.analisis;
      const salesForecast =
        analisisLabel &&
        dataGetSalesForecast.find(
          (salesForecast) => salesForecast.analisis === analisisLabel
        );
      const costsForecast =
        analisisLabel &&
        dataGetCostsForecast.find(
          (salesForecast) => salesForecast.analisis === analisisLabel
        );
      if (salesForecast) {
        simulationDispatch({
          type: "SET_SALES_FORECAST",
          payload: salesForecast,
        });
        getSalesForecastById(`simulador/sales_forecast/${salesForecast.id}`);
        localStorage.setItem("analysisId", salesForecast.analisis_id);
      } else if (costsForecast) {
        simulationDispatch({
          type: "SET_SALES_FORECAST",
          payload: costsForecast,
        });
        getCostsForecastById(`simulador/sales_forecast/${costsForecast.id}`);
        localStorage.setItem("analysisId", costsForecast.analisis_id);
      } else {
        simulationDispatch({ type: "SET_SALES_FORECAST", payload: undefined });
      }
    }
  }, [
    simulationRuleSelectedOption,
    dataGetSalesForecast,
    dataGetSimulationsRules,
    dataGetCostsForecast,
  ]);

  useEffect(() => {
    if (
      (salesForecastSelected || costsForecastSelected) &&
      dataGetAnalysisById
    ) {
      const principalColumns = (
        salesForecastSelected || costsForecastSelected
      ).columnas_principales?.map((columna) => {
        if (columna.is_medida && columna.columna) {
          const medidaEncontrada =
            dataGetAnalysisById &&
            buscarPorId(columna.columna, dataGetAnalysisById.medidas);
          if (medidaEncontrada) {
            return {
              ...columna,
              columna: {
                value: medidaEncontrada.id,
                label: medidaEncontrada.nombre,
              },
            };
          }
        } else if (!columna.is_medida) {
          const agrupationColumnEncontrada =
            dataGetAnalysisById &&
            buscarPorLabel(
              columna.columna,
              dataGetAnalysisById.agrupacion.columnas_a_usar
            );
          if (agrupationColumnEncontrada) {
            return {
              ...columna,
              columna: {
                value: agrupationColumnEncontrada.value,
                label: agrupationColumnEncontrada.label,
              },
            };
          }
        } else if (columna.valor) {
          return {
            ...columna,
            valor: columna.valor,
          };
        }
        return columna;
      });
      simulationDispatch({
        type: "SET_PRINCIPAL_COLUMNS",
        payload: principalColumns,
      });
    }
  }, [salesForecastSelected, dataGetAnalysisById, costsForecastSelected]);

  /*********************** MANEJO DE FUNCIONES *************************/

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    simulationDispatch({ type: "SET_NOMBRE", payload: e.target.value });
  };

  const setPrincipalColumnsReferences = (
    column_id: string | number,
    reference: IdOption | undefined,
    is_medida: boolean
  ) => {
    const config = {
      informacion: column_id,
      columna: reference,
      is_medida: is_medida,
    };

    // Verifica si la información ya existe en el estado
    const existingIndex = simulationState.principalColumns?.findIndex(
      (principalColumns) => principalColumns.informacion === column_id
    );

    // Crea una copia mutable del estado actual
    const updatedInformations = [...simulationState.principalColumns];

    // Si la información ya existe, reemplázala; de lo contrario, agrégala
    if (existingIndex !== -1) {
      updatedInformations[existingIndex] = config;
    } else {
      updatedInformations.push(config);
    }
    // Actualiza el estado con la nueva información
    simulationDispatch({
      type: "SET_PRINCIPAL_COLUMNS",
      payload: updatedInformations,
    });
  };

  const columnsSelects = (columnsArray: any[]) => {
    return (
      columnsArray &&
      columnsArray?.map((column: any) => {
        const referenceExists = simulationState?.principalColumns?.find(
          (principalColumns) => principalColumns.informacion === column.id
        );
        const opcionesFiltradas =
          dataGetAnalysisById &&
          prepareColumnsToUseOptions(
            dataGetAnalysisById?.medidas,
            t,
            dataGetAnalysisById?.agrupacion?.columnas_a_usar?.map((column) => {
              return {
                value: column.value,
                label: column.label,
              };
            })
          )?.map((categoria) => {
            const opcionesFiltradasCategoria = categoria?.options?.filter(
              (opcion) => {
                return !simulationState.principalColumns?.some(
                  (principalColumna) => {
                    // Comparar por valor o label según la estructura de tus datos
                    if (principalColumna?.columna) {
                      return (
                        principalColumna?.columna?.value === opcion?.value ||
                        principalColumna?.columna?.label === opcion?.label
                      );
                    }
                  }
                );
              }
            );
            return {
              label: categoria?.label,
              options: opcionesFiltradasCategoria,
            };
          });
        return (
          <PrincipalColumnsSelects
            key={`${column.id}`}
            defaultValue={referenceExists ? referenceExists.columna : undefined}
          >
            {({
              principalColumnFilterSelectedOptions,
              principalColumnFilterOptionsChangeHandler,
              setPrincipalColumnSelectedOptions,
            }) => {
              useEffect(() => {
                if (principalColumnFilterSelectedOptions) {
                  if (
                    isOptionInMedidas(
                      principalColumnFilterSelectedOptions,
                      dataGetAnalysisById?.medidas
                    )
                  ) {
                    setPrincipalColumnsReferences(
                      column.id,
                      principalColumnFilterSelectedOptions,
                      true
                    );
                  } else if (
                    isOptionInOptions(
                      principalColumnFilterSelectedOptions,
                      dataGetAnalysisById?.agrupacion?.columnas_a_usar
                    )
                  ) {
                    setPrincipalColumnsReferences(
                      column.id,
                      principalColumnFilterSelectedOptions,
                      false
                    );
                  }
                } else {
                  const principalColumnsFiltered =
                    simulationState?.principalColumns?.filter(
                      (principalColumns) => {
                        return principalColumns?.informacion !== column?.id;
                      }
                    );
                  const dependencyToDelete =
                    dataGetSimulationsInformation &&
                    dataGetSimulationsInformation?.find(
                      (info) => info.id === column.id
                    )?.nombre;

                  const idsDepende =
                    dependencyToDelete &&
                    dataGetSimulationsInformation &&
                    dataGetSimulationsInformation
                      ?.filter((item) => item?.depende === dependencyToDelete)
                      ?.map((item) => item?.id);

                  const filteredData =
                    idsDepende &&
                    principalColumnsFiltered?.filter(
                      (item) => !idsDepende?.includes(item?.informacion)
                    );
                  simulationDispatch({
                    type: "SET_PRINCIPAL_COLUMNS",
                    payload: filteredData
                      ? filteredData
                      : principalColumnsFiltered,
                  });
                }
              }, [principalColumnFilterSelectedOptions]);

              useEffect(() => {
                if (referenceExists) {
                  if (typeof referenceExists?.columna === "string") {
                    setPrincipalColumnSelectedOptions(referenceExists?.columna);
                  } else {
                    const medidaEncontrada = dataGetAnalysisById?.medidas?.find(
                      (medida) => medida?.id === referenceExists?.columna
                    );
                    if (medidaEncontrada) {
                      setPrincipalColumnSelectedOptions(
                        referenceExists?.columna
                      );
                    }
                  }
                } else {
                  setPrincipalColumnSelectedOptions([]);
                }
              }, [referenceExists]);

              return (
                <Grid key={column?.nombre} item xs={4}>
                  <FormSelectLayout
                    required={column?.obligatorio_simulacion}
                    title={column?.nombre}
                  >
                    <Stack
                      sx={{
                        width: "90%",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        styles={{
                          ...selectStyles(referenceExists),
                          control: () => ({
                            display: "flex",
                            borderRadius: "5px",
                            border: column?.obligatorio_simulacion
                              ? referenceExists
                                ? "1px solid #d7d7d7"
                                : "1px solid red"
                              : "1px solid #d7d7d7",
                          }),
                        }}
                        options={opcionesFiltradas && opcionesFiltradas}
                        className="cc_select"
                        name="analysisToUse"
                        onChange={principalColumnFilterOptionsChangeHandler}
                        closeMenuOnSelect
                        placeholder={t(
                          "simulatorTexts.simulationSchema.step1NewSimulationSchema.chooseReferenceColumn"
                        )}
                        isClearable
                        isDisabled={
                          ((salesForecastSelected &&
                            salesForecastSelected?.columnas_principales) ||
                            (costsForecastSelected &&
                              costsForecastSelected?.columnas_principales)) &&
                          referenceExists &&
                          (salesForecastSelected.columnas_principales?.find(
                            (salesForecast) =>
                              salesForecast?.informacion ===
                              referenceExists?.informacion
                          ) || salesForecastSelected.columnas_principales?.find(
                            (salesForecast) =>
                              salesForecast?.informacion ===
                              referenceExists?.informacion
                          )) &&
                          column?.sales_forecast
                        }
                        value={
                          referenceExists
                            ? referenceExists?.columna
                            : principalColumnFilterSelectedOptions
                        }
                      />
                    </Stack>
                  </FormSelectLayout>
                </Grid>
              );
            }}
          </PrincipalColumnsSelects>
        );
      })
    );
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingGetSalesForecastById || isLoadingGetCostsForecastById}
        message={t("simulatorTexts.simulationsRulesAdmin.loading")}
      />
      <TextField
        type="text"
        label={t(
          "simulatorTexts.simulations.simulationAdmin.step1SimulationConfig.simulationName"
        )}
        variant="outlined"
        fullWidth
        value={simulationState && simulationState.nombre}
        onChange={handleChangeName}
        error={
          configMode === "EDIT"
            ? dataGetSimulationById &&
              dataGetSimulations &&
              isDuplicatedName(
                simulationState.nombre,
                dataGetSimulations.filter(
                  (simulation) =>
                    simulation.nombre.trim().toLowerCase() !==
                    dataGetSimulationById.nombre?.trim().toLowerCase()
                )
              )
            : (dataGetSimulations &&
                isDuplicatedName(simulationState.nombre, dataGetSimulations)) ||
              simulationState.nombre === ""
        }
        FormHelperTextProps={{
          sx: {
            color: "var(--text-error)",
            margin: 0,
          },
        }}
        helperText={
          configMode === "EDIT"
            ? dataGetSimulationById &&
              dataGetSimulations &&
              isDuplicatedName(
                simulationState.nombre,
                dataGetSimulations.filter(
                  (simulation) =>
                    simulation.nombre.trim().toLowerCase() !==
                    dataGetSimulationById.nombre?.trim().toLowerCase()
                )
              ) &&
              "Ya existe una simulación con ese nombre."
            : dataGetSimulations &&
              isDuplicatedName(simulationState.nombre, dataGetSimulations) &&
              "Ya existe una simulación con ese nombre."
        }
      />
      <Stack
        onClick={() =>
          simulationDispatch({
            type: "SET_AUTO_RUN",
            payload: !simulationState.ejecucion_automatica,
          })
        }
        sx={{
          flexDirection: "row",
          alignItems: "center",
          width: "200px",
          cursor: "pointer",
        }}
      >
        <Checkbox checked={simulationState.ejecucion_automatica} />
        <span>{t("deltaTexts.simulation.simulationConfig.autoRun")}</span>
      </Stack>
      <FormSelectLayout
        title={t(
          "simulatorTexts.registerRoutes.configRegisterRoute.simulationRule"
        )}
        required={true}
      >
        <Select
          styles={selectStyles(simulationRuleSelectedOption)}
          options={
            dataGetSimulationsRules &&
            dataGetSimulationsRules?.map((rule) => {
              return {
                value: rule.id,
                label: rule.nombre,
              };
            }) || []
          }
          className="cc_select"
          name="analysisToUse"
          onChange={simulationRuleSelectedChangeHandler}
          closeMenuOnSelect
          placeholder={t(
            "simulatorTexts.registerRoutes.configRegisterRoute.chooseSimulationRule"
          )}
          isClearable
          defaultValue={
            dataGetSimulationById
              ? dataGetSimulationById.regla
              : simulationState.regla
              ? simulationState.regla
              : undefined
          }
          value={
            dataGetSimulationById
              ? dataGetSimulationById.regla
              : simulationState.regla
              ? simulationState.regla
              : undefined
          }
        />
      </FormSelectLayout>
      <Stack
        sx={{
          width: "100%",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <InfoIcon color="primary" fontSize="medium" />
        <Typography style={{ color: "var(--text-main)", fontWeight: "600" }}>
          {t("deltaTexts.simulation.simulationConfig.onceRuleChosen")}
        </Typography>
      </Stack>
      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {simulationRuleSelectedOption &&
          dataGetSimulationsInformation &&
          columnsSelects(
            dataGetSimulationsInformation?.filter((info) => info.simulacion)
          )}
      </Grid>
    </>
  );
};

export default Step1SimulationConfig;
