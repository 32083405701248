import { Message } from "../../criterias/configCriteria/types";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ComponentLayout from "../../layout/ComponentLayout";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import MessageBlock from "../../forms/MessageBlock";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import Step1Tables from "../shared/Step1Tables";
import Step2Tables from "../shared/Step2Tables";
import StepperButtons from "../../forms/StepperButtons";
import Typography from "@mui/material/Typography";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import {
  arrow_back_icon_styles,
  form_subtitle_styles,
} from "../../../styles/app-styles";
import {TABLAS_URL, TABLA_ARCHIVO } from "../../../api/axios";
import useSingleSelect from "../../../hooks/useSingleSelect";

const ConfigTablesFiles = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();;
  const configMode = localStorage.getItem("configMode");
  const table = JSON.parse(localStorage.getItem("cardInfo") as string);
  const [openConfirmationTableModal, toggleConfirmationTableModal] =
    useDialog();
  const [message, setMessage] = useState<Message>({
    type: "success",
    text: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [idTable, setIdTable] = useState(configMode === "EDIT" && table && table.id ? table.id : undefined);
  const [tableName, setTableName] = useState(
    configMode === "EDIT" && table
      ? table?.nombre
      : configMode === "COPY" && table
      ? table?.nombre + "_copy"
      : ""
  );
  const [rowsLimit, setRowsLimit] = useState<number | string | undefined>(
    (configMode === "EDIT" || configMode === "COPY") && table ? table?.limitar_resultados : undefined
  );

  const {
    data: dataGetTables,
    refetch: refetchTables,
  } = useApiQuery(
    `${TABLAS_URL}?tipo=TEMPLATE`,
    false,
    t("report.getKpis.error")
  );

  useEffect(() => {
    if ((configMode === "EDIT" || configMode === "COPY") && table && table.id) {
      getTable(`${TABLAS_URL}${table.id}`);
    } else if ((configMode === "EDIT" || configMode === "COPY")){
      navigate(t("/administracion-dashboard"), {
        state: { tab: "archivo/tablas" },
        replace: false,
      });
    }
  }, []);

  /********************************************* Stepper *********************************************/

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRestart = () => {
    console.log("preguntar si borrar todo y al confirmar borrar todo");
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /********************************************* POST y PUT *******************************************/

  const [fileSelectedOption, , fileOptionsChangeHandler] =
    useSingleSelect(
      undefined,
      configMode === "EDIT" && table ? table.archivo : undefined
    );

    const handlePostTable = (fileSelectedOption) => {
      if (!idTable) {
        if (fileSelectedOption) {
          postTable(undefined, {
            nombre: tableName,
            archivo: fileSelectedOption.value,
          });
        }
      } else {
        if (fileSelectedOption) {
          putTable(`${TABLAS_URL}${idTable}`, {
            nombre: tableName,
            archivo: fileSelectedOption.value,
          });
        }
      }
    };

  const {
    isLoading: isLoadingGetTable,
    callApi: getTable,
    data: dataGetTable,
  } = useApi(
    `${TABLAS_URL}`,
    "GET",
    t("indicatorsManagement.editIndicator.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  const onSuccessPost = (dataTable) => {
    setIdTable(dataTable.id);
    getTable(`${TABLAS_URL}${dataTable.id}`);
    refetchTables();
  };

  const { isLoading: isLoadingPostTable, callApi: postTable } = useApi(
    `${TABLAS_URL}`, 
    "POST",
    t("indicatorsManagement.addIndicator.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const onSuccessPut = (dataTable) => {
    getTable(`${TABLAS_URL}${dataTable.id}`);
  };

  const { isLoading: isLoadingPutTable, callApi: putTable } = useApi(
    `${TABLAS_URL}`,
    "PUT",
    t("indicatorsManagement.editIndicator.codes"),
    undefined,
    onSuccessPut,
    undefined,
    true
  );

  const handleFinish = () => {
    localStorage.removeItem("configMode");
    localStorage.removeItem("cardInfo");
    navigate(t("/administracion-dashboard"), {
      state: { tab: "archivo/tablas" },
      replace: false,
    });
  };

  const { isLoading: isLoadingPutFinishTable, callApi: putFinishTable } =
    useApi(
      `${TABLAS_URL}`,
      "PUT",
      t("indicatorsManagement.editIndicator.codes"),
      undefined,
      handleFinish,
      undefined,
      true
    );

  const sendTable = () => {
    const completedInfo = {
      nombre: tableName,
      limitar_resultados: rowsLimit,
    };
    idTable && putFinishTable(TABLA_ARCHIVO(idTable), completedInfo);
    toggleConfirmationTableModal();
  };

  /********************************************* Cierre y limpieza ************************************/

  const closeAndClean = () => {
    localStorage.removeItem("configMode");
    localStorage.removeItem("cardInfo");
    navigate(t("/administracion-dashboard"), {
      state: { tab: "archivo/tablas" },
      replace: false,
    });
  };

  return (
    <ComponentLayout
      title={t(
        "indicatorsManagementTexts.indicatorsManagement.indicatorConfigTableFile"
      )}
      icon={
        <ArrowBackIcon sx={arrow_back_icon_styles} onClick={closeAndClean} />
      }
    >
      <SimpleBackdrop
        open={
          isLoadingPostTable || isLoadingPutTable || isLoadingGetTable || isLoadingPutFinishTable
        }
        message={
          configMode === "CREATE" || configMode === "COPY"
            ? t("indicatorsManagementTexts.configTables.creatingTable")
            : t("indicatorsManagementTexts.configTables.modifyingTable")
        }
      />
      <ConfirmationModal
        open={openConfirmationTableModal}
        handleClose={toggleConfirmationTableModal}
        handleAccept={sendTable}
        message={
          configMode === "EDIT"
            ? t("indicatorsManagementTexts.configTables.editTheTable")
            : t("indicatorsManagementTexts.configTables.createTheTable")
        }
        title={
          configMode === "EDIT"
            ? t("indicatorsManagementTexts.configTables.editTable")
            : t("indicatorsManagementTexts.configTables.createTable")
        }
      />
      <Stack
        width={"100%"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        minHeight={"68vh"}
      >
        <Stack
          width={"100%"}
          minHeight={"68vh"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          {activeStep === 0 && (
            <>
              <Typography
                sx={{ ...form_subtitle_styles, margin: "0 0 10px 0" }}
              >
                {t("indicatorsManagementTexts.configTables.steps.initalParametrization")}
              </Typography>
              <MessageBlock message={message} />
              <Step1Tables
                fileType="TEMPLATE"
                defaultElement={table}
                message={message}
                dataGetTables={dataGetTables}
                tableName={tableName}
                setTableName={setTableName}
                dataGetTable={dataGetTable}
                fileSelectedOption={fileSelectedOption}
                fileOptionsChangeHandler={fileOptionsChangeHandler}
                getTable={getTable}
                idTable={idTable}
                handlePostTable={handlePostTable}
              />
            </>
          )}
          {activeStep === 1 && (
            <>
              <Typography
                sx={{ ...form_subtitle_styles, margin: "0 0 10px 0" }}
              >
                {t("indicatorsManagementTexts.configTables.steps.limitResults")}
              </Typography>
              <Step2Tables rowsLimit={rowsLimit} setRowsLimit={setRowsLimit} idTable={idTable}/>
            </>
          )}
        </Stack>
        <Stack width="100%" alignSelf={"flex-end"} alignItems={"center"}>
          <StepperButtons
            handleBack={handleBack}
            handleNext={handleNext}
            handleRestart={handleRestart}
            onFinishClick={() => toggleConfirmationTableModal()}
            isBackButtonDisabled={activeStep === 0}
            isFinishButtonDisabled={
              message?.type === "error" ||
              !fileSelectedOption ||
              activeStep === 0
            }
            isNextButtonDisabled={
              activeStep === 1 ||
              message?.type === "error" ||
              !fileSelectedOption
            }
          />
        </Stack>
      </Stack>
    </ComponentLayout>
  );
};

export default ConfigTablesFiles;
