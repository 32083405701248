import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

interface CollapseOptionProps {
  OptionIcon: any;
  optionTitle: string;
  optionUrl: string;
  handleDrawerClose: any;
  getViewTitle: any;
}

const CollapseOption = ({
  OptionIcon,
  optionTitle,
  optionUrl,
  handleDrawerClose,
  getViewTitle,
}: CollapseOptionProps) => {
  const navigate = useNavigate();

  const onclickCollapseOption = (event: React.MouseEvent) => {
    getViewTitle && getViewTitle(optionTitle);
    handleDrawerClose();
    if (event.button === 1 && optionUrl) {
      event.preventDefault();
      // Clic con rueda del mouse: abre en una nueva pestaña sin cambiar el foco
      window.open(optionUrl, "_blank", "noopener,noreferrer");
    } else if (event.button !== 1) {
      // Clic izquierdo: navegación estándar
      navigate(optionUrl);
    }
  };

  return (
    <a
      href={optionUrl}
      rel="noopener noreferrer"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <ListItemButton
        key={optionTitle}
        sx={{ pl: 4 }}
      >
        <ListItemIcon sx={{ minWidth: "35px" }}>
          <OptionIcon
            sx={{
              color:
                location.pathname === optionUrl
                  ? "var(--chart-22) !important"
                  : "white",
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={optionTitle}
          sx={{
            "& .MuiTypography-root": {
              fontSize: "14px",
              color:
                location.pathname === optionUrl
                  ? "var(--chart-22) !important"
                  : "white",
            },
          }}
        />
      </ListItemButton>
    </a>
  );
};

export default CollapseOption;
