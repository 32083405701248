import { Item } from "../../../basicGrid/BasicGrid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const DestinyValidationRowElement = ({ schemaRow }) => {
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      width="100%"
      gap={1}
    >
      <Item sx={{ width: "33.33%" }}>
        <Typography color={"primary"} sx={{ fontSize: "14px" }}>
          {schemaRow?.destino}
        </Typography>
      </Item>
      <Item sx={{ width: "33.33%" }}>
        <Typography color="primary" sx={{ fontSize: "14px" }}>
          {(schemaRow &&
            schemaRow.validaciones_conservar &&
            schemaRow?.validaciones_conservar
              ?.map((v, index) => (
                <span key={index}>
                  <strong>{`${v?.criterio?.label || "-"}`}</strong>            
                </span>
              ))
              .reduce((prev, curr, idx, arr) => (
                <>
                  {prev}
                  {idx > 0 && ", "}
                  {curr}
                </>
              ))) ||
            "-"}
        </Typography>
      </Item>
      <Item sx={{ width: "33.33%" }}>
        <Typography color="primary" sx={{ fontSize: "14px" }}>
          {(schemaRow &&
            schemaRow.validaciones_conservar &&
            schemaRow?.validaciones_conservar
              ?.map((v, index) => (
                <span key={index}>
                  {v?.validaciones?.length > 0
                    ? v?.validaciones?.map((val) => val?.label).join(", ")
                    : "Ninguna"}
                </span>
              ))
              .reduce((prev, curr, idx, arr) => (
                <>
                  {prev}
                  {idx > 0 && ", "}
                  {curr}
                </>
              ))) ||
            "-"}
        </Typography>
      </Item>
    </Stack>
  );
};

export default DestinyValidationRowElement;
