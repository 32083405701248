import { actionsTypes } from "../../data";
import { getTable } from "../../fake";
import { modal_container_450 } from "../../../../styles/app-styles";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { useRef, useState } from "react";
import Button from "../../../buttons/Button";
import FilterColumnsElement from "./FilterColumnsElement";
import FilterRowsElement from "./FilterRowsElement";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import GroupingElement from "./GroupingElement";
import NewColumnElement from "./NewColumnElement";
import OrderRowsElement from "./OrderRowsElement";
import Select from "../../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import MessageBlock from "../../../forms/MessageBlock";

interface AddElementModalProps {
  dataGetTables: any;
  dataGetTable: any;
  handleToggleModal: () => void;
  element: any;
  getTable: typeof getTable;
  setElementToEdit: any;
}

const AddElementModal = ({
  handleToggleModal,
  dataGetTable,
  element,
  getTable,
  setElementToEdit,
}: AddElementModalProps) => {
  const { t } = useLanguage();
  const actionSelectRef: any = useRef();
  const [message, setMessage] = useState({
    text:
      element?.tipo === "NUEVA_COLUMNA" || element?.tipo === "AGRUPAR_VALORES"
        ? "Modificar este elemento ocasionará que se eliminen los elementos subsiguientes."
        : "",
    type: "warning",
  });

  const [
    actionSelectedOption,
    setActionSelectedOption,
    actionOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    actionsTypes.find((action) => action.value === element?.tipo)
  );  

  const renderElementByType = (type: string | number | undefined) => {
    switch (type) {
      case "NUEVA_COLUMNA":
        return (
          <NewColumnElement
            dataGetTable={dataGetTable}
            handleToggleModal={handleToggleModal}
            element={element}
            getTable={getTable}
            setElementToEdit={setElementToEdit}
          />
        );
      case "AGRUPAR_VALORES":
        return (
          <GroupingElement
            dataGetTable={dataGetTable}
            handleToggleModal={handleToggleModal}
            element={element}
            getTable={getTable}
            setElementToEdit={setElementToEdit}
          />
        );
      case "ORDENAR_FILAS":
        return (
          <OrderRowsElement
            dataGetTable={dataGetTable}
            handleToggleModal={handleToggleModal}
            element={element}
            getTable={getTable}
            setElementToEdit={setElementToEdit}
          />
        );
      case "FILTRAR":
        return (
          <FilterRowsElement
            dataGetTable={dataGetTable}
            handleToggleModal={handleToggleModal}
            element={element}
            getTable={getTable}
            setElementToEdit={setElementToEdit}
          />
        );
      case "FILTRAR_COLUMNAS":
        return (
          <FilterColumnsElement
            dataGetTable={dataGetTable}
            handleToggleModal={handleToggleModal}
            element={element}
            getTable={getTable}
            setElementToEdit={setElementToEdit}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Stack sx={{ ...modal_container_450, gap: 2 }}>
      {message?.text && <MessageBlock message={message} />}
      <Stack
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{ width: "100%", gap: 2 }}
      >
        <FormSelectLayout title={t("Acción")} required={true} margin={"0px"}>
          <Select
            reference={actionSelectRef}
            styles={selectStyles(actionSelectedOption)}
            options={actionsTypes}
            className="cc_select"
            onChange={actionOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t("indicatorsManagementTexts.configTables.selectionAnAction")}
            defaultValue={actionSelectedOption}
            isClearable
          />
        </FormSelectLayout>
        {actionSelectedOption &&
          renderElementByType(actionSelectedOption?.value)}
      </Stack>
    </Stack>
  );
};

export default AddElementModal;
