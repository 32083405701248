import {
  CRITERIAS_LIST_URL,
  GET_BUCKETS_URL,
  GET_GROUPERS_URL,
  SCHEMAS_URL,
} from "../../../../api/axios";
import { Box, Stack } from "@mui/material";
import { handleElementRepeatedNameTypeMessage } from "../../../../utils/util";
import { isValidSchema } from "../utils";
import { selectStyles } from "../../configCriteria/select.styles";
import { useApiQuery } from "../../../../hooks/useApiQuery";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddIterationButton from "./AddIterationButton";
import Button from "../../../buttons/Button";
import ConfirmationModal from "../../../dialog/ConfirmationModal";
import CustomInput from "../../../forms/CustomInput";
import Dialog from "../../../dialog/Dialog";
import ExplicitAssign from "./explicit/ExplicitAssign";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import ImplicitAssign from "./implicit/ImplicitAssign";
import IterationElement from "../iterationVisualElements/IterationElement";
import MessageBlock from "../../../forms/MessageBlock";
import NewIterationModal from "./shared/NewIterationModal";
import Select from "../../../forms/Select";
import useDialog from "../../../../hooks/useDialog";
import useInfoContext from "../../../../hooks/useInfoContext";
import useLanguage from "../../../../context/LanguageProvider";

interface NewSchemaContentProps {
  handleCreateSchema: any;
  destinyTypeSelectedOption: any;
  destinyTypeSelectedOptionsChangeHandler: any;
  assignTypeSelectRef: any;
  assignTypeSelectedOption: any;
  assignTypeSelectedOptionsChangeHandler: any;
  criteriaSelectedOption: any;
  criteriaSelectedOptionChangeHandler: any;
  columnSelectRef: any;
  columnSelectedOption: any;
  columnSelectedOptionChangeHandler: any;
  newSchemaRows: any;
  setNewSchemaRows: any;
  schemaName: any;
  setSchemaName: any;
  iterations: any;
  setIterations: any;
}

const NewSchemaForm = ({
  handleCreateSchema,
  destinyTypeSelectedOption,
  destinyTypeSelectedOptionsChangeHandler,
  assignTypeSelectRef,
  assignTypeSelectedOption,
  assignTypeSelectedOptionsChangeHandler,
  criteriaSelectedOption,
  criteriaSelectedOptionChangeHandler,
  columnSelectRef,
  columnSelectedOption,
  columnSelectedOptionChangeHandler,
  newSchemaRows,
  setNewSchemaRows,
  schemaName,
  setSchemaName,
  iterations,
  setIterations,
}: NewSchemaContentProps) => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { companyData }: any = useInfoContext();
  const inputSchemaNameRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState<{
    text: string;
    type: string;
  }>({ text: "", type: "" });
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const [
    openDeleteIterationConfirmationModal,
    toggleDeleteIterationConfirmationModal,
  ] = useDialog();
  const [destinitySelectOptions, setDestinitySelectOptions] = useState<any[]>(
    []
  );
  const [openIterationModal, toggleIterationModal] = useDialog();
  const [iterationToDelete, setIterationToDelete] = useState<number>(0);
  const [areIterationErrors, setAreIterationErrors] = useState<boolean>(false);
  const [iterationToEdit, setIterationToEdit] = useState<any>();
  const schema = JSON.parse(localStorage.getItem("schema") as string);
  const configMode = localStorage.getItem("configMode");

  const [selectedCriteriaType, setSelectedCriteriaType] = useState(
    (configMode === "EDIT" || configMode === "COPY") &&
      schema?.porcentaje &&
      schema.porcentaje.length > 0
      ? {
          value: "porcentaje",
          label: t(
            "preprorationParameterization.newSchemaContent.percentage"
          ).toUpperCase(),
        }
      : (configMode === "EDIT" || configMode === "COPY") &&
        schema.criterios_destinos &&
        schema.criterios_destinos.length > 0
      ? {
          value: t(
            "preprorationParameterization.preprorationParameterization.criteria"
          ),
          label: t(
            "preprorationParameterization.preprorationParameterization.criteria"
          ).toUpperCase(),
        }
      : {
          value: t(
            "preprorationParameterization.preprorationParameterization.criteria"
          ),
          label: t(
            "preprorationParameterization.preprorationParameterization.criteria"
          ).toUpperCase(),
        }
  );

  const { data: dataGetCriteriaBucket } = useApiQuery(
    GET_BUCKETS_URL,
    false,
    undefined
  );

  const { data: dataGroupers } = useApiQuery(
    GET_GROUPERS_URL,
    false,
    undefined
  );

  const { data: dataSchemas } = useApiQuery(SCHEMAS_URL, false, undefined);

  const { data: criteriaData } = useApiQuery(
    CRITERIAS_LIST_URL,
    false,
    undefined
  );

  const destinyTypeOptions = companyData?.casos_preprorrateo?.map((option) => {
    return {
      value: option,
      label: option,
    };
  });

  useEffect(() => {
    if (dataGroupers && dataGetCriteriaBucket) {
      if (destinyTypeSelectedOption?.value === "BUCKET_GASTO") {
        setDestinitySelectOptions(dataGetCriteriaBucket);
      } else if (destinyTypeSelectedOption?.value === "AGRUPADOR") {
        setDestinitySelectOptions(dataGroupers);
      } else {
        setDestinitySelectOptions([]);
      }
    } else {
      setDestinitySelectOptions([]);
    }
  }, [dataGroupers, dataGetCriteriaBucket, destinyTypeSelectedOption]);

  useEffect(() => {
    if (!openIterationModal) {
      setIterationToEdit(undefined);
    }
  }, [openIterationModal]);

  /************************************** Selects ***********************************************/

  const handleChangeValue = (value: any) => {
    handleElementRepeatedNameTypeMessage(
      t,
      value,
      dataSchemas,
      schema?.id,
      configMode,
      setMessage
    );
    setSchemaName(value);
  };

  const createSchema = () => {
    if (assignTypeSelectedOption?.value === "EXPLICITA") {
      if (isValidSchema(t, newSchemaRows, schemaName, setMessage)) {
        setMessage({ text: "", type: "" });
        handleCreateSchema();
      }
    } else {
      setMessage({ text: "", type: "" });
      handleCreateSchema();
    }
  };

  const confirmDeleteIteration = (iter: number) => {
    setIterationToDelete(iter);
    toggleDeleteIterationConfirmationModal();
  };

  const deleteIteration = () => {
    const newIterations = iterations.filter(
      (it) => it.iteracion !== iterationToDelete
    );
    newIterations.forEach((iteration, i) => {
      iteration.iteracion = i + 1;
    });
    setIterations(newIterations);
    toggleDeleteIterationConfirmationModal();
  };

  const isAbleToAddIteration = !(
    (assignTypeSelectedOption?.value === "EXPLICITA" &&
      newSchemaRows?.length < 1) ||
    (assignTypeSelectedOption?.value === "IMPLICITA" &&
      !columnSelectedOption?.value) ||
    selectedCriteriaType?.value === "porcentaje" ||
    (iterations?.length > 0 &&
      assignTypeSelectedOption?.value === "EXPLICITA" &&
      iterations[iterations.length - 1]?.sin_match?.porcentaje?.length > 0 &&
      iterations[iterations.length - 1]?.criterio_cero?.porcentaje?.length >
        0) ||
    (iterations?.length > 0 &&
      assignTypeSelectedOption?.value === "IMPLICITA" &&
      iterations[iterations.length - 1]?.sin_match?.porcentaje?.length > 0 &&
      iterations[iterations.length - 1]?.criterio_cero?.porcentaje?.length >
        0) ||
    !destinyTypeSelectedOption ||
    !assignTypeSelectedOption
  );

  const areIterations = iterations && iterations.length > 0;

  const handleEditIteration = (iteration: any) => {
    setIterationToEdit(iteration);
    toggleIterationModal();
  };

  const handleBack = () => {
    localStorage.removeItem("schema");
    localStorage.removeItem("configMode");
    navigate("/administracion-esquemas");
  };

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          minHeight: "70vh",
          mb: "5px",
          gap: 2,
          justifyContent: "flex-start",
          alignItems: "stretch",
          height: "100%",
        }}
      >
        <MessageBlock message={message} />
        <Box>
          <FormSelectLayout
            title={t(
              "preprorationParameterization.newSchemaContent.schemaName"
            )}
            margin="0 0 0 0"
          >
            <CustomInput
              name="SchemaName"
              inputRef={inputSchemaNameRef}
              value={schemaName}
              setValue={handleChangeValue}
              maxLength={50}
              required={true}
              placeholder={`${t(
                "preprorationParameterization.newSchemaContent.schemaName"
              )}...`}
              autoFocus={true}
            />
          </FormSelectLayout>
          <Stack direction="row" justifyContent={"space-between"} gap={1}>
            <Box width="100%">
              <FormSelectLayout title={"Tipo de destino"} margin="0 0 0 0">
                <Select
                  value={destinyTypeSelectedOption}
                  styles={selectStyles(destinyTypeSelectedOption)}
                  options={destinyTypeOptions}
                  placeholder={t("general.selectOption")}
                  defaultValue={destinyTypeSelectedOption}
                  closeMenuOnSelect
                  onChange={destinyTypeSelectedOptionsChangeHandler}
                  isSearchable
                />
              </FormSelectLayout>
            </Box>

            <Box width="100%">
              <FormSelectLayout title={"Tipo de asignación"} margin="0 0 0 0">
                <Select
                  value={assignTypeSelectedOption}
                  reference={assignTypeSelectRef}
                  styles={selectStyles(assignTypeSelectedOption)}
                  options={[
                    { value: "EXPLICITA", label: "Asignación explícita" },
                    { value: "IMPLICITA", label: "Asignación implícita" },
                  ]}
                  placeholder={t("general.selectOption")}
                  defaultValue={assignTypeSelectedOption}
                  closeMenuOnSelect
                  onChange={assignTypeSelectedOptionsChangeHandler}
                  isSearchable
                />
              </FormSelectLayout>
            </Box>
          </Stack>
        </Box>
        {assignTypeSelectedOption?.value === "EXPLICITA" ? (
          <ExplicitAssign
            destinyTypeSelectedOption={destinyTypeSelectedOption}
            selectedCriteriaType={selectedCriteriaType}
            setSelectedCriteriaType={setSelectedCriteriaType}
            newSchemaRows={newSchemaRows}
            setNewSchemaRows={setNewSchemaRows}
            setMessage={setMessage}
            destinitySelectOptions={destinitySelectOptions}
            criteriaData={criteriaData}
            iterations={iterations}
            setIterations={setIterations}
          />
        ) : assignTypeSelectedOption?.value === "IMPLICITA" ? (
          <ImplicitAssign
            criteriaData={criteriaData}
            criteriaSelectedOption={criteriaSelectedOption}
            criteriaSelectedOptionChangeHandler={
              criteriaSelectedOptionChangeHandler
            }
            columnSelectRef={columnSelectRef}
            columnSelectedOption={columnSelectedOption}
            columnSelectedOptionChangeHandler={
              columnSelectedOptionChangeHandler
            }
          />
        ) : (
          <></>
        )}
        {areIterations &&
          iterations.map((iteration: any, index: number) => (
            <IterationElement
              key={index}
              iteration={iteration}
              deleteIteration={confirmDeleteIteration}
              setAreIterationErrors={setAreIterationErrors}
              setIterationToEdit={handleEditIteration}
              tipo_preprorrateo={assignTypeSelectedOption?.value}
            />
          ))}
      </Stack>
      <Dialog
        open={openIterationModal}
        handleClose={toggleIterationModal}
        title={t("Nueva iteración")}
        maxWidth={"xl"}
      >
        <Stack sx={{ width: "1000px", minHeight: "200px" }}>
          <NewIterationModal
            iterations={iterations}
            setIterations={setIterations}
            newSchemaRows={newSchemaRows}
            handleToggleModal={toggleIterationModal}
            criteriaData={criteriaData}
            assignTypeSelectedOption={assignTypeSelectedOption}
            criteriaSelectedOption={criteriaSelectedOption}
            columnSelectedOption={columnSelectedOption}
            destinyTypeSelectedOption={destinyTypeSelectedOption}
            destinitySelectOptions={destinitySelectOptions}
            iterationToEdit={iterationToEdit}
          />
        </Stack>
      </Dialog>
      <AddIterationButton
        onClick={toggleIterationModal}
        isDisabled={
          !isAbleToAddIteration || (areIterations && areIterationErrors)
        }
      />
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ width: "100%", alignSelf: "flex-end" }}
      >
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={() => handleBack()}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={toggleConfirmationModal}
          disabled={
            !schemaName ||
            message?.text !== "" ||
            (areIterations && areIterationErrors)
          }
        />
        <ConfirmationModal
          open={openConfirmationModal}
          handleClose={toggleConfirmationModal}
          handleAccept={createSchema}
          message={
            configMode === "EDIT"
              ? t("Modificar el esquema")
              : t("crear el esquema")
          }
          title={
            configMode === "EDIT" ? t("Modificar esquema") : t("Crear esquema")
          }
        />
        <ConfirmationModal
          open={openDeleteIterationConfirmationModal}
          handleClose={toggleDeleteIterationConfirmationModal}
          handleAccept={deleteIteration}
          message={t("eliminar la iteración")}
          title={t("Eliminar iteración")}
        />
      </Stack>
    </>
  );
};

export default NewSchemaForm;
