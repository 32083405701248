import { AddButton, Button } from "../buttons";
import { DashboardType } from "./types";
import { DELETE_DASHBOARD_URL, DASHBOARDS_URL } from "../../api/axios";
import { Stack, Tooltip, Typography } from "@mui/material";
import { transformDashboardData } from "./utils";
import { useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import ConfirmationModal from "../dialog/ConfirmationModal";
import CustomInput from "../forms/CustomInput";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import useApi from "../../hooks/useApi";
import useDialog from "../../hooks/useDialog";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
interface IAllowedFilesModalContent {
  dataGetDashboards: DashboardType[] | undefined;
  refetchGetDashboards: () => void;
  toggleNewDashboardModal: () => void;
}

const NewDashboardModalContent = ({
  dataGetDashboards,
  toggleNewDashboardModal,
  refetchGetDashboards,
}: IAllowedFilesModalContent) => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const inputDashboardNameRef: any = useRef(null);
  const inputDashboardLinkRef: any = useRef(null);
  const [dashboardName, setDashboardName] = useState<string>("");
  const [dashboardLink, setDashboardLink] = useState<string>("");
  const [selectedDashboardForDelete, setSelectedDashboardForDelete] =
    useState<DashboardType>();
  const [messageError, setMessageError] = useState<string | boolean>(false);
  const [openConfirmationModal, handleToggleConfirmationModal] = useDialog();
  const onSuccessPost = () => {
    refetchGetDashboards();
    setDashboardLink("");
    setDashboardName("");
    toggleNewDashboardModal();
  };
  const { isLoading: isLoadingAddDashboard, callApi: createDashboard } = useApi(
    DASHBOARDS_URL,
    "POST",
    t("dashboard.post.codes"),
    transformDashboardData(dashboardName, dashboardLink),
    onSuccessPost
  );

  const { isLoading: isLoadingDeleteDashboard, callApi: deleteDashboard } =
    useApi(
      selectedDashboardForDelete &&
        DELETE_DASHBOARD_URL(selectedDashboardForDelete.id),
      "DELETE",
      t("dashboard.delete.codes"),
      undefined,
      refetchGetDashboards
    );

  const handleChangeNameValue = (value: any) => {
    const dashboardNameExists = dataGetDashboards
      ? dataGetDashboards.find(
          (dashboard: DashboardType) =>
            dashboard.descripcion.toLowerCase() === value.toLowerCase()
        )
      : false;
    if (dashboardNameExists) {
      setMessageError(t("dashboard.duplicated"));
    } else {
      setMessageError("");
    }
    setDashboardName(value.toUpperCase());
  };

  const handleChangeLinkValue = (value: any) => {
    setMessageError("");
    setDashboardLink(value);
  };

  const handleAddDashboard = () => {
    if (!dashboardName || !dashboardLink) {
      setMessageError(t("general.emptyInputs"));
    } else if (!dashboardLink.includes("https://app.powerbi.com/")) { //TODO permitir otros tipos de tableros
      setMessageError(t("dashboard.invalidLink"));
    } else if (
      !messageError &&
      dashboardLink.includes("https://app.powerbi.com/") //TODO permitir otros tipos de tableros
    ) {
      createDashboard();
    }
  };

  const handleDeleteFileName = () => {
    if (selectedDashboardForDelete) {
      handleToggleConfirmationModal();
      deleteDashboard();
    }
  };

  const handleDeleteConfirmation = (dashboard: DashboardType) => {
    setSelectedDashboardForDelete(dashboard);
    handleToggleConfirmationModal();
  };

  return (
    <Stack 
      alignItems="center"
      minWidth="800px"      
    >
      <SimpleBackdrop
        open={isLoadingAddDashboard}
        message={t("general.loading")}
      />
      <SimpleBackdrop
        open={isLoadingDeleteDashboard}
        message={t("dashboardTexts.newDashboardModalContent.deletingDashboard")}
      />
      <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
        <Stack
          width="90%"
          sx={{
            "& ::placeholder": { textTransform: "initial" },
            "& input": { textTransform: "uppercase" },
          }}
        >
          <Typography sx={{ color: "var(--text-info)" }}>
            {t("dashboardTexts.newDashboardModalContent.name")}
          </Typography>
          <CustomInput
            name="dashboardName"
            inputRef={inputDashboardNameRef}
            value={dashboardName}
            setValue={handleChangeNameValue}
            placeholder={t(
              "dashboardTexts.newDashboardModalContent.dashboardName"
            )}
            autoFocus={true}
          />
        </Stack>
        <Stack width="90%">
          <Typography sx={{ color: "var(--text-info)" }}>Link:</Typography>
          <CustomInput
            name="dashboardName"
            inputRef={inputDashboardLinkRef}
            value={dashboardLink}
            setValue={handleChangeLinkValue}
            placeholder="Link del tablero..."
            autoFocus={false}
            maxLength={500}
          />
        </Stack>
        <Stack
          justifyContent="flex-end"
          sx={{
            margin: 0,
            height: "67px",
          }}
        >
          <AddButton onClick={handleAddDashboard} />
        </Stack>
      </Stack>
      <Stack mt="10px" sx={{ height: "30px" }}>
        {messageError && (
          <Typography sx={{ color: "red" }}>{messageError}</Typography>
        )}
      </Stack>
      <Stack direction="column" width="100%" sx={{ overflow: "auto" }}>
        <Typography
          sx={{
            color: "var(--text-main)",
            fontWeight: "600",
            fontSize: "18px",
            marginBottom: "10px",
          }}
        >
          Tableros
        </Typography>
        <List dense={true} sx={{ width: "100%", height: "280px" }}>
          {dataGetDashboards &&
            dataGetDashboards?.map((dashboard: DashboardType) => {
              return (
                <ListItem
                  key={dashboard.id}
                  secondaryAction={
                    userRolData && userRolData.dashboards.visualizacion_y_edicion ? (
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title={t(
                          "dashboardTexts.newDashboardModalContent.deleteDashboardFromListing"
                        )}
                        sx={{ verticalAlign: "center", fontSize: "16px" }}
                      >
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteConfirmation(dashboard)}
                        >
                          <DeleteIcon
                            sx={{
                              color: "var(--bg-neutral-hover) !important",
                              cursor: "pointer",
                              transition: "all 1s linear",
                              "& :hover": { color: "var(--icon-error)" },
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "var(--icon-info) !important",
                      }}
                    >
                      <DashboardIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      color: "var(--text-main) !important",
                      fontWeight: "bold",
                      maxWidth: "340px",
                    }}
                    primary={
                      <p
                        style={{
                          fontWeight: "bold",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {dashboard.descripcion}
                      </p>
                    }
                    secondary={
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title={dashboard.source}
                        sx={{ verticalAlign: "center", fontSize: "16px" }}
                      >
                        <Typography
                          color="var(--text-info)"
                          component="span"
                          sx={{
                            width: "180%",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            display: "inline-block",
                          }}
                        >
                          {dashboard.source}
                        </Typography>
                      </Tooltip>
                    }
                  />
                </ListItem>
              );
            })}
        </List>
      </Stack>
      <Button
        title={t("dashboardTexts.newDashboardModalContent.close")}
        color="grey"
        type="button"
        onClick={toggleNewDashboardModal}
      />
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleToggleConfirmationModal}
        handleAccept={handleDeleteFileName}
        message={`${t(
          "dashboardTexts.newDashboardModalContent.deleteDashboard"
        ).toLowerCase()} ${selectedDashboardForDelete?.descripcion}`}
        title={t("dashboardTexts.newDashboardModalContent.deleteDashboard")}
      />
    </Stack>
  );
};

export default NewDashboardModalContent;
