import React, { useEffect, useState } from 'react'
import ComponentLayout from '../../layout/ComponentLayout'
import SearchInput from "../../forms/SearchInput";
import FixedAddButton from "../../buttons/FixedAddButton";
import { Box, Stack, Typography } from '@mui/material'
import BasicGrid from '../../basicGrid/BasicGrid';
import { useNavigate } from 'react-router';
import useLanguage from '../../../context/LanguageProvider';
import useInfoContext from '../../../hooks/useInfoContext';
import SpeedIcon from '@mui/icons-material/Speed';
import { filterDataByName } from '../utils';
import useDialog from '../../../hooks/useDialog';
import SimulationCard from './SimulationCard';
import useApi from '../../../hooks/useApi';
import { GET_SIMULATIONS_URL, GET_SIMULATION_INFORMATION, GET_SIMULATION_URL, MODIFICATIONS_TYPES } from '../../../api/axios';
import { useApiQuery } from '../../../hooks/useApiQuery';
import SimpleBackdrop from '../../backdrop/SimpleBackdrop';
import { ConfirmationModal } from '../../dialog';
import ViewInfoSimulationModal from './ViewInfoSimulationModal';

const SimulationAdmin = () => {

    const { userRolData }: any = useInfoContext()
    const navigate = useNavigate();
    const { t } = useLanguage();
    const [searchValue, setSearchValue] = useState<string>("");
    const [filteredData, setFilteredData] = useState<any>();
    const [simulationToDelete, setSimulationToDelete] = useState<number | undefined>();
    const [dataToShow, setDataToShow] = useState<any[]>()
    const [openViewInfoSimulationModal, toggleViewInfoSimulationModal] = useDialog()
    const [openDeleteSimulationConfirmationModal, handleToggleDeleteSimulationConfirmationModal] = useDialog();

    /****************************** CARGA Y OBTENCIÓN DE DATOS ******************************/

    useEffect(()=>{
        localStorage.removeItem("configMode")
        localStorage.removeItem("simulationId")
        localStorage.removeItem("costsForecastId");
        localStorage.removeItem("analysisId")
        localStorage.removeItem("newElementConfig")
        localStorage.removeItem("simulationView")
        localStorage.removeItem("salesForecastView")
        localStorage.removeItem("costForecastView");
        localStorage.removeItem("tabValueEndpoint")
        localStorage.removeItem("previousUrl")
    })

    const {
        isLoading: isLoadingSimulationsInformation,
        data: dataGetSimulationsInformation,
    } = useApiQuery(GET_SIMULATION_INFORMATION, true, undefined);

    const {
        isLoading: isLoadingGetModificationTypes,
        data: modificationTypes
    } = useApiQuery(MODIFICATIONS_TYPES, false);

    const {
        isLoading: isLoadingSimulations,
        data: dataGetSimulations,
        refetch: refetchSimulations,
    } = useApiQuery(GET_SIMULATIONS_URL("RUTAS"), true, undefined);

    const onSuccessGetSimulationById = (data: any) => {
        setDataToShow(data)
    }

    const {
        isLoading: isLoadingGetSimulationById,
        callApi: getSimulationById,
    } = useApi(
        undefined,
        "GET",
        undefined,
        undefined,
        onSuccessGetSimulationById,
        true
    );

    const onSuccessDeleteSimulation = () => {
        refetchSimulations()
        setSimulationToDelete(undefined)
    }

    const { isLoading: isLoadingDeleteSimulation, callApi: deleteSimulationById } =
        useApi(
            GET_SIMULATION_URL(simulationToDelete ? simulationToDelete : undefined),
            "DELETE",
            t("delta.deleteSimulation.codes"),
            undefined,
            onSuccessDeleteSimulation
        );

    useEffect(() => {
        if (!openViewInfoSimulationModal) {
            setDataToShow(undefined)
        }
    })

    /****************************** MANEJO DE FUNCIONES ******************************/

    const handleCreate = () => {
        localStorage.setItem("configMode", "CREATE");
        navigate("/delta/configuracion-simulaciones")
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        filterDataByName(dataGetSimulations, e.target.value, setFilteredData);
    };

    const handleEdit = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        localStorage.setItem("configMode", "EDIT");
        localStorage.setItem("simulationId", String(id));
        navigate("/delta/configuracion-simulaciones")
    };

    // const handleCopy = (e: React.ChangeEvent<HTMLInputElement>, id: number | undefined) => {
    //     localStorage.setItem("configMode", "COPY");
    // };

    const handleInfo = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        getSimulationById(GET_SIMULATION_URL(id ? Number(id) : undefined))
        toggleViewInfoSimulationModal()
    };

    const handleCopy = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        localStorage.setItem("configMode", "COPY");
        localStorage.setItem("simulationId", String(id));
        navigate("/delta/configuracion-simulaciones")
    } 

    const handleClickOnDelete = (e: any, id: number) => {
        setSimulationToDelete(id)
        handleToggleDeleteSimulationConfirmationModal();
    };

    const handleDelete = () => {
        deleteSimulationById()
        handleToggleDeleteSimulationConfirmationModal()
    };

    return (
        <ComponentLayout title={t("deltaTexts.simulation.title")} icon={<SpeedIcon />}>
            <SimpleBackdrop
                open={isLoadingSimulations || isLoadingGetSimulationById || isLoadingDeleteSimulation || isLoadingSimulationsInformation || isLoadingGetModificationTypes}
                message={t("general.loading")}
            />
            <Stack spacing={2} sx={{ width: "100%" }}>
                <SearchInput
                    value={searchValue}
                    handleChange={handleSearch}
                    placeholder={t("simulatorTexts.simulationsRulesAdmin.searchByName")}
                />
            </Stack>
            {
                userRolData && (userRolData.simulador.visualizacion_y_edicion || userRolData.simulador.visualizacion_y_creacion) &&
                <FixedAddButton handleClick={handleCreate} />
            }
            {filteredData?.length || dataGetSimulations?.length ? (
                <>
                    <BasicGrid
                        data={filteredData ? filteredData : dataGetSimulations}
                        Card={SimulationCard}
                        handleEdit={userRolData && userRolData.simulador.visualizacion_y_edicion && handleEdit}
                        handleCopy={handleCopy}
                        handleInfo={handleInfo}
                        handleDelete={userRolData && userRolData.simulador.visualizacion_y_edicion && handleClickOnDelete}
                        showInfo={true}
                        xs={2}
                    />
                </>
            ) : (
                true && (
                    <Box
                        sx={{
                            width: "95%",
                            height: "50vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: "700", color: "var(--text-info)" }}
                        >
                            {t("simulatorTexts.simulations.simulationAdmin.noSimulationsYet")}
                        </Typography>
                    </Box>
                )
            )}
            {dataToShow &&
                <ViewInfoSimulationModal
                    simulation={dataToShow}
                    open={openViewInfoSimulationModal}
                    handleClose={toggleViewInfoSimulationModal}
                    modificationTypes={modificationTypes}
                    dataGetSimulationsInformation={dataGetSimulationsInformation}
                />
            }
            <ConfirmationModal
                open={openDeleteSimulationConfirmationModal}
                handleClose={handleToggleDeleteSimulationConfirmationModal}
                handleAccept={handleDelete}
                message={t("deltaTexts.simulation.simulationAdmin.theSimulation")}
                title={t("simulatorTexts.registerRoutes.registerRoutesAdmin.confirm")}
            />
        </ComponentLayout>
    )
}

export default SimulationAdmin