import { blue_color_styles } from "../../../../styles/app-styles";
import { columnOptions } from "../../../criterias/configCriteria/selectOptions";
import { selectStyles } from "../../../criterias/configCriteria/select.styles";
import { styled } from "@mui/material/styles";
import { SyntheticEvent, useEffect, useRef } from "react";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import Radio from "@mui/material/Radio";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import Select from "../../../forms/Select";
import useLanguage from "../../../../context/LanguageProvider";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
  onChange:
    | ((event: SyntheticEvent<Element, Event>, checked: boolean) => void)
    | undefined;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": {
    color: theme.palette.primary.main,
    fontWeight: checked && "bold",
  },
}));

function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();
  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return (
    <StyledFormControlLabel
      onChange={() => radioGroup?.onChange}
      checked={checked}
      {...props}
    />
  );
}
interface IUseRadioGroup {
  columnSelectedOptions: any;
  columnOptionsChangeHandler: any;
  fileColumnSelectOptions: any;
  fileSelectedOption: any;
  validationValue: any;
  setValidationValue: any;
  previousValidation: any;
  validationsToSend: any;
  setValidationsToSend: any;
}

export default function UseRadioGroup({
  columnSelectedOptions,
  columnOptionsChangeHandler,
  fileColumnSelectOptions,
  fileSelectedOption,
  validationValue,
  setValidationValue,
  previousValidation,
  validationsToSend,
  setValidationsToSend,
}: IUseRadioGroup) {
  const { t } = useLanguage();
  const columnSelectRef: any = useRef(null);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setValidationValue((event.target as HTMLInputElement).value);
    columnSelectRef.current?.clearValue();
  };

  useEffect(() => {
    setValidationValue(
      previousValidation.validar_asignacion_receptor
        ? t("modelParameterizationTexts.useRadioGroup.flaw")
        : t("modelParameterizationTexts.useRadioGroup.doNotValidate")
    );
  }, []);

  useEffect(() => {
    if (
      validationValue !==
      t("modelParameterizationTexts.useRadioGroup.doNotValidate")
    ) {
      const newValidation = {
        agrupadores_relacionados: previousValidation?.agrupadores,
        receptor_gasto: previousValidation?.receptor.id,
        etapa: previousValidation?.etapa_previa,
        columna:
          validationValue === t("modelParameterizationTexts.useRadioGroup.flaw")
            ? previousValidation?.columna_defecto.id
            : columnSelectedOptions?.value,
      };
      //Encontrar en validationsToSend el elemento que tenga el mismo receptor_gasto y etapa que newValidation y reemplazarlo por newValidation (si no existe, agregarlo)
      const index = validationsToSend.findIndex(
        (validation: any) =>
          validation.receptor_gasto === newValidation.receptor_gasto &&
          validation.etapa === newValidation.etapa
      );
      if (index !== -1) {
        const validationsWithoutPrevElement = validationsToSend.filter(
          (val: any) => {
            return val !== validationsToSend[index];
          }
        );
        setValidationsToSend(() => {
          return [...validationsWithoutPrevElement, newValidation];
        });
      } else {
        setValidationsToSend((prev: any) => {
          return [...prev, newValidation];
        });
      }
    } else {
      const newValidation = {
        agrupadores_relacionados: previousValidation?.agrupadores,
        receptor_gasto: previousValidation?.receptor.id,
        etapa: previousValidation?.etapa_previa,
        columna:
          validationValue === t("modelParameterizationTexts.useRadioGroup.flaw")
            ? previousValidation?.columna_defecto.id
            : columnSelectedOptions?.value,
      };
      const index = validationsToSend.findIndex(
        (validation: any) =>
          validation.receptor_gasto === newValidation.receptor_gasto &&
          validation.etapa === newValidation.etapa
      );
      if (index !== -1) {
        const validationsWithoutPrevElement = validationsToSend.filter(
          (val: any) => {
            return val !== validationsToSend[index];
          }
        );
        setValidationsToSend(() => {
          return [...validationsWithoutPrevElement];
        });
      } else {
        setValidationsToSend((prev: any) => {
          return [...prev];
        });
      }
    }
  }, [validationValue, columnSelectedOptions]);

  const renderRadio = () => {
    return <Radio sx={{ "span svg": blue_color_styles }} />;
  };

  const labelTexts = {
    previousValidationsText: previousValidation
      ? `${t("modelParameterizationTexts.useRadioGroup.flawLabel")} ` +
        previousValidation.columna_defecto?.nombre
      : t("modelParameterizationTexts.useRadioGroup.flawLabel"),
    doNotValidateText: t(
      "modelParameterizationTexts.useRadioGroup.doNotValidateLabel"
    ),
    fileSelectedOptionText: fileSelectedOption
      ? `${t("modelParameterizationTexts.useRadioGroup.otherLabel")} - ${t(
          "modelParameterizationTexts.useRadioGroup.file"
        )} ${fileSelectedOption.label}`
      : t("modelParameterizationTexts.useRadioGroup.otherLabel"),
  };

  const isOtherOptionSelected =
    validationValue === t("modelParameterizationTexts.useRadioGroup.other") &&
    fileSelectedOption;

  const getDefaultValue = previousValidation.validar_asignacion_receptor
    ? t("modelParameterizationTexts.useRadioGroup.flaw")
    : t("modelParameterizationTexts.useRadioGroup.doNotValidate");

  return (
    <RadioGroup name="use-radio-group" defaultValue={getDefaultValue}>
      <MyFormControlLabel
        value={t("modelParameterizationTexts.useRadioGroup.flaw")}
        label={labelTexts.previousValidationsText}
        control={renderRadio()}
        onChange={(event: SyntheticEvent<Element, Event>, checked: boolean) =>
          handleChange(event, checked)
        }
      />
      <MyFormControlLabel
        value={t("modelParameterizationTexts.useRadioGroup.doNotValidate")}
        label={labelTexts.doNotValidateText}
        control={renderRadio()}
        onChange={(event: SyntheticEvent<Element, Event>, checked: boolean) =>
          handleChange(event, checked)
        }
      />
      <MyFormControlLabel
        value={t("modelParameterizationTexts.useRadioGroup.other")}
        label={labelTexts.fileSelectedOptionText}
        control={renderRadio()}
        onChange={(event: SyntheticEvent<Element, Event>, checked: boolean) =>
          handleChange(event, checked)
        }
      />
      {isOtherOptionSelected && (
        <FormSelectLayout>
          <Select
            reference={columnSelectRef}
            styles={selectStyles(columnSelectedOptions)}
            options={columnOptions(fileColumnSelectOptions, fileSelectedOption)}
            className="mp_column_select"
            onChange={columnOptionsChangeHandler}
            closeMenuOnSelect={true}
            placeholder={t(
              "modelParameterizationTexts.useRadioGroup.chooseColumn"
            )}
            isClearable={true}
            isSearchable={true}
          />
        </FormSelectLayout>
      )}
    </RadioGroup>
  );
}
