import { GridColDef } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import {
  CostDriverCell,
  VariabilityCell,
  assignColors,
  extractAndFormatDataAsObject,
} from "../utils";
import { rendercell_container_report_table_styles } from "../../../styles/app-styles";
import { roundNumber } from "../utils";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

// Transformar los datos de la tabla
const transformDataRuleTable = (
  t: any,
  dataGetAnalysisMeasures: any,
  dataGetAccuracy: any,
  columnsData: any,
  handleVariableChange: any,
  variableData: any
) => {
  const driverLabels =
    dataGetAccuracy && dataGetAccuracy[0].data.map((item) => item.driver.label);
  const filteredColumns =
    columnsData &&
    columnsData.filter((column) => driverLabels.includes(column.columna));

  // Crear columnas
  const columnsInfo = filteredColumns && [
    t("deltaTexts.simulationRules.transformDataRuleTable.measure"),
    "CAGRs",
    ...filteredColumns
      .filter(
        (result) =>
          result.informacion !== "Identificador único de ruta" &&
          result.columna !== null
      )
      .map((item) => item.columna),
    t("deltaTexts.simulationRules.transformDataRuleTable.variability"),
    t("deltaTexts.simulationRules.transformDataRuleTable.costDriver"),
  ];

  const arrayColumns =
    columnsInfo &&
    columnsInfo.map((column: string) => ({
      field: column,
      headerName: column,
      sortable: false,
      minWidth:
        column ===
          t("deltaTexts.simulationRules.transformDataRuleTable.measure") ||
        column ===
          t("deltaTexts.simulationRules.transformDataRuleTable.costDriver")
          ? 200
          : column ===
            t("deltaTexts.simulationRules.transformDataRuleTable.variability")
          ? 100
          : 100,
      overflow: "visible",
      resize: true,
      flex:
        column ===
        t("deltaTexts.simulationRules.transformDataRuleTable.costDriver")
          ? 2
          : 1,
      renderHeader: (params: any) => (
        <Tooltip
          title={
            column === "CAGRs"
              ? t("deltaTexts.simulationRules.transformDataRuleTable.cagrsText")
              : column ===
                t(
                  "deltaTexts.simulationRules.transformDataRuleTable.costDriver"
                )
              ? t(
                  "deltaTexts.simulationRules.transformDataRuleTable.variabilityText"
                )
              : column ===
                t(
                  "deltaTexts.simulationRules.transformDataRuleTable.variability"
                )
              ? t(
                  "deltaTexts.simulationRules.transformDataRuleTable.driverText"
                )
              : ""
          }
          placement="bottom"
        >
          <Stack
            sx={{
              alignItems: "center",
              color: "var(--text-main)",
              flexDirection: "row",
              fontSize: "12px",
              fontWeight: "bold",
              height: "auto",
              justifyContent: "center",
              lineHeight: "1.1",
              margin: 0,
              textAlign: "center",
              whiteSpace: "normal",
              width: "100%",
              wordBreak: "break-word",
              userSelect: "none",
              gap: 1,
            }}
          >
            {params.field}
            {column === "CAGRs" ||
            column ===
              t(
                "deltaTexts.simulationRules.transformDataRuleTable.costDriver"
              ) ||
            column ===
              t(
                "deltaTexts.simulationRules.transformDataRuleTable.variability"
              ) ? (
              <InfoIcon
                color={"primary"}
                sx={{
                  fontSize: "20px",
                  transform: "scaleX(-1)",
                }}
              />
            ) : (
              <></>
            )}
          </Stack>
        </Tooltip>
      ),
      renderCell: (params: any) =>
        renderCell(params, column, {
          dataGetAnalysisMeasures,
          dataGetAccuracy,
          handleVariableChange,
          variableData,
          t,
          columnsData,
        }),
    }));

  // Crear filas
  const rows =
    dataGetAnalysisMeasures?.medidas
      ?.filter((elemento) => elemento.bucket_agrupador)
      ?.map((expenseMeasure) => {
        const correlationMeasure = dataGetAccuracy?.find(
          (correlation: any) => correlation.medida_gasto === expenseMeasure.id
        );
        const informationsMeasures = correlationMeasure?.data?.reduce(
          (acc, information) => {
            if (
              information.driver?.label &&
              information.driver?.value !== undefined
            ) {
              acc[information.driver.label] = information.correlation;
            }
            return acc;
          },
          {}
        );
        return {
          id: expenseMeasure.id,
          [t("deltaTexts.simulationRules.transformDataRuleTable.measure")]:
            expenseMeasure.nombre,
          CAGRs: correlationMeasure?.cagr,
          [t("deltaTexts.simulationRules.transformDataRuleTable.variability")]:
            variableData?.find((variable) => variable.id === expenseMeasure.id)
              ?.variabilidad || 0,
          ...informationsMeasures,
        };
      }) || [];

  // Añadir fila inicial
  const firstRow = {
    id: "cagrsInfo",
    [t("deltaTexts.simulationRules.transformDataRuleTable.measure")]: (
      <Tooltip
        title={t("deltaTexts.simulationRules.transformDataRuleTable.cagrsText")}
        placement="bottom"
      >
        <Stack
          sx={{
            alignItems: "center",
            color: "var(--text-main)",
            flexDirection: "row",
            fontSize: "12px",
            fontWeight: "bold",
            height: "auto",
            justifyContent: "center",
            lineHeight: "1.1",
            margin: 0,
            textAlign: "center",
            whiteSpace: "normal",
            width: "100%",
            wordBreak: "break-word",
            userSelect: "none",
            gap: 1,
          }}
        >
          CAGRs
          <InfoIcon
            color={"primary"}
            sx={{
              fontSize: "20px",
              transform: "scaleX(-1)",
            }}
          />
        </Stack>
      </Tooltip>
    ),
    CAGRs: "",
    ...extractAndFormatDataAsObject(dataGetAccuracy),
    [t("deltaTexts.simulationRules.transformDataRuleTable.variability")]: "",
    [t("deltaTexts.simulationRules.transformDataRuleTable.costDriver")]: "",
  };
  rows.unshift(firstRow);

  // Definir las columnas
  const columns: GridColDef[] =
    arrayColumns?.map((col) => ({
      ...col,
      headerAlign: "center",
      align: "center",
    })) || [];

  return { columns, rows };
};

// Renderizar celda según tipo de columna
const renderCell = (params: any, column: string, context: any) => {
  const {
    dataGetAnalysisMeasures,
    dataGetAccuracy,
    handleVariableChange,
    variableData,
    t,
    columnsData,
  } = context;

  if (params.id !== "cagrsInfo") {
    const expenseMeasure = dataGetAnalysisMeasures?.medidas
      ?.filter((elemento) => elemento.bucket_agrupador)
      ?.find((medida) => medida.id === params.id);
    const variableMeasure =
      expenseMeasure &&
      variableData?.find((variable) => variable.id === expenseMeasure?.id);

    if (
      column ===
      t("deltaTexts.simulationRules.transformDataRuleTable.variability")
    ) {
      return (
        <VariabilityCell
          value={variableMeasure?.variabilidad}
          onChange={(e) =>
            handleVariabilityChange(
              e,
              expenseMeasure,
              variableMeasure,
              handleVariableChange
            )
          }
        />
      );
    } else if (
      column ===
      t("deltaTexts.simulationRules.transformDataRuleTable.costDriver")
    ) {
      return <CostDriverCell variableMeasure={variableMeasure} />;
    } else if (column === "CAGRs") {
      return (
        <Stack
          sx={{
            ...rendercell_container_report_table_styles,
            backgroundColor: "#272734",
            color: "white",
            fontWeight: "bold",
          }}
        >
          {roundNumber(params.value * 100, 3)} %
        </Stack>
      );
    } else if (
      column === t("deltaTexts.simulationRules.transformDataRuleTable.measure")
    ) {
      return (
        <Stack sx={{ ...rendercell_container_report_table_styles }}>
          {params.value}
        </Stack>
      );
    } else {
      // Manejar otras celdas con asignación de colores
      const availableColumns =
        columnsData &&
        new Set(
          columnsData
            .filter(
              (result) =>
                result.informacion !== "Identificador único de ruta" &&
                result.columna !== null
            )
            .map((item) => item.columna)
        );

      const filteredData =
        dataGetAccuracy &&
        dataGetAccuracy.map((item) => ({
          ...item,
          data: item.data.filter((dataItem) =>
            availableColumns.has(dataItem.driver.label)
          ),
        }));

      const correlations = filteredData
        ?.find((item) => item.medida_gasto === params.id)
        ?.data.map((item) => item.correlation);
      const sortedCorrelations = correlations
        ? [...correlations].sort((a, b) => b - a)
        : [];
      const colorMap = assignColors(sortedCorrelations);
      const color = colorMap.get(params.value);

      return (
        <Tooltip
          title={
            <span
              style={{
                whiteSpace: "normal",
                display: "flex",
                textAlign: "center",
                width: 195,
              }}
            >
              {`${t(
                "deltaTexts.simulationRules.transformDataRuleTable.correlationBetween"
              )} ${column} ${t(
                "deltaTexts.simulationRules.transformDataRuleTable.and"
              )} ${
                params.row[
                  t(
                    "deltaTexts.simulationRules.transformDataRuleTable.measure"
                  ).toUpperCase()
                ]
              }`}
            </span>
          }
          placement="top-start"
          PopperProps={{
            disablePortal: true,
          }}
        >
          <Stack
            key={params.id}
            onClick={() =>
              handleCorrelationClick(
                params,
                dataGetAccuracy,
                handleVariableChange,
                expenseMeasure
              )
            }
            sx={{
              ...rendercell_container_report_table_styles,
              backgroundColor: color,
              color: params.value < 0 ? "var(--blue)" : "#fff",
              cursor: "pointer",
              fontWeight: "bold",
              padding: "10px 20px", 
              transition: "all 0.3s ease", 
              ":hover": {
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)", 
                transform: "translateY(-2px)",
              },
            }}
          >
            {roundNumber(params.value, 4)}
          </Stack>
        </Tooltip>
      );
    }
  } else if (
    column ===
      t("deltaTexts.simulationRules.transformDataRuleTable.variability") ||
    column === "CAGRs" ||
    column === t("deltaTexts.simulationRules.transformDataRuleTable.costDriver")
  ) {
    return (
      <Stack
        key={params.id}
        sx={{
          fontWeight: "bold",
          ...rendercell_container_report_table_styles,
        }}
      >
        {params.value}
      </Stack>
    );
  } else if (
    column === t("deltaTexts.simulationRules.transformDataRuleTable.measure")
  ) {
    return (
      <Stack
        sx={{
          alignItems: "center",
          color: "var(--text-main)",
          flexDirection: "row",
          fontSize: "12px",
          fontWeight: "bold",
          height: "auto",
          justifyContent: "center",
          lineHeight: "1.1",
          margin: 0,
          textAlign: "center",
          whiteSpace: "normal",
          width: "100%",
          wordBreak: "break-word",
        }}
      >
        {params.value}
      </Stack>
    );
  } else {
    return (
      <Stack
        key={params.id}
        sx={{
          backgroundColor: "#272734",
          color: "white",
          cursor: "auto",
          fontWeight: "bold",
          alignItems: "center",
          direction: "row",
          height: "100%",
          justifyContent: "center",
          padding: 0,
          width: "100%",
        }}
      >
        {params.value.replace("%", " %")}
      </Stack>
    );
  }
};

// Función para manejar el cambio de variabilidad
const handleVariabilityChange = (
  e,
  expenseMeasure,
  variableMeasure,
  handleVariableChange
) => {
  let newValue = e.target.value;
  newValue = newValue.replace(/^0+(?=\d)/, "");
  const numericValue = parseFloat(newValue);

  if (!isNaN(numericValue)) {
    handleVariableChange(
      Math.min(numericValue, 100),
      expenseMeasure.id,
      variableMeasure?.option,
      expenseMeasure
    );
  } else {
    handleVariableChange(
      0,
      expenseMeasure.id,
      variableMeasure?.option,
      expenseMeasure
    );
  }
};

// Función para manejar el clic en la correlación
const handleCorrelationClick = (
  params,
  dataGetAccuracy,
  handleVariableChange,
  expenseMeasure
) => {
  const correlationData = dataGetAccuracy?.find(
    (item) => item.medida_gasto === params.id
  )?.data;
  const clickedItem = correlationData?.find(
    (item) => item.correlation === params.value
  );
  handleVariableChange(
    clickedItem?.variabilidad,
    params.id,
    clickedItem?.driver,
    expenseMeasure
  );
};

export const transformData = transformDataRuleTable;
