import { formatOptions } from "../../../utils/util";
import { GET_BUCKETS_URL } from "../../../api/axios";
import { max_level_radiobutton_container } from "../../../styles/app-styles";
import { selectStyles } from "../configCriteria/select.styles";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useState } from "react";
import Button from "../../buttons/Button";
import RadioButtonRow from "../../companyParameterization/StepperView/Step2/RadioButtonRow";
import Select from "../../forms/Select";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../context/LanguageProvider";
import useMultiSelect from "../../../hooks/useMultiSelect";

interface FilterCriteriaModalProps {
  filterSelectedOption: any;
  setFilterSelectedOption: any;
  bucketsList: any;
  setBucketsList: any;
  toggleFilterModal: any;
  handleApply: any;
}

const FilterCriteriaModal = ({
  filterSelectedOption,
  setFilterSelectedOption,
  bucketsList,
  setBucketsList,
  toggleFilterModal,
  handleApply,
}: FilterCriteriaModalProps) => {
  const { t } = useLanguage();
  const [filterValue, setFilterValue] = useState(
    filterSelectedOption ? filterSelectedOption : ""
  );
  const { data: dataGetCriteriaBucket } = useApiQuery(
    GET_BUCKETS_URL,
    false,
    t("configCriteria.getCriteriaBucket.error")
  );
  const [
    bucketSelectedOptions,
    setBucketSelectedOptions,
    bucketOptionsChangeHandler,
  ] = useMultiSelect(bucketsList ? bucketsList : undefined, undefined);

  const onChangeInput = (e) => {
    setFilterValue(e.target.id);
    if (e.target.id !== "criteriaBuckets") {
      setBucketSelectedOptions(undefined);
    }
  };

  const handleAccept = () => {
    setFilterSelectedOption(filterValue);
    setBucketsList(bucketSelectedOptions);
    handleApply(filterValue, bucketSelectedOptions);
  };

  return (
    <Stack sx={{ width: "400px", minHeight: "300px" }} gap={2}>
      <Stack sx={max_level_radiobutton_container}>
        <RadioButtonRow
          id={"withoutCriteriaFilter"}
          selectedOption={filterValue}
          onChangeInput={onChangeInput}
          groupName={"criteriaFilters"}
          labelText={t("criteriaManagementTexts.criteriaManagement.noFilters")}
        />
      </Stack>
      <Stack sx={{max_level_radiobutton_container, minWidth: "250px"}}>
        <RadioButtonRow
          id={"criteriaBuckets"}
          selectedOption={filterValue}
          onChangeInput={onChangeInput}
          groupName={"criteriaFilters"}
          labelText={t("criteriaManagementTexts.criteriaManagement.bucketsWhereItsUsed")}
        />
        {filterValue === "criteriaBuckets" && (
          <Select
            styles={selectStyles(bucketSelectedOptions)}
            options={formatOptions(dataGetCriteriaBucket)}
            className="cc_select"
            onChange={bucketOptionsChangeHandler}
            placeholder={t("general.selectOptions")}
            defaultValue={bucketSelectedOptions}
            isMulti
            isClearable
            closeMenuOnSelect={false}
          />
        )}
      </Stack>
      <Stack sx={max_level_radiobutton_container}>
        <RadioButtonRow
          id={"activeCriteria"}
          selectedOption={filterValue}
          onChangeInput={onChangeInput}
          groupName={"criteriaFilters"}
          labelText={t(
            "criteriaManagementTexts.criteriaManagement.activeCriteria"
          )}
        />
      </Stack>
      <Stack sx={max_level_radiobutton_container}>
        <RadioButtonRow
          id={"inactiveCriteria"}
          selectedOption={filterValue}
          onChangeInput={onChangeInput}
          groupName={"criteriaFilters"}
          labelText={t(
            "criteriaManagementTexts.criteriaManagement.noActiveCriteria"
          )}
        />
      </Stack>
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <Button
          title={t("general.cancel")}
          color="grey"
          type="button"
          onClick={toggleFilterModal}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={handleAccept}
          disabled={
            filterValue === "criteriaBuckets" &&
            (!bucketSelectedOptions || bucketSelectedOptions.length === 0)
          }
        />
      </Stack>
    </Stack>
  );
};

export default FilterCriteriaModal;
