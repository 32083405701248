import { columnOptions } from "../components/criterias/configCriteria/selectOptions";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useApiQuery } from "../hooks/useApiQuery";
import { useNavigate } from "react-router-dom";
import useApi from "../hooks/useApi";
import useLanguage from "./LanguageProvider";
import useMultiSelect from "../hooks/useMultiSelect";
import useSingleSelect from "../hooks/useSingleSelect";
import {
  FILE_COLUMNS_AGRUPACION_URL,
  FILE_COLUMNS_TYPE_URL,
  MARKET_BASKET_URL,
  SINGLE_MARKET_BASKET_URL,
} from "../api/axios";

const MarketBasketConfigContext = createContext<any>({});

const MarketBasketConfigProvider: any = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useLanguage();
  const navigate = useNavigate();

  /************************************** Estados generales ************************************/

  const [activeStep, setActiveStep] = useState(0);
  const [isStepComplete, setIsStepComplete] = useState({
    step1: false,
  });
  const [message, setMessage] = useState("");
  const [marketBasketElement, setMarketBasketElement] = useState<any>(
    /* emptyMarketBasket */ {
      id: undefined,
      nombre: "",
      archivo: undefined,
      archivos_merge: [],
      columna_SKU: undefined,
      canal: undefined,
      venta_moneda: undefined,
      venta_unidades: undefined,
      marca: undefined,
      nivel_de_analisis: undefined,
      tipo_nivel_de_analisis: undefined, // "Cadena cliente" | "Punto de venta"
      periodicidad: undefined, //"mensual", "trimestral", "anual", "total", "no_consolidar"
      columna_transaccion: undefined, // Esta va solo si periodicidad === "no_consolidar"
      columnas_extra: [], //Solo cuando el archivo es tipo TEMPLATE
      parametros_avanzados: {
        soporte_minimo_apriori: 0.03,
        soporte_minimo_conjunto: 0.025,
        soporte_minimo_antecedente: 0.1,
        soporte_minimo_consecuente: 0.05,
        confianza_minima: 0.6,
        lift_minimo: 2.5,
      },
      objetivos: [],
      metricas_SKU_primarias: [],
      metricas_SKU_secundarias: [],
      metricas_destino_primarias: [],
      metricas_destino_secundarias: [],
      graficas: [],
      indicadores_destino: [],
    }
  );

  const [totalColumnOptions, setTotalColumnOptions] = useState<any>(undefined);

  /***************************** Step 1 ***************************************/

  const nameRef: any = useRef(null);
  const fileSelectRef: any = useRef(null);
  const columnsSKUSelectRef: any = useRef(null);
  const columnCanalSelectRef: any = useRef(null);
  const ventaMonedaSelectRef: any = useRef(null);
  const ventaUnidadesSelectRef: any = useRef(null);
  const marcaSelectRef: any = useRef(null);
  const nivelAnalisisSelectRef: any = useRef(null);
  const periodicidadSelectRef: any = useRef(null);
  const columnaTransaccionSelectRef: any = useRef(null);
  const columnasExtraSelectRef: any = useRef(null);
  const [mbName, setMbName] = useState("");

  const onFileChange = () => {    
    setColumnSKUSelectedOptions(undefined);
    columnsSKUSelectRef?.current?.clearValue();
    setColumnCanalSelectedOptions(undefined);
    columnCanalSelectRef?.current?.clearValue();
    setVentaMonedaSelectedOption(undefined);
    ventaMonedaSelectRef?.current?.clearValue();
    setVentaUnidadesSelectedOption(undefined);
    ventaUnidadesSelectRef?.current?.clearValue();
    setMarcaSelectedOption(undefined);
    marcaSelectRef?.current?.clearValue();
    setNivelAnalisisSelectedOption(undefined);
    nivelAnalisisSelectRef?.current?.clearValue();
    setPeriodicidadSelectedOption(undefined);
    periodicidadSelectRef?.current?.clearValue();
    setColumnaTransaccionSelectedOption(undefined);
    columnaTransaccionSelectRef?.current?.clearValue();
    setColumnasExtraSelectedOptions(undefined);
    columnasExtraSelectRef?.current?.clearValue();
    setColumn1SelectedOption(undefined);
    column1SelectRef?.current?.clearValue();
    setColumn2SelectedOption(undefined);
    column2SelectRef?.current?.clearValue();
    setColumn3SelectedOption(undefined);
    column3SelectRef?.current?.clearValue();
    setColumn4SelectedOption(undefined);
    column4SelectRef?.current?.clearValue();
    setColumn5SelectedOption(undefined);
    column5SelectRef?.current?.clearValue();

    column1SKUSelectRef?.current?.clearValue();
    column2SKUSelectRef?.current?.clearValue();
    column3SKUSelectRef?.current?.clearValue();
    column4SKUSelectRef?.current?.clearValue();
    column5SKUSelectRef?.current?.clearValue();
    setColumn1SKUSelectedOption("");
    setColumn2SKUSelectedOption(undefined);
    setColumn3SKUSelectedOption(undefined);
    setColumn4SKUSelectedOption(undefined);
    setColumn5SKUSelectedOption(undefined);
  
    column2DestinySelectRef?.current?.clearValue();
    column3DestinySelectRef?.current?.clearValue();
    column4DestinySelectRef?.current?.clearValue();
    column5DestinySelectRef?.current?.clearValue();
    column6DestinySelectRef?.current?.clearValue();
    setColumn1DestinySelectedOption("");
    setColumn2DestinySelectedOption(undefined);
    setColumn3DestinySelectedOption(undefined);
    setColumn4DestinySelectedOption(undefined);
    setColumn5DestinySelectedOption(undefined);
    setColumn6DestinySelectedOption(undefined);

    setMarketBasketElement((prev) => ({
      ...prev,
      archivos_merge: [],
      columna_SKU: undefined,
      canal: undefined,
      venta_moneda: undefined,
      venta_unidades: undefined,
      marca: undefined,
      nivel_de_analisis: undefined,
      tipo_nivel_de_analisis: undefined, // "Cadena cliente" | "Punto de venta"
      periodicidad: undefined, //"mensual", "trimestral", "anual", "total", "no_consolidar"
      columna_transaccion: undefined, // Esta va solo si periodicidad === "no_consolidar"
      columnas_extra: [], 
      objetivos: [],
      metricas_SKU_primarias: [],
      metricas_SKU_secundarias: [],
      metricas_destino_primarias: [],
      metricas_destino_secundarias: [],
      graficas: [],
      indicadores_destino: [],
    }));
  };

  const [fileSelectedOption, setFileSelectedOption, fileOptionChangeHandler] =
    useSingleSelect(() => onFileChange());

  const [
    columnSKUSelectedOption,
    setColumnSKUSelectedOptions,
    columnSKUOptionChangeHandler,
  ] = useSingleSelect();

  const [
    columnCanalSelectedOption,
    setColumnCanalSelectedOptions,
    columnCanalOptionChangeHandler,
  ] = useSingleSelect();

  const [
    ventaMonedaSelectedOption,
    setVentaMonedaSelectedOption,
    ventaMonedaOptionChangeHandler,
  ] = useSingleSelect();

  const [
    ventaUnidadesSelectedOption,
    setVentaUnidadesSelectedOption,
    ventaUnidadesOptionChangeHandler,
  ] = useSingleSelect();

  const [
    marcaSelectedOption,
    setMarcaSelectedOption,
    marcaOptionChangeHandler,
  ] = useSingleSelect();

  const [
    nivelAnalisisSelectedOption,
    setNivelAnalisisSelectedOption,
    nivelAnalisisOptionChangeHandler,
  ] = useSingleSelect();

  const [
    periodicidadSelectedOption,
    setPeriodicidadSelectedOption,
    periodicidadOptionChangeHandler,
  ] = useSingleSelect(() => onPeriodicidadChange());

  const onPeriodicidadChange = () => {
    setColumnaTransaccionSelectedOption(undefined);
    columnaTransaccionSelectRef?.current?.clearValue();
  };

  const [
    columnaTransaccionSelectedOption,
    setColumnaTransaccionSelectedOption,
    columnaTransaccionOptionChangeHandler,
  ] = useSingleSelect();

  const [
    columnasExtraSelectedOptions,
    setColumnasExtraSelectedOptions,
    columnasExtraOptionsChangeHandler,
  ] = useMultiSelect();

  /* ------------- Step 1 - Objetivos ----------------- */

  const column1SelectRef: any = useRef(null);
  const column2SelectRef: any = useRef(null);
  const column3SelectRef: any = useRef(null);
  const column4SelectRef: any = useRef(null);
  const column5SelectRef: any = useRef(null);
  const [
    column1SelectedOption,
    setColumn1SelectedOption,
    column1OptionsChangeHandler,
  ] = useSingleSelect();

  const [
    column2SelectedOption,
    setColumn2SelectedOption,
    column2OptionsChangeHandler,
  ] = useSingleSelect();

  const [
    column3SelectedOption,
    setColumn3SelectedOption,
    column3OptionsChangeHandler,
  ] = useSingleSelect();

  const [
    column4SelectedOption,
    setColumn4SelectedOption,
    column4OptionsChangeHandler,
  ] = useSingleSelect();

  const [
    column5SelectedOption,
    setColumn5SelectedOption,
    column5OptionsChangeHandler,
  ] = useSingleSelect();

  /************************************** Step 2 ****************************************/

  const column1SKUSelectRef: any = useRef(null);
  const column2SKUSelectRef: any = useRef(null);
  const column3SKUSelectRef: any = useRef(null);
  const column4SKUSelectRef: any = useRef(null);
  const column5SKUSelectRef: any = useRef(null);

  const [column1SKUSelectedOption, setColumn1SKUSelectedOption] = useState("");

  const onSKUFunctionChange = () => {
    setColumn4SKUSelectedOption(undefined);
    column4SKUSelectRef?.current?.clearValue();
  };

  const [
    column2SKUSelectedOption,
    setColumn2SKUSelectedOption,
    column2SKUOptionsChangeHandler,
  ] = useSingleSelect(() => onSKUFunctionChange());

  const [
    column3SKUSelectedOption,
    setColumn3SKUSelectedOption,
    column3SKUOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    column4SKUSelectedOption,
    setColumn4SKUSelectedOption,
    column4SKUOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    column5SKUSelectedOption,
    setColumn5SKUSelectedOption,
    column5SKUOptionsChangeHandler,
  ] = useSingleSelect();

  /* ------------- step 2 - Cálculo --------------- */
  const [formulaSKU, setFormulaSKU] = useState<
    { tipo: string; dato: string | number; orden: number }[]
  >([]);

  /******************************************* Step 3 *****************************************/

  const column1DestinySelectRef: any = useRef(null);
  const column2DestinySelectRef: any = useRef(null);
  const column3DestinySelectRef: any = useRef(null);
  const column4DestinySelectRef: any = useRef(null);
  const column5DestinySelectRef: any = useRef(null);
  const column6DestinySelectRef: any = useRef(null);
  const [column1DestinySelectedOption, setColumn1DestinySelectedOption] =
    useState("");

  const onDestinyFunctionChange = () => {
    setColumn4DestinySelectedOption(undefined);
    column4DestinySelectRef?.current?.clearValue();
  };

  const [
    column2DestinySelectedOption,
    setColumn2DestinySelectedOption,
    column2DestinyOptionsChangeHandler,
  ] = useSingleSelect(() => onDestinyFunctionChange());

  const [
    column3DestinySelectedOption,
    setColumn3DestinySelectedOption,
    column3DestinyOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    column4DestinySelectedOption,
    setColumn4DestinySelectedOption,
    column4DestinyOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    column5DestinySelectedOption,
    setColumn5DestinySelectedOption,
    column5DestinyOptionsChangeHandler,
  ] = useSingleSelect();

  const [
    column6DestinySelectedOption,
    setColumn6DestinySelectedOption,
    column6OptionsChangeHandler,
  ] = useSingleSelect();

  /* ------------- step 3 - Cálculo --------------- */
  const [formulaDestiny, setFormulaDestiny] = useState<
    { tipo: string; dato: string | number; orden: number }[]
  >([]);

  /**************************** Api Querys **********************************/

  const {
    isLoading: isLoadingGetAnalysisMBs,
    data: dataAnalysisMBs,
    refetch: refetchGetAnalysisMBs,
  } = useApiQuery(MARKET_BASKET_URL, false, t("dataModel.getAnalysis.error"));

  const {
    isLoading: isLoadingGetFileColumnsType,
    data: dataGetFileColumnsType,
    refetch: refetchGetFileColumnsType,
  } = useApiQuery(
    FILE_COLUMNS_TYPE_URL,
    false,
    t("dataModel.getAnalysis.error")
  );

  const {
    data: dataGetFileColumnsAgrupation,
  } = useApiQuery(
    FILE_COLUMNS_AGRUPACION_URL,
    false,
    t("dataModel.getAnalysis.error")
  );

  useEffect(() => {
    if (dataGetFileColumnsType && fileSelectedOption) {
      const updatedColumns = [
        {
          label: fileSelectedOption?.label,
          options: columnOptions(dataGetFileColumnsType, fileSelectedOption),
        },
      ];
      marketBasketElement?.archivos_merge?.forEach((base: any) =>
        updatedColumns?.push({
          label: base?.archivo?.label,
          options: base?.columnas,
        })
      );
      setTotalColumnOptions(updatedColumns);
    }
  }, [
    dataGetFileColumnsType,
    fileSelectedOption,
    marketBasketElement?.archivos_merge?.length,
  ]);

  const onSuccessPost = () => {
    navigate("/market-basket/administracion-analisis");
  };

  const prepareCompletedInfo = {
    id: marketBasketElement?.id,
    nombre: marketBasketElement?.nombre,
    archivo: marketBasketElement?.archivo?.value,
    archivos_merge: marketBasketElement?.archivos_merge?.map((base) => {
      return {
        archivo: base.archivo.value,
        columnas: base.columnas.map((columna) => columna.value),
        eliminar_duplicados: base.eliminar_duplicados,
        merge: {
          columnas_base_anterior: base.merge.columnas_base_anterior.map(
            (columna) => columna.value
          ),
          columnas_base_nueva: base.merge.columnas_base_nueva.map(
            (columna) => columna.value
          ),
        },
      };
    }),
    columna_SKU: marketBasketElement?.columna_SKU?.value,
    canal: marketBasketElement?.canal?.value,
    venta_moneda: marketBasketElement?.venta_moneda?.value,
    venta_unidades: marketBasketElement?.venta_unidades?.value,
    marca: marketBasketElement?.marca?.value,
    nivel_de_analisis: marketBasketElement?.nivel_de_analisis?.value,
    tipo_nivel_de_analisis: marketBasketElement?.tipo_nivel_de_analisis,
    periodicidad: marketBasketElement?.periodicidad?.value,
    columna_transaccion: marketBasketElement?.columna_transaccion?.value,
    columnas_extra: marketBasketElement?.columnas_extra?.map(
      (columna: any) => columna.value
    ),
    parametros_avanzados: marketBasketElement?.parametros_avanzados,
    objetivos: marketBasketElement?.objetivos?.map((objetivo: any) => {
      return {
        coleccion_objetivos: objetivo?.coleccion_objetivos?.value,
        columna_valor_objetivo: objetivo?.columna_valor_objetivo?.value,
        identificador_dueño_objetivo:
          objetivo?.identificador_dueño_objetivo?.value,
        columna_objetivo_analisis: objetivo?.columna_objetivo_analisis?.value,
        identificador_dueño_objetivo_analisis:
          objetivo?.identificador_dueño_objetivo_analisis?.value,
      };
    }),
    metricas_SKU_primarias: marketBasketElement?.metricas_SKU_primarias?.map(
      (metrica: any) => {
        return {
          nombre: metrica.nombre,
          funcion: metrica.funcion.value,
          columna: metrica.columna.value,
          columna_divisora: metrica?.columna_divisora?.value,
          consolidar_periodos: metrica.consolidar_periodos?.value,
        };
      }
    ),
    metricas_SKU_secundarias:
      marketBasketElement?.metricas_SKU_secundarias?.map((metrica: any) => {
        return {
          nombre: metrica.nombre,
          formula: metrica.formula,
        };
      }),
    metricas_destino_primarias:
      marketBasketElement?.metricas_destino_primarias?.map((metrica: any) => {
        return {
          nombre: metrica.nombre,
          funcion: metrica?.funcion?.value,
          columna: metrica?.columna?.value,
          columna_divisora: metrica?.columna_divisora?.value,
          consolidar_periodos: metrica.consolidar_periodos?.value,
          consolidar_destinos: metrica?.consolidar_destinos?.value,
        };
      }),
    metricas_destino_secundarias:
      marketBasketElement?.metricas_destino_secundarias,
    graficas: marketBasketElement?.graficas?.map((grafica: any) => {
      return {
        nombre: grafica.nombre,
        tipo: grafica.tipo,
        funcion: grafica?.funcion?.value,
        variable: grafica?.variable?.value,
        indicador: grafica?.indicador?.value,
      };
    }),
    indicadores_destino: marketBasketElement?.indicadores_destino.map(
      (indicador: any) => {
        return {
          nombre: indicador.nombre,
          unidad: indicador.unidad?.value,
          drilldown: indicador.drilldown,
        };
      }
    ),
  };

  const { isLoading: isLoadingPostMarketBasket, callApi: postMarketBasket } =
    useApi(
      MARKET_BASKET_URL,
      "POST",
      undefined,
      prepareCompletedInfo,
      onSuccessPost,
      undefined,
      true
    );

  const { isLoading: isLoadingPutMarketBasket, callApi: putMarketBasket } =
    useApi(
      SINGLE_MARKET_BASKET_URL(undefined),
      "PUT",
      undefined,
      prepareCompletedInfo,
      onSuccessPost,
      undefined,
      true
    );

  return (
    <MarketBasketConfigContext.Provider
      value={{
        activeStep,
        setActiveStep,
        isStepComplete,
        setIsStepComplete,
        message,
        setMessage,
        isLoadingGetAnalysisMBs,
        dataAnalysisMBs,
        refetchGetAnalysisMBs,
        isLoadingGetFileColumnsType,
        dataGetFileColumnsType,
        totalColumnOptions,
        refetchGetFileColumnsType,
        marketBasketElement,
        setMarketBasketElement,
        postMarketBasket,
        putMarketBasket,
        isLoadingPostMarketBasket,
        isLoadingPutMarketBasket,
        nameRef,
        fileSelectRef,
        columnsSKUSelectRef,
        columnCanalSelectRef,
        ventaMonedaSelectRef,
        ventaUnidadesSelectRef,
        marcaSelectRef,
        nivelAnalisisSelectRef,
        periodicidadSelectRef,
        columnaTransaccionSelectRef,
        columnasExtraSelectRef,
        mbName,
        setMbName,
        fileSelectedOption,
        setFileSelectedOption,
        fileOptionChangeHandler,
        columnSKUSelectedOption,
        setColumnSKUSelectedOptions,
        columnSKUOptionChangeHandler,
        columnCanalSelectedOption,
        setColumnCanalSelectedOptions,
        columnCanalOptionChangeHandler,
        ventaMonedaSelectedOption,
        setVentaMonedaSelectedOption,
        ventaMonedaOptionChangeHandler,
        ventaUnidadesSelectedOption,
        setVentaUnidadesSelectedOption,
        ventaUnidadesOptionChangeHandler,
        marcaSelectedOption,
        setMarcaSelectedOption,
        marcaOptionChangeHandler,
        nivelAnalisisSelectedOption,
        setNivelAnalisisSelectedOption,
        nivelAnalisisOptionChangeHandler,
        periodicidadSelectedOption,
        setPeriodicidadSelectedOption,
        periodicidadOptionChangeHandler,
        columnaTransaccionSelectedOption,
        setColumnaTransaccionSelectedOption,
        columnaTransaccionOptionChangeHandler,
        columnasExtraSelectedOptions,
        setColumnasExtraSelectedOptions,
        columnasExtraOptionsChangeHandler,
        column1SelectRef,
        column2SelectRef,
        column3SelectRef,
        column4SelectRef,
        column5SelectRef,
        column1SelectedOption,
        setColumn1SelectedOption,
        column1OptionsChangeHandler,
        column2SelectedOption,
        setColumn2SelectedOption,
        column2OptionsChangeHandler,
        column3SelectedOption,
        setColumn3SelectedOption,
        column3OptionsChangeHandler,
        column4SelectedOption,
        setColumn4SelectedOption,
        column4OptionsChangeHandler,
        column5SelectedOption,
        setColumn5SelectedOption,
        column5OptionsChangeHandler,
        column1SKUSelectRef,
        column2SKUSelectRef,
        column3SKUSelectRef,
        column4SKUSelectRef,
        column5SKUSelectRef,
        column1SKUSelectedOption,
        setColumn1SKUSelectedOption,
        column2SKUSelectedOption,
        setColumn2SKUSelectedOption,
        column2SKUOptionsChangeHandler,
        column3SKUSelectedOption,
        setColumn3SKUSelectedOption,
        column3SKUOptionsChangeHandler,
        column4SKUSelectedOption,
        setColumn4SKUSelectedOption,
        column4SKUOptionsChangeHandler,
        column5SKUSelectedOption,
        setColumn5SKUSelectedOption,
        column5SKUOptionsChangeHandler,
        column1DestinySelectRef,
        column2DestinySelectRef,
        column3DestinySelectRef,
        column4DestinySelectRef,
        column5DestinySelectRef,
        column6DestinySelectRef,
        column1DestinySelectedOption,
        setColumn1DestinySelectedOption,
        column2DestinySelectedOption,
        setColumn2DestinySelectedOption,
        column2DestinyOptionsChangeHandler,
        column3DestinySelectedOption,
        setColumn3DestinySelectedOption,
        column3DestinyOptionsChangeHandler,
        column4DestinySelectedOption,
        setColumn4DestinySelectedOption,
        column4DestinyOptionsChangeHandler,
        column5DestinySelectedOption,
        setColumn5DestinySelectedOption,
        column5DestinyOptionsChangeHandler,
        column6DestinySelectedOption,
        setColumn6DestinySelectedOption,
        column6OptionsChangeHandler,
        formulaSKU,
        setFormulaSKU,
        formulaDestiny,
        setFormulaDestiny,
        dataGetFileColumnsAgrupation,
        onFileChange,
      }}
    >
      {children}
    </MarketBasketConfigContext.Provider>
  );
};

export default MarketBasketConfigProvider;

export const useMarketBasketConfigProvider = (): any => {
  const context = useContext(MarketBasketConfigContext);
  if (!context) {
    throw new Error(
      "useMarketBasketProvider must be used within a MarketBasketProvider"
    );
  }
  return context;
};
