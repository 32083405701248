import { arrow_back_icon_styles } from "../../../styles/app-styles";
import { ChartType } from "../types";
import { columnOptions } from "../../criterias/configCriteria/selectOptions";
import { dataGetChartFunctions, dataGetChartFunctionsWithDivision } from "../data";
import { optionalSelectStyles } from "../../criterias/configCriteria/select.styles";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useRef, useState } from "react";
import { useGetCriteriaFileColumn } from "../../criterias/configCriteria/api";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "../../buttons/Button";
import ComponentLayout from "../../layout/ComponentLayout";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import CustomStepper from "../../Stepper/CustomStepper";
import data from "./data.json";
import DrilldownColumnSelect from "../DrilldownColumnSelect";
import Select from "../../forms/Select";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Step0Charts from "../shared/Step0Charts";
import Step1Charts from "../shared/Step1Charts";
import Step2 from "../../criterias/configCriteria/Step2";
import Step4 from "../../criterias/configCriteria/Step4";
import Tooltip from "@mui/material/Tooltip";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import useMultiSelect from "../../../hooks/useMultiSelect";
import useSingleSelect from "../../../hooks/useSingleSelect";
import {
  prepareFilters,
  prepareUnions,
  handleRepeatedName,
  handleAxisYName,
  handleAxisXName,
  completedInfo,
  chartListFiles,
} from "../utils";
import {
  ExternalFilter,
  Filter,
  IdOption,
  Message,
} from "../../criterias/configCriteria/types";
import {
  GRAFICOS_ARCHIVOS,
  GRAFICO_ARCHIVO,
  GRUPOS_GRAFICOS,
} from "../../../api/axios";

const ConfigChartsFiles = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { data: dataGetFileColumn } = useGetCriteriaFileColumn();
  const { data: dataGetGroups, refetch: refetchGetGroups } = useApiQuery(
    GRUPOS_GRAFICOS,
    false,
    t("chartsManagement.getGroup.error")
  );
  const configMode = localStorage.getItem("configMode");
  const chart = JSON.parse(localStorage.getItem("cardInfo") as string);

  const {
    data: charts,
  } = useApiQuery(GRAFICOS_ARCHIVOS, false, t("report.getKpis.error"));

  const onSuccessPost = () => {
    localStorage.removeItem("configMode");
    localStorage.removeItem("cardInfo");
    navigate(t("/administracion-dashboard"), {
      state: { tab: "archivo/graficos" },
      replace: false,
    });
  };

  const { isLoading: isLoadingPostChart, callApi: postChart } = useApi(
    GRAFICOS_ARCHIVOS,
    "POST",
    t("chartsManagement.addChart.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const { isLoading: isLoadingPutChart, callApi: putChart } = useApi(
    GRAFICO_ARCHIVO,
    "PUT",
    t("chartsManagement.editChart.codes"),
    undefined,
    onSuccessPost,
    undefined,
    true
  );

  const [message, setMessage] = useState<Message>();

  //Step 1
  const columnAxisYSelectRef: any = useRef(null);
  const columnAxisXSelectRef: any = useRef(null);
  const columnBaseSelectRef: any = useRef(null);
  const columnSeriesSelectRef: any = useRef(null);
  const chartNameRef: any = useRef(null);
  const groupRef: any = useRef(null);
  const axisYNameRef: any = useRef(null);
  const axisXNameRef: any = useRef(null);
  const fileSelectRef: any = useRef(null);
  const functionSelectRef: any = useRef(null);
  const [chartFormComplete, setChartFormComplete] = useState(false);
  const [chartName, setChartName] = useState(
    configMode === "EDIT" ? chart?.nombre : ""
  );

  const [axisXName, setAxisXName] = useState(
    configMode === "EDIT" || configMode === "COPY" ? chart?.nombre_eje_x : ""
  );
  const [activeStep, setActiveStep] = useState(0);

  const selectRefs = useRef<Array<any>>([]);

  const onFileColumn = () => {
    setColumnAxisYSelectedOptions(undefined);
    setColumnAxisXSelectedOptions(undefined);
    setColumnSeriesSelectedOptions(undefined);
    columnAxisYSelectRef?.current?.clearValue();
    columnAxisXSelectRef?.current?.clearValue();
    columnSeriesSelectRef?.current?.clearValue();
    selectRefs.current.map((ref) => ref?.clearValue());
    setColumnsDrilldownQuantity(1);
    setSelectedOptions([]);
    setDivisionRows([]);
  };

  const [
    fileSelectedOptions,
    setFileSelectedOptions,
    fileOptionsChangeHandler,
  ] = useSingleSelect(
    () => onFileColumn(),
    configMode === "EDIT" || configMode === "COPY" ? chart?.archivo : undefined
  );

  const [openChartModal, setOpenChartModal] = useState(false);
  const [chartType, setChartType] = useState<ChartType | undefined>();

  const [groupSelectedOptions, setGroupSelectedOptions, groupChangeHandler] =
    useSingleSelect(
      undefined,
      configMode === "EDIT" || configMode === "COPY"
        ? {
            value: chart?.grupo.value,
            label: chart?.grupo.label,
          }
        : undefined
    );

  const [
    columnAxisYSelectedOptions,
    setColumnAxisYSelectedOptions,
    columnAxisYOptionsChangeHandler,
  ] = useMultiSelect(    
    configMode === "EDIT" || configMode === "COPY"
      ? chart?.funcion !== "DIVISION"
        ? chart?.columna_eje_y?.map((column: any) => column?.columna)
        : undefined
      : undefined, undefined
  );
  const [
    columnBaseSelectedOptions,
    setColumnBaseSelectedOptions,
    columnBaseOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? chart?.columna_base
      : undefined
  );
  const [
    columnAxisXSelectedOptions,
    setColumnAxisXSelectedOptions,
    columnAxisXOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? chart?.columna_eje_x
      : undefined
  );

  const [
    columnSeriesSelectedOptions,
    setColumnSeriesSelectedOptions,
    columnSeriesOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? chart?.columna_series
      : undefined
  );
  const [
    functionSelectedOptions,
    setFunctionSelectedOptions,
    functionOptionsChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? { value: chart?.funcion, label: chart?.funcion }
      : undefined
  );

  const [columnsDrilldownQuantity, setColumnsDrilldownQuantity] = useState(
    (configMode === "EDIT" || configMode === "COPY") &&
      chart?.columnas_drilldown?.length > 0
      ? chart?.columnas_drilldown?.length
      : 1
  );
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const handleOptionChange = (index: number, newOption: any) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = newOption;
    setSelectedOptions(newSelectedOptions);
  };

  const columnSelects: any[] = [];

  for (let i = 0; i < columnsDrilldownQuantity; i++) {
    const columnSelect = (
      <DrilldownColumnSelect
        initialOption={
          configMode === "EDIT" || configMode === "COPY"
            ? !!chart?.columnas_drilldown && chart?.columnas_drilldown[i]
            : undefined
        }
        key={i}
      >
        {({
          selectedOption,
          setSelectedOption,
          columnMultiDrilldownSelectRef,
        }) => {
          useEffect(() => {
            selectRefs.current[i] = columnMultiDrilldownSelectRef.current;
          }, [columnMultiDrilldownSelectRef, i]);

          return (
            <Select
              reference={columnMultiDrilldownSelectRef}
              styles={optionalSelectStyles(selectedOption)}
              options={columnOptions(dataGetFileColumn, fileSelectedOptions)}
              className="cc_select"
              name="Columna"
              onChange={(newOption) => handleOptionChange(i, newOption)}
              closeMenuOnSelect
              placeholder={t("indicatorsManagementTexts.configCharts.none")}
              defaultValue={selectedOptions[i]}
            />
          );
        }}
      </DrilldownColumnSelect>
    );
    columnSelects.push(columnSelect);
  }

  const [axisYName, setAxisYName] = useState(
    configMode === "EDIT" || configMode === "COPY" ? chart?.nombre_eje_y : ""
  );
  const [settedYName, setSettedYName] = useState(
    configMode === "EDIT" || configMode === "COPY" ? chart?.nombre_eje_y : ""
  );
  const [settedXName, setSettedXName] = useState(
    configMode === "EDIT" || configMode === "COPY" ? chart?.nombre_eje_y : ""
  );

  useEffect(() => {
    handleAxisYName(
      chartType,
      axisYName,
      settedYName,
      columnAxisYSelectedOptions,
      functionSelectedOptions,
      setAxisYName,
      setSettedYName,
      axisYNameRef
    );
  }, [columnAxisYSelectedOptions, functionSelectedOptions]);

  useEffect(() => {
    handleAxisXName(
      axisXName,
      settedXName,
      columnAxisXSelectedOptions,
      setAxisXName,
      setSettedXName,
      axisXNameRef
    );
  }, [columnAxisXSelectedOptions]);

  const onColumnChange = () => {
    setSelectedValues([]);
    setValuesSelectedOptions(undefined);
  };

  const [activeIndicator, setActiveIndicator] = useState(
    configMode === "EDIT" || configMode === "COPY" ? chart?.activo : true
  );

  /* Step 2 */
  const [
    conditionSelectedOptions,
    setConditionSelectedOptions,
    conditionOptionsChangeHandler,
  ] = useSingleSelect();
  const [
    columnFilterSelectedOptions,
    setColumnFilterSelectedOptions,
    columnFilterOptionsChangeHandler,
  ] = useSingleSelect(() => onColumnChange());
  const [valuesSelectedOptions, setValuesSelectedOptions] =
    useState<IdOption[]>();
  const [selectedValues, setSelectedValues] = useState<string[] | null>([]);
  const [
    filterTypeSelectedOptions,
    setFilterTypeSelectedOptions,
    filterTypeOptionsChangeHandler,
  ] = useSingleSelect();
  const [filters, setFilters] = useState<Filter[]>();

  const onExternalColumnChange = () => {
    setSelectedExternalValues([]);
    setValuesExternalSelectedOptions(undefined);
  };

  //Step 3
  const externalFileSelectRef: any = useRef(null);
  const externalColumnSelectRef: any = useRef(null);
  const columnFileSelectRef: any = useRef(null);

  const onFileExternalColumnChange = () => {
    setExternalFilters([]);
    setExternalColumnSelectedOptions(undefined);
    setColumnFileSelectedOptions(undefined);
    externalColumnSelectRef.current.clearValue();
    columnFileSelectRef.current.clearValue();
  };

  const [
    externalFileSelectedOptions,
    setExternalFileSelectedOptions,
    externalFileOptionsChangeHandler,
  ] = useSingleSelect(
    () => onFileExternalColumnChange(),
    configMode === "EDIT" || configMode === "COPY"
      ? chart?.archivo_externo_validacion
      : undefined
  );
  const [
    externalColumnSelectedOptions,
    setExternalColumnSelectedOptions,
    externalColumnOptionsChangeHandler,
  ] = useSingleSelect();
  const [
    columnFileSelectedOptions,
    setColumnFileSelectedOptions,
    columnFileOptionsChangeHandler,
  ] = useSingleSelect();
  const [externalFilters, setExternalFilters] = useState<ExternalFilter[]>();
  const [
    conditionExternalSelectedOptions,
    setConditionExternalSelectedOptions,
    conditionExternalOptionsChangeHandler,
  ] = useSingleSelect();
  const [
    columnFilterExternalSelectedOptions,
    setColumnFilterExternalSelectedOptions,
    columnFilterExternalOptionsChangeHandler,
  ] = useSingleSelect(() => onExternalColumnChange());
  const [valuesExternalSelectedOptions, setValuesExternalSelectedOptions] =
    useState<IdOption[]>();
  const [selectedExternalValues, setSelectedExternalValues] = useState<
    string[] | null
  >([]);
  const [
    filterTypeExternalSelectedOptions,
    setFilterTypeExternalSelectedOptions,
    filterTypeExternalOptionsChangeHandler,
  ] = useSingleSelect();
  const [filtersExternal, setFiltersExternal] = useState<Filter[]>();
  const [validationsStep3Completed, setValidationsStep3Completed] = useState(
    configMode === "EDIT" || configMode === "COPY" ? true : false
  );
  const [divisionRows, setDivisionRows] = useState<any>((configMode === "EDIT" || configMode === "COPY") &&
  chart.funcion === "DIVISION"
  ? chart.columna_eje_y
  : ([] as any[]));


  useEffect(() => {
    if (configMode === "EDIT" || configMode === "COPY") {
      setSelectedOptions(chart.columnas_drilldown);
      setFilters(prepareFilters(false, chart));
      setFiltersExternal(prepareFilters(true, chart));
      setExternalFilters(prepareUnions(true, chart));
    } else {
      handleRestart();
    }
  }, []);

  //useEffect que está a la escucha de chartName, y si ya existe ese nombre en la lista de gráficos, entonces muestra un mensaje de error
  useEffect(() => {
    setMessage(undefined);
    handleRepeatedName(
      chartName,
      charts,
      configMode,
      setMessage,
      chart,
      chartType,
      selectedOptions,
      columnBaseSelectedOptions,
      columnAxisXSelectedOptions,
      columnAxisYSelectedOptions,
      columnSeriesSelectedOptions
    );
  }, [
    chartName,
    columnBaseSelectedOptions,
    selectedOptions,
    columnAxisYSelectedOptions,
    columnAxisXSelectedOptions,
    columnSeriesSelectedOptions,
  ]);

  const handleRestart = () => {
    setChartName("");
    setAxisYName("");
    setAxisXName("");
    setChartType(undefined);
    setFileSelectedOptions(undefined);
    setGroupSelectedOptions(undefined);
    setColumnAxisYSelectedOptions(undefined);
    setColumnAxisXSelectedOptions(undefined);
    setColumnBaseSelectedOptions(undefined);
    setColumnSeriesSelectedOptions(undefined);
    setFunctionSelectedOptions(undefined);
    setChartFormComplete(false);
    setActiveStep(0);
    setSelectedOptions([]);
    selectRefs.current = [];
    setColumnsDrilldownQuantity(1);
    groupRef.current?.clearValue();
    chartNameRef.value = "";
    axisYNameRef.value = "";
    axisXNameRef.value = "";
    fileSelectRef.current?.clearValue();
    functionSelectRef.current?.clearValue();
    columnAxisYSelectRef.current?.clearValue();
    columnAxisXSelectRef.current?.clearValue();
    columnSeriesSelectRef.current?.clearValue();
    columnBaseSelectRef.current?.clearValue();
    setDivisionRows([]);
    setSettedYName("");
    setSettedXName("");    

    //Step 2
    setConditionSelectedOptions(undefined);
    setColumnFilterSelectedOptions(undefined);
    setValuesSelectedOptions(undefined);
    setFilterTypeSelectedOptions(undefined);
    setFilters(undefined);

    //Step3
    setExternalFileSelectedOptions(undefined);
    setExternalColumnSelectedOptions(undefined);
    setColumnFileSelectedOptions(undefined);
    setExternalFilters(undefined);
    setConditionExternalSelectedOptions(undefined);
    setColumnFilterExternalSelectedOptions(undefined);
    setValuesExternalSelectedOptions(undefined);
    setFilterTypeExternalSelectedOptions(undefined);
    setFiltersExternal(undefined);
  };

  const handleCloseChartModal = () => {
    setOpenChartModal(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const sendChart = () => {
    const infoToSend = completedInfo(
      chartName,
      chartType,
      fileSelectedOptions,
      groupSelectedOptions,
      columnAxisYSelectedOptions,
      divisionRows,
      functionSelectedOptions,
      axisYName,
      columnAxisXSelectedOptions,
      axisXName,
      columnBaseSelectedOptions,
      selectedOptions,
      columnSeriesSelectedOptions,
      activeIndicator,
      filters,
      filtersExternal,
      externalFilters,
      undefined,
      
    );
    if (configMode === "CREATE" || configMode === "COPY") {
      postChart(undefined, infoToSend);
    } else {
      putChart(GRAFICO_ARCHIVO(chart.id), infoToSend);
    }
    handleCloseChartModal();
  };

  const closeAndClean = () => {
    localStorage.removeItem("configMode");
    localStorage.removeItem("cardInfo");
    navigate(t("/administracion-dashboard"), {
      state: { tab: "archivo/graficos" },
      replace: false,
    });
  };

  return (
    <ComponentLayout
      title={t(
        "indicatorsManagementTexts.indicatorsManagement.graphicConfigFile"
      )}
      icon={
        <ArrowBackIcon sx={arrow_back_icon_styles} onClick={closeAndClean} />
      }
    >
      <SimpleBackdrop
        open={isLoadingPostChart || isLoadingPutChart}
        message={
          configMode === "CREATE" || configMode === "COPY"
            ? t("indicatorsManagementTexts.configCharts.creatingGraphic")
            : t("indicatorsManagementTexts.configCharts.modifyingGraphic")
        }
      />
      <ConfirmationModal
        open={openChartModal}
        handleClose={handleCloseChartModal}
        handleAccept={sendChart}
        message={
          configMode === "EDIT"
            ? t("indicatorsManagementTexts.configCharts.editTheGraphic")
            : t("indicatorsManagementTexts.configCharts.createTheGraphic")
        }
        title={
          configMode === "EDIT"
            ? t("indicatorsManagementTexts.configCharts.editGraphic")
            : t("indicatorsManagementTexts.configCharts.createGraphic")
        }
      />
      <CustomStepper activeStep={activeStep} data={data.chartSteps} />
      {activeStep === 0 && (
        <>
          <div className="cc_step_title">{data.chartSteps.steps[0]}</div>
          <Step0Charts
            selectedChart={chart}
            chartType={chartType}
            setChartType={setChartType}
            handleRestart={handleRestart}
            chartList={chartListFiles}
          />
        </>
      )}
      {activeStep === 1 && (
        <>
          <div className="cc_step_title">{data.chartSteps.steps[1]}</div>
          {message && (
            <div className="cc_info_filters_box">
              <p className={`cc_info_filters_${message.type}`}>
                {message.text}
              </p>
            </div>
          )}
          <Step1Charts
            chartType={chartType}
            chartName={chartName}
            setChartName={setChartName}
            chartNameRef={chartNameRef}
            groupRef={groupRef}
            groupSelectedOptions={groupSelectedOptions}
            groupChangeHandler={groupChangeHandler}
            groupSelectOptions={dataGetGroups}
            refetchGetGroups={refetchGetGroups}
            axisYName={axisYName}
            setAxisYName={setAxisYName}
            axisYNameRef={axisYNameRef}
            axisXName={axisXName}
            setAxisXName={setAxisXName}
            axisXNameRef={axisXNameRef}
            setChartFormComplete={setChartFormComplete}
            fileColumnSelectOptions={dataGetFileColumn}
            functionSelectOptions={
              chartType === "LINEAS" ||
              chartType === "BARRAS CON DRILLDOWN" ||
              chartType === "SCATTERPLOT"
              ? dataGetChartFunctionsWithDivision
              : dataGetChartFunctions
            }
            fileSelectedOptions={fileSelectedOptions}
            fileOptionsChangeHandler={fileOptionsChangeHandler}
            columnAxisYSelectedOptions={columnAxisYSelectedOptions}
            columnAxisYOptionsChangeHandler={columnAxisYOptionsChangeHandler}
            columnAxisXSelectedOptions={columnAxisXSelectedOptions}
            columnAxisXOptionsChangeHandler={columnAxisXOptionsChangeHandler}
            columnBaseSelectedOptions={columnBaseSelectedOptions}
            columnBaseOptionsChangeHandler={columnBaseOptionsChangeHandler}
            columnSeriesSelectedOptions={columnSeriesSelectedOptions}
            columnSeriesOptionsChangeHandler={columnSeriesOptionsChangeHandler}
            functionSelectedOptions={functionSelectedOptions}
            functionOptionsChangeHandler={functionOptionsChangeHandler}
            columnAxisYSelectRef={columnAxisYSelectRef}
            columnAxisXSelectRef={columnAxisXSelectRef}
            columnBaseSelectRef={columnBaseSelectRef}
            columnSeriesSelectRef={columnSeriesSelectRef}
            fileSelectRef={fileSelectRef}
            functionSelectRef={functionSelectRef}
            activeIndicator={activeIndicator}
            setActiveIndicator={setActiveIndicator}
            columnsDrilldownQuantity={columnsDrilldownQuantity}
            setColumnsDrilldownQuantity={setColumnsDrilldownQuantity}
            columnSelects={columnSelects}
            setSelectedOptions={setSelectedOptions}
            selectRefs={selectRefs}
            selectedOptions={selectedOptions}
            message={message}
            fileType={"TEMPLATE"}
            divisionRows={divisionRows}
            setDivisionRows={setDivisionRows}
          />
        </>
      )}
      {activeStep === 2 && (
        <>
          <div className="cc_step_title">{`${data.chartSteps.steps[2]}: ${fileSelectedOptions?.label}`}</div>
          <Step2
            fileColumnSelectOptions={dataGetFileColumn}
            fileSelectedOptions={fileSelectedOptions}
            columnFilterSelectedOptions={columnFilterSelectedOptions}
            setColumnFilterSelectedOptions={setColumnFilterSelectedOptions}
            columnFilterOptionsChangeHandler={columnFilterOptionsChangeHandler}
            conditionSelectedOptions={conditionSelectedOptions}
            setConditionSelectedOptions={setConditionSelectedOptions}
            conditionOptionsChangeHandler={conditionOptionsChangeHandler}
            valuesSelectedOptions={valuesSelectedOptions}
            setValuesSelectedOptions={setValuesSelectedOptions}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            filterTypeSelectedOptions={filterTypeSelectedOptions}
            setFilterTypeSelectedOptions={setFilterTypeSelectedOptions}
            filterTypeOptionsChangeHandler={filterTypeOptionsChangeHandler}
            filters={filters}
            setFilters={setFilters}
            external={false}
            width="90%"
            externalFileSelectedOptions={externalFileSelectedOptions}
          />
        </>
      )}
      {activeStep === 3 && (
        <>
          <div className="cc_step_title">{`${data.chartSteps.steps[3]}`}</div>
          <div className="cc_step4_container">
            <Step4
              fileColumnSelectOptions={dataGetFileColumn}
              fileSelectedOptions={fileSelectedOptions}
              externalFileSelectedOptions={externalFileSelectedOptions}
              setExternalFileSelectedOptions={setExternalFileSelectedOptions}
              externalFileOptionsChangeHandler={
                externalFileOptionsChangeHandler
              }
              externalColumnSelectedOptions={externalColumnSelectedOptions}
              setExternalColumnSelectedOptions={
                setExternalColumnSelectedOptions
              }
              externalColumnOptionsChangeHandler={
                externalColumnOptionsChangeHandler
              }
              columnFileSelectedOptions={columnFileSelectedOptions}
              setColumnFileSelectedOptions={setColumnFileSelectedOptions}
              columnFileOptionsChangeHandler={columnFileOptionsChangeHandler}
              filters={externalFilters}
              setFilters={setExternalFilters}
              setValidationsStep4Completed={setValidationsStep3Completed}
              externalFileSelectRef={externalFileSelectRef}
              externalColumnSelectRef={externalColumnSelectRef}
              columnFileSelectRef={columnFileSelectRef}
            />
            <Step2
              fileColumnSelectOptions={dataGetFileColumn}
              fileSelectedOptions={fileSelectedOptions}
              columnFilterSelectedOptions={columnFilterExternalSelectedOptions}
              setColumnFilterSelectedOptions={
                setColumnFilterExternalSelectedOptions
              }
              columnFilterOptionsChangeHandler={
                columnFilterExternalOptionsChangeHandler
              }
              conditionSelectedOptions={conditionExternalSelectedOptions}
              setConditionSelectedOptions={setConditionExternalSelectedOptions}
              conditionOptionsChangeHandler={
                conditionExternalOptionsChangeHandler
              }
              valuesSelectedOptions={valuesExternalSelectedOptions}
              setValuesSelectedOptions={setValuesExternalSelectedOptions}
              selectedValues={selectedExternalValues}
              setSelectedValues={setSelectedExternalValues}
              filterTypeSelectedOptions={filterTypeExternalSelectedOptions}
              setFilterTypeSelectedOptions={
                setFilterTypeExternalSelectedOptions
              }
              filterTypeOptionsChangeHandler={
                filterTypeExternalOptionsChangeHandler
              }
              filters={filtersExternal}
              setFilters={setFiltersExternal}
              external={true}
              width="60%"
              validationsStep4Completed={validationsStep3Completed}
              externalFileSelectedOptions={externalFileSelectedOptions}
            />
          </div>
        </>
      )}
      <div className="cc_steps_buttons">
        <Button
          title="Anterior"
          color="blue-greeny"
          type="button"
          onClick={handleBack}
          disabled={activeStep === 0}
        />
        <Tooltip
          title={t("indicatorsManagementTexts.configCharts.resetAllGraphSteps")}
          arrow
        >
          <span>
            <Button
              title={t("indicatorsManagementTexts.configCharts.cleanAll")}
              color="grey"
              type="button"
              onClick={handleRestart}
            />
          </span>
        </Tooltip>
        <Button
          title={t("indicatorsManagementTexts.configCharts.finish")}
          color="blue"
          type="button"
          onClick={() => setOpenChartModal(true)}
          disabled={!chartFormComplete}
        />
        <Button
          title={t("indicatorsManagementTexts.configCharts.next")}
          color="blue-greeny"
          type="button"
          onClick={handleNext}
          disabled={
            activeStep === 3 ||
            !chartType ||
            (activeStep === 1 && !chartFormComplete)
          }
        />
      </div>
    </ComponentLayout>
  );
};

export default ConfigChartsFiles;
