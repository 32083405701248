import { columnOptions, fileOptions } from "../../criterias/configCriteria/selectOptions";
import { ConfirmationModal } from "../../dialog";
import { selectStyles } from "../../criterias/configCriteria/select.styles";
import { Stack, TextField, Typography } from "@mui/material";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "../../buttons/Button";
import ComponentLayout from "../../layout/ComponentLayout";
import FormSelectLayout from "../../forms/FormSelectLayout";
import Select from "../../forms/Select";
import ShadowBox from "../../dataModel/groupManagement/ShadowBox";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import useMultiSelect from "../../../hooks/useMultiSelect";
import useSingleSelect from "../../../hooks/useSingleSelect";
import {
  CALCULATE_VALIDATION_URL,
  GET_CRITERIA_FILE_COLUMN_URL,
  VALIDATIONS_URL,
  VALIDATION_URL,
} from "../../../api/axios";
import {
  arrow_back_icon_styles,
  columns_error_typography_styles,
  config_view_buttons_container,
  input_name_container_styles,
  shadow_boxes_container_styles,
} from "../../../styles/app-styles";
import { isNameDuplicated } from "../utils";

const ConfigCodesMatching = () => {
  const validationInfo = JSON.parse(
    localStorage.getItem("validationInfo") as string
  );
  const configMode = localStorage.getItem("configMode");
  const [codeMatchName, setCodeMatchName] = useState(
    configMode === "EDIT"
      ? validationInfo?.nombre
      : configMode === "COPY"
      ? `${validationInfo?.nombre}_copia`
      : ""
  );
  const [openConfirmationModal, toggleConfirmationModal] = useDialog();
  const { t } = useLanguage();
  const columns1SelectRef: any = useRef(null);
  const columns2SelectRef: any = useRef(null);
  const file1SelectRef: any = useRef(null);
  const file2SelectRef: any = useRef(null);
  const indicatorSelectRef: any = useRef(null);
  const navigate = useNavigate();

  /******************************* CARGA DE DATOS INICIAL ***********************************/

  const { data: dataGetFileColumn } = useApiQuery(
    GET_CRITERIA_FILE_COLUMN_URL,
    false,
    t("configCriteria.getCriteriaFileColumn.error")
  );

  const { data: dataGetCodeMatching } = useApiQuery(
    `${VALIDATIONS_URL}?tipo_validacion=CRUCE_CODIGOS`,
    true,
    t("validations.validationsAdmin.getCodeMatching.error")
  );

  /********************************** SELECTS *************************************/

  const resetFile1Column = () => {
    setColumns1SelectedOptions(undefined);
    columns1SelectRef.current.clearValue();
    setIndicatorSelectedOption(undefined);
    indicatorSelectRef.current.clearValue();
  };

  const [
    file1SelectedOption,
    setFile1SelectedOptions,
    file1OptionsChangeHandler,
  ] = useSingleSelect(
    () => resetFile1Column(),
    configMode === "EDIT" || configMode === "COPY"
      ? validationInfo?.izquierda.archivo
      : undefined
  );

  const [
    columns1SelectedOptions,
    setColumns1SelectedOptions,
    columns1OptionsChangeHandler,
  ] = useMultiSelect(
    configMode === "EDIT" || configMode === "COPY"
      ? validationInfo?.izquierda.columnas
      : undefined
  );

  const resetFile2Column = () => {
    setColumns2SelectedOptions(undefined);
    columns2SelectRef.current.clearValue();
    setIndicatorSelectedOption(undefined);
    indicatorSelectRef.current.clearValue();
  };

  const [
    file2SelectedOption,
    setFile2SelectedOptions,
    file2OptionsChangeHandler,
  ] = useSingleSelect(
    () => resetFile2Column(),
    configMode === "EDIT" || configMode === "COPY"
      ? validationInfo?.derecha.archivo
      : undefined
  );

  const [
    columns2SelectedOptions,
    setColumns2SelectedOptions,
    columns2OptionsChangeHandler,
  ] = useMultiSelect(
    configMode === "EDIT" || configMode === "COPY"
      ? validationInfo?.derecha.columnas
      : undefined
  );

  const generateIndicatorMatchOptions = () => {
    return [
      {
        value: 1,
        label: `${t(
          "validations.configValidations.textIndicatorMatchSelect.percentaje"
        )} ${file1SelectedOption?.label} ${t(
          "validations.configValidations.textIndicatorMatchSelect.in"
        )} ${file2SelectedOption?.label}`,
      },
      {
        value: 2,
        label: `${t(
          "validations.configValidations.textIndicatorMatchSelect.percentaje"
        )} ${file2SelectedOption?.label} ${t(
          "validations.configValidations.textIndicatorMatchSelect.in"
        )} ${file1SelectedOption?.label}`,
      },
    ];
  };

  const [
    indicatorSelectedOption,
    setIndicatorSelectedOption,
    indicatorOptionChangeHandler,
  ] = useSingleSelect(
    undefined,
    configMode === "EDIT" || configMode === "COPY"
      ? generateIndicatorMatchOptions().find(
          (option: any) =>
            option.value === parseInt(validationInfo?.tipo_match_codigo)
        )
      : undefined
  );

  /******************************* VALIDACIONES FORMULARIO ***************************/

  const isFormValid = () => {
    return !(
      columns1SelectedOptions === undefined ||
      columns1SelectedOptions?.length === 0 ||
      !file1SelectedOption ||
      columns2SelectedOptions === undefined ||
      columns2SelectedOptions?.length === 0 ||
      !file2SelectedOption ||
      !indicatorSelectedOption ||
      codeMatchName === "" ||
      !equalColumnsLength()
    );
  };

  const equalColumnsLength = () => {
    return !(
      columns1SelectedOptions?.length !== 0 &&
      columns1SelectedOptions !== undefined &&
      columns2SelectedOptions?.length !== 0 &&
      columns2SelectedOptions !== undefined &&
      columns1SelectedOptions?.length !== columns2SelectedOptions?.length
    );
  };

  const inputNameError = () => {
    if (codeMatchName === "") {
      return true;
    }
    if (
      configMode !== "EDIT" &&
      isNameDuplicated(dataGetCodeMatching, codeMatchName)
    ) {
      return true;
    }
    return false;
  };

  const inputNameHelperText = () => {
    if (codeMatchName === "") {
      return t("validations.configValidations.errorEmptyName");
    }
    if (
      configMode !== "EDIT" &&
      isNameDuplicated(dataGetCodeMatching, codeMatchName)
    ) {
      return t("validations.configValidations.errorDuplicatedName");
    }
    return "";
  };

  /*********************************** POST, PUT y CALCULAR ******************************/

  const { callApi: postCalculateValidation } =
  useApi(
    CALCULATE_VALIDATION_URL(""),
    "POST",
    t("validations.configValidations.calculate.codes"),
    undefined,
    undefined,
    undefined,
    true
  );

  const onSuccessPostValidation = (data: any) => {
    postCalculateValidation(CALCULATE_VALIDATION_URL(data.id))   
    closeAndClean(); 
  };

  const { isLoading: isLoadingPostValidation, callApi: postValidation } =
    useApi(
      VALIDATIONS_URL,
      "POST",
      t("validations.configValidations.codes"),
      undefined,
      onSuccessPostValidation,
      undefined,
      true
    );

  const { isLoading: isLoadingPutValidation, callApi: putValidation } = useApi(
    VALIDATION_URL(validationInfo?.id),
    "PUT",
    t("validations.configValidations.codes"),
    undefined,
    onSuccessPostValidation,
    undefined,
    true
  );

  const handleAccept = () => {
    const completedInfo = {
      nombre: codeMatchName,
      tipo: "CRUCE_CODIGOS",
      tipo_match_codigo: indicatorSelectedOption?.value,
      izquierda: columns1SelectedOptions?.map((column: any) => column.value),
      derecha: columns2SelectedOptions?.map((column: any) => column.value),
    };
    toggleConfirmationModal();
    if (configMode === "EDIT") {
      putValidation(VALIDATION_URL(validationInfo.id), completedInfo);
    } else {
      postValidation(VALIDATIONS_URL, completedInfo);
    }
  };

  /************************************ CIERRE *************************************/

  const closeAndClean = () => {
    localStorage.removeItem("configMode");
    localStorage.removeItem("validationInfo");
    navigate("/administracion-validaciones");
  };

  return (
    <ComponentLayout
      title={t("menuItemsOptions.validations.configCodeMatching.title")}
      icon={
        <ArrowBackIcon sx={arrow_back_icon_styles} onClick={closeAndClean} />
      }
    >
      <SimpleBackdrop
        open={isLoadingPostValidation || isLoadingPutValidation}
        message={t("validations.configValidations.loading")}
      />   
    
      <Stack sx={input_name_container_styles}>
        <TextField
          type="text"
          label={t("validations.configValidations.matchName")}
          variant="outlined"
          fullWidth
          value={codeMatchName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCodeMatchName(event.target.value);
          }}
          error={inputNameError()}
          helperText={inputNameHelperText()}
        />

        <Stack sx={shadow_boxes_container_styles}>
          <ShadowBox>
            <FormSelectLayout
              title={t("configCriteria.step1.file")}
              required={true}
            >
              <Select
                reference={file1SelectRef}
                styles={selectStyles(file1SelectedOption)}
                options={fileOptions(dataGetFileColumn)}
                onChange={file1OptionsChangeHandler}
                closeMenuOnSelect
                placeholder={t("configCriteria.step1.selectFile")}
                defaultValue={file1SelectedOption}
                isClearable
              />
            </FormSelectLayout>
            <FormSelectLayout
              title={t("validations.configValidations.subtitleColumns")}
              required={true}
            >
              <Select
                reference={columns1SelectRef}
                styles={selectStyles(columns1SelectedOptions)}
                options={columnOptions(dataGetFileColumn, file1SelectedOption)}
                onChange={columns1OptionsChangeHandler}
                closeMenuOnSelect={false}
                placeholder={t("configCriteria.step1.selectColumn")}
                defaultValue={columns1SelectedOptions}
                isClearable
                isMulti
              />
            </FormSelectLayout>
          </ShadowBox>
          <ShadowBox>
            <FormSelectLayout
              title={t("configCriteria.step1.file")}
              required={true}
            >
              <Select
                reference={file2SelectRef}
                styles={selectStyles(file2SelectedOption)}
                options={fileOptions(dataGetFileColumn)}
                onChange={file2OptionsChangeHandler}
                closeMenuOnSelect
                placeholder={t("configCriteria.step1.selectFile")}
                defaultValue={file2SelectedOption}
                isClearable
              />
            </FormSelectLayout>
            <FormSelectLayout
              title={t("validations.configValidations.subtitleColumns")}
              required={true}
            >
              <Select
                reference={columns2SelectRef}
                styles={selectStyles(columns2SelectedOptions)}
                options={columnOptions(dataGetFileColumn, file2SelectedOption)}
                onChange={columns2OptionsChangeHandler}
                closeMenuOnSelect={false}
                placeholder={t("configCriteria.step1.selectColumn")}
                defaultValue={columns2SelectedOptions}
                isClearable
                isMulti
              />
            </FormSelectLayout>
          </ShadowBox>
        </Stack>
        {!equalColumnsLength() && (
          <Typography variant="subtitle1" sx={columns_error_typography_styles}>
            {t("validations.configValidations.notEqualColumnsLength")}
          </Typography>
        )}
        <Stack sx={shadow_boxes_container_styles}>
          <ShadowBox>
            <FormSelectLayout
              title={t(
                "validations.configValidations.titleIndicatorMatchSelect"
              )}
              required={true}
            >
              <Select
                reference={indicatorSelectRef}
                styles={selectStyles(indicatorSelectedOption)}
                options={generateIndicatorMatchOptions()}
                onChange={indicatorOptionChangeHandler}
                closeMenuOnSelect
                placeholder={t(
                  "validations.configValidations.placeholderIndicatorMatchSelect"
                )}
                defaultValue={indicatorSelectedOption}
                isClearable
                isDisabled={
                  !file1SelectedOption || !file2SelectedOption ? true : false
                }
              />
            </FormSelectLayout>
          </ShadowBox>
        </Stack>
      </Stack>
      <Stack sx={config_view_buttons_container}>
        <ConfirmationModal
          open={openConfirmationModal}
          handleClose={toggleConfirmationModal}
          handleAccept={handleAccept}
          title={t("validations.configValidations.confirmationModal.title")}
          customMessage={true}
          message={t("validations.configValidations.confirmationModal.message")}
        />
        <Button
          title={t("general.cancel")}
          color="light-grey"
          type="button"
          onClick={closeAndClean}
        />
        <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="submit"
          onClick={toggleConfirmationModal}
          disabled={!isFormValid()}
        />
      </Stack>
    </ComponentLayout>
  );
};

export default ConfigCodesMatching;
