import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";
import { memo } from "react";
import exporting from "highcharts/modules/exporting";
import { useViewDashboard } from "../../context/ViewDashboardProvider";

exporting(Highcharts);
interface Props {
  results: any;
  onClickFilters?: any;
}
const PieDrilldownChart = memo((props: Props) => {
  const { results, onClickFilters } = props;

  const { categoricsColumns } = useViewDashboard();

  drilldown(Highcharts);

  const options = {
    chart: {
      type: "pie",
      style: {
        width: "100%",
      },
      zooming: {
        type: "xy",
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.y}",
        },
        point: {
          events: {
            name: "",
            parent: "",
            click: function () {
              // Si existe onClickFilters, ejecuta la función y evita el drilldown
              if (onClickFilters && this.name) {
                const column = categoricsColumns.find(
                  (col) => col.label === results?.drilldown?.series[0]
                );

                if (column) {
                  onClickFilters(
                    results?.drilldown?.series[0],
                    column.value,
                    this.name
                  );
                }
                return false; // Esto previene el drilldown
              }
            },
          },
        },
      },
    },
    title: {
      text: results.titulo_grafico,
      style: {
        color: "var(--text-main)",
        fontSize: "18px",
        fontWeight: "bold",
        textShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
      },
    },
    colors: [
      "var(--chart-1)",
      "var(--chart-2)",
      "var(--chart-3)",
      "var(--chart-4)",
      "var(--chart-5)",
      "var(--chart-6)",
      "var(--chart-7)",
      "var(--chart-8)",
      "var(--chart-9)",
      "var(--chart-10)",
      "var(--chart-11)",
      "var(--chart-12)",
      "var(--chart-13)",
      "var(--chart-14)",
      "var(--chart-15)",
      "var(--chart-16)",
      "var(--chart-17)",
      "var(--chart-18)",
      "var(--chart-19)",
      "var(--chart-20)",
      "var(--chart-21)",
    ],
    series: results.series,
    drilldown: results.drilldown,
    accessibility: {
      enabled: true,
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
    },
    exporting: {
      enabled: true, // Esto habilita el botón de exportación
      buttons: {
        contextButton: {
          menuItems: [
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
          ],
        },
      },
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
});

export default PieDrilldownChart;
